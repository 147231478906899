import React, { useState } from "react";
import { toast } from "react-toastify";
import DateFormate from "dateformat";
import { executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { PAYOUT__LIST_WITH_FILTERS } from "../../services/payout.gql";
import Pagination from "../elements/pagination";

const EligibleStdTabInPartner = ({
  partnerList,
  paginationData,
  onPaginate,
  filterData,
}) => {
  const [isPayoutTblVisible, setIsPayoutTblVisible] = useState(null);
  const [isPartnerPayoutTblVisible, setIsPartnerPayoutTblVisible] =
    useState(null);
  const [payoutList, setPayoutList] = useState([]);

  const getPayoutListing = async (partner_id) => {
    try {
      const payload = {
        filter_params: {
          partner_id,
          is_paging: "no",
          paid_status: "pending",
          source: "web",
        },
        page_no: 1,
      };

      const response = await executeGraphQLMutation(
        PAYOUT__LIST_WITH_FILTERS,
        payload,
        1
      );
      if (response.data?.getDisbursalLeads) {
        const disbursals =
          response.data?.getDisbursalLeads[0]?.disbursals || [];
        setPayoutList(disbursals);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePayoutListing = async (partnerid, index) => {
    if (isPayoutTblVisible === null) {
      await getPayoutListing(partnerid);
    }
    setIsPayoutTblVisible((prev) => (prev === index ? null : index));
  };

  const handlePayoutRowClick = (index) => {
    setIsPartnerPayoutTblVisible((prev) => (prev === index ? null : index));
  };

  return (
    <>
      <div className="clearfix">
        <div className="table-responsive">
          <table className="table table-bordered tablefontsize">
            <thead>
              <tr>
                <th>Partner Details</th>
                <th>Calculated</th>
                <th>Payable Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {partnerList.length > 0 ? (
                <>
                  {partnerList.map((partner, index) => (
                    <>
                      <tr key={`${index}_${index}`}>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Partner Code : <span> AMK{partner.partner_id}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Partner Name : <span>{partner.partner_name}</span>
                          </div>
                          <div>
                            {partner.partner_status != 1 ? (
                              <span style={{ color: "red" }}>NOT VERIFIED</span>
                            ) : (
                              ""
                            )}
                          </div>
                          {partner.parent_partner_id > 0 && (
                            <div style={{ opacity: 0.6 }}>
                              Parent Partner :{" "}
                              <span>
                                {partner.parent_partner_name} (
                                {partner.parent_partner_id})
                              </span>
                            </div>
                          )}
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            <div>
                              {" "}
                              Disbursed :
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_disbursal_amount
                              )}
                            </div>
                            <div>
                              {" "}
                              Sanctioned :
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_sanction_amount
                              )}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            <span>
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_payout_amount
                              )}
                            </span>
                            <div style={{ opacity: 0.6 }}>
                              PAT AMOUNT : <span>{partner.total_pat}</span>
                            </div>
                            <div style={{ opacity: 0.6 }}>
                              TDS AMOUNT : <span>{partner.total_tds}</span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div style={{ display: "flex" }}>
                            <div className="btn-group btn-group-small mrg-r10">
                              <div
                                className="btn btn-default"
                                title="Mark as View"
                                onClick={() =>
                                  handlePayoutListing(partner.partner_id, index)
                                }
                              >
                                <i className="ic-remove_red_eyevisibility icons-normal"></i>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      {isPayoutTblVisible === index && (
                        <tr
                          style={{
                            backgroundColor: "rgb(245,245,245)",
                            paddingRight: "40px",
                            paddingLeft: "40px",
                          }}
                        >
                          <td colSpan={4}>
                            <div className="table-responsive" width={100}>
                              <table className="table table-bordered tablefontsize">
                                <thead>
                                  <tr>
                                    <th className="payouttable">Lead ID</th>
                                    <th className="payouttable">Tranche ID</th>
                                    <th className="payouttable">Loan ID</th>
                                    <th className="payouttable">
                                      Customer Name
                                    </th>
                                    <th className="payouttable">Full. Type</th>
                                    <th className="payouttable">Product</th>
                                    <th className="payouttable">Bank</th>
                                    <th className="payouttable">calculated</th>
                                    <th className="payouttable">Payout</th>
                                    <th className="payouttable">TDS</th>
                                    <th className="payouttable">PAT</th>
                                    <th className="payouttable">Paid</th>
                                    <th className="payouttable">Pending</th>
                                    <th className="payouttable">Calc. Date</th>
                                    <th className="payouttable"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {payoutList.length > 0 ? (
                                    <>
                                      {payoutList.map(
                                        (partnerlead, leadIndex) => (
                                          <>
                                            <tr
                                              style={{ cursor: "pointer" }}
                                              key={`${leadIndex}_${index}`}
                                              onClick={() =>
                                                handlePayoutRowClick(leadIndex)
                                              }
                                            >
                                              <td>{partnerlead.lead_id}</td>
                                              <td>{partnerlead.tranche_id}</td>
                                              <td>{partnerlead.app_id}</td>
                                              <td>
                                                {partnerlead.customer_name}
                                              </td>
                                              <td>
                                                {partnerlead.fulfillment_type}
                                              </td>

                                              <td>
                                                {partnerlead.product_type}
                                              </td>
                                              <td>{partnerlead.financer_id}</td>

                                              <td>
                                                {partnerlead.slab_amount
                                                  ? new Intl.NumberFormat(
                                                      "en-IN"
                                                    ).format(
                                                      partnerlead[
                                                        partnerlead.slab_amount
                                                      ]
                                                    )
                                                  : ""}
                                                <div class="tooltip">
                                                  <div
                                                    style={{ display: "flex" }}
                                                    class="btn btn-default"
                                                  >
                                                    <i className="ic-info-icon1 icons-normal"></i>
                                                  </div>
                                                  <div class="tooltiptext">
                                                    {partnerlead.slab_date ? (
                                                      <div>
                                                        Slab Date :{" "}
                                                        {partnerlead.slab_date.replace(
                                                          "_",
                                                          " "
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {partnerlead.slab_amount ? (
                                                      <div>
                                                        Slab Amount :{" "}
                                                        {partnerlead.slab_amount.replace(
                                                          "_",
                                                          " "
                                                        )}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </td>

                                              <td>
                                                {new Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(
                                                  partnerlead.payout_total
                                                )}
                                              </td>
                                              <td>
                                                {new Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(partnerlead.tds_total)}
                                              </td>
                                              <td>
                                                {new Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(partnerlead.pat_total)}
                                              </td>
                                              <td>
                                                {new Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(
                                                  partnerlead.payout_paid
                                                )}
                                              </td>
                                              <td>
                                                {new Intl.NumberFormat(
                                                  "en-IN"
                                                ).format(
                                                  partnerlead.payout_pending
                                                )}
                                              </td>
                                              <td>
                                                {partnerlead.slab_date
                                                  ? DateFormate(
                                                      new Date(
                                                        partnerlead[
                                                          partnerlead.slab_date
                                                        ]
                                                      ),
                                                      "dS mmm yyyy"
                                                    )
                                                  : ""}
                                              </td>
                                              <td style={{ fontSize: 20 }}>
                                                {isPartnerPayoutTblVisible ===
                                                leadIndex
                                                  ? "-"
                                                  : "+"}
                                              </td>
                                            </tr>

                                            {isPartnerPayoutTblVisible ===
                                              leadIndex && (
                                              <tr>
                                                <td colSpan={15}>
                                                  <table className="table table-bordered tablefontsize">
                                                    <thead>
                                                      <tr>
                                                        <th className="payouttable">
                                                          ID
                                                        </th>
                                                        <th className="payouttable">
                                                          Payout Type
                                                        </th>
                                                        <th className="payouttable">
                                                          Date
                                                        </th>
                                                        <th className="payouttable">
                                                          Scheme
                                                        </th>
                                                        <th className="payouttable">
                                                          Payout Per
                                                        </th>
                                                        <th className="payouttable">
                                                          Per.
                                                        </th>
                                                        <th className="payouttable">
                                                          Payout Amount
                                                        </th>
                                                        <th className="payouttable">
                                                          TDS
                                                        </th>
                                                        <th className="payouttable">
                                                          TDS %
                                                        </th>
                                                        <th className="payouttable">
                                                          PAT
                                                        </th>
                                                        <th className="payouttable">
                                                          Paid Status
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {partnerlead.payouts.map(
                                                        (
                                                          payout,
                                                          payoutIndex
                                                        ) => (
                                                          <tr
                                                            key={`${index}_${leadIndex}_${payoutIndex}`}
                                                          >
                                                            <td>{payout.id}</td>
                                                            <td>
                                                              {
                                                                payout.payout_type
                                                              }
                                                            </td>
                                                            <td>
                                                              {DateFormate(
                                                                new Date(
                                                                  payout.created_at
                                                                ),
                                                                "dS mmm yyyy"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {payout.scheme &&
                                                              payout.scheme
                                                                .scheme_name
                                                                ? payout.scheme
                                                                    .scheme_name
                                                                : ""}
                                                            </td>
                                                            <td>
                                                              {
                                                                payout.payout_per
                                                              }
                                                            </td>
                                                            <td>
                                                              {payout.paid_per
                                                                ? payout.paid_per
                                                                : 100}{" "}
                                                              %
                                                            </td>
                                                            <td>
                                                              {
                                                                payout.payout_amount
                                                              }
                                                            </td>
                                                            <td>
                                                              {payout.tds}
                                                            </td>
                                                            <td>
                                                              {payout.tds_per}%
                                                            </td>
                                                            <td>
                                                              {payout.pat}
                                                            </td>
                                                            <td>
                                                              {payout.paid_status.replace(
                                                                "_",
                                                                " "
                                                              )}
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td>No Data Found!</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  <tr key={"pagination"}>
                    <td colSpan={3}>
                      <Pagination
                        pageCount={paginationData.totalpage}
                        activePage={filterData?.page_no || 1}
                        onPaginate={(page_no) =>
                          onPaginate(page_no, filterData.status)
                        }
                      />
                    </td>
                    <td>Total: {paginationData.totalrecords}</td>
                  </tr>
                </>
              ) : (
                <tr key={"No data found"}>
                  <td colSpan={3}>No Data Found!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default EligibleStdTabInPartner;
