import React, { useEffect, useMemo, useState } from "react";
import Formsy, { addValidationRule } from "formsy-react";
import { useDispatch, useSelector } from "react-redux";
import DocumentGallery from "../DocumentGallery";
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsySelect from "../../elements/FormsySelect";
import LeadRemarkHistory from "../../lead-list/LeadRemarkHistory";
import {
  getLoanDetail,
  getMasterCityState,
  setCityList,
  setAuthorityName
} from "../../../store/action/allAction";
import Modal from "../../elements/Modal";
import { SAVE_CUST_DETAILS } from "../../../services/customer.gql";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import {
  useOutletContext,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  CHECK_ASSIGN_USER,
  IS_USER_ACCESS_PAGE,
  DISBURSE_REJECTION_ID,
} from "../../../config/constants";
import GeneralService from "../../../services/generalService";
import { GET_BUILDER_LIST, GET_PROJECT_LIST } from "../../../services/bre.gql";
import crypto from "../../../config/crypto";

const PROPERTY_TYPE_RESALE = 9;

const FORM_FIELDS = FormFields["PROPERTY_IDENTIFIED"];
const LAP_FIELDS = FormFields["PROPERTY_IDENTIFIED_LAP"];
addValidationRule("matchAccNo", (values, value) => {
  if (values.account_number) {
    return !values.re_account_number
      ? "Please Re-Enter Account Number"
      : values.account_number !== values.re_account_number
      ? "Account No. does not match"
      : true;
  }
  return true;
});
const PropertyIdentified = (props) => {
  const [propertyIdentified, setPropertyIdentified] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isPropertyIdentified, setIsPropertyIdentified] = useState("no");
  const [is_lap, set_is_lap] = useState(false);
  const [occupancyStatus , setOccupancyStatus] = useState("")
  const [usageType , setUsageType] = useState("")
  const [property_sub_type , set_property_sub_type] = useState("")
  const [isValueChange,setIsValueChange]=useState(false)
  const { masterdata, lead, remarksHistory } = useSelector(
    ({ masterdata, lead }) => ({
      masterdata,
      lead,
      remarksHistory: lead.remarksHistory || [],
    })
  );
  const [isFormTouch, setTouch] = useState({});
  const client = useApolloClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || [];
  let navigate = useNavigate();
  // let { lead_id } = useParams();
  let accessCondition = !IS_USER_ACCESS_PAGE(
    "propertyIdentified",
    "customer-bank-details"
  )?.is_edit_access;
  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }
  const user_information = localStorage.getItem("user_information");
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;

  const prioritizedStates = [
    "Haryana",
    "Delhi",
    "Karnataka",
    "Maharashtra",
    "Telangana",
    "Uttar Pradesh",
  ];
  const prioritizedCities = [
    "New Delhi",
    "Gurgaon",
    "Fridabad",
    "Manesar",
    "Sonipat",
  ];

  const UsageType = [
    {
      id: "commercial",
      label: "Commercial",
      __typename: "Dropdown",
    },
    {
      id: "residential",
      label: "Residential",
      __typename: "Dropdown",
    },
    {
      id: "industrial",
      label: "Industrial",
      __typename: "Dropdown",
    },
  ];

  const OccupancyType = [
    {
      id: "vacant",
      label: "Vacant",
      __typename: "Dropdown",
    },
    {
      id: "self_occupied",
      label: "Self-Occupied",
      __typename: "Dropdown",
    },
    {
      id: "given_on_rent",
      label: "Given on Rent",
      __typename: "Dropdown",
    },
  ];

  const Property_Sub_Type = [
    {
       label : "Ready To Move",
       id : "1"
    },
    {
      label : "Plot",
      id : "2"
    }
  ]

  const PROPERTY_SUB_TYPE_MAPPING_OBJ = {
    1: "ready_to_move",
    4: "contruction_linked",
    2: "plot",
    3: "plot_contruction",
  };


  const DDA_PROPERTY_GROUP = [
    {
      id: "1",
      label: "EWS",
      value: 1,
      __typename: "Dropdown",
    },
    {
      id: "2",
      label: "LIG",
      value: 2,
      __typename: "Dropdown",
    },
    {
      id: "3",
      label: "MIG",
      value: 3,
      __typename: "Dropdown",
    },
    {
      id: "4",
      label: "HIG",
      value: 4,
      __typename: "Dropdown",
    },
  ];

  const DDA_Registration = [
    {
      id: "1",
      label: "Booking",
      value:1,
      __typename: "Dropdown",
    },
    {
      id: "2",
      label: "Registered",
      value:2,
      __typename: "Dropdown",
    },{
      id: "4",
      label: "Not Registered and Pni",
      value:4,
      __typename: "Dropdown",
    },
    {
      id: "5",
      label: "Demand Letter Pending",
      value:5,
      __typename: "Dropdown",
    },
    {
      id: "6",
      label: "PTM Pending",
      value:6,
      __typename: "Dropdown",
    },
    {
      id: "7",
      label: "PTM Received",
      value:7,
      __typename: "Dropdown",
    },
    {
      id: "8",
      label: "Demand Letter Received",
      value:7,
      __typename: "Dropdown",
    }

  ];

  const getBuilderList = async (key_name, value) => {
    try {
      let cityId = null,
        builderId = null;
      if (key_name === "builder") {
        cityId = +value;
        builderId = null;
      } else if (key_name === "project") {
        cityId = +propertyIdentified["property_city"] || null;
        builderId = +value;
      }
      const variables = {
        [`${key_name}Params`]: {
          ...(cityId ? { cityId } : {}),
          ...(builderId ? { builderId } : {}),
        },
      };
      const mutation =
        key_name === "builder" ? GET_BUILDER_LIST : GET_PROJECT_LIST;
      const resp = await executeGraphQLMutation(mutation, variables, client);
      let optionList = [];
      if (resp.data?.[`get_${key_name}_name`]?.data) {
        const responseData = resp.data?.[`get_${key_name}_name`];
        optionList = responseData.data?.map((obj) => {
          return {
            id: obj[`${key_name}_id`],
            value: obj[`${key_name}_id`],
            label: obj[`${key_name}_name`],
          };
        });
        return optionList;
      } else {
        toast.error(resp.data?.[`get_${key_name}_name`]?.message);
      }
    } catch (error) {
      console.error(`🔻 ERROR : ${error.message}`);
      toast.error("Internal server error");
      return [];
    }
  };

  const setBuilderAndProjectList = async (key_name, value) => {
    if (
      lead?.leadDetail?.lead_details?.is_property_identified === "yes" &&
      location.pathname.indexOf("property-identified") > -1
    ) {
      const list = await getBuilderList(key_name, value);
      ALL_OPTIONS[`${key_name}_name`] = list;
    }
  };

  useEffect(() => {
    if( lead.leadDetail.lead_details?.occupancy_status){
      setOccupancyStatus(lead.leadDetail.lead_details?.occupancy_status)
    }
    if ( lead.leadDetail.lead_details?.property_usage) {
      setUsageType( lead.leadDetail.lead_details?.property_usage)
    }
    if ( lead.leadDetail.lead_details?.property_sub_type) {
      set_property_sub_type(lead.leadDetail.lead_details?.property_sub_type)
    }
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      let property_identified = { ...lead.leadDetail.lead_details };
      property_identified.authority_name=property_identified?.property_authority_id;
      property_identified.authority_name_text=property_identified?.property_other_authority_name
      property_identified.property_dda_registration=property_identified?.property_dda_registration===3?2:property_identified?.property_dda_registration||null;
      if (Number(property_identified?.loan_amount) < 1500000) {
        if(!property_identified?.property_dda_group){
          property_identified.dda_property_group = 1 
        }else{
          property_identified.dda_property_group = property_identified?.property_dda_group
        }
      } else if(Number(property_identified?.loan_amount) >= 1500000 && Number(property_identified?.loan_amount)<3000000){
        if(!property_identified?.property_dda_group){
          property_identified.dda_property_group = 2 
        }else{
          property_identified.dda_property_group = property_identified?.property_dda_group
        }   
         }else if(Number(property_identified?.loan_amount) >= 3000000 && Number(property_identified?.loan_amount)<10000000){
          if(!property_identified?.property_dda_group){
            property_identified.dda_property_group = 3 
          }else{
            property_identified.dda_property_group = property_identified?.property_dda_group
          }
      }else if(Number(property_identified?.loan_amount) >= 10000000){
        if(!property_identified?.property_dda_group){
          property_identified.dda_property_group = 4 
        }else{
          property_identified.dda_property_group = property_identified?.property_dda_group
        }   
        }
      setPropertyIdentified(property_identified);
      setIsPropertyIdentified(
        property_identified["is_property_identified"] || "no"
      );
      const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[
          property_identified["property_type"]
        ]?.includes(obj.value)
      );
      ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;

      if (property_identified.property_city) {
        setBuilderAndProjectList("builder", property_identified.property_city);
      }
      if (property_identified.builder_name_id) {
        setBuilderAndProjectList(
          "project",
          property_identified.builder_name_id
        );
      }
    }


    if (lead?.leadDetail?.loan_type === 2) {
      set_is_lap(true);
    }
  }, [lead]);

  useEffect(() => {
    if (!masterdata.data || !masterdata.data.state_list) {
      dispatch(getMasterCityState());
    }
  }, []);
  useEffect(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    if (options?.city && options?.city?.length) {
      let record = options.city
        .filter((ob) => ob.state_id == propertyIdentified.property_state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });
      ALL_OPTIONS["city_list"] = record;
      ALL_OPTIONS["authority_name"] = [];
      dispatch(setCityList(record));
    }
  }, [propertyIdentified.property_state]);

  useEffect(() => {
  let options = masterdata ? { ...masterdata.data } : {};
  if (options?.authority_names && options?.authority_names.length) {
    let record = options.authority_names
      .filter((ob) => ob.city_id === propertyIdentified.property_city)
      .map((data) => ({
        ...data, 
        value: data.id,
        label: data.authority_name
      }));

    ALL_OPTIONS["authority_name"] = record; 
    dispatch(setAuthorityName(record));
  }
}, [propertyIdentified.property_city]);
  useEffect(() => {
    if (propertyIdentified.property_city) {
      setBuilderAndProjectList("builder", propertyIdentified.property_city);
    }
  }, [propertyIdentified.property_city]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    const existingPropertyType = propertyIdentified["property_type"] || null;
    options["property_sub_type"] = options["property_sub_type"]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    if (existingPropertyType && is_lap === false) {
      const filteredSubType = options["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[existingPropertyType].includes(obj.value)
      );
      options["filtered_property_sub_type"] = filteredSubType;
    }
    if(options["state_list"]){
      options["state_list"] = [
        ...options["state_list"].filter((option) =>
          prioritizedStates.includes(option.label)
        ),
        ...options["state_list"]
          .filter((option) => !prioritizedStates.includes(option.label))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ];
    }
    
    if (options["city_list"]) {
      options["city_list"] = [
        ...options["city_list"]?.filter((option) =>
          prioritizedCities.includes(option.label)
        ),
        ...options["city_list"]
          .filter((option) => !prioritizedStates.includes(option.label))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ].filter((city) => city.label !== "dddsdxelhi");
    }
    
    options["occupancy_status"] = OccupancyType;
    options["property_usage"] = UsageType;
    options["property_dda_registration"]= DDA_Registration;
    options["dda_property_group"]= DDA_PROPERTY_GROUP;
    return options;
  }, []);
  // }, [masterdata]);

  /**
   * key: property_type
   * value: list of sub_properties
   */
  const SUB_PROPERTY_MAPPING_OBJ = {
    9: [1, 2, 3],
    11: [1, 4],
    12: [1, 4],
    13: [1, 2, 3, 4],
    14: [1, 2, 3, 4],
  };

  const handleInputChange = (event) => {
    if (event.target.id) {
      setTouch({
        ...isFormTouch,
        [event.target.id]: true,
      });
      setPropertyIdentified((currentValue) => ({
        ...currentValue,
        [event.target.id]: event.target.value,
      }));
      if (
        event.target.id == "property_pincode" &&
        event.target.value.length == 6
      ) {
        setCityStateByPin(event.target.value);
      }
      setIsValueChange(true)
    }
  };

  const setCityStateByPin = async (pincode) => {
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0];
        setPropertyIdentified({
          ...propertyIdentified,
          property_pincode: pincode,
          property_state: item?.state_id,
          property_city: item?.city_id,
        });
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };
  const {property_agreement_value, expected_property_value}=lead?.leadDetail?.lead_details;
  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setPropertyIdentified((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      if (property_agreement_value == data?.floatValue || expected_property_value == data?.floatValue) {
        setIsValueChange(false);
      }else{
        setIsValueChange(true)
      }
    }
  };

  const handleSelectChange = async (data, field_name) => {
    if (data && field_name) {
      if (field_name === "is_property_identified") {
        setIsPropertyIdentified(data.value);
      }
      if (field_name === "property_type") {
        const filteredSubType = ALL_OPTIONS["property_sub_type"].filter((obj) =>
          SUB_PROPERTY_MAPPING_OBJ[data.value].includes(obj.value)
        );
        ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
      }
      if (field_name === "property_city") {
        const list = await getBuilderList("builder", data.value);
        ALL_OPTIONS["builder_name"] = list;
      }
      if (field_name === "builder_name_id") {
        const list = await getBuilderList("project", data.value);
        ALL_OPTIONS["project_name"] = list;
      }
      setPropertyIdentified((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      setIsValueChange(true)
    }
  };

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let is_value_change=isValueChange;
    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          is_value_change,
          lead_details: {
            is_property_identified: propertyIdentified.is_property_identified,
            property_value: propertyIdentified.property_value,
            property_type: +propertyIdentified.property_type > 0 ? +propertyIdentified.property_type : PROPERTY_TYPE_RESALE,
            usage_type: +propertyIdentified.usage_type,
            agreement_type: +propertyIdentified.agreement_type,
            property_pincode: propertyIdentified.property_pincode,
            property_city: propertyIdentified.property_city,
            property_state: propertyIdentified.property_state,
            property_address1: propertyIdentified.property_address1,
            property_address2: propertyIdentified.property_address2,
            property_agreement_value: propertyIdentified.property_agreement_value,
            property_sub_type: property_sub_type ? Number(property_sub_type) :  +propertyIdentified.property_sub_type,
            builder_name_id: propertyIdentified.builder_name_id,
            project_name: propertyIdentified.project_name,
            expected_property_value: propertyIdentified.expected_property_value,
            occupancy_status:occupancyStatus,
            property_usage:usageType,
            property_authority_id: propertyIdentified?.authority_name,
            property_other_authority_name: propertyIdentified?.authority_name_text,
            property_dda_registration:propertyIdentified?.property_dda_registration,
            property_dda_group:propertyIdentified?.dda_property_group,
          },
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.LeadAdditionalInput || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id, client));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let PropertyIdentifiedForm = FORM_FIELDS;

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {
    return lead.leadDetail.sub_status_id == 2 ? false : true;
  }, [allowSubmit]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const movetoNext = () => {
    let lead_id = lead?.leadDetail?.id || "";
    handleSubmit();
    if (allowSubmit) {
      navigate(
        `/lead-detail/customer-details/offer-detail/${crypto.encode(
          lead_id
        )}?offerCheck=no`
      );
    }
  };

  const {user_paid_token_amount}=lead?.leadDetail?.lead_details;

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let isPropIdentified = "no";
    if (checked && user_paid_token_amount) {
      isPropIdentified = "yes";
    }
    setIsPropertyIdentified(isPropIdentified);
    setPropertyIdentified((currentValue) => ({
      ...currentValue,
      [name]: isPropIdentified,
    }));
    setIsValueChange(true)
  };
  
  const handlePropertySubType = (value) => {
    set_property_sub_type(value);
    setIsValueChange(true)
  };

  const handleOccupancyChange = (value) => {
    setOccupancyStatus(value);
    setIsValueChange(true)
  };
  const handleUsageChange = (value) => {
    setUsageType(value)
    setIsValueChange(true)
 }
  const sectionInfo = {
    section: "Customer details",
    sub_section: "Bank details",
  };
  const rejectDisable =
    lead.leadDetail.reject_reason_id > 0 &&
    !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) &&
    [2, 3].includes(lead.leadDetail.status_id)
      ? true
      : false;
  const IS_LOST = lead?.leadDetail?.status_id == 5 ? true : false;
  let newPropertyIdentifiedForm =
    isPropertyIdentified === "yes" ? PropertyIdentifiedForm : [];
    
    const cityMatch = Array.isArray(ALL_OPTIONS["authority_names"]) &&
    ALL_OPTIONS["authority_names"]
      .some((item) => item.city_id === propertyIdentified["property_city"]);
  return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery
          documentCategoryList={documentCategoryList}
          doc_id="5"
        />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Property Details</h2>
          </div>

          {is_lap === true ? (
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
            >
              <div className="row">
                {LAP_FIELDS.map((field, index) =>
                  isPropertyIdentified === "" ||
                  (isPropertyIdentified === null &&
                    field.name !== "is_property_identified") ||
                  (isPropertyIdentified === "yes" &&
                    field.id === "expected_property_value") ||
                  (isPropertyIdentified === "no" &&
                    field.id === "property_value") ||
                  (propertyIdentified["property_type"] === 9 &&
                    field.name === "project_name") ||
                  (propertyIdentified["property_type"] === 11 &&
                    field.name === "project_name") ||
                  (propertyIdentified["property_type"] === null &&
                    field.name === "project_name") ||
                  (isPropertyIdentified === "no" &&
                    field.id === "project_name") ||
                  (propertyIdentified["property_type"] === 9 &&
                    field.name === "builder_name_id") ||
                  (propertyIdentified["property_type"] === 11 &&
                    field.name === "builder_name_id") ||
                  (propertyIdentified["property_type"] === null &&
                    field.name === "builder_name_id") ||
                  (isPropertyIdentified === "no" &&
                    field.id === "builder_name_id") ||
                  (isPropertyIdentified === "no" &&
                    field.id === "property_sub_type") ||
                  (isPropertyIdentified === "no" &&
                    field.id === "property_address1") ||
                  (isPropertyIdentified === "no" &&
                    field.id === "property_address2") ? null : [
                      "text",
                      "password",
                      "pattern-format",
                      "number-format",
                      "number",
                    ].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 " key={index}>
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={
                          (propertyIdentified &&
                            propertyIdentified[field.name]) ||
                          ""
                        }
                        placeholder=" "
                        thousandsGroupStyle="lakh"
                        thousandSeparator={","}
                        decimalSeparator={"."}
                        label={
                          propertyIdentified["is_property_identified"] === "yes"
                            ? `${field.label} \u2726`
                            : field.label
                        }
                        maxLength={field.maxLength}
                        onChange={
                          ["pattern-format", "number-format"].includes(
                            field.type
                          )
                            ? (data) =>
                                handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={
                          isFormTouch[field.name] ? field.validations : null
                        }
                        
                        validationError={
                          propertyIdentified[field.name] ||
                          propertyIdentified[field.name] == ""
                            ? isFormTouch[field.name]
                              ? field.validationError
                              : ""
                            : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) : (isPropertyIdentified === "no" &&
                      field.id === "agreement_type") ||
                    (isPropertyIdentified === "yes" &&
                      field.id === "property_type") ||
                    (propertyIdentified["property_type"] === null &&
                      field.id === "property_sub_type") ? null : field.type ===
                    "dropdown" ? (
                    <fieldset class="single-select col-md-6" key={index}>
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          // options: getSelectList(ALL_OPTIONS,field.optionsKey),
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder:
                            propertyIdentified["is_property_identified"] ===
                            "yes"
                              ? `${field.label} \u2726`
                              : field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value:
                            ALL_OPTIONS &&
                            ALL_OPTIONS[field.optionsKey] &&
                            ALL_OPTIONS[field.optionsKey].filter(
                              ({ value }) =>
                                value == propertyIdentified[field.name]
                            ),
                          isDisabled: field.readOnly,
                        }}
                        required={field.required}
                        value={propertyIdentified[field.name]}
                        onChange={(data) =>
                          handleSelectChange(data, field.name)
                        }
                      />
                    </fieldset>
                  ) : null
                )}

                  <fieldset class="single-select col-md-6">
                  <FormsySelect
                    name="Property Sub Type"
                    inputProps={{
                      options: Property_Sub_Type,
                      placeholder: "Property Sub Type",
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value: Property_Sub_Type.filter(({ id }) => id == property_sub_type)
                    }}
                    onChange={(data) => handlePropertySubType(data.id)}
                  >
                  </FormsySelect>
                    </fieldset>

                    <fieldset class="single-select col-md-6">
                  <FormsySelect
                    name="Occupancy Status"
                    inputProps={{
                      options: OccupancyType,
                      placeholder: "Occupancy Status",
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value: OccupancyType.filter(({ id }) => id == occupancyStatus)
                    }}
                    onChange={(data) => handleOccupancyChange(data.id)}
                  >
                  </FormsySelect>
                    </fieldset>

                    <fieldset class="single-select col-md-6">
                  <FormsySelect
                    name="Usage Type"
                    inputProps={{
                      options: UsageType,
                      placeholder: "Usage Type",
                      className: "react-select",
                      classNamePrefix: "react-select",
                      value: UsageType.filter(({ id }) => id == usageType)
                    }}
                    onChange={(data) => handleUsageChange(data.id)}
                  >
                  </FormsySelect>
                    </fieldset>

                {/* <div className="btnmarkdoneflex col-sm-6 mb-b20">
                  <div className="box">
                    <select
                      value={property_sub_type}
                      onChange={(e) => handlePropertySubType(e.target.value)}
                      required
                      placeholder=" "
                    >
                      <option value="">Property Sub Type</option>
                      {Property_Sub_Type.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="btnmarkdoneflex col-sm-6 mb-b20">
                  <div className="box">
                    <select
                      value={occupancyStatus}
                      className="react-select" 
                      onChange={(e) => handleOccupancyChange(e.target.value)}
                      required
                    >
                      <option value="">Select Occupancy Status</option>
                      {OccupancyType.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="btnmarkdoneflex col-sm-6 mb-b20">
                  <div className="box">
                    <select
                      value={usageType}
                      onChange={(e) => handleUsageChange(e.target.value)}
                      required
                    >
                      <option value="">Select Property Usage</option>
                      {UsageType.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

              </div>

              <div className="btn-save-remarks btn-save-next">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={IS_LOST}
                >
                  Save
                </button>
                <button
                  className="btn-primary-outline btn-next"
                  onClick={movetoNext}
                  disabled={IS_LOST}
                >
                  Next
                </button>
                {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}
              disabled={rejectDisable}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
              </div>
            </Formsy>
          ) : (
            <>
              <div className="property-identified">
                <label>Property Identified ?</label>
                <label className="toggle-label">No</label>
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="is_property_identified"
                    id="is_property_identified"
                    value="Yes"
                    checked={isPropertyIdentified === "yes" ? true : false}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider"></span>
                </label>
                <label className="toggle-label">Yes</label>
              </div>
              <div className='flexinputfield'>
                {!user_paid_token_amount && (
                  <p style={{ marginBottom: '20px', fontWeight: 400, color: 'red' }}>
                    The property Identified cannot be marked Yes since token amount is not yet paid
                  </p>
                )}
              </div>
              <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => setAllowSubmit(true)}
                onInvalid={() => setAllowSubmit(false)}
                onValidSubmit={handleSubmit}
              >
                <div className="row">
                {PropertyIdentifiedForm.map((field, index) =>
                    isPropertyIdentified === "" ||
                    (isPropertyIdentified === null &&
                      field.name !== "is_property_identified") ||
                    (isPropertyIdentified === "yes" &&
                      field.id === "expected_property_value") ||
                    (isPropertyIdentified === "no" &&
                      field.id === "property_value") ||
                    (propertyIdentified["property_type"] === 9 &&
                      field.name === "project_name") ||
                    (propertyIdentified["property_type"] === 11 &&
                      field.name === "project_name") ||
                    (propertyIdentified["property_type"] === null &&
                      (field.name === "project_name" || field.name === "authority_name" || field.name === "authority_name_text")) ||
                    (isPropertyIdentified === "no" &&
                      (field.id === "project_name" || field.id === "authority_name"||field.name === "authority_name_text"))  ||
                    (propertyIdentified["property_type"] === 9 &&
                      field.name === "builder_name_id") ||(
                        isPropertyIdentified==="yes" &&
                        (propertyIdentified["property_type"] === 9 ||
                        propertyIdentified["property_type"] === 11 ||
                        propertyIdentified["property_type"] === 12)
                        &&
                        (field.name === "authority_name"||field.name==="authority_name_text" )
                      )||
                    (propertyIdentified["property_type"] === 11 &&
                      field.name === "builder_name_id") ||
                      (propertyIdentified["property_city"] === null &&
                        field.name === "authority_name")||(propertyIdentified["property_city"] === null 
                          && field.name === "authority_name_text")||
                    (propertyIdentified["property_type"] === null &&
                      field.name === "builder_name_id") ||
                    (isPropertyIdentified === "no" &&
                      field.id === "builder_name_id") ||
                    (isPropertyIdentified === "no" &&
                      field.id === "property_sub_type") ||
                    (isPropertyIdentified === "no" &&
                      field.id === "property_address1")
                       ||
                    (isPropertyIdentified === "no" &&
                      field.id === "property_address2")
                       ||(
                        isPropertyIdentified === "yes" && 
                        propertyIdentified["property_type"] === 13 &&
                        (field.name === "project_name" || field.name === "builder_name_id") )||(
                          isPropertyIdentified === "yes" &&
                          cityMatch &&
                          field.name === "authority_name_text"
                        ) ||
                        (
                          isPropertyIdentified === "yes" &&
                          !cityMatch &&
                          field.name === "authority_name"
                        )||
                        (
                          isPropertyIdentified === "yes" &&
                          ![2015, 2287, 2520, 1031, 1630].includes(propertyIdentified["property_city"]) && 
                          field.name === "dda_property_group"
                        )||
                        (
                          isPropertyIdentified === "yes" &&
                          ![2015, 2287, 2520, 1031, 1630].includes(propertyIdentified["property_city"]) && 
                          field.name === "dda_registration"
                        ) ||
                        (
                          isPropertyIdentified === "no" &&
                          field.name === "dda_property_group"
                        ) 
                        ||
                        (
                          isPropertyIdentified === "no" &&
                          field.name === "property_dda_registration"
                        ) ||
                        (
                          isPropertyIdentified === "yes" &&
                          ![2015, 2287, 2520, 1031, 1630].includes(propertyIdentified["property_city"]) && 
                          field.name === "property_dda_registration"
                        )||(
                          isPropertyIdentified === "yes" && 
                          propertyIdentified["property_type"] !== 13 &&
                          (field.name === "property_dda_registration" || field.name === "dda_property_group") )
                      ? null : [
                        "text",
                        "password",
                        "pattern-format",
                        "number-format",
                        "number",
                      ].includes(field.type) ? (
                      <fieldset class="form-filed col-md-6 " key={index}>
                        <FormsyInputField
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          value={
                            (propertyIdentified &&
                              propertyIdentified[field.name]) ||
                            ""
                          }
                          placeholder=" "
                          thousandsGroupStyle="lakh"
                          thousandSeparator={","}
                          decimalSeparator={"."}
                          label={
                            propertyIdentified["is_property_identified"] ===
                            "yes"
                              ? `${field.label} \u2726`
                              : field.label
                          }
                          maxLength={field.maxLength}
                          onChange={
                            ["pattern-format", "number-format"].includes(
                              field.type
                            )
                              ? (data) =>
                                  handlePatternFormatChange(data, field.name)
                              : handleInputChange
                          }
                          format={field.ApplyNumberformat ? field.format : ""}
                          validations={
                            isFormTouch[field.name] ? field.validations : null
                          }
                          validationError={
                            propertyIdentified[field.name] ||
                            propertyIdentified[field.name] == ""
                              ? isFormTouch[field.name]
                                ? field.validationError
                                : ""
                              : ""
                          }
                          required={field.required}
                          readOnly={field.readOnly}

                        />
                      </fieldset>
                    ) : (isPropertyIdentified === "no" &&
                        field.id === "agreement_type") ||
                      (isPropertyIdentified === "no" &&
                        field.id === "property_type") ||
                      (propertyIdentified["property_type"] === null &&
                        field.id ===
                          "property_sub_type") ? null : field.type ===
                      "dropdown" ? (
                      <fieldset class="single-select col-md-6" key={index}>
                        <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            // options: getSelectList(ALL_OPTIONS,field.optionsKey),
                            options:
                              ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder:
                              propertyIdentified["is_property_identified"] ===
                              "yes"
                                ? `${field.label} \u2726`
                                : field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value:
                              ALL_OPTIONS &&
                              ALL_OPTIONS[field.optionsKey] &&
                              ALL_OPTIONS[field.optionsKey].filter(
                                ({ value }) =>
                                  value == propertyIdentified[field.name]
                              ),
                            isDisabled: field.readOnly,
                          }}
                          required={field.required}
                          value={propertyIdentified[field.name]}
                          onChange={(data) =>
                            handleSelectChange(data, field.name)
                          }
                        />
                      </fieldset>
                    ) : null
                  )}
                </div>

                <div className="btn-save-remarks btn-save-next">
                  <button
                    type="submit"
                    className="btn-primary"
                    disabled={IS_LOST}
                  >
                    Save
                  </button>
                  <button
                    className="btn-primary-outline btn-next"
                    onClick={movetoNext}
                    disabled={IS_LOST}
                  >
                    Next
                  </button>
                  {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}
              disabled={rejectDisable}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
                </div>
              </Formsy>
            </>
          )}
        </div>
      </div>
      <div className="view-timeline-popup">
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory
              showremarkform={showremarkform}
              markAsFreezed={getContext.markAsFreezed}
              accessCondition={accessCondition}
              sectionInfo={sectionInfo}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default PropertyIdentified;
