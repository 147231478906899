import Formsy from "formsy-react";
import React, { useEffect, useState } from "react";
import FormFields from "./formFields/customerDetailsFormFields.json";
import FormsyInputField from "../elements/FormsyInputField";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { UPDATE_REFERENCE_DETAILS } from "../../services/leadCreate.gql";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";


const maskOptions = {
  maskWith: "*",
  unmaskedStartDigits: 0,
  unmaskedEndDigits: 6,
};

function maskNumber(number, options) {
  // Ensure number is a string
  const numberStr = String(number);

  // Destructure options with defaults
  const { maskWith = '*', unmaskedStartDigits = 3, unmaskedEndDigits = 4 } = options;

  // If the number is too short to mask, return it as is
  if (numberStr.length <= unmaskedStartDigits + unmaskedEndDigits) {
    return numberStr;
  }

  // Get the start and end parts of the number
  const start = numberStr.slice(0, unmaskedStartDigits);
  const end = numberStr.slice(-unmaskedEndDigits);

  // Create the masked middle part
  const maskedMiddle = maskWith.repeat(numberStr.length - unmaskedStartDigits - unmaskedEndDigits);

  // Combine start, masked middle, and end
  return start + maskedMiddle + end;
}


const EditReference = ({ lead_id, referenceData, lead_status }) => {
  const [selectedValue, setSelectedValue] = useState("partner");
  const [referenceForm, setReferenceForm] = useState({});

  useEffect(()=>{
    setSelectedValue(referenceData?.reference_type ? referenceData?.reference_type : "partner")
    setReferenceForm({
      reference_name: referenceData?.reference_name ? referenceData?.reference_name : referenceData?.reference_name,
      reference_number: referenceData?.reference_phone ? maskNumber(referenceData?.reference_phone, maskOptions) : ""
    });
  },[referenceData])

  const client = useApolloClient();

  const handleRadioChange = (event) => {
    if (![3,4,5].includes(lead_status)) {
      setSelectedValue(event.target.value);
      if(event.target.value==referenceData?.reference_type){
        setReferenceForm({
          reference_name: referenceData?.reference_name ? referenceData?.reference_name : referenceData?.reference_name,
          reference_number: referenceData?.reference_phone ? maskNumber(referenceData?.reference_phone, maskOptions) : ""
        })        
      }else{
        setReferenceForm({
          ...referenceForm,
          reference_name: "",
          reference_number: ""
        })
      }
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setReferenceForm((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setReferenceForm((current) => ({
        ...current,
        [field_name]: data.value,
      }));
    }
  }

  const handleSubmit = () => {
    if (![3,4,5].includes(lead_status)) {
      if(selectedValue=="other" && !referenceForm.reference_name){
        toast.error("Enter reference name")
        return;
      }
      if (referenceForm.reference_number && (referenceForm.reference_number.length !== 10 || !/^[6-9]\d{9}$/.test(referenceForm.reference_number))) {
        toast.error("Enter a correct reference mobile number");
        return;
      }
      const mutation = UPDATE_REFERENCE_DETAILS
      let variables = {
        lead_id: parseFloat(lead_id),
        reference_name: referenceForm?.reference_name,
        reference_phone: referenceForm?.reference_number,
        reference_type: selectedValue,
      };
      
      executeGraphQLMutation(mutation, variables, client)
        .then((resp) => {
          toast.success("Success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(` 🔻 ERROR : ${err.message}`);
          toast.error("Got Invalid value. Please Check");
        });
    }
  }


  const REFERENCE_FORM = FormFields["REFERENCE_FORM"];
  return (
    <>
      <div >
        <h2>Reference Details</h2>
        <div className='followupradio' style={{ marginBottom: 10 }}>
          <div className='followupcall'>
            <input
              type="radio"
              id="partner"
              name="reference"
              value="partner"
              onChange={handleRadioChange}
              checked={selectedValue === 'partner'}
            />
            <label htmlFor="partner">Partner</label>
          </div>
          <div className='followupcall'>
            <input
              type="radio"
              id="no_reference"
              name="reference"
              value="no_reference"
              checked={selectedValue === 'no_reference'}
              onChange={handleRadioChange}
            />
            <label htmlFor="no_reference">No Reference</label>
          </div>
          <div className='followupcall'>
            <input
              type="radio"
              id="other"
              name="reference"
              value="other"
              checked={selectedValue === 'other'}
              onChange={handleRadioChange}
            />
            <label htmlFor="other">Other</label>
          </div>
        </div>
        {selectedValue === "other" && (
          <div className="form-tab-right-panel">
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => null}
              onInvalid={() => null}
            >
              <div className="row">
                {REFERENCE_FORM.map((field, index) =>
                  [
                    "text",
                    "pattern-format",
                    "number",
                    "number-format",
                  ].includes(field.type) ? (
                    <fieldset className="form-filed col-md-6" key={index}>
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        readOnly={![1, 2].includes(lead_status)}
                        value={(referenceForm && referenceForm[field.name]) || ""}
                        placeholder=""
                        maxLength={field.maxLength}
                        label={field.label}
                        required={field.required}
                        thousandsGroupStyle="lakh"
                        thousandSeparator={","}
                        decimalSeparator={"."}
                        onChange={
                          ["pattern-format", "number-format"].includes(field.type)
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                      />
                    </fieldset>
                  ) : null
                )}
              </div>
            </Formsy>
          </div>
        )}
        <div className="btn-save-remarks">
          <div className="submitbtnflex">
            <button
              type="submit"
              className="btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReference;
