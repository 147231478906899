import { gql } from "@apollo/client";

export const ADD_RULE_CONFIG = gql`
  mutation add_rule_config($addRuleInput: AddRuleDTO!) {
    add_rule_config(addRuleInput: $addRuleInput)
  }
`;

export const GET_RULES_LIST = gql`
  query get_rules_list($filter_params: FilterParamsDTO!) {
    get_rules_list(filter_params: $filter_params) {
      rule_list {
        id
        financer_id
        rule_start_date
        rule_end_date
        ctp
        ctp_start_day
        ctp_start_month
        ctp_end_day
        ctp_end_month
        expected_mis_day
        expected_mis_month
        expected_collection_day
        expected_collection_month
        ptp
      }
      pagination {
        totalpage
        page_no
        totalrecords
        nextpage
        prevpage
      }
    }
  }
`;
