/* eslint-disable */
import React, { useState } from "react";
import DateFormate from "dateformat";

const ApproveSchemeConfirmPopUp = ({
  is_disable_notification,
  recalculateLeads,
  title,
  message,
  modalVisiblity,
  processData,
  productType,
  loanType,
  financers,
  isProcessDataBtnDisabled,
  selectSchemeData
}) => {
  const [isNotification, setIsNotification] = useState("yes");

  const handleRadioChange = (event) => {
    const { id } = event.target;
    setIsNotification(id);
  };

  const handleModalClose = () => {
    modalVisiblity(false);
  };

  const handleProcessData = () => {
    processData(isNotification);
  };

  return (
    <div className="mark-lost-popup">
      <div className="modal display-block" tabIndex="-1" role="dialog">
    
        <section className="modal-main increase-width" role="document">
        <h2 className="modal-title"> Scheme:  {selectSchemeData.scheme_name ? selectSchemeData.scheme_name : ""} ({selectSchemeData.id || 0}) </h2>
          <div className="modal-header">
            <h2 className="modal-title">{title || "Confirm"}</h2>
            <button
              type="button"
              className="close_icn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleModalClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h4
              dangerouslySetInnerHTML={{
                __html: message || "Are you sure to want to delete?",
              }}
            />
            {is_disable_notification && (
              <p> Do you want to send notification to partner?</p>
            )}
          </div>

          <div className="modal-footer">
            <div className="propertyflex">
              <input
                type="radio"
                id="yes"
                name="is_notification"
                onChange={handleRadioChange}
                checked={isNotification === "yes" ? true : false}
              />
              <label htmlFor="yes">Yes</label>

              <input
                type="radio"
                id="no"
                name="is_notification"
                onChange={handleRadioChange}
                checked={isNotification === "no" ? true : false}
              />
              <label htmlFor="no">No</label>
            </div>

            {recalculateLeads && recalculateLeads.length > 0 && (
              <div className="table-responsive" style={{ marginTop: "40px" ,height:200,overflowY:"auto"}}>
                <table className="table table-bordered tablefontsize">
                  <thead>
                    <tr>
                      <th>Lead id</th>
                      <th>Tranche id</th>
                      <th>Bank</th>
                      <th>Partner id</th>
                      <th>Disbursal date</th>
                      <th>Disbursal amount</th>
                      <th>Product type</th>
                      <th>Sub product type</th>
                      <th>Old payout %</th>
                      <th>Old payout amount</th>
                      <th>New payout %</th>
                      <th>New payout amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recalculateLeads.map((lead, index) => (
                      <tr key={index}>
                        <td>{lead.lead_id}</td>
                        <td>{lead.tranche_id}</td>
                        <td>
                          {lead?.financer_id
                            ? financers.find(
                                ({ id }) => id == lead?.financer_id
                              )?.name
                            : ""}
                        </td>
                        <td>{lead.partner_id}</td>
                        <td>
                          {lead?.disbursal_date
                            ? DateFormate(
                                new Date(+lead?.disbursal_date),
                                "dS mmm yyyy"
                              )
                            : ""}
                        </td>
                        <td>{lead.disbursal_amount || ""}</td>
                        <td>
                          {lead?.product_type
                            ? productType.find(
                                ({ id }) => id == lead?.product_type
                              )?.name
                            : ""}
                        </td>
                        <td>
                          {lead?.loan_type
                            ? loanType.find(({ id }) => id == lead?.loan_type)
                                ?.name
                            : ""}
                        </td>
                        <td>{lead.old_payout_per}</td>
                        <td>{lead.old_payout_amount}</td>
                        <td>{lead.new_payout_per}</td>
                        <td>{lead.new_payout_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleProcessData}
                disabled={isProcessDataBtnDisabled}
              >
                Process
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ApproveSchemeConfirmPopUp;
