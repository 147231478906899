import React, { useRef, useState } from "react"; 
import { useApolloClient } from "@apollo/client";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { INCENTIVE_CASHBACK_UPDATE_DATA, LOAN_ID_REPORT } from "../../services/mis.gql";
import { CSVLink } from "react-csv";
import dateFormat from 'dateformat';
import Loader from "../elements/Loader";
const DownloadReport = (props) => {
  const client = useApolloClient();
  const csvLink = useRef() 
  const [reportDetail,setDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const ISTDateFormate = (dateStr) => {
    if (!dateStr) return ""; 
    const dateInIST = new Date(dateStr).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
  });
  
  // Format to "yyyy-mm-dd"
   return dateInIST.split("/").reverse().join("-");
  }

  function formatReport(data) {
    let tempArray = [];
    for(let item of data?.loan_id_report){
   
      tempArray.push({
        lead_id: item.lead_id,
        tranche_type: item.tranche_type,
        tranche_id: item.tranche_id,
        partner_id: item.partner_id,
        parent_partner_id: item.parent_partner_id,
        partner_name: item.partner_name,
        parent_partner_name: item.parent_partner_name,
        partner_circle: item.partner_circle,
        yoddha: item.rm_name,
        fulfillment_type: item.fulfillment_type,
        fbm_name: item.fbm_name,
        channel: item.channel,  
        region: item.region,
        property_type: item.property_type,
        customer_first_name: item.customer_first_name,
        customer_last_name: item.customer_last_name,
        lead_created_date: ISTDateFormate(item.lead_created_date),
        login_date: dateFormat(item.login_date , "yyyy-mm-dd"), 
        login_amount: item.login_amount,
        login_amount_cr: item.login_amount_cr,
        login_number: item.login_number,
        sanctioned_date: ISTDateFormate(item.sanctioned_date),
        sanctioned_amount: item.sanctioned_amount,
        sanctioned_amount_cr: item.sanctioned_amount_cr,
        sanctioned_id: item.sanctioned_id,
        disbursed_date: ISTDateFormate(item.disbursed_date),
        disbursed_month: item.disbursed_month,
        disbursal_id: item.disbursal_id,
        disbursed_amount: item.disbursed_amount,
        disbursed_amount_cr: item.disbursed_amount_cr,
        reported_amount: item.reported_amount,
        reported_on: item.reported_on,
        disbursal_verified: item.disbursal_verified,
        lender_name: item.lender_name,
        product_type: item.product_type,
        product_sub_type: item.product_sub_type,
        transaction_id: item.transaction_id,
        transaction_date: dateFormat(item.transaction_date , "yyyy-mm-dd"),
        pdd_date: ISTDateFormate(item.pdd_date),
        payout_scheme: item.scheme_name,
        partner_slab: item.partner_slab,
        payout_per: item.payout_per,
        calculated_payout: item.calculated_payout,
        processing_fee: item.processing_fee,
        calc_payin_per  : item.calc_payin_per,
        payin_calculated: item.payin_calculated,
        cashback_marketing_amount: item.cashback_amount,
        gross_margin: item.gross_margin,
        
      })
    }
    return tempArray;
 }; 
 const downloadCSVReport = async (type='open') => {
  setLoading(true)
  try {   

    const response = await executeGraphQLQuery(LOAN_ID_REPORT(props.report_id,props.user_id, type),client); 
    if (response.data?.loan_id_report) { 
      const tempArray =  formatReport(response.data);
      setDetail(tempArray);
      setTimeout(() => csvLink.current?.link?.click(), 400);
      setLoading(false)
    }
  } catch (error) { 
    console.log("Error 🔻 ", error.message);
    setLoading(false);
  }
};

const downloadUrl = () => downloadCSVReport();
const downloadFreezUrl = () => downloadCSVReport('close');

const reportheaders = [
  { label: "Lead ID", key: "lead_id" },
  { label: "Tranch Type", key: "tranche_type" },
  { label: "Tranch ID", key: "tranche_id" },
  { label: "Partner ID", key: "partner_id" },
  { label: "Parent Partner ID", key: "parent_partner_id" },
  { label: "Partner Name", key: "partner_name" },
  { label: "Parent Partner Name", key: "parent_partner_name" },
  { label: "Partner Circle", key: "partner_circle" },
  { label: "Yoddha", key: "yoddha" },
  { label: "Fulfilment Type", key: "fulfillment_type" },
  { label: "FBM Name", key: "fbm_name" },
  { label: "Channel", key: "channel" },
  { label: "Region (Property City)", key: "region" },
  { label: "Property Type", key: "property_type" },
  { label: "Customer First Name", key: "customer_first_name" },
  { label: "Customer Last Name", key: "customer_last_name" },
  { label: "Lead Created Date", key: "lead_created_date" },
  { label: "Login Date", key: "login_date" },
  { label: "Login Amount", key: "login_amount" },
  { label: "Login Amount CR", key: "login_amount_cr" },
  { label: "Login Number", key: "login_number" },
  { label: "Sanctioned Date", key: "sanctioned_date" },
  { label: "Sanctioned Amount", key: "sanctioned_amount" },
  { label: "Sanctioned Amount CR", key: "sanctioned_amount_cr" },
  { label: "Sanctioned ID", key: "sanctioned_id" },
  
  { label: "Disbursed Date", key: "disbursed_date" },
  { label: "Disbursed Month", key: "disbursed_month" },
  { label: "Disbursal ID", key: "disbursal_id" },
  { label: "Disbursement Amount", key: "disbursed_amount" },
  { label: "Disbursement Amount CR", key: "disbursed_amount_cr" },
  { label: "Reported Amount", key: "reported_amount" },
  { label: "Reported On", key: "reported_on" },
  { label: "Disbursal Verified", key: "disbursal_verified" },
  { label: "Lender Name", key: "lender_name" },
  { label: "Product Type", key: "product_type" },
  { label: "Product Sub-Type", key: "product_sub_type" },
  { label: "Transaction ID", key: "transaction_id" },
  { label: "Transaction Date", key: "transaction_date" },
  { label: "PDD Date", key: "pdd_date" },
  { label: "Payout Scheme", key: "payout_scheme" },
  { label: "Partner Slab", key: "partner_slab" },
  { label: "Payout Per", key: "payout_per" },
  { label: "Calculated Payout", key: "calculated_payout" },
  { label: "Processing Fee", key: "processing_fee" },
  { label: "Calc Payin Per", key: "calc_payin_per" },
  { label: "Payin Calculated", key: "payin_calculated" },
  { label: "Cashback Amount", key: "cashback_amount" },
  { label: "Gross Margin", key: "gross_margin" },

 ];


//console.log('reportheaders=====================>',reportheaders)

  return (
    <>
        <div class="btn-group-flex" onClick={downloadUrl}>
        <div class="btn-group btn-group-small mrg-r5">
          <div class="btn btn-default" title="Download Current Report">
            <img src="https://static.ambak.com/saathi/1731051675319.png" alt="Download Current Report" width={18}height={20}/>
            {/* <i class="ic-upload icons-normal"></i> */}
          </div>
        </div>
      </div> 

        {loading && <Loader/>}
        {props.reporting_status == 'close' ? (
          <div class="btn-group-flex" onClick={downloadFreezUrl}>
            <div class="btn-group btn-group-small mrg-r5">
              <div class="btn btn-default" title="Download Freezed Report" disabled={loading}>
                {/* <img src="https://static.ambak.com/saathi/1731051675319.png" alt="Download Freezed Report" width={18}height={20}/> */}
                <i class="ic-arrow_drop_down_circle icons-normal" style={{color:"green"}}></i>
              </div>
            </div>
          </div> 
        ) :''}
        
        
      <CSVLink data={reportDetail} headers={reportheaders} filename={"scheme-level.csv"} className='hidden' ref={csvLink}/> 
    </>
  );
};


export default DownloadReport
