import React, { useState } from "react";
import DateFormate from "dateformat";
import Pagination from "../elements/pagination";
import { executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { INVOICE_LISTING_DATA } from "../../services/payout.gql";
import { toast } from "react-toastify";

const CDCNTabInPartner = ({
  invoiceList,
  filterData,
  financers,
  userlist,
  paginationData,
  onPaginate,
}) => {
  const [isPayoutTblVisible, setIsPayoutTblVisible] = useState(null);
  const [payoutList, setPayoutList] = useState([]);

  const getPayoutListing = async (partner_id) => {
    try {
      const response = await executeGraphQLMutation(
        INVOICE_LISTING_DATA(partner_id)
      );
      if (response.data?.invoice_payouts) {
        const invoices = response.data.invoice_payouts || [];
        setPayoutList(invoices);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePayoutListing = async (partnerid, index) => {
    if (isPayoutTblVisible === null) {
      await getPayoutListing(partnerid);
    }
    setIsPayoutTblVisible((prev) => (prev === index ? null : index));
  };

  const renderNoteIssue = (partner) => {
    switch (filterData.status) {
      case "pending":
        return (
          <>
            <div style={{ opacity: 0.6 }}>
              Date :{" "}
              <span>
                {partner?.created_at
                  ? DateFormate(new Date(partner.created_at), "dS mmm yyyy")
                  : ""}
              </span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Amount : <span>{partner.payout_amount}</span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Type :{" "}
              <span>
                {partner.invoice_type == "credit" ? "Credit" : "Debit"}
              </span>
            </div>
          </>
        );
      case "invoice_created":
        return (
          <>
            <div style={{ opacity: 0.6 }}>
              Date :{" "}
              <span>
                {partner?.created_at
                  ? DateFormate(new Date(partner.created_at), "dS mmm yyyy")
                  : ""}
              </span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Approval Date :{" "}
              <span>
                {partner?.approved_at
                  ? DateFormate(new Date(partner.approved_at), "dS mmm yyyy")
                  : ""}
              </span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Amount : <span>{partner.payout_amount}</span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Type :{" "}
              <span>
                {partner.invoice_type == "credit" ? "Credit" : "Debit"}
              </span>
            </div>
          </>
        );
      case "payment_pending":
        return (
          <>
            <div style={{ opacity: 0.6 }}>
              Date :{" "}
              <span>
                {partner?.created_at
                  ? DateFormate(new Date(partner.created_at), "dS mmm yyyy")
                  : ""}
              </span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Signed Date :{" "}
              <span>
                {partner?.approved_at
                  ? DateFormate(new Date(partner.approved_at), "dS mmm yyyy")
                  : ""}
              </span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Amount : <span>{partner.payout_amount}</span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Type :{" "}
              <span>
                {partner.invoice_type == "credit" ? "Credit" : "Debit"}
              </span>
            </div>
          </>
        );
      case "paid":
        return (
          <>
            <div style={{ opacity: 0.6 }}>
              Date :{" "}
              <span>
                {partner?.created_at
                  ? DateFormate(new Date(partner.created_at), "dS mmm yyyy")
                  : ""}
              </span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Paid Date :{" "}
              <span>
                {partner?.paid_date
                  ? DateFormate(new Date(partner.paid_date), "dS mmm yyyy")
                  : ""}
              </span>
            </div>
            <div style={{ opacity: 0.6 }}>
              UTR : <span>{partner?.utr_no}</span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Amount : <span>{partner.payout_amount}</span>
            </div>
            <div style={{ opacity: 0.6 }}>
              Type :{" "}
              <span>
                {partner.invoice_type == "credit" ? "Credit" : "Debit"}
              </span>
            </div>
          </>
        );
    }
  };

  return (
    <div className="clearfix">
      <div className="table-responsive">
        <table className="table table-bordered tablefontsize">
          <thead>
            <tr>
              <th>ID</th>
              <th>Partner Details</th>
              <th>Payment Details</th>
              <th>Dates</th>
              <th>Created by</th>
              <th>Note Issue</th>
              <th>CN/DN Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invoiceList.length > 0 ? (
              <>
                {invoiceList.map((partner, index) => (
                  <>
                    <tr key={`${index}`}>
                      <td>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Invoice ID :{" "}
                          <span>
                            {" "}
                            {partner.link_invoice_id > 0
                              ? partner.link_invoice_id
                              : ""}
                          </span>
                        </div>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Note ID : <span> {partner.id}</span>
                        </div>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Note Number : <span> {partner.invoice_no}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ opacity: 0.6 }}>
                          Partner Code : <span> AMK{partner.partner_id}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Partner Name : <span>{partner.partner_name}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ opacity: 0.6 }}>
                          Payable amount :{" "}
                          <span>{partner?.linked_invoice?.payable_amount}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          TDS : <span>{partner?.linked_invoice?.tds}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          GST : <span>{partner?.linked_invoice?.gst}</span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Payment :{" "}
                          <span>{partner?.linked_invoice?.payment}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ opacity: 0.6 }}>
                          Invoice Date :{" "}
                          <span>
                            {partner?.created_at
                              ? DateFormate(
                                  new Date(partner.created_at),
                                  "dS mmm yyyy"
                                )
                              : ""}
                          </span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Signed Date :{" "}
                          <span>
                            {partner?.approved_at
                              ? DateFormate(
                                  new Date(partner.approved_at),
                                  "dS mmm yyyy"
                                )
                              : ""}
                          </span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Payment Date :{" "}
                          <span>
                            {partner?.paid_date
                              ? DateFormate(
                                  new Date(partner.paid_date),
                                  "dS mmm yyyy"
                                )
                              : ""}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div style={{ opacity: 0.6 }}>
                          User name :{" "}
                          <span>
                            {(userlist.length &&
                              userlist.find(
                                (item) => item.value == partner.created_by
                              )?.label) ||
                              ""}
                          </span>
                        </div>
                        <div style={{ opacity: 0.6 }}>
                          Reason : <span>{partner.comment}</span>
                        </div>
                      </td>

                      <td>{renderNoteIssue(partner)}</td>
                      <td>
                        <p
                          style={{
                            color:
                              partner.invoice_approval == 1 ? "green" : "red",
                          }}
                        >
                          {partner.invoice_approval == 1
                            ? "Approved"
                            : "Approval Pending"}
                        </p>
                      </td>
                      <td>
                        <div style={{ display: "flex" }}>
                          <div className="btn-group btn-group-small mrg-r10">
                            <div
                              className="btn btn-default"
                              title="Mark as View"
                              onClick={() =>
                                handlePayoutListing(partner.id, index)
                              }
                            >
                              <i className="ic-remove_red_eyevisibility icons-normal"></i>
                            </div>
                          </div>
                          {[
                            "invoice_created",
                            "payment_pending",
                            "paid",
                          ].includes(filterData.status) && (
                            <div>
                              <a
                                target="_blank"
                                href={
                                  partner?.approved_invoice_url
                                    ? partner.approved_invoice_url
                                    : partner.pending_invoice_url
                                }
                              >
                                <div className="btn-group btn-group-small mrg-r10">
                                  <div className="btn btn-default" title="View">
                                    <i
                                      className="ic-pdf icons-normal"
                                      style={{
                                        fontSize: 16,
                                        color: "gray",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </a>
                            </div>
                          )}
                          {partner.paid_status == "eligible_note" && (
                            <div className="btn-group btn-group-small mrg-r10">
                              <div
                                class="btn btn-default"
                                title="Mark as Verified"
                                //   onClick={() => createNote(partner.id)}
                              >
                                <i class="ic-check icons-normal"></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>

                    {isPayoutTblVisible === index && (
                      <tr key={`${index}_payout`}>
                        <td colSpan={8} key={`${index}_payout`}>
                          <table className="table table-bordered tablefontsize">
                            <thead>
                              <tr>
                                <th
                                  className="payouttable"
                                  style={{
                                    fontSize: 20,
                                    cursor: "pointer",
                                  }}
                                >
                                  Lead
                                </th>
                                <th className="payouttable">Tranche</th>
                                <th className="payouttable">Loan</th>
                                <th className="payouttable">Name</th>
                                <th className="payouttable">Ful. Type</th>
                                <th className="payouttable">Product</th>
                                <th className="payouttable">Bank</th>
                                <th className="payouttable">Disb. amt</th>
                                <th className="payouttable">Disb. date</th>
                                <th className="payouttable">Payout</th>
                                <th className="payouttable">TDS</th>
                                <th className="payouttable">GST</th>
                                <th className="payouttable">PAT</th>
                                <th className="payouttable">
                                  {partner.invoice_type == "credit"
                                    ? "Credit"
                                    : "Debit"}{" "}
                                  amount
                                </th>
                                <th className="payouttable">Reason</th>
                              </tr>
                            </thead>
                            <tbody>
                              {payoutList.length > 0 ? (
                                <>
                                  {payoutList.map((detail, leadIndex) => (
                                    <>
                                      <tr key={`${leadIndex}_${index}`}>
                                        <td>
                                          {
                                            detail?.payouts?.partnerleads
                                              ?.lead_id
                                          }
                                        </td>
                                        <td>
                                          {
                                            detail?.payouts?.partnerleads
                                              ?.tranche_id
                                          }
                                        </td>
                                        <td>
                                          {
                                            detail?.payouts?.partnerleads
                                              ?.sanction_amount
                                          }
                                        </td>
                                        <td>
                                          {
                                            detail?.payouts?.partnerleads
                                              ?.customer_name
                                          }
                                        </td>
                                        <td>
                                          {
                                            detail?.payouts?.partnerleads
                                              ?.fulfillment_type
                                          }
                                        </td>
                                        <td>
                                          {detail?.payouts?.partnerleads
                                            ?.product_type == 1
                                            ? "HL"
                                            : "LAP"}
                                        </td>
                                        <td>
                                          {financers &&
                                            financers.length > 0 &&
                                            financers.find(
                                              (bank) =>
                                                bank.id ==
                                                detail?.payouts?.partnerleads
                                                  ?.financer_id
                                            )?.name}
                                        </td>
                                        <td>
                                          {
                                            detail?.payouts?.partnerleads
                                              ?.disbursal_amount
                                          }
                                        </td>
                                        <td>
                                          {DateFormate(
                                            new Date(
                                              detail?.payouts?.partnerleads?.disbursal_date
                                            ),
                                            "dS mmm yyyy"
                                          )}
                                        </td>
                                        <td>{detail?.payout_amount}</td>
                                        <td>{detail?.tds}</td>
                                        <td>{detail?.payouts?.gst_amount}</td>
                                        <td>{detail?.pat}</td>
                                        <td>
                                          {detail?.payouts?.payout_amount}
                                        </td>
                                        <td>
                                          <div class="tooltip">
                                            <div
                                              style={{ display: "flex" }}
                                              class="btn btn-default"
                                              title="Mark as Verified"
                                            >
                                              <i className="ic-info-icon1 icons-normal"></i>
                                            </div>
                                            {detail?.payouts?.comment && (
                                              <span class="tooltiptext">
                                                {detail.payouts.comment}
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <tr>
                                  <td colSpan={4}>No payout Found!</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
                <tr key={"pagination"}>
                  <td colSpan={5}>
                    <Pagination
                      pageCount={paginationData.totalpage}
                      activePage={filterData?.page_no || 1}
                      onPaginate={(page_no) =>
                        onPaginate(page_no, filterData.status)
                      }
                    />
                  </td>
                  <td>Total: {paginationData.totalrecords}</td>
                </tr>
              </>
            ) : (
              <tr key={"No data found"}>
                <td colSpan={3}>No Data Found!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CDCNTabInPartner;
