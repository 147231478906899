import React, { useState } from "react";
import DateFormate from "dateformat";
import { executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import { INVOICE_LISTING_DATA } from "../../services/payout.gql";
import { toast } from "react-toastify";
import Pagination from "../elements/pagination";

const PaymentPendingTabInPartner = ({
  invoiceList,
  filterData,
  financers,
  paginationData,
  onPaginate,
}) => {
  const [isPayoutTblVisible, setIsPayoutTblVisible] = useState(null);
  const [payoutList, setPayoutList] = useState([]);

  const getPayoutListing = async (partner_id) => {
    try {
      const response = await executeGraphQLMutation(
        INVOICE_LISTING_DATA(partner_id)
      );
      if (response.data?.invoice_payouts) {
        const invoices = response.data.invoice_payouts || [];
        setPayoutList(invoices);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePayoutListing = async (partnerid, index) => {
    if (isPayoutTblVisible === null) {
      await getPayoutListing(partnerid);
    }
    setIsPayoutTblVisible((prev) => (prev === index ? null : index));
  };

  return (
    <div className="clearfix">
      <div className="table-responsive">
        <table className="table table-bordered tablefontsize">
          <thead>
            <tr>
              <th>Inv. Id</th>
              <th>Partner Details</th>
              <th>Payout Amt</th>
              <th>Disb Date</th>

              {filterData.status === "invoice_created" ||
              filterData.status === "payment_pending" ? (
                <th>Bank Details</th>
              ) : filterData.status === "paid" ||
                filterData.status === "gst_payment_pending" ||
                filterData.status === "gst_paid" ? (
                <th>Payment Details</th>
              ) : null}

              {filterData.status === "gst_payment_pending" ||
              filterData.status === "gst_paid" ? (
                <th>Gst Amt </th>
              ) : null}

              {filterData.status === "gst_paid" ? (
                <th>GST payment details</th>
              ) : null}

              {filterData.status === "gst_payment_pending" ? (
                <th>Gst Flags </th>
              ) : null}

              <th>Leads</th>

              {["payment_pending", "paid"].includes(filterData.status) ? (
                <th>Invoice Status </th>
              ) : null}

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invoiceList.map((partner, index) => (
              <>
                <tr key={index}>
                  <td>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      {" "}
                      Invoice ID : <span>{partner.id}</span>
                    </div>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      Invoice no. : <span>{partner.invoice_no}</span>
                    </div>
                  </td>
                  <td>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      Partner Code : <span> AMK{partner.partner_id}</span>
                    </div>
                    <div style={{ opacity: 0.6 }}>
                      Partner Name : <span>{partner.partner_name}</span>
                    </div>
                    {partner?.parent_partner_id > 0 && (
                      <div style={{ opacity: 0.6 }}>
                        Parent Partner :{" "}
                        <span>
                          {partner.parent_partner_name} (
                          {partner.parent_partner_id})
                        </span>
                      </div>
                    )}
                    <div style={{ opacity: 0.6 }}>
                      GSTIN : <span>{partner.gst_number}</span>
                    </div>
                  </td>
                  <td>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      Total Amount:{partner.payout_amount}
                    </div>
                    <div style={{ opacity: 0.6 }}>
                      PAT Amount : {partner.pat}
                    </div>
                    <div style={{ opacity: 0.6 }}>
                      TDS Aount : {partner.tds}
                    </div>
                  </td>
                  <td>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      Acknowledgment Date:&nbsp;&nbsp;&nbsp;{" "}
                      {partner.created_at
                        ? DateFormate(
                            new Date(partner.created_at),
                            "dS mmm yyyy"
                          )
                        : null}
                    </div>
                    {partner.approved_at ? (
                      <div style={{ fontSize: 13, fontWeight: 600 }}>
                        Approved Date:
                        {partner.approved_at
                          ? DateFormate(
                              new Date(partner.approved_at),
                              "dS mmm yyyy"
                            )
                          : null}
                      </div>
                    ) : null}

                    {partner.paid_date ? (
                      <div style={{ fontSize: 13, fontWeight: 600 }}>
                        Paid Date:
                        {partner.paid_date
                          ? DateFormate(
                              new Date(partner.paid_date),
                              "dS mmm yyyy"
                            )
                          : null}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      A/C:{" "}
                      <span style={{ opacity: 0.6 }}>
                        {partner?.bankdetails?.bank_account_no}
                      </span>
                    </div>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      ISFC:
                      <span style={{ opacity: 0.6 }}>
                        {partner?.bankdetails?.bank_ifsc_code}
                      </span>
                    </div>
                    <div style={{ fontSize: 13, fontWeight: 600 }}>
                      beneficiary Name:
                      <span style={{ opacity: 0.6 }}>
                        {partner?.bankdetails?.beneficiary_name}
                      </span>
                    </div>
                  </td>
                  <td
                    onClick={() => handlePayoutListing(partner.id, index)}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {partner.total_lead}
                    </div>
                    <i
                      className="ic-keyboard_arrow_down"
                      style={{ fontSize: 24, marginLeft: -8 }}
                    ></i>
                  </td>
                  {["payment_pending", "paid"].includes(partner.paid_status) ? (
                    <td>
                      <p
                        style={{
                          color:
                            partner.invoice_approval == 1 ? "green" : "red",
                        }}
                      >
                        {partner.invoice_approval == 1
                          ? "Approved"
                          : "Approval Pending"}
                      </p>
                    </td>
                  ) : (
                    ""
                  )}

                  <td>
                    <div style={{ display: "flex" }}>
                      <div className="btn-group btn-group-small mrg-r10">
                        <div
                          className="btn btn-default"
                          title="Mark as View"
                          onClick={() => handlePayoutListing(partner.id, index)}
                        >
                          <i className="ic-remove_red_eyevisibility icons-normal"></i>
                        </div>
                      </div>
                      <div>
                        <a
                          target="_blank"
                          href={
                            partner.paid_status == "payment_pending"
                              ? partner.pending_invoice_url
                              : partner.approved_invoice_url
                          }
                        >
                          <div className="btn-group btn-group-small mrg-r10">
                            <div
                              className="btn btn-default"
                              title="Mark as View"
                            >
                              <i
                                className="ic-pdf icons-normal"
                                style={{
                                  fontSize: 16,
                                  color: "gray",
                                }}
                              ></i>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>

                {isPayoutTblVisible === index && (
                  <tr
                    style={{
                      backgroundColor: "rgb(245,245,245)",
                      paddingRight: "40px",
                      paddingLeft: "40px",
                    }}
                  >
                    <td colSpan={8}>
                      <div className="table-responsive">
                        <table className="table table-bordered tablefontsize">
                          <thead>
                            <tr>
                              <th className="payouttable">Lead Details</th>
                              <th className="payouttable">Scheme Details</th>
                              <th className="payouttable">Calc. Amount</th>
                              <th className="payouttable">Bank</th>
                              <th className="payouttable">Calc. Date</th>
                              <th className="payouttable">Payout%</th>
                              <th className="payouttable">Payout Details</th>
                              <th className="payouttable">PAT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payoutList.length > 0 ? (
                              <>
                                {payoutList.map((partnerlead, leadIndex) => (
                                  <tr key={`${leadIndex}_${index}`}>
                                    <td>
                                      <div>
                                        ID :{" "}
                                        <span>
                                          {
                                            partnerlead?.payouts?.partnerleads
                                              ?.lead_id
                                          }
                                        </span>
                                      </div>
                                      <div>
                                        Tranche :{" "}
                                        <span>
                                          {
                                            partnerlead?.payouts?.partnerleads
                                              ?.tranche_id
                                          }
                                        </span>
                                      </div>
                                      <div>
                                        App:{" "}
                                        <span>
                                          {
                                            partnerlead?.payouts?.partnerleads
                                              ?.app_id
                                          }
                                        </span>
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Partner Code:
                                        <span> AMK{partner.partner_id}</span>
                                      </div>
                                      <div
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Partner Name:
                                        {
                                          partnerlead?.payouts?.scheme
                                            ?.scheme_name
                                        }
                                      </div>
                                    </td>

                                    <td>
                                      {partnerlead?.payouts?.partnerleads
                                        ?.slab_amount
                                        ? partnerlead?.payouts?.partnerleads[
                                            partnerlead?.payouts?.partnerleads
                                              ?.slab_amount
                                          ]
                                        : ""}
                                      <div class="tooltip">
                                        <div
                                          style={{ display: "flex" }}
                                          class="btn btn-default"
                                        >
                                          <i className="ic-info-icon1 icons-normal"></i>
                                        </div>
                                        <div class="tooltiptext">
                                          {partnerlead?.payouts?.partnerleads
                                            ?.slab_date ? (
                                            <div>
                                              Slab Date :{" "}
                                              {partnerlead?.payouts?.partnerleads?.slab_date.replace(
                                                "_",
                                                " "
                                              )}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {partnerlead?.payouts?.partnerleads
                                            ?.slab_amount ? (
                                            <div>
                                              Slab Amount :{" "}
                                              {partnerlead?.payouts?.partnerleads?.slab_amount.replace(
                                                "_",
                                                " "
                                              )}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      {financers &&
                                        financers.length > 0 &&
                                        partnerlead.id &&
                                        financers.filter(
                                          (bank) =>
                                            bank.id ==
                                            partnerlead.payouts.partnerleads
                                              .financer_id
                                        )[0]?.name}
                                    </td>
                                    <td>
                                      {DateFormate(
                                        new Date(
                                          partnerlead.payouts.partnerleads.disbursal_date
                                        ),
                                        "dS mmm yyyy"
                                      )}
                                    </td>
                                    <td>
                                      <div>
                                        {" "}
                                        Payout:{" "}
                                        {partnerlead?.payouts?.payout_per}%
                                      </div>
                                      <div>
                                        {" "}
                                        Paid: {partnerlead?.payouts?.paid_per}%
                                      </div>
                                      {partnerlead?.utr_no ? (
                                        <div> UTR: {partnerlead?.utr_no}</div>
                                      ) : null}
                                      {partnerlead?.paid_date ? (
                                        <div>
                                          {" "}
                                          Paid Date:{" "}
                                          {partnerlead?.paid_date
                                            ? DateFormate(
                                                new Date(
                                                  partnerlead?.paid_date
                                                ),
                                                "dS mmm yyyy"
                                              )
                                            : ""}
                                        </div>
                                      ) : null}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Payout:
                                        {partnerlead?.payout_amount}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 600,
                                        }}
                                      >
                                        PAT amount:
                                        {partnerlead?.pat}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 600,
                                        }}
                                      >
                                        TDS amount:
                                        {partnerlead?.tds}
                                      </div>
                                    </td>
                                    <td>
                                      {partnerlead?.pat ??
                                        0 -
                                          (partnerlead?.pat ?? 0) -
                                          (partnerlead?.tds ?? 0)}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={4}>No payout Found!</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}

            {invoiceList.length > 0 ? (
              <tr key={"pagination"}>
                <td colSpan={6}>
                  <Pagination
                    pageCount={paginationData.totalpage}
                    activePage={filterData?.page_no || 1}
                    onPaginate={(page_no) =>
                      onPaginate(page_no, filterData.status)
                    }
                  />
                </td>
                <td>Total: {paginationData.totalrecords}</td>
              </tr>
            ) : (
              <tr>
                <td colSpan={6}>No Data Found!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentPendingTabInPartner;
