import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import { useSelector, useDispatch } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import FormsySelect from "../elements/FormsySelect";
import BankPOC from "./BankPOC";
import NewBankerPOC from "./NewBankerPOC";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { GET_BANKER_RECORD_BY_ID, SAVE_BANK_POC_MAPPING } from "../../services/bank.gql";
import { useRef } from "react";
import { UPDATE_PARTNER_ID } from "../../services/leads.gql";
import { PARTNER_DROPDOWN_LIST } from "../../services/leadCreate.gql";
import { IS_USER_ACCESS_PAGE } from '../../../src/config/constants';
import { getLoanDetail } from "../../store/action/allAction";

const BankerPOCMapping = ({ existingbankPOCMappingData, existingBankRecordData, modalType, handleParentModalClose }) => {
  const { masterdata, lead, leadDetail } = useSelector(({ masterdata, lead }) => ({ masterdata, lead, leadDetail: lead?.leadDetail?.id }));
  const dispatch = useDispatch();
  const client = useApolloClient();
  const parentDivRef = useRef(null);
  const [bankPOCFields, setBankPOCFields] = useState([]);
  const [createModalVisiblity, setCreateModalVisiblity] = useState(false);
  const [bankPOCMappingData, setBankPOCMappingData] = useState({ mapping_type: "bank" });
  const [bankPOCData, setBankPOCData] = useState([]);
  const [bankPOCFieldsCount, setBankPOCFieldsCount] = useState(0);
  const [partnerData, setPartnerData] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    return options;
  }, [masterdata]);

  const getPartnerData = () => {
    executeGraphQLQuery(PARTNER_DROPDOWN_LIST, client)
      .then((result) => {
        if (result?.data) {
          const jsonData = JSON.parse(result?.data?.partner_list_dropdown);
          if (jsonData?.status === 200) {
            const partnerList = jsonData?.data?.map((obj) => {
              return {
                id: obj.partnerId,
                label: `${obj.PartnerName || "N/A"} - ${obj.partnerId}`,
                value: obj.partnerId,
              };
            });

            setPartnerData(partnerList);
          }
        }
      })
      .catch((errors) => {
        toast.error("Something went wrong");
      });
  };

  const handleAddExistingBankPOC = (data) => {
    // skip first iteration, add by bankList
    for (let i = 1; i < data.length; i++) {
      handleAddBankPOC(i);
      ALL_OPTIONS[`filtered_banker_poc_${i}`] = ALL_OPTIONS[
        `filtered_banker_poc_${i - 1}`
      ]?.filter((obj) => obj.value !== data[i - 1].banker_id);
    }
  };

  useEffect(() => {
    if (existingbankPOCMappingData.length > 0) {
      const banker_id = existingbankPOCMappingData[0]?.banker_id;
      const filteredData = existingBankRecordData.filter(
        (obj) => obj.id === banker_id
      );

      getBankerPOCListById(filteredData[0]?.bank_id);
      handleAddExistingBankPOC(existingbankPOCMappingData);
      setBankPOCMappingData((current) => ({
        ...current,
        bank_name: filteredData[0]?.bank_id,
        bank_poc: existingbankPOCMappingData,
      }));
    }
  }, [existingbankPOCMappingData]);

  useEffect(() => {
    getPartnerData();
    const exPartnerId = lead?.leadDetail?.partner_id;
    setBankPOCMappingData((current) => ({
      ...current,
      partner_id: exPartnerId,
    }));
  }, []);

  useEffect(() => {
    ALL_OPTIONS["partner_id"] = partnerData;
  }, [partnerData]);

  const handleDivHeight = () => {
    const div = parentDivRef.current;
    let divHeight = "";
    let divOverflowY = "";
    if (div?.offsetHeight >= 500) {
      divHeight = "500px";
      divOverflowY = "scroll";
    }
    // if(!div?.style) {
    //   div.style = {
    //     height: divHeight,
    //     overflowY: divOverflowY
    //   }
    // } else {
    //   div.style.height = divHeight;
    //   div.style.overflowY = divOverflowY;
    // }
  };

  const handleAddBankPOC = (index, record = null) => {
    const bankRecordCount = record ? record : bankPOCData.length;
    if (index < bankRecordCount) {
      const isExisting = bankPOCFields.filter(
        (obj) => obj.id === `bank_poc_field_${index}`
      );
      if (
        isExisting.length === 0 ||
        bankPOCFields[bankPOCFields.length - 1]?.canAddMore
      ) {
        const field = { id: `bank_poc_field_${index}` };
        setBankPOCFieldsCount(index + 1);
        setBankPOCFields((current) => [...current, field]);
        handleDivHeight();
      }
    }
  };

  const handleRemoveBankPOC = (index) => {
    const exitingBankPOCField = [...bankPOCFields];
    const existingBankPOC = [...(bankPOCMappingData["bank_poc"] || [])];
    const removedEle = {
      ...existingBankPOC[index],
      status: "0",
      // banker_id: null,
    };
    exitingBankPOCField.splice(index, 1);
    if (exitingBankPOCField.length > 0) {
      exitingBankPOCField[exitingBankPOCField.length - 1].canAddMore = true;
    }
    existingBankPOC.splice(index, 1);
    existingBankPOC.push(removedEle);
    setBankPOCFieldsCount(bankPOCFieldsCount - 1);
    setBankPOCFields(exitingBankPOCField);
    setBankPOCMappingData((currentValue) => ({
      ...currentValue,
      bank_poc: existingBankPOC,
    }));
    handleDivHeight();
  };

  const getBankerPOCListById = (bank_id) => {
    executeGraphQLQuery(GET_BANKER_RECORD_BY_ID(bank_id), client)
      .then((result) => {
        if (result.data) {
          const record = result.data.get_banker_records_by_id || [];
          if (record.length) {
            handleAddBankPOC(0, record.length);
            setBankPOCData(record);
            ALL_OPTIONS["filtered_banker_poc_0"] = record.map((obj) => ({
              id: obj.id,
              label: obj.name,
              value: obj.id,
            }));
          } else {
            setBankPOCFields([]);
            setBankPOCFieldsCount(0);
          }
        } else {
          toast.error("Internal Server Error");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleCreatePOC = () => {
    setCreateModalVisiblity(true);
  };

  const handleModalClose = () => {
    setCreateModalVisiblity(false);
  };

  const handleCheckboxChange = (event) => {
    const { id, name } = event.target;
    setBankPOCMappingData((currentValue) => ({
      ...currentValue,
      [name]: id,
    }));
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      const selectedBank = existingBankRecordData?.filter(
        (bank) => data.value === bank.bank_id
      );
      setBankPOCFields([]);
      setBankPOCFieldsCount(0);
      getBankerPOCListById(data.value);
      setBankPOCMappingData((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      if (selectedBank.length > 1) {
        handleAddExistingBankPOC(selectedBank);
      }
    }
  };

  const handleSetBankPOCData = (data) => {
    setBankPOCMappingData((currentValue) => ({
      ...currentValue,
      bank_poc: data,
    }));
  };

  const handleBankPOCOptions = (data, index) => {
    if (data) {
      ALL_OPTIONS[`filtered_banker_poc_${index + 1}`] = ALL_OPTIONS[
        `filtered_banker_poc_${index}`
      ]?.filter((obj) => obj.value !== data.value);
    }
  };

  const handleSubmit = async (modalType) => {
    try {
      setSubmitDisabled(true);
      const lead_id = lead?.leadDetail?.id;
      const { bank_poc, partner_id } = bankPOCMappingData;

      if (modalType === "partner") {
        const response = await executeGraphQLMutation(
          UPDATE_PARTNER_ID(lead_id, partner_id),
          {},
          client
        );
        if (response.data?.update_partner_id === "Success") {
          dispatch(getLoanDetail(lead_id, client))                
          toast.success("Success");
          setSubmitDisabled(false);
          handleParentModalClose();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(response.data?.update_partner_id);
        }
      } else {
        const variables = {
          bank_mapping_list: {
            bank_mapping_list: bank_poc.reduce((acc, obj) => {
              acc.push({
                ...(obj.id && { id: obj.id }),
                lead_id,
                banker_id: obj.banker_id,
                status: obj.status,
              });
              return acc;
            }, []),
          },
        };

        const response = await executeGraphQLMutation(
          SAVE_BANK_POC_MAPPING,
          variables,
          client
        );
        if (response.data?.save_bank_poc_mapping === "Success") {
          dispatch(getLoanDetail(lead_id, client))                
          toast.success("Success");
          setSubmitDisabled(false);
          handleParentModalClose();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(response.data?.save_bank_poc_mapping);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  let edit_partner = IS_USER_ACCESS_PAGE("change_partner", "change_partner")?.is_edit_access;

  return (
    <>
      <div style={{display:"flex",justifyContent:"space-between"}}>
        <h2>Change Mapping</h2>
        <div className="addNewPoc" onClick={handleCreatePOC}>
                <span>+</span>Create new POC
              </div>
      </div>

      <div className="propertyflex">
        <input
          type="radio"
          id="bank"
          name="mapping_type"
          onChange={handleCheckboxChange}
          checked={bankPOCMappingData["mapping_type"] === "bank" ? true : false}
        />
        <label htmlFor="bank">Bank POC</label>

        { edit_partner == 1 && (
           <>
              <input
                type="radio"
                id="partner"
                name="mapping_type"
                onChange={handleCheckboxChange}
                checked={
                  bankPOCMappingData["mapping_type"] === "partner" ? true : false
                }
              />
              <label htmlFor="partner">Partner</label>
            </>
          )}
      </div>

      {bankPOCMappingData["mapping_type"] === "bank" && (
        <>
          <div
            ref={parentDivRef}
              // style={{
              //   display: "flex",
              //   justifyContent: "space-between",
              // }}
          >
            <div>
              <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => null}
                onInvalid={() => null}
              >
                <div className="row">
                  <fieldset className={"single-select col-md-6"} key={"bank_name"}>
                    <FormsySelect
                      name={"bank_name"}
                      id={"bank_name"}
                      inputProps={{
                        options: ALL_OPTIONS["bank_list"],
                        placeholder: "Select Bank",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS["bank_list"] &&
                          ALL_OPTIONS["bank_list"].filter(
                            ({ value }) =>
                              value == bankPOCMappingData["bank_name"]
                          ),
                        isDisabled: false,
                      }}
                      required={true}
                      value={
                        bankPOCMappingData && bankPOCMappingData["bank_name"]
                      }
                      onChange={(data) => handleSelectChange(data, "bank_name")}
                    />
                  </fieldset>
                
            
             
                </div>
                {bankPOCFields.map((field, index) => (
                <BankPOC
                  index={index}
                  ALL_OPTIONS={ALL_OPTIONS}
                  setBankPOCData={handleSetBankPOCData}
                  removeBankPOC={handleRemoveBankPOC}
                  setBankPOCOptions={handleBankPOCOptions}
                  bankPOCData={bankPOCMappingData["bank_poc"]}
                  bankerList={bankPOCData}
                  key={index}
                />
              ))}
              {bankPOCFields.length > 0 &&
                bankPOCMappingData["bank_poc"]?.[bankPOCFieldsCount - 1]
                  ?.banker_id && (
                  <div
                    className="addBankPoc"
                    onClick={() => handleAddBankPOC(bankPOCFieldsCount)}
                  >
                    <span>+</span>Add Bank POC
                  </div>
                )}
       
              </Formsy>

             
            </div>
          

         
          </div>
          <div className="btn-save-remarks" style={{ textAlign: "center",marginTop:20 }}>
            <button
              type="button"
              className="btn-primary"
              onClick={() => handleSubmit("bank")}
              disabled={submitDisabled}
            >
              Submit
            </button>
          </div>

          <div className="view-timeline-popup image-timeline-popup">
            <Modal show={createModalVisiblity} handleClose={handleModalClose}>
              <NewBankerPOC bankNameId={bankPOCMappingData["bank_name"]} />
            </Modal>
          </div>
        </>
      )}

      {bankPOCMappingData["mapping_type"] === "partner" && (
        <>
          <div className="pocflex"><h4 className="pocheading">Partner Mapping</h4></div>
          {/* <label htmlFor="partner">Partner</label> */}
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => null}
            onInvalid={() => null}
            onSubmit={() => handleSubmit("partner")}
          >
            <div className="row">
              <fieldset className={"single-select col-md-6"} key={"partner_id"}>
                <FormsySelect
                  name={"partner_id"}
                  id={"partner_id"}
                  inputProps={{
                    options: ALL_OPTIONS["partner_id"],
                    placeholder: "Select Partner",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value:
                      ALL_OPTIONS &&
                      ALL_OPTIONS["partner_id"] &&
                      ALL_OPTIONS["partner_id"].filter(
                        ({ value }) => value == bankPOCMappingData["partner_id"]
                      ),
                    isDisabled: false,
                  }}
                  required={true}
                  value={bankPOCMappingData && bankPOCMappingData["partner_id"]}
                  onChange={(data) => handleSelectChange(data, "partner_id")}
                />
              </fieldset>
            </div>
            <div className="btn-save-remarks" style={{ textAlign: "center",marginTop:20 }}>
              <button
                type="submit"
                className="btn-primary"
                disabled={submitDisabled}
              >
                update
              </button>
            </div>
          </Formsy>
        </>
      )}
    </>
  );
};
const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <button onClick={handleClose} className="close_icn">
          <i className="ic-clearclose"></i>
        </button>
      </section>
    </div>
  );
};
export default BankerPOCMapping;
