import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "../common/Header"
import Footer from '../common/Footer'
import LeadList from "../view/lead-list/LeadList";
import LeadDetail from "../view/leads/LeadDetail";
import Dashboard from "../view/dashboard/Dashboard"
import Login from "../view/login/Login"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BasicDetailsForm from '../view/leads/customer-details/BasicDetails';
import LoanDetails from '../view/leads/customer-details/LoanDetails';
import BankOfferForm from '../view/leads/bank-details/BankOffer';
import LoanDetailsForm from '../view/leads/bank-details/LoanDetailsForm';
import FollowUpHistoryForm from '../view/leads/bank-details/FollowUpHistory';
import IncomeDetailsForm from '../view/leads/customer-details/IncomeDetails';
import PropertyIdentified from '../view/leads/customer-details/PropertyIdentified';
import OfferDetails from '../view/leads/customer-details/OfferDetails'; 
import CA_BasicDetailsForm from '../view/leads/co-applicant-details/CA_BasicDetails';
import CA_LoanDetailsForm from '../view/leads/co-applicant-details/CA_LoanDetails';
import CA_PropertyIdentifiedForm from '../view/leads/co-applicant-details/CA_PropertyIdentified';
import DocumentUpload from '../view/leads/docs-upload/DocumentUpload';
import ApprovedForm from '../view/leads/ApprovalLead';
import DisbursedLead from '../view/leads/DisbursedLead';
import LoggedIn from '../view/leads/LoggedIn';
import CheckListForm from '../view/leads/CheckListForm';
import FollowUpsForm from '../view/leads/Followup';
import PrivateRoute from "./private-route";
import PayoutForm from '../view/leads/Payout';
import CollectionDetails from '../view/leads/Collection';
import TransactionForm from '../view/leads/Transaction';
import PDDForm from '../view/leads/PDD';
import ReportView from "../view/dashboard/ReportView";
import ManageRoleTab from '../view/manage-role/ManageRoleTab';
import Reference from '../view/leads/customer-details/Reference';
import SchemeListing from '../view/scheme/SchemeListing';
import AddScheme from '../view/scheme/AddScheme';
import PayoutListing from '../view/payout/PayoutListing';
import LeadwiseScheme from '../view/payout/LeadwiseScheme';
import BookingPoint from '../view/payout/BookingPoint';
import AddPayoutPoint from '../view/payout/AddPayoutPoint';
import BankOnboarding from '../view/payout/BankOnboarding';
import BankOnboardingWithPp from '../view/bank/BankOnboarding';
import AddBankPayoutPoint from '../view/bank/AddPayoutPoint';
import AddPartnerShip from '../view/bank/AddPartnerShip';
import MisList from '../view/payout/MisList';
import CibilReport from '../view/leads/customer-details/CibilReport';
import EmiCalculator from '../view/leads/EmiCalculator';
import Subvension from '../view/leads/Subvension';
import CrossSell from '../view/leads/CrossSell';
 import PartnerDashboard from '../view/partner/PartnerDashboard';
import SendFinancer from '../view/leads/SendFinancer';
 //import EmailComms from '../view/leads/comms/EmailComms';

import DealerList from '../view/partner/dealer-list';
import Register from '../view/partner/RegisterPartner';
import PreRegisterLeadManager from '../view/partner/pre-register-lead-manager/preRegisterLeadManager';
import BasicDetails from '../view/partner/component/BasicDetails';
import DealerDetail from '../view/partner/component/MyAccount';
import Account from '../view/partner/component/Account';
import LeadImportScreen from '../view/partner/pre-register-lead-manager/lead-import-export/leadImportScreen';
import PartnerLeadDetailsPage from '../view/partner/pre-register-lead-manager/partner-lead-dashboard/PartnerLeadDetailsPage';
import ProtectedRoute from './protected-route';
import secureStorage from '../config/partnerEncrypt';
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../config/constants';
import NoAccess from '../common/no-access';
import { ShareLead } from '../view/partner/pre-register-lead-manager/lead-share/leadShare';
import DocumentCollect from '../view/leads/customer-details/DocumentCollect';
import BreOfferCheck from '../view/leads/BreOffer/BreOfferCheck';
import BankSchemeListing from '../view/scheme/BankSchemeListing'
import BankScheme from '../view/scheme/BankScheme';
import BankSchemedetails from '../view/scheme/BankSchemedetails';
import BankPayoutRanges from '../view/scheme/BankPayoutRanges';
import InsuranceForm from '../view/insurance/Insurance';
import ReportingMonth from '../view/report/ReportingMonth'

import NotFound from '../common/not-found';
import APF from '../view/APF/apf';
import SupportPartnerListing from '../view/partner/component/SupportPartner/SupportPartnerListing';
import LeadUpdateStatusForm from '../view/leads/UpdateStatus/form';
import PayInMIS from '../view/leads/PayInMis';
import CallList from '../view/call-list/call-list';
import ImagePreview from '../view/elements/imagePreview';
import RuleListing from '../view/payout/RuleListing';
import AddRule from '../view/payout/AddRule';
import IncentiveList from '../view/payout/IncentiveList';

  import BREDashboard from '../view/BreBankOperation/bre_dashboard';

// import EmailThread from '../view/leads/EmailComms';

const MainRoute = () => {

  let leadDetailRoutes = (
    <>
      <Route path="/lead-detail/customer-details/basic-details/:lead_id" element={<BasicDetailsForm />} />
      <Route path="/lead-detail/customer-details/loan-details/:lead_id" element={<LoanDetails />} />
      <Route path="/lead-detail/bank-details/bank-offer/:lead_id" element={<BankOfferForm />} />
      <Route path="/lead-detail/bank-details/loan-full-details/:lead_id" element={<LoanDetailsForm />} />
      <Route path="/lead-detail/bank-details/follow-up-history/:lead_id" element={<FollowUpHistoryForm />} />
      <Route path="/lead-detail/bank-details/offer-check/:lead_id" element={<BreOfferCheck />} />
      <Route path="/lead-detail/customer-details/income-details/:lead_id" element={<IncomeDetailsForm />} />
      <Route path="/lead-detail/customer-details/property-identified/:lead_id" element={<PropertyIdentified />} />
      <Route path="/lead-detail/customer-details/offer-detail/:lead_id" element={<OfferDetails />} />
      <Route path="/lead-detail/co-applicant-details/basic-details/:lead_id" element={<CA_BasicDetailsForm />} />
      <Route path="/lead-detail/co-applicant-details/loan-details/:lead_id" element={<CA_LoanDetailsForm />} />
      <Route path='/lead-detail/co-applicant-details/property-identified/:lead_id' element={<CA_PropertyIdentifiedForm/>} PropertyIdentyfied/>
      <Route path='/lead-detail/new-lead/:lead_id' element={<LoanDetails/>} />
      <Route path="/lead-detail/contacted/:lead_id" element={<LoanDetails/>} />
      <Route path="/lead-detail/approved/:lead_id" element={<ApprovedForm />} />
      <Route path="/lead-detail/disbursed/:lead_id" element={<DisbursedLead />} />
      <Route path="/lead-detail/loggedin/:lead_id" element={<LoggedIn/>} />
      <Route path="/lead-detail/loggedin/credit-analysis-memorandum/:lead_id" element={<CheckListForm/>} />
      <Route path="/lead-detail/pdd/:lead_id" element={< PDDForm />} />
      <Route path="/lead-detail/transaction/:lead_id" element={< TransactionForm />} />
      <Route path="/lead-detail/docs-upload/:lead_id" element={< DocumentUpload />} />
      <Route path="/lead-detail/payout/:lead_id" element={<PayoutForm/>} />
      <Route path="/lead-detail/collection/:lead_id" element={<CollectionDetails/>}/>
      <Route path="/lead-detail/payin-mis/:lead_id" element={<PayInMIS/>}/>
      <Route path="/lead-detail/transaction/:lead_id" element={<TransactionForm/>} />
      <Route path="/lead-detail/docs-upload/:lead_id" element={<DocumentUpload />} />
      <Route path='/lead-detail/customer-details/documents/:lead_id' element={<DocumentUpload/>}/>
      <Route path='/lead-detail/customer-details/documents/:lead_id' element={< DocumentUpload />}/>      
      <Route path='/lead-detail/customer-details/reference/:lead_id' element={<Reference/>}/>
      {/* <Route path="/lead-detail/emi-calculator/:lead_id" element={< EmiCalculator />} /> */}
      <Route path="/lead-detail/customer-details/cibil-report/:lead_id" element={<CibilReport />} />
      <Route path="/lead-detail/emi-calculator/:lead_id" element={< EmiCalculator />} />  
      <Route path="/lead-detail/subvention-pf/:lead_id" element={<Subvension/>}/>
      <Route path="/lead-detail/insurance-cross-sell/:lead_id" element={<CrossSell/>}/>
      <Route path="/lead-detail/express-login/:lead_id" element={<SendFinancer />} />
      <Route path="/lead-detail/email/:lead_id" element={IS_USER_ACCESS_PAGE("email_communication", "email_communication")?.is_access_page ? <LoanDetails/> : ""} />
      <Route path="/lead-detail/whatsapp/:lead_id" element={<LoanDetails/>} /> 
      <Route path="/lead-detail/call/:lead_id" element={<LoanDetails/>} />
      <Route path="/lead-detail/bank-details/insurance/:lead_id" element={<InsuranceForm />} />
      <Route path="/lead-detail/update-status/:lead_id" element={<LeadUpdateStatusForm />} />
    </>
  );

  const [logg, setLogg] = useState(false);

  const partner_dashboard = IS_USER_ACCESS_PAGE("menu", "partner_dashboard")?.is_edit_access;
  const payout = IS_USER_ACCESS_PAGE("menu", "payouts")?.is_edit_access;
  const scheme_list =  IS_USER_ACCESS_PAGE("menu", "scheme_list")?.is_edit_access;
  const payout_list =  IS_USER_ACCESS_PAGE("menu", "payout_list")?.is_edit_access;
  const payout_point =  IS_USER_ACCESS_PAGE("menu", "payout_point")?.is_edit_access;
  const bank_onboarding =  IS_USER_ACCESS_PAGE("menu", "bank_onboarding")?.is_edit_access;
  const mis_list =  IS_USER_ACCESS_PAGE("menu", "mis_list")?.is_edit_access;
  const bank_scheme_list =  IS_USER_ACCESS_PAGE("menu", "bank_scheme_list")?.is_edit_access;
  const add_bank_scheme =  IS_USER_ACCESS_PAGE("menu", "add_bank_scheme")?.is_edit_access;
  const reporting_month = IS_USER_ACCESS_PAGE("reporting_month","reporting_month").is_edit_access;
  const exotel_call_logs = IS_USER_ACCESS_PAGE("exotel_call_logs", "exotel_call_logs")?.is_edit_access;
  const bre_operation=IS_USER_ACCESS_PAGE("bre_operation", "bre_operation")?.is_edit_access;
  useEffect(() => {
    const checklog = localStorage.getItem("loggedIn");
    if (checklog) {
      setLogg(true);
    }
  }, []);


  const parentCallback = () => {
    let checklog = localStorage.getItem("loggedIn");
    setLogg(true);
  }

  return (
    <BrowserRouter>
      <div> 
        {logg ? <Header /> : null}
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<Login parentCallback={parentCallback} />} />
          <Route path="/partner-dashboard" element={<ProtectedRoute component={PartnerDashboard} access={partner_dashboard} />} />
          <Route path="/partner-register" element={<ProtectedRoute component={Register} access={partner_dashboard} />} />
          <Route path="/dealer-list" element={<ProtectedRoute component={DealerList} access={partner_dashboard} />} />
          <Route path="/apf" element={<ProtectedRoute component={APF} access={partner_dashboard} />} />

          <Route path="/dealer/:dealer_id_hash/:source_type" element={<ProtectedRoute component={Account} access={partner_dashboard} />} />
          <Route path="/dealer" element={<ProtectedRoute component={Account} access={partner_dashboard} />} />
          <Route path="/pre-register-lead-manager" element={<ProtectedRoute component={PreRegisterLeadManager} access={partner_dashboard} />} />
          <Route path="/pre-register-lead-import" element={<ProtectedRoute component={LeadImportScreen} access={partner_dashboard} />} />
          <Route path="/partner-lead-detals/:id" element={<ProtectedRoute component={PartnerLeadDetailsPage} access={partner_dashboard} />} />
          <Route path="/partner-lead-share/:id" element={<ProtectedRoute component={ShareLead} access={partner_dashboard} />} />
          <Route path="/partner-support-listing" element={<ProtectedRoute component={SupportPartnerListing} access={partner_dashboard} />} />
          
          <Route path="/login" element={<Login parentCallback={parentCallback} />} />
          <Route path="/role-list" element={<ManageRoleTab/>} />
          <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
          <Route path="/lead-list" element={<PrivateRoute component={LeadList}/>} />
          <Route path="/lead-list/:fulfillment_type/:assign_to/:status" element={<PrivateRoute component={LeadList}/>} />
          <Route path="/lead-list/:fulfillment_type/:assign_to/:status/:pre_login_data/:searchtype/:followup_mode" element={<PrivateRoute component={LeadList} />} />
          <Route path="/lead-list/:fulfillment_type/:assign_to/:status/:searchtype/:searchtext" element={<PrivateRoute component={LeadList} />} />
          <Route path="/lead-list/:fulfillment_type/:assign_to/:status/:pre_login_data" element={<PrivateRoute component={LeadList}/>} />
          <Route path="/lead-detail" element={<PrivateRoute component={LeadDetail} />}>{leadDetailRoutes}</Route>
          <Route path="/lead-detail/docs-upload/:lead_id" element={<PrivateRoute component={DocumentUpload} />} />
	        <Route path="/report-view/:clicked_index" element={<PrivateRoute component={ReportView}/>} />

          <Route path="/schemes/list" element={<ProtectedRoute component={SchemeListing} access={scheme_list} />} />
          <Route path="/payout/rule-list" element={<ProtectedRoute component={RuleListing} access={scheme_list} />} />
          <Route path="/schemes/addscheme" element={<ProtectedRoute component={AddScheme} access={scheme_list} />} />
          <Route path="/payout/add-rule" element={<ProtectedRoute component={AddRule} access={scheme_list} />} />
          <Route path="/scheme/:scheme_id_hash/:tab" element={<ProtectedRoute component={AddScheme} access={scheme_list} />} />
          <Route path="/payout/list" element={<ProtectedRoute component={PayoutListing} access={payout_list} />} />
          <Route path="/payout/leadwisescheme" element={<ProtectedRoute component={LeadwiseScheme} access={payout_list} />} />
          <Route path="/payout/points" element={<ProtectedRoute component={BookingPoint} access={payout_point} />} />
          <Route path="/payout/addpayoutpoint" element={<ProtectedRoute component={AddPayoutPoint} access={payout_point} />} />
          <Route path="/payout/edit/:id" element={<ProtectedRoute component={AddPayoutPoint} access={payout_point} />} />
          <Route path="/payout/bank-onboarding" element={<ProtectedRoute component={BankOnboarding} access={bank_onboarding} />} /> 
          <Route path="/payout/mis-list" element={<ProtectedRoute component={MisList} access={mis_list} />} />
          <Route path="/payout/incentive-list" element={<ProtectedRoute component={IncentiveList} access={mis_list} />} />
          <Route path="/report/reporting-month" element={<ProtectedRoute component={ReportingMonth} access={reporting_month} />} />
          <Route path="bank/bank-onboarding" element={<ProtectedRoute component={BankOnboardingWithPp} access={bank_onboarding} />} />
          <Route path="/bank/addpayoutpoint" element={<ProtectedRoute component={AddBankPayoutPoint} access={bank_onboarding} />} />
          <Route path="/bank/addpayoutpoint/:id" element={<ProtectedRoute component={AddBankPayoutPoint} access={bank_onboarding} />} />
          <Route path="/bank/addpartnership" element={<ProtectedRoute component={AddPartnerShip} access={bank_onboarding} />} />
          <Route path="/bank/addpartnership" element={<ProtectedRoute component={AddPartnerShip} access={bank_onboarding} />} />
           
          <Route path="/no-access" element={<PrivateRoute component={NoAccess}/>} />
          
          <Route path="/schemes/banklist" element={<ProtectedRoute component={BankSchemeListing} access={bank_scheme_list} />} />
          <Route path="/bankscheme/:scheme_id_hash/:tab" element={<ProtectedRoute component={BankScheme} access={add_bank_scheme} />} />
          <Route path="/bankscheme/addscheme" element={<ProtectedRoute component={BankScheme} access={add_bank_scheme} />} />
          <Route path="/customer-zone/:lead_id/:user" element={<DocumentCollect />} />
          <Route path="/call-list" element={<ProtectedRoute component={CallList} access={exotel_call_logs}/>} />
          <Route path="/bre-dashboard" element={<ProtectedRoute component={BREDashboard} access={bre_operation}/>} />
         
          <Route path="/preview-image" element={<ImagePreview />} />          <Route path="*" element={<NotFound />} />
         

        </Routes>
       {logg ?<Footer /> :null }
      </div> 
    </BrowserRouter>
  )
}

export default MainRoute;
