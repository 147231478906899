import React, { useEffect, useState } from "react";
import Modal from "../../../elements/partnerModal";
import FosHistoryPopup from "./fos-history-popup";
import { MasterService } from "../../../../services";

const FosComponent = ({ fosDealerList }) => {
  const [showHistory, setShowHistory] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [taskIDArray, setTaskIDArray] = useState([]);
  const [selectedFosData, setSelectedFosData] = useState([]);

  useEffect(() => {
    const newTaskIDs = fosDealerList.map((data) => (data?.task_id > 0 ? data.task_id : 0));
    setTaskIDArray(newTaskIDs);
  }, [fosDealerList]);

  useEffect(() => {
    const fetchTaskList = async () => {
      if (taskIDArray.length === 0) return;
      try {
        const response = await MasterService.post("/sfa/task_management/getTaskList", { task_id: taskIDArray });
        setSelectedFosData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching task list:", error);
      }
    };

    fetchTaskList();
  }, [taskIDArray]);

  const openModal = (taskId) => {
    setShowHistory(true);
    document.body.classList.add("overflow-hidden");
    setSelectedTaskId(taskId);
  };

  const closeModal = () => {
    setShowHistory(false);
    document.body.classList.remove("overflow-hidden");
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered tablefontsize">
          <thead>
            <tr>
              <th>Lead Details</th>
              <th>Type</th>
              <th>Source/ Sub-Source</th>
              <th>Call Status/Sub-Status</th>
              <th>Latest Field Visit</th>
              <th>Transfer Details</th>
              <th>Current Assigned Yodha</th>
              <th>City</th>
              <th>Onboarding BM</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {fosDealerList.map((data, index) => {
              const fosData = selectedFosData.find((item) => item.id == data.task_id);
              
              return (
                <tr key={index}>
                  <td>
                    {data.first_name || ""} {data.last_name || ""} <br />
                    {data.agent_code} <br />
                    {data.dealership_contact}
                  </td>
                  <td>{data?.status === "10" || data?.status === "11" ? "Partner Lead" : "Registered Partner"}</td>
                  <td>
                    {data.source} <br />
                    {data.subsource}
                  </td>
                  <td>
                    {data.status_name} <br />
                    {data.sub_status_name}
                  </td>
                  <td>
                    <div className="statusflexlist" style={{ marginTop: "10px", display: "flex" }}>
                      {fosData?.eta_from_date ? new Date(fosData.eta_from_date).toLocaleString() : "N/A"}
                    </div>
                    <div>
                      {fosData ? (
                        <span>
                          {fosData.status == 1 ? <span style={{color:"green"}}>Done</span> : fosData.status == 2 ? <span style={{color:"red"}}>Overdue</span> : "Upcoming"}
                        </span>
                      ) : (
                        <span style={{color:"yellow"}}>Upcoming</span>
                      )}
                    </div>
                  </td>
                  <td>
                    {new Date(data.updated_date).toLocaleString()} <br />
                    From: {data.caller_name} &nbsp; ID: {data.yoddha_code}
                  </td>
                  <td>
                    Name: {fosData?.name || "N/A"} <br />
                    ID: {fosData?.user_id || "N/A"}
                  </td>
                  <td>{data.field_visit_city_name || "N/A"}</td>
                  <td>
                    {data.onboarding_bm_name} <br />
                    {data.onboarding_bm_mobile}
                  </td>
                  <td>
                    <strong>Created Date:</strong> {new Date(data.created_date).toLocaleString()} <br />
                    <strong>Updated Date:</strong> {new Date(data.updated_date).toLocaleString()}
                  </td>
                  <td>
                    <div className="commoniconbtn" style={{ width: 30 }} onClick={() => openModal(data.task_id)}>
                      <i className="ic-Call-history icons-normal"></i>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {showHistory && (
        <div className="mark-lost-popup">
          <Modal show={showHistory} handleClose={closeModal} width={1200}>
            <FosHistoryPopup id={selectedTaskId} />
          </Modal>
        </div>
      )}
    </>
  );
};

export default FosComponent;
