import React, { useEffect, useRef, useState } from "react";
import {
  GET_TICKETS,
  MARK_TICKET_SEEN,
} from "../../../services/threads.gql";
import { 
  executeGraphQLQuery,
  executeGraphQLMutation,
} from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { CKEditor } from "ckeditor4-react";
import { CHAT_TEMPLATE_LIST } from "../../../services/leads.gql";
import attachmentsicon from "./../../../webroot/images/attachment-icon.svg";
import docdummyimg from "./../../../webroot/images/docdummyimg.png";
import FormsySelect from "../../elements/FormsySelect";
import Formsy from "formsy-react";
import bin from "./../../../webroot/images/Bin.svg";
import crosssvg from "./../../../webroot/images/crosssvg.svg";
import replyimg from "./../../../webroot/images/replysvg.svg";
import backsvg from "./../../../webroot/images/back.svg";
import EmptyFillSomething from "./../../../webroot/images/EmptyFillSomething.svg";
import FormsyInputField from "../../elements/FormsyInputField";
import { SAVE_CUST_DETAILS } from "../../../services/customer.gql";
import {gatewayApiInstance} from "../../../common/axiosInstance";
import { useNavigate } from "react-router-dom";
import crypto from '../../../config/crypto';


const EmailThread = (props) => {
  let navigate = useNavigate();

  const client = useApolloClient();
  const [tickets, setTickets] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsOriginal, setTagsOriginal] = useState([]);
  const [sendMailDisabled, setSendMailDisabled] = useState(true);
  const [modalName, setModalName] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [replyIndex,setReplyIndex] = useState(0);
  const [defaultConversation, setDefaultConversation] = useState([]);
  const [infoEmail,setInfoEmail] = useState ({email: ''});
  const [emailDetails, setEmailDetails] = useState({
    emailFrom: props?.formType === 'customer' ? "communication@ambak.com" : "fulfilment@ambak.com",
    configEmailId: props?.formType === 'customer' ? 1 : 2,
    emailDescription: "",
  });
  const [notSeenId,setNotSeenId] = useState([]);
  const [tabEmailData, setTabEmailData] = useState(null);
  const [list, setList] = useState([]);
  const [inputField, setField] = useState({ chat_type: 'TEXT', mode_type: 1, message: '', files: '' });
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [loanDetailData, setLoanDetailData] = useState({ compose_option: null });
  const [showComposePopup, setShowComposePopup] = useState(false);
  const [attachDocPopup, setAttachDocPopup] = useState(false);
  const [showReplyEmail, setShowReplyEmail] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [showCcInput, setShowCcInput] = useState(false);
  const [ccTags, setCcTags] = useState([]);
  const [checkedBox, setCheckedBox] = useState([]);
  
  const { lead, userInfo, leadFiles } = useSelector(({ lead, user }) =>
      ({ 
        lead,
        leadFiles: lead && lead.leadDetail && lead.leadDetail.leaddocs || [],
        userInfo: (user.user_information) ? JSON.parse(user.user_information) : {} 
      }));

  let user_info = JSON.parse(localStorage.getItem("user_information"));
  const editorRef = useRef(null);

  const handleAttachDoc = () =>{
    setAttachDocPopup(!attachDocPopup)
  }  

  const fetchData = (lead_id, ticket_id = 0, returnRes = false, tab) => {
    let res = null;
      executeGraphQLQuery(GET_TICKETS(lead_id, ticket_id, tab), client)
        .then((response) => {
          if(response?.data?.get_email_tickets?.length>0){
            setTickets(response?.data?.get_email_tickets);
            const filteredData = response?.data?.get_email_tickets.filter((ticket) => {
              // Ensure the ticket has a reply array and it contains at least one reply
              if (ticket?.reply?.length > 0) {
                  return ticket.reply.some(reply => !reply?.seen_at || reply.seen_at.length === 0);
              }
              return false;
            }).map((item)=>{
              return item.id
            });
            setNotSeenId(filteredData);
          }else{
            setTickets([]);
          }
        })
        .catch((error) => { });
    if (returnRes) return res
  };


  const handleRemoveAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  useEffect(() => {
    if (lead?.leadDetail?.id) {
      fetchData(lead.leadDetail.id,0,false,props.activeTab);
    }
  }, [lead]);

  useEffect(() => {
    getTemplateList(lead?.leadDetail?.id, userInfo?.name, "", userInfo?.id, "false", "true");
  }, [lead.leadDetail?.id]);


  useEffect(() => {
    if (props?.emailForComs) {
      let emailIdTags = [...tags]
      if (props?.emailForComs) {
        const propsEmailForComs = props?.emailForComs.split(",")
        if (propsEmailForComs.length) {
          emailIdTags = [...emailIdTags, ...propsEmailForComs];
        }
      }
      if (emailIdTags.length) {
        setEmailTags(emailIdTags, true);
      }
    }
  }, [props?.emailForComs]);


  const getTemplateList = (lead_id, username, tempelate_type, user_id, is_whatsapp, is_email) => {
    executeGraphQLQuery(CHAT_TEMPLATE_LIST(lead_id, username, tempelate_type, user_id, is_whatsapp, is_email), client).then((result) => {
      setList(result?.data?.chat_template_list);
    }).catch((error) => { });
  };

  const setEmailTags = (emailIds, setOriginal = false) => {
    if (typeof emailIds === 'string' && emailIds.length) {
      emailIds = emailIds.split(",");
    }
    if (typeof emailIds === 'object' && Array.isArray(emailIds) && emailIds.length) {
      const emailIdsUnq = [...new Set(emailIds)];
      setTags([...new Set(emailIdsUnq)]);
      if (setOriginal) {
        setTagsOriginal([...new Set(emailIdsUnq)]);
      }
    }
  }

  const handleTabClick = async (ticketId, ticketCreatedAt, ticketSubject,index=0) => {
    setReplyIndex(index);
    setEmailDetails((current) => ({
      ...current,
      emailDescription: "",
      emailSubject: "",
    }));
    setAttachments([]);
    setCheckedBox([]);
    if(notSeenId.includes(tickets?.[index]?.id)){
      const filterData = notSeenId.filter((item)=>(
        item !== tickets?.[index]?.id
      ));
      setNotSeenId(filterData);
      await executeGraphQLQuery(MARK_TICKET_SEEN(tickets?.[index]?.ticket_id), client);
    }
    setTabEmailData({
      to_emails: tickets?.[index]?.toEmails,
      cc_emails: tickets?.[index]?.ccEmails || [], 
      bcc_emails: tickets?.[index]?.bccEmails || [], 
      subject: tickets?.[index]?.ticket_subject,
      body_text_initial: tickets?.[index]?.ticket_initial_body?.replace(/<p>/g, '')
      ?.replace(/<\/p>/g, '<br />')
      ?.trim(),//.replace(/<(?:.|\n)*?>/gm, ''),
      attachments: [],
    })
    setDefaultConversation([{
      to_emails: tickets?.[index]?.ticket_initial_to_emails,
      cc_emails: tickets?.[index]?.ccEmails || [], 
      bcc_emails: tickets?.[index]?.bccEmails || [], 
      body_text_initial: tickets?.[index]?.ticket_initial_body?.replace(/<p>/g, '')
      ?.replace(/<\/p>/g, '<br />')
      ?.trim(),//.replace(/<(?:.|\n)*?>/gm, ''),
      attachments: [],
      reply: tickets?.[index]?.reply,
      created_date: tickets?.[index]?.created_date
    }])
    setAttachDocPopup(false);
    setShowDetailedView(true);
    setShowComposePopup(false);
    setSelectedEmailId(ticketId);
    setShowCcInput(false);
    setShowCcInput(false);
    setCcTags([]);
  };

  const handleCheckedBox =(e,id)=>{
    if(e.target.checked){
      setCheckedBox([...checkedBox,id])
    }else{
      setCheckedBox(checkedBox.filter((item) => item !== id))
    }
  }


  const handleSubmit = async () => {
    if (!emailDetails?.emailSubject && showReplyEmail !== true ) {
      toast.error("Email Subject is missing!!");
      return;
    }
    let confirm = window.confirm(
      "Are you sure you want to send this E-Mail from the official Ambak ID?"
    );
    if (confirm) {
      const emailIdsUnq = [...new Set([...tags])]; 
      const emailIdscc = [...new Set([...ccTags])]; 
      const [toEmail, ...ccEmail] = emailIdsUnq;
      if (showReplyEmail === true) {
        let formData = new FormData();
        formData.append('body',crypto.encode(emailDetails.emailDescription));
        if (attachments) {
          for (let file of attachments) {
            formData.append("attachments[]", file);
          }
        }
        for (let cc of tags) {
          formData.append("cc_emails[]", cc);
        }
        formData.append("email", toEmail);    
        for (let cc of emailIdsUnq) {
          formData.append("to_emails[]", cc);
        }
        for (let addcc of emailIdscc){
          formData.append("cc_emails[]", addcc);
        }
        formData.append("ticket_id", tickets?.[replyIndex]?.ticket_id);
        formData.append("lead_id", lead?.leadDetail?.id);
        formData.append("email_from",emailDetails?.emailFrom)

        gatewayApiInstance.post(`/api/emailcomms/replyTicket`,formData)
        .then((res) => {
          toast.success("Mail sent");
          setTimeout(async () => {
            hideModalEmail();
            handleTabClick(tickets?.[replyIndex]?.ticket_id, "", "", replyIndex)
            fetchData(lead.leadDetail.id, 0, false, props.activeTab);
          }, 1000);
        })
        .catch((error) => console.error("ERROR : ", error.message));  
      } else {

        const emailIdsUnq = [...new Set([...tags])]; 
        const emailIdscc = [...new Set([...ccTags])]; 
        const [toEmail, ...ccEmail] = emailIdsUnq;

        const descriptionFreshDesk = emailDetails.emailDescription + `<!DOCTYPE html> 
            <html> 
            <head> </head> 
            <body> 
              <div dir='ltr' class='gmail_signature'> 
                <div dir='ltr'> 
                  <table style='color:rgb(0,0,0);font-family:Poppins,sans-serif;font-size:medium'> 
                    <tbody> 
                      <tr> 
                        <td style='font-size:12px'>
                          <span style='font-weight:600'>${user_info.name} |&nbsp;</span>
                          <font color='#0000ff'>Team Ambak</font>
                        </td> 
                      </tr> 
                      <tr style='display:flex;margin-top:5px'> 
                        <td style='margin-top:5px;display:flex'>
                          <a href='https://ambak.com/' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://ambak.com/&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw0tVwaNZz23ZIBv946jMbP4'>
                            <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4yr2uCiizuiiSDXWwyUkGzq5kxyZyUukGNxRoJo9ssMOkiCu3O1wlYMu0pF9YxSTGFrMxMISv0' alt='faviconicon' width='45px' height='66px' style='margin-right:10px;border-right:1px solid lightgray;padding-right:10px' class='CToWUd' data-bit='iit'>
                          </a>
                        </td> 
                        <td>
                          <div>
                            <a href='https://www.facebook.com/AmbakOfficial/' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.facebook.com/AmbakOfficial/&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw3-Qy2iDLy5mtTskTWBMYTF'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4xSv-h7oO1Ed44E9gjFnC_GcBGYaZ-87Rac2ozCexb62wxrM0IZfOC0Vh0mIwFgFCXTelcyAg8' alt='facebook' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.instagram.com/ambakofficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.instagram.com/ambakofficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw3O7fttVJo-lYNNmAnuknyZ'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4wh17UsbHaAdKESK_aDsw9mxSBCjYAoaCqNMbR7fWqAZIbuVNe9V2T1xseYmx18VGGsZ5jlh7U' alt='insta' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.linkedin.com/company/ambakofficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.linkedin.com/company/ambakofficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw2b2Fh1sb0gkUYkbu9RAv9s'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4wzbTmMt3ZRcaopcWFz5H9_lEXPXx7Ytl1stQ-49mCG_88MgScIFqZsDA63BUYh9Kk-VLutvAE' alt='link' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>&nbsp;
                            <a href='https://www.youtube.com/@AmbakOfficial' style='color:rgb(17,85,204)' rel='noreferrer' target='_blank' data-saferedirecturl='https://www.google.com/url?q=https://www.youtube.com/@AmbakOfficial&amp;source=gmail&amp;ust=1722318248056000&amp;usg=AOvVaw1Z5_-JRGGpkgiZkhpJZU5n'>
                              <img src='https://ci3.googleusercontent.com/mail-sig/AIorK4we4kDPB3fXLe3NusrE8z94bZyUpd0If6qwcu6gGtv9A7-WZrQKURPQbptNFo-Zke_V51QZ03s' alt='link' width='20px' height='20px' class='CToWUd' data-bit='iit'>
                            </a>
                          </div> 
                        </td> 
                      </tr> 
                    </tbody> 
                  </table> 
                  <div style='text-align:left'><br></div> 
                </div> 
              </div> 
            </body> 
            </html>`;

        let formData = new FormData();
        formData.append("description", crypto.encode(descriptionFreshDesk));
        formData.append("subject", emailDetails.emailSubject);
        formData.append("email", toEmail);
        formData.append("email_config_id", 1070000078051);
        formData.append("priority", 1);
        formData.append("lead_id", lead?.leadDetail?.id);
        formData.append("status", 2);
        formData.append("email_to", props.activeTab==="customer" ? "customer": "bank");
        if (attachments && attachments?.length > 0) {
          for (let file of attachments) {
            formData.append("attachments[]", file);
          }
        }
  
        for (let cc of emailIdsUnq) {
          formData.append("to_emails[]", cc);
        }
        for (let addcc of emailIdscc){
          formData.append("cc_emails[]", addcc);
        }

        const data = leadFiles.filter((file) => {
          if(checkedBox.includes(file?.id)) return file;
        });

        data.forEach(element => {
          formData.append('urls[]', element.doc_path);
        });

        data.forEach(element => {
          let parentDoc = lead?.documentList?.get_document_list?.find(v => v?.id === element?.parent_doc_id);
          let childDoc = parentDoc?.child?.find(v => v?.id === element?.doc_id);
          let parentName = parentDoc?.doclang?.name || "";
          let childName = childDoc?.doclang?.name || "";
          let filename = (parentName || childName) ? `${parentName} ${childName}`.trim() : "Untitled";
          formData.append('filename[]', filename);
        });

        gatewayApiInstance.post(`/api/emailcomms/sendEmail`,formData)
          .then(async (res) => {
            const newTicket = {
              lead_id: lead?.leadDetail?.id,
              ticket_id: res.data?.id,
            };
            toast.success("Ticket created");
            setAttachDocPopup(false)
            setTimeout(async () => {
              hideModalEmail();
              fetchData(lead.leadDetail.id,0,false, props.activeTab);
              handleTabClick(res.data.id, res.data.created_at, res.data.subject, replyIndex)
            }, 1000);
          })
          .catch((error) => console.error("ERROR : ", error.message));
      }
    }
  };


  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const { emailDescription, emailSubject } = emailDetails;

    setEmailDetails((current) => ({
      ...current,
      [id]: value,
    }));

    if (
      emailDescription != "" &&
      emailDescription !==
      undefined /* && (emailSubject != "" && emailSubject !== undefined) */
    ) {
      setSendMailDisabled(false);
    } else {
      setSendMailDisabled(true);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleKeyUp = (e, fieldType) => {
    if (e.key === "Enter") {
      if (validateEmail(emailDetails[fieldType])) {
        if (fieldType === "emailTo") {
          setEmailTags([...tags, emailDetails[fieldType]]);
        } else if (fieldType === "emailCc") {
          setCcTags([...ccTags, emailDetails[fieldType]]);
        } 
        setEmailDetails((current) => ({
          ...current,
          [fieldType]: "",
        }));
      } else {
        // alert('Invalid email address');
      }
    }
  };

  const removeTag = (indexToRemove, tagType) => {
    if (tagType === "emailTo") {
      setTags(tags.filter((_, index) => index !== indexToRemove)); 
    } else if (tagType === "emailCc") {
      setCcTags(ccTags.filter((_, index) => index !== indexToRemove)); 
    } 
  };

  const toggleCcInput = () => {
    setShowCcInput(true)
  }

  const showModalEmail = (e) => {
    const message = e ? "emailadd" : "emailreply";
    let id;
    if (e?.target) {
      ({ id } = e.target);
    }
    if (tagsOriginal.length) {
      setTags([...new Set(tagsOriginal)]);
    }
    (id === "emailadd" || message === "emailadd") ? setModalName("add") : setModalName("reply");
    setShowComposePopup(true);
    setShowDetailedView(false);
    setShowReplyEmail(false)

    document.body.classList.add("overflow-hidden");
  };

  useEffect(() => {
    if (inputField?.message) {
      editorRef?.current?.setData(inputField.message);

      showModalEmail({ target: { id: "emailadd" } });
      setEmailDetails((current) => ({
        ...current,
        emailDescription: inputField?.message,
      }));
    }
  }, [inputField?.message]);


  const hideModalEmail = () => {
    setEmailDetails({
      emailFrom: props?.formType === 'customer' ? "communication@ambak.com" : "fulfilment@ambak.com",
      configEmailId: props?.formType === 'customer' ? 1 : 2,
      emailDescription: "",
    })
    setField({ chat_type: 'TEXT', mode_type: 1, message: '', files: '' })
    editorRef?.current?.setData("");
    document.body.classList.remove("overflow-hidden");
    handleCloseReply();
  };

  const handleAttachment = async (event) => {
    const newFiles = Array.from(event.target.files);
  
    if (newFiles.length > 0) {
      setAttachments((prevAttachments) => {
        const updatedAttachments = [
          ...prevAttachments,
          ...newFiles.filter(
            (file) => !prevAttachments.some((prevFile) => prevFile.name === file.name)
          ),
        ];
        return updatedAttachments;
      });
  
    }
  };
  



  const resetAttachment = () => {
    setCheckedBox([]);
    setAttachments([]);
  };

  const hideTemplate = () => {
    document.body.classList.add("overflow-hidden");
  };

  const handleTabClick1 = (tab) => {
    props.setActiveTab(tab);
    fetchData(lead?.leadDetail?.id, 0, false, tab);
  };



  const handleCloseCompose = () => { setShowComposePopup(false); setAttachDocPopup(false); };
  const handleCloseReply = () => { setShowReplyEmail(false) };
  const handleCloseAttach = () => { setAttachDocPopup(false) };
  const handleReplyClick = () => { setShowReplyEmail(true) };
  const handleBackClick = () => { setShowDetailedView(false) };
  const setTemplate = (value) => {
    hideTemplate()
    setShowComposePopup(true);
    setShowDetailedView(false);
    setShowReplyEmail(false)
    setField({ ...inputField, message: value })
  }
  const formType1 = props?.formType || "customer";
  //  -----------------------------------------------end
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;

  const changeDetail = (event) => {
    setInfoEmail(
      { ...infoEmail, email: event.target.value }
    );
  };

  const handleEmailAdd = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let first_name = lead?.leadDetail?.customer?.first_name || "";
    let last_name = lead?.leadDetail?.customer?.last_name || "";
    let mobile = lead?.leadDetail?.customer?.mobile || "";
    let emailToSave = infoEmail.email || null;
    if (!emailToSave) {
      toast.error("Email is required!");
      return;
    }
    let reqBody = {
      first_name: first_name,
      last_name: last_name,
      mobile:mobile,
      lead_id: lead_id,
      email: emailToSave,
    };
    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          customer: reqBody,
        },
      }; 
    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_additional_info[0] || null;
        if (response && response.lead_details) {
          toast.success("Email saved successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        else {
          throw new Error(response.error || "Something went w rong");
        }
        setInfoEmail({ email: emailToSave });
      })
      .catch((err) => {
        toast.error(err.message || "An error occurred while saving the email.");
      });
  };
  
  return (
    <>
      <div className="emailpopup_conatiner">
        {/* {props?.emailForComs && ( */}
        <div className="left_container">
          <div className="emailstickycont">
            <div className="Compose_email">
              <div className="emailinbox">
                Inbox
              </div>
              <div className="formsyinEMAIL">
                <Formsy onSubmit={(formData) => console.log(formData)}>
                  <fieldset className="select-template">
                    <FormsySelect
                      name="compose_option"
                      id="emailadd"
                      inputProps={{
                        options: [
                          { value: "new_compose", label: "New Compose" },
                          ...list.map((ob) => ({
                            value: ob.template,
                            label: ob.name,
                          })),
                        ],
                        placeholder: loanDetailData["compose_option"] ? " " : "Select a template",
                        className: "react-select select-radius",
                        classNamePrefix: "react-select ",
                        value: [
                          { value: "new_compose", label: "New Compose" },
                          ...list.map((ob) => ({
                            value: ob.template,
                            label: ob.name,
                            subject: ob?.subject,
                          })),
                        ].find(({ value }) => value === loanDetailData["compose_option"]),
                        isDisabled: false,
                      }}
                      required={false}
                      value={loanDetailData["compose_option"]}
                      onChange={(data) => {
                        if (data.value === "new_compose") {
                          // Handle logic for "New Compose"
                          showModalEmail({ target: { id: "emailadd" } });
                          hideModalEmail();
                          document.body.classList.add("overflow-hidden");

                        } else {
                          setTemplate("");
                          const selectedTemplate = list.find((ob) => ob.template === data.value);
                          showModalEmail({ target: { id: "emailadd" } });

                          setTimeout(() => {
                            setTemplate(selectedTemplate?.template || "");
                            setEmailDetails((current) => ({
                              ...current,
                              emailSubject: selectedTemplate?.subject || "",
                            }))
                          }, 1000);
                        }
                        if(data.label === "Sanction"){
                          const data = leadFiles?.filter((item) => item?.parent_doc_id === 114 && item?.doc_id === 43).map((item)=>item?.id)
                          setCheckedBox([ ...checkedBox, ...data]);
                        }else{
                          setCheckedBox([]);
                        }
                        loanDetailData["compose_option"] = data.value;
                      }}
                    />
                  </fieldset>


                </Formsy>

              </div>

            </div>

            <div className="email_tabs">
              <div
                className={`email_tab ${props.activeTab === "customer" ? "active" : ""}`}
                onClick={() => handleTabClick1("customer")}
              >
                Customer
              </div>
              <div
                className={`email_tab ${props.activeTab === "bank" ? "active" : ""}`}
                onClick={() => handleTabClick1("bank")}
              >
                Banker
              </div>
            </div>
          </div>

          <div className="Recieve_Email_scroller">

            {

            tickets.map((ticket, index) => (
              <div className={`Email_textContainer ${selectedEmailId === ticket.id ? 'selected' : ''
                }`}
                onClick={() => handleTabClick(ticket.id, ticket.created_at, ticket.subject,index)}

                key={index}>
                  
                <div className="dummyimgcont">
                {notSeenId.includes(ticket.id) &&
                    <>
                      <div className="ping"></div>
                    </>
                  }
                  <img src={docdummyimg} alt="icon" width={50} height={50} />
                </div>
                
                <div className="message_container">

                  <div className="name_cont">
                    <div> {lead?.leadDetail?.customer?.first_name + ' ' + lead?.leadDetail?.customer?.last_name}</div>
                    <div className="timediv">  {dateFormat(ticket?.created_date, "d mmm,yyyy hh:MM")}</div>
                  </div>
                  <div className="leaddoc_contain">
                    <div>

                      <div className="Lead_text">{ticket.ticket_subject}</div>
                      <div className="mess_text">
                        Ticket Id :- {ticket.id || ""}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            ))}

          </div>
        </div>
        {props?.emailForComs && (

          <div className="right_main_container">
            {/* -----------------------------------reply popup------------------------------ */}
            {showReplyEmail ? (
              <div className="Reply_main_container">
                <div className="cross_conatienr">
                  <div className="Reply_cont">
                    <img src={replyimg} alt="icon" width={17.6} height={15} />
                    Reply
                  </div>
                  <div className="Cursor_Pointer" onClick={handleCloseReply}>
                    <img src={crosssvg} alt="icon" width={22} height={22} />
                  </div>
                </div>
                <div className="to_cont">
                  <div className="allupperinputs3">
                    <div className="addmailinputs">
                      <ul>
                        {tags.map((tag, index) => (
                          <li key={index}>
                            {tag} <i onClick={() => removeTag(index)}>X</i>
                          </li>
                        ))}
                        <input
                          type="text"
                          id="emailTo"
                          value={emailDetails.emailTo || ""}
                          onChange={handleInputChange}
                          onKeyUp={handleKeyUp}
                          required={true}
                        />
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="full_input_container">
                  <CKEditor
                    initData={emailDetails?.emailDescription}
                    onInstanceReady={(event) => {
                      editorRef.current = event.editor;
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      setEmailDetails((current) => ({
                        ...current,
                        emailDescription: data,
                      }));
                      setSendMailDisabled(!data);
                    }}
                    config={{
                      allowedContent: true,
                      extraAllowedContent: '*(*);*{*}',
                      extraPlugins: 'autogrow',
                      versionCheck: false,
                      toolbar: [
                        ['Source', '-', 'Bold', 'Italic', 'Underline', 'RemoveFormat'],
                        ['Link', 'Unlink', 'Anchor'],
                        ['Table', 'HorizontalRule'],
                        ['Styles', 'Format', 'Font', 'FontSize'],
                        ['TextColor', 'BGColor'],
                      ],
                      autoGrow_minHeight: 200,
                      autoGrow_maxHeight: 270,
                      autoGrow_onStartup: true,
                    }}
                  />
                </div>
                <div className="Send_cont">
                  <div className="send_btn">
                    <button onClick={handleSubmit} disabled={sendMailDisabled}>Send</button>
                  </div>
                  {/* {selectedFileCount && (
                    <div className="attechedfillcount">
                      <p>Count : {selectedFileCount}</p>
                      <div className="crossicon" onClick={resetAttachment}>
                        X
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            ) : (
              " "
            )}
       
            {/* ---------------------------new composepopup------------------------------------------------- */}
            {showComposePopup &&
              <div className="NewCompose_main_container">

                <div className="cross_conatienr">
                  <div>New Message</div>
                  <div onClick={handleCloseCompose} className="Cursor_Pointer">
                    <img src={crosssvg} alt="icon" width={22} height={22} />
                  </div>
                </div>
                <div className="to_cont">
                  To:
                  <div className="allupperinputs3">
                    <div className="addmailinputs">
                      <ul>
                        {tags.map((tag, index) => (
                          <li key={index}>
                            {tag} <i onClick={() => removeTag(index, "emailTo")}>X</i>
                          </li>
                        ))}

                        <input
                          type="text"
                          id="emailTo"
                          value={emailDetails.emailTo || ""}
                          onChange={(e) => handleInputChange(e, "emailTo")}
                          onKeyUp={(e) => handleKeyUp(e, "emailTo")}
                          required
                        />

                      </ul>
                    </div>
                  </div>
                  <div onClick={toggleCcInput} className="Cc_cont"> <strong>Cc</strong></div>

                </div>
                {showCcInput && (
                  <div className="to_cont">
                    Cc:
                    <div className="addmailinputs">
                      <ul>
                        {ccTags.map((tag, index) => (
                          <li key={index} className="email_tag">
                            {tag} <i onClick={() => removeTag(index, "emailCc")}>X</i>
                          </li>
                        ))}

                        <input
                          type="text"
                          id="emailCc"
                          value={emailDetails.emailCc || ""}
                          onChange={(e) => handleInputChange(e, "emailCc")}
                          onKeyUp={(e) => handleKeyUp(e, "emailCc")}
                        />
                      </ul>
                    </div>
                  </div>
                )}
                <div className="to_cont">
                  Subject :
                  <input
                    className="subject_input"
                    type="text"
                    id="emailSubject"
                    value={(modalName === "reply" ? (tabEmailData?.ticket_subject || tabEmailData?.subject) : (emailDetails.emailSubject || ""))}
                    onChange={handleInputChange}
                    required={true}
                    readOnly={!!(modalName === "reply")}
                  />
                </div>
                <div className="full_input_container">
                  <CKEditor
                    initData={emailDetails?.emailDescription}
                    onInstanceReady={(event) => {
                      editorRef.current = event.editor;
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      setEmailDetails((current) => ({
                        ...current,
                        emailDescription: data,
                      }));
                      setSendMailDisabled(!data);
                    }}
                    config={{
                      allowedContent: true,
                      extraAllowedContent: '*(*);*{*}',
                      extraPlugins: 'autogrow',
                      versionCheck: false,
                      toolbar: [
                        ['Source', '-', 'Bold', 'Italic', 'Underline', 'RemoveFormat'],
                        ['Link', 'Unlink', 'Anchor'],
                        ['Table', 'HorizontalRule'],
                        ['Styles', 'Format', 'Font', 'FontSize'],
                        ['TextColor', 'BGColor'],
                      ],
                      autoGrow_minHeight: 200,
                      autoGrow_maxHeight: 360,
                      autoGrow_onStartup: true,
                    }}
                  />
                </div>
                <div className="Send_cont">
                  <div className="send_btn">
                  <button onClick={handleSubmit} disabled={sendMailDisabled}>
                      Send</button>
                  </div>
                  <div className="attchDoc_cont" >
                    <div className="attach_doc" onClick={handleAttachDoc}>
                      <img src={attachmentsicon}  alt="icon" width={20} height={17.7} /> Attach Doc
                    </div>
                    <img onClick={()=>{setCheckedBox([]); handleCloseAttach()}} src={bin} alt="icon" width={32} height={32} />
                  </div>
                </div>
              </div>
            }

{/* ---------------------------Document Upload------------------------------------------------- */}

          {attachDocPopup &&
          <>
              <div className="attachdoccontainer">

                  <div className="cross_conatienr">
                    <div>Attach Document <span>{(attachments?.length + checkedBox?.length)>0 ? ((attachments?.length + checkedBox?.length) + " Selected") : ""} </span></div>
                    <div className="attachdone" onClick={handleCloseAttach}>
                    <div>Done</div>
                    <div className="Cursor_Pointer">
                      <img src={crosssvg} alt="icon" width={22} height={22} />
                    </div>
                    </div>
                  </div> 
                  {attachments?.length > 0 && Object.entries(attachments).map(([key, value],index) => {
                    return <>
                    <div style={{display:"flex",margin:"20px 30px", justifyContent:"space-between",width:"400px"}}>
                      <p>{value.name}</p>
                      <div className="Cursor_Pointer" onClick={() => handleRemoveAttachment(key)}>
                        <img src={crosssvg} alt="icon" width={22} height={22} />
                      </div>
                    </div>
                    </>; 
                  })}

                  <div className="attachpadding">
                      <div style={{marginBottom:20}}>
                        <label className="attachdocupload" 
                        onClick={() => { navigate(`/lead-detail/docs-upload/${crypto.encode(lead?.leadDetail?.id)}`); props.emailCommsPopup()}}>
                          + Upload More Document
                        </label>
                        </div>

                        <div className="docheight">
                        <div className="upload-document-view-bx" key={leadFiles.length}>
                  </div>
                        <div>
                          <ul className="doclisting">
                          {leadFiles?.map((file, index) => (
                            <li key={index} className="doccard">
                              <div>{file.parent_doc_id && lead?.documentList?.get_document_list?.filter(v => v?.id === file?.parent_doc_id)?.map(v => v?.doclang?.name)[0] || <div style={{marginTop:"20px"}}></div>}</div>
                              <div>
                                  {file?.parent_doc_id &&
                                    lead?.documentList?.get_document_list
                                      ?.filter(v => v?.id === file?.parent_doc_id)?.[0]
                                      ?.child?.filter(v => v?.id === file?.doc_id)
                                      ?.map(v => v?.doclang?.name)?.[0] || ''}
                                </div>
                              {
                                (file.ext === 'pdf' || file.ext === 'PDF') ?
                                    <a href={file.doc_path} target="_blank" rel="noopener noreferrer">
                                        <i className="ic-pdf-file icons-normal"></i>
                                    </a> : <img src={file.doc_path} alt="images doc_id here" className="" style={{ "maxHeight": "135px" }} />
                              }
                              {(file && file.doc_id && file.doc_status == '2') ? <span className="image-status">Approved</span> : (file && file.doc_id && file.doc_status == '0') ? <span className="image-requested">Rejected</span> : ''}
                              <div className="docselect">
                                <input type="checkbox" checked={checkedBox.includes(file.id)} onChange={(e) => {handleCheckedBox(e,file.id)}} />
                              </div>
                            </li>
                          ))}
                          </ul>
                          
                        </div>

                        </div>


                  </div>
              </div>
            </>
            }
            {showDetailedView ? (
              (tabEmailData?.ticket_subject || tabEmailData?.subject) && (
                <div>
                  <div className="mainhead_cont">
                    <div onClick={handleBackClick} className="backsvglogo">
                      <img src={backsvg} alt="icon" width={20} height={20} />
                    </div>
                  </div>
                  {[...defaultConversation].map((con, index) => (
                    <div className="right_inbox_cont_main" key={con.id || index}>
                      <div className="right_inbox_cont">
                        <div className="profil_cont_main">
                          <div className="profil_cont">
                            <div>
                              <img src={docdummyimg} alt="icon" width={50} height={50} />
                            </div>
                            <div className="mail_user_info">
                              <div className="mail_usernam">
                                {lead.leadDetail?.customer?.first_name + ' ' + lead.leadDetail?.customer?.last_name}
                              </div>
                              <div className="mess_text">
                                <span>
                                  {con.to_emails}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="timediv1">
                            {dateFormat(con?.created_date, "d mmm, yyyy hh:MM")}
                          </div>
                        </div>
                        <div>
                          <p className="mail_body">
                            {con?.body_text_initial ? (
                              <h3 className="emailcommondetails" dangerouslySetInnerHTML={{ __html: con.body_text_initial }} />
                            ) : (
                              <h3 className="emailcommondetails">
                                {con?.body_text || "No Content Available"}
                              </h3>
                            )}
                          </p>
                        </div>
                        {con?.reply?.length > 0 && con?.reply?.map((rep, index) => (
                          <>
                          <div style={{border:"1px solid #e4e4e4", marginBottom:"20px"}}></div>
                          <div className="profil_cont_main">
                          <div className="profil_cont">
                            <div>
                              <img src={docdummyimg} alt="icon" width={50} height={50} />
                            </div>
                            <div className="mail_user_info">
                              
                              <div className="mail_usernam">
                                <span>
                                  {rep.email_from}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="timediv1">
                            {dateFormat(rep?.created_date, "d mmm, yyyy hh:MM")}
                          </div>
                        </div>
                           <p className="mail_body">
                           {rep?.ticket_reply_body ? (
                             <h3 className="emailcommondetails" dangerouslySetInnerHTML={{ __html: rep.ticket_reply_body }} />
                           ) : (
                             <h3 className="emailcommondetails">
                               {con?.body_text || "No Content Available"}
                             </h3>
                           )}
                         </p>
                         </>
                        ))}
                      </div>

                      <div className="reply_maincont">
                        <button id="emailreply" onClick={handleReplyClick}>
                          <img src={replyimg} alt="icon" width={11.6} height={10} />
                          Reply
                        </button>
                      </div>

                    </div>
                  ))}
                </div>
              )
            ) : (
              " "
            )}

            {tickets.length === 0 && props?.emailForComs && (
              <>
                <div className="create_tickectDIV">
                  <h3 className="emailheadingticket1">
                    <span>➢</span>
                    {props?.formType === 'customer' ? "Please create a ticket to begin conversation with the customer" : "Please create a ticket to begin conversation with the bankers"}
                  </h3>
                </div>
              </>
            )}

          </div>
        )}

        {!props?.emailForComs && (
          <div className="addemail_bank_div">
            {/* <img src={EmptyFillSomething} alt="icon" width={332} height={136} /> */}

            <h3 className="emailheadingticket2" >
              <div className="empatyemailcard">

              {props.formType === 'customer' && (
                <>
                  <div className="emailheadingempty">No Email Address added for the customer.</div>
                  <Formsy 
                  className="lead-form-filed" 
                  autoComplete="off" 
                  onValidSubmit={handleEmailAdd} 
                  style={{marginBottom:20}} >
                        <fieldset className="form-filed col-md-8">
                        <FormsyInputField
                          id="email"
                          name="email"
                          type="text"
                          value={infoEmail.email}
                          placeholder=" "
                          label="Add Email ID"
                          onChange={changeDetail}
                          format={true}
                          readOnly={false}
                          required={true}
                          validations={{
                            isEmail: true
                          }}
                          validationErrors={{
                            isEmail: 'Please enter a valid email address.'
                          }}
                        />
                      </fieldset>
                      <button className="form-filed col-md-4 btnconfirm"> Confirm</button>
                  </Formsy>
                </>
              )}

              {props.formType === 'bank_poc' && (
                <>
                  <img src={EmptyFillSomething} alt="icon" width={332} height={136} />
                  <div style={{marginTop:20,fontSize:12}}>Please add the bank POC along with the banker's email for this lead, enabling communication with them.</div>
                </>
              )}
            </div>
            </h3>
          </div>
        )}

      </div>
    </>
  );
};

export default EmailThread;
