import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, LOGGED_QUERY_ID } from '../../../src/config/constants';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import crypto from '../../config/crypto';
import { LEADS_HISTORY_QUERY, ROLLBACK, UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { getLoanDetail } from '../../store/action/allAction';
import BankOfferForm from '../../view/leads/bank-details/BankOffer';
import FollowUpHistoryForm from '../../view/leads/bank-details/FollowUpHistory';
import LeadHistory from '../lead-list/LeadHistory';
import DocumentUpload from '../leads/docs-upload/DocumentUpload';
import dangericon from "./../../webroot/images/dangericon.png";
import LoanDetailsForm from './bank-details/LoanDetailsForm';
import EmiCalculator from './EmiCalculator';
import { useOutletContext } from 'react-router-dom';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import LostModal from './LostModal';
import CibilReport from './customer-details/CibilReport';
import LoggedinPopup from '../elements/LoggedinPopup';
import WhatsAppTemplate from '../chat/WhatsAppTemplate';
import BreOfferCheck from './BreOffer/BreOfferCheck';
import InsuranceForm from '../insurance/Insurance';
import StatusStep from './StatusStep';
import LeadCommuniationEmailModal from './emailComponent/EmailComms.modal';
import LeadStatusService from "../../services/leadStatus";
import Emailpopup from '../elements/Emailpopup';


const POST_LOGIN_DOC_ID = 114;

const LeadDetailTabs = (props) => {
    const {LOGIN,NEW_LEAD,CALL_BACK,SANCTIONED,DISBURSED,LOST,INTERESTED_QUALIFIED,INTERESTED_CONTACTED,NOT_INTERESTED_CONTACTED} = LeadStatusService.LEAD_STATUS_MASTER;

    const { toggleTip, toggleTip1, toggleTip2, toggleTip3,t,isLoading, } = props;
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { leadDetail, userInfo } = useSelector(({ lead, user }) => {
        return {
            leadDetail: lead.leadDetail,
            userInfo: user.user_information,
        }
    });
    let userDetail = userInfo && JSON.parse(userInfo)
    let user_id = userDetail && userDetail.id || 0;    

    const [leadStatusHistory, setLeadStatusHistory] = useState([]);
    const [rejectionType,setRejection] = useState('')
    const [tab_status, setTabStatus] = useState([]);
    const [MarkAsLost, setMarkAsLost] = useState(false);
    const [reopenModal, setReopenModal] = useState(false);
    const [rollbackModal, setRollbackModal] = useState(false);
    const [defaultActiveTab, setDefaultActiveTab] = useState(""); 
    const [remarkText, setRemarkText] = useState("");
    const client = useApolloClient();
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information)
    const docCategoryList = props.docCategoryList || [];
    const exDocCategoryList = props.exDocCategoryList || [];
    const docOtherCategoryList = props.docOtherCategoryList || [];
    let [saveChecklistData, setSaveChecklistData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [historyList, setHistoryList] = useState([]);
    const [showhistorymodal, setShowhistorymodal] = useState(false);
    const [isviewHistory, setIsviewHistory] = useState("");
    const [followUphistoryList, setFollowUphistoryList] = useState([]);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [isEmailComms, setEmailComms] = useState(false);
    const [unreadMsgCountWA, setUnreadMsgCountWA] = useState("");
    

    const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || []
      }));

    


    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;

    if (accessCondition !== 1) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

  // Context
  const getContext = useOutletContext();

    const handleMarkAsFreezed = () => {
        let currentStatus = leadDetail?.status_id;
        let disbursement_done = leadDetail?.disbursement_done || '0';
        // let currentSubStatus = leadDetail?.sub_status_id;
        // let currentDocStatus = leadDetail?.doc_status;
        return ([5].includes(currentStatus)) ? true : false;
    }
    const preLoginDocs = docOtherCategoryList.filter(item => item.id === POST_LOGIN_DOC_ID);
    const mergedList = [...(docCategoryList.get_document_list ? docCategoryList.get_document_list : []), ...preLoginDocs];
    const contextList = { docList: mergedList, markAsFreezed: handleMarkAsFreezed() } 
    const location = useLocation();

    useEffect(()=>{
        if(location?.state?.open){
            setEmailComms(true) 
        }
    },[location?.state])

    let STATUS_ID = 0;  
    if(location.pathname.includes('new-lead')){
        STATUS_ID = 1
        if(lead?.leadDetail?.status_id === 6) {
            STATUS_ID = lead.leadDetail.status_id
        }
    }else if(location.pathname.includes('loggedin')){
        STATUS_ID = 2
    }else  if(location.pathname.includes('approved')){
        STATUS_ID = 3
    }  

    const current_url_main = window.location.href;
    let accessConditionLost = !IS_USER_ACCESS_PAGE("loanapplication", "mark_as_lost").is_edit_access;
    let accessConditionReopen = !IS_USER_ACCESS_PAGE("loanapplication", "re_open").is_edit_access;
    let accessConditionRollback = !IS_USER_ACCESS_PAGE("loanapplication", "rollback").is_edit_access;
    let loanDetailAccess = IS_USER_ACCESS_PAGE("loan_detail", "loan_detail")?.is_edit_access;
    let followUpAccess = IS_USER_ACCESS_PAGE("follow_up", "follow_up")?.is_edit_access;

    let payInAccess = IS_USER_ACCESS_PAGE("disbursal_payin", "disbursal_payin")?.is_edit_access;
    let payOutAccess = IS_USER_ACCESS_PAGE("disbursal_payout", "disbursal_payout")?.is_edit_access;

    let bankOfferAccess = IS_USER_ACCESS_PAGE("bank_offers", "bank_offers")?.is_edit_access;
    let followupHistoryAccess = IS_USER_ACCESS_PAGE("follow_up_history", "follow_up_history")?.is_edit_access; 
    let callHistroyAccess = IS_USER_ACCESS_PAGE("call_history", "call_history")?.is_edit_access; 
    let emiCalAccess = IS_USER_ACCESS_PAGE("emi_calculator", "emi_calculator")?.is_edit_access; 
    let lead_subvension = IS_USER_ACCESS_PAGE("lead_subvension", "lead_subvension")?.is_edit_access;
    let cibilAccess = IS_USER_ACCESS_PAGE("cibil", "cibil")?.is_edit_access; 
    let email_communication = IS_USER_ACCESS_PAGE("email_communication", "email_communication")?.is_edit_access;
    let whats_app = IS_USER_ACCESS_PAGE("whats_app", "whats_app")?.is_edit_access;

    const payinMisAccess = IS_USER_ACCESS_PAGE("payout", "payin_mis")?.is_edit_access
    const collectionAccess = IS_USER_ACCESS_PAGE("payout","collection")?.is_edit_access
    const payoutNewAccess = IS_USER_ACCESS_PAGE("payout", "payout_view")?.is_edit_access





    if (!accessConditionLost) {
        accessConditionLost = CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }
    if (!accessConditionReopen) {
        accessConditionReopen = CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }
    if (!accessConditionRollback) {
        accessConditionRollback = CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }

    useEffect(() => {
        let updatedStatusList = leadDetail?.lead_status_history || [];
        let customer_details = leadDetail?.customer || [];
        let lead_details = leadDetail?.lead_details || {};
        let co_applicant = leadDetail?.co_applicant?.length && leadDetail?.co_applicant[0] || {};
        let splitpayment = leadDetail?.splitpayment?.length && leadDetail?.splitpayment[0] || {};
        // Remove checklist method as no use here | AMB-66
        getLostReasonList();
        if (leadDetail?.checklists && leadDetail?.checklists?.length) setSaveChecklistData(leadDetail.checklists);
        updatedStatusList = updatedStatusList?.map(data => data.status_id);
        let tabstatus = {
            customer_details: {
                is_completed: true,
                is_active: true,
                basic_details: {
                    is_completed: (
                        customer_details?.first_name && 
                        customer_details?.last_name && 
                        customer_details?.gender &&
                        customer_details?.dob &&
                        customer_details?.mobile &&
                        customer_details?.email &&
                        customer_details?.aadhar_no &&
                        customer_details?.pancard_no &&
                        customer_details?.cra_pincode &&
                        customer_details?.cra_state &&
                        customer_details?.cra_city &&
                        customer_details?.cra_address1 &&
                        customer_details?.cra_address2
                        ) ? true : false,
                    is_active: true
                },
                loan_details: {
                    is_completed: (
                        leadDetail?.fulfillment_type && 
                        lead_details?.tenure && 
                        leadDetail?.loan_type &&
                        +lead_details?.loan_amount
                        ) ? true : false,
                    is_active: true
                },
                property_identified: {
                    is_completed: (
                        lead_details?.is_property_identified &&
                        lead_details?.property_value &&
                        lead_details?.property_type &&
                        lead_details?.usage_type &&
                        lead_details?.agreement_type &&
                        lead_details?.property_pincode &&
                        lead_details.property_state &&
                        lead_details.property_city &&
                        lead_details?.property_address1 &&
                        lead_details?.property_address2 
                        )? true :false,
                    is_active: true
                },
                additional_details: {
                    is_completed: true,
                    is_active: true
                },
                employment_details: {
                    is_completed: true,
                    is_active: true
                },
                residential_details: {
                    is_completed: true,
                    is_active: true
                },
                bank_details: {
                    is_completed: true,
                    is_active: true
                },
                character_reference: {
                    is_completed: true,
                    is_active: true
                }
            },
            coapplicant_details: {
                is_completed: true,
                is_active: true,
                co_applicant_customer_details: {
                    is_completed: (
                        co_applicant?.ca_type &&
                        co_applicant?.relationship_with_customer &&
                        co_applicant?.ca_first_name &&
                        co_applicant?.ca_last_name &&
                        co_applicant?.ca_gender &&
                        co_applicant?.ca_dob &&
                        co_applicant?.ca_mobile &&
                        co_applicant?.ca_email &&
                        co_applicant?.ca_aadhar_no &&
                        co_applicant?.ca_pancard_no
                        ) ? true : false,
                    is_active: true
                },
                co_applicant_income_details: {
                    is_completed: (
                        co_applicant?.ca_annual_income &&
                        co_applicant?.ca_profession &&
                        co_applicant?.ca_salary_credit_mode &&
                        co_applicant?.ca_company_name &&
                        co_applicant?.ca_existing_emi &&
                        co_applicant?.ca_no_of_emi &&
                        co_applicant?.ca_existing_emi_amount
                        ) ? true : false,
                    is_active: true
                },
                primary_income_details:{
                    is_completed: (
                        lead_details?.annual_income &&
                        lead_details?.profession &&
                        lead_details?.salary_credit_mode &&
                        lead_details?.company_name &&
                        lead_details?.existing_emi &&
                        lead_details?.no_of_emi &&
                        lead_details?.existing_emi_amount
                        ) ? true : false,
                    is_active: true
                },
                additional_details: {
                    is_completed: false,
                    is_active: true
                },
                employment_details: {
                    is_completed: false,
                    is_active: true
                },
                bank_details: {
                    is_completed: false,
                    is_active: true
                },
                residential_details: {
                    is_completed: false,
                    is_active: true
                },
                character_reference: {
                    is_completed: false,
                    is_active: true
                }
            },
            contacted: {
                is_active: ([NEW_LEAD].filter(status_id => updatedStatusList.includes(status_id)).length >= 1 && lead_details.is_calling === 'yes') ? true : false,
                is_completed: ([6].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? true : false
            },
            logged_in: {
                is_active: ([LOGIN].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? true : false,
                is_completed: ([LOGIN].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? true : false
            },
            approved: {
                is_active: ([NEW_LEAD, LOGIN].filter(status_id => updatedStatusList.includes(status_id)).length > 2) ? true : false,
                is_completed: ([SANCTIONED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? true : false
            },
            disbursal: {
                is_active: ([NEW_LEAD, LOGIN, SANCTIONED].filter(status_id => updatedStatusList.includes(status_id)).length > 3) ? true : false,
                is_completed: ([DISBURSED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? true : false
            },
            transation: {
                is_active: ([DISBURSED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1 && splitpayment?.is_tranch_verified === '1') ? true : false,
                is_completed: (splitpayment?.transaction_id) ? true : false
            },
            pdd: {
                is_active: ([DISBURSED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1 && splitpayment?.is_tranch_verified === '1') ? true : false,
                is_completed: (splitpayment?.pdd_status) ? true : false
            },
            payout: {
                is_active: ([DISBURSED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1 && splitpayment?.is_tranch_verified === '1') ? true : false,
                is_completed: (splitpayment?.pdd_status) ? true : false
            },
            payin: {
                is_active: ([DISBURSED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1 && splitpayment?.is_tranch_verified === '1') ? true : false,
                is_completed: (splitpayment?.pay_in_confirm_id) ? true : false
            },
            subvension: {
                is_active: ([DISBURSED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1 && splitpayment?.is_tranch_verified === '1') ? true : false,
                is_completed: (lead_details?.subvension_type_id && lead_details?.cross_sell_type_id) ? true : false
            },
            is_visiable_checklist: (leadDetail?.fulfillment_type === "ambak" && ([LOGIN].filter(status_id => updatedStatusList.includes(status_id)).length >= 1)) ? true : false,
            is_visiable_pdd: (leadDetail?.is_disbursal_verified === "1" && ([DISBURSED].filter(status_id => updatedStatusList.includes(status_id)).length >= 1)) ? true : false
        }
        setLeadStatusHistory(updatedStatusList);
        setTabStatus(tabstatus);
    }, [leadDetail?.lead_status_history]);

    useEffect(() => {
        let current_url = current_url_main.split('/');
        let param = current_url[current_url.length - 2];
        let detail_section = current_url[current_url.length - 3]; // customer or coborrower details route 
        if (param == 'basic-details') {
            setDefaultActiveTab("first");
        } else if (param == 'docs-upload') {
            setDefaultActiveTab("second");
        } else if (param === 'bank-offer') {
            setDefaultActiveTab("three");
        } else if (param === 'loan-full-details') {
            setDefaultActiveTab("four");
        } else if (param === 'follow-up-history') {
            setDefaultActiveTab("five");
        } else if (param === 'emi-calculator') {
            setDefaultActiveTab("seven");
        }else if (param === 'cibil-report') {
            setDefaultActiveTab("eight");
        }else if (param === 'offer-check') {
            setDefaultActiveTab("nine");
        }else if (param === 'insurance') {
            setDefaultActiveTab("ten");
        }else{
            setDefaultActiveTab("first");
        }

        if (param === 'whatsapp' && detail_section === 'lead-detail' && !isOpen) {
            togglePopup()
        } else if (param === 'email' && detail_section === 'lead-detail' && !isEmailComms) {
            EmailCommsPopup()
        }

    }, [current_url_main])
    
    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const showModalMarkAsLost = (type) => { 
        setRejection(type)
        setMarkAsLost(true);
        document.body.classList.add("overflow-hidden"); 
    };

    const showModalReopen = () => {
        setReopenModal(true)
        document.body.classList.add("overflow-hidden");
    };

    const hideModalMarkAsLost = () => { 
        setMarkAsLost(false); 
        document.body.classList.remove("overflow-hidden");
    };

    const hideModalReopen = () => {
        setRemarkText("");
        setReopenModal(false); 
        document.body.classList.remove("overflow-hidden");
    };

    const hideModalRollback = () => {
        setRollbackModal(false);
        document.body.classList.remove("overflow-hidden");
    };
        
    const togglePopup = () => {
        setUnreadMsgCountWA("");
        if(isOpen){
        navigate(-1)
        }
        setIsOpen(!isOpen);
        if(!isOpen){
            document.body.classList.add("overflow-hidden");
        }else{
            document.body.classList.remove("overflow-hidden");
        }
    };

    const EmailCommsPopup = () => {
        setEmailComms(!isEmailComms);
        if(!isEmailComms){
            document.body.classList.add("overflow-hidden");
        }else{
            document.body.classList.remove("overflow-hidden");
        }
    };

    const toggleModalBox = (lead) => {

        if (Object.keys(lead).length) {
            setIsviewHistory(lead.id);
            
            let historyData = {};
            setShowhistorymodal(true)
            executeGraphQLQuery(LEADS_HISTORY_QUERY(lead.id), client).then((result) => {
                setIsviewHistory("")
                setHistoryList(result?.data?.get_history)
            })
                .catch((error) => {
                    console.error('Query error:', error);
                });
        }
        else {
            setShowhistorymodal(false)
        }
    }

    const getLostReasonList = () => {
/*
        executeGraphQLQuery(REJECTED_REASONS_LIST(), client).then((result) => {
            let reasonList = result?.data?.status_list || []
            reasonList = reasonList?.filter(data => data?.statuslang?.status_id === leadDetail?.status_id).map(v=>v.rejectreasons) || []; 
            setSubStatusList(reasonList?.[0]);
        }).catch((errors) => {
            toast.error("Somethig went wrong ,please try again")
        });
*/
    }

    const tabStatus = (item) => {
        let classNames = (saveChecklistData?.length && saveChecklistData?.filter(data =>
        (item.id === 1 && data.is_done_cam === '1' ||
            item.id === 2 && data.checklist_sub_status_id === 2 ||
            item.id === 3 && data.checklist_sub_status_id === 4 ||
            item.id === 4 && data.checklist_sub_status_id === 6 ||
            item.id === 5 && data.checklist_sub_status_id === 8 ||
            item.id === 6 && data.checklist_sub_status_id === 12))).length ? 'completed' : 'active';
        return classNames;
    };
 
    const submitReopen = () => {
        if (!remarkText || /^\s*$/.test(remarkText)) {
            toast.error("Please enter remarks");
        }
        else {
            let mutation = UPDATE_LEAD_STATUS,
                variables = {
                    api_called_by: 'web',
                    UpdateLeadStatus: {
                        update_type: "reopen",
                        status_id: [],
                        lead_id: leadDetail.id,
                        user_id: +user_id,
                        reopen_form: {
                            reopen_remark: remarkText
                        },
                        callFrom:"updateSideForm"
                    }
                };
            executeGraphQLMutation(mutation, variables, client)
                .then((resp) => {
                    let response = resp?.data?.update_lead_status || null;
                    if (response) {
                        toast.success("Success")
                        hideModalMarkAsLost();
                        props.getLeadDetail(leadDetail.id);
                        setReopenModal(false)
                    }
                    else {
                        toast.error("Error");
                    }
                })
                .catch((err) => {
                    toast.error("Error")
                })
        }
    }

    const submitRollback = () => {
        let rollbackType = '', redirecturl = '';
        rollbackType = (leadDetail.sub_status_id === 3) ? 'send_to_financier' :
            (leadDetail.sub_status_id === 5) ? 'credit_investigation' :
                'orcr_submission_to_financier';
        executeGraphQLMutation(ROLLBACK, { rollback_type: rollbackType, lead_id: leadDetail.id }, client)
            .then((res) => {
                if (res.data.rollback.message !== 'Error!') {
                    hideModalRollback();
                    props.getLeadDetail(leadDetail.id)
                    dispatch(getLoanDetail(leadDetail.id, client))
                    toast.success("Success");
                }
                else {
                    toast.error("Error");
                }
            })
            .catch(() => {
                toast.error("Error")
            })
        if (rollbackType === 'send_to_financier') {
            redirecturl = `/lead-detail/customer-details/basic-details/${crypto.encode(leadDetail.id)}`;
        }
        if (redirecturl != '') navigate(redirecturl)
    }

    useEffect(() => {
        if (leadDetail?.whatsAppUnread?.unread_count && !unreadMsgCountWA.length) {
            setUnreadMsgCountWA(`${leadDetail?.whatsAppUnread?.unread_count} New Message`);
        }
    }, [leadDetail?.whatsAppUnread?.unread_count])

    if (leadDetail && Object.keys(leadDetail).length) {

        let lead_id = crypto.encode(leadDetail.id)
    
        // let loginCheckList = (leadDetail && leadDetail?.checklists && leadDetail?.checklists.length)?  leadDetail?.checklists?.filter(ob=>ob.sub_status_id =='2') : [];
        let loginCheckList = (leadDetail && leadDetail?.checklists && leadDetail?.checklists.length)?  leadDetail?.checklists : [];

        let latestLoginRecord = []; 
        if(loginCheckList.length){
            for(let item of loginCheckList){ 
                let index = latestLoginRecord.findIndex(ob=>ob.checklist_id ==item.checklist_id);  
                if(index > -1 && latestLoginRecord[index] && item){  
                    latestLoginRecord[index] = item   
                }else{  
                    latestLoginRecord.push(item)
                }
            }
        }    
        const loginQuery = latestLoginRecord.map(ob=>ob.checklist_sub_status_id).some(r=> LOGGED_QUERY_ID.includes(r))
        const sectionInfo = { section: "", sub_section: "" }
 
   
        return (
            <div className="lead-detail-tabs">
              
                <Tab.Container id="left-tabs-example" activeKey={defaultActiveTab} defaultActiveKey={defaultActiveTab} onSelect={(tab) => setDefaultActiveTab(tab)}>

                    <Nav variant="pills" className="flex-column">
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link onClick={() => { navigate(`/lead-detail/customer-details/basic-details/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="first">Details and Status</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => { navigate(`/lead-detail/docs-upload/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="second">Documents</Nav.Link>
                            </Nav.Item>
                            {
                                // bankOfferAccess ? 
                                // <Nav.Item>
                                //     <Nav.Link onClick={() => { navigate(`/lead-detail/bank-details/bank-offer/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="three">Bank Offers</Nav.Link>
                                // </Nav.Item>
                                // : null
                            }
                            {
                                loanDetailAccess ? 
                                <Nav.Item>
                                    <Nav.Link onClick={() => { navigate(`/lead-detail/bank-details/loan-full-details/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="four">Loan Details</Nav.Link>
                                </Nav.Item> : null

                            }
                            {
                                followupHistoryAccess ? 
                                <Nav.Item>
                                    <Nav.Link onClick={() => { navigate(`/lead-detail/bank-details/follow-up-history/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="five">Follow up History</Nav.Link>
                                </Nav.Item> : null
                            } 
                            {
                                emiCalAccess ? 
                                <Nav.Item>
                                <Nav.Link onClick={() => { navigate(`/lead-detail/emi-calculator/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="seven">EMI Calculator</Nav.Link>
                                </Nav.Item> : null
                            }
                            {
                                cibilAccess ?
                                <Nav.Item>
                                    <Nav.Link onClick={() => { navigate(`/lead-detail/customer-details/cibil-report/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="eight">Cibil Report</Nav.Link>
                                </Nav.Item> : null
                            }  
                                <Nav.Item>
                                    {/* <Nav.Link onClick={() => { navigate(`/lead-detail/bank-details/offer-check/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="nine">Offers Check</Nav.Link> */}
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link onClick={() => { navigate(`/lead-detail/bank-details/insurance/${lead_id}`); toggleTip(false); toggleTip1(false); toggleTip2(false); toggleTip3(false); }} eventKey="ten">Insurance</Nav.Link>
                                </Nav.Item>
 
                            
                        </div>
                       

                         <div className='marklost'> 
                         {/* {
                           [2,3].includes(leadDetail.status_id) ? 
                           <div className=''> 
                                <button className="bank-reject" onClick={()=>showModalMarkAsLost('bank_reject')}>Bank Rejected</button> 
                            </div>
                            : null
                        }  */}
                        {
                            // (leadDetail.rejection_type =='bank_hard_reject') ? 
                            // <div className='d-flex' style={{marginLeft:"20px"}}>
                            //    {/* <button className="lost-btn">Lead Closed</button> */}
                            //    <button className="mark-lost-btn">Mark as Lost</button>
                            // </div>
                            // :
                            // <div className='d-flex' style={{marginLeft:"20px"}}>
                            //     {(leadDetail.status_id == 5 && leadDetail.rejection_type !='bank_hard_reject') 
                            //     ? <button className="reopen-btn" 
                            //                 onClick={showModalReopen}>Reopen</button> 
                            //     :
                            //         (leadDetail.status_id != 4) ? <button className="mark-lost-btn" onClick={()=>showModalMarkAsLost('lost')}>
                            //             {/* Close Lead */}
                            //             Mark as Lost
                            //             </button> : null
                            //     }
                            // </div> 
                        } 

                        {
                            leadDetail?.rejection_type === 'bank_hard_reject' ? (
                                <div className='d-flex' style={{ marginLeft: "20px" }}>
                                    <button className="mark-lost-btn" disabled={leadDetail.status_id==5}>Mark as Lost</button>
                                </div>
                            ) : (
                                <div className='d-flex' style={{ marginLeft: "20px" }}>
                                    {(leadDetail.status_id == 5 && leadDetail.rejection_type != 'bank_hard_reject') 
                                     ? <button className="reopen-btn" 
                                        onClick={showModalReopen}>Reopen</button>
                                     : (leadDetail?.splitpayment?.[0]?.is_transaction_verified != 1) ?
                                        <button className="mark-lost-btn" onClick={() => showModalMarkAsLost(leadDetail?.status_id == 4 ? 'bank_reject':'lost')}>Mark as Lost</button> : null
                                    }
                                </div>
                            )
                        }

                        {/* <button className='marklostbtn'>Mark as lost</button> */}
                        {/* <div>
                        <li onClick={() => { toggleModalBox(leadDetail) }}>
                            <i className="ic-Call-history" style={{fontSize:25,cursor:"pointer",margin:"20px"}}></i>
                        </li>
                        </div>
                       */}
                        </div> 
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane key="first" eventKey="first">
                            <div className="detail-form-sec">
                                <div className="menu-tab-left-panel">
                                    <div className="dealer-tab-left-panel nav">
                                        <h1 style={{fontSize:18}}>Application Data</h1>
                                        <ul>
                                            <li>
                                                <NavLink to={`/lead-detail/customer-details/loan-details/${lead_id}`} className={tab_status?.customer_details?.loan_details?.is_completed ? "completed" : tab_status?.customer_details?.loan_details?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    Loan Requirements</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/lead-detail/customer-details/basic-details/${lead_id}`} className={tab_status?.customer_details?.basic_details?.is_completed ? "completed" : tab_status?.customer_details?.basic_details?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    Customer Details</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/lead-detail/customer-details/income-details/${lead_id}`} className={tab_status?.coapplicant_details?.primary_income_details?.is_completed ? "completed" : tab_status?.coapplicant_details?.primary_income_details?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    Income Details</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/lead-detail/customer-details/property-identified/${lead_id}`} className={tab_status?.customer_details?.property_identified?.is_completed ? "completed" : tab_status?.customer_details?.property_identified?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    Property Details</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/lead-detail/customer-details/offer-detail/${lead_id}?offerCheck="no"`} className={tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    Offer Check</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/lead-detail/customer-details/reference/${lead_id}`} className={tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    Reference</NavLink>
                                            </li>
                                            
                                            {/* <li>
                                                <Dropdown className={(tab_status?.customer_details?.basic_details?.is_completed && tab_status?.coapplicant_details?.co_applicant_customer_details?.is_completed) ? "d-inline mx-2 completed" : tab_status?.coapplicant_details?.is_active ? "d-inline mx-2 active" : 'd-inline mx-2'} autoClose={false}>
                                                    <Dropdown.Toggle id="dropdown-autoclose-false">
                                                        <span className="img-type"></span>
                                                        Customer Details
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <ul>
                                                            <li><NavLink to={`/lead-detail/customer-details/basic-details/${lead_id}`} className={tab_status?.customer_details?.basic_details?.is_completed ? "completed" : tab_status?.customer_details?.basic_details?.is_active ? "active" : ''}>
                                                                <span className="img-type"></span>
                                                                Primary Applicant Details</NavLink>
                                                            </li>

                                                            {
                                                                leadDetail.co_applicant.length ? 
                                                                <li><NavLink to={`/lead-detail/co-applicant-details/basic-details/${lead_id}`} className={tab_status?.coapplicant_details?.co_applicant_customer_details?.is_completed ? "completed" : tab_status?.coapplicant_details?.co_applicant_customer_details?.is_active ? "active" : ''}>
                                                                <span className="img-type"></span>
                                                                Co-applicant Details</NavLink>
                                                                </li>
                                                                : null
                                                            }
                                                            
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li> */}
                                            {/* <li>
                                                <Dropdown className={( tab_status?.coapplicant_details?.primary_income_details?.is_completed && tab_status?.coapplicant_details?.co_applicant_income_details?.is_completed ) ? "d-inline mx-2 completed" : tab_status?.coapplicant_details?.is_active ? "d-inline mx-2 active" : 'd-inline mx-2'} autoClose={false}>
                                                    <Dropdown.Toggle id="dropdown-autoclose-false">
                                                        <span className="img-type"></span>
                                                        Income Details
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <ul>
                                                            <li><NavLink to={`/lead-detail/customer-details/income-details/${lead_id}`} className={tab_status?.coapplicant_details?.primary_income_details?.is_completed ? "completed" : tab_status?.coapplicant_details?.primary_income_details?.is_active ? "active" : ''}>
                                                                <span className="img-type"></span>
                                                                Primary Applicant Income</NavLink>
                                                            </li>
                                                            {
                                                                leadDetail.co_applicant.length ? 
                                                                <li><NavLink to={`/lead-detail/co-applicant-details/loan-details/${lead_id}`} className={tab_status?.coapplicant_details?.co_applicant_income_details?.is_completed ? "completed" : tab_status?.coapplicant_details?.basic_details?.is_active ? "active" : ''}>
                                                                <span className="img-type"></span>
                                                                Co-applicant Income</NavLink>
                                                                </li>
                                                                : null
                                                            }
                                                            
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li> */}
                                          
                                            {/* <li>
                                                <Dropdown className={tab_status?.coapplicant_details && tab_status?.coapplicant_details.is_completed ? "d-inline mx-2 completed" : tab_status?.coapplicant_details && tab_status?.coapplicant_details?.is_active ? "d-inline mx-2 active" : 'd-inline mx-2'} autoClose={false}>
                                                    <Dropdown.Toggle id="dropdown-autoclose-false">
                                                        <span className="img-type"></span>
                                                        References
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <ul>
                                                            <li><NavLink to={`/lead-detail/customer-details/reference/${lead_id}`} className={tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_active ? "active" : ''}>
                                                                <span className="img-type"></span>
                                                                Customer Reference</NavLink></li>

                                                            <li><NavLink to={`/lead-detail/customer-details/reference/${lead_id}`} className={tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.basic_details && tab_status?.customer_details?.basic_details?.is_active ? "active" : ''}>
                                                                <span className="img-type"></span>
                                                                Reference 2</NavLink></li>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li> */}
                                            {/* <li>
                                                <NavLink to={`/lead-detail/customer-details/documents/${lead_id}`} className={tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    Documents</NavLink>
                                            </li> */}
                                        </ul>
                                        <h1 style={{fontSize:16}}>Loan Application</h1>
                                        <ul>
                                             {/* <li>
                                                <NavLink to={`/lead-detail/new-lead/${lead_id}`} className={tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_active ? "active" : ''}>
                                                    <span className="img-type"></span>
                                                    New Lead
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/lead-detail/contacted/${lead_id}`} className={tab_status?.contacted && tab_status?.contacted?.is_completed ? "completed" : tab_status?.contacted && tab_status?.contacted?.is_active ? "active" : 'disabled'}>
                                                    <span className="img-type"></span>
                                                    Contacted
                                                </NavLink>
                                            </li>  */}
                                            
                                            {tab_status?.is_visiable_checklist ?
                                                <li>
                                                    <Dropdown className={tab_status?.logged_in && tab_status?.logged_in.is_completed ? "d-inline mx-2 completed" : tab_status?.logged_in && tab_status?.logged_in?.is_active ? "d-inline mx-2 active" : 'd-inline mx-2'} autoClose={false}>
                                                        <Dropdown.Toggle id="dropdown-autoclose-false">
                                                            <NavLink style={{ marginBottom: -20 }} to={`/lead-detail/loggedin/${lead_id}`} className={tab_status?.logged_in && tab_status?.logged_in?.is_completed ? "completed" : tab_status?.logged_in && tab_status?.logged_in?.is_active ? "active" : 'disabled'}>
                                                                <span className="img-type"></span>
                                                                Logged In
                                                            </NavLink>
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </li> :
                                                <li style={{display:"flex",justifyContent:"space-between"}}>
                                                    <NavLink style={{display:"flex",justifyContent:"space-between"}} to={`/lead-detail/loggedin/${lead_id}`} className={tab_status?.logged_in && tab_status?.logged_in?.is_completed ? "completed" : tab_status?.logged_in && tab_status?.logged_in?.is_active ? "active" : 'disabled'}>
                                                        <div>
                                                            <span className="img-type"></span>Logged In
                                                        </div>
                                                        <div>
                                                            {loginQuery ? <span style={{color:"black"}}><img style={{marginRight:10}} src={dangericon} alt='logo' width={15} height={15} />Query Raised</span> : null}
                                                        </div>
                                                    </NavLink>
                                                </li>
                                            }

                                            <li style={{display:"flex",justifyContent:"space-between"}}>
                                                <NavLink style={{display:"flex",justifyContent:"space-between"}} to={`/lead-detail/approved/${lead_id}`} className={tab_status?.approved && tab_status?.approved?.is_completed ? "completed" : tab_status?.approved && tab_status?.approved?.is_active ? "active" : 'disabled'}>
                                                    <div>
                                                        <span className="img-type"></span>Sanctioned
                                                    </div>
                                                    <div>
                                                        {leadDetail.sub_status_id ==3 ? <span style={{color:"black"}}><img style={{marginRight:10}} src={dangericon} alt='logo' width={15} height={15} />OTC</span> : null}
                                                    </div>
                                                </NavLink> 
                                            </li> 

                                            {/* <li>
                                                <NavLink to={`/lead-detail/disbursed/${lead_id}`} className={tab_status?.disbursal && tab_status?.disbursal?.is_completed ? "completed" : tab_status?.disbursal && tab_status?.disbursal?.is_active ? "active" : 'disabled'}>
                                                    <span className="img-type"></span>
                                                    Disbursed
                                                </NavLink>
                                            </li> */}

                                            <li>
                                                <Dropdown className={tab_status?.disbursal && tab_status?.disbursal?.is_completed ? "completed" : tab_status?.disbursal && tab_status?.disbursal?.is_active ? "active" : 'disabled'}>
                                                    <Dropdown.Toggle id="dropdown-autoclose-false">
                                                        <NavLink style={{ marginBottom: -20 }} to={`/lead-detail/disbursed/${lead_id}`} className={tab_status?.disbursal && tab_status?.disbursal?.is_completed ? "completed" : tab_status?.disbursal && tab_status?.disbursal?.is_active ? "active" : 'disabled'}>
                                                            <span className="img-type"></span>
                                                            Disbursed
                                                        </NavLink>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <ul>
                                                            <li>
                                                                <NavLink to={`/lead-detail/transaction/${lead_id}`} className={tab_status?.transation && tab_status?.transation?.is_completed ? "completed" : tab_status?.transation && tab_status?.transation?.is_active ? "active" : 'disabled'}>
                                                                    <span className="img-type"></span>
                                                                    Transacted
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to={`/lead-detail/pdd/${lead_id}`} className={tab_status?.pdd && tab_status?.pdd?.is_completed ? "completed" : tab_status?.pdd && tab_status?.pdd?.is_active ? "active" : 'disabled'}>
                                                                    <span className="img-type"></span>
                                                                    PDD
                                                                </NavLink>
                                                            </li>
                                                            {
                                                                payoutNewAccess==1 ? 
                                                                <li>
                                                                    <NavLink to={`/lead-detail/payout/${lead_id}`} className={tab_status?.payout && tab_status?.payout?.is_completed ? "completed" : tab_status?.payout && tab_status?.payout?.is_active ? "active" : 'disabled'}>
                                                                        <span className="img-type"></span>
                                                                        Pay-Out
                                                                    </NavLink>
                                                                </li>
                                                                : null
                                                            }
                                                            {
                                                                payinMisAccess==1 ? 
                                                                <li>
                                                                    <NavLink to={`/lead-detail/payin-mis/${lead_id}`} className={tab_status?.payin && tab_status?.payin?.is_completed ? "completed" : tab_status?.payin && tab_status?.payin?.is_active ? "active" : 'disabled'}>
                                                                        <span className="img-type"></span>
                                                                        Pay-In MIS
                                                                    </NavLink>
                                                                </li> : null
                                                            }
                                                            {
                                                                collectionAccess==1 ? 
                                                                <li>
                                                                    <NavLink to={`/lead-detail/collection/${lead_id}`} className={tab_status?.payin && tab_status?.payin?.is_completed ? "completed" : tab_status?.payin && tab_status?.payin?.is_active ? "active" : 'disabled'}>
                                                                        <span className="img-type"></span>
                                                                        Collection
                                                                    </NavLink>
                                                                </li> : null
                                                            }
                                                            {
                                                                lead_subvension == 1 ? 
                                                                <li>
                                                                    <NavLink to={`/lead-detail/subvention-pf/${lead_id}`} className={tab_status?.subvension && tab_status?.subvension?.is_completed ? "completed" : tab_status?.subvension && tab_status?.subvension?.is_active ? "active" : 'disabled'}>
                                                                        <span className="img-type"></span>
                                                                        Subvention & PF
                                                                    </NavLink>
                                                                </li> : null
                                                            }
                                                            {
                                                                // lead_subvension ? 
                                                                <li>
                                                                    <NavLink to={`/lead-detail/insurance-cross-sell/${lead_id}`} className={tab_status?.subvension && tab_status?.subvension?.is_completed ? "completed" : tab_status?.subvension && tab_status?.subvension?.is_active ? "active" : 'disabled'}>
                                                                        <span className="img-type"></span>
                                                                        Insurance and Cross Sell
                                                                    </NavLink>
                                                                </li> 
                                                                // : null
                                                            }
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                        {/* <h1 style={{fontSize:16}}>Communication</h1>
                                        <ul>
                                            {
                                                email_communication == "1" ?
                                                    <li>
                                                        <NavLink onClick={togglePopup} to={`/lead-detail/whatsapp/${lead_id}`} className={tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_active ? "active" : ''}>
                                                            <span className="img-type"></span>
                                                            Whatsapp {unreadMsgCountWA}
                                                        </NavLink>
                                                    </li>
                                                : null
                                            }
                                            {
                                                email_communication == "1" ?
                                                    <li>
                                                        <NavLink onClick={EmailCommsPopup} to={`/lead-detail/email/${lead_id}`} className={tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_completed ? "completed" : tab_status?.customer_details && tab_status?.customer_details?.additional_details && tab_status?.customer_details?.additional_details?.is_active ? "active" : ''}>
                                                            <span className="img-type"></span>
                                                            Email
                                                        </NavLink>
                                                    </li>
                                                : null
                                            }
                                        </ul> */}
                                    </div>
                                </div>

                                <div className='route-files'>
                                    <StatusStep  leadDetail={leadDetail} />
                                    <Outlet context={contextList} />
                                </div>
                            </div>
                        </Tab.Pane>

                        <Tab.Pane key="second" eventKey="second">
                            <DocumentUpload 
                                docCategoryList={docCategoryList.get_document_list}
                                coAppdocCategoryList={docCategoryList.co_app_doc_list}
                                exDocCategoryList={exDocCategoryList.get_document_list}
                                otherdocCategoryList={docOtherCategoryList}
                                coAppotherdocCategoryList={docCategoryList.co_app_other_doc_list}
                                markAsFreezed={handleMarkAsFreezed()}
                            />
                        </Tab.Pane>

                        <Tab.Pane key="three" eventKey="three">
                            <BankOfferForm />
                        </Tab.Pane>
                       
                        <Tab.Pane key="four" eventKey="four">
                            <LoanDetailsForm />
                        </Tab.Pane>
                        
                        <Tab.Pane key="five" eventKey="five">
                            <FollowUpHistoryForm />
                        </Tab.Pane>

                        <Tab.Pane key="seven" eventKey="seven">
                            <EmiCalculator/>
                        </Tab.Pane>  

                        <Tab.Pane key="eight" eventKey="eight">
                            <CibilReport />
                        </Tab.Pane>

                        <Tab.Pane key="nine" eventKey="nine">
                            <BreOfferCheck/>
                        </Tab.Pane>

                        <Tab.Pane key="ten" eventKey="ten">
                            <InsuranceForm/>
                        </Tab.Pane>


                    </Tab.Content>

                </Tab.Container>

                {/* {(leadDetail?.fulfillment_type === 'ambak') &&
                    <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}>
                            <h2>Checklist</h2>
                        <LoggedinTabs checkList={checkList} />
                    </LoggedinPopup>
                } */}
                <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup} style={{padding:"0px"}}>
                    <WhatsAppTemplate/>
                </LoggedinPopup>

                {/* <LoggedinPopup isOpen={isEmailComms} togglePopup={EmailCommsPopup}>
                    <LeadCommuniationEmailModal />
                </LoggedinPopup> */}

                <Emailpopup isOpen={isEmailComms} togglePopup={EmailCommsPopup}>
                    {/* <EmailThread/> */}
                    <LeadCommuniationEmailModal emailCommsPopup={EmailCommsPopup} />
                </Emailpopup>

                {/* Modal for remarks history */}
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                    <div className='followuohistoryflex'>
                        <div className='followupwidth'>
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        </div>
                        <div>
                        <div className="sub-value">
                            <h3 className="followupleadid">
                            ➣  Lead Id:  <span> #{leadDetail.id}</span>
                            </h3>
                        </div>
                        <div className="sub-value">
                            <h3 className="followupleadid">
                            ➣  Customer :  <span> {leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}</span>
                            </h3>
                        </div>
                        </div>
                        </div>
                    <LeadRemarkHistory showremarkform={showremarkform} sectionInfo={sectionInfo} />
                    </Modal>
                </div>

                <div className="mark-lost-popup">
                    <LostModal MarkAsLost={MarkAsLost} hideModalMarkAsLost={hideModalMarkAsLost} rejection_type ={rejectionType} statusId={STATUS_ID} bucketId={leadDetail.bucket_id}/>
                   
                    <Modal show={reopenModal} handleClose={hideModalReopen} >
                        <div className="modal-header">
                            <h2>Re-open</h2>
                        </div>
                        <div className="modal-body" >
                            <fieldset className="form-filed">
                                <div className="material">
                                    <textarea
                                        placeholder=" "
                                        className="form-input" rows="3"
                                        value={remarkText}
                                        onChange={(e) => setRemarkText(e.target.value)}>
                                    </textarea>
                                    <label
                                        data-label="Remarks"
                                        className="form-label"
                                    >
                                    </label>
                                </div>
                            </fieldset>
                            <button className="btn-primary" onClick={submitReopen}>Save</button>
                        </div>
                    </Modal>

                    <Modal show={rollbackModal} handleClose={hideModalRollback} >
                        <div className="modal-header">
                            <h2>Rollback</h2>
                        </div>
                        <div className="modal-body roll-back-confirmation" >
                            <h4>Are you sure want to rollback ?</h4>
                            <button className="btn-primary m-sm-r" onClick={hideModalRollback}>Cancel</button>
                            <button className="btn-primary" onClick={submitRollback}>Save</button>
                        </div>
                    </Modal>

                    
                {showhistorymodal ? (
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal show={showhistorymodal} handleClose={() => { toggleModalBox({}) }} >
                        <div className='followuohistoryflex'>
                        <div className='followupwidth'>
                        <div className="modal-header">
                            <h2>{"CUSTOMER TIMELINE"}</h2>
                        </div>
                        </div>
                        <div>
                        <div className="sub-value">
                            <h3 className="followupleadid">
                            ➣  Lead Id:  <span> #{leadDetail.id}</span>
                            </h3>
                        </div>
                        <div className="sub-value">
                            <h3 className="followupleadid">
                            ➣  Customer :  <span> {leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}</span>
                            </h3>
                        </div>
                        </div>
                        </div>
                        <LeadHistory historyList={historyList} followUphistoryList={followUphistoryList} isLoading={isLoading} t={t} />
                    </Modal>
                </div>
            ) : ''}
                </div>
            </div>
        )
    } else {
        return <div></div>
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';
    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default LeadDetailTabs;
