import React, { useEffect, useState } from "react";
import PreEligibleTableContentInPartner from "./PreEligibleTableContentInPartner";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery";
import {
  GET_USER_LIST_PAYOUT,
  INVOICE_LISTING,
  PARTNER_DISBURSAL_LIST,
  PRE_ELIGIBLE_PARTNER_LIST,
} from "../../services/payout.gql";
import Loader from "../elements/Loader";
import EligibleStdTabInPartner from "./EligibleStdTabInPartner";
import PaymentPendingTabInPartner from "./PaymentPendingTabInPartner";
import { FINEX_MASTER_LIST } from "../../services/scheme.gql";
import CDCNTabInPartner from "./CDCNTabInPartner";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQueryFinex";

const SEARCH_BY_LIST = [{ id: "lead_id", label: "Lead Id", value: "lead_id" }];

const PayoutListingInPartner = ({ partner_id }) => {
  const [activeTab, setActiveTab] = useState("standard");
  const [partnerList, setPartnerList] = useState([]);
  const [pageloading, setpageloading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [financers, setFinancers] = useState([]);
  const [user_list, setUserList] = useState([]);
  const [filterData, setFilterData] = useState({
    status: "calculated",
    source: "web",
    page_no: 1,
    search_by: "lead_id",
    search_text: null,
  });

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
    }
  };

  const getDealerList = async () => {
    let user_list = [],
      userlist = [];
    setUserList(userlist);
    const response = await executeGraphQLQueryFinex(GET_USER_LIST_PAYOUT).catch(
      (err) => console.log(err)
    );
    if (response && response.data && response.data.get_user_list) {
      user_list = response.data.get_user_list;
      user_list &&
        user_list.map((v) => {
          userlist.push({ value: v.user_id, label: v.name });
          return v;
        });
      setUserList(userlist);
    }
  };

  const getPreEligiblePartner = async (payload, page_no = 1) => {
    try {
      setpageloading(true);
      const response = await executeGraphQLMutation(
        PRE_ELIGIBLE_PARTNER_LIST(page_no),
        payload
      );

      if (response.data?.pre_eligible_partners) {
        const listOfPartners =
          response.data?.pre_eligible_partners[0]?.disbursals || [];
        const pagination =
          response.data?.pre_eligible_partners[0]?.pagination || {};
        setPaginationData(pagination);
        setpageloading(false);
        setPartnerList(listOfPartners);
      }
    } catch (error) {
      toast.error(error.message);
      setpageloading(false);
    }
  };

  const getEligiblePartner = async (payload, page_no = 1) => {
    try {
      setpageloading(true);
      const response = await executeGraphQLMutation(
        PARTNER_DISBURSAL_LIST(page_no),
        payload
      );

      if (response.data?.partner_disbursals) {
        const listOfPartners =
          response.data?.partner_disbursals[0]?.disbursals || [];
        const pagination =
          response.data?.partner_disbursals[0]?.pagination || {};
        setPartnerList(listOfPartners);
        setPaginationData(pagination);
        setpageloading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setpageloading(false);
    }
  };

  const getInvoiceList = async (payload, page_no = 1) => {
    try {
      setpageloading(true);
      const response = await executeGraphQLMutation(
        INVOICE_LISTING(page_no),
        payload,
        page_no
      );

      if (response.data?.invoice_list) {
        const invoices = response.data?.invoice_list[0]?.invoices || [];
        const pagination = response.data?.invoice_list[0]?.pagination || {};
        setPartnerList(invoices);
        setPaginationData(pagination);
        setpageloading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setpageloading(false);
    }
  };

  useEffect(() => {
    const variables = {
      filter_params: {
        partner_ids: [partner_id],
        status: filterData.status,
        tab_type: "STD",
      },
    };
    getFinexMasterData();
    getDealerList();
    getPreEligiblePartner(variables);
  }, []);

  const handleStatusClick = async (sname, currentTab) => {
    const filterDataClone = { ...filterData };
    filterDataClone.search_text = null;

    if (sname == "all") {
      delete filterDataClone.status;
    } else {
      filterDataClone.status = sname;
    }
    setFilterData(filterDataClone);

    if (sname === "invoice_created") {
      setActiveTab("cndn");
    } else if (currentTab && currentTab === activeTab) {
      setActiveTab("standard");
    }

    if (sname === "calculated") {
      const variables = {
        filter_params: {
          partner_ids: [partner_id],
          status: sname,
          tab_type: "STD",
        },
      };
      await getPreEligiblePartner(variables);
    }

    if (
      ["pending", "invoice_created", "payment_pending", "paid"].includes(sname)
    ) {
      if (sname === "pending") {
        const variables = {
          filter_params: {
            partner_ids: [partner_id],
            status: sname,
            tab_type: "STD",
          },
        };
        await getEligiblePartner(variables);
      } else if (sname === "payment_pending" || sname === "paid") {
        const variables = {
          filter_params: {
            status: sname,
            source: "web",
            partner_ids: [partner_id],
            tab_type: "STD",
          },
        };
        await getInvoiceList(variables);
      }
    }
  };

  const handleTabChange = async (tab) => {
    const variables = {
      filter_params: {
        status:
          filterData.status === "pending" ? "eligible_note" : filterData.status,
        source: "web",
        partner_ids: [partner_id],
        tab_type: tab === "cndn" ? "CNDN" : "STD",
      },
    };

    await getInvoiceList(variables);
    setActiveTab(tab);
  };

  const handlePageChange = async (page_no, status) => {
    if (status === "payment_pending" || status === "paid") {
      const variables = {
        filter_params: {
          status,
          source: "web",
          partner_ids: [partner_id],
          tab_type: "STD",
        },
      };
      await getInvoiceList(variables, page_no);
    } else if (status === "calculated") {
      const variables = {
        filter_params: {
          partner_ids: [partner_id],
          status,
          tab_type: "STD",
        },
      };
      await getPreEligiblePartner(variables, page_no);
    } else if (status === "pending") {
      const variables = {
        filter_params: {
          partner_ids: [partner_id],
          status,
          tab_type: "STD",
        },
      };
      await getEligiblePartner(variables, page_no);
    }
    setFilterData((current) => ({ ...current, page_no }));
  };

  const handleChange = (event, type = "input") => {
    if (type === "input") {
      const { id, value } = event.target;
      setFilterData((current) => ({ ...current, [id]: value }));
    } else {
      const { id } = event;
      setFilterData((current) => ({ ...current, search_by: id }));
    }
  };

  const handleSearch = async () => {
    const { status, search_by, search_text, page_no } = filterData;

    if (search_by === "lead_id") {
      if (isNaN(+search_text)) return toast.error("Invalid lead id");

      if (status === "payment_pending" || status === "paid") {
        const variables = {
          filter_params: {
            status,
            source: "web",
            partner_ids: [partner_id],
            tab_type: activeTab === "cndn" ? "CNDN" : "STD",
            [search_by]: +search_text,
          },
        };
        await getInvoiceList(variables, page_no);
      } else if (status === "calculated") {
        const variables = {
          filter_params: {
            partner_ids: [partner_id],
            status,
            tab_type: activeTab === "cndn" ? "CNDN" : "STD",
            [search_by]: +search_text,
          },
        };
        await getPreEligiblePartner(variables, page_no);
      } else if (status === "pending") {
        const variables = {
          filter_params: {
            partner_ids: [partner_id],
            status,
            tab_type: activeTab === "cndn" ? "CNDN" : "STD",
            [search_by]: +search_text,
          },
        };
        await getEligiblePartner(variables, page_no);
      }
    }
  };

  const handleReset = () => {
    const defaultFilters = {
      status: "calculated",
      source: "web",
      page_no: 1,
      search_by: "lead_id",
      search_text: null,
    };
    const variables = {
      filter_params: {
        partner_ids: [partner_id],
        status: defaultFilters.status,
        tab_type: "STD",
      },
    };
    setActiveTab("standard");
    getPreEligiblePartner(variables);
    setFilterData(defaultFilters);
  };

  return (
    <>
      {pageloading && <Loader />}

      <div className="row">
        <div className="col-sm-7">
          <ul className="list-inline nav-tabs">
            <li
              className={
                filterData.status == "calculated"
                  ? "tabs-item active"
                  : "tabs-item"
              }
              onClick={() => handleStatusClick("calculated", activeTab)}
            >
              <div>Pre-Eligible</div>
            </li>

            <li
              className={
                filterData.status == "pending"
                  ? "tabs-item active"
                  : "tabs-item"
              }
              onClick={() => handleStatusClick("pending", activeTab)}
            >
              <div>Eligible</div>
            </li>

            <li
              className={
                filterData.status == "payment_pending"
                  ? "tabs-item active"
                  : "tabs-item"
              }
              onClick={() => handleStatusClick("payment_pending", activeTab)}
            >
              <div>Approved/Payment Pending</div>
            </li>

            <li
              className={
                filterData.status == "paid" ? "tabs-item active" : "tabs-item"
              }
              onClick={() => handleStatusClick("paid", activeTab)}
            >
              <div>Paid</div>
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
            }}
          >
            <div className="filterinput">
              <div style={{ width: "165px" }}>
                <fieldset className="single-select">
                  <div className="material">
                    <Select
                      onChange={(data) => handleChange(data, "dropdown")}
                      options={SEARCH_BY_LIST}
                      name="search_by"
                      placeholder={"Search By"}
                      value={SEARCH_BY_LIST.filter(
                        ({ value }) => value === filterData.search_by
                      )}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ value }) => value}
                    />
                  </div>
                </fieldset>
              </div>

              <div>
                <fieldset className="search-bx">
                  <div className="material">
                    <input
                      id="search_text"
                      type="text"
                      name="search_text"
                      onChange={handleChange}
                      className="form-input-new"
                      title={"SEARCH_TEXT"}
                      value={filterData?.search_text || ""}
                      placeholder="Enter Keyword"
                    />
                    <i className="ic-search"></i>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-sm-2"
          style={{ marginTop: "4px", display: "flex", justifyContent: "start" }}
        >
          <button className="searchbtnnew" onClick={handleSearch}>
            Search
          </button>
          <button className="resetbtnnew" onClick={handleReset}>
            Reset
          </button>
        </div>
      </div>

      {filterData.status == "calculated" && (
        <PreEligibleTableContentInPartner
          paginationData={paginationData}
          partnerList={partnerList}
          filterData={filterData}
          onPaginate={handlePageChange}
        />
      )}

      {["pending", "invoice_created", "payment_pending", "paid"].includes(
        filterData.status
      ) && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #cccccc",
              marginTop: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginLeft: 10,
              }}
            >
              <div>
                {filterData.status !== "invoice_created" && (
                  <button
                    style={{
                      ...styles.wiredButton,
                      borderBottomWidth:
                        activeTab === "standard" ? "2px" : "0px",
                      color: activeTab === "standard" ? "#6147FF" : "#666666",
                      fontWeight: activeTab === "standard" ? "bold" : "normal",
                    }}
                    onClick={() => handleTabChange("standard")}
                  >
                    Standard
                  </button>
                )}
                <button
                  style={{
                    ...styles.wiredButton,
                    borderBottomWidth: activeTab === "cndn" ? "2px" : "0px",
                    color: activeTab === "cndn" ? "#6147FF" : "#666666",
                    fontWeight: activeTab === "cndn" ? "bold" : "normal",
                  }}
                  onClick={() => handleTabChange("cndn")}
                >
                  Credit/Debit Notes
                </button>
              </div>
            </div>
          </div>

          {activeTab === "cndn" ? (
            <CDCNTabInPartner
              invoiceList={partnerList}
              filterData={filterData}
              financers={financers}
              userlist={user_list}
              paginationData={paginationData}
              onPaginate={handlePageChange}
            />
          ) : filterData.status === "pending" ? (
            <EligibleStdTabInPartner
              paginationData={paginationData}
              partnerList={partnerList}
              onPaginate={handlePageChange}
              filterData={filterData}
            />
          ) : (
            ["payment_pending", "paid"].includes(filterData.status) && (
              <PaymentPendingTabInPartner
                invoiceList={partnerList}
                filterData={filterData}
                financers={financers}
                paginationData={paginationData}
                onPaginate={handlePageChange}
              />
            )
          )}
        </>
      )}
    </>
  );
};

const styles = {
  addPartnerButton: {
    backgroundColor: "#6147FF",
    color: "#fff",
    borderRadius: "10px 10px 0 0",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
  },
  wiredButton: {
    backgroundColor: "transparent",
    color: "#6147FF",
    borderTop: "0px",
    borderLeft: "0px",
    borderRight: "0px",
    borderBottom: "2px solid #6147FF",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    marginRight: "10px",
  },
};

export default PayoutListingInPartner;
