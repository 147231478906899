import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MasterService from "../../services/MasterService";

const ImagePreview = () => {
    const [image, setImage] = useState(null);
    const location = useLocation();
    const image_url = location.search.substring(1);

    useEffect(async () => {
        console.log(image_url);
        try {
            const response = await MasterService.get(`/core/commonservice/signin_url?file_url=${image_url}&expiry_days=2`);
            setImage(response?.data);
        } catch (error) {
            console.error(`Error fetching signed URL for file_url:`, error.message);
        }
    }, [image_url]);

    return (
        <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={image} alt="Preview" style={{ width: "80%", height: "80%", objectFit: "cover" }} />
        </div>
    );
};

export default ImagePreview;