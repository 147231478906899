import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import Pagination from "../elements/pagination";
import { MasterService } from "../../services";
import InputField from "../elements/Input";
import Select from "react-select";
import Formsy from "formsy-react";
import FormsySelect from "../elements/FormsySelect";
import Modal from "../elements/Modal";
import "./BreBankUSP.css";

// Toastify
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Options for filtering
const statusOptions = [
  { value: "", label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

const professionOptions = [
  { value: "", label: "All" },
  { value: "1", label: "Salaried" },
  { value: "2", label: "Self Employed Professional" },
  { value: "3", label: "Self Employed Non-Professional" },
];

// Endpoints
const GET_ALL_LAP_URL = "/process-rule/crud/getalllapprocessing";
const DELETE_LAP_URL = "/process-rule/crud/deletelapprocessing";
const UPDATE_LAP_URL = "/process-rule/crud/updatelapprocessing";
const ADD_LAP_URL = "/process-rule/crud/addlapprocessing"; // For "Add" mode

const BreBankLAPProcessing = () => {
  const [lapData, setLapData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // For pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // For Add/Edit modal
  const [showModal, setShowModal] = useState(false);
  const [selectedLAP, setSelectedLAP] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});


  // For filtering
  const [searchParams, setSearchParams] = useState({
    bank_name: "",
    status: "",
    profession_id: "",
  });

  // Fetch data on mount
  useEffect(() => {
    fetchData();
  }, []);

  // Re-filter or re-paginate when data/filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams]);

  // **Fetch all LAP data**
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await MasterService.get(GET_ALL_LAP_URL);
      setLapData(response.data?.data || []);
    } catch (err) {
      setError("Failed to fetch LAP data.");
      toast.error("Error fetching LAP data.");
    } finally {
      setLoading(false);
    }
  };

  // **Filter** based on `searchParams`
  const filteredData = lapData.filter((item) => {
    const bankMatch = searchParams.bank_name
      ? item.bank_name?.toLowerCase().includes(searchParams.bank_name.toLowerCase())
      : true;

    const statusMatch =
      searchParams.status !== ""
        ? String(item.lapprocess_status) === searchParams.status
        : true;

    const professionMatch =
      searchParams.profession_id !== ""
        ? String(item.lapprocess_profession_id) === searchParams.profession_id
        : true;

    return bankMatch && statusMatch && professionMatch;
  });

  // **Paginate** filtered data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  // **Handle filter** inputs
  const handleInputChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  // **Delete** => Mark record Inactive
  const handleDelete = async (lapId) => {
    try {
      await MasterService.put(`${DELETE_LAP_URL}/${lapId}`);
      toast.success("Status changed to Inactive!");
      await fetchData(); // ensure data is refreshed
    } catch (err) {
      toast.error("Error updating status.");
    }
  };

  // **Edit** => open modal with row data
  const handleEdit = (lap) => {
    setSelectedLAP({
      lapprocess_id: lap.lapprocess_id,
      lapprocess_bank_id: lap.lapprocess_bank_id?.toString() || "",
      lapprocess_profession_id: lap.lapprocess_profession_id?.toString() || "",
      lapprocess_charge_text: lap.lapprocess_charge_text || "",
      lapprocess_login_fee: lap.lapprocess_login_fee || "",
      lapprocess_status: lap.lapprocess_status || "1",
      duDiligeChar: lap.duDiligeChar || "",
      eStampChar: lap.eStampChar || "",
      modtChar: lap.modtChar || "",
      noiChar: lap.noiChar || "",
      cersaiChar: lap.cersaiChar || "",
      legTechFee: lap.legTechFee || "",
      acHanChar: lap.acHanChar || "",
    });
    setShowModal(true);
  };

  const toggleTextExpansion = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  

  // **Add** => open modal with blank data
  const handleAdd = () => {
    setSelectedLAP({
      lapprocess_id: null,
      lapprocess_bank_id: "",
      lapprocess_profession_id: "",
      lapprocess_charge_text: "",
      lapprocess_login_fee: "",
      lapprocess_status: "1",
      duDiligeChar: "",
      eStampChar: "",
      modtChar: "",
      noiChar: "",
      cersaiChar: "",
      legTechFee: "",
      acHanChar: "",
    });
    setShowModal(true);
  };

  // **Add or Update** logic
  const handleUpdate = async () => {
    try {
      // If no ID => Add Mode
      if (!selectedLAP?.lapprocess_id) {
        // Validate Bank ID
        if (!selectedLAP.lapprocess_bank_id?.toString().trim()) {
          toast.error("Bank ID is required!");
          return;
        }
  
        const bankId = Number(selectedLAP.lapprocess_bank_id);
        if (isNaN(bankId) || bankId <= 0) {
          toast.error("Bank ID must be a valid positive number!");
          return;
        }
  
        // Profession ID (default to 0 if empty)
        const professionId = selectedLAP.lapprocess_profession_id?.trim()
          ? Number(selectedLAP.lapprocess_profession_id)
          : 0;
  
        // Build ADD payload using consistent property names
        const addPayload = {
          bank_id: bankId,
          profession_id: professionId,
          charge_text: selectedLAP.lapprocess_charge_text, // Updated name
          login_fee: selectedLAP.lapprocess_login_fee,       // Updated name
          status: selectedLAP.lapprocess_status,
          due_diligence_charge: selectedLAP.duDiligeChar,
          e_stamp_charge: selectedLAP.eStampChar,
          MODT_charge: selectedLAP.modtChar,
          NOI_charge: selectedLAP.noiChar,
          CERSAI_charge: selectedLAP.cersaiChar,
          legal_technical_fee: selectedLAP.legTechFee,
          ac_handling_charge: selectedLAP.acHanChar,
        };
  
        const data = { data: JSON.stringify(addPayload) };
        // POST for add
        const resp = await MasterService.post(ADD_LAP_URL, data, {
          headers: { "Content-Type": "application/json" },
        });
        
        if (resp.status > 399) {
          toast.error("Bank ID is not valid");
          return;
        }
  
        toast.success("Added successfully!");
        setShowModal(false);
  
        // Refresh data right away
        await fetchData();
  
        // Optional: Reset filters
        setSearchParams({ bank_name: "", status: "", profession_id: "" });
      } else {
        // Update existing record
  
        const professionId = selectedLAP.lapprocess_profession_id?.trim()
          ? Number(selectedLAP.lapprocess_profession_id)
          : 0;
        if (selectedLAP.lapprocess_profession_id?.trim() && (isNaN(professionId) || professionId < 0)) {
          toast.error("Profession ID must be a valid positive number (or 0)!");
          return;
        }
  
        // Build UPDATE payload using the same consistent naming
        const updatePayload = {
          charge_text: selectedLAP.lapprocess_charge_text, // Updated name
          login_fee: selectedLAP.lapprocess_login_fee,       // Updated name
          status: String(selectedLAP.lapprocess_status),
          profession_id: professionId,
          due_diligence_charge: selectedLAP.duDiligeChar,
          e_stamp_charge: selectedLAP.eStampChar,
          MODT_charge: selectedLAP.modtChar,
          NOI_charge: selectedLAP.noiChar,
          CERSAI_charge: selectedLAP.cersaiChar,
          legal_technical_fee: selectedLAP.legTechFee,
          ac_handling_charge: selectedLAP.acHanChar,
        };
  
        const reqData = { data: JSON.stringify(updatePayload) };
        await MasterService.put(
          `${UPDATE_LAP_URL}/${selectedLAP.lapprocess_id}`,
          reqData,
          { headers: { "Content-Type": "application/json" } }
        );
  
        toast.success("Updated successfully!");
        setShowModal(false);
  
        // Refresh data to see changes
        await fetchData();
      }
    } catch (err) {
      console.error("Update Error:", err.response?.data || err.message);
      toast.error("Error saving LAP record");
    }
  };
  
  
  

  return (
    <>
     
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
          <p>Loading data...</p>
        </div>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <>
          {/* Filters */}
          <div className="search-wrap mb-3">
            <div className="filter-container">
              <div className="filter-body">
                <div className="row">
                  {/* Bank Name */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Bank Name</div>
                      <div className="filter-input">
                        <InputField
                          name="bank_name"
                          placeholder="Search Bank Name"
                          value={searchParams.bank_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Status */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Status</div>
                      <div className="material">
                        <Select
                          options={statusOptions}
                          placeholder="Select Status"
                          name="status"
                          value={statusOptions.find(
                            (o) => o.value === searchParams.status
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            setSearchParams({ ...searchParams, status: newVal });
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Profession */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Profession</div>
                      <div className="material">
                        <Select
                          options={professionOptions}
                          placeholder="Select Profession"
                          name="profession_id"
                          value={professionOptions.find(
                            (opt) => opt.value === searchParams.profession_id
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            setSearchParams({ ...searchParams, profession_id: newVal });
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Buttons */}
                  <div className="text-btn d-flex">
                    <div className="btn-submit-reset top-btn-none pull-right">
                      <button
                        className="btn-primary"
                        onClick={() => setCurrentPage(1)}
                      >
                        Apply
                      </button>
                      <button
                        className="btn-reset"
                        onClick={() =>
                          setSearchParams({ bank_name: "", status: "", profession_id: "" })
                        }
                      >
                        Reset
                      </button>
                      {/* Add Button */}
                      <button
                        className="btn-reset"
                        style={{ marginLeft: "150px" }}
                        onClick={handleAdd}
                      >
                        Add Entry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Data Table */}
          <Table striped bordered hover responsive>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Bank</th>
                <th>Profession</th>
                <th>City ID</th>
                <th>Login Fee</th>
                <th>Charge Text</th>
                <th>Status</th>
                <th>Due Diligence</th>
                <th>eStamp</th>
                <th>MODT</th>
                <th>NOI</th>
                <th>CERSAI</th>
                <th>Legal Tech</th>
                <th>Account Handling</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((lap) => (
                  <tr key={lap.lapprocess_id}>
                    <td>{lap.lapprocess_id}</td>
                    <td>{lap.bank_name}</td>
                    <td>{lap.profession_label || "-"}</td>
                    <td>{lap.lapprocess_city_id || "-"}</td>
{/* Login Fee */}
<td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    className={
      expandedRows[`login_fee-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.lapprocess_login_fee || "-"}
  </div>
  {lap.lapprocess_login_fee?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`login_fee-${lap.lapprocess_id}`)}
      style={{ padding: "0", marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`login_fee-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

{/* Charge Text */}
<td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    className={
      expandedRows[`charge_text-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.lapprocess_charge_text || "-"}
  </div>
  {lap.lapprocess_charge_text?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`charge_text-${lap.lapprocess_id}`)}
      style={{ padding: "0", marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`charge_text-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

{/* Due Diligence */}
<td>
  <div
    className={
      expandedRows[`duDiligeChar-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.duDiligeChar || "-"}
  </div>
  {lap.duDiligeChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`duDiligeChar-${lap.lapprocess_id}`)}
      style={{ padding: 0, marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`duDiligeChar-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

{/* eStamp */}
<td>
  <div
    className={
      expandedRows[`eStampChar-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.eStampChar || "-"}
  </div>
  {lap.eStampChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`eStampChar-${lap.lapprocess_id}`)}
      style={{ padding: 0, marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`eStampChar-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

{/* MODT */}
<td>
  <div
    className={
      expandedRows[`modtChar-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.modtChar || "-"}
  </div>
  {lap.modtChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`modtChar-${lap.lapprocess_id}`)}
      style={{ padding: 0, marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`modtChar-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

{/* NOI */}
<td>
  <div
    className={
      expandedRows[`noiChar-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.noiChar || "-"}
  </div>
  {lap.noiChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`noiChar-${lap.lapprocess_id}`)}
      style={{ padding: 0, marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`noiChar-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

{/* CERSAI */}
<td>
  <div
    className={
      expandedRows[`cersaiChar-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.cersaiChar || "-"}
  </div>
  {lap.cersaiChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`cersaiChar-${lap.lapprocess_id}`)}
      style={{ padding: 0, marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`cersaiChar-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

{/* Legal Tech */}
<td>
  <div
    className={
      expandedRows[`legTechFee-${lap.lapprocess_id}`]
        ? "expanded-text"
        : "truncated-text"
    }
  >
    {lap.legTechFee || "-"}
  </div>
  {lap.legTechFee?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleTextExpansion(`legTechFee-${lap.lapprocess_id}`)}
      style={{ padding: 0, marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`legTechFee-${lap.lapprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>


                    <td>{lap.acHanChar || "-"}</td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        style={{ marginRight: "0px" }}
                        onClick={() => handleEdit(lap)}
                      >
                        ✏️ Edit
                      </Button>
                      {String(lap.lapprocess_status)==="1" && (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(lap.lapprocess_id)}
                      >
                        🗑️ Delete
                      </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="15" className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Pagination
            perPage={itemsPerPage}
            onPaginate={(page) => setCurrentPage(page)}
            pageCount={totalPages}
            activePage={currentPage}
          />
        </>
      )}

      {/* Modal for Add/Edit */}
      <Modal show={showModal} handleClose={() => setShowModal(false)} centered>
        <div className="modern-modal">
          <h4 className="modal-title">
            {selectedLAP?.lapprocess_id ? "Edit LAP Processing" : "Add LAP Processing"}
          </h4>

          <Formsy onValidSubmit={handleUpdate}>
            {/* Bank ID (only if adding a new record) */}
            {!selectedLAP?.lapprocess_id && (
              <div className="input-group">
                <label>
                  Bank ID <span style={{ color: "red" }}>*</span>
                </label>
                <InputField
                  name="lapprocess_bank_id"
                  value={selectedLAP?.lapprocess_bank_id || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedLAP({
                      ...selectedLAP,
                      lapprocess_bank_id: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {/* Profession Dropdown */}
            <div className="input-group">
  <label>Profession</label>
  <FormsySelect
    name="lapprocess_profession_id"
    inputProps={{
      options: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ],
      placeholder: selectedLAP?.lapprocess_profession_id ? undefined : "Select Profession",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ].filter(
        (option) => option.value === selectedLAP?.lapprocess_profession_id
      ),
    }}
    onChange={(selected) =>
      setSelectedLAP({
        ...selectedLAP,
        lapprocess_profession_id: selected?.value || "",
      })
    }
  />
</div>


            {/* Login Fee */}
            <div className="input-group">
              <label>Login Fee</label>
              <InputField
                name="lapprocess_login_fee"
                value={selectedLAP?.lapprocess_login_fee || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    lapprocess_login_fee: e.target.value,
                  })
                }
              />
            </div>

            {/* Charge Text */}
            <div className="input-group">
              <label>Charge Text</label>
              <InputField
                name="lapprocess_charge_text"
                value={selectedLAP?.lapprocess_charge_text || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    lapprocess_charge_text: e.target.value,
                  })
                }
              />
            </div>

            {/* Status */}
            <div className="input-group">
  <label>Status</label>
  <FormsySelect
    name="lapprocess_status"
    inputProps={{
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ],
      placeholder: selectedLAP?.lapprocess_status ? undefined : "Select Status",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ].filter((option) => option.value === selectedLAP?.lapprocess_status),
    }}
    onChange={(selected) =>
      setSelectedLAP({
        ...selectedLAP,
        lapprocess_status: selected?.value || "1",
      })
    }
  />
</div>


            {/* Due Diligence */}
            <div className="input-group">
              <label>Due Diligence</label>
              <InputField
                name="duDiligeChar"
                value={selectedLAP?.duDiligeChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    duDiligeChar: e.target.value,
                  })
                }
              />
            </div>

            {/* eStamp */}
            <div className="input-group">
              <label>eStamp Charge</label>
              <InputField
                name="eStampChar"
                value={selectedLAP?.eStampChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    eStampChar: e.target.value,
                  })
                }
              />
            </div>

            {/* MODT */}
            <div className="input-group">
              <label>MODT Charge</label>
              <InputField
                name="modtChar"
                value={selectedLAP?.modtChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    modtChar: e.target.value,
                  })
                }
              />
            </div>

            {/* NOI */}
            <div className="input-group">
              <label>NOI Charge</label>
              <InputField
                name="noiChar"
                value={selectedLAP?.noiChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    noiChar: e.target.value,
                  })
                }
              />
            </div>

            {/* CERSAI */}
            <div className="input-group">
              <label>CERSAI Charge</label>
              <InputField
                name="cersaiChar"
                value={selectedLAP?.cersaiChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    cersaiChar: e.target.value,
                  })
                }
              />
            </div>

            {/* Legal Tech Fee */}
            <div className="input-group">
              <label>Legal Tech Fee</label>
              <InputField
                name="legTechFee"
                value={selectedLAP?.legTechFee || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    legTechFee: e.target.value,
                  })
                }
              />
            </div>

            {/* Account Handling */}
            <div className="input-group">
              <label>Account Handling</label>
              <InputField
                name="acHanChar"
                value={selectedLAP?.acHanChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedLAP({
                    ...selectedLAP,
                    acHanChar: e.target.value,
                  })
                }
              />
            </div>

            {/* Modal Buttons */}
            <div className="button-group">
              <button
                className="btn-cancel"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="btn-update" type="submit">
                {selectedLAP?.lapprocess_id ? "Update" : "Add"}
              </button>
            </div>
          </Formsy>
        </div>
      </Modal>
    </>
  );
};

export default BreBankLAPProcessing;
