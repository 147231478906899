
import React from "react";

const TickectList = ({  freshDeskTicketList}) => {

  const handleNewTicket = (ticket_id) => {
    
    window.open(`https://ambak-help.freshdesk.com/a/tickets/${ticket_id}`, "_blank");

  }
    return (
    <>
    <div className="table-responsive">
        <table className="table table-bordered tablefontsize">
          <thead style={{position:"sticky",top:"0"}}>
            <tr>
              <th>FD ID</th>
              <th>Title</th>
              <th>Type</th>
              <th>Issue<br/>Issue Sub-Type</th>
              <th>Type</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {freshDeskTicketList?.length > 0 ? (
              freshDeskTicketList.map((freshDeskTicketList, index) => (
                <tr key={index}>
                  <td onClick={() => handleNewTicket(freshDeskTicketList?.id)} style={{color:"blue" , cursor : "pointer" , textDecoration : "underline"}}>{freshDeskTicketList?.id ? freshDeskTicketList?.id : "N/A"}</td>
                  <td>{freshDeskTicketList?.custom_fields?.cf_issue_raised_by_rm  ? freshDeskTicketList?.custom_fields?.cf_issue_raised_by_rm : "N/A" }</td>
                  <td>{freshDeskTicketList?.type ? freshDeskTicketList?.type : "N/A"}</td>
                  <td>{freshDeskTicketList?.custom_fields?.cf_issue_raised_by_rm ? (<><div>{freshDeskTicketList?.custom_fields?.cf_issue_raised_by_rm}</div><div>{freshDeskTicketList?.description_text}</div></>) : ("N/A")}</td>
                  <td>{freshDeskTicketList?.type ?freshDeskTicketList?.type : ("N/A")}</td>
                  <td>{freshDeskTicketList?.updated_at ? new Date(freshDeskTicketList.updated_at).toLocaleDateString() : "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr><td colSpan="6">No Leads Found</td></tr>
            )}

          </tbody>
        </table>
      </div>
    </>
  );
};

export default TickectList;
