import React, { useState, useEffect } from "react";
import "../../../pre-register-lead-manager/pre-register-lead.css";
import { MasterService, DealerService } from "../../../../../services";
import { getCurrentUserDetails } from "../../utility/getUserInfo";
import Formsy from "formsy-react";
import FormsyInputField from "../../../../elements/FormsyInputField";
import { toast } from "react-toastify";
import {
  getCurrentDateTime,
  getDateAndTimeFormat,
  getISODateAndTimeFormat,
} from "../../utility/dateFormateUtility";
import PartnerFeedVisit from "./PartnerFieldVisit";

const INTERESTED_SUB_STATUS_ID = 35;
const NOT_HAVE_LEAD_SUB_STATUS_ID = 70;
const POOR_EXPERIENCE_STATUS_ID = 65;
const NOT_RELEVANT = 85;
const PHYSICAL_VISIT_STATUS_ID = 68;
const RM_ASSIGNED_STATUS_ID = 69;
const CONNECTED_STATUS_ID = 1;
const NOT_CONNECTED_STATUS_ID = 2;
const INVALID_NUMBER_SUB_STATUS_ID = 72;
const BUSY_SUB_STATUS_ID = 71;
const OUT_OF_REACH_SUB_STATUS_ID = 73;
const NOT_PICK_SUB_STATUS_ID = 74;
const SWITCHED_OFF_SUB_STATUS_ID = 75;
const LANGUAGE_BARRIER_SUB_STATUS_ID = 86;
const MORE_CALL_SALES =97;
const MORE_CALL_SALES_PARTNER =98;

const INTEREST_LEVEL_OPTIONS = [
  { id: "low", label: "Low" },
  { id: "medium", label: "Medium" },
  { id: "high", label: "High" },
];

const SalesRegisteredCallPopup = (props) => {
  const leadData = props.leadData;
  const [status, setStatus] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [callStatus, setCallStatus] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [reasonsList, setReasonsList] = useState([]);
  const [isFollowupDisabled, setIsFollowupDisabled] = useState(false);
  const [field_visit , set_field_visit] = useState(false)
  const [field_list_reason , set_field_list_reason] = useState([])
  const [field_visit_data , set_field_visit_data] = useState([])
  const [reqObj , setReqObj] = useState({})
  const [values, setValues] = useState({
    partner_id: "",
    // status_id: leadData.status_id || "",
    // sub_status_id: leadData.sub_status_id || "",
    call_status_id: leadData.call_status_id || "",
    // followup_date: leadData.followup_date || "",
    // comment: leadData.comment || "",
    source: props.source,
    status: 1,
    created_by: "",
    updated_by: "",
    // remarks: leadData.remarks || "",
    // title: leadData.title || "",
    not_connected_today: 0,
  });

  const userInfo = getCurrentUserDetails();

  useEffect(() => {
    fetchData();
    getStatusList();
    getCallStatus();
    getFieldVisitReasons();
    // if (leadData.sub_status_id === 83 || values.sub_status_id === 80) {
    //   set_field_visit(true)
    //  }
  }, []);

  useEffect(() => {
    if (values.status_id) {
      getSubStatusList(values.status_id);
    }
  }, [values.status_id, leadData]);

  useEffect(() => {
    if (isSubmitDisabled) {
      setIsSubmitDisabled(false);
    }
  }, [values]);
  
  const handlefieldVisitReason = (value) => {
    setReqObj(value)
  }
  const fetchData = async () => {
    try {
      const dealerHashId = window.location.pathname.split("/")[2];
      let postdata = {dealer_id_hash: dealerHashId, _with: ["DIF"] };
      const response = await MasterService.post(
                "/partner/partner-api/details",
                postdata
              );
      const data = response.data.data[0];
      set_field_visit_data(response.data.data[0])

      setValues((prevValues) => ({
        ...prevValues,
        // comment: data.comment || "",
        // remarks: data.remarks || "",
        // reason_id: data.reason_id || "",
        // interest_level: data.interest_level || "",
        // title: data.title || "",
        // status_id: data.status_id || "",
        // sub_status_id: data.sub_status_id || "",
        // followup_date: data.followup_date || "",
        not_connected_today: data.not_connected_today || 0,
      }));
      // if (data.sub_status_id === 83 || data.sub_status_id === 80  ){
      //   set_field_visit(true)
      // }
      //if (
      //  data.sub_status_id === POOR_EXPERIENCE_STATUS_ID ||
      //  data.sub_status_id === NOT_RELEVANT
     // )
      //  getReasonsList(data.sub_status_id);
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const getStatusList = async () => {
    try {
      const response = await MasterService.get("/partner/partner-call/status");
      setStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching status list:", error);
      toast.error("Error fetching status list");
    }
  };

  const getSubStatusList = async (statusId) => {
    try {
      const response = await MasterService.get(
        `/partner/partner-call/sub-status?status_id=${statusId}&source=${props.source}`
      );
      let subStatusList = response.data.data;

      setSubStatus(subStatusList);
    } catch (error) {
      console.error("Error fetching sub-status list:", error);
      toast.error("Error fetching sub-status list");
    }
  };

  const getReasonsList = async (sub_status_id) => {
    const response = await MasterService.get(
      `/partner/partner-call/reasons?sub_status_id=${sub_status_id}&source=${props.source}`
    );
    if (response.data.data) {
      setReasonsList(response.data.data);
    }
  };

  const getCallStatus = async () => {
    try {
      const response = await MasterService.get(
        "/partner/partner-call/call-status"
      );
      setCallStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching call status list:", error);
      toast.error("Error fetching call status list");
    }
  };

  const getFieldVisitReasons = async () => {
    try {
      const response = await MasterService.get("/partner/partner-call/partner-field-visit-purpose")
      set_field_list_reason(response.data.data)

    } catch (error) {
      
    }
  }

  const handleStatusChange = async (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.target.value);
    setValues((prevValues) => ({
      ...prevValues,
      status_id: selectedId,
    }));
    await getSubStatusList(selectedId);
  };

  const handleSubStatusChange = (event) => {
    if (event.target.value == 83){
      set_field_visit(true)
    }
    else {
      set_field_visit(false)
    }
    event.preventDefault();
    let followup_date = values?.followup_date;
    const selectedId = parseInt(event.target.value);
    if (values.status_id === NOT_CONNECTED_STATUS_ID) {
      if (
        selectedId === INVALID_NUMBER_SUB_STATUS_ID 
        || selectedId === MORE_CALL_SALES
        || selectedId === MORE_CALL_SALES_PARTNER) {
        followup_date = "";
      } else {
        followup_date = getFollowupTimeForNc(values.not_connected_today);
      }
      setIsFollowupDisabled(true);
      toast.warn(`Follow up will be scheduled automatically by system`);
    } else {
      setIsFollowupDisabled(false);
    }
    if (selectedId === POOR_EXPERIENCE_STATUS_ID || selectedId === NOT_RELEVANT)
      getReasonsList(selectedId);
    setValues((prevValues) => ({
      ...prevValues,
      followup_date,
      sub_status_id: selectedId,
    }));
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setValues((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleDateTimeChange = (event) => {
    const dateTime = event.target.value;
    setValues({
      ...values,
      followup_date: dateTime,
    });
  };

  const handleComment = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      comment: e.target.value,
    }));
  };

  const handleRemarks = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      remarks: e.target.value,
    }));
  };

  const handleTitle = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      title: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitDisabled(true);
    setIsSubmitted(true);

    // Validate inputs
    if (!values.status_id) {
      toast.error("Please select a status");
      return;
    }
    if (!values.sub_status_id) {
      toast.error("Please select a sub-status");
      return;
    }
    if (
      values.sub_status_id !== NOT_HAVE_LEAD_SUB_STATUS_ID &&
      values.sub_status_id !== NOT_RELEVANT &&
      values.status_id !== NOT_CONNECTED_STATUS_ID &&
      values.sub_status_id !== INVALID_NUMBER_SUB_STATUS_ID &&
      values.sub_status_id !== LANGUAGE_BARRIER_SUB_STATUS_ID &&
      values.sub_status_id !== MORE_CALL_SALES &&
      values.sub_status_id !== MORE_CALL_SALES_PARTNER 
    ) {
      if (!values.followup_date && field_visit === false) {
        toast.error("Please select a followup date");
        return;
    }
      if (values.followup_date < getCurrentDateTime() && field_visit === false) {
        toast.error(
          "Followup date should be greater than or equal to the current date and time"
        );
        return;
      }
    }

    // Prepare request data
    const reqData = {
      partner_id: props?.leadData?.id,
      ...(values.status_id && { status_id: values.status_id }),
      ...(values.sub_status_id && { sub_status_id: values.sub_status_id }),
      ...(values.call_status_id && { call_status_id: values.call_status_id }),
      ...(values.followup_date && { followup_date: values.followup_date }),
      ...(values.comment && { comment: values.comment }),
      ...(values.title && { title: values.title }),
      ...(values.remarks && { remarks: values.remarks }),
      ...(values.reason_id && { reason_id: values.reason_id }),
      ...(values.interest_level && { interest_level: values.interest_level }),
      source: props.source,
      status: 1,
      created_by: userInfo.userId,
    };

    if (values.status_id == 1) {
      reqData.connected_at = new Date();
    } else {
      reqData.connected_at = null;
    }

    if (reqData.sub_status_id === INVALID_NUMBER_SUB_STATUS_ID) {
      reqData.followup_date = "";
    }

    // Handle form submission
    try {
      const response = await MasterService.post(
        "/partner/partner-call/save-partner-call-details",
        reqData
      );
      if (response.status === 200) {
        toast.success("Success");
        props.nextActive();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error saving details:", error);
      toast.error("Error saving details");
    }
  };
  
  const updateYodha = async () => {
    const ls = localStorage.getItem("user_information")
        const resObj = {
          "user_id": reqObj.city_head_id,
          "task_type":1,
          "visit_type":3,
          "eta_from_date": reqObj.dateTime,
          "eta_to_date": reqObj.dateTime,
          "comment":reqObj.comments,
          "created_by":(JSON.parse(ls)).yodha_code,
          "assigned_to":reqObj.city_head_id ,
          "note": reqObj.comments,
          "visit_reason": reqObj.purpose_name,
          "address":reqObj.address,
          "source":"PST",
          "partner_id" : props?.leadData?.id
      }
    const response =  await MasterService.post("sfa/task_management/createTask" , resObj)
    if(response?.data?.status === 200){
        const ls = localStorage.getItem("user_information")
        const obj = {
          "partner_id": props?.leadData?.id,
          "user_id": props?.leadData?.user_id,
          "purpose_id": reqObj.purpose_id,
          "city_id": reqObj.city_id,
          "city_head_id": reqObj.city_head_id,
          "regional_head_id": reqObj.regional_head_id,
          "field_visit_date": reqObj.dateTime,
          "address": reqObj.address,
          "comments": reqObj.comments,
          "created_by": (JSON.parse(ls)).yodha_code,
          "purpose_name" :  reqObj.purpose_id,
          "city_name" : reqObj.city_name ,
          "city_head_name" : reqObj.city_head_name,
          "rm_name" : reqObj.rm_name,
          "Source" : "PST",
          "task_id" : response?.data?.data?.id
        }
        const res =  await MasterService.post("/partner/partner-call/save-field-visit-purpose" , obj)
      toast.success("Field Visit Created")
      handleSubmit()
      updateAssignment()
      return response?.data
    }
    else {

      toast.error(response?.data?.message)
      return "API failed"
    }
  }
  const handlefieldVisitReasonSubmit = async () => {
    if (field_visit === false) {
      return
    }
    if (!reqObj.purpose_id){
      toast.error("Purpose of field visit is required")
      return;
    }
    if (!reqObj.city_id){
      toast.error("City is required")
      return;
    }
    if (!reqObj.city_head_id && !reqObj.regional_head_id){
      toast.error("FOS Agent is required")
      return;
    }
   
    if (!reqObj.dateTime){
      toast.error("Time and date of field visit is required")
      return;
    }
    if (!reqObj.address){
      toast.error("Address is required")
      return;
    }
    if (!reqObj.comments){
      toast.error("Comment is required")
      return;
    }
    const res = await updateYodha()


   }    

  const getFollowupTimeForNc = (noOfAttempts) => {
    const currentDate = new Date();
    if (noOfAttempts === 0) {
      currentDate.setHours(currentDate.getHours() + 4);

      // IF current +2hrs reached 7pm, then set time to next day 11am
      if (currentDate.getHours() > 19) {
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(11, 0, 0, 0);
      }
    } else if (noOfAttempts >= 1) {
      // Set time to next day 2pm
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(14, 0, 0, 0);
    }
    return currentDate;
  };

 const updateAssignment = async () => {
    try {
      const userInformation = localStorage.getItem("user_information");
      if (!userInformation) {
        throw new Error("User information is missing from localStorage");
      }
  
      const parsedUserInfo = JSON.parse(userInformation);
        const Obj = {
        assigned_user_id: [reqObj?.city_head_id || reqObj?.regional_head_id],
        beforeUser_id: [parsedUserInfo?.yodha_code],
        dealer_id: props?.leadData?.id,
        dealer_name: String(props?.leadData?.first_name),
        logged_user_id: 1,
      };
      const res = await MasterService.post("/sfa/dealer/updateAssignExecutive", Obj);
  
      if (res?.success) {
        console.log("Assignment updated successfully", res);
      } else {
        console.error("Failed to update assignment", res?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error in updateAssignment:", error.message || error);
    }
  };

  return (
    <>
      <div className="mb-b20">Call Status</div>
  
      <Formsy autoComplete="off">
        <div className="row">
          {/* Status Dropdown */}
          <div className="btnmarkdoneflex col-sm-6 mb-b20">
            <div className="box">
              <h4 className="dropdown-heading1">Status*</h4>
              <select value={values.status_id} onChange={handleStatusChange} required>
                <option value="">Select Status</option>
                {status.map((status) => (
                  <option key={status.status_id} value={status.status_id}>
                    {status.status_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
  
          {/* Sub-Status Dropdown */}
          <div className="btnmarkdoneflex col-sm-6 mb-b20">
            <div className="box">
              <h4 className="dropdown-heading2">Sub-Status*</h4>
              <select value={values.sub_status_id} onChange={handleSubStatusChange} required>
                <option value="">Select Sub-Status</option>
                {subStatus.map((option) => (
                  <option key={option.sub_status_id} value={option.sub_status_id}>
                    {option.sub_status_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
  
          {/* Conditional Rendering for Field Visit */}
          {field_visit ? (
            <div className="btnmarkdoneflex col-sm-12 mb-b20">
              <div className="box">
                <PartnerFeedVisit
                  field_list_reason={field_list_reason}
                  handlefieldVisitReason={handlefieldVisitReason}
                  leadData={field_visit_data}
                />
              </div>
            </div>
          ) : (
            <>
              {/* Reasons Dropdown for Connected Status */}
              {values.status_id === CONNECTED_STATUS_ID &&
                (values.sub_status_id === POOR_EXPERIENCE_STATUS_ID ||
                  values.sub_status_id === NOT_RELEVANT) && (
                  <div className="btnmarkdoneflex col-sm-12 mb-b20">
                    <div className="box">
                      <h4 className="dropdown-heading2">Reasons*</h4>
                      <select value={values.reason_id} onChange={handleChange} required>
                        <option value="">Select reason</option>
                        {reasonsList.map((option) => (
                          <option key={option.reason_id} value={option.reason_id}>
                            {option.reason_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
  
              {/* Follow-Up Information */}
              {values.status_id === NOT_CONNECTED_STATUS_ID &&
                [
                  BUSY_SUB_STATUS_ID,
                  INVALID_NUMBER_SUB_STATUS_ID,
                  NOT_PICK_SUB_STATUS_ID,
                  OUT_OF_REACH_SUB_STATUS_ID,
                  SWITCHED_OFF_SUB_STATUS_ID,
                  MORE_CALL_SALES,
                  MORE_CALL_SALES_PARTNER
                ].includes(+values.sub_status_id) && (
                  <div style={{ margin: "80px 20px" }}>
                    <div className="mb-b20">Followup</div>
                    <span className="patrnermessageplay">
                      Auto-followup date set for{" "}
                      <span style={{ fontWeight: "500" }}>
                        {getDateAndTimeFormat(values.followup_date)}
                      </span>{" "}
                      as{" "}
                      {subStatus
                        .find(({ sub_status_id }) => sub_status_id === values?.sub_status_id)
                        ?.sub_status_name.toLowerCase()}
                    </span>
                  </div>
                )}

              {/* Interest Level */}
              {values.status_id === CONNECTED_STATUS_ID && 
                values.sub_status_id === INTERESTED_SUB_STATUS_ID && (
                  <div className="btnmarkdoneflex col-sm-6 mb-b20">
                    <div className="box">
                      <h4 className="dropdown-heading2">Interest Level*</h4>
                      <select
                        id="interest_level"
                        value={values.interest_level}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Interest Level</option>
                        {INTEREST_LEVEL_OPTIONS.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
  
              {/* Follow-Up Form */}
              <fieldset className="col-sm-6 mb-b20">
                <FormsyInputField
                  id="followup_time"
                  name="followup_time"
                  type="datetime-local"
                  value={
                    values.followup_date
                      ? getISODateAndTimeFormat(values.followup_date)
                      : ""
                  }
                  onChange={handleDateTimeChange}
                  label="Date/Time"
                  min={getCurrentDateTime()}
                  disabled={isFollowupDisabled || values.status_id === 4}
                />
              </fieldset>
  
              <fieldset className="col-sm-6 mb-b20">
                <FormsyInputField
                  id="title"
                  name="title"
                  type="text"
                  value={values?.title}
                  onChange={handleTitle}
                  label="Title"
                />
              </fieldset>
  
              <fieldset className="col-sm-12 mb-b20">
              <div className="material">
                <textarea
                  name="remarks"
                  id="remarks"
                  placeholder="Add Remark"
                  rows="2"
                  className="form-input"
                  onChange={handleRemarks}
                  value={values.remarks}
                />
                </div>
              </fieldset>
  
              <fieldset className="col-sm-12 mb-b10">
              <div className="material">
                <textarea
                  name="comment"
                  id="comment"
                  placeholder="Comment"
                  rows="2"
                  className="form-input"
                  onChange={handleComment}
                  value={values.comment}
                />
                </div>
              </fieldset>
            </>
          )}
        </div>
      </Formsy>
  
      {/* Validation Messages */}
      {isSubmitted && (
        <>
          {!values.status_id && <span style={{ color: "red" }}>Please select a status<br /></span>}
          {!values.sub_status_id && <span style={{ color: "red" }}>Please select a sub-status</span>}
        </>
      )}
  
      <button
        className="partnerregisterbtn1"
        type="submit"
        onClick={field_visit === true ?handlefieldVisitReasonSubmit : handleSubmit}
        // disabled={isSubmitDisabled}
      >
        Save
      </button>
    </>
  );
  
};

export default SalesRegisteredCallPopup;
