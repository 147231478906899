import React, { useState, useEffect, useRef, memo } from "react";

const Timer = ({ 
  initialSeconds = 0, 
  isRunning 
}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const intervalRef = useRef(null);

  // Start or stop the interval based on isRunning
  useEffect(() => {
    
     
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setSeconds((prev) => {
          const newValue = prev + 1;
        
          return newValue;
        });
      }, 1000);
    } else if (!isRunning && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  

    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isRunning]);



  // If you want to re-calculate once the initialSeconds changes:
  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return [hours, minutes, secs]
      .map((val) => val.toString().padStart(2, "0"))
      .join(":");
  };

 

  return (
    <span style={{ marginLeft:"10px",marginRight: "10px",marginTop:"3px" ,width:"60px"}}>
      {formatTime(seconds)}
    </span>
  );
};

// Use memo if you want to skip re-renders due to parent changes
export default memo(Timer);
