import { GET_ALL_FOLLOWUP, LEADS_HISTORY_QUERY, REFERENCE_FORM_DATA, SAVE_EXPECTED_DATES__LEAD_DETAILS } from '../../services/leads.gql';
import { GET_CALL_ATTEMPT_DATA, UPDATE_CALL_FEEDBACK } from '../../services/leadLogic.gql';
import { getLeadRemarks, getLoanDetail } from '../../store/action/allAction';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import style from "../../style/newLoanRibbon/newLoanRibbon.module.scss";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { IS_USER_ACCESS_PAGE } from '../../../src/config/constants';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { VERIFIER_ROLE_ID } from '../../../src/config/constants';
import LeadStatusService from '../../../src/services/leadStatus'
import { GET_BANK_POC_MAPPING } from '../../services/bank.gql';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import callicon from "./../../webroot/images/callicon.svg";
import { GET_USER_LIST } from '../../services/role.gql';
import { useSelector, useDispatch } from "react-redux";
import FormsySelect from '../elements/FormsySelect';
import BankerPOCMapping from './BankerPOCMapping';
import { useApolloClient } from '@apollo/client';
import OpenModalForm from './Modal/Form.modal';
import EditReference from './EditReference';
import crypto from '../../config/crypto';
import { toast } from 'react-toastify';
import FollowUpsForm from './Followup';
import DateFormate from "dateformat";
import { gql } from '@apollo/client';
import dateFormat from "dateformat";
import Formsy from 'formsy-react';
import Select from "react-select";
import moment from 'moment';

const LoanRibbenBoxNew = (props) => {
    const { 
        LOGIN,
        SANCTIONED,
        INTERESTED_QUALIFIED,
        INTERESTED_CONTACTED,
        NOT_INTERESTED_CONTACTED,
        NOT_INTERESTED_QUALIFIED
    } = LeadStatusService.LEAD_STATUS_MASTER;

    const dateInputRef = useRef(null);
    const [isCallFeedbackModelOpen, setIsCallFeedbackModelOpen] = useState(false);
    let amiyo_click_to_call = IS_USER_ACCESS_PAGE("amiyo_click_to_call", "amiyo_click_to_call")?.is_edit_access;
    let knowlarity_click_to_call = IS_USER_ACCESS_PAGE("knowlarity_click_to_call", "knowlarity_click_to_call")?.is_edit_access;
    const [bankerPOCModalVisibility, setBankerPOCModalVisiblity] = useState(false); 
    const [referenceEditModalVisibililty, setReferenceEditModalVisibility] = useState(false)
    const [bankPOCMappingData, setBankPOCMappingData] = useState([]);
    const [unreadMsgCountWA, setUnreadMsgCountWA] = useState("");
    const [lead_id_encoded, setLeadIdEncode] = useState(null);
    const [bankRecordData, setBankRecordData] = useState([]);
    const [showDateEdit, setShowDateEdit] = useState(false);
    const [expectedDate, setExpectedDate] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [bankerName, setBankerName] = useState("");
    const [followUp, setFollowUp] = useState(false); 
    const [modalType, setModalType] = useState("");
    const [userList, setUserList] = useState([]);
    const [lead_id, setLeadId] = useState(0);
    const [activityHistory, setActivityHistory] = useState([]);
    const [referenceData, setReferenceData] = useState({});
    const client = useApolloClient();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { masterdata, leadDetail } = useSelector(({ masterdata, lead }) => {
        const persistedState = JSON.parse(localStorage.getItem('reduxState'));
        if (persistedState && persistedState?.lead) {
            delete persistedState.lead;
            localStorage.setItem('reduxState', JSON.stringify(persistedState));
          } else {
            console.error('No user data found in localStorage.');
          }
        return {
            masterdata,
            leadDetail: lead.leadDetail,
            remarksHistory: lead.remarksHistory || []
        }
    });

    const getActivityHistoryData = async (lead_id, user_id) => {
        try {
          const [allFollowsData, leadsHistoryData] = await Promise.all([
            executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client),
            executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client),
          ]);
          const customerFollowups = allFollowsData?.data?.get_followup?.filter(obj => obj.followup_with === "customer");
          setActivityHistory(customerFollowups);
        } catch (error) {
          console.error("Error fetching activity history data:", error);
        }
      };

    const showModalFollowUp = () => {
        setFollowUp(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalFollowUp = () => {
        setFollowUp(false);
        document.body.classList.remove('overflow-hidden');
    };


    const getReferenceDetails = (lead_id) => {
        try {
            const mutation = REFERENCE_FORM_DATA(lead_id);
            let variables = {};
            executeGraphQLMutation(mutation, variables, client).then(result => {
                if (result.data) {
                    setReferenceData(result.data.get_reference_details);
                }
            }).catch(err=>{
                console.log(err)
            });
        }catch(error) {

        }
    }

    useEffect(() => {
        if (leadDetail?.id) {
            const user_id = leadDetail?.customer?.customer_id;
            getRemarksHistory(leadDetail.id);
            getBankPOCMappingData(leadDetail.id);
            setLeadId(leadDetail.id);
            setLeadIdEncode(crypto.encode(`${leadDetail.id}`))
            getActivityHistoryData(leadDetail?.id, user_id);
            getReferenceDetails(leadDetail.id);
        }
    }, [leadDetail]);

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
        let dataobject = {...expectedDate}
        dataobject[field_name] = date;
        setExpectedDate(dataobject);
        }
    };

    const getBankPOCMappingData = async (lead_id) => {
        executeGraphQLQuery(GET_BANK_POC_MAPPING(lead_id), client)
        .then((result) => {
            if (result.data) {
                const record = result.data.get_bank_poc_mapping || {};
                if (record?.bank_record.length > 0) {
                    setBankerName(record?.bank_record[0]?.name || "");
                    setBankRecordData(record?.bank_record);
                    setBankPOCMappingData(record?.bank_poc_mapping);
                }
            } else {
                toast.error("Internal Server Error");
            }
        })
        .catch((error) => {
            toast.error(error.message);
        });
    };

    
    
    useEffect(() => {
        if (leadDetail?.id) {
            getRemarksHistory(leadDetail.id);
            getBankPOCMappingData(leadDetail.id);
        }
    }, [leadDetail]);

    useEffect(() => {
        if (leadDetail?.whatsAppUnread?.unread_count && !unreadMsgCountWA.length) {
            setUnreadMsgCountWA(`${leadDetail?.whatsAppUnread?.unread_count} New Message`);
        }
    }, [leadDetail?.whatsAppUnread?.unread_count])

    const { lead } = useSelector(({ lead }) => ({ lead }));

    let assign_to = lead?.leadDetail?.assign_to;

    const handleClickToCallCustomer = () => {
       
           
        
        const user_information = localStorage.getItem('user_information');
        const isQueueVisible = localStorage.getItem("isQueueVisible") || "false";
        let authUser = user_information && JSON.parse(user_information);
        let login_user_id = authUser?.id || 0;
        setIsLoading(true);
        let lead_id = lead?.leadDetail?.id || "";
        let variables = ''
        
        let mutation = gql`mutation{
            dialer_call(
            calling_type:"customer",
            lead_id: ${lead_id},
            user_id: ${assign_to},
            agent_id: ${login_user_id}
            )
        }`;

        executeGraphQLMutation(mutation, variables, client).then(result => {
          if (result.data.dialer_call=='Dialer Calling...') {
            setIsLoading(false);
            toast.success(result.data.dialer_call, {fadeOut: 5000})
          } else {
            setIsLoading(false);
            toast.error(result.data.dialer_call, {fadeOut: 5000});
          }
        //   toast.error(result.data.dialer_call, {fadeOut: 5000});
        //   toast.success('Dialer Calling...', {fadeOut: 5000});
        }).catch((error) => {
          setIsLoading(false);
        });

        //if(isQueueVisible === "true")
        setIsCallFeedbackModelOpen(true);
    }

    const handleClickToCallPartner = () => {
        const user_information = localStorage.getItem('user_information');
        let authUser = user_information && JSON.parse(user_information);
        let login_user_id = authUser?.id || 0;

        setIsLoading(true);
        let lead_id = lead?.leadDetail?.id || "";
        let variables = ''
        
        let mutation = gql`mutation{
            dialer_call(
            calling_type:"partner",
            lead_id: ${lead_id},
            user_id: ${assign_to},
            agent_id: ${login_user_id}
            )
        }`;

        executeGraphQLMutation(mutation, variables, client).then(result => {
          if (result.data.dialer_call=='Dialer Calling...') {
            setIsLoading(false);
            toast.success(result.data.dialer_call, {fadeOut: 5000})
          } else {
            setIsLoading(false);
            toast.error(result.data.dialer_call, {fadeOut: 5000});
          }
        //   toast.error(result.data.dialer_call, {fadeOut: 5000});
        //   toast.success('Dialer Calling...', {fadeOut: 5000});
        }).catch((error) => {
          setIsLoading(false);
        });
    }

    const saveExpectedDate = () => {
        let lead_id = lead?.leadDetail?.id || "";
        let is_allow_history=0;
        let expected_date = (expectedDate.expected_login_date) ? expectedDate.expected_login_date : (expectedDate.expected_approved_date) ? expectedDate.expected_approved_date : (expectedDate.expected_disbursed_date) ? expectedDate.expected_disbursed_date : "";
        const variables = {
            lead_id,
            status_id: leadDetail?.status_id,
            expected_date,
            is_allow_history
        };
    
        executeGraphQLMutation(SAVE_EXPECTED_DATES__LEAD_DETAILS, variables, client)
        .then(result => {
            if (result.data.save_expected_date) {
                dispatch(getLoanDetail(lead_id, client))                
                toast.success(result.data.save_expected_date, { fadeOut: 5000 });
                setShowDateEdit(false);
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    };

    const handleCalendar = () => {
        if (dateInputRef.current) {
            dateInputRef.current.click();
        }
    }

    const handleReferenceEdit = () => {
        setReferenceEditModalVisibility(true);
        document.body.classList.add('overflow-hidden');
    }

    const handleBankerPocEdit = (modal_type) => {
        setBankerPOCModalVisiblity(true);
        setModalType(modal_type);
        document.body.classList.add('overflow-hidden');
    }

    const handleBankerModalClose = () => {
        setBankerPOCModalVisiblity(false);
        document.body.classList.remove('overflow-hidden');
    }

    const handleReferenceEditClose =()=> {
        setReferenceEditModalVisibility(false);
        document.body.classList.remove('overflow-hidden');
    }

    const getRemarksHistory = (lead_id) => {
        dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
    } 

      const handleDoDial = () => {
        try {
          //if (!isAmeyoFrameVisible) return toast.warn("Please Login to Ameyo dashboard");
          if (window.ameyo.integration.doDial) {
            window.ameyo.integration.doDial(`${lead?.leadDetail?.customer?.mobile}`);
            let variables = ''
            let lead_id = lead?.leadDetail?.id || "";
            let mutation = gql`mutation{
                exotel_call_logs(
                lead_id: ${lead_id},
                source: "Lead"
                )
            }`;
            executeGraphQLMutation(mutation, variables, client).then(result => {
            }).catch((error) => {
            });
            setIsCallFeedbackModelOpen(true);
          }
        } catch (error) {
          toast.error(error.message);
        }
      };

    // Remove checklist method as no use here | AMB-66
        
    let calling_reasons = useMemo(() => {
    let substatus = (masterdata?.check_list?.length) ? masterdata?.check_list.filter(ob => ob?.statuslang?.status_id ==6)?.[0]?.substatus : [];
    let calling_reason = substatus?.filter(data => data.id === (leadDetail?.lead_details?.call_status === 'connected' ? 22 : 23))?.map(v => v.calling_reasons)?.[0] || [];
    return calling_reason?.map(v => ({ ...v, value: v.id, label: v.calling_reason }))
    }, [leadDetail?.lead_details?.call_status]);

    calling_reasons = calling_reasons?.filter(data => data.id === leadDetail?.lead_details?.calling_reason_id)?.map(v=>v)?.[0]?.calling_reason || '';

    let dialer_call = '1';//IS_USER_ACCESS_PAGE("dialer", "dialer")?.is_edit_access;

    const getUserList = async () => {
        let user_list = [],userlist=[];
        await executeGraphQLQuery(GET_USER_LIST(5,1),client).then((response) => {
            if (response && response.data && response.data.get_user_list) {
                user_list = response.data.get_user_list?.filter(obj => obj.status === '1');
                user_list && user_list?.map((v)=>{
                    userlist.push({value:v.user_id,label:v.name,role_id:v.role_assigned_ids})
                    return v;
                })
                setUserList(userlist);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            await getUserList();
        };
        fetchData();
    }, []);

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information)
    let makerUserList = userList.find(data => data.value == assign_to && data.value != UserInfo?.id);
    let verifierUserList = userList.find(data => data.role_id.includes(VERIFIER_ROLE_ID) && data.value != UserInfo?.id); 

   

    if (leadDetail && leadDetail.customer) {
        let folloupText;
        if(leadDetail.followup_status =='1' && leadDetail.followup_date){
            if(new Date() <= new Date(leadDetail.followup_date)){
                folloupText = `Upcoming :${dateFormat(leadDetail.followup_date, 'd mmm,yyyy hh:MM')}`
            }else{
                folloupText = `Overdue : ${dateFormat(leadDetail.followup_date, 'd mmm,yyyy hh:MM')}` 
            }
        } 

        class NameFormatter {
            capitalizeFirstLetter(word) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            capitalizeEachWord(name) {
                return name?.split(' ').map(this.capitalizeFirstLetter).join(' ');
            }
            formatFirstName(firstName) {
                return this.capitalizeEachWord(firstName);
            }
        }
        const nameFormatter = new NameFormatter();

        const getFollowupStatus = (followupDate) => {
            const followupDateTime = new Date(followupDate);
            
            const currentDateTime = new Date();
          
            if (followupDateTime > currentDateTime) {
              return 'Upcoming';
            } else if (followupDateTime < currentDateTime) {
              return 'Overdue';
            }
        };

        let followupStatus = "";
        if (activityHistory.length && activityHistory[0]?.followup_status == 2) {
            followupStatus = "Done";
        } else if (activityHistory[0]?.followup_status == 0) {
            followupStatus = getFollowupStatus(activityHistory[0]?.followup_date);
        }


        return (
            <div className="loan-bx-ribben">
                <ul className="newmaindetail-list clearfix ">
                    <li className="maindetail-item">
                        <div className='newmaindetailscommon'>
                            <div className="sub-value">
                                <h3 className="newsubvalue">Lead Id</h3>
                                <div className='newsubheading'>#{leadDetail.id}</div>
                            </div>
                            <div>
                                <div className="newsubvalue">Customer Name</div>
                                <div className='newsubheading'  title={leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}>
                                    {leadDetail?.customer?.first_name +' '+leadDetail?.customer?.last_name}
                                </div>
                            </div>
                            <div>
                                <div className="newsubvalue">Created Date</div>
                                <div className='newsubheading'>{dateFormat(leadDetail?.created_date, 'd mmm,yyyy')}</div>
                            </div>
                        </div>
                        <div className='newallstatus'>

                            <div>
                                <div className="newsubvalue">Source/Sub Source</div>
                                <div className='newsubheading'><span> {leadDetail?.leadSourceInfo?.source}</span> <span> {leadDetail?.is_otp_verified ? "[OTP Verified]" : ""}</span>{(leadDetail?.leadSourceInfo?.source || leadDetail?.leadSourceInfo?.subSource) ? "/" : ""} <span> {leadDetail?.leadSourceInfo?.subSource}</span> <span> {leadDetail?.is_revisit ? `[${leadDetail.is_revisit} Revisits]` : ''}</span></div>
                            </div>
                            <div>
                                <div className="newsubvalue">Reference</div>
                                <div className='newsubheading'  style={{cursor:'pointer'}}>
                                    <span> {referenceData?.reference_name}</span> 
                                    {leadDetail?.leadSourceInfo?.source == "Saathi" && leadDetail?.leadSourceInfo?.subSource == "Partner Referral" &&
                                        <img onClick={() => handleReferenceEdit()} src='https://static.ambak.com/partner/1728465420154.png' />
                                    }
                                </div>
                            </div>
                        </div>
                    </li>                   

                    <li className="maindetail-item">
                        <div className='newallstatus'>
                            <div>
                                <div className="newsubvalue"> Fulfillment Type</div>
                                <div className='newsubheading'><span title={nameFormatter.formatFirstName(leadDetail?.fulfillment_type)}>{nameFormatter.formatFirstName(leadDetail?.fulfillment_type)}</span></div>
                            </div>
                            <div>
                                <div className="newsubvalue">Product</div>
                                <div className='newsubheading'><span title={leadDetail?.loan_type}>{(leadDetail.loan_type===1)?'Home Loan':'LAP'}</span></div>
                            </div>
                            <div>
                                <div className="newsubvalue">Sub-Product</div>
                                <div className='newsubheading'><span title={leadDetail?.loan_sub_type}>{ leadDetail.loan_sub_type === '1' ? 'BT' : leadDetail.loan_sub_type === '2' ? 'TOP UP' : leadDetail.loan_sub_type === '3' ? 'OD' : leadDetail.loan_sub_type === '1,2' ? 'BT/TOP UP' : ''}</span></div>
                            </div>
                        </div>

                        <div className='newallstatus'>
                            <div>
                                <div className="newsubvalue"> Partner</div>
                                <div className='newsubheading'><div className='newdetailsicon'>{leadDetail?.partner_id ? nameFormatter.formatFirstName(leadDetail?.partner_name) + " (AMB"+ leadDetail?.partner_id+")" : ""}{}
                                <img onClick={() => handleBankerPocEdit("partner")} src='https://static.ambak.com/saathi/1728465420154.png' />
                                {
                                dialer_call=='1' ?
                                    leadDetail.fulfillment_type === 'ambak' && 
                                        <img onClick={handleClickToCallPartner} style={{marginLeft:5,cursor:'pointer'}} src={callicon} alt='logo' width={25} height={25} />
                                    :null
                                }
                                </div>
                                </div>
                            </div>
                            <div>
                                <div className="newsubvalue">Relationship Manger</div>
                                <div className='newsubheading'><span title={leadDetail?.loan_type}>{leadDetail?.rmdetails?.label ? nameFormatter.formatFirstName(leadDetail?.rmdetails?.label) : ""}</span></div>
                            </div>
                            
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <div className='newallstatus'>
                            <div>
                                <div className="newsubvalue"> Bank Name</div>
                                <div className='newsubheading'><span>{nameFormatter.formatFirstName(leadDetail?.lead_details?.bank?.banklang?.bank_name || "")}</span></div>
                            </div>
                            {[INTERESTED_CONTACTED,NOT_INTERESTED_CONTACTED,INTERESTED_QUALIFIED,NOT_INTERESTED_QUALIFIED].includes(leadDetail?.status_id) ?
                                <div onClick={handleCalendar} >
                                    <div className="newsubvalue">Expected Login Date</div>
                                    <div className='newsubheading'>
                                        <div className='newdetailsicon'>{leadDetail?.lead_details?.expected_login_date ? dateFormat(leadDetail?.lead_details?.expected_login_date, 'd mmm, yyyy') : ''}
                                            <label htmlFor="dateInput" onClick={() => setShowDateEdit(!showDateEdit)} ><img src='https://static.ambak.com/saathi/1728465420154.png' /></label>
                                            {showDateEdit && <OpenModalForm show={showDateEdit}
                                                handleClose={() => setShowDateEdit(false)}
                                                removeHeight={true}>
                                                <div className={style.mainContainer}>
                                                    <div className={style.headingSection}>
                                                        <h2>Expected Login Date</h2>
                                                    </div>
                                                    <div className={style.contentSection}>
                                                        <Formsy>
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <fieldset className="form-filed col-md-12 ">
                                                                    <FormsyDatePicker
                                                                        name={"expected_login_date"}
                                                                        id={"expected_login_date"}
                                                                        selectedDate={expectedDate?.expected_login_date}
                                                                        placeholder={"Select Date"}
                                                                        minDate={new Date()}
                                                                        value={expectedDate?.expected_login_date}
                                                                        onDateSelect={(date) => handleDateChange(date, "expected_login_date") }
                                                                        DateFormate
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        disabled={false}
                                                                        required={false}
                                                                        hasError={false}
                                                                        className={style.textarea}
                                                                        style={{ border: "1px solid #cccccc" }}
                                                                    />
                                                                </fieldset>
                                                                <button className="newstatusupdate" onClick={() => saveExpectedDate()} >Submit</button>
                                                            </div>
                                                        </Formsy>
                                                    </div>
                                                </div>
                                            </OpenModalForm>}
                                        </div>
                                    </div>
                                </div> :
                                [LOGIN].includes(leadDetail?.status_id) ?
                                <div onClick={handleCalendar} >
                                    <div className="newsubvalue">Expected Sanction Date</div>
                                    <div className='newsubheading'>
                                        <div className='newdetailsicon'>{leadDetail?.lead_details?.expected_approved_date ? dateFormat(leadDetail?.lead_details?.expected_approved_date, 'd mmm, yyyy') : ""}
                                            <label htmlFor="dateInput" onClick={() => setShowDateEdit(!showDateEdit)} ><img src='https://static.ambak.com/saathi/1728465420154.png' /></label>
                                            {showDateEdit && <OpenModalForm show={showDateEdit}
                                                handleClose={() => setShowDateEdit(false)}
                                                removeHeight={true}>
                                                <div className={style.mainContainer}>
                                                    <div className={style.headingSection}>
                                                        <h2>Expected Sanction Date</h2>
                                                    </div>
                                                    <div className={style.contentSection}>
                                                        <Formsy className="lead-form-filed" autoComplete="off">
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <fieldset className="form-filed col-md-12 ">
                                                                    <FormsyDatePicker
                                                                        name={"expected_approved_date"}
                                                                        id={"expected_approved_date"}
                                                                        selectedDate={expectedDate?.expected_approved_date}
                                                                        placeholder={"Select Date"}
                                                                        minDate={new Date()}
                                                                        value={expectedDate?.expected_approved_date}
                                                                        onDateSelect={(date) => handleDateChange(date, "expected_approved_date") }
                                                                        DateFormate
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        disabled={false}
                                                                        required={false}
                                                                        hasError={false}
                                                                        className={style.textarea}
                                                                        style={{ border: "1px solid #cccccc" }}
                                                                    />
                                                                </fieldset>
                                                                <button className="newstatusupdate" onClick={() => saveExpectedDate()} >Submit</button>
                                                            </div>
                                                        </Formsy>
                                                    </div>
                                                </div>
                                            </OpenModalForm>}
                                        </div>
                                    </div>
                                </div> :
                                [SANCTIONED].includes(leadDetail?.status_id) ?
                                <div onClick={handleCalendar} >
                                    <div className="newsubvalue">Expected Disbursal Date</div>
                                    <div className='newsubheading'>
                                        <div className='newdetailsicon'>{leadDetail?.lead_details?.expected_disbursed_date ? dateFormat(leadDetail?.lead_details?.expected_disbursed_date, 'd mmm, yyyy') : ""}
                                            <label htmlFor="dateInput" onClick={() => setShowDateEdit(!showDateEdit)} ><img src='https://static.ambak.com/saathi/1728465420154.png' /></label>
                                            {showDateEdit && <OpenModalForm show={showDateEdit}
                                            handleClose={() => setShowDateEdit(false)}
                                            removeHeight={true}>
                                            <div className={style.mainContainer}>
                                                <div className={style.headingSection}>
                                                    <h2>Expected Disbursal Date</h2>
                                                </div>
                                                <div className={style.contentSection}>
                                                    <Formsy>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <fieldset className="form-filed col-md-12 ">
                                                                <FormsyDatePicker
                                                                    name={"expected_disbursed_date"}
                                                                    id={"expected_disbursed_date"}
                                                                    selectedDate={expectedDate?.expected_disbursed_date}
                                                                    placeholder={"Select Date"}
                                                                    minDate={new Date()}
                                                                    value={expectedDate?.expected_disbursed_date}
                                                                    onDateSelect={(date) => handleDateChange(date, "expected_disbursed_date") }
                                                                    DateFormate
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    disabled={false}
                                                                    required={false}
                                                                    hasError={false}
                                                                    className={style.textarea}
                                                                    style={{ border: "1px solid #cccccc" }}
                                                                />
                                                            </fieldset>
                                                            <button className="newstatusupdate" onClick={() => saveExpectedDate()} >Submit</button>
                                                        </div>
                                                    </Formsy>
                                                </div>
                                            </div>
                                        </OpenModalForm>}
                                        </div>
                                    </div>
                                </div> : ""
                            }
                        </div>

                        <div className='newallstatus'>
                            <div>
                                <div className="newsubvalue"> BANKER</div>
                                <div className='newsubheading'><div className='newdetailsicon'> {bankerName ? ` ${nameFormatter.formatFirstName(bankerName)}` : ""}
                                    <img  onClick={() => handleBankerPocEdit("bank")} src='https://static.ambak.com/saathi/1728465420154.png' /></div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <div className='newallstatus'>
                            <div>
                                <div className="newsubvalue"> Status</div>
                                <div className='newsubheading'><span>{leadDetail?.status_info?.statuslang?.status_name}</span></div>
                            </div>
                            <div>
                                <div className="newsubvalue">Sub Status</div>
                                <div className='newsubheadingcut'title={leadDetail?.sub_status_info?.substatuslang?.sub_status_name} ><span>{nameFormatter.formatFirstName(leadDetail?.sub_status_info?.substatuslang?.sub_status_name)}</span></div>
                            </div>
                        </div>

                        <div className='newallstatus'>
                            <div>
                                <div className="newsubvalue">Last Updated</div>
                                <div className='newsubheading'><span>{leadDetail.updated_date ? dateFormat(leadDetail.updated_date, 'd mmm,yyyy hh:MM') : ""}</span></div>
                            </div>
                            {activityHistory[0]?.followup_with === "customer" ? (
                                    <>
                                    <div style={{ textAlign: 'right' }}>
                                        <div className="newsubvalue">
                                        <span style={{ verticalAlign: "middle", marginRight: 10 }}>Followup</span>
                                        {followupStatus === "Upcoming" ? (
                                            <div className="historydetailsstatusbtnupcoming">Upcoming</div>
                                        ) : followupStatus === "Overdue" ? (
                                            <div className="historydetailsstatusbtnoverdue">Overdue</div>
                                        ) : followupStatus === "Done" ? (
                                            <div className="historydetailsstatusbtndone">Done</div>
                                        ) : null}
                                        </div>
                                        <div className="newsubheading">
                                        <span>
                                            {activityHistory.length && activityHistory[0]?.followup_date
                                            ? dateFormat(activityHistory[0]?.followup_date, 'd mmm,yyyy hh:MM')
                                            : ""}
                                        </span>
                                        </div>
                                    </div>
                                    </>
                                ):(
                                 <div>No Follow Up</div>
                                )}


                            <div className="view-timeline-popup image-timeline-popup">
                                <div className="view-timeline-popup image-timeline-popup">
                                    <Modal show={followUp} handleClose={hideModalFollowUp} width={650} >
                                        <FollowUpsForm />
                                    </Modal>
                                </div>
                            </div> 
                        </div>
                    </li>

                    <li className="maindetail-item">
                        <div className='newassignedflex'>
                            <div>
                                <div className='newallstatus'>
                                    <div>
                                        <div className="newsubvalue">Assigned to</div>
                                        <div className='newsubheading'><span>{makerUserList?.label}</span></div>
                                    </div>
                                </div>

                                <div className='newallstatus'>
                                    <div>
                                        <div className="newsubvalue">Verification Agent</div>
                                        <div className='newsubheading'><span>{verifierUserList?.label}</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className='iconborder'>
                                <div className='newallstatus'>
                                    <div>
                                        <div className="newsubvalue">Communication</div>
                                        <div className='newicon'>
                                            <NavLink to={`/lead-detail/whatsapp/${lead_id_encoded}`} >
                                                <img src='https://static.ambak.com/saathi/1728469675972.png' alt='whatsapp' />
                                            </NavLink>
                                            <NavLink to={`/lead-detail/email/${lead_id_encoded}`} state={{ open: true }} >
                                                <img  src='https://static.ambak.com/saathi/1728469707105.png' alt='message' />
                                            </NavLink>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            {/* {
                                                dialer_call=='1' ?
                                                leadDetail.fulfillment_type === 'ambak' && 
                                                <img width={"34px"} onClick={handleClickToCallCustomer} src='https://static.ambak.com/saathi/1728469713808.png' alt='call'/>
                                                : null
                                            } */}
                                            { amiyo_click_to_call == 1 && (
                                                dialer_call=='1' ?
                                                leadDetail.fulfillment_type === 'ambak' && 
                                                <img width={"34px"} onClick={handleDoDial} src='https://static.ambak.com/saathi/1728469713808.png' alt='call'/>
                                                : null
                                            ) 
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </li>
                </ul>
              
                <div className="view-timeline-popup image-timeline-popup">
                    <div className="view-timeline-popup image-timeline-popup">
                        <Modal show={bankerPOCModalVisibility} handleClose={handleBankerModalClose} width={600} >
                            <BankerPOCMapping
                                existingbankPOCMappingData={bankPOCMappingData}
                                existingBankRecordData={bankRecordData}
                                modalType={modalType}
                                handleParentModalClose={handleBankerModalClose}
                            />
                        </Modal>
                    </div>
                </div>
                <div className="view-timeline-popup image-timeline-popup">
                    <div className="view-timeline-popup image-timeline-popup">
                        <Modal show={referenceEditModalVisibililty} handleClose={handleReferenceEditClose} width={600} >
                            <EditReference
                                lead_status = {leadDetail.status_id}
                                lead_id={leadDetail?.id}
                                handleParentModalClose={handleReferenceEditClose}
                                referenceData={referenceData}
                            />
                        </Modal>
                    </div>
                </div>
                <div className="view-timeline-popup">
        {leadDetail.status_id != "5" && isCallFeedbackModelOpen == true ? <Modal
          show={isCallFeedbackModelOpen}
          children={<CallStatus setIsCallFeedbackModelOpen={setIsCallFeedbackModelOpen} leadDetail={leadDetail} userId={UserInfo.id} lead = {lead} Set/>}
          height={500}
          
        /> : null}
      </div>
            </div>
        )
    }
}

const Modal = ({ handleClose, show, children, width }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main' style={{ width: width ? width : 'auto' }}>
                {children}
                <button onClick={handleClose} className="close_icn">
                    {handleClose ? <i className="ic-clearclose"></i>:""}
                    
                </button>
            </section>
        </div>
    );
};
export default LoanRibbenBoxNew;



const CallStatus = ({ setIsCallFeedbackModelOpen, userId, leadDetail,lead}) => {

    const client = useApolloClient();
    const now = new Date();
    const currhour = Number(now.getHours());
    const currmin = now.getMinutes();

    const [minTime, setMinTime] = useState(now.setHours(currhour, currmin, 0));
   const [automaticFollowup, setAutomaticFollowup] = useState(false);
    const [formData,setFormData] = useState({
        updateCallFeedbackInput: {
          lead_id: leadDetail.id,
          call_status: null,
          comment: "",
          followup_date: "",
          followup_mode:2,
          priority: lead?.prioritize_lead || 0,
        },
        api_called_by: "web",
        partner_id: leadDetail.partner_id,
        user_id: userId
      })
   
  
    const options = [
      {
        value: "Customer disconnected the call",
        label: "Customer disconnected the call",
      },
      {
        value: "Call busy",
        label: "Call busy",
      },
      {
        value: "Ringing But Not Answering",
        label: "Ringing But Not Answering",
      },
      {
        value: "Switched off",
        label: "Switched off",
      },
      {
        value: "Invalid Number",
        label: "Invalid Number",
      },
      {
          value: "Not Reachable",
          label: "Not Reachable",
      },
      {
        value: "Does not exist",
        label: "Does not exist",
      }
      
    ];
    const pendingOptions = [
        {
            value: "customer",
            label: "Customer",
          },
          {
            value: "financier",
            label: "Financier",
          },
          {
            value: "saathi",
            label: "Saathi",
          },
          {
            value: "ambak",
            label: "Ambak",
          },
          {
            value: "yoddha",
            label: "Yoddha",
          },
    ]

    const handleRadioChange = (event) => {
        const { checked, id } = event.target;
        let value = "";
        if (id === "not-connect") {
            fetchCallAttemptData();
            value = "1";
        } else if (id === "connect") {
            value = "0";
        }
        setFormData((prev) => ({
            ...prev,
            updateCallFeedbackInput: {
              ...prev.updateCallFeedbackInput,
              lead_id:leadDetail.id,
              call_status: value,
              priority: lead?.prioritize_lead || 0,
            },
            partner_id:leadDetail.partner_id
        }));
    }
 

    const  handleSubmit =async()=>{
        
        if(formData.updateCallFeedbackInput.call_status === "1" && (formData.updateCallFeedbackInput.comment === "" || formData.updateCallFeedbackInput.comment === null)){
            toast.error("Please provide what went wrong");
            return;
        }
        if(formData.updateCallFeedbackInput.call_status === "1" && (formData.updateCallFeedbackInput.followup_with === "" || formData.updateCallFeedbackInput.followup_with === null)){
            toast.error("Please provide follow up with");
            return;
        }
        if(formData.updateCallFeedbackInput.call_status !== "")
        try{
        const result = await executeGraphQLMutation(UPDATE_CALL_FEEDBACK,formData, client);
			if(result?.data){
                setIsCallFeedbackModelOpen(false)
				toast.success(result?.data?.saveusers?.message || "Success");
                const activityDivRef = document.getElementById("referenceForRenderComponent");
                activityDivRef.click();
				setFormData(() => ({
                    updateCallFeedbackInput: {
                        lead_id: leadDetail.id,
                        comment: "",
                        followup_date: "",
                        priority: 2,
                        followup_with:"",
                        call_status: null,
                      },
                      api_called_by: "web",
                      partner_id: leadDetail.partner_id,
                      user_id: userId
                }));
			}
		  } catch (error) {
			toast.error(error.toString().replace('ApolloError:',''));
			console.error(error);
		  }
        
    }

    const handleDateChange = (date) => {
        if (DateFormate(now, "yyyy-mm-dd") != DateFormate(date, "yyyy-mm-dd")) {
            setMinTime(now.setHours(0, 0, 0));
        } else {
            setMinTime(now.setHours(currhour, currmin, 0));
        }
        if (date) {
            setFormData((prev) => ({
                ...prev,
                updateCallFeedbackInput:{
                    ...prev.updateCallFeedbackInput,
                    followup_date: date,
                }
                
              }));
        }
    };

   // console.log("FormData",formData);
    
    const fetchCallAttemptData = async()=>{
        try{
            const result = await executeGraphQLMutation(GET_CALL_ATTEMPT_DATA,{lead_id: leadDetail.id,partner_id: leadDetail.partner_id}, client);
                if(result){
                   if(result?.data?.get_call_attempt_data?.called_attempt_data.length < 1){
                    setAutomaticFollowup(true);
                    setFormData((prev) => ({
                        ...prev,
                        updateCallFeedbackInput:{
                            ...prev.updateCallFeedbackInput,
                            // comment:"Automatic Follow Up By the System.",
                            followup_with:"customer",
                            followup_date: convertUTCToIST(result.data.get_call_attempt_data.next_time),
                        }
                        
                      }));
                   }else {
                    setAutomaticFollowup(false);
                    setFormData((prev) => ({
                        ...prev,
                        updateCallFeedbackInput:{
                            ...prev.updateCallFeedbackInput,
                            followup_with:"customer",
                            followup_date: convertUTCToIST(result.data.get_call_attempt_data.next_time),
                        }
                        
                      }));
                   }
                    
                }
              } catch (error) {
                toast.error(error.toString().replace('ApolloError:',''));
                console.error(error);
              }
    }

    function convertUTCToIST(utcString) {
        // Parse the UTC string using Moment in UTC mode
        const utcMoment = moment.utc(utcString); // `moment.utc()` handles the UTC time
        
        // Add 5 hours and 30 minutes to convert to IST
        const istMoment = utcMoment.add(5, 'hours').add(30, 'minutes');
        
        // Return formatted IST time in ISO string format, but without adding the timezone offset
        return istMoment.format("YYYY-MM-DDTHH:mm:ss"); // This will give the date in ISO format without the offset
    }
    
    
    function convertISOToCustomFormat(isoString) {
        const date = new Date(isoString);
    
        // Extract components
        const hours24 = date.getHours(); // Local hours
        const minutes = date.getMinutes();
        const day = date.getDate();
        const monthIndex = date.getMonth(); // Zero-based
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
        // Format time to 12-hour clock
        const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
        const period = hours24 < 12 ? "AM" : "PM";
    
        // Format minutes to always have 2 digits
        const formattedMinutes = String(minutes).padStart(2, "0");
    
        // Format day suffix
        const suffix = (day % 10 === 1 && day !== 11) ? "st" :
                       (day % 10 === 2 && day !== 12) ? "nd" :
                       (day % 10 === 3 && day !== 13) ? "rd" : "th";
    
        // Combine formatted string
        return `${hours12}:${formattedMinutes} ${period} on ${day}${suffix} ${months[monthIndex]}.`;
    }
    
   
    
    
    
  
    return (
      <div className="calling-status-modal-content-container">
        <div className="heading-1">
          You just tried calling the - <strong>Customer/Partner</strong>
        </div>
        <p>Please tell us how it went</p>
        <div className="radio-container" style={{ marginBottom: 10 }}>
          <div className="radio-btn">
            <input
              type="radio"
              id="not-connect"
              name="call-status"
              onChange={handleRadioChange}
              checked={formData.updateCallFeedbackInput.call_status === "1"}
            />
            <label htmlFor="not-connect">Call did not Connect</label>
          </div>
          <div className="radio-btn">
            <input
              type="radio"
              id="connect"
              name="call-status"
              onChange={handleRadioChange}
              checked={formData.updateCallFeedbackInput.call_status === "0"}
            />
            <label htmlFor="connect">Call Connected</label>
          </div>
        </div>

        {/* Conditional rendering based on selected radio button */}
        {formData.updateCallFeedbackInput.call_status === "1" && (
          <div className="not-connected-form-container">
            <div className="not-connected-info">
              <img
                src="https://static.ambak.com/saathi/1732535024767.png"
                alt="img"
              />
              <span>Okay! Scheduling a follow up for {convertISOToCustomFormat(formData.updateCallFeedbackInput.followup_date)}</span>
            </div>
            <div style={{ marginBottom: "5px" }}>What's went wrong?</div>
                <div className="single-select">
                  <Select
                    options={options}
                    required = {true}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        updateCallFeedbackInput: {
                          ...prev.updateCallFeedbackInput,
                          comment: e.value,
                        },
                      }));
                    }}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  <p>Please schedule a follow up for this call</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    <div className="datepicker" style={{ width: "45%" }}>
                     
                      <Formsy>
                        <FormsyDatePicker
                          name={"follow_up_datetime"}
                          id={"follow_up_datetime"}
                          selectedDate={
                            formData.updateCallFeedbackInput.followup_date
                          }
                          placeholder={"Follow-up"}
                          fieldTitle={
                            formData.updateCallFeedbackInput.followup_date
                              ? "Follow-up"
                              : "NA"
                          }
                          value={formData.updateCallFeedbackInput.followup_date}
                          onDateSelect={(date) =>
                            handleDateChange(date, "follow_up_datetime")
                          }
                          DateFormate
                          showYearDropdown
                          showMonthDropdown
                          showTimeSelect={true}
                          timeIntervals={15}
                          minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                          minTime={minTime}
                          maxTime={new Date().setHours(19, 0)}
                          disabled={automaticFollowup}
                          hasError={false}
                          className={style.textarea}
                          isFieldRequired={true}
                          style={{ border: "1px solid #cccccc" }}
                        />
                      </Formsy>
                    </div>
                    <div className="single-select" style={{ width: "45%" }}>
                      
                      <Formsy>
                        <FormsySelect
                        name="pending-with"
                        inputProps={{
                            options:
                              pendingOptions,
                            placeholder: "Pending with",
                            value: pendingOptions.find(
                                  (option) => option.value === formData?.updateCallFeedbackInput?.followup_with
                                ) || { value: "customer", label: "Customer" }
                            ,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            isDisabled:automaticFollowup
                          }}
                        value={formData?.updateCallFeedbackInput?.followup_with || "Customer"}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            updateCallFeedbackInput: {
                              ...prev.updateCallFeedbackInput,
                              followup_with: e.value,
                            },
                          }));
                        }}
                        />
                      </Formsy>
                     
                    </div>
                  </div>
                </div>
          </div>
        )}

        <button className="calling-status-done-button" onClick={handleSubmit}>
          Done
        </button>
        <div className="info">
          {" "}
          <img
            src="https://static.ambak.com/saathi/1732534278882.png"
            alt="img"
            height="10px"
          />
          If the call was connected, You’ll have to update status.
        </div>
      </div>
    );
  };
  
