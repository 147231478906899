import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../elements/Loader';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../elements/FormsySelect';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";
import { CREATE_INSAURANCE_RECORD, GET_INSURANCE_RECORD } from '../../services/mis.gql';

const INSURANCE_CROSS_FORM = FormFields["INSURANCE_CROSS_SELL"];
const COLLECTION_CROSS_FORM = FormFields["COLLECTION_CROSS_SELL"];

const DisbursedLead = (props) => {
  const [insurance, setInsuranceData] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [data, setData] = useState([])
  const client = useApolloClient();
  const dispatch = useDispatch();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let [loading, setIsLoading] = useState(false);
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({ masterdata, lead }));
  const [showForm, setShowForm] = useState(false);
  const [refresh, setrefresh] = useState(false);
  

  let lead_id = lead?.leadDetail?.id || "";

  useEffect(() => {
    const GetInsuranceRecord = async (lead_id) => {
      try {
        const resp = await executeGraphQLQuery(GET_INSURANCE_RECORD(lead_id), client);
        setData(resp?.data?.get_insurance_record);
      } catch (error) {
        console.error("GraphQL error:", error);
        toast.error("Please try again!");
      }
    };

    GetInsuranceRecord(lead_id);
  }, [refresh]);
  let ALL_OPTIONS = {
    insurance_type: [
      { id: "Home Insurance", label: "Home Insurance" },
      { id: "Property Insurance", label: "Property Insurance" }
    ],
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setInsuranceData(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleInputChange = event => {
    if (event.target.id) {
      setInsuranceData(currentValue => ({
        ...currentValue,
        [event.target.id]: event.target.value
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setInsuranceData(currentValue => ({
        ...currentValue,
        [field_name]: data.id
      }))

    }
    console.log("-----", insurance)
  }
  const createInsauranceRecord = async(variables) =>{
    try {
      const resp = await executeGraphQLMutation(CREATE_INSAURANCE_RECORD, variables,client);
      // toast.success(resp);
      return resp
    } catch (error) {
      toast.error("Something went wrong!")
    }
  }

  const handleSubmit = async () => {
    try{

      let lead_id = lead?.leadDetail?.id || "";
      
    const variables = {
        insuranceRecord: {
          insuranceData: [{
            lead_id: lead_id,
            insurance_type: insurance?.insurance_type,
            selected_premium: insurance?.premium_amount,
            selected_supplier: insurance?.insurer_id,
            total_sum_insured: +insurance?.sum_insured,
            policy_tenure: +insurance?.tenure,
            per_collection: +insurance?.collection_percentage,
            expected_payin_amount: insurance?.collection_amount
          }]
        }
      };

      const resp = await createInsauranceRecord(variables);
      setrefresh(!refresh)
      setInsuranceData({
        insurance_type: "",
        insurer_id: "",
        premium_amount: "",
        sum_insured:"",
        tenure: "",
        collection_percentage:"",
        collection_amount:""
      })
    }
    catch(err){
      console.log("++++++++++++++++++ error",err);
    }
  }

  const isSubmitDisabled = useMemo(() => {
    return (lead?.leadDetail?.status_id != 4) ? true : false;
  }, [allowSubmit, getContext]);

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="65" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Insurance & Cross Sell</h2>
          </div>
            {data?.length > 0 &&
              <div className="table-container">
                    <table style={{paddingBottom:"10px"}}>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>% Collection</th>
                          <th>Coll. Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? data.map((data, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{data?.id}</td>
                              <td>{data?.insurance_type}</td>
                              <td>{data?.selected_premium}</td>
                              <td>{data?.per_collection }</td>
                              <td>{data.expected_payin_amount}</td>
                            </tr>
                          </React.Fragment>
                        )) : null}
                      </tbody>
                    </table>
              </div>
            }
          {showForm === false ?
            <div className="btn-save-remarks" style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
              <button type="button" className="btn-primary" onClick={() => setShowForm(true)}>Add New</button>
            </div>
            :
            <>
              <div className="lead-form-heading">
                <h2 style={{ paddingBottom: "5px" }}>Add New Insurance</h2>
              </div>
              <div className="lead-form-heading">
                <h4 style={{ fontSize: "14px", marginBottom: "14px" }}>Insurance Details</h4>
              </div>
              <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => setAllowSubmit(true)}
                onInvalid={() => setAllowSubmit(false)}
                onValidSubmit={handleSubmit}

              >
                <div className="row" >
                  {INSURANCE_CROSS_FORM.map((field) =>
                    ["text", "number-format"].includes(field.type) ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyInputField
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          value={insurance[field.name]}
                          placeholder=" "
                          thousandsGroupStyle="lakh"
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          label={field.label}
                          onChange={
                            field.type === "number-format"
                              ? (data) => handlePatternFormatChange(data, field.name)
                              : handleInputChange
                          }
                          format={field.ApplyNumberformat ? field.format : ""}
                          validations={field.validations}
                          validationError={
                            insurance[field.name] ? field.validationError : ""
                          }
                          readOnly={field.readOnly || (insurance.insurance_type_id && field.name === 'premium_amount')}
                          required={field.required}
                        />
                      </fieldset>
                    ) :
                      field.type === "dropdown" ? (
                        <fieldset class="single-select col-md-6">
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == insurance[field.name]),
                              isDisabled: field.readOnly
                            }}
                            required={field.required}
                            // value={field.name}
                            onChange={(data) => handleSelectChange(data, field.name)}
                          />
                        </fieldset>
                      ) : null
                  )
                  }
                </div>
                <div className="lead-form-heading">
                  <h4 style={{ fontSize: "14px", marginBottom: "14px" }}>  Collection Details</h4>
                </div>
                <div className="row" >
                  {COLLECTION_CROSS_FORM.map((field) =>
                    ["text", "number-format"].includes(field.type) ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyInputField
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          value={insurance[field.name]}
                          placeholder=" "
                          thousandsGroupStyle="lakh"
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          label={field.label}
                          onChange={
                            field.type === "number-format"
                              ? (data) => handlePatternFormatChange(data, field.name)
                              : handleInputChange
                          }
                          format={field.ApplyNumberformat ? field.format : ""}
                          validations={field.validations}
                          validationError={
                            insurance[field.name] ? field.validationError : ""
                          }
                          readOnly={field.readOnly || (insurance.insurance_type_id && field.name === 'premium_amount')}
                          required={field.required}
                        />
                      </fieldset>
                    ) :
                      field.type === "dropdown" ? (
                        <fieldset class="single-select col-md-6">
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == insurance[field.name]),
                              isDisabled: field.readOnly
                            }}
                            required={field.required}
                            value={field.name}
                            onChange={(data) => handleSelectChange(data, field.name)}
                          />
                        </fieldset>
                      ) : null
                  )
                  }
                </div>
                <div className="btn-save-remarks">
                  <button type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit}>Add New</button>
                </div>
              </Formsy>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default DisbursedLead;