import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import secureStorage from "../../config/encrypt";
import { REPORT_VERIFICATION_LIST, REPORTING_STATUS } from "../../services/mis.gql";
import { useApolloClient, gql } from '@apollo/client';
import Select from "react-select";
import Modal from "../elements/Modal";
import DownloadReport from './DownloadReport';
import { toast } from 'react-toastify';
import { Tab, Nav } from "react-bootstrap";
import * as moment from "moment";
import { convertJsonToCsv, downloadFile } from "../../helpers/helpers";

const ReportingMonth = (props) => {
  const client = useApolloClient();

  const [reportList, setReportList] = useState([]);
  const [reportingYear, setYearList] = useState([]);
  const [reportMonth, setMonthList] = useState([]);
  const [reportingStatus, setReportongStatusList] = useState([]);
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [closeReport, setCloseReport] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [activeProductTab, setActiveProductTab] = useState("verification_failed");
  const [reportVerificationList, setReportVerificationList] = useState(null);
  const [closeReportingMonthData, setCloseReportingMonthData] = useState(null);

  const afterLoginSetState = (loginResponse) => {
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return <Navigate to={"/"} />;
    }
  };

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Navigate to={"/login"} />;
    }
  };

  const getReportingSttaus = async () => {
    try {
      console.log("filterData", filterData);
      const response = await executeGraphQLQuery(REPORTING_STATUS(5, filterData?.month, filterData?.year, filterData?.reporting_status), client);
      console.log("response", response);
      if (response.data?.reporting_status) {
        setReportList(response.data?.reporting_status || []);
      }
      if (response.data?.masterdata?.report_month_list) {
        setMonthList(response.data?.masterdata?.report_month_list || []);
      }
      if (response.data?.masterdata?.report_year_list) {
        setYearList(response.data?.masterdata?.report_year_list || []);
      }
      if (response.data?.masterdata?.report_status_list) {
        setReportongStatusList(response.data?.masterdata?.report_status_list || []);
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
    }
  };

  useEffect(() => {
    getReportingSttaus();
  }, []);

  const resetFilter = () => {
    window.location.reload();
  };


  const downloadReport = (data, month, year) => {

    const month_name = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    }
    const verificationFailed = data?.verification_failed_data?.map((data) => {
      return {
        "Lead Id": data.lead_id,
        "Tranche Id": data.tranche_id,
        "Disbursed Date": data?.disbursed_date,
        "Disbursed Amount": data?.disbursed_amount,
        "Verification Status": "Verification Failed"
      }
    }) || [];

    const verificationPending = data?.verification_pending_data?.map((data) => {
      return {
        "Lead Id": data.lead_id,
        "Tranche Id": data.tranche_id,
        "Disbursed Date": data?.disbursed_date,
        "Disbursed Amount": data?.disbursed_amount,
        "Verification Status": "Verification Pending"
      }
    }) || [];

    
    let jsonArray = [...verificationFailed, ...verificationPending];
    
    const csvData = jsonArray && jsonArray.length>0 ? convertJsonToCsv(jsonArray) : null;
    downloadFile(csvData, "text/csv", month_name[month] + "_" +year + "_non_verified_cases");
  }

  const handleReportOpen = async (month, year, report_id) => {
    try {
      const response = await executeGraphQLQuery(REPORT_VERIFICATION_LIST(report_id, props.user_id), client);
      if (response?.data?.get_reporting_verification_list) {
        let close_month_data = {
          month, year, report_id
        }
        setCloseReportingMonthData(close_month_data);
        setReportVerificationList(response?.data?.get_reporting_verification_list?.data);
        setCloseReport(true);
        downloadReport(response?.data?.get_reporting_verification_list?.data, month, year);
        document.body.classList.add("overflow-hidden")
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
      toast.error('Failed to fetch reporting month details');
    }

  };

  const handleReportClose = () => {
    setCloseReport(false);
    document.body.classList.remove("overflow-hidden")
  };

  const handleModalOpen = () => {
    setModalVisiblity(true);
  };

  const handleModalClose = () => {
    setModalVisiblity(false);
  };

  const handleFilterApply = () => {
    getReportingSttaus();
  };

  const handleChange = async (n, v, isMulti, opt, optl) => {
    switch (n) {
      case 'year':
        setFilterData((prev) => ({ ...prev, [n]: parseInt(v.id) }));
        break;
      case 'month':
        setFilterData((prev) => ({ ...prev, [n]: parseInt(v.id) }));
        break;
      case 'reporting_status':
        setFilterData((prev) => ({ ...prev, [n]: (v.label).toString() }));
        break;
    }
  }

  const closeReportingMonth = (month, year, report_id, api_called_by) => {
    let user_id = props.user_id;

    const mutation = gql`
        mutation UpdateReportingStatus($month: Float!, $year: Float!, $report_id: Float!, $user_id: Float!, $api_called_by: API_CALL_BY!) {
          close_reporting_month(
            month: $month,
            year: $year,
            report_id: $report_id,
            user_id: $user_id,
            api_called_by: $api_called_by
          )
        }
      `;

    const variables = {
      month: Number(month),
      year: Number(year),
      report_id: Number(report_id),
      user_id: Number(user_id),
      api_called_by: "web"
    };

    executeGraphQLMutation(mutation, variables, client)
      .then(result => {
        if (result && result.data) {
          setIsButtonVisible(false);
          setCloseReport(false);
          window.location.reload();
          toast.success('Reporting Month Updated successfully');
        } else {
          toast.error('Failed to update reporting month');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error(error?.message || "Error occurred during the mutation");
      });
  };

  const formatToINR = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  }


  return (
    <>
      <div className="container-fluid">
        <h2 className="top-heading">Disbursement Reporting Months</h2>
        <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Select Year</label>
              <Select
                options={reportingYear}
                placeholder={"Year"}
                name={"year"}
                optionLabel="label"
                optionValue="id"
                onChange={(e) => handleChange("year", e)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                    color: 'black',
                  }),
                }}
              />
            </div>
            <div className="col sm-2 col-md-2">
              <label>Select Month</label>
              <Select
                options={reportMonth}
                placeholder={"Month"}
                name={"month"}
                optionLabel="label"
                optionValue="id"
                onChange={(e) => handleChange("month", e)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                    color: 'black',
                  }),
                }}
              />
            </div>

            <div className="col sm-2 col-md-2">
              <label>Status</label>
              <Select
                options={reportingStatus}
                placeholder={"Status"}
                name={"reporting_status"}
                optionLabel="label"
                optionValue="label"
                onChange={(e) => handleChange("reporting_status", e)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                    color: 'black',
                  }),
                }}
              />
            </div>

            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={handleFilterApply}
                >
                  {"Search"}
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilter}
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            marginBottom: "5px",
          }}
        >
          {/* { <button
             className="add-schemebtn"
            type="button"
            onClick={handleModalOpen}
          >
            Add Reporting Month
          </button> } */}

        </div>

        <div className="view-timeline-popup">
          <Modal show={modalVisiblity} handleClose={handleModalClose}>
            <>
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <label>Select Year</label>
                  <Select
                    options={reportingYear}
                    placeholder={"Year"}
                    name={"year"}
                    optionLabel="label"
                    optionValue="id"
                    value=""
                    onChange={(e) => handleChange("year", e)}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                        color: 'black',
                      }),
                    }}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label>Reporting Month</label>
                  <Select
                    options={reportMonth}
                    placeholder={"Month"}
                    name={"month"}
                    optionLabel="label"
                    optionValue="id"
                    value=""
                    onChange={(e) => handleChange("month", e)}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                        color: 'black',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="text-center" style={{ marginTop: 20 }}>
                <button className="btn btn-primary" type="button" >
                  Save
                </button>
              </div>
            </>
          </Modal>
        </div>

        <div className="mark-lost-popup">
          <Modal show={closeReport} handleClose={handleReportClose}>
            <div className="reportlistcard">
              <div className="reportingvariheading">Reporting Verification List</div>
              <div className="reportlistingtotal">
                <div className="reporttrance">Total tranches</div>
                <div className="reporttrancecount">{reportVerificationList?.total_tranches_count || ""}</div>
              </div>
              <div className="reportlistingtotal">
                <div className="reporttrance">Verified</div>
                <div className="reporttrancecount">{reportVerificationList?.verified_count || ""}</div>
              </div>
              <div className="reportlistingtotal">
                <div className="reporttrance">Verification Pending</div>
                <div className="reporttrancecount">{reportVerificationList?.verification_pending_count || ""}</div>
              </div>
              <div className="reportlistingtotal">
                <div className="reporttrance">Verification Failed</div>
                <div className="reporttrancecount">{reportVerificationList?.verification_failed || ""}</div>
              </div>
              <div className="reportlistingtotal">
                <div className="reporttrance">Scheme Not Found</div>
                <div className="reporttrancecount">{reportVerificationList?.scheme_not_found || ""}</div>
              </div>
              <div className="reportlistingtotal">
                <div className="reporttrance">Payout Not Calculated</div>
                <div className="reporttrancecount">{reportVerificationList?.payout_not_found || ""}</div>
              </div>
              <div className="reportlistingtotal">
                <div className="reporttrance">MIS Not Uploaded</div>
                <div className="reporttrancecount">{reportVerificationList?.mis_not_found || ""}</div>
              </div>
            </div>

            <div className="tab-data" style={{ marginBottom: 20 }}>
              <Tab.Container Not Calculated
                id="left-tabs-example"
                activeKey={activeProductTab}
                onSelect={(tab) => setActiveProductTab(tab)}
              >
                <Nav variant="pills" className="flex-column nav nav-pills">
                  <div className="tab-list">
                    <Nav.Item>
                      <Nav.Link eventKey="verification_failed">
                        Verification Failed
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                  <div className="tab-list">
                    <Nav.Item>
                      <Nav.Link eventKey="verification_pending">
                        Verification Pending
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                </Nav>
              </Tab.Container>
            </div>
            Tab.Container
            {activeProductTab === "verification_failed" && (
              <>
                <div className="clearfix">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered tablefontsize">
                        <thead>
                          <tr>
                            <th>{"Lead ID"}</th>
                            <th>{"Tranche ID"}</th>
                            <th>{"Disb. Date"}</th>
                            <th>{"Disb. Amt."}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          reportVerificationList?.verification_failed_data.map((point, index) => (
                            <>
                              <tr key={index}>
                                <td>{point.lead_id}</td>
                                <td>{point.tranche_id}</td>
                                <td>{point.disbursed_date ? moment(point.disbursed_date).format("DD-MM-YYYY") : ""}</td>
                                <td>{point.disbursed_amount}</td>
                              </tr>
                            </>
                          ))
                        
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}

            {activeProductTab === "verification_pending" && (
              <>
                <div className="clearfix">
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-bordered tablefontsize">
                        <thead>
                          <tr>
                            <th>{"Lead ID"}</th>
                            <th>{"Tranche ID"}</th>
                            <th>{"Disb. Date"}</th>
                            <th>{"Disb. Amt."}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportVerificationList?.verification_pending_data.map((point, index) => (
                            <>
                              <tr key={index}>
                                <td>{point.lead_id}</td>
                                <td>{point.tranche_id}</td>
                                <td>{point.disbursed_date ? moment(point.disbursed_date).format("DD-MM-YYYY") : ""}</td>
                                <td>{point.disbursed_amount}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="reportbtncenter" style={{ marginTop: 20 }}>
              <button className="reportyesbtn" type="button"
                onClick={(e) => closeReportingMonth(closeReportingMonthData.month, closeReportingMonthData.year, closeReportingMonthData.report_id, 'web')}
              >
                Close month Anyway
              </button>
              <button className="reportnobtn" type="button"
                onClick={() => handleReportClose}
              >
                No
              </button>
            </div>
          </Modal>
        </div>

        <div className="clearfix">
          <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize">
                <thead>
                  <tr>
                    <th>{"Month"}</th>
                    <th>{"Year"}</th>
                    <th>{"Status"}</th>
                    <th>{"Total Disbursement Count"}</th>
                    <th>{"Total Tranche Count"}</th>
                    <th>{"Total Disbursement Amount"}</th>
                    <th>{"Total Freezed Disbursement Count"}</th>
                    <th>{"Total Freezed Tranche Count"}</th>
                    <th>{"Total Freezed Disbursement Amount"}</th>
                    <th>{"Report"}</th>

                  </tr>
                </thead>
                <tbody>

                  { reportList && reportList.length > 0 ? reportList.map((point, index) => (
                    <>
                      <tr key={index}>
                        <td>{point.month_name}</td>
                        <td>{point.year}</td>
                        <td>{point.reporting_status}</td>
                        <td>{point.total_lead_count}</td>
                        <td>{point.total_disbursed_lead}</td>
                        <td>{point?.total_disbursed_amount ? formatToINR(point.total_disbursed_amount) : 'NA'}</td>
                        <td>{point.freezed_total_lead_count || 'NA'}</td>
                        <td>{point.freezed_total_disbursed_lead || 'NA'}</td>
                        <td>{point?.freezed_total_disbursed_amount ? formatToINR(point.freezed_total_disbursed_amount) : 'NA'}</td>
                        <td>{point.total_disbursed_lead ?
                          (
                            <div className="btn-submit-reset top-btn-none ">
                              {point.reporting_status == 'open' && isButtonVisible ? (
                                <div class="btn-group btn-group-small mrg-r5">
                                  <div class="btn btn-default" title="Freeze Month" onClick={(e) => handleReportOpen(point.month, point.year, point.id)}>
                                    <i class="ic-clearclose icons-normal" style={{ color: "green" }}></i>
                                    {/* <img src="nfc-lock.png" alt="Freezed Report" width={18}height={20}/> */}
                                  </div>

                                  {/* <div class="btn btn-default" title="Freeze Month" onClick={(e) => closeReportingMonth(point.month, point.year, point.id, 'web')}>
                                  <i class="ic-clearclose icons-normal" style={{color:"green"}}></i>
                                  <img src="nfc-lock.png" alt="Freezed Report" width={18}height={20}/>
                                </div> */}
                                </div>
                                //   <button 
                                //   type="button"
                                //   className="btn btn-primary mrg-r10"
                                //   onClick={(e) => closeReportingMonth(point.month, point.year, 'web')}
                                // >
                                //   {"Close Reporting"}
                                // </button>
                              ) : ""}
                              
                              <DownloadReport report_id={point.id} user_id={1} reporting_status={point.reporting_status} />
                            </div>
                          ) : ("")}

                        </td>
                      </tr> 
                    </>
                  ))
                  : <tr><td colSpan={10}>No record found!</td></tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  let user_information = state.user?.user_information;

  // Parse the JSON string if it exists
  const userInfo = typeof user_information === 'string' ?
    JSON.parse(user_information) :
    user_information;

  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
    user_id: userInfo?.id || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(ReportingMonth)
);
