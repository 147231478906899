import { gql, useApolloClient } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { toast } from 'react-toastify';
import Loader from "../elements/Loader";
import Modal from "../elements/partnerModal";
import style from "../../style/offershare.module.scss"
import { Nav, Tab } from "react-bootstrap";

const OfferShare = (checkedOffers) => {  
  const client = useApolloClient();
  const { lead } = useSelector(({ lead }) => ({ lead }));
  const [selectedOffers, setSelectedOffers] = useState([...checkedOffers?.checkedOffers]);
  let [loading, setIsLoading] = useState(false);
  const [htmlTemplate, setHtmlTemplate] = useState("");
  const [templatePopup, setTemplatePopup] = useState(false);
  const [activeOffer, setActiveOffer] = useState(0);
  const [isOfferShared, setIsOfferShared] = useState(false);

  const handleRemoveOffer = (index) => {
    let data=selectedOffers.splice(index,1);
    setSelectedOffers(data);
    setActiveOffer(0);
  }

  const handleShareOffer = () => {
    setIsOfferShared(true);
    submmitBankOfferData(true);
  };


  useEffect(() => {
    setSelectedOffers(checkedOffers?.checkedOffers)
  }, [checkedOffers?.checkedOffers?.length]);

  const handleInputChange = (event, activeOffer, key) => {
    const updatedOffers = selectedOffers.map((offer, index) => {
      if (index == activeOffer) {
        return { ...offer, [key]: event.target.value };
      }
      return offer;
    });
    setSelectedOffers(updatedOffers);
  };

  const handleCommentInput = (event, activeOffer, key) => {
    const updatedOffers = selectedOffers.map((offer, index) => {
      if (index == activeOffer) {
        return { ...offer, [key]: event.target.value };
      }
      return offer;
    });
    setSelectedOffers(updatedOffers)
  }

  const submmitBankOfferData = (sendEmail) => {
    setIsLoading(true);
    let mutation = gql`
      mutation SendBankOffer($bankOfferData: String!, $customerEmail: String!, $customerName: String!,$lead_id:Float!, $sendEmail: Boolean!) {
        send_bank_offer_to_customer(
          bankOfferData: $bankOfferData 
          customer_email_id: $customerEmail
          customer_name: $customerName
          lead_id:$lead_id
          send_email: $sendEmail
        )
      }
    `;
    const variables = {
      bankOfferData: JSON.stringify(selectedOffers) || "",
      customerEmail: lead?.leadDetail?.customer?.email || "",
      customerName: lead?.leadDetail?.customer?.first_name || "",
      lead_id:lead?.leadDetail?.id,
      sendEmail,
    };

    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        if (result.data?.send_bank_offer_to_customer?.mailResponse) {
          setTemplatePopup(true);
          setHtmlTemplate(result.data?.send_bank_offer_to_customer?.mailResponse.data);
        }
        setIsLoading(false);
        if (sendEmail) {
          toast.success("Email template sent successfully");
          setTimeout(() => { window.location.reload() }, 2000);
        }
      }
    })
  }

  const handleModalClose = () => {
    setTemplatePopup(false);
  }

  return (
    <>
      <div className="">
        {loading ? <Loader /> : null}
        <h2>Share Bank Offers</h2>
        <form class="lead-form-filed" autocomplete="off">
          <button type="button" class="btn-primary" style={{ cursor: "pointer", float: "right" }} onClick={() => submmitBankOfferData(false)}> Share offer confirmation</button>
          <div className={style.nameSection}>
            <div className={style.inputSection}>
              <label className={style.inputLabel}>Customer Name</label>
              <input
                className={style.inputContainer}
                type="text"
                value={`${lead?.leadDetail?.customer?.first_name} ${lead?.leadDetail?.customer?.last_name}`} />
            </div>
            <div className={style.inputSection}>
              <label className={style.inputLabel}>Current Month</label>
              <input
                className={style.inputContainer}
                type="text"
                value={new Date().toLocaleString('en-US', { month: 'long' })} />
            </div>
          </div>



          <div className={style.bankSection}>
            <div className={style.inputSection}>
              <label className={style.inputLabel}>Bank</label>
              <input
                className={style.inputContainer}
                type="text"
                value={selectedOffers[activeOffer]?.bankName ?? "-"} />
            </div>
            {checkedOffers.checkedOffers.length > 1 ?
            <button type="button" className={style.removeButton} onClick={(e) => handleRemoveOffer(activeOffer)}>Remove</button>:null
            }
          </div>


          <Tab.Container
            id="left-tabs-example"
            activeKey={activeOffer}
            onSelect={(tab) => setActiveOffer(tab)}
          >

            {/* tabs name */}
            <Nav variant="pills">
              <div className="tab-list">
                {selectedOffers.map((item, index) => (
                  <Nav.Item>
                    <Nav.Link eventKey={index}>{item.bankName.toUpperCase()}</Nav.Link>
                  </Nav.Item>
                ))}
              </div>
            </Nav>

            {/* tabs content */}
            <Tab.Content>
              {selectedOffers.map((item, index) => (
                <Tab.Pane eventKey={index}>
                  <div className={style.contentSection}>
                    <div className={style.loanDetailSection}>
                      <div>Loan Details</div>
                      <div className={style.loanDetailsContainer}>

                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Eligible Loan Amount</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "eligibalLoanAmount")}
                            value={selectedOffers[activeOffer]?.eligibalLoanAmount ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "eligibalLoanAmountComment")}
                            value={selectedOffers[activeOffer]?.eligibalLoanAmountComment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}

                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Rate of Interest</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "maxRoi")}
                            value={selectedOffers[activeOffer]?.minRoi ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "maxRoiComment")}
                            value={selectedOffers[activeOffer]?.maxRoiComment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}

                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Loan Tenure</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "maxTenure")}
                            value={selectedOffers[activeOffer]?.maxTenure ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "maxTenureComment")}
                            value={selectedOffers[activeOffer]?.maxTenureComment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}

                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Expected EMI</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "monthlyEMI")}
                            value={selectedOffers[activeOffer]?.monthlyEMI ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "monthlyEMIComment")}
                            value={selectedOffers[activeOffer]?.monthlyEMIComment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}

                      </div>
                    </div>

                    <div className={style.horizontalLine}>
                    </div>

                    <div className={style.feeDetailSection}>
                      <div>Fee Details</div>
                      <div className={style.feeDetailsContainer}>

                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Processing Fee</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "processingFee")}
                            value={selectedOffers[activeOffer]?.processingFee ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "processingFeeComment")}
                            value={selectedOffers[activeOffer]?.processingFeeComment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}

                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Login Fee</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "login_fee")}
                            value={selectedOffers[activeOffer]?.login_fee ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "login_fee_comment")}
                            value={selectedOffers[activeOffer]?.login_fee_comment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}
                        
                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Account Handling and Other Charges</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "ac_handling_charge")}
                            value={selectedOffers[activeOffer]?.ac_handling_charge ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "ac_handling_charge_comment")}
                            value={selectedOffers[activeOffer]?.ac_handling_charge_comment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}
                        
                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Legal and Technical Fee</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "legal_technical_fee")}
                            value={selectedOffers[activeOffer]?.legal_technical_fee ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "legal_technical_fee_comment")}
                            value={selectedOffers[activeOffer]?.legal_technical_fee_comment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}
                        
                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>E-Stamp Charges</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "e_stamp_charge")}
                            value={selectedOffers[activeOffer]?.e_stamp_charge ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "e_stamp_charge_comment")}
                            value={selectedOffers[activeOffer]?.e_stamp_charge_comment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}
                        
                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>MODT Charges</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "MODT_charge")}
                            value={selectedOffers[activeOffer]?.MODT_charge ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "MODT_charge_comment")}
                            value={selectedOffers[activeOffer]?.MODT_charge_comment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}
                        
                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>NOI Charges</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "NOI_charge")}
                            value={selectedOffers[activeOffer]?.NOI_charge ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "NOI_charge_comment")}
                            value={selectedOffers[activeOffer]?.NOI_charge_comment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}

                        <div className={style.inputSection}>
                          <label className={style.inputLabel}>Cersal Charges</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleInputChange(event, activeOffer, "CERSAI_charge")}
                            value={selectedOffers[activeOffer]?.CERSAI_charge ?? ""} 
                            placeholder="Please enter here" />                            
                        </div>
                        {/* <div className={style.inputSection}>
                          <label className={style.inputLabel}>Comment</label>
                          <input
                            className={style.inputContainer}
                            type="text"
                            onChange={(event) => handleCommentInput(event, activeOffer, "CERSAI_charge_comment")}
                            value={selectedOffers[activeOffer]?.CERSAI_charge_comment ?? ""} 
                            placeholder="Please enter here" />                            
                        </div> */}
                       
                      </div>
                    </div>
                    <div className={style.horizontalLine}>
                    </div>
                    <div>
                    <div className={style.remarkSection}>
                        <div for="remarks" className={style.inputLabel}>Remarks:</div>
                        <textarea
                          id="remarks"
                          className={style.remarkContainer}
                          placeholder="Enter your remarks here..."
                          value={selectedOffers[activeOffer]?.Remarks ?? ""}
                          onChange={(event) => handleCommentInput(event, activeOffer, "Remarks")}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </form>
      </div>

      <div className="view-timeline-popup">
        <Modal show={templatePopup} handleClose={handleModalClose} width={1000} >
        <div>
      {!isOfferShared ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              className="btn btn-primary"
              style={{ backgroundColor: 'blue', color: 'white',marginTop:20 }}
              onClick={handleShareOffer}
            >
              Share offer to Customer
            </button>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            style={{ marginBottom: 50 ,marginTop:"150px"}}
            src="https://static.ambak.com/saathi/1731920484175.png"
            alt="thank you"
            width={200}
            height={80}
          />
          <h2>Offer Shared to Customer</h2>
        </div>
      )}
    </div>
        </Modal>
      </div>

    </>
  );
};

export default OfferShare;
