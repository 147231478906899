import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { WS_URL } from '../../config/constants';
import crypto from "../../config/crypto";
import { GET_UNREAD_MESSAGE_LIST, GET_UNREAD_MESSAGE_LIST_YODHA, UPDATE_CHAT_READ_STATUS } from '../../services/leads.gql';
import './../assets/css/WhatsAppUnread.css'; // CSS file for styling
import whatsappIcon from './../assets/images/icons/whatsapp-icon.png';
import mailIcon from './../assets/images/icons/mail.png';
import { MasterService } from '../../services';
import Draggable from 'react-draggable';

const WhatsAppUnreadComponent = () => {
  const client = useApolloClient();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [connectWebsocket, setConnectWebsocket] = useState(false);
  const [showWhatsAppIcon, setShowWhatsAppIcon] = useState(true);
  const [newMessages, setNewMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentUrlPath, setCurrentUrlPath] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [isGettingIncomingCall, setIsGettingIncomingCall] = useState(false);
  const [incomingCallData, setIncomingCallData] = useState([]);
  const [isIncomingCallOpen, setIsIncomingCallOpen] = useState(false);
  const [isAmeyoFrameVisible, setIsAmeyoFrameVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isMailOpen, setIsMailOpen] = useState(false); 
  const [mailReplyData, setMailReplyData] = useState([]); 
  const [newMail, setNewMail] = useState(false);


  const userInfo = JSON.parse(localStorage.getItem("user_information"));
  const yodha_code = userInfo?.yodha_code

  // WebSocket Code Initialize
  useEffect(() => {
    if (connectWebsocket && userInfo?.user_data?.user_id) {
      const identifierType = "unreadMessage";
      const connectionId = Math.floor(Date.now() / 1000);
      let socketConnection;
      const socketData = {
        transports: ['websocket'],
        query: { type: identifierType, identifier: userInfo.user_data.user_id, connectionId },
        reconnectionAttempts: 10,
        reconnectionDelay: 60000,
        randomizationFactor:5
      }
      const connectSocket = () => {
        socketConnection = io(`${WS_URL}/socket`, socketData);
        socketConnection.on(`${identifierType}-${userInfo.user_data.user_id}_${connectionId}`, (msg) => {
          if (msg) {
            setNewMessages(true);
            setMessages((prevMessages) => [...prevMessages, { ...msg }]);
          }
          setRetryCount(0); // Reset retry count on successful connection
        });
      };

      connectSocket();

      if(yodha_code){
        getUnreadMessageListYodha(yodha_code);
      }else{
        getUnreadMessageList(userInfo.user_data.user_id);
      }
      return () => {
        if (socketConnection) {
          socketConnection.disconnect();
        }
      };
    }
  }, [connectWebsocket]);
  // WebSocket Code Ends


  useEffect(() => {
    if (currentUrlPath != location.pathname) {
      setMessages([]);
      if(yodha_code){
        getUnreadMessageListYodha(yodha_code);
      }else{
        getUnreadMessageList(userInfo.user_data.user_id);
      } 

      }

    const currentPath = location.pathname.trim().split('/');

    if (currentPath[1] === 'lead-detail' && currentPath[2] === 'whatsapp') {
      setShowWhatsAppIcon(false)
      setIsOpen(false);
    } else {
      setShowWhatsAppIcon(true);
      setConnectWebsocket(true);
    }
    // You can trigger any update or fetch data based on URL change
    // For example, fetch data or update state here
    setCurrentUrlPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const identifierType = "incomingCall";
    const connectionId = Math.floor(Date.now() / 1000);
    let socketConnection;
    const socketData = {
      transports: ['websocket'],
      query: { type: identifierType, identifier: userInfo.user_data.mobile, connectionId, },
      reconnectionAttempts: 10,
      reconnectionDelay: 60000,
      randomizationFactor:5
    }
    const connectSocket = () => {
      socketConnection = io(`${WS_URL}/socket`, socketData);
      socketConnection.on(`${identifierType}-${userInfo.user_data.mobile}_${connectionId}`, (msg) => {
        if (msg) {
          if (Object.keys(msg?.message).length) {
            setIncomingCallData([msg?.message]);
            setIsGettingIncomingCall(true);
          }
        }
      });
    };

    connectSocket();

    return () => {
      if (socketConnection) {
        socketConnection.disconnect();
      }
    };
  }, []);


  useEffect(() => {
    const identifierType = "mailReply";
    const connectionId = Math.floor(Date.now() / 1000);
    let socketConnection;
    const socketData = {
      transports: ['websocket'],
      query: { type: identifierType, identifier: userInfo.user_data.mobile, connectionId, },
      reconnectionAttempts: 10,
      reconnectionDelay: 60000,
      randomizationFactor:5
    }
    const connectSocket = () => {
      socketConnection = io(`${WS_URL}/socket`, socketData);
      socketConnection.on(`${identifierType}-${userInfo.user_data.mobile}_${connectionId}`, (msg) => {
        if (msg) {
          console.log(msg)
          if (Object.keys(msg?.message).length) {
            setNewMail(true)
            setMailReplyData([msg?.message]);
          }
        }
      });
    };

    connectSocket();

    return () => {
      if (socketConnection) {
        socketConnection.disconnect();
      }
    };
  }, []);

  const handleIconClick = () => {
    if (messages.length) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };

  const handleMaleIconClick =()=>{
    setIsMailOpen(!isMailOpen);
  }

  const handleAmeyoClick = () => {
    if (window.initialise) {
      setIsAmeyoFrameVisible(true);

      // add slight delay to state updates and component re-renders
      setTimeout(() => {
        window.initialise();
      }, 0);
    }
  };

  const handleAmeyoFrameClose = () => {
    setIsAmeyoFrameVisible(false);
  };

  const updateReadStatus = (msgId, userId) => {
    executeGraphQLMutation(UPDATE_CHAT_READ_STATUS, { chatId: msgId, userId }, client)
      .then(result => {
      })
      .catch((error) => {
        console.error("\n\n updateReadStatus >> executeGraphQLQuery => error -> ", error)
      });
  }

  const clickMessage = (msgObject) => {
    const newMessages = messages.filter((_val, _idx) => (_val.identifier.msgId != msgObject.identifier.msgId))
    setMessages(newMessages)
    setIsOpen(!isOpen);
    setNewMessages(!!newMessages.length);
    updateReadStatus(msgObject.identifier.msgId, msgObject.identifier.userId);
    if(yodha_code){
      (async () => {
        const dataOfPartner = await PartnerList(yodha_code, String(msgObject.identifier.leadId));
        if(dataOfPartner[0]?.partnerStatus==="10" ||dataOfPartner[0]?.partnerStatus==="11"){
          window.open(`/partner-lead-detals/${dataOfPartner[0]?.dealerIdHash}`, '_blank')
  
        }else if(dataOfPartner[0]?.partnerStatus==="1" || dataOfPartner[0]?.partnerStatus==="4"){
          window.open(`/dealer/${dataOfPartner[0]?.dealerIdHash}/sales-registered`, '_blank')
        }
      })(); 
    }else{
      window.open(`/lead-detail/whatsapp/${crypto.encode(msgObject.identifier.leadId)}`, '_blank')
    }
  }

  const PartnerList = async (yoddha_code, partner_id) => {
    try {
      if(partner_id>0 ){
        const response = await MasterService.post("/partner/partner/get-partner-list-new", {yoddha_code,partner_id });
      return response.data.data||[];
      } 
    } catch (error) {
      console.error("Failed to fetch partner list.");
    }
  };

  const getUnreadMessageList = (userId) => {
    executeGraphQLQuery(GET_UNREAD_MESSAGE_LIST(0, userId), client).then((result) => {
      if (result?.data?.unread_message_list?.length) {
        const transformedMessages = result.data.unread_message_list.map(message => ({
          identifier: {
            msgId: message.id, // Replace with actual value if available
            mobile: message.mobile,
            userId: message.user_id,
            leadId: message.lead_id,
            customerId: message.customer_id,
            customerName: message.customer_name
          },
          message: {
            message: message.message,
            chat_type: message.chat_type,
            mode_type: message.mode_type,
            created_date: new Date(message.created_date).toLocaleString()
          }
        }));

        if (transformedMessages.length) {
          setMessages(transformedMessages);
          setNewMessages(true);
        }
      }
    }).catch((error) => {
      console.error("\n\n getUnreadMessageList >> executeGraphQLQuery => error -> ", error)
    });
  };


  const getUnreadMessageListYodha = (yodha_code) => {
    executeGraphQLQuery(GET_UNREAD_MESSAGE_LIST_YODHA(0, yodha_code), client).then((result) => {
      if (result?.data?.unread_message_list_yodha?.length) {
        const transformedMessages = result.data.unread_message_list_yodha.map(message => ({
          identifier: {
            msgId: message.id, 
            mobile: message.mobile,
            userId: message.user_id,
            leadId: message.lead_id,
            customerId: message.customer_id,
            customerName: message.partner_name
          },
          message: {
            message: message.message,
            chat_type: message.chat_type,
            mode_type: message.mode_type,
            created_date: new Date(message.created_date).toLocaleString()
          }
        }));

        if (transformedMessages.length) {
          setMessages(transformedMessages);
          setNewMessages(true);
        }
      }
    }).catch((error) => {
      console.error("\n\n getUnreadMessageList >> executeGraphQLQuery => error -> ", error)
    });
  };


  const handleIncomingIconClick = () => {
    if (incomingCallData.length) {
      setIsIncomingCallOpen(!isIncomingCallOpen);
    } else {
      setIsIncomingCallOpen(false);
    }
  }

  const handleRedirectPartnerPg = (endPointUrl) => {
    setTimeout(() => {
      setIncomingCallData([]);
    setIsIncomingCallOpen(false);
    setIsGettingIncomingCall(false); 
    }, 0);
    const fullUrl = window.location.href;
    const urlObject = new URL(fullUrl);
    window.open(`${urlObject.origin}/${endPointUrl}`);
  }

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      <div className="whatsapp-container">
        {showWhatsAppIcon && (
          <div className="whatsapp-icon-wrapper" onClick={handleIconClick}>
            {newMessages && <span className="new-badge">New</span>}
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              className="whatsapp-icon"
            />
          </div>
        )}
        {isOpen && (
          <ul className="message-list">
            {messages.map((message, index) => (
              <li
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => {
                  clickMessage(message);
                }}
              >
                {message?.identifier?.customerName ?? "Partner"} (ID:{" "}
                {message.identifier.leadId})
              </li>
            ))}
          </ul>
        )}

        
      </div>
      <div className='mail-container'>
      {showWhatsAppIcon && (
          <div className="mail-icon-wrapper" onClick={handleMaleIconClick}>
            {newMail && <span className="new-badge">New</span>}
            <img
              src={mailIcon}
              alt="mail Icon"
              className="mail-icon"
            />
          </div>
        )}
         {isMailOpen && (
          <ul className="message-list">
            {mailReplyData.map((message, index) => (
              <li
                style={{ cursor: "pointer" }}
                key={index}
              >
                <a target="_blank" href={message.url}>Lead ID: {message.lead_id}</a>
              </li>
            ))}
          </ul>
         )}
      </div>

      <div className="incoming-call-container">
        <div
          className="whatsapp-icon-wrapper"
          onClick={handleIncomingIconClick}
        >
          {isGettingIncomingCall && <span className="new-badge-new">{incomingCallData[0]?.call_type} Call</span>}
          <img
            src="https://static.ambak.com/saathi/1728469713808.png"
            alt="incoming call Icon"
            className="incoming-call-icon"
          />
        </div>
        {isIncomingCallOpen && (
          <ul className="message-list">
            {incomingCallData.map((obj, index) => {
              const {
                call_type,
                partner_id,
                dealership_contact,
                first_name,
                url,
              } = obj;
              const partnerIdType = typeof partner_id;
              const preMsg =
                call_type === "Incoming"
                  ? "Incoming call from"
                  : "Got missed call from";
              return (
                <li
                  style={{ cursor: "pointer" }}
                  key={`incomingcall${index}`}
                  onClick={() => handleRedirectPartnerPg(url)}
                >
                  {partnerIdType === "number"
                    ? `${preMsg} ${dealership_contact} - ${first_name}`
                    : `${partner_id}`}
                </li>
              );
            })}
          </ul>
        )}
      </div>

      
      {isAmeyoFrameVisible&& (
                <div style={{ display: "flex", justifyContent: "end",position:"absolute",bottom:0,right:50,zIndex:100000, pointerEvents: isHovering ? "auto" : "none", }}>
                  <Draggable>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: "pointer", pointerEvents: "auto" }}>
                      <div className="ameyo-iframe">
                        <span onClick={handleAmeyoFrameClose}>X</span>
                      </div>
                      <div id="ameyoIframeDiv"></div>
                    </div>
                  </Draggable>
                </div>
              )}


              <Draggable>
                <div className="ameyo-icon-parent" onClick={handleAmeyoClick}>
                  <div className="ameyo-icon">
                    <img
                      src="https://static.ambak.com/saathi/1731488683241.png"
                      alt="ameyo"
                    />
                  </div>
                </div>
              </Draggable>

    </>
  );

};

export default WhatsAppUnreadComponent;
