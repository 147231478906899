import { useEffect, useState } from 'react';
import Select from 'react-select';
import { CallListTable } from './list-table';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { SEARCH_CALLBACK_DETAILS } from "../../services/callList.gql";
import moment from 'moment';

const SEARCH_TYPE = [
    { value: 'lead_id', label: 'Lead ID' },
    { value: 'mobile', label: 'Customer Phone' }, 
]

const DATE_TYPE_LIST = [
  { label: "Call Time", value: "time" },
  // { label: "Lost", value: "lost"}
]

const limit = 10;



const CallList = () => {
  const [filterData, setFilterData] = useState({
    search_type:"lead_id",
  });
  const [checkbox,setCheckbox] = useState({
    search_type:"lead_id",
  });
  const [leadType, setLeadType] = useState('New');
  const [callType, setCallType] = useState('Missed');
  const [assigned, setAssigned] = useState([]);
  const [callList, setCallList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount,setPageCount] = useState(0);
  const client = useApolloClient();


  const removeFilter = (key) => { 
    const obj ={ ...checkbox };
    if(key==="to_date" || key==="from_date"){
      delete obj["from_date"];
      delete obj["to_date"];
    }else{
      delete obj[key];
    }
    setFilterData(obj);
    searchCallDetail(obj);
  }

  function snakeCaseToFirstUpperCase(str) {
    if (!str || typeof str !== 'string') return '';
    
    // Replace underscores and capitalize the first letter of the resulting string
    const noUnderscores = str.replace(/_/g, ' ');
    return noUnderscores.charAt(0).toUpperCase() + noUnderscores.slice(1);
  }

  const handleOutsideClick = (event) => {
    const toggleCheckbox = document.getElementById('toggleCheckbox');
    if (toggleCheckbox.checked) {
      toggleCheckbox.checked = false;
    }
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;
      setFilterData((prev) => ({
        ...prev,
        from_date:start,
        to_date: end
      }));
  }

  useEffect(()=>{
    const mutation = gql`
    query {
        get_all_users {
            id
            name
        }
    }
  `;
  executeGraphQLQuery(mutation,client).then((result) => {
    const data = result?.data?.get_all_users?.map((obj,index)=>{
      return {
        id: obj.id,
        label: `${obj.name} - ${obj.id}`,
        value: obj.id
      }
    })
    setAssigned(data);
  }).catch((error) => {
    console.log(error);
  })
  },[])

  useEffect(() => {
    searchCallDetail(filterData);
  }, [page, limit, callType, leadType]);

  const handleChange=(search_type,e)=>{
    if(search_type==="search_type"){
      setFilterData({...filterData,search_type:e.value})
    }else if(search_type==="search_text"){
      setFilterData({...filterData,search_text:e.target.value})
    }else if(search_type==="assign_id"){
      setFilterData({...filterData,"assign_id":e.value})
    }
    
  }

  function removeEmptyKeys(obj) {
    if (!obj || typeof obj !== 'object') return {};
  
    // Filter out keys with "", null, or undefined values
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        acc[key] = value; // Keep the key if value is valid
      }
      return acc;
    }, {});
  }

  const searchCallDetail = (updatedData) => {
    executeGraphQLMutation(SEARCH_CALLBACK_DETAILS({
      mobile_no:(updatedData?.search_type === "mobile" && updatedData?.search_text) ? updatedData?.search_text : '',
      lead_id:(updatedData?.search_type === "lead_id" && updatedData?.search_text) ? updatedData?.search_text : 0,
      assigne_to: updatedData?.assign_id ? updatedData?.assign_id : 0,
      start_date: updatedData?.from_date ? updatedData?.from_date : '',
      end_date: updatedData?.to_date ? updatedData?.to_date : '', 
      lead_type: leadType,
      call_status: callType,
      page,
      limit
    }), {}, client)
    .then((res)=>{
      setCallList(res.data.get_call_back_details.data)
      setPageCount(res.data.get_call_back_details.total)
    }).catch(err=>{
      console.log(err)
    })

    setCheckbox(removeEmptyKeys(updatedData));
  }

  const resetFilter = () => {
    setFilterData({
      search_type:"lead_id",
    })
    searchCallDetail({
      search_type:"lead_id",
    });
  }

  const handleKeyDown = (e)=>{ 
    if (e.key === 'Enter') {
      if(filterData.search_type === "lead_id"){
        setFilterData({...filterData,search_type:"lead_id",search_text:e.target.value})
      }
      if(filterData.search_type === "mobile"){
        setFilterData({...filterData,search_type:"mobile",search_text:e.target.value})
      }
      searchCallDetail(filterData);
    }
    
  }

  return (
    <div className="container-fluid">
    <div className="top-heading" >
        <div className="filterflex">
          <h1 style={{ marginLeft: "7px" }}>Call Logs</h1>
          <div className="filterinput" onClick={(e)=>handleOutsideClick()}>
            <div>
              <fieldset className="single-select">
                <div className="material">
                  <Select
                    options={SEARCH_TYPE}
                    placeholder={"Search Type"}
                    name={"search_type"}
                    optionLabel="label"
                    optionValue="value"
                    value={
                      filterData && filterData["search_type"]
                        ? SEARCH_TYPE.filter(
                            ({ value }) => value === filterData["search_type"]
                          )
                        : { value: "lead_id", label: "Lead Id" }
                    }
                    onChange={(e) => {handleChange("search_type", e)}}
                    // onChange={(e) => handleChange("search_type", e)}
                  />
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset className="search-bx">
                <div className="material">
                  <input
                    id=""
                    type="text"
                    name="search_text"
                    value={filterData.search_text || ""}
                    onChange={(e) => {handleChange("search_text", e)}}
                    className="form-input-new"
                    title={"SEARCH_TEXT"}
                    placeholder="Search" 
                    onKeyDown={handleKeyDown} 
                  />
                  <i className="ic-search"></i>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <input type="checkbox" id="toggleCheckbox" />
      </div>
      <div className='search-wrap'>
      <div className='row'>
      {/* <div className="col-sm-3" style={{padding:"20px"}}>
      <div className="filter-title">Select Date Type</div>
        <fieldset className="single-select">
          <div className="material">
            <Select
              options={DATE_TYPE_LIST}
              placeholder={"Select Date Type"}
              name={"date_type"}
              value={
                filterData && filterData["date_type"]
                  ? DATE_TYPE_LIST.filter(
                      ({ value }) =>
                        value === filterData["date_type"]
                    )
                  : ""
              }
              onChange={(e) => handleChange("date_type", e)}
              menuShouldScrollIntoView={false}
            />
          </div>
        </fieldset>
      </div> */}
      <div className="col-sm-3" style={{padding:"20px"}}>
      <div className="filter-title">Select Date Range</div>
        <fieldset className="single-select">
          <div className="datepicker">
            <DatePicker
              selected={filterData?.from_date}
              onChange={(date) => onChangeDate(date)}
              startDate={filterData?.from_date}
              endDate={filterData?.to_date}
              selectsRange
              placeholderText={"Select Date Range"}
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              peekNextMonth
              minDate={null}
              maxDate={new Date()}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              isClearable={true}
            />
          </div>
        </fieldset>
      </div>
      <div className='col-sm-3' style={{padding:"20px"}}>
      <div>Assign User</div>
        <fieldset className="single-select">
          <div className="material">
            <Select
              options={assigned}
              placeholder={"Assigned User"}
              name={"assign_id"}
              optionLabel="label"
              optionValue="value"
              value={
                filterData && filterData["assign_id"]
                  ? assigned.filter(
                      ({ value }) =>
                        value === filterData["assign_id"]
                    )
                  : ""
              }
              onChange={(e) => handleChange("assign_id", e)}
              menuShouldScrollIntoView={false}
            />
          </div>
        </fieldset>
      </div>

      <div className="col-sm-3" style={{padding:"40px"}}>
        <div className="col-sm-2">
                <div className="more-filter">
                  <div className="filter-option">
                    <Dropdown id="more-filter-dropdown">
                      <div className="text-btn d-flex">
                        <div className="btn-submit-reset top-btn-none pull-right">
                          <button 
                          onClick={()=>searchCallDetail(filterData)} 
                          className="btn-primary">
                            Apply
                          </button>
                          <button
                            onClick={resetFilter}
                            className="btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
              
      </div>
        <div className='col-sm-12' style={{marginBottom:"10px"}}>
        <div className="selected-chips">
            <div className="chipsflex">
            <ul>
              
              {checkbox &&
                Object.entries(checkbox).map(([key, value],index) => (
                  <li key={index}>
                      {snakeCaseToFirstUpperCase(key)}: {key==="from_date" || key==="to_date" ? moment(value).format("DD-MM-YYYY") : value}
                       
                       <span>
                         <i
                           className="ic-clearclose"
                           onClick={() =>
                             removeFilter(key)
                           }
                         ></i>
                      </span>
                     </li>
                ))
                }
            </ul>
            </div>

          </div>
        </div>
        </div>
         </div>
         
        
       
         
           <Tab.Container
               id="left-tabs-example"
               activeKey={leadType} 
               onSelect={(tab) => setLeadType(tab)}
           >
               <Nav variant="newpills" className="flex-column">
                       <Nav.Item className='nav-item'>
                           <Nav.Link eventKey="New">New</Nav.Link>
                       </Nav.Item>
                       <Nav.Item className='nav-item'>
                           <Nav.Link eventKey="Existing">Existing</Nav.Link>
                       </Nav.Item>
               </Nav>
               <Nav >           
               </Nav>
           </Tab.Container>
           <div className="doument-gallery-tabs" style={{marginTop:"10px", marginLeft:"10px"}}>
                       <Tab.Container id="left-tabs-example" activeKey={callType} onSelect={(tab) => setCallType(tab)}>
                           <Nav variant="pills" className="flex-column">
                               <div className="tab-list" style={{fontSize:''}}>
                                   <Nav.Item className='nav-item'>
                                       <Nav.Link eventKey="Missed">Missed</Nav.Link>
                                   </Nav.Item>
                                   <Nav.Item className='nav-item'>
                                       <Nav.Link eventKey="Outgoing">Outgoing</Nav.Link>
                                   </Nav.Item>
                                   <Nav.Item className='nav-item'>
                                       <Nav.Link eventKey="Incoming">Incoming</Nav.Link>
                                   </Nav.Item>
                               </div>
                           </Nav>
                       </Tab.Container>
                   </div>  


                  
          <CallListTable 
            leadType={leadType}
            callType={callType} 
            filterData={filterData}
            callList={callList}
            pageCount={pageCount}
            setPage={setPage}
            page={page}
            searchCallDetail={()=>searchCallDetail(filterData)}
          />
     
    

    </div>
  );
};

export default CallList;
