import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import crypto from "../../config/crypto";
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";
import FormsyInputField from './../elements/FormsyInputField';
import FormsyDatePicker from './../elements/FormsyDatePicker';
import FormsySelect from './../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from './../lead-list/LeadRemarkHistory';
import Modal from './../elements/Modal';
import { executeGraphQLMutation } from './../../common/executeGraphQLQuery';
import { UPDATE_LEAD_STATUS } from './../../services/leads.gql';
import { useApolloClient, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail, getLeadRemarks } from './../../store/action/allAction';
import GeneralService from '../../services/generalService';
import Loader from '../elements/Loader';
import LoggedinPopup from '../elements/LoggedinPopup';
import LoggedinTabs from './LoggedinTabs';
import right from "../../webroot/images/angle-right.svg";
import LeadStatusService from "../../services/leadStatus";

const DisbursedDetailsForm = FormFields["PDD"];
const DisbursalDocsJson = FormFields['PDD_DOCS'];

const PDD_STATUS = [
  { id: "1", label: "Confirmed on Email", value: "1" },
  { id: "2", label: "Confirmed in MIS", value: "2" },
  { id: "3", label: "Initiated", value: "3" }
]

const DisbursedLead = (props) => { 

  const [disburseDetails, setDisbursedDetails] = useState([]);
  const [leadDocCount, setLeadDocCount] = useState(0);
  const [disbursalDocuments, setDisbursalDocuments] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let [loading, setIsLoading] = useState(false);
  let [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("tranchtype_0");
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  let updatedStatusList = lead?.leadDetail?.lead_status_history?.map(data => data.status_id) || [];

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let disbursedData = lead.leadDetail.splitpayment || [];
      const splitPayment = disbursedData.map(data => {
        return {
          id: data.id,
          pdd_status: data.pdd_status || '',
          is_pdd_verified: data.is_pdd_verified || '0',
          pdd_date: data.pdd_date ? dateFormat(data.pdd_date, "dd mmm, yyyy") : null,
          payout_expected_date: data.payout_expected_date ? dateFormat(data.payout_expected_date, "dd mmm, yyyy") : null,
        }
      })
      setDisbursedDetails(splitPayment);      
    }

  }, [lead]);

  useEffect(() => {
    let doc_list = [{
      "id": 65,
      "max_upload": 20,
      "min_upload": 1,
      "name": "PDD Image"
    }];
    let doc_array = [];
    DisbursalDocsJson && DisbursalDocsJson.map((field) => {
      doc_list?.map(doc => { return (doc.id === field.doc_id) ? doc_array.push(doc) : doc_array.push({}) })
    })
    doc_array = doc_array.filter(obj => Object.keys(obj).length !== 0);
    setDisbursalDocuments(doc_array);
  }, []);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};    
    options["pdd_status"] = PDD_STATUS;
    return options;
  }, [masterdata]);

  const handleInputChange = event => {
    if (event.target.id) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][event.target.id] = event.target.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }


  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = data.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = date;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedDisburalDetails = [...disburseDetails];
      updatedDisburalDetails[activetabIndex][field_name] = data?.value;
      setDisbursedDetails(updatedDisburalDetails);
    }
  }

  const is_pdd_verified = useMemo(() => {
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    let singleTranch = lead?.leadDetail?.splitpayment[activetabIndex];
    let is_pdd_verified = singleTranch?.is_pdd_verified || '0';
    return (is_pdd_verified === '1') ? true : false;
  }, [activeTab, getContext]);

  const isSubmitDisabled = useMemo(() => {
    return (is_pdd_verified) ? true : false;
  }, [allowSubmit, getContext]);

  const handleSubmit = (mark_verify=null) => {
    let lead_id = lead?.leadDetail?.id || "";   
    setIsLoading(true);
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    let saveDisbursalData = disburseDetails[activetabIndex] ? [disburseDetails[activetabIndex]] : [];
    let reqBody = saveDisbursalData.map((trans) => {
      return {
        id: trans.id,
        pdd_status: trans.pdd_status || '0',
        pdd_date: dateFormat(trans?.pdd_date, "yyyy-mm-dd"),
        ...(trans?.payout_expected_date 
          ? {payout_expected_date: dateFormat(trans?.payout_expected_date, "yyyy-mm-dd")} 
          : {}),
        transaction_type: "pdd"
      }
    });

    if(mark_verify === 'tranch_verify'){
      reqBody = [{
        id: reqBody[0]?.id,
        is_pdd_verified: "1",
        transaction_type: "pdd"
      }]
    }

    let mutation = UPDATE_LEAD_STATUS,
      variables = {
        api_called_by: 'web',
        UpdateLeadStatus: {
          update_type: "pdd",
          status_id: [4],
          sub_status_id: LeadStatusService.LEAD_SUB_STATUS_MASTER.DISBURSE.PDD, //(mark_verify === 'tranch_verify') ? 18 : 14,
          lead_id: lead_id,
          user_id: user_id,
          pdd_form: reqBody && reqBody.length && reqBody[0]
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.UpdateLeadStatus || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getLoanDetail(lead_id, client))
      if (reqBody?.[0]?.disbursal_remark) dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
      setIsLoading(false);
    })
      .catch(err => {
        setIsLoading(false);
        toast.error(err.message);
      })
  }
 
  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };  

  const togglePopup = () => { 
    setIsOpen(!isOpen);
    if(!isOpen){
        document.body.classList.add("overflow-hidden");
    }else{
        document.body.classList.remove("overflow-hidden");
    }
  }

  const handleFileChange = async (event, max_upload) => {
    let lead_id = lead?.leadDetail?.id || "";
    let selectedImage = event && event.target && event.target.files && event.target.files.length && event.target.files[0].name || "";
    let publicUrl = '', docPathArray = '';
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    if (selectedImage) {
      if (errors["disbural_doc_count"]) delete errors.disbural_doc_count;
      if ((max_upload - leadDocCount) > 0) {
        const formData = new FormData();
        formData.append('upload_type', 'customer_doc');
        formData.append('dealer_id', lead_id);
        for (let imgFile of event.target.files) {
          formData.append("images", imgFile);
        }
        try {
          publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
          if (publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length) {
            docPathArray = publicUrl.data.data;
            docPathArray = docPathArray.map(ob => ob.file_url);
            saveDocument(lead_id, docPathArray);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
        setLeadDocCount(leadDocCount + 1);

      }
    }
  }

  const saveDocument = (lead_id, docPathArray) => {
    let leadDocArr = [];
    for (let imgPath of docPathArray) {
      leadDocArr.push({
        lead_id: Number(lead_id),
        doc_id: 65,
        parent_doc_id: 64,
        doc_path: imgPath,
        doc_source: "web",
        customer_type: "customer",
        customer_id: lead?.leadDetail?.customer?.customer_id || 0,
        user_id: authUser && authUser.id || 1,
      })
    }
    let variables = {
      saveDocInput: {
        lead_id: lead_id,
        lead_docs: leadDocArr
      },
      api_called_by: 'web'
    };

    let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
        save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
          lead_id
          doc_id
          doc_path
        }
    }`;
    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        toast.success('Document uploaded successfully');
        // dispatch(getLoanDetail(lead_id, client));
      }
    })
  }

  const sectionInfo = { section: "Disbursal Details", sub_section: "Disbursal Details" }
  let active_tab_index = activeTab.split('_');
  active_tab_index = active_tab_index && active_tab_index[1] || '';
  let activeTabDataObject = lead?.leadDetail?.splitpayment[active_tab_index] || {};

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="65" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>PDD</h2>
          </div>
          {([4].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ?
          <div className='loggedinbox' onClick={togglePopup}>
            <div className='loggedinflex'>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>
                <i class="ic-pending-application"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>
              </div>
              <div style={{ fontSize: 13, marginLeft: 10 }}>PDD checklist</div>
            </div>
            <img src={right} alt='icon' width={10} height={10} />
          </div> :""}
          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
              {disburseDetails.length && disburseDetails.map((coApplicant, index) =>                
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setActiveTab(`tranchtype_${index}`)}
                      eventkey={`tranchtype_${index}`}
                      active={activeTab === `tranchtype_${index}`}
                    >
                      <b>{`Transaction ${index + 1}`}</b>
                    </Nav.Link>
                  </Nav.Item>
              )}
            </div>
          </Nav>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {DisbursedDetailsForm.map((field) =>
                disburseDetails.map((splitPaymentDetailData, index) => (`tranchtype_${index}` === activeTab) && (
                  ["text", "pattern-format", "number"].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                        placeholder=" "
                        label={field.label}
                        maxLength={field.maxLength}
                        onChange={
                          field.type === "pattern-format"
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          splitPaymentDetailData[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) :
                    field.type === "date-picker" ? (
                      <fieldset class="form-filed col-md-6 ">
                        <FormsyDatePicker
                          name="transaction_date"
                          selectedDate={splitPaymentDetailData && splitPaymentDetailData[field.name] && new Date(splitPaymentDetailData[field.name])}
                          placeholder={field.label}
                          minDate={field.hasOwnProperty("max_date") ? new Date() : new Date(activeTabDataObject?.disbursed_date)}
                          maxDate={field.hasOwnProperty("max_date") ? null : new Date()}
                          value={splitPaymentDetailData && splitPaymentDetailData[field.name] ? dateFormat(splitPaymentDetailData[field.name], "dd mmm, yyyy") : null}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                            splitPaymentDetailData[field.name] ? field.validationError : ""
                          }
                          required={field.required}
                          hasError={(splitPaymentDetailData.hasOwnProperty(field.name) && !splitPaymentDetailData[field.name]) ? true : false}
                        />
                      </fieldset>
                    ) :
                      field.type === "dropdown" ? (
                        <fieldset class="single-select col-md-6">
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == splitPaymentDetailData[field.name]),
                              isDisabled: field.readOnly
                            }}
                            required={field.required}
                            value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                            onChange={(data) => handleSelectChange(data, field.name)}
                          />
                        </fieldset>
                      ) : field.type === "textarea" ? (
                        <fieldset class="col-md-12">
                          <div class="material">
                            <textarea
                              id={field.id}
                              name={field.name}
                              type={field.type}
                              value={splitPaymentDetailData && splitPaymentDetailData[field.name]}
                              placeholder=" "
                              onChange={handleInputChange}
                              class="form-input"
                            >
                            </textarea>
                            <label
                              data-label={field.label}
                              class="form-label"
                            >
                            </label>
                          </div>
                        </fieldset>
                      
                      ) : null
                ))
              )
              }
            </div>

            <div className="acco-doc-list">
              <ul>{disbursalDocuments.map((fields) => (
                <li>
                  <div className="img-bx-select-opt">
                    <div className="image-bx more-file-upload">
                      <input type="file" name="" onChange={e => handleFileChange(e, fields.max_upload)} onClick={e => (e.target.value = null)} required={fields.is_required} multiple />
                      {/* <a href='#' onClick={navigateToDocSection}>Verify</a> */}
                    </div>
                  </div>
                </li>
              ))}
              </ul>
              <span style={{ 'display': `${errors["disbursal_doc_count"] ? "block" : "none"}` }}>
                Please Upload Disbursal Docs
              </span>
            </div>

            <div className="btn-save-remarks">
              <button type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit}>Update</button>
              <button type="button" onClick={() => handleSubmit('tranch_verify')} className="btn-primary" disabled={is_pdd_verified || !activeTabDataObject.pdd_status}>Verify</button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} sectionInfo={sectionInfo} />
        </Modal>
      </div>
      <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}> 
        <LoggedinTabs status_id={4} sub_status_id={7} togglePopup={togglePopup}/>
      </LoggedinPopup>
    </div>
  );
}

export default DisbursedLead;