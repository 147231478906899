import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tooltip } from "react-tippy";
import { Nav } from "react-bootstrap";
import Loader from "../../elements/Loader";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import {
  GET_DOC_REQUEST_LIST,
  SAVE_DOC_REQUEST,
} from "../../../services/customer.gql";
import verified from "../../../webroot/images/verifiednew.svg";
import verifiedone from "../../../webroot/images/verifiednewone.svg";
import reject from "../../../webroot/images/reject.png";
import generalService from "../../../services/generalService";
import Q from "q";
import { gql } from "@apollo/client";

class UploadDocumentList extends Component {
  constructor(props) {
    super(props);
    this.tipContentRef = React.createRef();
    this.buttonRef = React.createRef();
    this.state = {
      tipOpen: 0,
      UploadedFiles: this.props.UploadedFiles || [],
      isLoading: false,
      displayTootipIcon: false,
      docCollectModal: false,
      selectedDocsList: [],
      openAccordion: null,
      ActiveTab: `first_submit-${this.props?.leadDetails?.customer?.customer_id}`,
      customerType: this.props?.uType,
      customerId: +this.props.uId,
    };
    this.toggleTip = this.toggleTip.bind(this);
    this.bodyClick = this.bodyClick.bind(this);
  }

  handleDocumentChange = (index, type) => {
    const id = +index.split("-")[1];
    this.setState({ ActiveTab: index, customerId: id, customerType: type });
    this.getDocRequestList(
      this.props?.leadDetails?.id,
      type,
      this.props.client
    );
  };

  toggleAccordion = (index) => {
    this.setState((prevState) => ({
      openAccordion: prevState.openAccordion === index ? null : index,
    }));
  };

  getDocRequestList = (lead_id, customerType, client) => {
    executeGraphQLQuery(GET_DOC_REQUEST_LIST(lead_id, customerType), client)
      .then((response) => {
        if (response.data && response.data?.get_doc_request_list) {
          const selList = response.data?.get_doc_request_list.map((obj) => ({
            id: obj.id,
            lead_id: obj.lead_id,
            parent_doc_id: obj.parent_doc_id,
            doc_id: obj.doc_id,
            customer_type: obj.customer_type,
            status: `${obj.status}`,
          }));
          this.setState({
            selectedDocsList: selList,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.bodyClick);
    this.getDocRequestList(
      this.props?.leadDetails?.id,
      this.state.customerType,
      this.props.client
    );
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.bodyClick);
  }

  toggleTip(id = 0) {
    let { tipOpen } = this.state;
    if (id) this.setState({ tipOpen: tipOpen === id ? 0 : id });
  }
  bodyClick(e) {
    this.setState({ tipOpen: 0 });
  }

  setFilter = (doc_id, parent_id) => {
    this.props.setFilter({ doc_id: doc_id, file_id: 0, parent_id: parent_id });
  };

  updateChildDocList = (doc_list, doc_id, customer_type, sec_doc_id) => {
    return doc_list?.map((v) => {
      if (doc_id && (v.id === doc_id || v.id === sec_doc_id)) {
        v["customer_type"] = `${customer_type}`;
        v.is_required = "1";
      } else {
        v["customer_type"] = "";
      }
      return v;
    });
  };

  handleDocCheck = (event) => {
    const approve = window.confirm(
      "Are you sure want to change recommended doc"
    );
    if (approve) {
      const parent_doc_id = +event.target.getAttribute("parentdocid");
      const doc_id = +event.target.getAttribute("docid");
      const lead_id = this.props?.leadDetails?.id;
      const customer_id = this.state.customerId;
      const customer_type = this.state.customerType;
      const isChecked = event.target.checked;
      const currentDocState = this.state.selectedDocsList;
      const currentDoc = currentDocState.filter(
        (current) =>
          current.parent_doc_id === parent_doc_id && current.doc_id === doc_id
      );
      const id = currentDoc.length > 0 ? currentDoc[0]?.id : null;
      const docObj = {
        ...(id ? { id } : {}),
        lead_id,
        doc_id,
        parent_doc_id,
        customer_id,
        customer_type,
        status: isChecked ? "1" : "0",
        is_recommended: isChecked ? 1 : 0,
      };

      const variables = {
        saveDocRequest: docObj,
      };

      const mutation = SAVE_DOC_REQUEST;

      executeGraphQLMutation(mutation, variables, this.props.client)
        .then((result) => {
          if (result.data.save_doc_request === "SUCCESS") {
            toast.success("Success");
            this.getDocRequestList(lead_id, customer_type, this.props.client);
          } else {
        //    console.log(result.data);
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error.message);
          toast.error("Internal server error");
        });
    }
  };

  handleChangeImage = (event) => {
    let files = Array.from(event.target.files),
      awsRes = [],
      leadDocArr = [];
    const lead_id = this.props?.leadDetails?.id;
    const doc_id = +event.target.getAttribute("docid");
    const parent_doc_id = +event.target.getAttribute("parentdocid");
    if (files && files.length) {
      this.setState({ isLoading: true });
      var formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "customer_doc");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        generalService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
    } else {
      awsRes.push(
        Promise.resolve({
          data: { status: 200, message: "Success", data: ["not_updated"] },
        })
      );
    }

    Q.allSettled(awsRes).then((uploadOutput) => {
      uploadOutput.forEach((res, index) => {
        let response = res.value || [];
        if (res.state === "fulfilled") {
          if (response.data.data.length) {
            let resData = response.data.data;
            resData.forEach((row, i) => {
              let loanDetailsSaveDoc = {
                lead_id,
                doc_path: row.file_url,
                parent_doc_id,
                doc_id,
                doc_source: "web",
                user_id: 0,
                customer_type: this.state.customerType,
                customer_id: +this.state.customerId,
              };
              leadDocArr.push(loanDetailsSaveDoc);
            });
          }
        }
      });
      if (leadDocArr.length) {
        let variables = {
          saveDocInput: {
            lead_id,
            lead_docs: leadDocArr,
          },
          api_called_by: "web",
        };

        let mutation = gql`
          mutation save_doc(
            $saveDocInput: SaveDocInput!
            $api_called_by: API_CALL_BY!
          ) {
            save_doc(
              saveDocInput: $saveDocInput
              api_called_by: $api_called_by
            ) {
              lead_id
              doc_id
              doc_path
            }
          }
        `;

        executeGraphQLMutation(mutation, variables, this.props.client)
          .then((result) => {
            if (result && result.data) {
              this.setState({ isLoading: false });
              toast.success("Document uploaded successfully");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              this.setState({ isLoading: false });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
          });
      } else {
        toast.error("Error in image upload!");
        this.setState({ isLoading: false });
      }
    });
  };

  getCountData = (files) => [
    ...files.reduce((mp, o) => {
      if (!mp.has(o.doc_id)) mp.set(o.doc_id, { ...o, count: 0 });
      mp.get(o.doc_id).count++;
      return mp;
    }, new Map()).values()
  ];

  filterFilesByList = (files, filterList) => files.filter(o1 => filterList.some(o2 => o1.doc_id === o2.id || o1.doc_id === 0));

  processChildDocList = (docList) => {
    let childList = [];
    docList?.forEach((data) => {
      childList = [...childList, ...data.child];
      if (data?.child?.length === 0) childList.push(data);
    });
    return childList;
  };

  processDocList = (docList, childList, dataWithCount, currCustomerType) => {
    return docList.reduce((acc, element) => {
      if (element.parent_id === 0) {
        element.count = dataWithCount.find(data => data.doc_id === element.id)?.count || 0;
  
        element["child"] = childList
          .filter(data => data.parent_id === element.id)
          .map(v => {
            let docObj = { ...v };
            let is_required = null;
            if (docObj.doc_section === "customer_details") {
              is_required = v.customer_type && v.customer_type.includes(currCustomerType) ? "1" : "0";
            }
            let uploaddocsCount = dataWithCount.find(data => data.doc_id === v.id)?.count || 0;
            docObj.is_required = is_required;
            docObj.count = uploaddocsCount;
            return docObj;
          }) || [];
          
        acc.push(element);
      }
      return acc;
    }, []);
  };

  render() {
    const { tipOpen, displayTootipIcon } = this.state;
    const { filter_doc_list, activeTab, leadDetails, filter_other_doc_list, filter_co_app_doc_list, filter_co_app_other_doc_list } =
      this.props;
    let { UploadedFiles, isLoading } = this.state;
    let customerDetails = leadDetails?.customer || {};
    let recommendDocList = [], pickOtherDocList = [];
    const mandantoryDocCount = 2;
    if (this.state.selectedDocsList.length === mandantoryDocCount) {
      recommendDocList = filter_doc_list;
      pickOtherDocList = filter_other_doc_list;
    } else {
      const filteredRecommendDocs = filter_other_doc_list.map(doc => {
        const listOfDoc = doc.child.filter(child => 
          this.state.selectedDocsList.some(recDoc => child.id === recDoc.doc_id && child.parent_id === recDoc.parent_doc_id)
        );
        return {
          ...doc,
          child: listOfDoc
        }
      }).filter(doc => doc.child.length > 0);
      
      const filteredOtherDocs = filter_other_doc_list.map(doc => {
        const listOfDoc = doc.child.filter(child => 
          !this.state.selectedDocsList.some(recDoc => child.id === recDoc.doc_id && child.parent_id === recDoc.parent_doc_id)
        );
        return {
          ...doc,
          child: listOfDoc
        }
      }).filter(doc => doc.child.length > 0);

      const mergedDocs = [];
      filter_doc_list.forEach(existing => {
        const matchingFilteredDoc = filteredRecommendDocs.find(filtered => filtered.id === existing.id);
        if (matchingFilteredDoc) {
          const mergedChildren = [...existing.child, ...matchingFilteredDoc.child];
          mergedDocs.push({ ...existing, child: mergedChildren });
        } else {
          mergedDocs.push(existing);
        }
      });

      filteredRecommendDocs.forEach(filtered => {
        const matchingExistingDoc = filter_doc_list.find(existing => existing.id === filtered.id);
        
        if (!matchingExistingDoc) {
          mergedDocs.push(filtered);
        }
      });

      recommendDocList = mergedDocs;
      pickOtherDocList = filteredOtherDocs;
    }

    let filter_doc_child_list = this.processChildDocList(recommendDocList);
    let filter_co_app_doc_child_list = this.processChildDocList(filter_co_app_doc_list);
    let filter_other_doc_child_list = this.processChildDocList(pickOtherDocList);
    let filter_co_app_other_doc_child_list = this.processChildDocList(filter_co_app_other_doc_list);

    let filterFiles = this.filterFilesByList(UploadedFiles, filter_doc_child_list);
    let otherDocfilterFiles = this.filterFilesByList(UploadedFiles, filter_other_doc_child_list);
    let coAppfilterFiles = this.filterFilesByList(UploadedFiles, filter_co_app_doc_child_list);
    let coAppotherDocfilterFiles = this.filterFilesByList(UploadedFiles, filter_co_app_other_doc_child_list);

    const datawithCount = this.getCountData(filterFiles);
    const otherDatawithCount = this.getCountData(otherDocfilterFiles);
    const coAppDatawithCount = this.getCountData(coAppfilterFiles);
    const coAppotherDatawithCount = this.getCountData(coAppotherDocfilterFiles);

    // check for if doc tagged for Tax id Card
    let currCustomerType = customerDetails?.customer_type || 0;
    const POST_LOGIN_DOC_ID = 114;
    const temp = this.processDocList(recommendDocList, filter_doc_child_list, datawithCount, currCustomerType);
    const coAppDocList = this.processDocList(filter_co_app_doc_list, filter_co_app_doc_child_list, coAppDatawithCount, currCustomerType);
    const otherDocLists = this.processDocList(pickOtherDocList, filter_other_doc_child_list, otherDatawithCount, currCustomerType);
    const coAppOtherDocList = this.processDocList(filter_co_app_other_doc_list, filter_co_app_other_doc_child_list, coAppotherDatawithCount, currCustomerType);
    const preLoginDocs = otherDocLists.filter(item => item.id === POST_LOGIN_DOC_ID);
    const removeLoginDocs = otherDocLists.filter(item => item.id !== POST_LOGIN_DOC_ID);
    const otherDocList = removeLoginDocs;


    const showDocs = (temp, doc_type) => {
      if (doc_type === "recommend") {
        if (this.props.uType === "co_applicant") {
          if (this.props.caType === "non_financing") {
            // temp = temp.filter((ob) => ob.is_coapplicant === "1" && ob.is_financial === "0");
            temp = temp.map((ob) => {
              const childs = ob?.child?.filter(chOb => chOb.no_financial == "1");
              if (childs.length > 0) {
                return { ...ob, child: childs }
              }
            });
          } else {
            // temp = temp.filter((ob) => ob.is_coapplicant === "1");
            const KYC_DOC_ID = 3;
            temp = temp.filter((ob) => {
              const childs = ob?.child?.filter(chOb => chOb.no_financial == "0" || chOb.parent_id === KYC_DOC_ID);
              if (childs.length > 0) {
                return { ...ob, child: childs }
              }
            });
          }
        }
      }
      temp = temp.filter(item => item !== null && item !== undefined);
      let doc_list_arr = temp.map((doc, index) => {
        var childCountArray = doc?.child?.filter((val) => val.count > 0);
        var childRequiredCount = doc?.child?.filter(
          (val) =>
            (val.count >= val.min_upload && val.is_required === "1") ||
            (val.count > 0 && val.count >= val.min_upload)
        );
        var childCountRequired = doc?.child?.length
          ? doc?.child?.filter((v) => v.is_required === "1" || v.count > 0)
          : [];

        let isUserDoc = this.props.leadDetails?.leaddocs?.filter(
          (ob) => ob.customer_id == this.props.uId && ob.parent_doc_id == doc.id
        );

        let docUploadStatus =
          (doc?.child?.length === 0 && doc.count >= doc.min_upload) ||
          (doc?.child?.length > 0 &&
            childRequiredCount.length === childCountRequired.length &&
            childCountArray.length &&
            isUserDoc.length > 0)
            ? "done"
            : "";

        let rejectedCount = 0;
        const filteredDocs = doc?.child?.map(child => {
          return isUserDoc?.filter(userDoc => 
            userDoc.doc_id === child.id && userDoc.parent_doc_id === child.parent_id
          );
        }).flat();
        filteredDocs?.forEach(obj => obj?.doc_status === 0 && rejectedCount++);

        let showParentRequired =
          doc?.child?.some((item) => item.is_required === "1") || false;
        return (
          <AccordionItem key={`${doc.id}_${index}`}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {(docUploadStatus === "done" && rejectedCount === 0) ? (
                  <span className="stepprogress">
                    <i className="sprite ic-check"></i>
                  </span>
                ) : null}

                {`${doc.doclang && doc.doclang.name} ${
                  showParentRequired ? " *" : ""
                }`}
                {doc.min_upload && displayTootipIcon ? (
                  <React.Fragment>
                    <span
                      onClick={() => this.toggleTip(doc.id)}
                      title=""
                      className="tooltio-ic"
                    >
                      <i className="ic-info-icon1"></i>
                    </span>
                    <Tooltip
                      html={
                        <div
                          ref={this.tipContentRef}
                          className="controlled-example tooltip-main"
                        >
                          <div className="tooltip-data">
                            <span
                              className="controlled-example_close-button"
                              onClick={() => this.toggleTip(doc.id)}
                            >
                              <i className="ic-clearclose"></i>
                            </span>
                            <p>
                              <span className="label">
                                Min Upload : {doc.min_upload}
                              </span>{" "}
                              &nbsp; | &nbsp;
                              <span className="label">
                                Max Upload : {doc.max_upload}
                              </span>
                            </p>
                          </div>
                        </div>
                      }
                      open={tipOpen === doc.id}
                      tagName="span"
                      position="bottom"
                      trigger="click"
                      interactive={true}
                      animateFill={false}
                      arrow={true}
                      arrowSize="small"
                      className="tootip-outer tooltip-doc-list"
                      forceDirection
                    ></Tooltip>
                  </React.Fragment>
                ) : null}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="acco-doc-list">
                <ul>
                  {doc.child.length ? (
                    doc.child.map((doc, index) => {
                      let docarray = this.props.leadDetails?.leaddocs?.filter(
                        (ob) =>
                          ob.customer_id === this.props.uId &&
                          ob.doc_id === doc.id
                      );
                      let docNo = docarray?.length;
                      const isReq = doc_type === "recommend" ? doc?.is_required_app || "0" : "0";
                      const isDisabled =
                        isReq == "1"
                          ? true
                          : isUserDoc?.filter(
                              (obj) =>
                                obj.doc_id === doc.id &&
                                obj.parent_doc_id === doc.parent_id &&
                                obj.doc_status === 2
                            ).length > 0
                          ? true
                          : false;

                      const isFilteredUserDoc = isUserDoc?.filter(
                        (obj) =>
                          obj.doc_id === doc.id &&
                          obj.parent_doc_id === doc.parent_id
                      );

                      const docStatus =
                        isFilteredUserDoc?.length > 0
                          ? isFilteredUserDoc[0].doc_status
                          : null;

                      const isChecked =
                        isReq == "1"
                          ? true
                          // : doc_type === "recommend"
                          // ? true
                          : this.state.selectedDocsList?.length &&
                            this.state.selectedDocsList?.filter(
                              (selObj) =>
                                selObj.doc_id === doc.id &&
                                selObj.parent_doc_id === doc.parent_id &&
                                selObj.status === "1" &&
                                selObj.customer_type === this.props.uType
                            ).length > 0
                          ? true
                          : false;

                      return (
                        <li key={`${doc.id}${index}`}>
                          <span>
                            {`${doc.doclang.name} ${
                              doc.is_required === "1" ? "*" : ""
                            }`}
                          </span>
                          <span>
                            {docStatus === 2 && (
                              <>
                                <img
                                  src={verified}
                                  alt="icon"
                                  width={20}
                                  height={20}
                                />
                              </>
                            )}

                            {docStatus === 1 && (
                              <>
                                <img
                                  src={verifiedone}
                                  alt="icon"
                                  width={20}
                                  height={20}
                                />
                              </>
                            )}

                            {docStatus === 0 && (
                              <>
                                <img
                                  src={reject}
                                  alt="icon"
                                  width={20}
                                  height={20}
                                />
                              </>
                            )}

                            {docStatus === null && (
                              <>
                                <label
                                  htmlFor={`fileuploadByIcon${doc.parent_id}_${doc.id}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="ic-upload"
                                    style={{ fontSize: 24 }}
                                  ></i>
                                </label>
                                <input
                                  type="file"
                                  name="fileName[]"
                                  multiple
                                  id={`fileuploadByIcon${doc.parent_id}_${doc.id}`}
                                  onChange={this.handleChangeImage}
                                  parentdocid={doc.parent_id}
                                  docid={doc.id}
                                  style={{ display: "none" }}
                                />
                              </>
                            )}
                          </span>
                          <span>
                            <div className="d-flex">
                              <label className="toggle-switch-doc">
                                <input
                                  type="checkbox"
                                  onChange={this.handleDocCheck}
                                  name="disbursed_with_otc"
                                  id="disbursed_with_otc"
                                  parentdocid={doc.parent_id}
                                  docid={doc.id}
                                  checked={isChecked}
                                  disabled={isDisabled}
                                />
                                <div
                                  className="slider"
                                  style={{ width: "45px" }}
                                ></div>
                              </label>
                            </div>
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <li key={`${doc.id}${index}`}>
                      {`${doc.doclang.name} ${
                        doc.is_required === "1" ? "*" : ""
                      }`}
                    </li>
                  )}
                </ul>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        );
      });
      return doc_list_arr;
    };

    const renderedTabs = (
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {this.props.uType === "co_applicant" ? showDocs(coAppDocList, "recommend") : showDocs(temp, "recommend")}
      </Accordion>
    );

    const renderedOtherTabs = (
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {this.props.uType === "co_applicant" ? showDocs(coAppOtherDocList, "other") : showDocs(otherDocList, "other")}
      </Accordion>
    );

    const renderedPostLoginTabs = (
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {this.props.uType === "co_applicant" ? showDocs(preLoginDocs, "other") : showDocs(preLoginDocs, "other")}
      </Accordion>
    );

    return (
      <>
        <div
          className="upload-document-list-accordain"
          key={`${activeTab}_${filter_doc_list.length}`}
        >
          <div className="doc-list-heading doc-collect-parent">
            <h3>Documents</h3>
          </div>
          <div style={{ margin: "10px 0px" }}>
            <h3 style={{ fontWeight: "500" }}>Recommended Docs</h3>
          </div>
          <div className="d-flex documentdoc">
            <span>Category and Name</span>
            <span>Status</span>
            <span>Recommended</span>
          </div>
          {isLoading ? <Loader /> : null}
          {renderedTabs}
          <div style={{margin: "20px 0px"}}>
            <hr></hr>
          </div>
          <div style={{ margin: "10px 0px" }}>
            <h3 style={{ fontWeight: "500" }}>Pick Other Docs</h3>
          </div>
          <div className="d-flex documentdoc">
            <span>Category and Name</span>
            <span>Status</span>
            <span>Recommended</span>
          </div>
          {isLoading ? <Loader /> : null}
          {renderedOtherTabs}
          <div style={{margin: "20px 0px"}}>
            <hr></hr>
          </div>
          <div style={{ margin: "10px 0px" }}>
            <h3 style={{ fontWeight: "500" }}>Post Login Docs </h3>
          </div>
          <div className="d-flex documentdoc">
            <span>Category and Name</span>
            <span>Status</span>
            <span>Recommended</span>
          </div>
          {isLoading ? <Loader /> : null}
          {renderedPostLoginTabs}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ lead, common, user }) => {
  return {
    authUser: user.authUser,
  };
};

export default withTranslation("loan_details")(
  connect(mapStateToProps, null)(UploadDocumentList)
);
