import React, { useEffect, useState } from "react";
import DateFormate from 'dateformat';
import { FINEX_MASTER_LIST,PAYOUT_POINT_DETAILS } from "../../services/scheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery";
import { IS_USER_ACCESS_PAGE } from '../../config/constants';
import { RESET_PAYOUT_STATUS, UPDATE_PAYOUT_POINT} from "../../services/payout.gql"
import { toast } from 'react-toastify';
import InputField from "../elements/InputField";

const BookingPoint = ({payout_points_list}) => {   
    const [financers, setFinancers] = useState([]);
    const [bookingpoints, setBookingPoints] = useState([]);
    const [product_type, setProductType] = useState([]);
    const [payout_points, setPayoutpoints] = useState([])
    const [payoutPointEditDetails, setPayoutPointEditDetails] = useState({});

    let bank_create =  IS_USER_ACCESS_PAGE("payout", "bank_create")?.is_edit_access;
    let bank_update =  IS_USER_ACCESS_PAGE("payout", "bank_update")?.is_edit_access;

    useEffect(() => {
        getFinexMasterData()
        getPayoutDataList()
    }, []);

    const getPayoutDataList =  async() => {
        const result = await executeGraphQLQuery(PAYOUT_POINT_DETAILS());
        const PayoutDataList = []
        result.data.points.map(point => {
           PayoutDataList.push({ id: point.id, name: point.booking_point });
       });
       setPayoutpoints(PayoutDataList)
   }
   
    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const bookingPointsData = []
            const producttypedata = [];
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const products = data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];

            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
                payout_points.map(point => {
                    bookingPointsData.push({ id: point.lead_status, name: point.point_name });
                });
                setBookingPoints(bookingPointsData);

                if (products && products.length > 0) {
                    products.map(ptype => {
                        producttypedata.push({ id: ptype.id, name: ptype.label })
                    })
                    setProductType(producttypedata)
                }

            }

        }

    }

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    
    const updatePayoutPoint = async (point, event) => {
        try {
          event.stopPropagation();
          if (window.confirm("Do you want to update this payout point")) {
            const updateVariables = {
              id: point?.id,
              end_date: formatDateTime(payoutPointEditDetails[point?.id]),
            };
            const resetVariables = {
              resetPayoutStatus: {
                financier_id: point.financier_id,
                product_type: point.product_type,
                start_date: formatDateTime(point.start_date),
                end_date: formatDateTime(payoutPointEditDetails[point?.id]),
              },
            };

            const updatePayoutPointProm = new Promise((resolve) =>
              resolve(executeGraphQLMutation(UPDATE_PAYOUT_POINT, updateVariables))
            );
            const resetPayoutStatusProm = new Promise((resolve) =>
              resolve(executeGraphQLMutation(RESET_PAYOUT_STATUS, resetVariables))
            );
    
            const [updatePayoutPointResp, resetPayoutStatusResp] =
              await Promise.all([updatePayoutPointProm, resetPayoutStatusProm]);
    
            if (updatePayoutPointResp.data?.update_payoutpoint === "Success") {
              toast.success("Payout point updated successfully!");
            } else {
              toast.error(updatePayoutPointResp.data?.update_payoutpoint);
            }
    
            if (resetPayoutStatusResp.data?.reset_payout_status === "Success") {
              toast.success("Payout status updated successfully!");
            } else {
              toast.error(resetPayoutStatusResp.data?.reset_payout_status);
            }
    
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } catch (error) {
          toast.error(error.message);
        }
    };
    
    const handleDateEdit = (point, event) => {
        event.stopPropagation();
        const { id, end_date } = point;
        const dateObj = new Date(end_date);
        const dateOnly = `${dateObj.getFullYear()}-${String(
          dateObj.getMonth() + 1
        ).padStart(2, "0")}-${String(dateObj.getDate()).padStart(2, "0")}`;
        
        setPayoutPointEditDetails({ [id]: dateOnly });
    };
    
    const handleDateChange = (event, id) => {
        if (event.target) {
            event.stopPropagation();
            const { value } = event.target;
            setPayoutPointEditDetails({ [id]: value });
        }
    };

    return (
        <>
            <div className='modal-body' style={{overflowY: "scroll"}}>
              
                <h2 className="top-heading">Payout Point</h2>
                <div className='clearfix'>
                    <div className="card">
                        <div className='table-responsive '>
                            <table className='table table-bordered tablefontsize' >
                                <thead>
                                    <tr>
                                        <th>{('ID')}</th>
                                        <th>{('Bank')}</th>
                                        <th>{('% Payout')}</th>
                                        <th>{('Booking Point')}</th>
                                        <th>{('Product Type')}</th>
                                        <th>{('Start Date')}</th>
                                        <th>{('End Date')}</th>
                                        <th>{('Status')}</th>
                                        <th>{('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payout_points_list && payout_points_list.length > 0 && payout_points_list.map((point) => (

                                        <tr key={point.id}>
                                            <td>{point.id}</td>
                                            <td>{financers && financers.length > 0 && point.financier_id && financers.filter(bank => bank.id == point.financier_id)[0]?.name}</td>
                                            <td>{point.payout_per}</td>
                                            <td>{point.payoutpointgroup.map((point) => point.points.booking_point).join(" + ")}</td>
                                            <td>
                                                {product_type.find(pt => pt.id == point.product_type)?.name || ""}
                                            </td>
                                            <td>{DateFormate(new Date(point.start_date), "dS mmm yyyy")}</td>
                                            <td>
                                                {payoutPointEditDetails?.[point.id] ? (
                                                    <InputField
                                                        inputProps={{
                                                            id: "start_date",
                                                            type: "date",
                                                            name: "start_date",
                                                            autocompleate: "off",
                                                            min: new Date(new Date().setDate(new Date().getDate() + 1))
                                                                .toISOString()
                                                                .split("T")[0],
                                                            value: payoutPointEditDetails?.[point.id] ? DateFormate(new Date(payoutPointEditDetails?.[point.id]), 'yyyy-mm-dd') : '',

                                                        }}
                                                        onChange={(date, event) => handleDateChange(date, point.id, event)}
                                                    />
                                                ) : (
                                                    <>
                                                        {DateFormate(new Date(point.end_date), "dS mmm yyyy")}
                                                    </>
                                                )}
                                            </td>
                                            <td>{point.status==1? "Active":"In-active"}</td>
                                            <td>

                                            <div style={{display:"flex"}}>
                                            {bank_update === 1&& (
                                                <div 
                                                    style={{ background: "#4e53a4", color: "#fff" }} 
                                                    className="btn-group btn-group-small mrg-r10"
                                                    onClick={(e) => payoutPointEditDetails?.[point.id] ? updatePayoutPoint(point, e) : handleDateEdit(point, e)}
                                                >
                                                    <div>{payoutPointEditDetails?.[point.id] ? "Update" : "Edit"}</div>
                                                </div>
                                            )}
                                            
                                            </div>
                                            </td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BookingPoint;





