import React, { useEffect, useState } from "react";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import InputField from "../elements/InputField.js";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery.js";
import { FINEX_MASTER_LIST } from "../../services/scheme.gql.js";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CTP_LIST, PTP_LIST, HARD_MONTH_LIST } from "../../config/constants.js";
import { ADD_RULE_CONFIG, GET_RULES_LIST } from "../../services/etaRule.gql.js";
import { toast } from "react-toastify";
import DateFormate from "dateformat";
import Pagination from "../elements/pagination.js";

const RuleListing = () => {
  const [pageloading, setpageloading] = useState(false);
  const [financers, setFinancers] = useState([]);
  const [ruleListData, setRuleListData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [filter_params, setFilterParams] = useState({});
  const [isEditMode, setIsEditMode] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilterParams((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleSelectChange = (id, data) => {
    setFilterParams((current) => ({
      ...current,
      [id]: data.id,
    }));
  };

  const resetFilterForm = () => {
    window.location.reload();
  };

  const searchFilter = async () => {
    setpageloading(true);
    getRulesList(filter_params);
  };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];

      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            value: banks.banklang.bank_id,
            label: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
    }
  };

  const getRulesList = async (filter_params = {}, page_no) => {
    try {
      setpageloading(true);
      if (page_no) {
        filter_params.page_no = page_no;
      }
      const response = await executeGraphQLQuery(GET_RULES_LIST, {
        filter_params,
      });

      setpageloading(false);
      if (response.data?.get_rules_list) {
        const ruleList = response.data.get_rules_list?.rule_list || [];
        const pagination = response.data.get_rules_list?.pagination || {};
        setRuleListData(ruleList);
        setPaginationData(pagination);
      } else {
        setRuleListData([]);
        setPaginationData({});
      }
    } catch (error) {
      toast.error(error.message);
      setpageloading(false);
    }
  };

  useEffect(() => {
    getFinexMasterData();
    getRulesList();
  }, []);

  const handlePagination = (page_no) => {
    getRulesList(filter_params, page_no);
  };

  const handleRuleEndDateEdit = (index, date, id) => {
    setIsEditMode((prev) => ({ ...prev, [index]: true }));
    setFilterParams((prev) => ({ ...prev, id, edited_rule_end_date: date }));
  };

  const handleUpdateRuleEndDate = async () => {
    try {
      if (!filter_params?.edited_rule_end_date)
        return toast.error("Please select a date!");

      const existingRule = ruleListData.find(
        (rule) => rule.id === filter_params?.id
      );
      if (existingRule) {
        if (existingRule.hasOwnProperty("__typename")) {
          delete existingRule.__typename;
        }
        const addRuleInput = {
          ...existingRule,
          rule_end_date: filter_params?.edited_rule_end_date,
        };
        const response = await executeGraphQLMutation(ADD_RULE_CONFIG, {
          addRuleInput,
        });

        if (response.data?.add_rule_config) {
          if (response.data?.add_rule_config === "Success") {
            toast.success("Success");
          } else {
            toast.error(response.data?.add_rule_config);
          }
          setTimeout(() => window.location.reload(), 2000);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleResetEditMode = (index) => {
    setIsEditMode((prev) => ({ ...prev, [index]: false }));
    setFilterParams((prev) => ({
      ...prev,
      id: null,
      edited_rule_end_date: "",
    }));
  };

  return (
    <>
      <div
        className={pageloading ? "container-fluid loading" : "container-fluid"}
      >
        <h2 className="top-heading">Payout ETA Configs</h2>
        <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2">
              <label>Banks</label>
              <Select
                id="financer_id"
                name="financer_id"
                options={financers}
                value={
                  filter_params.financer_id &&
                  financers.find(({ id }) => id === filter_params.financer_id)
                }
                onChange={(data) => handleSelectChange("financer_id", data)}
              ></Select>
            </div>

            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "rule_start_date",
                  type: "date",
                  name: "rule_start_date",
                  autocompleate: "off",
                  label: "Rule start",
                  value: filter_params.rule_start,
                }}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "rule_end_date",
                  type: "date",
                  name: "rule_end_date",
                  autocompleate: "off",
                  label: "Rule end",
                }}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-sm-2">
              <label>CTP</label>
              <Select
                id="ctp"
                name="ctp"
                options={CTP_LIST}
                value={
                  filter_params.ctp &&
                  CTP_LIST.find(({ id }) => id === filter_params.ctp)
                }
                onChange={(data) => handleSelectChange("ctp", data)}
              ></Select>
            </div>

            <div className="col-sm-2">
              <label>PTP</label>
              <Select
                id="ptp"
                name="ptp"
                options={PTP_LIST}
                value={
                  filter_params.ptp &&
                  PTP_LIST.find(({ id }) => id === filter_params.ptp)
                }
                onChange={(data) => handleSelectChange("ptp", data)}
              ></Select>
            </div>

            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={searchFilter}
                >
                  Search
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilterForm}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="pad-15">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <div className="col-sm-2">
              <div className="text-right">
                <NavLink to="/payout/add-rule" className="add-schemebtn">
                  Add new config
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix">
          <div className="table-responsive">
            <table className="table table-bordered tablefontsize">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Bank</th>
                  <th>Rule interval</th>
                  <th>CTP</th>
                  <th>PTP</th>
                  <th>MIS date</th>
                  <th>Collection</th>
                </tr>
              </thead>
              <tbody>
                {ruleListData.map((rule, index) => (
                  <>
                    <tr key={index}>
                      <td>{rule.id}</td>
                      <td>
                        {rule.financer_id
                          ? financers.find(({ id }) => id === rule.financer_id)
                              ?.label
                          : ""}
                      </td>
                      <td>
                        <div>
                          {rule.rule_start_date
                            ? DateFormate(
                                new Date(rule.rule_start_date),
                                "dS mmm yyyy"
                              )
                            : ""}
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          {rule.rule_end_date && !isEditMode?.[index]
                            ? DateFormate(
                                new Date(rule.rule_end_date),
                                "dS mmm yyyy"
                              )
                            : ""}
                          {rule.rule_end_date && !isEditMode?.[index] && (
                            <div
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={() =>
                                handleRuleEndDateEdit(
                                  index,
                                  rule.rule_end_date,
                                  rule.id
                                )
                              }
                            >
                              <i className="ic-createmode_editedit icons-normal"></i>
                            </div>
                          )}
                          {isEditMode?.[index] && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <InputField
                                inputProps={{
                                  id: "edited_rule_end_date",
                                  type: "date",
                                  name: "edited_rule_end_date",
                                  autocompleate: "off",
                                  value: filter_params?.edited_rule_end_date
                                    ? new Date(
                                        filter_params.edited_rule_end_date
                                      ).toLocaleDateString("en-CA") // Formats in "YYYY-MM-DD"
                                    : "",
                                  min: new Date().toISOString().split("T")[0],
                                }}
                                onChange={handleInputChange}
                              />
                              <div
                                onClick={handleUpdateRuleEndDate}
                                style={{
                                  margin: "10px 10px",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="ic-check icons-normal"></i>
                              </div>
                              <div
                                onClick={() => handleResetEditMode(index)}
                                style={{
                                  margin: "10px 0px",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="ic-clearclose icons-normal"></i>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        {
                          CTP_LIST.filter((item) => item.id == rule.ctp)[0]
                            ?.label
                        }
                      </td>
                      <td>
                        {
                          PTP_LIST.filter((item) => item.id == rule.ptp)[0]
                            ?.label
                        }
                      </td>
                      <td>
                        {rule.expected_mis_day} -
                        {
                          HARD_MONTH_LIST.filter(
                            (item) => item.id == rule.expected_mis_month
                          )[0]?.label
                        }
                      </td>
                      <td>
                        {rule.expected_collection_day} -
                        {
                          HARD_MONTH_LIST.filter(
                            (item) => item.id == rule.expected_collection_month
                          )[0]?.label
                        }
                      </td>
                    </tr>
                  </>
                ))}

                {ruleListData.length > 0 && (
                  <tr>
                    <td colSpan={4}>
                      <Pagination
                        pageCount={
                          paginationData && paginationData.totalpage
                            ? paginationData.totalpage
                            : 0
                        }
                        activePage={
                          filter_params.page_no ? filter_params.page_no : 1
                        }
                        onPaginate={handlePagination}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {paginationData && paginationData.totalrecords
                        ? paginationData.totalrecords
                        : 0}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("ac_manager")(
  connect(mapStateToProps, mapDispatchToProps)(RuleListing)
);
