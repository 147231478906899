import { LEAD_CREATION_FORM, PARTNER_DROPDOWN_LIST, ASSIGN_LEAD_CSV, GET_SFA_USER_LIST, GET_REFERENCE_DETAILS_BY_LEAD_ID } from "../../../services/leadCreate.gql";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { convertCsvToJson, convertJsonToCsv, debounce, downloadFile } from "../../../helpers/helpers";
import { E2E_SALES, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import FormFields from "../formFields/customerDetailsFormFields.json";
import { getMasterData } from "../../../store/action/allAction";
import FormsyInputField from "../../elements/FormsyInputField";
import React, { useEffect, useMemo, useState } from "react";
import FormsySelect from "../../elements/FormsySelect";
import { useDispatch, useSelector } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import Formsy from "formsy-react";

import * as yup from "yup";


const LEAD_FORM = FormFields["LEAD_CREATION_FORM"];
const REFERENCE_FORM = FormFields["REFERENCE_FORM"];
const BULK_LEAD_FORMAT = FormFields["LEAD_CREATION_BULK_FORMAT"];
const LEAD_ASSIGN_FORMAT = FormFields["LEAD_CREATION_ASSIGN_LEAD_FORMAT"];

const FULFILLMENT_TYPE_ENUM = ["ambak", "self"];
const LOAN_TYPE_ENUM = ["home loan", "llp"];
const LOAN_SUB_TYPE_ENUM = ["bt", "topup"];
const SOURCE_ENUM = [
  "Referrals",
  "Online",
  "Saathi",
  "Yoddha",
  "Affiliate",
  "Ofline"
];
const SUB_SOURCE_MAPPING_ENUM = {
  Referrals: ["Customer Referral", "Internal Referral"],
  Online: ["Direct", "Google Paid", "LinkedIn", "Facebook", "Instagram", "Youtube", "Justdial", "Website","Twitter"],
  Saathi: ["Partner Referral"],
  Yoddha: ["Partner Referral", "Yoddha Referral"],
  Affiliate: ["Online property brokers", "Society Access Control systems"],
  Inbound: ["Call"],
  Ofline: ["DDA booths","DDA - Auction Data"]
};
const LOAN_TYPE_MAPPING = {
  "home loan": 1,
  llp: 2,
};
const LOAN_SUB_TYPE_MAPPING = {
  bt: "1",
  topup: "2",
};
const SOURCE_MAPPING = {
  "Referrals":8,
  "Online":9,
  "Saathi":10,
  "Yoddha":11,
  "Affiliate":12,
  "Inbound":13,
  "Ofline":14
};

const SUB_SOURCE_MAPPING = {
  "Customer Referral":37,
  "Internal Referral":38,
  "Direct":39,
  "Google Paid":40, 
  "LinkedIn":41, 
  "Facebook":42, 
  "Instagram":43, 
  "Youtube":44, 
  "Justdial":45, 
  "Website":46,
  "Partner Referral":47,
  "Partner Referral":48, 
  "Yoddha Referral":49,
  "Online property brokers":50,
  "Society Access Control systems":51,
  "Twitter":52,
  "Call":53,
  "DDA booths":54,
  "DDA - Auction Data":55
};


const SOURCE_ID_MAP = {
  8: "Referrals",
  9: "Online",
  10: "Saathi",
  11: "Yoddha",
  12: "Affiliate",
  13: "Inbound",
  14: "Ofline"
};


const leadSchema = yup.object().shape({
  first_name: yup.string().required("Firstname is required"),
  last_name: yup.string().required("Lastname is required"),
  mobile: yup
  .string()
  .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
  .required("Mobile number is required"),
  loan_type: yup.string().lowercase().oneOf(LOAN_TYPE_ENUM).required("Loan type is required"),
  loan_sub_type: yup
    .string()
    .lowercase()
    .oneOf([...LOAN_SUB_TYPE_ENUM, ""])
    .nullable(),
  loan_amount: yup.string().required("Loan amount is required"),
  fulfillment_type: yup.string().oneOf(FULFILLMENT_TYPE_ENUM).required("Fulfillment type is required"),
  // rm_id: yup.number().required(),
  source: yup.string().oneOf(SOURCE_ENUM).required("Source is required"),
  sub_source: yup
    .string()
    .when("source", (source, schema) => {
      if (source && SUB_SOURCE_MAPPING_ENUM[source]) {
        return schema.oneOf(SUB_SOURCE_MAPPING_ENUM[source]);
      }
      return schema;
    })
    .required("Sub Source is required"),
  partner_id: yup.number().required("Partner ID is required"),
});

const formLeadSchema = yup.object().shape({

  reference_number: yup
  .string()
  .when((value, schema) => {
    if (value[0]?.length == 0) {
      return schema.notRequired();
    }
    return schema.min(10, "Reference number cannot be less than 10 characters").max(10, "Reference number cannot be more than 10 characters");
  }),
  reference_name: yup
  .string()
  .when("reference_type", (reference_type, schema) => {
    if (reference_type[0] !== "other" || !reference_type[0]) {
      return schema.notRequired();
    }
    return schema.required("Reference name is required");
  }),
  partner_id: yup
  .number()
  .when("sub_source", (sub_source, schema) => {
    if (![37,38,39,40,41,42,43,44,45,46,49].includes(sub_source[0])) {
      return schema.required("Partner ID is required");
    }
    return schema; // No additional validation if source is 8 or 9
  }),
  bu_list: yup
  .number()
  .when("source", (source, schema) => {
    if ([9].includes(source[0])) {
      return schema.required("Bu Tagging is required");
    }
    return schema; // No additional validation if source is 8 or 9
  }),
  sub_source: yup
  .mixed()
  .when("source", (source, schema) => {
    if (source && SUB_SOURCE_MAPPING_ENUM[source]) {
      return schema.oneOf(SUB_SOURCE_MAPPING_ENUM[source]);
    }
    return schema;
  })
  .required("Sub Source is required"),
  source: yup
  .mixed()
  .test("is-valid-source", "Invalid source", (value) => {
    const validSourceIds = Object.keys(SOURCE_ID_MAP).map(Number); // Convert keys to numbers
    const validSourceNames = Object.values(SOURCE_ID_MAP);
    return validSourceIds.includes(value) || validSourceNames.includes(value);
  })
  .required("Source is required"),
  fulfillment_type: yup.string().oneOf(FULFILLMENT_TYPE_ENUM).required("Fulfillment type is required"),
  loan_amount: yup.string().required("Loan amount is required"),
  loan_type: yup.string().lowercase().required("Loan type is required"),
  mobile: yup
  .string()
  .matches(/^[6-9]\d{9}$/, "Invalid Mobile Number")
  .required("Mobile number is required"),
  last_name: yup.string().required("Lastname is required"),
  first_name: yup.string().required("Firstname is required"),

});

const LeadsCreateForm = (props) => {
  let createLeadE2E =  IS_USER_ACCESS_PAGE("create_lead_E2E", "create_lead_E2E")?.is_edit_access;
  let createLeadBoth =  IS_USER_ACCESS_PAGE("create_lead_both", "create_lead_both")?.is_edit_access;
  const [leadCreate, setLeadCreate] = useState({});
  const [canRadioEdit, setCanRadioEdit] = useState(false)
  const [key, setKey] = useState("create leads");
  const [uploadedFileJson, setUploadedFileJson] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [sfaUserData, setSfaUserData] = useState([]);
  const [isFileInvalid, setIsFileInvalid] = useState(false);
  const [selectedValue, setSelectedValue] = useState('no_reference');
  const [Fulfillment, setFulfillment] = useState('');
  const client = useApolloClient();
  const dispatch = useDispatch(); 


  const { masterdata, userInfo } = useSelector(({ masterdata, user }) => ({
    masterdata,
    userInfo: user.user_information,
  }));

  const handleRadioChange = (event) => {
    if(canRadioEdit){
      resetReferenceData()
      setSelectedValue(event.target.value);
      setLeadCreate((currentValue)=>({
        ...currentValue,
        reference_type: event.target.value,
      }))
    }
  };

  const FulfillmentTypeChange = (event) => {
    setFulfillment(event.target.value);
    setLeadCreate((currentValue)=>({
      ...currentValue,
      fulfillment_type: event.target.value,
    }))
  };
  
  let userDetail = userInfo && JSON.parse(userInfo);
  let userId = (userDetail && userDetail.id) || 0;


  useEffect(()=>{
    if(createLeadBoth===1){
    }else if(createLeadE2E===1){
      setLeadCreate((currentValue)=>({
        ...currentValue,
        fulfillment_type: "ambak",
      }))
    }
  },[createLeadE2E,createLeadBoth])
  
  const getPartnerData = () => {
    executeGraphQLQuery(PARTNER_DROPDOWN_LIST, client).then((result) => {
      if (result?.data) {
        const jsonData = JSON.parse(result?.data?.partner_list_dropdown);
        if (jsonData?.status === 200) {
          const partnerList = jsonData?.data?.map(obj => {
            return {
              id: obj.partnerId,
              label: `${obj.PartnerName} - ${obj.partnerId}`,
              value: obj.partnerId,
              isDemo: obj.isDemo,
            }
          });

          setPartnerData(partnerList);
        }
      }
    })
      .catch((errors) => {
        toast.error("Something went wrong")
      });
  }

  const getSfaUserData = () => {
    executeGraphQLQuery(GET_SFA_USER_LIST, client).then((result) => {
      if (result?.data) {
          const sfaUserList = result.data.sfa_user_list?.map(obj => {
            return {
              id: obj.id,
              label: `${obj.name} - ${obj.id}`,
              value: obj.partnerId,
              mobile: obj.mobile,
              name: obj.name
            }
          });

          setSfaUserData(sfaUserList);
      }
    })
      .catch((errors) => {
        console.log(errors.message);
        toast.error("Something went wrong")
      });
  }

  useEffect(()=>{
    getPartnerData();
    getSfaUserData();
    // if(!masterdata){
      dispatch(getMasterData(client));
    // }
  },[])

  const setSourceOptions = (options) => {
    const listOfSources = [];
      options["bu_list"] = options["bu_list"]?.map((obj) => ({
        id: obj.id,
        label: obj.label,
        value: obj.id,
      }));
      options["source"] = options["subsource_type"]
        ?.filter((obj) => {
          if (!listOfSources.includes(obj.source_name)) {
            listOfSources.push(obj.source_name);
            return {
              id: obj.source_id,
              label: obj.source_name,
              value: obj.source_id,
            };
          }
        })
        .map((obj) => ({
          id: obj.source_id,
          label: obj.source_name,
          value: obj.source_id,
        }));
    return options;
  }

  let leadAssignAccess = IS_USER_ACCESS_PAGE("assign_lead", "assign_lead").is_edit_access;
  let leadAccess = IS_USER_ACCESS_PAGE("create_lead", "create_lead").is_edit_access;
  let leadUploadAccess = IS_USER_ACCESS_PAGE("bulk_upload_lead", "bulk_upload_lead").is_edit_access;

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options = setSourceOptions(options);
    return options;

  }, [masterdata.data]);

  useEffect(() => {
    ALL_OPTIONS["partner_id"] = partnerData;
    ALL_OPTIONS["yodhha_id"] = sfaUserData;
  }, [partnerData, sfaUserData,ALL_OPTIONS]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setLeadCreate((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handlePatternFormatChange = (data,field_name) => {
    if (field_name && data.value !== undefined) {
      setLeadCreate((current) => ({
        ...current,
        [field_name]: data.value,
      }));
    }
  }

  function fetchSearchResults(lead_id) {
    // Simulate an API call
    const mutation = GET_REFERENCE_DETAILS_BY_LEAD_ID;
    const variables = {
      lead_id: parseFloat(lead_id),
    }
    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
          if(resp?.data?.get_reference_details_by_lead_id){
            if(resp?.data?.get_reference_details_by_lead_id?.lead_id !==null ){
              setLeadCreate((current) => ({
                ...current,
                reference_name: resp?.data?.get_reference_details_by_lead_id?.first_name + " " + resp?.data?.get_reference_details_by_lead_id?.last_name,
                reference_number: `XXXXXX${resp?.data?.get_reference_details_by_lead_id?.mobile?.slice(-4)}`
              }))
            }else{
              toast.error("Invalid Lead Id")
            }
          }else{
            setLeadCreate((current) => ({
              ...current,
              reference_name: "",
              reference_number: ""
            }))
            toast.error("Invalid Lead Id")
          }
      })
      .catch((err) => {
        setLeadCreate((current) => ({
          ...current,
          reference_name: "",
          reference_number: ""
        }))
        toast.error('Invalid Lead Id');
      });
    // Example: fetch(`https://api.example.com/search?q=${query}`)
  }
  
  // Wrap the API call in the debounce function
  const debouncedFetch = debounce(fetchSearchResults, 1000);

  const onLeadIdChange = (event) => {
    debouncedFetch(event.target.value);
  }

  const resetReferenceData =() => {
    setLeadCreate((current) => ({
      ...current,
      reference_name: "",
      reference_number: "",
    }));
  }

  const handleFileChange = async (event) => {
    const { files, id } = event.target;
    const fileNameElement =
      id === "fileInput_assignLead"
        ? document.getElementById("fileName_assignLead")
        : document.getElementById("fileName");

    if (files.length > 0) {
      const fileName = files[0].name;
      const jsonArray = await convertCsvToJson(files[0]);
      setUploadedFileJson(jsonArray);
      fileNameElement.textContent = "Selected file: " + fileName;
    } else {
      fileNameElement.textContent = "";
    }
  };

  const handleSourceChange = (source_id) => {
    const filteredSources = ALL_OPTIONS["subsource_type"].filter(
      (obj) => obj.source_id === source_id
    );
    ALL_OPTIONS["sub_source"] = filteredSources.map((obj) => ({
      id: +obj.id,
      label: obj.sub_source_name,
      value: +obj.id,
    }));
    setSelectedValue('other')
    setCanRadioEdit(true);
    setLeadCreate((currentValue)=>({
      ...currentValue,
      sub_source:"",
      reference_type: "other",
      reference_name: "",
      reference_number: ""
    }))
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name === "source") handleSourceChange(data.id);
      if (field_name === "partner_id" && data.id === 10454) {
        ALL_OPTIONS["source"] = ALL_OPTIONS["source"]?.filter(obj => obj.id !== 1 && obj.id !== 2);
      }
      if (field_name === "partner_id" && data.id !== 10454) setSourceOptions(ALL_OPTIONS);
      setLeadCreate((currentValue) => ({
        ...currentValue,
        [field_name]: data.id,
        ...(field_name === "partner_id" ? { is_demo: data.isDemo || "0" } : {})
      }));
      if(field_name==='sub_source' && data.label == "Customer Referral") {
        resetReferenceData()
        setSelectedValue('other')
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_type: "other",
        }))
        setCanRadioEdit(true);
      }
      if(field_name==='sub_source' && data.label == "Internal Referral") {
        resetReferenceData()
        setSelectedValue('other')
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_type: "other",
        }))
        setCanRadioEdit(true);
      }
      if(field_name==='sub_source' && ["Direct", "Justdial", "Youtube","Google Paid", "Facebook", "Instagram", "Linkedin", "Website", "Twitter"].includes(data.label)) {
        resetReferenceData()
        setSelectedValue('no_reference')
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_type: "no_reference",
        }))
        setCanRadioEdit(false)
      }
      if(field_name==='sub_source' && data.label == "Partner Referral") {
        resetReferenceData()
        setSelectedValue('partner')
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_type: "partner",
        }))
        setCanRadioEdit('true')
      }
      if(field_name==='sub_source' && data.label == "Yoddha Referral") {
        resetReferenceData()
        setSelectedValue('other')
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_type: "other",
        }))
        setCanRadioEdit('true')
      }      
      
      if(field_name==='sub_source' && data.label == "Online property brokers") {
        resetReferenceData()
        setSelectedValue('no_reference')
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_type: "no_reference",
        }))
        setCanRadioEdit(false);
      }
      if(field_name==='sub_source' && data.label == "Society Access Control systems") {
        resetReferenceData()
        setSelectedValue('no_reference')
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_type: "no_reference",
        }))
        setCanRadioEdit(false)
      }
      if(field_name === 'yodhha_id'){
        setLeadCreate((currentValue)=>({
          ...currentValue,
          reference_name: data.name,
          reference_number: data.mobile
        }))
      }

    }
  };

  const validateLead = async (lead, schema) => {
    try {
      await schema.validate(lead);
      return {
        error: false,
        message: "",
      };
    } catch (error) {
      return {
        error: true,
        message: error.message,
      };
    }
  };

  const handleDownloadErrorFile = () => {
    const csvData = convertJsonToCsv(uploadedFileJson);
    downloadFile(csvData, "text/csv", "error_file.csv");
    setIsFileInvalid(false);
  };

  const handleFormatDownload = (event) => {
    const { id } = event.target;
    let jsonArray = [],
      fileName = "";
    if (id === "bulkLeadFormat") {
      jsonArray = BULK_LEAD_FORMAT;
      fileName = "bulk_lead_file_format.csv";
    }
    if (id === "leadAssingFormat") {
      jsonArray = LEAD_ASSIGN_FORMAT;
      fileName = "lead_assign_file_format.csv";
    }
    const csvData = convertJsonToCsv(jsonArray);
    downloadFile(csvData, "text/csv", fileName);
  };

  const handleAssignLeadSubmit = () => {
    if (uploadedFileJson.length === 0) {
      return toast.error('Please upload file');
    }

    let assignlead = [];
    for (let i = 0; i < uploadedFileJson.length; i++) {
      assignlead.push({
        lead_id: Number(uploadedFileJson[i].lead_id),
        user_id: Number(uploadedFileJson[i].user_id),
      });
    }
    let mutation = ASSIGN_LEAD_CSV,
      variables = {
        BulkAssignLeadInput: {
          assignlead,
        },
      };
    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.update_assign_lead || null;
        if (response && !response) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error('Got invalid value. Please check');
      });
  };

  const handleSubmit = async (event) => {
    if (Object.keys(leadCreate).length === 0 && uploadedFileJson.length === 0) {
      return toast.error("Either fill form or upload file");
    }

    if (uploadedFileJson.length > 0) {
      let errorCount = 0;
      const jsonClone = [],
        parsedJson = [];
      for (let i = 0; i < uploadedFileJson.length; i++) {
        const lead = uploadedFileJson[i];
        lead.fulfillment_type = lead.fulfillment_type
          ? lead.fulfillment_type
          : "ambak";
        const response = await validateLead(lead, leadSchema);
        if (response.error) {
          errorCount++;
          jsonClone.push({ ...lead, error: response.message });
        } else {
          parsedJson.push({
            ...leadSchema.cast(lead),
            loan_type: LOAN_TYPE_MAPPING[lead.loan_type.toLowerCase()],
            loan_sub_type:
              LOAN_SUB_TYPE_MAPPING[lead.loan_sub_type.toLowerCase()],
            loan_amount: lead.loan_amount.replaceAll(",", ""),
            source: SOURCE_MAPPING[lead.source],
            sub_source: SUB_SOURCE_MAPPING[lead.sub_source],
            assignee: +lead.assignee
          });
          jsonClone.push({ ...lead, error: "" });
        }
      }

      if (errorCount > 0) {
        toast.error("Invalid File");
        setUploadedFileJson(jsonClone);
        setIsFileInvalid(true);
      } else {
        setIsFileInvalid(false);
        const responseArry = [];
        const mutation = LEAD_CREATION_FORM;
        try {
          for (let i = 0; i < parsedJson.length; i++) {
            const lead = parsedJson[i];
            const variables = {
              partner_id: lead.partner_id,
              api_called_by: "web",
              user_id: userId,
              is_demo: lead?.is_demo? String(lead?.is_demo):"0",
              newLeadInput: {
                first_name: lead.first_name,
                last_name: lead.last_name,
                mobile: lead.mobile,
                loan_type: +lead.loan_type,
                loan_sub_type: lead.loan_sub_type,
                fulfillment_type: lead.fulfillment_type,
                loan_amount: lead.loan_amount,
                source: lead?.source || null,
                sub_source: lead?.sub_source || null,
                assignee: lead?.assignee || null
              },
            };

            const resp = await executeGraphQLMutation(
              mutation,
              variables,
              client
            );
            // if (resp && !resp?.data?.create_loan) {
            //   throw new Error("Internal Server error");
            // }
            responseArry.push(1);
          }

          if (parsedJson.length === responseArry.length) {
            toast.success("Success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error("Internal Server error");
          }
        } catch (error) {
          console.log(error.message);
          toast.error("Internal Server error");
        }
      }
    } else {
      const response = await validateLead(leadCreate, formLeadSchema);
      if(response.error){
        toast.error(response.message);
        return
      }
      let mutation = LEAD_CREATION_FORM;
      let variables = {
        partner_id: [37,38,39,40,41,42,43,44,45,46,49].includes(leadCreate.sub_source) ? E2E_SALES : +leadCreate.partner_id,
        api_called_by: "web",
        user_id: userId,
        is_demo: leadCreate?.is_demo ? String(leadCreate.is_demo):"0",
        newLeadInput: {
          first_name: leadCreate.first_name,
          last_name: leadCreate.last_name,
          mobile: leadCreate.mobile,
          loan_type: +leadCreate.loan_type,
          loan_sub_type: leadCreate.loan_sub_type,
          fulfillment_type: leadCreate.fulfillment_type,
          loan_amount: leadCreate.loan_amount,
          source: leadCreate.source,
          sub_source: leadCreate.sub_source,
          reference_name: leadCreate.reference_name,
          reference_phone: leadCreate.reference_number,
          bu_tagging: leadCreate?.source == 9 ? leadCreate.bu_list : 0,
          reference_type: selectedValue
        },
      };

      // Call executeGraphQLMutation function to send the mutation to the server
      executeGraphQLMutation(mutation, variables, client)
        .then((resp) => {
          if (resp && !resp?.data?.create_loan) {
            throw new Error("Something went wrong");
          }
          if( resp?.data?.create_loan?.is_duplicate == '1'){
            toast.error("Duplicate lead with this number");
          }else{
            toast.success("Success");
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(` 🔻 ERROR : ${err.message}`);
          toast.error("Got Invalid value. Please Check");
        });
    }
  };

  return (
    <>
      <div>
        <div>
          <h2>
            Create and Assign Leads
          </h2>
         
       
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="createtabs"
          >
            {
              leadAccess === 1 ?
                <Tab
                  eventKey="create leads"
                  title={
                    <div
                    
                      className={`${key === "create leads" ? "active-leads" : ""
                        }`}
                    >
                      Create Leads
                    </div>
                  }
                >
                {createLeadBoth===1 ? 
                    <> 
                    <div className="referencedetails" style={{margin:"20px 0px 5px 0px"}}>Fulfillment Type</div>
                      <div className='followupradio' style={{ marginBottom: 2 }}>
                        <div className='followupcall'>
                          <input 
                            type="radio" 
                            id="self" 
                            name="fulfillment" 
                            value="self"
                            onChange={FulfillmentTypeChange}
                            checked={Fulfillment === 'self'}
                          />
                          <label htmlFor="self">No, i will do it myself</label>
                        </div>
                        <div className='followupcall'>
                          <input 
                            type="radio" 
                            id="ambak"
                            name="fulfillment" 
                            value="ambak"
                            checked={Fulfillment === 'ambak'}
                            onChange={FulfillmentTypeChange}
                          />
                          <label htmlFor="ambak">Yes, refer to Ambak</label>
                        </div>
                      </div>
                      </>: (createLeadE2E===1) ? <div className="ambakfulfilment">You can only make Ambak fulfilment leads on Sangam</div>:<></>
                  }
                  <div className="form-tab-right-panel">
                    <Formsy
                      className="lead-form-filed"
                      autoComplete="off"
                      onValid={() => null}
                      onInvalid={() => null}
                    >
                      <div className="row">
                        {LEAD_FORM.map((field, index) =>
                          [
                            "text",
                            "pattern-format",
                            "number",
                            "number-format",
                          ].includes(field.type) ? (
                            <fieldset className="form-filed col-md-4" key={index}>
                              <FormsyInputField
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                value={(leadCreate && leadCreate[field.name]) || ""}
                                placeholder=" "
                                maxLength={field.maxLength}
                                label={field.label}
                                required={field.required}
                                thousandsGroupStyle="lakh"
                                thousandSeparator={","}
                                decimalSeparator={"."}
                                onChange={
                                  ["pattern-format", "number-format"].includes(field.type)
                                    ? (data) => handlePatternFormatChange(data, field.name)
                                    : handleInputChange
                                }
                              />
                            </fieldset>
                          ) : field.type === "dropdown" ? (
                            <>
                            {(["",37,38,39,40,41,42,43,44,45,46,49].includes(leadCreate.sub_source) && field.name=="partner_id")  ? <></> :
                              <fieldset
                              className="single-select col-md-4"
                              key={index}
                            >
                            <FormsySelect
                                name={field.name}
                                id={field.id}
                                inputProps={{
                                  options:
                                    ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                                  placeholder: field.label,
                                  className: "react-select",
                                  classNamePrefix: "react-select",
                                  value:
                                    ALL_OPTIONS &&
                                    ALL_OPTIONS[field.optionsKey] &&
                                    ALL_OPTIONS[field.optionsKey].filter(
                                      ({ value }) => value == leadCreate[field.name]
                                    ),
                                  }}
                                required={field.required}
                                value={leadCreate && leadCreate[field.name]}
                                onChange={(data) =>
                                  handleSelectChange(data, field.name)
                                }
                              />
                              </fieldset>
                              }
                            </>
                          ) : null
                        )}
                        {leadCreate.source == 9 &&
                        <fieldset className="single-select col-md-4">
                        <FormsySelect
                                name={"bu list"}
                                id={"bu_list"}
                                inputProps={{
                                  options:
                                    ALL_OPTIONS && ALL_OPTIONS['bu_list'],
                                  placeholder: "BU",
                                  className: "react-select",
                                  classNamePrefix: "react-select",
                                }}
                                required={true}
                                value={leadCreate && leadCreate["bu_list"]}
                                onChange={(data) =>
                                  handleSelectChange(data, "bu_list")
                                }
                        />
                        </fieldset>
                      }
                      </div>
                    </Formsy>
                  </div>
                  <div className="referencedetails">Reference Details</div>

                  <div className='followupradio' style={{ marginBottom: 10 }}>
                    <div className='followupcall'>
                      <input 
                        type="radio" 
                        id="partner" 
                        name="reference" 
                        value="partner"
                        onChange={handleRadioChange}
                        checked={selectedValue === 'partner'}
                      />
                      <label htmlFor="partner">Partner</label>
                    </div>
                    <div className='followupcall'>
                      <input 
                        type="radio" 
                        id="no_reference"
                        name="reference" 
                        value="no_reference"
                        checked={selectedValue === 'no_reference'}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="no_reference">No Reference</label>
                    </div>
                    <div className='followupcall'>
                      <input 
                        type="radio" 
                        id="other"
                        name="reference" 
                        value="other"
                        checked={selectedValue === 'other'}
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="other">Other</label>
                    </div>
                  </div>
           
                  {selectedValue === "other" && (
                  <div className="form-tab-right-panel">
                    <Formsy
                      className="lead-form-filed"
                      autoComplete="off"
                      onValid={() => null}
                      onInvalid={() => null}
                    >
                      <div className="row">
                      {leadCreate.sub_source == 37 &&
                      <fieldset className="single-select col-md-4">
                        <FormsyInputField
                        id={"lead_id"}
                        name={"lead_id"}
                        type={"text"}
                        placeholder=""
                        label={"Lead Id"}
                        required={false}
                        thousandsGroupStyle="lakh"
                        thousandSeparator={","}
                        decimalSeparator={"."}
                        onChange={onLeadIdChange}
                      />
                      </fieldset>
                      }
                      {(leadCreate.source == 11 && leadCreate.sub_source == 49) && 
                      <fieldset className="single-select col-md-4">
                      <FormsySelect
                                name='yoddha_id'
                                id="yoddha_id"
                                inputProps={{
                                  options:
                                    ALL_OPTIONS && ALL_OPTIONS['yodhha_id'],
                                  placeholder: "Yoddha Id",
                                  className: "react-select",
                                  classNamePrefix: "react-select",
                                }}
                                required={false}
                                value={leadCreate && leadCreate["yoddha_id"]}
                                onChange={(data) =>
                                  handleSelectChange(data, "yodhha_id")
                                }
                        />
                      </fieldset>
                      }

                        {REFERENCE_FORM.map((field, index) =>
                          [
                            "text",
                            "pattern-format",
                            "number",
                            "number-format",
                          ].includes(field.type) ? (
                            <fieldset className="form-filed col-md-4" key={index}>
                              <FormsyInputField
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                value={(leadCreate && leadCreate[field.name]) || ""}
                                placeholder=" "
                                maxLength={field.maxLength}
                                label={field.label}
                                required={field.required}
                                thousandsGroupStyle="lakh"
                                thousandSeparator={","}
                                decimalSeparator={"."}
                                onChange={
                                  ["pattern-format", "number-format"].includes(field.type)
                                    ? (data) => handlePatternFormatChange(data, field.name)
                                    : handleInputChange
                                }
                              />
                            </fieldset>
                          ): null
                        )}
                      </div>
                    </Formsy>
                  </div>
                  )}
                 {
                    leadUploadAccess === 1 ?
                      <>
                        <div className="orcsvfile">
                          <div className="orcsvline"></div>
                          <div style={{ margin: "0 10px" }}>OR</div>
                          <div className="orcsvline"></div>
                        </div>
                        <div className="csvfileupload">
                          <input type="file" id="fileInput" onChange={handleFileChange} />
                          <label htmlFor="fileInput" className="file-label">
                            <span>+</span>
                            <p>Drag your CSV file here</p>
                          </label>
                          <p id="fileName"></p>
                        </div>
                      </> : null
                  }
                  {isFileInvalid && (
                    <div
                      className="downloadformat"
                      onClick={handleDownloadErrorFile}
                    >
                      Download error file
                    </div>
                  )}
                  <div className="btn-save-remarks">
                    <div className="submitbtnflex">
                      <button
                        type="submit"
                        className="btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      <div
                        className="downloadformat"
                        id="bulkLeadFormat"
                        onClick={handleFormatDownload}
                      >
                        Download format
                      </div>
                    </div>
                  </div>
                </Tab> : null
            }
            {
              leadAssignAccess ===1  ?
                <Tab
                  eventKey="assign leads"
                  title={
                    <div
                      className={`${key === "assign leads" ? "" : ""
                        }`}
                    >
                      Assign Leads
                    </div>
                  }
                >
                  <div className="csvfileupload">
                    <input
                      type="file"
                      id="fileInput_assignLead"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="fileInput_assignLead" className="file-label">
                      <span>+</span>
                      <p>Drag your CSV file here</p>
                    </label>
                    <p id="fileName_assignLead"></p>
                  </div>

                  <div className="btn-save-remarks">
                    <div className="submitbtnflex">
                      <button
                        type="submit"
                        onClick={handleAssignLeadSubmit}
                        className="btn-primary"
                      >
                        Submit
                      </button>
                      <div
                        className="downloadformat"
                        id="leadAssingFormat"
                        onClick={handleFormatDownload}
                      >
                        Download format
                      </div>
                    </div>
                  </div>
                </Tab> : null
            }
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default LeadsCreateForm;