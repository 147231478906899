import React, { useEffect, useState,useRef } from 'react';
import Modal from '../elements/Modal'
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getStatusList,getLeadRemarks  } from '../../store/action/allAction'; 
import { useApolloClient } from '@apollo/client';
import { executeGraphQLMutation,executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { UPDATE_LEAD_STATUS,LEAD_DETAIL_QUERY, STATUS_REJECTION_QUERY, BUCKET_STATUS_REJECTION_QUERY } from '../../services/leads.gql';
import LeadStatusService from "../../services/leadStatus";
import { toast } from 'react-toastify';
import MultiSelect from '../elements/MultiSelect';


const LostModal = ({MarkAsLost,hideModalMarkAsLost,rejection_type,statusId,singleTranchId, bucketId}) => {

    const radioRef = useRef(null);
    const dispatch = useDispatch();
    const client=useApolloClient();
    const [resasonList, setReasonList] = useState([]);

    const [inputField, setField] = useState({
        reason_id: [],
        comment: '',
        bank_id: '',
        rejection_type:(rejection_type =='bank_reject') ? "bank_reject" : (rejection_type =='ops_reject') ? 'ops_reject' : 'lost'
    })
    useEffect(()=>{
        setField({
            ...inputField,
            rejection_type:(rejection_type =='bank_reject') ? "bank_reject" : (rejection_type =='ops_reject') ? 'ops_reject' : 'lost'
        })
    },[rejection_type,MarkAsLost]) 
    const [errField, setErrField] = useState({
        reason_id: '',
        comment: '',
        bank_id: '',
        rejection_type:''
    })
    const { masterdata,leadDetail,userInfo } = useSelector(({ masterdata,lead,user }) => ({
        masterdata,
        leadDetail: lead.leadDetail, 
        userInfo: user.user_information,
    })); 
    let userDetail = userInfo && JSON.parse(userInfo)
    let user_id = userDetail && userDetail.id || 0;
    useEffect(()=>{  
        getLostReasonList();
        if(!masterdata.data || !masterdata.data.status_list){
            dispatch(getStatusList(client))
        }  
        
      },[])
    const handleChangeOption = (e,key) => { 
        setErrField({...errField,[key]:''})
        setField((prev) => ({
            ...prev,
            [key]: e.value
        }))
    }

    const handleChange = async (n, v, isMulti, opt, optl) => {
        //console.log("vvvvvvvvvvvvvvvvv",v);
        switch (n) {          
          case 'reason_id':
            let reason_ids = [];
            reason_ids = v.map(obj => { return obj.value });
            setField((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
          //  console.log("inputField", inputField);
            break;
        }    

    }

    const radioHandle = (e,key) => {   
        setField((prev) => ({
            ...prev,
            [key]: e.target.value 
        }))
    }
    const validateForm = ()=>{
        let errOb = {}
        if(inputField.bank_id =='' && inputField.rejection_type && inputField.rejection_type =='bank_hard_reject'){
            errOb.bank_id = 'Please select bank list'
        }
        if(inputField.rejection_type =='' && rejection_type =='bank_reject'){
            errOb.rejection_type = 'Please select this field'
        }
        if(inputField.reason_id ==''){
            errOb.reason_id = 'Please select reason'
        }
        if(inputField.comment ==''){
            errOb.comment = 'Please enter comment'
        } 
        setErrField(errOb)
        return Object.keys(errOb).length ? true : false
    }
    const submitMarkAsLost = () => { 
       if(!validateForm()) {  
            let passOb =  {
                update_type: "lost",
                status_id: (rejection_type =='lost')? [LeadStatusService.LEAD_STATUS_MASTER.LOST]:[+statusId],
                lead_id: leadDetail.id,
                user_id: +user_id,
                reject_reason_id: inputField.reason_id,
                rejection_type : inputField.rejection_type !='' ? inputField.rejection_type:rejection_type ,
                lost_form: {
                    lost_remark: inputField.comment
                }
            } 
            if(inputField.rejection_type && inputField.rejection_type =='bank_hard_reject'){
                passOb.reject_bank_id = inputField.bank_id;
                passOb.status_id = [5]
            }
            if(rejection_type && rejection_type == 'ops_reject' && statusId === LeadStatusService.LEAD_STATUS_MASTER.DISBURSED){
                passOb.tranch_id = singleTranchId
            }
            let mutation = UPDATE_LEAD_STATUS,
                variables = {
                    api_called_by: 'web',
                    UpdateLeadStatus:passOb
                }; 
            executeGraphQLMutation(mutation, variables, client)
                .then((resp) => { 
                    let response = resp?.data?.update_lead_status || null; 
                    if (response) {
                        toast.success(response.message) 
                        getLeadDetail(leadDetail.id);
                    }
                    else {
                        getLeadDetail(leadDetail.id);
                        toast.error("Error");
                    }
                })
                .catch((err) => {
                    getLeadDetail(leadDetail.id);
                    toast.error("Error")
                })
            closeModal();
        }
    }  
    const getLeadDetail =(lead_id) => { 
        if(!lead_id) lead_id = 0;
		executeGraphQLQuery(LEAD_DETAIL_QUERY(lead_id),client).then((response) => {
			if (response && response.data) {
				let data = response.data.get_lead_detail[0];
				if (data) {
                    let payload = data
					dispatch({ type: "GET_LEAD_DETAIL", payload }); 
                    dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
				}
                else{
                    let payload = {}
					dispatch({ type: "GET_LEAD_DETAIL", payload }); 
                    toast.error("Lead not found")
                }   
			}
            else{
                if(response?.errors?.length)
                toast.error(response?.errors?.[0].message,{
                    toastId: 'timeout',
                });  
            }
            
        })
        .catch((error) => { 
            toast.error(error.toString().replace('ApolloError:',''));
        });
    }
    const closeModal = () => {
        if(inputField.rejection_type !='ops_reject' && radioRef?.current){ 
            radioRef.current.click()
        }
        let ob = { reason_id: '', comment: '', bank_id: '', rejection_type:''}
        setField(ob) 
        setErrField(ob)
        document.body.classList.remove("overflow-hidden");
        hideModalMarkAsLost();
    };

    const getLostReasonList = () => {        
        executeGraphQLQuery(BUCKET_STATUS_REJECTION_QUERY, client).then((result) => {
            setReasonList(result?.data?.get_reason_bucket_wise || []);
        }).catch((errors) => {
            toast.error("Somethig went wrong ,please try again")
        });

    }       

    let rejectionKey = (inputField.rejection_type && ['bank_hard_reject','bank_soft_reject'].includes(inputField.rejection_type) ) ?  'bank_soft_reject': inputField.rejection_type;  
    rejectionKey = (rejection_type === 'ops_reject') ? rejection_type : rejectionKey ? rejectionKey : rejection_type; 
    let reasonOption = resasonList?.filter(ob=>ob.bucket_id == bucketId && ob.rejection_type == rejectionKey).map((item)=>{
        return {
            value:item.id,
            label:item?.rejectreasonlang?.name
        }
    })
     
    let bankList = (masterdata?.data?.bank_list) ? masterdata.data.bank_list :[]
 
    const REJECTION_OPTION = [
        {label:'Yes',value:'bank_hard_reject'},
        {label:'No',value:'bank_soft_reject'}
    ]  
    /*
    console.log("LostModal >> leadDetail => ", leadDetail)
    console.log("LostModal >> REJECTION_OPTION => ", REJECTION_OPTION)
    console.log("LostModal >> inputField => ", inputField)
    console.log("LostModal >> rejection_type => ", rejection_type)
    console.log("LostModal >> resasonList => ", resasonList)
    console.log("LostModal >> rejectionKey => ", rejectionKey)
    // console.log("LostModal >> tempList => ", tempList)
    console.log("LostModal >> reasonOption => ", reasonOption)
    console.log("LostModal >> inputField.rejection_type => ", inputField.rejection_type)
    */

    useEffect(() => {
      if (leadDetail?.status_id == 4) {
        setField((prev) => ({ ...prev, rejection_type: "bank_reject" }));
      }
    }, [leadDetail?.status_id]);

    return (
        <>
        <div className="view-timeline-popup">
        <Modal show={MarkAsLost} handleClose={closeModal} removeHeight={true} >
            <div className="modal-header">
                <h2>{ (rejection_type =='ops_reject') ? 'Verification Failed': 'Close Lead'} </h2>
            </div>
            <div className="modal-body" >
                {
                    rejection_type !='ops_reject' ? 
                    <div className='loanfulfillment'>

                        {
                            leadDetail.status_id !=4 &&
                        <div className='loaninput'>
                            <input type="radio" required ref={radioRef}   name="lost_type" value="lost" onChange={(e)=>radioHandle(e,'rejection_type')} checked={(inputField.rejection_type=='lost')? true:false} />
                            <label htmlFor='loanproperty'>Mark as Lost</label>
                        </div>
                        }
                        {
                            [LeadStatusService.LEAD_STATUS_MASTER.LOGIN, LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED, LeadStatusService.LEAD_STATUS_MASTER.DISBURSED].includes(leadDetail?.status_id) ? 
                            <div className='loaninput'>
                                <input type="radio" required name="lost_type" value="bank_reject"  onChange={(e)=>radioHandle(e,'rejection_type')} checked={(inputField.rejection_type=='bank_reject' || inputField.rejection_type=="bank_hard_reject" || inputField.rejection_type=="bank_soft_reject")? true:false}/>
                                <label htmlFor='homeloan' className='col-md-12'>Bank Reject</label>
                            </div>  : null
                        } 
                    </div>
                    : null 
                } 

                {
                   ['bank_reject','bank_hard_reject','bank_soft_reject'].includes(inputField.rejection_type) ? 
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                options={REJECTION_OPTION}
                                placeholder="Do you want to Login With Other Bank"
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(e)=>handleChangeOption(e,'rejection_type')}
                                value={REJECTION_OPTION.filter(ob=>inputField.rejection_type==ob.value)}
                            />
                            {   errField.rejection_type ?
                                <div className="error-msg-txt">{errField.rejection_type}</div> : null
                            }  
                        </div>
                    </fieldset> : null
                } 
                {
                    inputField.rejection_type =='bank_hard_reject' ? 
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                options={bankList}
                                placeholder="Bank List"
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(e)=>handleChangeOption(e,'bank_id')}
                                value={bankList.filter(ob=>inputField.bank_id==ob.value)}
                            />
                            {   errField.bank_id ?
                                <div className="error-msg-txt">Please select bank list</div> : null
                            }  
                        </div>
                    </fieldset> : null
                } 
                {
                   ['ops_reject'].includes(rejection_type) ? 
                   <fieldset className="single-select">
                   <div className="material">
                       <MultiSelect
                           options={reasonOption}
                           placeholder="List Of Reasons"                           
                           name={"reason_id"}
                           optionLabel="label"
                           optionValue="value"  
                           onChange={handleChange}
                           values={inputField.reason_id}
                           isMulti
                       />
                       
                       {   errField.reason_id ?
                           <div className="error-msg-txt">{errField.reason_id}</div> : null
                       }
                   </div>
               </fieldset>   :
               
                <fieldset className="single-select">
                <div className="material">
                    <Select
                        options={reasonOption}
                        placeholder="List Of Reason"
                        className="react-select"
                        classNamePrefix="react-select"
                        onChange={(e)=>handleChangeOption(e,'reason_id')}
                        value={reasonOption.filter(ob=>inputField.reason_id==ob.value)}
                    />
                    {   errField.reason_id ?
                        <div className="error-msg-txt">{errField.reason_id}</div> : null
                    }
                </div>
            </fieldset>            
             }
                <fieldset className="form-filed" >
                    <div className="material">
                        <textarea
                            placeholder=" "
                            className="form-input" rows="3"
                            value={inputField.comment}
                            onChange={(e) => setField({ ...inputField, comment: e.target.value })}>
                        </textarea>
                        <label
                            data-label="Comment"
                            className="form-label"
                        >
                        </label>
                        {   errField.comment ?
                            <div className="error-msg-txt">{errField.comment}</div> : null
                        } 
                    </div>
                </fieldset>
                <div style={{display:"flex",justifyContent:"center"}}>
                <button className="btn-primary" style={{marginTop:40}} onClick={submitMarkAsLost}>Save</button>
                </div>
            </div>
        </Modal>
        </div>
        </>
    )
}

export default LostModal;
