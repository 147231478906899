import { useApolloClient } from "@apollo/client";
import Q from "q";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/executeGraphQLQuery";
import { WS_URL } from "../../config/constants";
import GeneralService from "../../services/generalService";
import { CHAT_SAVE } from "../../services/leadCreate.gql";
import {
  CHAT_LIST,
  CHAT_TEMPLATE_LIST,
  UPDATE_CHAT_READ_STATUS,
} from "../../services/leads.gql";
import dateFormat from "dateformat";
import docImage from "../../webroot/images/saveicon.svg";
import pluscrcle from "../../webroot/images/pluscircle.svg";
import sendarrow from "../../webroot/images/sendrightarrow.svg";
import Select from "react-select";

const WhatsAppTemplate = () => {
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null); // Reference for chat container
  const [initiate, setInitiate] = useState(false);

  let refresh = 0;
  const [inputField, setField] = useState({
    chat_type: "TEXT",
    mode_type: 1,
    message: "",
    files: "",
  });
  const [list, setList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const client = useApolloClient();
  const textareaRef = useRef(null);
  const intervalRef = useRef(null);

  const { leadDetail, userInfo } = useSelector(({ lead, user }) => {
    return {
      leadDetail: lead.leadDetail,
      userInfo: user.user_information ? JSON.parse(user.user_information) : {},
    };
  });

  const ALL_OPTIONS = {
    composeOptions: list.map((item) => ({
      value: item.template,
      label: item.name,
    })),
  };

  useEffect(() => {
    getTemplateList(
      leadDetail?.id,
      userInfo?.name,
      "",
      userInfo?.id,
      "true",
      "false"
    );
    getMessageList(leadDetail?.customer?.mobile);
  }, [leadDetail?.id]);

  useEffect(() => {
    scrollToBottom();
  }, [chatList]);

  const getTemplateList = (
    lead_id,
    username,
    tempelate_type,
    user_id,
    is_whatsapp,
    is_email
  ) => {
    executeGraphQLQuery(
      CHAT_TEMPLATE_LIST(
        lead_id,
        username,
        tempelate_type,
        user_id,
        is_whatsapp,
        is_email
      ),
      client
    )
      .then((result) => {
        setList(result?.data?.chat_template_list);
      })
      .catch((error) => { });
  };

  const getMessageList = (mobile) => {
    executeGraphQLQuery(
      CHAT_LIST(mobile, leadDetail?.id, userInfo?.id, "Sangam"),
      client
    )
      .then((result) => {
        let replyResult = result?.data?.chat_list.filter(
          (ob) => ob.mode_type == 2
        );

        let replyResultForInitiateChat = result?.data?.chat_list.filter(
          (ob) => ob.mode_type == 1
        );
        function isMoreThan24Hours(date) {
          const currentTime = new Date();
          const timeDifference = (currentTime - date) / (1000 * 60 * 60);
          return timeDifference >= 24;
        }
        let lastChatTime;
        if (replyResult?.length) {
          lastChatTime = new Date(replyResult.at(-1)?.created_date);
        }
        if (!replyResult.length || (lastChatTime && isMoreThan24Hours(lastChatTime))) {
          setInitiate(true);
        } else {
          setInitiate(false);
        }
        let curCount = result?.data?.chat_list?.length;
        if (!(refresh == curCount)) {
          setTimeout(() => scrollToBottom(), 200);
        }
        refresh = curCount;
        setChatList(result?.data?.chat_list);
      })
      .catch((error) => { });
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setField({
      ...inputField,
      message: value,
    });
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = '28px';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, 0);
  };

  const handleTemplateChange = (message) => {
    setField((prev) => ({ ...prev, message }));
    // add a slight delay
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = '28px';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, 0);
  }

  const sendMessage = (reqParam = null, initiate = false) => {
    if (inputField.message.trim() !== "" || reqParam || initiate) {
      let chatField = {
        ...inputField,
        lead_id: leadDetail?.id,
        user_id: userInfo?.id,
        customer_id: leadDetail?.customer?.customer_id,
        mobile: leadDetail?.customer?.mobile,
        is_fbm:1
      };
      if (initiate) {
        chatField.initiate = 1;
      }
      if (reqParam && reqParam?.chat_type) {
        chatField = { ...chatField, ...reqParam };
      }
      let variables = {
        api_called_by: "partner",
        chatInput: chatField,
        call_by: "whatspp_page",
        tempelate_name: "sangam",
      };
      let mutation = CHAT_SAVE;
      executeGraphQLMutation(mutation, variables, client)
      .then((result) => {
        setTimeout(() => {
          getMessageList(leadDetail?.customer?.mobile);
        }, 1000);
      })
      .catch((error) => { });
      setField({
        ...inputField,
        message: "",
      });
      if(!initiate){
        textareaRef.current.style.height = '28px';
  
      }    }
  };

  const handleChangeImage = (event) => {
    let files = Array.from(event.target.files),
      awsRes = [],
      leadDocArr = [];
    if (files && files.length) {
      var formData = new FormData();
      formData.append("dealer_id", leadDetail.id);
      formData.append("upload_type", "customer_doc");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        GeneralService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
      Q.allSettled(awsRes).then((uploadOutput) => {
        uploadOutput.forEach((res, index) => {
          let response = res.value || [];
          if (res.state === "fulfilled") {
            let docPath = response?.data?.data?.length
              ? response?.data?.data?.[0]?.file_url
              : "";
            let chat_type = "DOCUMENT";
            if (
              docPath.endsWith(".jpg") ||
              docPath.endsWith(".jpeg") ||
              docPath.endsWith(".png") ||
              docPath.endsWith(".gif")
            ) {
              chat_type = "IMAGE";
            }
            let params = {
              files: docPath,
              chat_type: chat_type,
            };
            sendMessage(params);
          }
        });
      });
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // WebSocket Code Initialize
  useEffect(() => {
    if (leadDetail?.id) {
      const identifierType = "activeMessage";
      const connectionId = Math.floor(Date.now() / 1000);
      const socketData = {
        transports: ["websocket"],
        query: {
          type: identifierType,
          identifier: leadDetail?.id,
          connectionId,
        },
        reconnectionAttempts: 10,
        reconnectionDelay: 60000,
        randomizationFactor: 5,
      };
      const newSocket = io(`${WS_URL}/socket`, socketData);

      newSocket.on(
        `${identifierType}-${leadDetail?.id}_${connectionId}`,
        (msg) => {
          if (msg) {
            const msgFrmt = {
              created_date: msg.message.created_date,
              message: msg.message.message,
              chat_type: msg.message.chat_type,
              files: msg?.message?.files,
              mode_type: msg.message.mode_type
            };
            if (msg.message.chat_status) {
              setChatList((prevMessages) => {
                const messageIndex = prevMessages.findIndex(m => m.chat_id === msg.message.chat_id);
                if (messageIndex === -1) return prevMessages;
              
                return prevMessages.map((message, index) => {
                  if (index !== messageIndex) return message;
                  
                  const updates = {
                    chat_status: msg.message.chat_status
                  };
              
                  // Add relevant timestamp based on status
                  switch (msg.message.chat_status) {
                    case 1: updates.send_time = new Date(); break;
                    case 2: updates.delivered_time = new Date(); break;
                    case 3: updates.read_time = new Date(); break;
                    case 9: updates.failed_time = new Date(); break;
                  }
              
                  return { ...message, ...updates };
                });
              });
            } else{
              setChatList((prevMessages) => [...prevMessages, msgFrmt]);
              scrollToBottom();
              setInitiate(false);

            }
            updateReadStatus(msg.identifier.msgId, msg.identifier.userId);
          }
        }
      );

      return () => {
        newSocket.disconnect();
      };
    }
  }, [leadDetail?.id]);
  // WebSocket Code Ends

  const updateReadStatus = (msgId, userId) => {
    executeGraphQLMutation(
      UPDATE_CHAT_READ_STATUS,
      { chatId: msgId, userId },
      client
    )
      // .then(result => {
      //   console.log("\n\n updateReadStatus >> executeGraphQLQuery => result -> ", result)
      // })
      .catch((error) => {
        console.error(
          "\n\n updateReadStatus >> executeGraphQLQuery => error -> ",
          error
        );
      });
  };

  return (
    <>
      <div className="wahtsapp_main_container">
        <div className="Whatspp_header">
          <div className="whatsappInbox">
            WhatsApp Chat
          </div>

          <div className="select_tempWhatsapp">
            <Select
              name="compose_option"
              id="compose_option"
              styles={{
                control: (base) => ({
                  ...base,
                  borderRadius: "20px" 
                }),
              }}
              options={ALL_OPTIONS["composeOptions"]}
              placeholder="Select Templates"
              className="react-select"
              classNamePrefix="react-select"
              required={false}
              onChange={(message) => {
                handleTemplateChange(message.value);
              }}
            />
          </div>
        </div>

        <div ref={chatContainerRef} className="mess_main_cont">
          <div className="timestamp">
            {chatList && chatList.length ? dateFormat(chatList[0].created_date, "dd mmm yyyy") : null}
          </div>

          {chatList && chatList.length
            ? chatList.map((item, key) => {
              let messageElement = <div>{item.message}</div>;
              if (item.chat_type == "IMAGE") {
                messageElement = (
                  <div>
                    {item.files && (
                      <>
                        <a
                          href={item.files}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={item.files}
                            width="100px"
                            height="100px"
                            alt="chat-img"
                          />
                        </a>
                        <br />
                      </>
                    )}
                    <span>{item.message}</span>
                  </div>
                );
              } else if (item.chat_type == "DOCUMENT" && item.files) {
                messageElement = (
                  <div>
                    {item.files && (
                      <>
                        <a
                          href={item.files}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={docImage}
                            width="100px"
                            height="50px"
                            alt="chat-doc"
                          />
                        </a>
                      </>
                    )}
                  </div>
                );
              }
              return (
                <div
                  className={`${item.mode_type == "2" ? "recievd_messs" : "send_messs"
                    }`}
                >
                  {messageElement}
                  <div className="tickSign">
                    <div className="dateTimewhatsapp">
                      {dateFormat(
                        item.chat_status === 1 ? (item.send_time||item.created_date):
                          item.chat_status === 2 ? item.delivered_time :
                          item.chat_status === 3 ? item.read_time :
                          item.chat_status === 9 ? item.failed_time :
                            item.created_date,
                        "dd mmm yyyy, h:MM TT"
                      )}
                    </div>

                    {/* Show appropriate tick marks based on chat_status */}
                    {item.chat_status === 1 && (
                      <div><i className="ic-check"></i></div>
                    )}

                    {item.chat_status === 2 && (
                      <div>
                        <i className="ic-check" style={{ marginRight: "-6px" }}></i>
                        <i className="ic-check"></i>
                      </div>
                    )}

                    {item.chat_status === 3 && (
                      <div>
                        <i className="ic-check" style={{ marginRight: "-6px", color: "blue" }}></i>
                        <i className="ic-check" style={{ color: "blue" }}></i>
                      </div>
                    )}

                    {item.chat_status === 9 && (
                      <div className="failedicon" title="Message Not Sent">
                        <i className="ic-peson-blacklist" style={{ color: "red" }}></i>
                      </div>
                    )}
                  </div>
                    </div>
              );
            })
            : null}
        </div>

        {initiate ? (
          <div className="foot_cont">
            <center>
              <button
                className="btn-primary"
                type="button"
                onClick={() => sendMessage(null, 1)}
              >
                Initiate Chat
              </button>
            </center>
          </div>
        ) : (
          <div className="foot_cont">
            <div className="send_input_cont">
              <div className="send_input">
                <textarea
                  ref={textareaRef}
                  onChange={handleChange}
                  value={inputField.message || ""}
                ></textarea>
              </div>

              <div className="send_arrow" onClick={() => sendMessage()}>
                <img src={sendarrow} alt="icon" width={20} height={20} />
              </div>
            </div>
            <div className="plus_cont">
              <img
                src={pluscrcle}
                alt="icon"
                width={46}
                height={46}
                onClick={handleIconClick}
              />
              <input
                type="file"
                className="input_file"
                onChange={handleChangeImage}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WhatsAppTemplate;
