import { gql } from "@apollo/client";

export const BANK_ONBOARDING_PAYIN_LIST = (page_number) =>  gql`
  query payin_config_list($filter_params: ConfigPayinFiltersArgs!) {
    payin_config_list(filter_params: $filter_params, page_no: ${page_number}) {
      payinlist {
        financer_id
        bank_name
        is_on_payin
        is_partnership
        logo
        total_payout
      }
      pagination {
      totalpage
      totalrecords
      nextpage
      prevpage
      __typename
    }
    total_count
    productData {
        product_type
        bank_id
        dsa_code
    }
    }
  }
`;


export const UPDATE_BANK_ONBOARDING_PAYIN = gql`
  mutation save_on_payin($addpayinconfigargs: AddPayinConfigArgs!) {
    save_on_payin(addpayinconfigargs: $addpayinconfigargs)
  }
`;

export const MIS_LIST = (page_number) => gql`
  query mis_file_list($filter_params: LeadMisFiltersArgs!) {
    mis_file_list(filter_params: $filter_params, page_no: ${page_number}) {
      list {
        bank_id
        created_at
        created_by
        csv_file
        id
        is_processed
        process_at
        processed_file
        update_for
        lead_id_count
        tranche_id_count
        lead_id_update_count
        tranche_id_update_count
      }
      pagination {
        page_no
        prevpage
        totalpage
        totalrecords
        nextpage
        __typename
      }
      __typename
    }
  }
`;

export const INCENTIVE_LIST = gql`
  query incentive_cashback_list($page_no: Int!) {
    incentive_cashback_list(page_no: $page_no) {
      list {
        id,
        created_at,
        lead_id_count,
        tranche_id_count,
        lead_id_update_count,
        tranche_id_update_count,
        csv_file,
        processed_file,
        created_by
        }
      pagination {
        page_no
        prevpage
        totalpage
        totalrecords
        nextpage
        __typename
      }
      total_count
      __typename
    }
  }
`;


export const UPLOAD_MIS_LIST = gql`
  mutation mis_upload($uploadPaymentArgs: UploadMisArgs!) {
    mis_upload(uploadPaymentArgs: $uploadPaymentArgs){
      totalRows
      mis_id
      data{
        id
        lead_id
        tranche_id
        bank_name
        bank_id
        partner_id
        payin_per
        payin_amount
        payin_type
        payin_status
        payin_bank_id
        is_duplicate
        disbursed_amount
        sanction_amount
        disbursal_date
        sanction_date
        fulfilment_type
        product_type
        sub_product_type
        Invoice_id
        Invoice_date
        utr
        payment_date
        pf_amount
        pf_per
        pf_type
        login_fees
        payin_tds_per
        payin_tds_amount
        tds_amount_via_gst
        tds_per_via_gst
        tds_via_gst_applicable
        is_valid
        reason
      }
    }
  }
`;

export const UPLOAD_INCENTIVE_CASHBACK_LIST = gql`
  mutation incentive_and_cashback_upload($uploadIncentiveCashbackArgs: UploadIncentiveCashbackArgs!) {
    incentive_and_cashback_upload(uploadIncentiveCashbackArgs: $uploadIncentiveCashbackArgs){
      totalRows
      mis_id
      data{
        id
        lead_id
        tranche_id
        cost_type
        amount
        incentive_to
        payment_utr
        payment_date
        payment_from_bank
        is_valid
        reason
      }
    }
  }
`;


export const REPORTING_STATUS = (user_id,month, year, report_status) => gql`
  query{
    reporting_status(api_called_by:web,user_id:${user_id || 0},month:${month || 0},year:${year || 0},reporting_status:"${report_status || ""}"){
      id
      month
      month_name
      year
      created_date
      reporting_status
      total_lead_count
      total_disbursed_lead
      total_disbursed_amount
      freezed_total_lead_count
      freezed_total_disbursed_lead
      freezed_total_disbursed_amount
    }
      masterdata(api_called_by:web,is_partnership:"0"){
        report_month_list{
          id
          label
        }
        report_year_list{
          id
          label
        }
        report_status_list{
          id
          label
        }
      }
  }
`;
export const LOAN_ID_REPORT = (report_id, user_id, status = 'open' ) => gql`
query{
  loan_id_report(api_called_by:web, user_id:${user_id}, report_id:${report_id}, status:"${status}" ){
    lead_id
    created_date
    login_date
    disbursed_date
    disbursed_amount
    tranche_id
    tranche_type
    tranche_number
    fulfillment_type
    product_type
    product_sub_type
    partner_id
    parent_partner_id
    partner_name
    parent_partner_name
    rm_name
    customer_first_name
    customer_last_name
    bank_name
    transaction_date
    lead_created_date
    login_amount
    login_amount_cr
    login_number
    sanctioned_date
    sanctioned_amount
    sanctioned_id
    disbursed_amount_cr
    reported_amount
    reported_on
    disbursal_verified
    disbursed_month
    disbursal_id
    transaction_id
    pdd_date
    scheme_name
    partner_circle
    partner_slab
    payout_per
    calculated_payout
    processing_fee
    calc_payin_per
    payin_calculated
    cashback_amount
    gross_margin
    lender_name
    month
    year
    reporting_status
    partner_circle
    fbm_name
    channel
    region
    sanctioned_amount_cr
    property_type
  }
}
`;

export const CLOSED_REPORTING = (user_id, year) => gql`
  query{
    get_closed_reporting_month(api_called_by:web,user_id:${user_id},year:${year}){
      months
      year
    }
  }
`;

export const MIS_UPDATE_DATA = gql`
  mutation mis_update_data($updatePaymentArgs: UpdateMisArgs!) {
    mis_update_data(updatePaymentArgs: $updatePaymentArgs)
  }
`;

export const DELETE_TRANCH = (tranche_id , user_id) => gql`
  mutation {
    delete_tranche(tranche_id: ${tranche_id}, user_id: ${user_id}) {
      message
      __typename
    }
  }
`;


export const INCENTIVE_CASHBACK_UPDATE_DATA = gql`
mutation incentive_cashback_update_data($updateIncentiveCashbackArgs: UpdateIncentiveCashbackArgs!) {
  incentive_cashback_update_data(updateIncentiveCashbackArgs: $updateIncentiveCashbackArgs) {
    totalRows
    mis_id
    data {
      id
      lead_id
      tranche_id
      cost_type
      amount
      incentive_to
      payment_date
      payment_utr
      payment_from_bank
      created_at
      is_duplicate
      is_valid
      reason
    }
  }
}
`;

export const MIS_DETAILS = () => gql`
  query mis_list($filter_params: LeadMisFiltersArgs!) {
  mis_list(filter_params: $filter_params, page_no: 1) {
    list{
        id
        lead_id
        tranche_id
        payin_type
        payin_status
        utr
        Invoice_id
        Invoice_date
        payment_date
        payin_amount
    }
  }
}
`;

export const REPORT_VERIFICATION_LIST = (report_id, user_id, status = 'open' ) => gql`
query{
  get_reporting_verification_list(api_called_by:web, user_id:${user_id}, report_id:${report_id}, status:"${status}")
}
`;


export const DISBURSE_DETAIL_LIST = (tranche_id) => gql`
query{
  disburse_detail_list(tranche_id:${tranche_id})
}
`;
export const CREATE_INSAURANCE_RECORD = gql`
  mutation create_insurance_record($insuranceRecord: InsuranceRecordInput!){
        create_insurance_record(insuranceRecord: $insuranceRecord)
    }
`;
export const GET_INSURANCE_RECORD = (lead_id)  =>gql`
  query {
    get_insurance_record(lead_id: ${lead_id}){
            lead_id
            id
            insurance_type
            selected_premium
            selected_supplier
            total_sum_insured
            policy_tenure
            per_collection
            expected_payin_amount
    }
  } 
`;
