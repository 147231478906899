import { gql } from "@apollo/client";

export const GET_ACCESS_FEATURES=(user_id)=>gql`
query{
  get_user_access_features(user_id:${user_id}){
    name
    controller
  	feature_type
    features_role_id
    product_type
    action
    access_type
  }
}
`;

export const GET_FEATURE_LIST=()=>gql`
query{
    get_feature_list{
    id
    name
    description
    controller
    action
    status
    feature_type
    features_role_id
    product_type
    created_date
    updated_date
    created_by
    updated_by
    is_change_allowed

  }
}
`;

export const GET_ROLE_LIST=()=>gql`
query{
    get_role_list {
      id
      name
      description
      created_date
      updated_date
      created_by
      updated_by
      status
      role_status
      user_assigned_ids
      user_assigned_count
      feature_access_count
      feature_access_ids {
        id
        access_type
      }
    }
}
`;

export const SAVE_EDIT_ROLE = gql`
  mutation saveRole($action_type: String!,$SaveRoleInput:SaveRoleInput!,$user_id: Float!, $user_ids: [Float!]!, $feature_data: [FeatureData!]!){
    save_role(action_type: $action_type,saveRoleInput: $SaveRoleInput, user_id: $user_id, user_ids: $user_ids, feature_data: $feature_data) {
      message
    }
}`

export const SAVE_EDIT_USER = gql`
  mutation saveUser($action_type: String!,$SaveUserInput:SaveUserInput!,$created_by: Float!, $role_ids: [Float!]!){
    save_user(action_type: $action_type,saveUserInput: $SaveUserInput, created_by: $created_by, role_ids: $role_ids) {
      message
    }
}`

/* export const GET_USER_LIST = (role_id=0) => gql`
query{
  get_user_list(role_id: ${role_id}) {
    email
    feature_assigned_ids
    mobile
    name
    reporting_user
    reporting_user_id
    role_assigned
    role_assigned_ids
    status
    user_id
    user_status
    vertical
    vertical_id
    dialer_id
    reporting_user_role_assigned
  }
}
`; */
export const GET_USER_LIST = (role_id=0,checker_role_id=0) => gql`
query{
  get_user_list(role_id: ${role_id},checker_role_id: ${checker_role_id}) {
    email
    feature_assigned_ids
    mobile
    name
    role_assigned
    role_assigned_ids
    status
    user_id
    user_status
    vertical
    vertical_id
    dialer_id
  }
}
`;

export const GET_USERS_LIST = (role_ids=0,checker_role_id=0) => gql`
query{
  get_users_list(role_ids: ${JSON.stringify(role_ids)},checker_role_id: ${checker_role_id}) {
    email
    feature_assigned_ids
    mobile
    name
    role_assigned
    role_assigned_ids
    status
    user_id
    user_status
    vertical
    vertical_id
    dialer_id
  }
}
`;


export const UPDATE_STATUS = gql`
  mutation updateStatus($action_type: String!, $id: Float!, $status: String!, $user_id: Float!){
    update_status(action_type: $action_type, id: $id, status: $status, user_id: $user_id) {
      message
    }
}`
