import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import crypto from "../../config/crypto";
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";
import FormsyInputField from './../elements/FormsyInputField';
import FormsyDatePicker from './../elements/FormsyDatePicker';
import FormsySelect from './../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from './../lead-list/LeadRemarkHistory';
import Modal from './../elements/Modal';
import { executeGraphQLMutation } from './../../common/executeGraphQLQuery';
import { UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { gql, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail, getLeadRemarks } from './../../store/action/allAction';
import GeneralService from '../../services/generalService';
import Loader from '../elements/Loader';
import LostModal from './LostModal';
import { APROVED_QUERY_ID,IS_USER_ACCESS_PAGE } from '../../config/constants';
import LoggedinPopup from '../elements/LoggedinPopup';
import LoggedinTabs from './LoggedinTabs';
import right from "../../webroot/images/angle-right.svg";
import dangericon from "./../../webroot/images/dangericon.png";
import { SAVE_CUST_DETAILS } from '../../services/customer.gql';
import LeadStatusService from "../../services/leadStatus";

const ApprovedDetailsForm = FormFields["APPROVED"];
const approvalDocsJson = FormFields['APPROVAL_DOCS'];
const EXPECTED_SANCTION_DATE = FormFields['EXPECTED_SANCTION_DATE'];

const ApprovedForm = (props) => {
  const [approvedDetails, setApprovedDetails] = useState({});
  const [approvalDocuments, setApprovalDocs] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  let [loading, setIsLoading] = useState(false);
  let [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const [MarkAsLost, setMarkAsLost] = useState(false);
  const [bankLost, setBankLost] = useState(false);
  const [rejectionType, setRejection] = useState('')
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();
  const getContext = useOutletContext();
  let verifyAccess = IS_USER_ACCESS_PAGE("verify_sancation", "verify_sancation")?.is_edit_access; 

  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  let updatedStatusList = lead?.leadDetail?.lead_status_history?.map(data => data.status_id) || [];

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      if(lead.leadDetail.sub_status_id ==3){
        setChecked(true)
      } 
      let approvedData = { ...lead.leadDetail.lead_details };
      approvedData["approval_remark"] = "";
      approvedData["approval_date"] = approvedData["approval_date"] ? dateFormat(approvedData["approval_date"], "dd mmm, yyyy") : '';
      approvedData["expected_approved_date"]= approvedData["expected_approved_date"] ? dateFormat(approvedData["expected_approved_date"], "dd mmm, yyyy") : '';
      setApprovedDetails(approvedData);
    }

  }, [lead]);

  useEffect(() => {
    let doc_list = [{
      "id": 43,
      "max_upload": 5,
      "min_upload": 1,
      "name": "Approved Image"
    }];
    let doc_array = [];
    approvalDocsJson && approvalDocsJson.map((field) => {
      doc_list?.map(doc => { return (doc.id === field.doc_id) ? doc_array.push(doc) : doc_array.push({}) })
    })
    doc_array = doc_array.filter(obj => Object.keys(obj).length !== 0);
    setApprovalDocs(doc_array);
  }, []);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    return options;
  }, [masterdata]);

  const handleInputChange = event => {
    if (event.target.id) {
      setApprovedDetails(currentValue => ({
        ...currentValue,
        [event.target.id]: event.target.value
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setApprovedDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setApprovedDetails(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setApprovedDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }
  const showModalMarkAsLost = (type) => {
    // if(['bank_hard_reject','bank_soft_reject'].includes(type)){
    //   hideModalBankLost()
    // }    
    setMarkAsLost(true);
    setRejection(type)
    document.body.classList.add("overflow-hidden");
  };
  const hideModalMarkAsLost = () => {
    setMarkAsLost(false);
    document.body.classList.remove("overflow-hidden");
  };

  const showModalBankLost = () => {
    setBankLost(true);
    document.body.classList.add("overflow-hidden");
  };
  const hideModalBankLost = () => {
    setBankLost(false);
    document.body.classList.remove("overflow-hidden");
  };

  const navigateToDocSection = () => {
    let lead_id = lead?.leadDetail?.id || "";
    navigate(`/lead-detail/docs-upload/${crypto.encode(lead_id)}`);
  }

  // const isRequiredCheckList = (lead?.leadDetail?.checklists?.length && lead.leadDetail?.checklists?.filter(data => ([2, 4, 6, 8, 12].includes(data.checklist_sub_status_id)))?.length >= 5 && lead.leadDetail?.checklists?.filter(data => (data.checklist_status_id === 1 && data.is_done_cam === '1')))?.length ? false : true;
  const isSubmitDisabled = useMemo(() => {
    return (lead.leadDetail.is_sanction_verified === '1' || lead.leadDetail.lead_details?.expected_approved_date == '') ? true : false;
  }, [allowSubmit, getContext]);

  const is_sanction_verified = useMemo(() => {
    return ([1, 2].includes(lead.leadDetail.status_id) || lead.leadDetail.is_sanction_verified === '2') ? true : false;
  }, [getContext]);

  const sanction_verified = useMemo(() => {
    return ([1, 2].includes(lead.leadDetail.status_id) || lead.leadDetail.is_sanction_verified === '1' || lead.leadDetail.is_sanction_verified === '2') ? true : false;
  }, [getContext]);

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    //if (+lead.leadDetail?.lead_details?.login_amount < +approvedDetails.approved_amount) return toast.error("Sanctioned amount greater than the login amount, please correct the amount before proceeding.");
    // if(approvedDetails.expected_approved_date && !approvedDetails.approved_amount){
    //   handleSubmitExpected();
    // }else{
      if(approvedDetails.approved_roi>=0 && approvedDetails.approved_roi<=99 || approvedDetails.approved_roi===null){
        setIsLoading(true);
        let reqBody = {
          approved_amount: approvedDetails.approved_amount || "0",
          approval_number: approvedDetails.approval_number || "0",
          approval_remark: approvedDetails.approval_remark || "",
          approved_emi: approvedDetails.approved_emi || "0",
          approval_date:approvedDetails.approval_date ? dateFormat(approvedDetails.approval_date, "yyyy-mm-dd") : null,
          approved_roi:approvedDetails.approved_roi || "0"
        }
        
        let mutation = UPDATE_LEAD_STATUS,
          variables = {
            api_called_by: 'web',
            UpdateLeadStatus: {
              update_type: "status",
              save_type: ([3].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ? "update" : "update",
              status_id: [3],
              sub_status_id: (checked)? 3:4,
              lead_id: lead_id,
              user_id: user_id,
              approval_form: reqBody,
              callFrom:"updateSideForm"
            }
          };
    
        executeGraphQLMutation(mutation, variables, client).then(resp => {
          let response = resp?.data?.UpdateLeadStatus || null;
          if (response && !response.success) {
            throw new Error(response.error || "Something went wrong");
          }
          toast.success("Success");
          dispatch(getLoanDetail(lead_id, client));
          if (approvedDetails.approval_remark) dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
          setIsLoading(false);
        })
          .catch(err => {
            setIsLoading(false);
            toast.error(err.message);
          })
      }else{
        toast.error('Sanction roi between 0 and 99.')
        return false;
      }
   // }
  }

  const handleSubmitExpected = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            expected_approved_date: approvedDetails.expected_approved_date ? dateFormat(approvedDetails.expected_approved_date, "yyyy-mm-dd") : null
          }
        }
      };
  
      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.LeadAdditionalInput || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        } 
  
        toast.success("Success");
        // setTouch({})
        dispatch(getLoanDetail(lead_id, client))
  
      })
        .catch(err => {
          toast.error(err.message);
        })
   }
  

  const MardkDisburedApproved = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let partner_id = lead?.leadDetail?.partner_id || 0
    setIsLoading(true);
    let variables = ''
    let mutation = gql`mutation{
      approve_lead(action_type:"sanction_verify",lead_id:${lead_id},user_id:${user_id},partner_id:${partner_id}){
        message
      }
    }`;

    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        setIsLoading(false);
        toast.success('Sanction Verified');
        dispatch(getLoanDetail(lead_id, client))
      } else {
        setIsLoading(false);
      }
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleFileChange = async (event, max_upload) => {
    let lead_id = lead?.leadDetail?.id || "";
    let selectedImage = event && event.target && event.target.files && event.target.files.length && event.target.files[0].name || "";
    let publicUrl = '', docPathArray = [];
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }
    if (selectedImage) {
      let lead_id = lead?.leadDetail?.id || "";
      const formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "customer_doc");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      try {
        publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
        if (publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length) {
          docPathArray = publicUrl.data.data;
          docPathArray = docPathArray.map(ob => ob.file_url);
          saveDocument(lead_id, docPathArray);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  }

  const saveDocument = (lead_id, docPathArray) => {
    let leadDocArr = [];
    for (let imgPath of docPathArray) {
      leadDocArr.push({
        lead_id: Number(lead_id),
        doc_id: 43,
        customer_type: "customer",
        customer_id: lead?.leadDetail?.customer?.customer_id || 0,
        parent_doc_id: 9,
        doc_path: imgPath,
        doc_source: "web",
        user_id: authUser && authUser.id || 1,
      })
    }

    let variables = {
      saveDocInput: {
        lead_id: lead_id,
        lead_docs: leadDocArr
      },
      api_called_by: 'web'
    };

    let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
        save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
            lead_id
            doc_id
            doc_path
        }
    }`;
    executeGraphQLMutation(mutation, variables, client).then(result => {
      if (result && result.data) {
        toast.success('Document uploaded successfully');
      }
    })
  }
  const togglePopup = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }

  const checkList = ( masterdata && masterdata.check_list && masterdata.check_list.length) ? masterdata?.check_list?.filter(ob=>ob?.statuslang?.status_id == LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED)?.[0]?.checklist || [] : []; 
  const checkIdArray = lead?.leadDetail?.checklists?.map(ob=>ob.checklist_id) || [];  
  let allCheckList = []
  for(let itemCheck of checkList){
    if(checkIdArray.includes(+itemCheck.id)) {
      const valueArray = lead?.leadDetail?.checklists.filter(ob=>ob.checklist_id ==itemCheck.id)
      if(valueArray && valueArray[valueArray.length-1]?.checklist_sub_status_id) {
        allCheckList = [...allCheckList, {...itemCheck, value_id: valueArray[valueArray.length-1]?.checklist_sub_status_id}] 
      }
    }
  }    
  const approveQuery = allCheckList.map(ob=>ob.value_id).some(r=> APROVED_QUERY_ID.includes(r));  

  const sectionInfo = { section: "Sanctioned Details", sub_section: "" }
  let pageSubmit = lead?.leadDetail?.lead_status_history?.filter(ob => ob.status_id == 3) || []; 

  const rejectDisable = (lead.leadDetail.is_sanction_verified === '1' || lead.leadDetail.is_disbursal_verified === '1' || !pageSubmit.length || lead.leadDetail.status_id == 5) ? true : false
  
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false 
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 90);
  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="43" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer"> 
        <div className="lead-form-heading">
          <div className='loggedinflex'>
          <h2>Sanctioned</h2> 

          
        
          {
            (isSubmitDisabled || !allowSubmit || getContext.markAsFreezed || approveQuery)?
            <div className='updatedbtnDisabled' type="submit">
              Update
            </div>
            : 
            <div className='updatedbtn' type="submit" onClick={handleSubmit}>
              Update
            </div>
          }
          
            </div>
        </div> 
 
       {
        pageSubmit.length && checked ? 
        <div className='check-text' onClick={togglePopup}>
          <div className='loggedinflex'>
            <div style={{display:"flex"}}>
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              <i class="ic-pending-application"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>
            </div>
            <div style={{ fontSize: 13, marginLeft: 10 }}>Sanction checklist</div>  
          </div>
          <img src={right} alt='icon' width={10} height={10} />
          </div>

            {
              allCheckList.map((item)=>{ 
                if(APROVED_QUERY_ID.includes(item.value_id)){
                  return <div style={{marginLeft:50}}>
                    <img style={{marginRight:10}} src={dangericon} alt='logo' width={20} height={20} />
                    Query Raised : &nbsp;
                    {item.checklist_name}
                  </div>
                }else{
                  return null
                } 
              })
            }   
        </div>  : null
       }
       

{/* 
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            >
            <div className="row">
              {EXPECTED_SANCTION_DATE.map((field) => (
                field.type === "date-picker" ? (
                  <fieldset class="form-filed col-md-12 ">
                    <FormsyDatePicker
                      name={field.name}
                      selectedDate={approvedDetails && approvedDetails[field.name] && new Date(approvedDetails[field.name])}
                      placeholder={field.label}
                      minDate={new Date()}
                      maxDate={maxDate}
                      value={approvedDetails && approvedDetails[field.name]}
                      onDateSelect={(date) => handleDateChange(date, field.name)}
                      dateFormat="dd MMM, yyyy"
                      validations={field.validations}
                      validationError={
                        approvedDetails[field.name] ? field.validationError : ""
                      }
                      disabled={approvedDetails.approved_amount}
                      required={field.required}
                      hasError={(approvedDetails.hasOwnProperty(field.name) && !approvedDetails[field.name]) ? true : false}
                    />
                  </fieldset>
                ) : null
              ))}
            </div>
          </Formsy> */}
 

          {/* {(lead?.leadDetail?.fulfillment_type === 'ambak' && isRequiredCheckList) ?
            <div className='disbursedcard'>
              <div className='disheading'>Pending Items in Checklist</div>
              <div className='dispara'>Your Login Checklist is not yet completed! Please finish the checklist to verify the approval status.</div>
            </div>
            : ""} */}

{/* <h2>Sanctioned Details</h2>  */}

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {ApprovedDetailsForm.map((field) => (
                ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={approvedDetails && approvedDetails[field.name]}
                      placeholder=" "
                      label={field.label}
                      onChange={
                        field.type === "pattern-format"
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        approvedDetails[field.name] ? field.validationError : ""
                      }
                      readOnly={field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) : ["number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={approvedDetails && approvedDetails[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      onChange={
                        field.type === "number-format"
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        approvedDetails[field.name] ? field.validationError : ""
                      }
                      readOnly={field.readOnly}
                      required={(lead?.leadDetail?.fulfillment_type === 'ambak' && field.name === 'approved_roi') ? true : field.required}
                    />
                  </fieldset>
                ) : field.type === "date-picker" ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyDatePicker
                      name={field.name}
                      selectedDate={approvedDetails && approvedDetails[field.name] && new Date(approvedDetails[field.name])}
                      placeholder={field.label}
                      minDate={new Date(approvedDetails?.login_date)}
                      maxDate={new Date()}
                      value={approvedDetails && approvedDetails[field.name]}
                      onDateSelect={(date) => handleDateChange(date, field.name)}
                      dateFormat="dd MMM, yyyy"
                      validations={field.validations}
                      validationError={
                        approvedDetails[field.name] ? field.validationError : ""
                      }
                      disabled={field.readOnly}
                      required={field.required}
                      hasError={(approvedDetails.hasOwnProperty(field.name) && !approvedDetails[field.name]) ? true : false}
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == approvedDetails[field.name]),
                        isDisabled: field.readOnly
                      }}
                      required={field.required}
                      value={approvedDetails && approvedDetails[field.name]}
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                  </fieldset>
                ) : field.type === "textarea" ? (
                  <fieldset class="col-md-12">
                    <div class="material">
                      <textarea
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={approvedDetails && approvedDetails[field.name]}
                        placeholder=" "
                        onChange={handleInputChange}
                        class="form-input"
                      >
                      </textarea>
                      <label data-label={field.label} class="form-label"></label>
                    </div>
                  </fieldset>
                ) : null
              ))}
            </div>

            <div className="d-flex">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={checked}
                onChange={()=>setChecked(!checked)}
                name="disbursed_with_otc"
                id="disbursed_with_otc"
                value="Yes"
                disabled={IS_LOST}
              />
              <span className="slider"></span>
            </label>
            <label className="toggle-label">Sanction with OTC</label>
          </div>

            {/* <div className="acco-doc-list">
              <ul>
                {approvalDocuments.map((fields) => (
                  <li>
                    <div className="img-bx-select-opt">
                      <div className="image-bx more-file-upload">
                        <input id="uploadForm" type="file" name="" onChange={e => handleFileChange(e, fields.max_upload)} onClick={e => (e.target.value = null)} required={fields.is_required} multiple />
                        <a href='#' onClick={navigateToDocSection}>Verify</a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div> */}
            <br></br>
            <div className="btn-save-remarks"> 
              <button type="button" onClick={MardkDisburedApproved} className="btn-primary" disabled={sanction_verified || approveQuery || !pageSubmit.length || IS_LOST || verifyAccess !== 1}>{sanction_verified ? "Verified" : "Verify"}</button>
              {(!is_sanction_verified && verifyAccess === 1) && (
                <button type="button" onClick={() => showModalMarkAsLost('ops_reject')} className="btn-primary" disabled={rejectDisable}>
                  Verification Failed
                </button>
              )}
              {/*   <button type="button"onClick={()=>showModalMarkAsLost('bank_reject')} className="btn-primary" disabled={rejectDisable}>
                 Bank Reject
              </button> */}
              {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
            </div>
          </Formsy>
        </div>
      </div>


      <div className="mark-lost-popup">
        <LostModal MarkAsLost={MarkAsLost} hideModalMarkAsLost={hideModalMarkAsLost} rejection_type={rejectionType} statusId={LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED} bucketId={LeadStatusService.LEAD_STATUS_BUCKET_MASTER.SANCTIONED} />
      </div>

      <div className="view-timeline-popup">
        {/* <Modal show={bankLost} handleClose={hideModalBankLost} >
          <div className="modal-header"><h2>Bank Denied Sanction</h2></div>
          <div className="btn-save-remarks"> 
              <button type="button" onClick={()=>showModalMarkAsLost('bank_soft_reject')} className="btn-primary">
               Soft Reject
              </button>
              <button type="button" onClick={()=>showModalMarkAsLost('bank_hard_reject')} className="btn-primary">
                Hard Reject
              </button> 
            </div>
                  
        </Modal> */}

        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header"><h2>Remark History</h2></div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} sectionInfo={sectionInfo} />
        </Modal>
      </div>

      <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}>
        <LoggedinTabs status_id={LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED} togglePopup={togglePopup} />
      </LoggedinPopup>
    </div>
  );
}

export default ApprovedForm;
