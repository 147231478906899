import { gql } from '@apollo/client';

export const LEAD_CREATION_FORM = gql`
    mutation create_loan($newLeadInput: CreateLoanInput!, $api_called_by: API_CALL_BY!, $user_id: Float,$role_id:Float, $partner_id:Float, $is_demo:String) {
    create_loan(newLeadInput: $newLeadInput, api_called_by: $api_called_by,user_id: $user_id,role_id:$role_id, partner_id:$partner_id, is_demo:$is_demo) {
        id
        message
        is_duplicate
    }
}
`;

export const LEAD_CREATION_CSV = gql`
    mutation create_leads_csv($newLeadsJsonArray: CreateCsvuploadInput!) {
        create_leads_csv(newLeadsJsonArray: $newLeadsJsonArray)
    }
`;

export const ASSIGN_LEAD_CSV = gql`
    mutation update_assign_lead($BulkAssignLeadInput: BulkAssignLeadInput!){
        update_assign_lead(bulkAssignLeadInput: $BulkAssignLeadInput) 
    }
`;
export const PARTNER_DROPDOWN_LIST = gql`
    query{
      partner_list_dropdown
    }
`;

export const PARTNER_DROPDOWN_LIST_FILTER = (partner_type = "") => gql`
    query{
        partner_list_dropdown(partner_type: "${partner_type}")
    }
`;

export const GET_SFA_USER_LIST = gql`
  mutation {
    sfa_user_list {
      id
      name
      email
      role_id
      mobile
    }
  }
`;


export const CHAT_SAVE = gql`
mutation chat_save($chatInput: SaveChatInput!, $api_called_by: API_CALL_BY!, $call_by: String! ,   $tempelate_name: String! ) {
    chat_save(chatInput: $chatInput, api_called_by: $api_called_by, call_by: $call_by , tempelate_name : $tempelate_name)  {
        message
    }
}
`;

export const UPDATE_REFERENCE_DETAILS = gql`
  mutation updateReferenceDetails(
    $lead_id: Float!,
    $reference_name: String!,
    $reference_phone: String!,
    $reference_type: String!
  ) {
    update_reference_details(
      lead_id: $lead_id,
      reference_name: $reference_name,
      reference_phone: $reference_phone,
      reference_type: $reference_type
    )
  }
`;

export const GET_REFERENCE_DETAILS_BY_LEAD_ID = gql`
  mutation getReferenceDetailsByLeadId($lead_id: Float!) {
    get_reference_details_by_lead_id(lead_id: $lead_id,) {
      lead_id
      mobile
      full_name
      first_name
      last_name
    }
  }
`;

