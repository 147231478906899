import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import Pagination from "../elements/pagination";
import { MasterService } from "../../services";
import InputField from "../elements/Input";
import Select from "react-select";
import "./BreBankUSP.css";
import Modal from "../elements/Modal";
import Formsy from "formsy-react";
import FormsySelect from "../elements/FormsySelect";

// ✅ Toastify
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Endpoint for creating new entries
const ADD_API_URL = "/process-rule/crud/addprocessing";

// Status Filter Options
const statusOptions = [
  { value: "", label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

// Profession Options (both for filtering and for add/update)
const professionOptions = [
  { value: "", label: "All" },
  { value: "1", label: "Salaried" },
  { value: "2", label: "Self Employed Professional" },
  { value: "3", label: "Self Employed Non-Professional" },
];

const BreBankProcessingTable = () => {
  const [data, setData]             = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage]   = useState(1);
  const [itemsPerPage]                 = useState(10);

  // Modal + item state
  const [showModal, setShowModal]         = useState(false);
  const [selectedItem, setSelectedItem]   = useState(null);
  const [expandedRows, setExpandedRows] = useState({});


  // Filter state
  const [searchParams, setSearchParams] = useState({
    bank_name: "",
    status: "",
    profession_id: "",
  });

  // Loading state
  const [loading, setLoading] = useState(true);

  // Existing endpoints
  const API_URL        = "/process-rule/crud/brebankprocess";
  const DELETE_API_URL = "/process-rule/crud/deletebrecharging";
  const UPDATE_API_URL = "/process-rule/crud/updateprocessing";

  // 1) Fetch data on mount
  useEffect(() => {
    fetchData();
  }, []);

  // Re-apply filters whenever searchParams or data changes
  useEffect(() => {
    applyFilters();
  }, [searchParams, data]);

  // 1) Fetch all records
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await MasterService.get(API_URL);
      setData(response.data?.data || []);
    } catch (err) {
      toast.error("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };

  const toggleExpand = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  

  // 2) Apply filters to data
  const applyFilters = () => {
    const result = data.filter((item) => {
      // Filter by bank name
      const bankMatch = searchParams.bank_name
        ? item.bank_name?.toLowerCase().includes(searchParams.bank_name.toLowerCase())
        : true;

      // Filter by status
      const statusMatch =
        searchParams.status !== ""
          ? String(item.bankprocess_status || item.status) === searchParams.status
          : true;

      // Filter by profession
      const professionMatch =
        searchParams.profession_id !== ""
          ? String(item.bankprocess_profession_id || item.profession_id) === searchParams.profession_id
          : true;

      return bankMatch && statusMatch && professionMatch;
    });
    setFilteredData(result);
  };

  // 3) Handle input changes for filters
  const handleInputChange = (e) => {
    setCurrentPage(1);
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  // 4) Mark record as Inactive
  const handleDelete = async (id) => {
    try {
      await MasterService.put(`${DELETE_API_URL}/${id}`);
      toast.success("Status changed to Inactive!");
      fetchData();
    } catch (err) {
      toast.error("Error updating status.");
    }
  };

  // 5) Edit item -> populate form
  const handleEdit = (item) => {
    setSelectedItem({
      bankprocess_id: item.bankprocess_id,
      bankprocess_bank_id: item.bank_id?.toString() || "", // Convert bank_id to string
      bankprocess_profession_id: item.bankprocess_profession_id?.toString() || "", // Convert profession to string
      bankprocess_charge_text: item.bankprocess_charge_text || "",
      bankprocess_login_fee:  item.bankprocess_login_fee  || "",
      bankprocess_status:     item.bankprocess_status     || "1",
      duDiligeChar: item.duDiligeChar || "",
      eStampChar:   item.eStampChar   || "",
      modtChar:     item.modtChar     || "",
      noiChar:      item.noiChar      || "",
      cersaiChar:   item.cersaiChar   || "",
      legTechFee:   item.legTechFee   || "",
      acHanChar:    item.acHanChar    || ""
    });
    setShowModal(true);
  };

  // 6) Add button -> open modal in 'create' mode
  const handleAdd = () => {
    setSelectedItem({
      bankprocess_id: null,
      bankprocess_bank_id: "",
      bankprocess_profession_id: "",
      bankprocess_charge_text: "",
      bankprocess_login_fee:  "",
      bankprocess_status:     "1",
      duDiligeChar: "",
      eStampChar:   "",
      modtChar:     "",
      noiChar:      "",
      cersaiChar:   "",
      legTechFee:   "",
      acHanChar:    ""
    });
    setShowModal(true);
  };

  // 7) Decide whether to Add or Update
  const handleUpdate = async () => {
    try {
      // If there's no bankprocess_id => do ADD
      if (!selectedItem?.bankprocess_id) {
        // First, check if bank_id is provided
        if (!selectedItem.bankprocess_bank_id?.toString().trim()) {
          toast.error("Bank ID is required for adding a new record!");
          return;
        }

        const bankId = Number(selectedItem.bankprocess_bank_id);
        if (isNaN(bankId) || bankId <= 0) {
          toast.error("Bank ID must be a valid positive number!");
          return;
        }

        // Profession ID
        let professionId = 0;
        if (selectedItem.bankprocess_profession_id?.trim()) {
          professionId = Number(selectedItem.bankprocess_profession_id);
          if (isNaN(professionId) || professionId < 0) {
            toast.error("Profession ID must be a valid positive number (or 0)!");
            return;
          }
        }

        // Build ADD payload
        const addPayload = {
          bank_id: bankId,
          profession_id: professionId,
          charge_text: selectedItem.bankprocess_charge_text,
          login_fee:   selectedItem.bankprocess_login_fee,
          status:      selectedItem.bankprocess_status,
          due_diligence_charge: selectedItem.duDiligeChar,
          e_stamp_charge:        selectedItem.eStampChar,
          MODT_charge:           selectedItem.modtChar,
          NOI_charge:            selectedItem.noiChar,
          CERSAI_charge:         selectedItem.cersaiChar,
          legal_technical_fee:   selectedItem.legTechFee,
          ac_handling_charge:    selectedItem.acHanChar
        };

        const requestData = { data: JSON.stringify(addPayload) };
        // POST for add
       const resp= await MasterService.post(ADD_API_URL, requestData, {
          headers: { "Content-Type": "application/json" },
        });
        
        if (resp.status > 399) {
          toast.error("Bank ID is not valid");
          return;
        }

        toast.success("Added successfully!");
        setShowModal(false);

        // Reset filters so user can see newly added row
        setSearchParams({ bank_name: "", status: "", profession_id: "" });
        fetchData();
      } else {
        // existing UPDATE logic
        // Convert profession to number if needed
        let professionId = 0;
        if (selectedItem.bankprocess_profession_id?.trim()) {
          professionId = Number(selectedItem.bankprocess_profession_id);
          if (isNaN(professionId) || professionId < 0) {
            toast.error("Profession ID must be a valid positive number (or 0)!");
            return;
          }
        }

        const updatePayload = {
          charge_text: selectedItem.bankprocess_charge_text,
          login_fee:   selectedItem.bankprocess_login_fee,
          status:      String(selectedItem.bankprocess_status),
          profession_id: professionId,
          due_diligence_charge: selectedItem.duDiligeChar,
          e_stamp_charge:        selectedItem.eStampChar,
          MODT_charge:           selectedItem.modtChar,
          NOI_charge:            selectedItem.noiChar,
          CERSAI_charge:         selectedItem.cersaiChar,
          legal_technical_fee:   selectedItem.legTechFee,
          ac_handling_charge:    selectedItem.acHanChar
        };

        const reqData = { data: JSON.stringify(updatePayload) };
        await MasterService.put(
          `${UPDATE_API_URL}/${selectedItem.bankprocess_id}`,
          reqData,
          { headers: { "Content-Type": "application/json" } }
        );

        toast.success("Updated successfully!");
        setShowModal(false);
        fetchData();
      }
    } catch (err) {
      console.error("Update Error:", err.response?.data || err.message);
      toast.error("Error saving record");
    }
  };

  // 8) Paginated data
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
   
      

      {/* If loading */}
      {loading && (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
          <p>Loading data...</p>
        </div>
      )}

      {!loading && (
        <>
          {/* Filters */}
          <div className="search-wrap mb-3">
            <div className="filter-container">
              <div className="filter-body">
                <div className="row">
                  {/* Bank Name */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Bank Name</div>
                      <div className="filter-input">
                        <InputField
                          name="bank_name"
                          placeholder="Search Bank Name"
                          value={searchParams.bank_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Status */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Status</div>
                      <div className="material">
                        <Select
                          options={statusOptions}
                          placeholder="Select Status"
                          name="status"
                          value={statusOptions.find(
                            (o) => o.value === searchParams.status
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            setSearchParams({ ...searchParams, status: newVal });
                            handleInputChange({
                              target: { name: "status", value: newVal },
                            });
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Profession */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Profession</div>
                      <div className="material">
                        <Select
                          options={professionOptions}
                          placeholder="Select Profession"
                          name="profession_id"
                          value={professionOptions.find(
                            (opt) => opt.value === searchParams.profession_id
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            setSearchParams({
                              ...searchParams,
                              profession_id: newVal,
                            });
                            handleInputChange({
                              target: { name: "profession_id", value: newVal },
                            });
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Buttons */}
                  <div className="text-btn d-flex">
                    <div className="btn-submit-reset top-btn-none pull-right">
                      <button
                        className="btn-primary"
                        onClick={() => setCurrentPage(1)}
                      >
                        Apply
                      </button>
                      <button
                        className="btn-reset"
                        onClick={() =>
                          setSearchParams({
                            bank_name: "",
                            status: "",
                            profession_id: "",
                          })
                        }
                      >
                        Reset
                      </button>

                      {/* Add Button */}
                      <button
                        className="btn-reset"
                        style={{ marginLeft: "150px" }}
                        onClick={handleAdd}
                      >
                        Add Entry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Data Table */}
          <Table striped bordered hover responsive>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Bank </th>
                <th>Profession</th>
                <th>Charge Type</th>
                <th>Charge Text</th>
                <th>Login Fee</th>
                <th>Status</th>
                <th>Due Diligence</th>
                <th>eStamp</th>
                <th>MODT</th>
                <th>NOI</th>
                <th>CERSAI</th>
                <th>Legal Tech</th>
                <th>Account Handling</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((item) => (
                  <tr key={item.bankprocess_id}>
                    <td>{item.bankprocess_id}</td>
                    <td>{item.bank_name}</td>
                    <td>
                      {professionOptions.find(
                        (opt) =>
                          opt.value === String(
                            item.bankprocess_profession_id ||
                              item.profession_id
                          )
                      )?.label || "-"}
                    </td>
                    <td>{item.bankprocess_charge_type}</td>
                    <td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    style={{
      display: "-webkit-box",
      WebkitLineClamp: expandedRows[item.bankprocess_id] ? "none" : 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }}
  >
    {item.bankprocess_charge_text || "-"}
  </div>
  {item.bankprocess_charge_text?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() => toggleExpand(item.bankprocess_id)}
      style={{ padding: "0", marginTop: "4px" ,color: "#0d6efd", fontWeight: "500"}}
    >
      {expandedRows[item.bankprocess_id] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

<td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    style={{
      display: "-webkit-box",
      WebkitLineClamp: expandedRows[`login_fee-${item.bankprocess_id}`] ? "none" : 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }}
  >
    {item.bankprocess_login_fee || "-"}
  </div>
  {item.bankprocess_login_fee?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() =>
        setExpandedRows((prev) => ({
          ...prev,
          [`login_fee-${item.bankprocess_id}`]: !prev[`login_fee-${item.bankprocess_id}`],
        }))
      }
      style={{ padding: "0", marginTop: "4px",color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`login_fee-${item.bankprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

                    <td>
                      {item.bankprocess_status === "1" ? "Active" : "Inactive"}
                    </td>
                    <td>{item.duDiligeChar || "-"}</td>
                    <td>{item.eStampChar || "-"}</td>
                    <td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    style={{
      display: "-webkit-box",
      WebkitLineClamp: expandedRows[`modt-${item.bankprocess_id}`] ? "none" : 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }}
  >
    {item.modtChar || "-"}
  </div>
  {item.modtChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() =>
        setExpandedRows((prev) => ({
          ...prev,
          [`modt-${item.bankprocess_id}`]: !prev[`modt-${item.bankprocess_id}`],
        }))
      }
      style={{ padding: "0", marginTop: "4px",color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`modt-${item.bankprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

<td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    style={{
      display: "-webkit-box",
      WebkitLineClamp: expandedRows[`noi-${item.bankprocess_id}`] ? "none" : 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }}
  >
    {item.noiChar || "-"}
  </div>
  {item.noiChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() =>
        setExpandedRows((prev) => ({
          ...prev,
          [`noi-${item.bankprocess_id}`]: !prev[`noi-${item.bankprocess_id}`],
        }))
      }
      style={{ padding: "0", marginTop: "4px",color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`noi-${item.bankprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

<td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    style={{
      display: "-webkit-box",
      WebkitLineClamp: expandedRows[`cersai-${item.bankprocess_id}`] ? "none" : 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }}
  >
    {item.cersaiChar || "-"}
  </div>
  {item.cersaiChar?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() =>
        setExpandedRows((prev) => ({
          ...prev,
          [`cersai-${item.bankprocess_id}`]: !prev[`cersai-${item.bankprocess_id}`],
        }))
      }
      style={{ padding: "0", marginTop: "4px" ,color: "#0d6efd", fontWeight: "500"}}
    >
      {expandedRows[`cersai-${item.bankprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

<td style={{ maxWidth: "300px", whiteSpace: "pre-wrap" }}>
  <div
    style={{
      display: "-webkit-box",
      WebkitLineClamp: expandedRows[`legTechFee-${item.bankprocess_id}`] ? "none" : 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    }}
  >
    {item.legTechFee || "-"}
  </div>
  {item.legTechFee?.length > 100 && (
    <Button
      variant="link"
      size="sm"
      onClick={() =>
        setExpandedRows((prev) => ({
          ...prev,
          [`legTechFee-${item.bankprocess_id}`]: !prev[`legTechFee-${item.bankprocess_id}`],
        }))
      }
      style={{ padding: "0", marginTop: "4px", color: "#0d6efd", fontWeight: "500" }}
    >
      {expandedRows[`legTechFee-${item.bankprocess_id}`] ? "Show Less" : "Show More"}
    </Button>
  )}
</td>

                    <td>{item.acHanChar || "-"}</td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-1"
                        onClick={() => handleEdit(item)}
                      >
                        ✏️edit
                      </Button>
                      {String(item.bankprocess_status)==="1" && (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(item.bankprocess_id)}
                      >
                        🗑️delete
                      </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="15" className="text-center">
                    No data found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Pagination
            perPage={itemsPerPage}
            onPaginate={(page) => setCurrentPage(page)}
            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
            activePage={currentPage}
          />
        </>
      )}

      {/* Modal for Add/Edit */}
      <Modal show={showModal} handleClose={() => setShowModal(false)} centered>
        <div className="modern-modal">
          <h4 className="modal-title">
            {selectedItem?.bankprocess_id ? "Edit" : "Add"} Bank Processing
          </h4>

          <Formsy onValidSubmit={handleUpdate}>
            {/* Bank ID - show only if adding */}
            {!selectedItem?.bankprocess_id && (
              <div className="input-group">
                <label>
                  Bank ID <span style={{ color: "red" }}>*</span>
                </label>
                <InputField
                  name="bankprocess_bank_id"
                  value={selectedItem?.bankprocess_bank_id || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      bankprocess_bank_id: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {/* Profession */}
            <div className="input-group">
  <label>Profession</label>
  <FormsySelect
    name="bankprocess_profession_id"
    inputProps={{
      options: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ],
      placeholder: selectedItem?.bankprocess_profession_id ? undefined : "Select Profession",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ].filter(
        (option) => option.value === selectedItem?.bankprocess_profession_id
      ),
    }}
    onChange={(selected) =>
      setSelectedItem({
        ...selectedItem,
        bankprocess_profession_id: selected?.value || "",
      })
    }
  />
</div>


            {/* Login Fee */}
            <div className="input-group">
              <label>Login Fee</label>
              <InputField
                name="bankprocess_login_fee"
                value={selectedItem?.bankprocess_login_fee || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    bankprocess_login_fee: e.target.value,
                  })
                }
              />
            </div>

            {/* Charge Text */}
            <div className="input-group">
              <label>Charge Text</label>
              <InputField
                name="bankprocess_charge_text"
                value={selectedItem?.bankprocess_charge_text || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    bankprocess_charge_text: e.target.value,
                  })
                }
              />
            </div>

            {/* Status */}
            <div className="input-group">
  <label>Status</label>
  <FormsySelect
    name="bankprocess_status"
    inputProps={{
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ],
      placeholder: selectedItem?.bankprocess_status ? undefined : "Select Status",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ].filter((option) => option.value === selectedItem?.bankprocess_status),
    }}
    onChange={(selected) =>
      setSelectedItem({
        ...selectedItem,
        bankprocess_status: selected?.value || "1",
      })
    }
  />
</div>


            {/* Due Diligence */}
            <div className="input-group">
              <label>Due Diligence</label>
              <InputField
                name="duDiligeChar"
                value={selectedItem?.duDiligeChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    duDiligeChar: e.target.value,
                  })
                }
              />
            </div>

            {/* eStamp Charge */}
            <div className="input-group">
              <label>eStamp Charge</label>
              <InputField
                name="eStampChar"
                value={selectedItem?.eStampChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    eStampChar: e.target.value,
                  })
                }
              />
            </div>

            {/* MODT Charge */}
            <div className="input-group">
              <label>MODT Charge</label>
              <InputField
                name="modtChar"
                value={selectedItem?.modtChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    modtChar: e.target.value,
                  })
                }
              />
            </div>

            {/* NOI Charge */}
            <div className="input-group">
              <label>NOI Charge</label>
              <InputField
                name="noiChar"
                value={selectedItem?.noiChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    noiChar: e.target.value,
                  })
                }
              />
            </div>

            {/* CERSAI Charge */}
            <div className="input-group">
              <label>CERSAI Charge</label>
              <InputField
                name="cersaiChar"
                value={selectedItem?.cersaiChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    cersaiChar: e.target.value,
                  })
                }
              />
            </div>

            {/* Legal Tech Fee */}
            <div className="input-group">
              <label>Legal Tech Fee</label>
              <InputField
                name="legTechFee"
                value={selectedItem?.legTechFee || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    legTechFee: e.target.value,
                  })
                }
              />
            </div>

            {/* Account Handling */}
            <div className="input-group">
              <label>Account Handling</label>
              <InputField
                name="acHanChar"
                value={selectedItem?.acHanChar || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    acHanChar: e.target.value,
                  })
                }
              />
            </div>

            {/* Buttons */}
            <div className="button-group">
              <button
                className="btn-cancel"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="btn-update" type="submit">
                {selectedItem?.bankprocess_id ? "Update" : "Add"}
              </button>
            </div>
          </Formsy>
        </div>
      </Modal>
    </div>
  );
};

export default BreBankProcessingTable;
