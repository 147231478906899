import { gql } from "@apollo/client"

export const GET_TICKETS = (lead_id, ticket_id = 0, email_to = 'customer') => {
    return gql`
        query{
        get_email_tickets(lead_id:${lead_id}, ticket_id: ${ticket_id}, email_to: "${email_to}") {
            id
            lead_id
            ticket_id
            ticket_subject
            ticket_initial_body
            ticket_initial_to_emails
            created_date
            reply {
                id
                ticket_reply_body
                seen_at
                email_from
                created_date
            }
        }
    }`
}

export const MARK_TICKET_SEEN = (ticket_id) => {
    return gql`
        query{
        mark_ticket_seen(ticket_id: ${ticket_id})
    }`
}

export const CREATE_TICKETS = gql`
    mutation create_email_ticket($createEmailTicketInput: CreateEmailTicketInput!){
        create_email_ticket(createEmailTicketInput: $createEmailTicketInput)
    }
`;

export const GET_TICKETS_CONVERSATION = (ticket_id) => {
    return gql`
    query{
        get_all_tickets_from_freshdesk(ticket_id:${ticket_id})
    }`
}
