import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from "react";

import RadioBox from '../elements/Radiobox'
import InputField from "../elements/InputField";
import Select from 'react-select';
import { toast } from 'react-toastify';
import MasterService from "../../services/MasterService";
import { Navigate, NavLink, BrowserRouter as Router } from 'react-router-dom';
import { Add_BANK, SAVE_PRODUCT_DSA_CODE, FINEX_MASTER_LIST,PAYOUT_POINT_DETAILS } from "../../services/scheme.gql";
import {    
    UPDATE_BANK_ONBOARDING_PAYIN,
  } from "../../services/mis.gql";
import { executeGraphQLQuery, executeGraphQLMutation, executeGraphQLMutationFinex } from "../../common/partnerExecuteGraphQLQuery";
import DateFormate from 'dateformat';
import { useParams } from "react-router-dom";
import secureStorage from './../../config/encrypt';
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';
import { InventoryService } from "../../services/index.js";

const AddNewBank = (props) => {
    const param = useParams()
    const { handleTabClick } = props;
    const [formData, setFormData] = useState({});
    const [selectstatus, setselectstatus] = useState("");   
    const [errors, setErrors] = useState("");    
    const [uploadedFile, setUploadedFile] = useState(null);
    const [product_type, setProductType] = useState([]);
    const [selected_partner_id, setSelectedPartnerID] = useState(0);
    const [payout_points, setPayoutpoints] = useState([])
    const [bookingpoints, setBookingPoints] = useState([]);

    let scheme_view =  IS_USER_ACCESS_PAGE("payout", "scheme_view")?.is_edit_access;
   
    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Navigate to={"/login"} />);
        }
    }

    const afterLoginSetState = (loginResponse) => {
        setSelectedPartnerID(loginResponse.data.user_data.user_id);
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Navigate to={"/"} />);
        }
    }

    const handleInput = (e, key = "") => {
        const name = e.target ? e.target.name : key;
        const val = e.target ? e.target.value : e.value;    
      //  console.log("val",val);    
        setFormData({ ...formData, [name]: val })
        let newErrors = { ...errors };
        newErrors[name] = ('');        
     // console.log("newErrors",newErrors);
    }

    const getPayoutDataList =  async() => {
        const result = await executeGraphQLQuery(PAYOUT_POINT_DETAILS());
        const PayoutDataList = []
        result.data.points.map(point => {
           PayoutDataList.push({ id: point.id, name: point.booking_point });
       });
       setPayoutpoints(PayoutDataList)
   }

    
    useEffect(() => {
       
        if (props.schemedetailsData && props.schemedetailsData.scheme_name != "") {
            setFormData(props.schemedetailsData)
            //getSchemeCitities(props.schemedetailsData.id)
        }
        updateReduxData();
        getFinexMasterData();
        getPayoutDataList();
    }, [props.schemedetailsData]);

    

    const AddBankSave = async (e) => {
        e.preventDefault();
        if (param.id) {
           // updateScheme();
        } else {
            handleUploadFile();            
        }
    }  
    
    const base64EncodeId = (id) => {
        return btoa(id.toString());
    }

    const addBank = async (file_url) => {
       // const formDataNew = { ...formData };
       // formDataNew.status = selectstatus?.value;
        if(!file_url){
            const newErrors = {...errors}
            newErrors['bank_logo'] = ('Please upload bank logo');
            setErrors(newErrors)
            return false;
        }
        const data = {};
        const variables = {
            addeditbankargs: {
                bank_id: formData.bank_id ? formData.bank_id : 0,
                dsa_code: formData.dsa_code ? formData.dsa_code : "",
                bank_type: formData.bank_type ? formData.bank_type : "",
                name: formData.bank_name ? formData.bank_name : "",
                logo: file_url ? file_url : null,
                lang_id: formData.lang_id ? formData.lang_id : 1,
                status: formData.status ? formData.status : "1",
                is_partnership: formData.is_partner ? formData.is_partner : "0",                
            },
        };
        data.addeditbankargs = variables;
        if (validateForm()) {
            try {
               // console.log(variables);
                const result = await executeGraphQLMutationFinex(Add_BANK, variables);
                if (result && result.data && result.data.save_bank.msg) {
                   if (result?.data?.save_bank?.error) {
                        toast.error(result?.data?.save_bank || "Success");
                    } else {                       
                             //saveProductDsaCode(result?.data?.save_bank.bank_id);
                             //handleUpdateOnboardPoint(result?.data?.save_bank.bank_id);
                             toast.success(result?.data?.save_bank.msg || "Success");
                             if(formData.is_partnership==1){
                                props.handleTabClick("new_partner_ship")
                             }else{
                               // setTimeout(redirectpage("/bank/bank-onboarding"), 1000);
                             }
                            
                    }                 
                }
            } catch (error) {
                toast.error("Failed");
            }
        }
    }
    
    const saveProductDsaCode = async (bank_id) => {
         try{
            const prodvariables = {
                addproductdsacodeargs: {
                    bank_id: bank_id ? bank_id : 0,
                    dsa_code: formData.dsa_code ? formData.dsa_code : "",
                    product_type: formData.dsa_type ? formData.dsa_type : 0,
                }
             }
             const response = await executeGraphQLMutation(SAVE_PRODUCT_DSA_CODE, prodvariables);
             if(response && response.data && response.data.save_product_dsa_code){ 
                toast.success("Success");                
            }

         }catch (error) {
                toast.error("Failed");
            }
       }

   const handleUpdateOnboardPoint = async (bank_id) => {
        try {
         
          const variables = {
            addpayinconfigargs: {
              financer_id: bank_id ? bank_id : 0,
             // dsa_code: bankOnboardPoints[id].dsa_code,
              is_on_payin: formData.is_payin ? formData.is_payin : 0,
              payout_calc_at: formData.point_id ? formData.point_id : 0,
              payout_calc_on: formData.payout_calc_on ? formData.payout_calc_on : "",
            },
          };
    
          const response = await executeGraphQLMutation(
            UPDATE_BANK_ONBOARDING_PAYIN,
            variables
          );
    
          if (response.data?.save_on_payin) {
            toast.success(response.data?.save_on_payin);
          }
        } catch (error) {
          console.log("Error 🔻 ", error.message);
          toast.error(error.message);
        }
      };    
    
 const handleUploadFile = async () => {
        try {
            if (uploadedFile) {
                let NewformData = new FormData();
               
                NewformData.append("dealer_id", selected_partner_id);
                NewformData.append("upload_type", "customer_doc");
                NewformData.append("images", uploadedFile);                
                const response = await InventoryService.uploadFilesToS3(NewformData, {
                                 "Content-Type": "multipart/form-data",
                               });
                if (response.data.status === 200) {
                    const formdataNew = {...formData}
                    const fileUrl = response?.data?.data[0]?.file_url;
                    //console.log("fileUrl",fileUrl);
                    setFormData(prevFormData => ({ ...prevFormData, bank_logo: fileUrl }));

                   // console.log("formDataformDataformData",formData);
                    addBank(fileUrl);
                    return true;
                }
            }else {
                   toast.error("Please select file");
            }
        }catch (error) {
            console.log("Error 🔻 ", error.message);
            toast.error("Internal server error");
          }
    }
    
    const validateForm = () => {
        let validForm = true;
        let newErrors = { ...errors };
        let newFormData = { ...formData };
       // console.log("newFormData",newFormData);
//console.log("newErrors",newErrors);
        if (!newFormData['bank_type'] || newFormData['bank_type'] === '') {
            newErrors['bank_type'] = ('Please select Bank type');
            validForm = false;
        }
        if (!newFormData['bank_name'] || newFormData['bank_name'] === '') {
            newErrors['bank_name'] = ('Please Enter Bank Name');
            validForm = false;
        }
        // if (!newFormData['bank_logo'] || newFormData['bank_logo'] === '') {
        //     newErrors['bank_logo'] = ('Please upload bank logo');
        //     validForm = false;
        // }
        // if (!newFormData['dsa_code'] || newFormData['dsa_code'] === '') {
        //     newErrors['dsa_code'] = ('Please Enter dsa code');
        //     validForm = false;
        // }
        // if (!newFormData['dsa_type'] || newFormData['dsa_type'] === '') {
        //     newErrors['dsa_type'] = ('Please Select dsa type');
        //     validForm = false;
        // }
        if (!newFormData['is_partner'] || newFormData['is_partner'] === '') {
            newErrors['is_partner'] = ('Please alow partner bank');
            validForm = false;
        }
        // if (!newFormData['is_payin'] || newFormData['is_payin'] === '') {
        //     newErrors['is_payin'] = ('Please alow is payin');
        //     validForm = false;
        // }
        // if (!newFormData['point_id'] || newFormData['point_id'] === '') {
        //     newErrors['point_id'] = ('Please select payout calc at');
        //     validForm = false;
        // }
        // if (!newFormData['payout_calc_on'] || newFormData['payout_calc_on'] === '') {
        //     newErrors['payout_calc_on'] = ('Please select payout calc on');
        //     validForm = false;
        // }
        setErrors(newErrors);
        console.log("newErrors",newErrors);
        return validForm;
    };
    
    
    const banktypes = [
        { value: 'public_sector', label: "Public Sector"},
        { value: 'private_sector', label: "Private Sector"},
        { value: 'SFB', label: "SFB"},
        { value: 'rural_banks', label: "Rural Bank"},
        { value: 'foriegn_banks', label: "Foreign Bank"},
        { value: 'NBFC', label: "NBFC"},
        { value: 'HFC', label: "HFC"},
        { value: 'payments_bank', label: "Payments Bank"}
    ]

    const payoutCalcOn = [
        { value: 'login_data', label: "Login Date"},
        { value: 'senction_date', label: "Sanction Date"},
        { value: 'disbursal_date', label: "Disbursal Date"},
        { value: 'padd_date', label: "Padd Date"},
        { value: 'transaction_date', label: "Transaction Date"},
        { value: 'mis_date', label: "MIS Date"},
        { value: 'payin_date', label: "Payin Date"},        
    ]


    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bookingPointsData = []
            const producttypedata = [];
            const data = masterData.data.masterdata;
            const fulfillmenttype = data.fulfillment_type && data.fulfillment_type.length > 0 ? data.fulfillment_type : []
            const products = data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];

            payout_points.map(point => {
                bookingPointsData.push({ id: point.lead_status, name: point.point_name });
            });
            setBookingPoints(bookingPointsData);
            
            if (products && products.length > 0) {
                products.map(ptype => {
                    producttypedata.push({ id: ptype.id, name: ptype.label })
                })
                setProductType(producttypedata)
            }
        }
    } 
      

    const redirectpage = (url) => {
        window.location.href = url
    }

    const handleDsaType = (e,n) => {
        setFormData({ ...formData, [n]: e.id})
    }

    const handlePayoutCalc = (e,n) => {
        setFormData({ ...formData, [n]: e.id})
    }

    const handleFileChange = (event) => {       
        setUploadedFile(event.target.files[0]);         
       };
     

    const handleCheckBoxDefault = (n, e) => {
        const checked = e.target.checked;      
        switch (n) {
            case 'is_partner':
                setFormData({ ...formData, [n]: checked ? '1' : '0' });
                break;
            case 'is_payin':
                setFormData({ ...formData, [n]: checked ? '1' : '0' });
                break;
        }
    };

    return (
        <>
            <div className="card">

                <div className="card-body">
                   
                    <div className="row">
                        <div className="col-sm-3">

                            <label>Bank Type <span className="required" style={{color: 'red'}}>*</span></label>
                            <Select
                                id="bank_type"                                
                                onChange={(e) => handleInput(e, 'bank_type')}
                                options={banktypes}
                                name="bank_type"                               
                                value={banktypes.filter(({ value }) => value === formData.bank_type)}                       
                                dataerror={errors.bank_type}
                                placeholder = "Please select Bank type"
                                validationreq = "true"
                            />
                             {errors.bank_type &&
                                    <div className="error show" >{errors.bank_type}</div>
                                }
                        </div>

                        <div className="col-sm-3">
                       <label>Upload Bank Logo <span className="required" style={{color: 'red'}}>*</span></label>
                       <div className="misinput">
                       <input
                           type="file"
                           name="bank_logo"
                           id="bank_logo"
                           placeholder="Upload Bank Logo"
                           label="Upload PNG,JPG"
                           onChange={(e) => handleFileChange(e)}
                  />

                              {errors.bank_logo &&
                                    <div className="error show" >{errors.bank_logo}</div>
                                }

                  </div>
                  
                </div>

                        <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "bank_name",
                                    type: "text",
                                    name: "bank_name",
                                    label: ('Bank Name'),
                                    value: formData.bank_name || '',
                                    placeholder: "Please Enter Bank Name",
                                    dataerror: errors.bank_name || '',
                                    validationreq: "true",
                                    label_before_input: "true"

                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        {/* <div className="col-sm-3">
                        <label>Payout Calc At <span className="required" style={{color: 'red'}}>*</span></label>
                        <Select
                                    id="point_id"
                                    onChange={(e) => handlePayoutCalc(e,'point_id')}
                                    options={payout_points}
                                    label={"Payout Point"}
                                    name="point_id"
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                    dataerror={errors.point_id}
                                    value={payout_points.filter(({ id }) => id === formData.point_id)}
                                    // isDisabled = {is_disabled}                                    
                                />
                                {errors.point_id &&
                                    <div className="error show" >{errors.point_id}</div>
                                } 
                         </div>

                         <div className="col-sm-3">
                        <label>Payout Calc On<span className="required" style={{color: 'red'}}>*</span></label>                       
                           <Select
                                id="payout_calc_on"                                
                                onChange={(e) => handleInput(e, 'payout_calc_on')}
                                options={payoutCalcOn}
                                name="payout_calc_on"                               
                                value={payoutCalcOn.filter(({ value }) => value === formData.payout_calc_on)}                       
                                dataerror={errors.payout_calc_on}
                                placeholder = "Please Payout Calc On"
                                validationreq = "true"
                            />
                             {errors.payout_calc_on &&
                                    <div className="error show" >{errors.payout_calc_on}</div>
                                }     
                         </div>

                        <div className="col-sm-3">

                            <label>DSA Code Type<span className="required" style={{color: 'red'}}>*</span></label>
                            <Select
                                id="dsa_type"
                                name="dsa_type"
                                options={product_type}
                                //value={calculation.filter(({ value }) => value === formData.scheme_for)}
                                onChange={(e) => handleDsaType(e, 'dsa_type')}
                                dataerror={errors.dsa_type}
                                value={product_type.filter(({ id }) => id === formData.dsa_type)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            >
                                
                            </Select>
                            {errors.dsa_type &&
                                    <div className="error show" >{errors.dsa_type}</div>
                                }
                        </div>

                        <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "dsa_code",
                                    type: "text",
                                    name: "dsa_code",
                                    label: ('DSA Code'),
                                    value: formData.dsa_code || '',
                                    placeholder: "Please Enter DSA Code ",
                                    dataerror: errors.dsa_code || '',
                                    validationreq: "true",
                                    label_before_input: "true"

                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div> */}
                        
                        <div className="col-sm-3">
                            <label>Is this a partner bank<span className="required" style={{color: 'red'}}>*</span></label><br />
                            <input
                                type="checkbox"
                                name="is_partner"
                                label="Is Partner Bank"
                                id={'is_partner'}
                                key={'is_partner'}
                                value={formData.is_partner}
                                checked={formData.is_partner == '1' ? true : false}
                                onClick={(e) => handleCheckBoxDefault('is_partner', e)}
                            />
                            {errors.is_partner &&
                                    <div className="error show" >{errors.is_partner}</div>
                                }
                        </div>

                        {/* <div className="col-sm-3">
                            <label>% Payin type bank<span className="required" style={{color: 'red'}}>*</span></label><br />
                            <input
                                type="checkbox"
                                name="is_payin"
                                label="Is Payin type Bank"
                                id={'is_payin'}
                                key={'is_payin'}
                                value={formData.is_payin}
                                checked={formData.is_payin == '1' ? true : false}
                                onClick={(e) => handleCheckBoxDefault('is_payin', e)}
                            />
                            {errors.is_payin &&
                                    <div className="error show" >{errors.is_payin}</div>
                                }
                        </div> */}
                       
                    </div>

                    <div>
                        {formData.id && formData.id > 0 ?
                            <div className="text-center">
                                {scheme_view === 1 && (
                                    <button style={{ marginBottom: 10, marginTop: 20 }}
                                   // onClick={(e) => updateScheme(e)}
                                    className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Update</b>}</button>
                                )}
                               
                            </div>
                            :
                            <div className="text-center">
                                <button style={{ marginBottom: 10, marginTop: 20 }}
                                    onClick={(e) => AddBankSave(e)}
                                    className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Create</b>}</button>
                            </div>
                        }

                    </div>


                </div>
            </div>          


        </>
    )
};


export default AddNewBank;

