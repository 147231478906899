import React from "react";
import DateFormate from "dateformat";
import { PAYOUT_STATUS_LIST } from "../../config/constants";
import Pagination from "../elements/pagination";

const PreEligibleTableContentInPartner = ({
  partnerList,
  paginationData,
  filterData,
  onPaginate,
}) => {
  return (
    <div>
      <div className="clearfix" style={{ marginTop: "25px" }}>
        <div className="table-responsive">
          <table className="table table-bordered tablefontsize">
            <thead>
              <tr>
                <th>Partner Details</th>
                <th>Disb.Details</th>
                <th>Calculated</th>
                <th>Calc.Date</th>
                <th>Reason</th>
                <th>Payout Amount</th>
              </tr>
            </thead>
            <tbody>
              {partnerList.length > 0 ? (
                <>
                  {partnerList.map((partner, index) => (
                    <>
                      <tr key={index}>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Lead ID : <span> {partner.lead_id}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Partner Code : <span> AMK{partner.partner_id}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Customer Name :{" "}
                            <span> {partner.customer_name}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Partner Name : <span>{partner.partner_name}</span>
                          </div>
                          {partner.parent_partner_id > 0 && (
                            <div style={{ opacity: 0.6 }}>
                              Parent Partner :{" "}
                              <span>
                                {partner.parent_partner_name} (
                                {partner.parent_partner_id})
                              </span>
                            </div>
                          )}
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Fulfillment Type :{" "}
                            <span> {partner.fulfillment_type}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Product : <span> {partner.product}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Bank : <span> {partner.bank}</span>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            <span>
                              Disbursed:{" "}
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_disbursal_amount
                              )}{" "}
                              <br></br>
                              Sanctioned:{" "}
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_sanction_amount
                              )}
                            </span>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            <span>
                              {partner.disbursal_date &&
                              partner.slab_date == "disbursal_date"
                                ? partner.disbursal_date
                                  ? DateFormate(
                                      new Date(partner.disbursal_date),
                                      "dS mmm yyyy"
                                    )
                                  : null
                                : partner.sanction_date
                                ? DateFormate(
                                    new Date(partner.sanction_date),
                                    "dS mmm yyyy"
                                  )
                                : null}
                            </span>
                            <div class="tooltip">
                              <div
                                style={{ display: "flex" }}
                                class="btn btn-default"
                              >
                                <i className="ic-info-icon1 icons-normal"></i>
                              </div>
                              <div class="tooltiptext">
                                {partner.slab_date ? (
                                  <div>
                                    Slab Date :{" "}
                                    {partner.slab_date.replace("_", " ")}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {partner.slab_amount ? (
                                  <div>
                                    Slab Amount :{" "}
                                    {partner.slab_amount.replace("_", " ")}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            <span>
                              {PAYOUT_STATUS_LIST[partner.payout_status]}
                            </span>
                            <br></br>
                            {partner.points ? (
                              <span>({partner.points})</span>
                            ) : null}
                          </div>
                        </td>

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            <span>
                              {new Intl.NumberFormat("en-IN").format(
                                partner.total_payout_amount
                              )}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  <tr key={"pagination"}>
                    <td colSpan={4}>
                      <Pagination
                        pageCount={paginationData.totalpage}
                        activePage={filterData?.page_no || 1}
                        onPaginate={(page_no) =>
                          onPaginate(page_no, filterData.status)
                        }
                      />
                    </td>
                    <td>Total: {paginationData.totalrecords}</td>
                  </tr>
                </>
              ) : (
                <tr key={"no data found"}>
                  <td colSpan={4}>No Data Found!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PreEligibleTableContentInPartner;
