import React, { useEffect, useState } from "react";
import { convertJsonToCsv, downloadFile } from "../../../helpers/helpers";
import { RotatingLines } from "react-loader-spinner";
import Modal from "../../elements/partnerModal";
import Formsy from "formsy-react";
import FormsySelect from "../../elements/FormsySelect";
import Offershare from '../Offershare';

import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const MIN_TENURE_YEAR = 5;
const MIN_LOAN_AMOUNT = 500000;

const BreOfferSection = ({
  checkBankOffers,
  recalculateOffer,
  isLoadingOffer,
  isOfferFetched = false,
}) => {

  const [showModal, setShowModal] = useState(false);
  const [allFees, setAllFees] = useState({});
  const [checkedOffers, setCheckedOffers] = useState([]);
  const [loanDetails, setLoanDetails] = useState({});
  const [selectedBank, setSelectedBank] = useState([]);
  const [isEmailComms, setEmailComms] = useState(false);
  const [offerShareTemplate, setOffferShareTemplate] = useState("");
  const [emailOfferModal, setEmailOfferModal] = useState(false);

  const { lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));


  useEffect(() => {
    if (checkBankOffers.length) {
      const listOfLoanAmount = checkBankOffers.map(
        (offer) => offer.maxEligibalLoanAmount
      );
      const listOfMaxLoanAmount = checkBankOffers.map((offer) =>
        offer.eligibalLoanAmount.replaceAll(",", "")
      );
      const listOfTenure = checkBankOffers.map((offer) => offer.finalMaxTenure);
      const listOfMaxTenure = checkBankOffers.map((offer) => offer.maxTenure);
      const maxEligibleLoan = Math.max(...listOfLoanAmount);
      const maxLoan = Math.max(...listOfMaxLoanAmount);
      const maxTenure = Math.max(...listOfTenure);
      const tenure = Math.max(...listOfMaxTenure);

      setLoanDetails((prev) => ({
        ...prev,
        maxEligibleLoanAmount: maxEligibleLoan,
        requiredLoanAmount: maxEligibleLoan,
        minLoanAmount: MIN_LOAN_AMOUNT,
        requiredTenure: maxTenure,
        maxLoan,
        // tenure,
        // maxTenure,
      }));
    }
  }, [checkBankOffers]);

  const getMaxTenureOptions = (minYear, maxYear) => {
    const options = [];
    for (let i = minYear; i <= maxYear; i++) {
      options.push({
        id: i,
        label: `${i} Years`,
        value: i,
      });
    }
    return options;
  };


  const getDateAndTimeStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };

  const handleExportCSVFile = () => {
    const offersList =
      checkedOffers.length > 0 ? checkedOffers : checkBankOffers;
    const requiredOfferCol = JSON.parse(JSON.stringify(offersList)).map(
      (offer) => {
        offer.bankId && delete offer.bankId;
        offer.bankLogo && delete offer.bankLogo;
        offer.propValue && delete offer.propValue;
        offer.documentSet && delete offer.documentSet;
        offer.messages = offer.messages?.join("||").replaceAll(",", "");
        offer.processingText = offer.processingText?.replaceAll(",", "");
        offer.login_fee = offer.login_fee?.replaceAll(",", "");
        offer.ac_handling_charge = offer.ac_handling_charge?.replaceAll(
          ",",
          ""
        );
        offer.legal_technical_fee = offer.legal_technical_fee?.replaceAll(
          ",",
          ""
        );
        offer.e_stamp_charge = offer.e_stamp_charge?.replaceAll(",", "");
        offer.due_diligence_charge = offer.due_diligence_charge?.replaceAll(",","");
        offer.CERSAI_charge = offer.CERSAI_charge?.replaceAll(",", "");
        offer.MODT_charge = offer.MODT_charge?.replaceAll(",", "");
        offer.NOI_charge = offer.NOI_charge?.replaceAll(",", "");
        offer.netMonthlyIncome = offer.netMonthlyIncome?.replaceAll(",", "");
        offer.eligibalLoanAmount = offer.eligibalLoanAmount?.replaceAll(
          ",",
          ""
        );
        offer.monthlyEMI = offer.monthlyEMI?.replaceAll(",", "");

        return { ...offer };
      }
    );
    const csvData = convertJsonToCsv(requiredOfferCol);
    downloadFile(csvData, "text/csv", `BRE_OFFERS_${getDateAndTimeStr()}.csv`);
  };

  const handleAmountSlider = (event) => {
    const { value } = event.target;
    setLoanDetails((prev) => ({
      ...prev,
      maxLoan: +value,
    }));
  };

  const send_offer_mail = () => {
    setEmailOfferModal(true);
    document.body.classList.add("overflow-hidden")
    checkBankOffers = checkBankOffers.filter(data => selectedBank.includes(data.bankId));
    if(offerShareTemplate) setEmailComms(!isEmailComms);
    
  };

  const handleViewFeeModal = (index) => {
    const feesObj = {};
    const selectedOffer = checkBankOffers[index];
    feesObj["Processing Fees"] = selectedOffer?.processingText || "N/A";
    feesObj["Login Fee"] = selectedOffer?.login_fee || "N/A";
    feesObj["Account handling charges/Other Charges"] =
      selectedOffer?.ac_handling_charge || "N/A";
    feesObj["Legal and Technical Fee"] =
      selectedOffer?.legal_technical_fee || "N/A";
    feesObj["E-Stamp Charges"] = selectedOffer?.e_stamp_charge || "N/A";
    feesObj["Due Diligence Charges"] =
      selectedOffer?.due_diligence_charge || "N/A";
    feesObj["CERSAI Charges"] = selectedOffer?.CERSAI_charge || "N/A";
    feesObj["MODT Charges"] = selectedOffer?.MODT_charge || "N/A";
    feesObj["NOI Charges"] = selectedOffer?.NOI_charge || "N/A";
    setAllFees(feesObj);
    setShowModal(true);
    document.body.classList.add("overflow-hidden");
  };

  const handleCloseFeeModal = () => {
    setShowModal(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleCloseOfferCheckModal = () => {
    setEmailOfferModal(false);
    document.body.classList.remove("overflow-hidden");
  };  

  const handleOfferCheck = (event, index) => {
    if (event.target) {
      const { checked } = event.target;
      const existingList = [...checkedOffers];
      if (checked) {
        const filtered = checkBankOffers[index];
        existingList.push({ ...filtered, offerIndex: index });
      } else {
        const unCheckedIndex = existingList.findIndex(
          (obj) => obj.offerIndex === index
        );
        if (unCheckedIndex !== -1) {
          existingList.splice(unCheckedIndex, 1);
        }
      }
      setCheckedOffers(existingList);
    }
  };

  const handleTenureChange = (data) => {
    setLoanDetails((prev) => ({
      ...prev,
      tenure: data.value,
    }));
  };

  const handleRecalculate = () => {
    const { maxLoan, tenure } = loanDetails;
    if (!tenure) {
      toast.error("Please Select Tenure First.")
      return;
    }
    const reCalculate = {
      loan_amount: `${maxLoan}`,
      max_tenure: tenure,
    };
    recalculateOffer(reCalculate);
  };

  useEffect(() => {
    const rangeInputs = document.querySelectorAll(
      'input[type="range"].slider-progress'
    );
    rangeInputs.forEach((e) => {
      e.style.setProperty("--value", e.value);
      e.style.setProperty("--min", e.min === "" ? "0" : e.min);
      e.style.setProperty("--max", e.max === "" ? "100" : e.max);
      e.addEventListener("input", (event) => {
        e.style.setProperty("--value", event.target.value);
      });
    });
    return () => {
      rangeInputs.forEach((e) => {
        e.removeEventListener("input", () => {});
      });
    };
  }, [loanDetails]);

  const dataNotProvided = [];

  if (!lead.leadDetail?.loan_type) {
    dataNotProvided.push("Loan Type");
  }
  
  if (!lead.leadDetail.lead_details?.loan_amount) {
    dataNotProvided.push("Loan Amount");
  }
   
  if (!lead.leadDetail.lead_details?.profession) {
    dataNotProvided.push("Profession");
  }

  if (!lead.leadDetail.lead_details?.cibil_score) {
    dataNotProvided.push("Cibil Score");
  }

  if (!lead.leadDetail.customer.dob) {
    dataNotProvided.push("Date of Birth");
  }

   if (lead.leadDetail.lead_details?.profession === 1) {
     if (!lead.leadDetail.lead_details?.monthly_salary && lead.leadDetail.lead_details?.salary_credit_mode == 1) {
       dataNotProvided.push("Monthly Salary");
     }
     if (!lead.leadDetail.lead_details?.gross_monthly_salary && lead.leadDetail.lead_details?.salary_credit_mode == 1) {
       dataNotProvided.push("Gross Monthly Salary");
     }
     if (!lead.leadDetail.lead_details?.in_hand_monthly_cash_salary && lead.leadDetail.lead_details?.salary_credit_mode == 2) {
       dataNotProvided.push("In Hand Monthly Cash Salary");
     }
     if (!lead.leadDetail.lead_details?.company_address && lead.leadDetail.lead_details?.salary_credit_mode == 2) {
       dataNotProvided.push("Company Address");
     }
     
     if (!lead.leadDetail.lead_details?.work_experience) {
       dataNotProvided.push("Work Experience");
     }
   }
   if (lead.leadDetail.lead_details?.profession === 2) {
     if (!lead.leadDetail.lead_details?.annual_income) {
       dataNotProvided.push("annual_income");
     }
    
   }
   if (lead.leadDetail.lead_details?.profession === 3) {
     
     if (lead.leadDetail.customer.offer_type == "itr") {
       if (lead.leadDetail.customer.offer_itr_details.length === 0) {
         dataNotProvided.push(`NPAT 1`);
         dataNotProvided.push(`NPAT 2`);
         dataNotProvided.push(`Depreciation `);
         dataNotProvided.push(`Depreciation`);
         dataNotProvided.push(`TAX pain`);
         dataNotProvided.push(`Interest`);
       }

       lead.leadDetail?.customer?.offer_itr_details.map((item) => {
         if (!item.npat) {
           dataNotProvided.push(`NPAT ${item.itr_year}`);
         }
         if (!item.depreciation) {
           dataNotProvided.push(`Depreciation ${item.itr_year}`);
         }
         if (!item.interest) {
           dataNotProvided.push(`Interest ${item.itr_year}`);
         }
         if (!item.tax_paid) {
           dataNotProvided.push(`Tax Pain ${item.itr_year}`);
         }
       });
     }

     if(lead.leadDetail.customer.offer_type == "bank"){
      lead.leadDetail?.customer?.offer_bank_details.map((item,index) => {
        if (!item.account_type) {
          dataNotProvided.push(`Account Type ${index}`);
        }
        if (!item.amount) {
          dataNotProvided.push(`Amount ${index}`);
        }
      });
     }
   }
   if(lead.leadDetail.co_applicant.length){
    lead.leadDetail.co_applicant.map((co_app)=>{
      if(co_app.ca_profession == 1){
        if (!co_app?.ca_monthly_salary) {
          dataNotProvided.push("Co-Applicant Monthly Salary");
        }
        if (!co_app.ca_gross_monthly_salary) {
          dataNotProvided.push("Co Applicant Gross Monthly Salary");
        }
      }

      if(co_app.ca_profession == 2){
        if (!co_app?.ca_annual_income) {
          dataNotProvided.push("Co-Applicant Annual Income");
        }
       
      }

      if(co_app.ca_profession == 3){
        if (co_app?.ca_offer_type == "itr") {
         
          co_app.ca_offer_itr_details.map((item) => {
            if (!item.npat) {
              dataNotProvided.push(`Co Applicant NPAT ${item.itr_year}`);
            }
            if (!item.depreciation) {
              dataNotProvided.push(`Co Applicant Depreciation ${item.itr_year}`);
            }
            if (!item.interest) {
              dataNotProvided.push(`Co Applicant Interest ${item.itr_year}`);
            }
            if (!item.tax_paid) {
              dataNotProvided.push(`Co Applicant Tax Pain ${item.itr_year}`);
            }
          });
        }

        if(co_app?.ca_offer_type == "bank"){
          co_app.ca_offer_bank_details.map((item,index) => {
            if (!item.account_type) {
              dataNotProvided.push(`Co Applicant Account Type ${index}`);
            }
            if (!item.amount) {
              dataNotProvided.push(`Co Applicant Amount ${index}`);
            }
          });
         }
       
      }
    })
   }


  return (
    <>
      <div className="doument-upload-left-panel">
        {checkBankOffers.length > 0 && loanDetails?.requiredLoanAmount > 0 && (
          <>
            {/* <div className="row">
              <div className="col-md-8">
              </div>
              <div className="col-md-4">
                {checkBankOffers.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {checkedOffers?.length ? (
                      <div
                        onClick={send_offer_mail}
                        style={{
                          margin: "10px",
                          "font-weight": "bold",
                          cursor: "pointer",
                        }}
                      >
                        Send to Customer
                      </div>
                    ) : (
                      ""
                    )}
                    <div onClick={handleExportCSVFile}>
                      <img
                        src="https://static.ambak.com/saathi/1731116440307.png"
                        alt="download"
                        width={30}
                        height={30}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div> */}
             <div style={{display:"flex",justifyContent:"space-between"}}>
            <div>
              {/* <div className="col-md-8">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <label>Desired Amount</label>
                  </div>
                  <div>
                    <label>₹ {getNumberFormat_IN(loanDetails?.maxLoan)}</label>
                  </div>
                </div>
                <div className="box" style={{ marginBottom: "10px" }}>
                  <div className="range" style={{ width: "100%" }}>
                    <input
                      step={1000}
                      type="range"
                      id="loan_amount"
                      name="loan_amount"
                      onChange={handleAmountSlider}
                      className="styled-slider slider-progress"
                      min={loanDetails?.minLoanAmount}
                      max={loanDetails?.maxEligibleLoanAmount}
                      value={loanDetails?.maxLoan}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.minLoanAmount)}
                    </label>
                  </div>
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.maxEligibleLoanAmount)}
                    </label>
                  </div>
                </div>
              </div> */}
           

            <div style={{display:"flex",gap:"20px"}}>
              <div style={{ marginTop: "10px",width:"200px" }}>
                <Formsy>
                  <fieldset className="single-select">
                    <FormsySelect
                      style={{ width: "200px" }}
                      name={"requiredTenure"}
                      id={"requiredTenure"}
                      inputProps={{
                        options: getMaxTenureOptions(
                          MIN_TENURE_YEAR,
                          loanDetails?.requiredTenure,
                        ),
                        placeholder: "Pick Tenure",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        isDisabled: false,
                        value: getMaxTenureOptions(
                          MIN_TENURE_YEAR,
                          loanDetails?.requiredTenure
                        ).filter((obj) => obj.id == loanDetails.tenure),
                      }}
                      required={false}
                      onChange={(data) => handleTenureChange(data)}
                    />
                  </fieldset>
                </Formsy>
              </div>

              <div>
                <div className="btn-save-remarks">
                  <div
                    className="bankofferrecalculate"
                    onClick={handleRecalculate}
                  >
                    Recalculate
                  </div>
                </div>
              </div>
              </div>
             
            </div>
            <div>
          {checkBankOffers.length > 0 && (
            <div style={{ display: "flex",alignItems:"center", justifyContent: "end" }}>
              {checkedOffers?.length ? <div onClick={send_offer_mail} style={{display:"flex",color:"#473391", margin: "10px", "font-weight": "bold",cursor:"pointer",border:"1px solid #473391",padding:"10px",borderRadius:"10px",gap:"10px"}}><img src="https://static.ambak.com/saathi/1732013271288.png" alt="send" width={20} height={20} />Send to Customer</div>:""}
              <div onClick={handleExportCSVFile}>
                <i className="ic-upload offeruploadbre"></i>
              </div>
            </div>
          )}
        </div>
        </div>
          </>
        )}

        

        <div className="breoffercard">
          <div className="breofferheading">
            {checkBankOffers?.length === 0 &&
              !isLoadingOffer && !isOfferFetched &&
              <div className="breNoOffersErrors">

{
                 dataNotProvided.length > 0 && (
                  <>
                    <h3 className="noOfferHeading">
                      Offer could not be generated as the following fields are incomplete:
                    </h3>

                    <div className="onFieldList">
                      {
                        dataNotProvided.map(
                          (item)=>
                            <div className="noFieldsItem"> <span style={{fontSize:"18px",marginRight:"10px"}}>{"\u003E"}</span>{item}</div>
                        
                        )
                      }
                    </div>

                    <div className="noOfferWarning">Please fill all fields with (✦) symbol marked against them</div>
                  </>
                 )
                }
              </div>
}
              {/* // "Please fill all the required fields to get offers !!!!"} */}
            {checkBankOffers?.length === 0 &&
              !isLoadingOffer && isOfferFetched &&
              "No offer found for any lenders"}
          </div>

          {isLoadingOffer && (
            <div style={{ textAlign: "center" }}>
              <RotatingLines
                visible={true}
                height="80"
                width="80"
                strokeColor="grey"
                strokeWidth="4"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <p>Please wait while generating offer</p>
            </div>
          )}

          {checkBankOffers?.map((offer, index) => (
            <div key={index} className="offerbox">
              <div className="offercardflex">
                <div className="offerimg">
                  <img src={offer.bankLogo} alt="" />
                </div>

                <div className="offerwidth">
                  <div className="offerlistingcard">
                    <div>
                      <input
                        type="checkbox"
                        onClick={(event) => handleOfferCheck(event, index)}
                      />
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Eligible Loan Amount</div>
                      <div className="breheading">
                        ₹ {offer.eligibalLoanAmount}
                      </div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Monthly EMI</div>
                      <div className="breheading">₹ {offer.monthlyEMI}</div>
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Interest Rate</div>
                      <div className="breheading">{offer.minRoi} %</div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Tenure</div>
                      <div className="breheading">{offer.maxTenure} years</div>
                    </div>
                  </div>
                </div>

                <div
                  className="breviewfees"
                  onClick={() => handleViewFeeModal(index)}
                >
                  View Fees
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="view-timeline-popup">
          <Modal show={emailOfferModal} handleClose={handleCloseOfferCheckModal} width={1000}>
            <Offershare checkedOffers={checkedOffers.slice(0,3)} />
          </Modal>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={showModal} handleClose={handleCloseFeeModal}>
          <h2>Fees</h2>
          {Object.keys(allFees)?.map((feeKey, index) => (
            <div className="modal-body" key={index}>
              <div className="offerlistingfees">
                <div className="feesheading" style={{ fontSize: 14 }}>
                  <ul style={{ paddingLeft: "20px" }}>
                    <li style={{ listStyle: "initial" }}>
                      {feeKey} :{" "}
                      <span className="feessubheading">{allFees[feeKey]}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </Modal>
      </div>
    </>
  );
};

export default BreOfferSection;
