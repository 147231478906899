import { LEADS_HISTORY_QUERY, GET_ALL_FOLLOWUP } from "../../../services/leads.gql";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import messageinfo from"../../../webroot/images/messageicon.svg";
import React, { useState, useEffect, useRef } from "react";
import moreinfo from"../../../webroot/images/document.svg";
import personinfo from"../../../webroot/images/person.svg";
import { maskString } from "../../../helpers/helpers";
import ReactAudioPlayer from "react-audio-player";
import { useApolloClient } from "@apollo/client";
import { encode } from '../../../config/crypto'
import { useSelector } from "react-redux"
import DateFormate from "dateformat";
import moment from "moment";

const ActivityHistory = ({activityHistoryTab}) =>{
  const [activityHistory, setActivityHistory] = useState({});
  const [renderingCounts, setRenderingCounts] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [openIndex, setOpenIndex] = useState(null);
  const [duration, setDuration] = useState(0);
  const client = useApolloClient();
  const audioRef = useRef(null);

  const isDifferent = (value1, value2) => {
    // Check if both are objects
    if (typeof value1 === "object" && typeof value2 === "object" && value1 !== null && value2 !== null) {
      // Check if the number of keys is different
      const keys1 = Object.keys(value1);
      const keys2 = Object.keys(value2);
      if (keys1.length !== keys2.length) return true;
  
      // Recursively compare each key
      for (const key of keys1) {
        if (isDifferent(value1[key], value2[key])) {
          return true;
        }
      }
      return false; // All keys and values are equal
    }
  
    // Compare primitive values
    return value1 !== value2;
  };

  const updatedValues = (prev,curr) => {
    if(!prev && !curr){
      return {};
    }

    const prevData = prev ? JSON.parse(prev) : {};
    const currData = curr ? JSON.parse(curr): {};
    const differences = {};

    // Find keys that are different between prevData and currData
    const allKeys = new Set([...Object.keys(prevData), ...Object.keys(currData)]);

    allKeys.forEach((key) => {
      const val1 = prevData[key];
      const val2 = currData[key];
    
      // Function to format date into DD-MM-YYYY
      const formatDate = (date) => {
        if (typeof date === "number" || !isNaN(Number(date))) {
          return date; // Ignore pure numbers
        }
        return moment(date, moment.ISO_8601, true).isValid() ? moment(date).format("DD-MM-YYYY HH:mm") : date;
      };
    
      // Use isDifferent to check for differences
      if (isDifferent(val1, val2)) {
        differences[key] = {
          prev: val1 !== undefined
            ? (key === "pancard_no" || key === "mobile")
              ? maskString(val1, { unmaskedStart: 1, unmaskedEnd: 4 })
              : typeof val1 === "object" && val1 !== null
              ? JSON.stringify(val1) // Convert objects to string for readability
              : formatDate(val1)
            : null,
          current: val2 !== undefined
            ? (key === "pancard_no" || key === "mobile")
              ? maskString(val2, { unmaskedStart: 1, unmaskedEnd: 4 })
              : typeof val2 === "object" && val2 !== null
              ? JSON.stringify(val2) // Convert objects to string for readability
              : formatDate(val2)
            : null,
        };
      }
    });
    return differences;
  }

  const getActivityHistoryData = async (lead_id, user_id) => {
    try {
      const [allFollowsData, leadsHistoryData] = await Promise.all([
        executeGraphQLQuery(GET_ALL_FOLLOWUP(lead_id, user_id), client),
        executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client),
      ]);
      setActivityHistory({
        followupdata: allFollowsData?.data?.get_followup,
        loanhistorydata: leadsHistoryData?.data?.get_history,
      });
    } catch (error) {
      console.error("Error fetching activity history data:", error);
    }
  };

  const { lead } = useSelector(({ lead, masterdata }) => ({
      lead,
      masterdata,
      leadDetail: lead?.leadDetail?.id,
      remarksHistory: lead.remarksHistory || [],
    })
  );

  useEffect(() => {
    const lead_id = lead?.leadDetail?.id || "";
    const user_id = lead?.leadDetail?.customer?.customer_id;
    if (lead_id && user_id) {  
      getActivityHistoryData(lead_id, user_id);
    }
  }, [lead?.leadDetail?.id, lead?.leadDetail?.customer?.customer_id, renderingCounts]);

  const reference_id = encode(lead?.leadDetail?.ref_lead_id);

  const tab_status = {
    Verified: { is_completed: true, is_active: true },
    New_Lead: { is_completed: false, is_active: false },
    Reject: { is_completed: false, is_active: false },
  };

  const { userInfo } = useSelector(({ lead, user }) => {
    return {
      remarksHistory: lead.remarksHistory || [],
      leadDetail: lead.leadDetail,
      userInfo: user.user_information,
    }
  });
  let userDetail=userInfo && JSON.parse(userInfo)
  let user_id = userDetail && userDetail.id || 0;

  const handleRenderComponent = () => {
    setRenderingCounts((current) => current + 1);
  }

  const handleToggleClick = (index, section) => {
    if (openIndex === index && activeSection === section) {
      setOpenIndex(null);
      setActiveSection(null);
    } else {
      setOpenIndex(index);
      setActiveSection(section);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current?.audioEl.current) {
      setCurrentTime(audioRef.current.audioEl.current.currentTime);
    }
  };

  // Set total duration when metadata is loaded
  const handleLoadedMetadata = () => {
    if (audioRef.current?.audioEl.current) {
      setDuration(audioRef.current.audioEl.current.duration);
    }
  };

  const formatDuration = (milliseconds) => {
    if (milliseconds == null || milliseconds <= 0) return ""; // Hide if undefined, null, or zero

    const seconds = Math.floor(milliseconds / 1000); // Convert ms to seconds
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");

    return `${hrs}:${mins}:${secs}`;
};

  return (
    <div className="historymenucard">
      <div id="referenceForRenderComponent" onClick={handleRenderComponent}></div>
      <div className="menu-tab-left-panel-new-design">
        <ul className="overflowhide">
          {activityHistory.loanhistorydata?.map((item, index) => {
              const parsedInfo = item?.extra_info ? JSON.parse(item.extra_info) : {};
              return (item.status_type?.toLowerCase() === activityHistoryTab) ? 
              (
                <li key={index}>
                  <p className={ tab_status?.Verified?.is_completed ? "completed" : tab_status?.Verified?.is_active ? "active" : ""}>
                    <div className="img-type"></div>
                    <div className="statusborder">
                      <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div style={{width:"75%"}}>
                          <div className="historydetails">{item.title}</div>
                          <div className="checklisthistory">{(item?.reason_list?.length) > 0 ? item?.reason_list.map((reason, index) => (<span key={index}>Reason {index + 1} : {reason?.name}<br /></span>)) : ""}</div>

                          <div className="checklisthistory">{(item?.previous_assign_to_details) ? `Previous lead assign :-> ${item?.previous_assign_to_details?.added_by_name}` : ""}</div>
                          <div className="checklisthistory">{(item?.assign_to_details)  ? `Current lead assign :-> ${item?.assign_to_details?.added_by_name}` : ""}</div>

                          <div className="checklisthistory">{(item?.checklist_details) ? `Checklist name:-> ${item?.checklist_details?.checklist_name}`  : ""}</div>
                          <div className="checklisthistory">{(item?.checklist_details) ? `Checklist status:-> ${item.checklist_sub_status_details?.name}`: ""}</div>

                          <div className="checklisthistory">{parsedInfo.tranch_id  ? `Tranch id :-> ${parsedInfo.tranch_id}` : ""}</div>
                          <div className="checklisthistory">{parsedInfo.offer_send ? `Offer :-> ${parsedInfo.offer_send}`    : ""}</div>
                          <div className="checklisthistory">{parsedInfo.call_status_mark ? `Call Mark Status :-> ${parsedInfo.call_status_mark}`: ""}</div>

                          <div className="checklisthistory">{parsedInfo.time ? `Time :-> ${DateFormate(parsedInfo.time, "dd mmm")}`+`${DateFormate(item.created_date, " h:MM TT ")}` : ""}</div>
                          <div className="checklisthistory">{parsedInfo.duration >= 0 && `Duration :-> ${formatDuration(parsedInfo.duration)}`}</div>
                          <div className="checklisthistory">{parsedInfo.call_status ? `Call status :-> ${parsedInfo.call_status}`: ""}</div>

                          <div className="checklisthistory">
                            {parsedInfo.recording_url && (
                              <div className="audio-container">
                                {parsedInfo.duration > 0 && (
                                  <>
                                    {/* Audio Player */}
                                    <ReactAudioPlayer
                                      ref={audioRef}
                                      src={parsedInfo.recording_url}
                                      controls
                                      onListen={handleTimeUpdate}
                                      onLoadedMetadata={handleLoadedMetadata}
                                    />                                    
                                  </>
                                )}
                              </div>
                            )}
                          </div>

                          {item.remark &&
                            <div className="documentlist">
                              {/* Remarks Added Section */}
                              <div className="doclistshow" onClick={() => handleToggleClick(index, "remarks")}>
                                <div className="doclistflex">
                                  <div style={{ display: "flex",alignItems:"center" }}>
                                    <img src={messageinfo} alt='logo' width={12} height={12} />
                                    <div>Remarks Added</div>
                                  </div>
                                  {/* <div className={`rotate-icon ${openIndex === index && activeSection === "remarks" ? "open" : ""}`}>
                                    <i className="ic-keyboard_arrow_down" style={{ fontSize: 20 }}></i>
                                  </div> */}
                                </div>
                              </div>
                              
                              <div className="docuploadlistshow1"><div className="historydetailsheading">{(item.remark) ? item.remark : ""}</div></div>
                            </div>
                          }
                          {(item?.previous_data !== item?.current_data) &&
                          <>
                          {(item?.previous_data || item?.current_data) &&
                            <div className="documentlist">
                              <div className="doclistshow" onClick={() => handleToggleClick(index, "info")}>
                                <div className="doclistflex">
                                  <div style={{ display: "flex" }}>
                                    <img src={moreinfo} alt='logo' width={12} height={14} />
                                    <div style={{fontSize:"13px"}}>More Information</div>
                                  </div>
                                  <div className={`rotate-icon ${openIndex === index && activeSection === "info" ? "open" : "" }`}>
                                    <i className="ic-keyboard_arrow_down" style={{ fontSize: 20 }}></i>
                                  </div>
                                </div>
                              </div>
                                    
                              {openIndex === index && activeSection === "info" && (
                                <div className="docuploadlistshow">
                                  <ul className="moreinfolist">
                                    {Object.entries(updatedValues(item?.previous_data, item?.current_data)).map(([key, nestedObj],index) => {
                                    return <li key={index}>
                                        <span>• {key} : - </span>
                                        {Object.entries(nestedObj)?.map(([subKey, value], index) => {
                                        return <span key={index}>{value}  {(value && index!= 1)  ? <i className="ic-keyboard_arrow_right"></i> : ""}</span>
                                      })}
                                      </li>
                                    })}
                                  </ul>
                                </div>
                              )}
                            </div>
                          }
                          </>
                          }
                          {activityHistoryTab == "follow up" &&
                            <div className="personinfoflex">
                              <img src={personinfo} alt='logo' width={14} height={16} />
                              <span>
                                Follow Up with {JSON.parse(item.current_data)?.followup_with ? JSON.parse(item.current_data)?.followup_with : parsedInfo.followup_with ? parsedInfo.followup_with :''} Scheduled on{" "}
                                <span>{DateFormate(JSON.parse(item.current_data)?.followup_date,"dd mmm h:MM TT")}</span>
                              </span>
                            </div>
                          }
                        </div>                 
                        <div style={{width:"20%"}}>
                          <div className="newstatusdetailsnew">
                            <div>
                              <span style={{color:"#473391"}}>By </span>
                              <span>{(item?.user_details?.added_by_name) ? item?.user_details?.added_by_name : "System"}</span>
                            </div>
                            <div>
                              <span><span style={{color:"#473391"}}>At</span> {item?.created_date ? DateFormate(item?.created_date,"dd mmm") : ""}, </span>
                              <span>{item?.created_date ? DateFormate(item?.created_date,"h:MM TT") : ""}</span>
                            </div>
                            <div><span style={{color:"#473391"}}>From</span> <span>{['web'].includes(item?.updated_by_source) ? "Sangam" : ['sfa'].includes(item?.updated_by_source) ? 'Yoddha App' : ['partner'].includes(item?.updated_by_source) ? 'Saathi App' : ['website'].includes(item?.updated_by_source) ? 'Website' : ['saathi_web'].includes(item?.updated_by_source) ? 'Saathi Web': ['incentive'].includes(item?.updated_by_source) ? 'incentive' : ''}</span></div>

                            {/* Check if the title matches and display the link */}
                            {item?.title === "Lead for Re-login Created" && (
                              <button  className="newsredirectbtn">
                                <a 
                                  href={`${window.location.origin}/lead-detail/update-status/${reference_id}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                >
                                  {lead?.leadDetail?.ref_lead_id}
                                </a>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </li>
              ) : ['All','all'].includes(activityHistoryTab) ?  
              (
                <li key={index}>
                  <p className={ tab_status?.Verified?.is_completed ? "completed" : tab_status?.Verified?.is_active ? "active" : ""}>
                    <div className="img-type"></div>
                    <div className="statusborder">
                      <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div style={{width:"75%"}}>
                          <div className="historydetails">{item.title}</div>
                          <div className="checklisthistory">{(item?.reason_list?.length) > 0 ? item?.reason_list?.map((reason, index) => (<span key={index}>Reason {index + 1} : {reason?.name}<br /></span>)) : ""}</div>

                          <div className="checklisthistory">{(item?.previous_assign_to_details) ? `Previous lead assign :-> ${item?.previous_assign_to_details?.added_by_name}` : ""}</div>
                          <div className="checklisthistory">{(item?.assign_to_details)  ? `Current lead assign :-> ${item?.assign_to_details?.added_by_name}` : ""}</div>

                          <div className="checklisthistory">{(item?.checklist_details) ? `Checklist name:-> ${item?.checklist_details?.checklist_name}`  : ""}</div>
                          <div className="checklisthistory">{(item?.checklist_details) ? `Checklist status:-> ${item?.checklist_sub_status_details?.name}`: ""}</div>

                          <div className="checklisthistory">{parsedInfo?.tranch_id  ? `Tranch id :-> ${parsedInfo?.tranch_id}` : ""}</div>
                          <div className="checklisthistory">{parsedInfo?.offer_send ? `Offer :-> ${parsedInfo?.offer_send}`    : ""}</div>
                          <div className="checklisthistory">{parsedInfo?.call_status_mark ? `Call Mark Status :-> ${parsedInfo?.call_status_mark}`: ""}</div>

                          <div className="checklisthistory">{parsedInfo.time ? `Time :-> ${DateFormate(parsedInfo.time, "dd mmm")}` + `${DateFormate(item.created_date, " h:MM TT ")}` : ""}</div>
                          <div className="checklisthistory">{parsedInfo.duration >= 0 && `Duration :-> ${formatDuration(parsedInfo.duration)}`}</div>
                          <div className="checklisthistory">{parsedInfo.call_status ? `Call status :-> ${parsedInfo.call_status}`: ""}</div>
                      
                          <div className="checklisthistory">
                            {parsedInfo.recording_url && (
                              <div className="audio-container">
                                {parsedInfo.duration > 0 && (
                                  <>
                                    {/* Audio Player */}
                                    <ReactAudioPlayer
                                      ref={audioRef}
                                      src={parsedInfo.recording_url}
                                      controls
                                      onListen={handleTimeUpdate}
                                      onLoadedMetadata={handleLoadedMetadata}
                                      style={{ width: "300px", height: "32px" }}
                                    />
                                  </>
                                )}
                              </div>
                            )}
                          </div>

                          {item.remark &&
                            <div className="documentlist">
                              {/* Remarks Added Section */}
                              <div className="doclistshow" onClick={() => handleToggleClick(index, "remarks")}>
                                <div className="doclistflex">
                                  <div style={{ display: "flex",alignItems:"center" }}>
                                    <img src={messageinfo} alt='logo' width={12} height={12} />
                                    <div>Remarks Added</div>
                                  </div>
                                  {/* <div className={`rotate-icon ${openIndex === index && activeSection === "remarks" ? "open" : ""}`}>
                                    <i className="ic-keyboard_arrow_down" style={{ fontSize: 20 }}></i>
                                  </div> */}
                                </div>
                              </div>
                               <div className="docuploadlistshow1">
                                <div className="historydetailsheading">{(item.remark) ? item.remark : ""}
                                  </div>
                                  </div>
                            </div>
                          }
                          {(item?.previous_data !== item?.current_data) &&
                          <>
                          {(item?.previous_data || item?.current_data) &&
                            <div className="documentlist">
                              <div className="doclistshow" onClick={() => handleToggleClick(index, "info")}>
                                <div className="doclistflex">
                                  <div style={{ display: "flex" }}>
                                    <img src={moreinfo} alt='logo' width={12} height={14} />
                                    <div style={{fontSize:"13px"}}>More Information</div>

                                  </div>
                                  <div className={`rotate-icon ${openIndex === index && activeSection === "info" ? "open" : "" }`}>
                                    <i className="ic-keyboard_arrow_down" style={{ fontSize: 20 }}></i>
                                  </div>
                                </div>
                              </div>
                                    
                              {openIndex === index && activeSection === "info" && (
                                <div className="docuploadlistshow">
                                  <ul className="moreinfolist">
                                    {Object.entries(updatedValues(item?.previous_data, item?.current_data)).map(([key, nestedObj],index) => {
                                    return <li key={index}>
                                        <span>• {key} : - </span>
                                        {Object.entries(nestedObj)?.map(([subKey, value], index) => {
                                        return <span key={index}>{value}  {(value && index!= 1)  ? <i className="ic-keyboard_arrow_right"></i> : ""}</span>
                                      })}
                                      </li>
                                    })}
                                  </ul>
                                </div>
                              )}
                            </div>
                          }
                          </>

                          }
                          {activityHistoryTab == "follow up" &&
                            <div className="personinfoflex">
                              <img src={personinfo} alt='logo' width={14} height={16} />
                              <span>
                                Follow Up with {JSON.parse(item.current_data)?.followup_with ? JSON.parse(item.current_data)?.followup_with : parsedInfo.followup_with ? parsedInfo.followup_with :''} Scheduled on{" "}
                                <span>{DateFormate(JSON.parse(item.current_data)?.followup_date,"dd mmm h:MM TT")}</span>
                              </span>
                            </div>
                          }
                        </div>                 
                        <div style={{width:"20%"}}>
                          <div className="newstatusdetailsnew">
                            <div>
                              <span style={{color:"#473391"}}>By </span>
                              <span>{(item?.user_details?.added_by_name) ? item?.user_details?.added_by_name : "System"}</span>
                            </div>
                            <div>
                              <span><span style={{color:"#473391"}}>At</span> {item?.created_date ? DateFormate(item?.created_date,"dd mmm") : ""}, </span>
                              <span>{item?.created_date ? DateFormate(item?.created_date,"h:MM TT") : ""}</span>
                            </div>
                            <div><span style={{color:"#473391"}}>From</span> <span>{['web'].includes(item?.updated_by_source) ? "Sangam" : ['sfa'].includes(item?.updated_by_source) ? 'Yoddha App' : ['partner'].includes(item?.updated_by_source) ? 'Saathi App' : ['website'].includes(item?.updated_by_source) ? 'Website' : ['saathi_web'].includes(item?.updated_by_source) ? 'Saathi Web': ['incentive'].includes(item?.updated_by_source) ? 'incentive' : ''}</span></div>

                            {/* Check if the title matches and display the link */}
                            {item?.title === "Lead for Re-login Created" && (
                              <button  className="newsredirectbtn">
                                <a 
                                  href={`${window.location.origin}/lead-detail/update-status/${reference_id}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                >
                                  {lead?.leadDetail?.ref_lead_id}
                                </a>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </li>
              ): null
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default ActivityHistory;