import { CHAT_TEMPLATE_LIST, SAVE_EXPECTED_DATES__LEAD_DETAILS, SPECIFIC_CHAT_TEMPLATE, STATUS_QUERY, UPDATE_LEAD_STATUS, UPDATE_LEAD_STATUS_UPDATE_FORM } from "../../../services/leads.gql";
import { executeGraphQLQuery, executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import FormFields from "../formFields/customerDetailsFormFields.json";
import style from "../../../style/leadStatus/leadStatus.module.scss";
import { SAVE_CUST_DETAILS } from "../../../services/customer.gql";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getLoanDetail } from "../../../store/action/allAction";
import { IS_USER_ACCESS_PAGE } from "../../../config/constants";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import { SAVE_FOLLOWUP } from "../../../services/followup.gql";
import GeneralService from "../../../services/generalService";
import LeadStatusService from "../../../services/leadStatus";
import React, { useEffect, useMemo, useState } from "react";
import SaveAndQualify from "../Modal/SaveAndQualify.modal";
import FollowUpService from "../../../services/followup";
import FormsySelect from "../../elements/FormsySelect";
import { useDispatch, useSelector } from "react-redux";
import LeadRemarkModal from "../Modal/Remark.modal";
import DocumentGallery from "../DocumentGallery";
import { gql, useApolloClient } from "@apollo/client";
import OpenModalForm from "../Modal/Form.modal";
import { toast } from "react-toastify";
import DateFormate from "dateformat";
import dateFormat from 'dateformat';
import Formsy from "formsy-react";
import crypto from '../../../config/crypto'
import MultiSelect from '../../elements/MultiSelect';
import LeadDetail from "../LeadDetail";
import generalService from "../../../services/generalService";

const LeadUpdateStatusForm = (props) => {
    const { LOGIN, SANCTIONED, INTERESTED_CONTACTED, NOT_INTERESTED_CONTACTED, INTERESTED_QUALIFIED, NOT_INTERESTED_QUALIFIED } = LeadStatusService.LEAD_STATUS_MASTER;

    const now = new Date();
    const currhour = Number(now.getHours());
    const currmin = now.getMinutes();

    const [minTime, setMinTime] = useState(now.setHours(currhour, currmin, 0));
    // const [currentLeadSubStatus, setCurrentLeadSubStatus] = useState(0);
    // const [currentLeadStatus, setCurrentLeadStatus] = useState(0);
    const [currentSubStatus, setCurrentSubStatus] = useState(0);
    // const [createFollowup, setCreateFollowup] = useState(false);
    const [showFormError, setShowFormError] = useState(false);
    const [followupStatus, setFollowupStatus] = useState({});
    // const [followupHistory, setFolloupHisory] = useState({});
    const [expectedDate, setExpectedDate] = useState(null);
    const [sanctionUploaded, setSannctionUploaded] = useState(false);
    const [subStatusList, setSubStatusList] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(0);
    const [remarkModal, setRemarkModal] = useState(false);
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState(null);
    const [errorMessageList, setErrorMessageList] = useState({});
    const [statusList, setStatusList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [isLoading, setIsLoading] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [modalSavequalify, setModalSaveQualify] = useState(false);
    const [sanctionedChecked_OTC, setSanctionedChecked_OTC] = useState(false);

    const PropertyIdentifiedForm = FormFields["PROPERTY_IDENTIFIED"];
    const PropertyIdentifiedFormFirstPart = ['property_value', 'expected_property_value', 'property_agreement_value', 'property_type', 'property_sub_type', 'usage_type', 'agreement_type'];
    const PropertyIdentifiedFormSecondPart = ['property_pincode', 'property_state', 'property_city', 'property_address1', 'property_address2'];

    const user_information = localStorage.getItem("user_information");
    const authUser = user_information && JSON.parse(user_information);
    const userId = authUser && authUser.id || 1

    const dispatch = useDispatch();
    const client = useApolloClient();
    const location = useLocation();
    const getContext = useOutletContext();
    const navigate = useNavigate();

    const urlparam = location?.pathname?.split("/");
    const pageName = urlparam?.length && urlparam[urlparam?.length - 2];

    const documentCategoryList = getContext?.docList || [];

    const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
    }));


    const accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;
    if (accessCondition !== 1) {
        // accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

    const CloseCreateFollowupNew = () => {
        // setCreateFollowup(false);
        document.body.classList.remove("overflow-hidden");
    };

    // const getFollowupData = async (lead_id, user_id) => {
    //     try {
    //         const result = await executeGraphQLQuery(GET_FOLLOWUP(lead_id, user_id), client);
    //         if (result?.data?.get_followup && result.data.get_followup.length > 0) {
    //             const followUps = result.data.get_followup;
    //             const sortedFollowUps = followUps.sort((a, b) => new Date(b.date) - new Date(a.date));
    //             const latestFollowup = sortedFollowUps[0];
    //             setShowFollowup(latestFollowup);
    //         } 
    //     } catch (error) {
    //         console.error('Query error:', error);
    //     }
    // }

    // useEffect(() => {
    //     let lead_id = lead.leadDetail.id || "";
    //     let user_id = lead?.leadDetail?.customer?.customer_id;
    //     getFollowupData(lead_id, user_id)
    // }, [lead]);

    const getStatusList = async () => {
        setSubStatusList([])
        executeGraphQLQuery(STATUS_QUERY("updateForm", lead.leadDetail.id), client)
            .then((result) => {
                if (result?.data?.status_list?.length) {
                    setStatusList(
                        result?.data?.status_list.map((v) => {
                            return { label: v.status_name, value: v.status_id, ...v };
                        })
                    );
                } else if (result?.errors?.length) {
                    setShowFormError(true);
                    toast.error(result.errors[0].message);
                } else {
                    setShowFormError(true);
                    toast.error("Somethig went wrong ,please try again");
                }
            })
            .catch((errors) => {
                setShowFormError(true);
                toast.error("Somethig went wrong ,please try again")
            })
    }

    useEffect(() => {
        const { status_id, sub_status_id, lead_details } = lead.leadDetail || {};

        if (status_id) {
            if (lead.leadDetail.status_id === LeadStatusService.LEAD_STATUS_MASTER.LOST) {
                const redirecturl = `/lead-detail/customer-details/loan-details/${crypto.encode(lead.leadDetail.id)}`;
                navigate(redirecturl);
            } else {
                getStatusList();
                setCurrentStatus(lead.leadDetail.status_id);
                setCurrentSubStatus(lead.leadDetail.sub_status_id);
                // setCurrentLeadStatus(lead.leadDetail.status_id);
                // setCurrentLeadSubStatus(lead.leadDetail.sub_status_id);
                if (lead.leadDetail?.lead_details?.expected_login_date) {
                    updateSetFormDetails('expected_login_date', lead.leadDetail?.lead_details?.expected_login_date)
                }
                if (lead.leadDetail?.lead_details?.expected_approved_date) {
                    updateSetFormDetails('expected_approved_date', lead.leadDetail?.lead_details?.expected_approved_date)
                }
                if (lead.leadDetail?.lead_details?.expected_disbursed_date) {
                    updateSetFormDetails('expected_disbursed_date', lead.leadDetail?.lead_details?.expected_disbursed_date)
                }
                if (lead.leadDetail.status_id === LeadStatusService.LEAD_STATUS_MASTER.LOGIN) {

                }
            }
        }
    }, [
        lead.leadDetail?.status_id, 
        lead.leadDetail?.sub_status_id, 
        lead.leadDetail?.lead_details?.expected_login_date, 
        lead.leadDetail?.lead_details?.expected_approved_date, 
        lead.leadDetail?.lead_details?.expected_disbursed_date,
    ]);

    const setLeadDetailsIntoFormObj = async () => {
        if (lead.leadDetail.status_id === LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED) {
            const addInFormDetails = {
                loan_type: lead.leadDetail?.loan_type || null,
                loan_sub_type: lead.leadDetail?.loan_sub_type?.split(',').map(Number) || [],
            }
            await updateSetFormDetails('bulkUpdate', addInFormDetails)
        }
    }

    const handleDateChange = (date, field_name) => {
        if (DateFormate(now, "yyyy-mm-dd") != DateFormate(date, "yyyy-mm-dd")) {
            setMinTime(now.setHours(0, 0, 0));
        } else {
            setMinTime(now.setHours(currhour, currmin, 0));
        }
        if (date) {
            if (field_name === 'follow_up_datetime') {
                setFollowupStatus((currentValue) => ({
                    ...currentValue,
                    [field_name]: date,
                }));
            }
            updateSetFormDetails(field_name, date, 'date')
        }
    };

    const handleSelectChange = async (data, selectKey) => {
        if (selectKey === "sub_status_id" && data.value) {
            setCurrentSubStatus(data.value);
        } else if (selectKey === "cra_state" && data.value) {
            const options = masterdata ? { ...masterdata.data } : {};
            if (options?.city && options?.city?.length) {
                const record = options.city
                    .filter((ob) => ob.state_id == data.value)
                    .map((data) => {
                        data.value = data.id;
                        data.label = data.label;
                        return data;
                    });
                setCityList(record);
            }
        } else if (selectKey === "pending_with" && data.value) {
            setFollowupStatus((currentValue) => ({
                ...currentValue,
                ["pending_with"]: data.value,
            }));
            // } else if (selectKey === "loan_type" && data.value) {
            //     await updateSetFormDetails('loan_sub_type', '')
        }

        await updateSetFormDetails(selectKey, data?.value)
    }

    const updateSetFormDetails = async (key, value, type = '') => {
        if (key === 'bulkUpdate' && typeof value === 'object' && Object.keys(value).length) {
            setFormDetails((currentValue) => ({
                ...currentValue,
                ...value
            }));
        } else {
            setErrorMessageList(null);
            if (typeof value === 'object' && value && value?.target && value?.target?.value) {
                value = value.target.value
            }

            if (type != 'date' && typeof value === 'object' && value != null) {
                toast.error('Unable to identify & set value')
                return false;
            }
            setFormDetails((currentValue) => ({
                ...currentValue,
                [key]: value || "",
            }));
        }
    };

    const handleStatusChange = async (data) => {
        if (currentStatus != data.status_id) {
            setFormDetails({});
            setCurrentSubStatus(0);
        }
        if (
            data?.subStatusList &&
            Array.isArray(data.subStatusList) &&
            data.subStatusList.length
        ) {
            setCurrentStatus(data.status_id);
            setSubStatusList(
                data.subStatusList.map((v) => {
                    return { label: v.name, value: v.id };
                })
            );
            await updateSetFormDetails("status_id", data.status_id);
            if (currentStatus != data.status_id)
                await updateSetFormDetails("sub_status_id", null);
            await setLeadDetailsIntoFormObj();
        }
    };

    const handleInputChange = async (event) => {
        const { id, value } = event.target;
        if (id == "cra_pincode") {
            if (value.length == 6) {
                await setCityStateByPin(value);
            } else {
                setStateList([]);
                setCityList([]);
                await updateSetFormDetails("bulkUpdate", { "cra_pincode": null, "cra_state": null, "cra_city": null });
            }
        } else if (id) {
            updateSetFormDetails(id, value);
        }
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            updateSetFormDetails(field_name, data.value)
        }
    }

    const handleRadioButtonChange = async (key, value) => {
        if (key === 'disbursed_with_otc')
            setSanctionedChecked_OTC(value)
        await updateSetFormDetails(key, value);
    };

    const setCityStateByPin = async (pincode) => {
        try {
            const response = await GeneralService.stateCityByPincode(pincode);
            if (response?.data?.data?.length) {
                const item = response.data.data[0];
                await handleSelectChange(
                    {
                        id: item.state_id,
                        name: item.state_name,
                        value: item.state_id,
                        label: item.state_name,
                    },
                    "cra_state"
                );

                await updateSetFormDetails("cra_pincode", pincode);
                await updateSetFormDetails("cra_state", item.state_id);
                await updateSetFormDetails("cra_city", item.city_id);
            }
        } catch (error) {
            console.error("Error :", error);
        }
    };

    const ALL_OPTIONS = useMemo(() => {
        const options = masterdata ? { ...masterdata.data } : {};
        if (options?.state_list) {
            options.cra_state = options.state_list;
            setStateList(options.state_list);
        }
        options.disbursed_tenure = Array.from({ length: 36 }, (_, index) => {
            const value = index + 5;
            return { id: value, label: value, value };
        });
        return options;
    }, [masterdata]);

    const handleCloseModal = () => {
        setRemarkModal(false);
    }

    const handleOpenSaveQualifyModal = () => {
        if (!modalSavequalify && formDetails?.sub_status_id && [LeadStatusService.LEAD_STATUS_MASTER.INTERESTED_CONTACTED, LeadStatusService.LEAD_STATUS_MASTER.NOT_INTERESTED_CONTACTED].includes(formDetails?.status_id)) {
            setModalSaveQualify(true);
            document.body.classList.add('overflow-hidden');
        }
    }

    const handleCloseSaveQualifyModal = () => {
        updateSetFormDetails("save_button", null);
        setModalSaveQualify(false);
        [...PropertyIdentifiedFormFirstPart, ...PropertyIdentifiedFormSecondPart].map((fieldName) => {
            updateSetFormDetails(fieldName, null);
        })
        document.body.classList.remove('overflow-hidden');
    }

    const handleExpectedDateChange = (date, field_name) => {
        if (date && field_name) {
            let dataobject = { ...expectedDate }
            dataobject[field_name] = date;
            setExpectedDate(dataobject);
        }
    };

    const handleSaveButton = async (button = "save") => {
        let validated={status:false};
        if(formDetails?.save_button!=="special_permission"){
          validated = validateStatusSubstatusForm();
        }
        if (validated.status || formDetails?.save_button==="special_permission") {
            if (button === 'qualify-modal') {
                handleOpenSaveQualifyModal();
            } else {
               if(formDetails?.save_button==="special_permission") {
                formDetails["save_button"]="qualify";
               }
                setIsLoading(true);
                try {
                    // Wait for both API calls to success
                    if ([LeadStatusService.LEAD_STATUS_MASTER.INTERESTED_QUALIFIED, LeadStatusService.LEAD_STATUS_MASTER.NOT_INTERESTED_QUALIFIED].includes(lead.leadDetail.status_id) && formDetails.status_id === LeadStatusService.LEAD_STATUS_MASTER.LOGIN) {
                        await saveLoginStatusDetails(button);
                        await saveStatusSubstatus(button);
                    } else if ([LeadStatusService.LEAD_STATUS_MASTER.LOGIN].includes(lead.leadDetail.status_id) && formDetails.status_id === LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED) {
                        await saveSanctionedStatusDetails(button);
                        await saveStatusSubstatus(button);
                    } else if ([LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED].includes(lead.leadDetail.status_id) && formDetails.status_id === LeadStatusService.LEAD_STATUS_MASTER.DISBURSED) {
                        await saveDisbursalStatusDetails(button);
                        await saveStatusSubstatus(button);
                    } else {
                        await saveStatusSubstatus(button);
                    }

                    await saveExpectedDate();
                    await saveFollowUpDetails();
                    await saveQualifyStatusDetails();
                    setFormDetails({});
                    setFollowupStatus({});
                    dispatch(getLoanDetail(lead.leadDetail.id, client));
                } catch (error) {
                    toast.error(error.message);
                } finally {
                    setIsLoading(false);
                }
            }
        } else {
            // toast.error(validated?.error || "Form validation failed. Please check required fields.");
            // return;
        }
    }
    const validStatusIds = [
        LeadStatusService.LEAD_STATUS_MASTER.NOT_CONNECTED,
        LeadStatusService.LEAD_STATUS_MASTER.CALL_BACK,
        LeadStatusService.LEAD_STATUS_MASTER.NOT_INTERESTED_CONTACTED,
        LeadStatusService.LEAD_STATUS_MASTER.NOT_INTERESTED_QUALIFIED,
        LeadStatusService.LEAD_STATUS_MASTER.DISBURSED,
      ];
      
      const validSubStatusIds = [
        LeadStatusService.LEAD_SUB_STATUS_MASTER.NOT_CONNECTED.INVALID_PHN_NO,
        LeadStatusService.LEAD_SUB_STATUS_MASTER.CALL_BACK.WRONG_N0,
        LeadStatusService.LEAD_SUB_STATUS_MASTER.NOT_INTERESTED_CONTACTED.NOT_APPLIED_FOR_LOAN,
        LeadStatusService.LEAD_SUB_STATUS_MASTER.NOT_INTERESTED_CONTACTED.LOAN_NOT_REQUIRED,
        LeadStatusService.LEAD_SUB_STATUS_MASTER.NOT_INTERESTED_CONTACTED.ALREADY_LOGGEDIN_ANOTHER_BANK,
        LeadStatusService.LEAD_SUB_STATUS_MASTER.NOT_INTERESTED_QUALIFIED.LOAN_NOT_REQUIRED,
        LeadStatusService.LEAD_SUB_STATUS_MASTER.NOT_INTERESTED_QUALIFIED.ALREADY_LOGGEDIN_ANOTHER_BANK,
        LeadStatusService.LEAD_SUB_STATUS_MASTER.DISBURSE.TRANSACTION_PENDING
      ];

    const isExemptFromValidation =
  validStatusIds.includes(formDetails?.status_id) &&
  validSubStatusIds.includes(formDetails?.sub_status_id);


    const validateStatusSubstatusForm = () => {
        const validate = { status: true, error: null }
        let errorMsg = null
        if (!formDetails?.status_id) {
            errorMsg = 'Please select Status'
            setErrorMessageList({ status_id: errorMsg })
        } else if (!formDetails?.sub_status_id) {
            errorMsg = 'Please select Sub Status'
            setErrorMessageList({ sub_status_id: errorMsg })
        } else if (!formDetails?.follow_up_datetime && !isExemptFromValidation) {
            errorMsg = "Please select followup date & time";
            setErrorMessageList({ follow_up_datetime: errorMsg });
        } else if (!formDetails?.pending_with && !isExemptFromValidation) {
            errorMsg = "Please select Pending with";
            setErrorMessageList({ pending_with: errorMsg });
        } else if (formDetails.sub_status_id && [LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_CONTACTED.VISIT_REQUESTED, LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_QUALIFIED.VISIT_REQUESTED].includes(formDetails.sub_status_id)) {
            if (!formDetails?.visit_datetime) {
                errorMsg = 'Please select Visit Date'
                setErrorMessageList({ visit_datetime: errorMsg })
            } else if (!formDetails?.cra_address1 || formDetails?.cra_address1.length < 10) {
                errorMsg = 'Please enter Address'
                setErrorMessageList({ cra_address1: errorMsg })
            } else if (!formDetails?.cra_pincode || (formDetails?.cra_pincode && formDetails?.cra_pincode.length != 6)) {
                errorMsg = 'Please enter valid pincode'
                setErrorMessageList({ cra_pincode: errorMsg })
            } else if (!formDetails?.cra_state) {
                errorMsg = 'Please select State'
                setErrorMessageList({ cra_state: errorMsg })
            } else if (!formDetails?.cra_city) {
                errorMsg = 'Please select City'
                setErrorMessageList({ cra_city: errorMsg })
            }
        } else if (formDetails.status_id === LeadStatusService.LEAD_STATUS_MASTER.LOGIN && lead.leadDetail.status_id != LeadStatusService.LEAD_STATUS_MASTER.LOGIN) {
            if (!formDetails?.bank_id) {
                errorMsg = 'Please select Bank for marking status to login'
                setErrorMessageList({ bank_id: errorMsg })
            } else if (!formDetails?.login_date) {
                errorMsg = 'Please select Login Date for marking status to login'
                setErrorMessageList({ login_date: errorMsg })
            } else if (!formDetails?.login_amount) {
                errorMsg = 'Please enter Login Amount for marking status to login'
                setErrorMessageList({ login_amount: errorMsg })
            } else if (!formDetails?.login_id) {
                errorMsg = 'Please enter Login ID for marking status to login'
                setErrorMessageList({ login_id: errorMsg })
            }
            if (formDetails?.login_amount < 20000) {
                errorMsg = 'Please enter valid Login Amount for marking status to login'
                setErrorMessageList({ login_amount: errorMsg })
            }
        } else if (formDetails.status_id === LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED && lead.leadDetail.status_id != LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED) {
            if (!formDetails?.approved_amount) {
                errorMsg = 'Please enter Sanctioned Amount for marking status to sanctioned'
                setErrorMessageList({ approved_amount: errorMsg })
            } else if (!formDetails?.approval_date) {
                errorMsg = 'Please select Sanctioned Date for marking status to sanctioned'
                setErrorMessageList({ approval_date: errorMsg })
            } else if (!formDetails?.approved_emi) {
                errorMsg = 'Please enter Sanctioned EMI for marking status to sanctioned'
                setErrorMessageList({ approved_emi: errorMsg })
            } else if (!formDetails?.approval_number) {
                errorMsg = 'Please enter Sanctioned ID for marking status to sanctioned'
                setErrorMessageList({ approval_number: errorMsg })
            } else if (!formDetails?.approved_roi) {
                errorMsg = 'Please enter Sanctioned ROI for marking status to sanctioned'
                setErrorMessageList({ approved_roi: errorMsg })
            }
            if (!((formDetails.approved_roi >= 0 && formDetails.approved_roi <= 99) || formDetails.approved_roi === null)) {
                errorMsg = 'Please enter valid Sanctioned ROI for marking status to sanctioned'
                setErrorMessageList({ approved_roi: errorMsg })
            }
            if (formDetails?.approved_amount < 20000) {
                errorMsg = 'Please enter valid Sanctioned Amount for marking status to sanctioned'
                setErrorMessageList({ approved_amount: errorMsg })
            }
            // if (+lead.leadDetail?.lead_details?.login_amount < +formDetails?.approved_amount) {
            //     errorMsg = 'Sanctioned amount greater than the login amount, please correct the amount before proceeding.'
            //     setErrorMessageList({ approved_amount: errorMsg })
            // }
        } else if (formDetails.status_id === LeadStatusService.LEAD_STATUS_MASTER.DISBURSED && lead.leadDetail.status_id != LeadStatusService.LEAD_STATUS_MASTER.DISBURSED) {
            if (!formDetails?.loan_type) {
                errorMsg = 'Please select Loan Type for marking status to disbursal'
                setErrorMessageList({ loan_type: errorMsg })
                // } else if (!formDetails?.loan_sub_type) {
                //     errorMsg = 'Please select Loan Sub-Type for marking status to disbursal'
                //     setErrorMessageList({ loan_sub_type: errorMsg })
            } else if (!formDetails?.tranche_type) {
                errorMsg = 'Please select Disbursal Type for marking status to disbursal'
                setErrorMessageList({ tranche_type: errorMsg })
            } else if (!formDetails?.disbursed_amount) {
                errorMsg = 'Please enter Disbursal Amount for marking status to disbursal'
                setErrorMessageList({ disbursed_amount: errorMsg })
            } else if (!formDetails?.disbursed_date) {
                errorMsg = 'Please enter Disbursal Date for marking status to disbursal'
                setErrorMessageList({ disbursed_date: errorMsg })
            } else if (!formDetails?.disbursed_tenure) {
                errorMsg = 'Please enter Disbursal Tenure for marking status to disbursal'
                setErrorMessageList({ disbursed_tenure: errorMsg })
            } else if (!formDetails?.disbursed_id) {
                errorMsg = 'Please enter Disbursal ID for marking status to disbursal'
                setErrorMessageList({ disbursed_id: errorMsg })
            } else if (!formDetails?.disbursed_roi) {
                errorMsg = 'Please enter Disbursal ROI for marking status to disbursal'
                setErrorMessageList({ disbursed_roi: errorMsg })
            }
            if (formDetails.disbursed_roi && (!((formDetails.disbursed_roi >= 0 && formDetails.disbursed_roi <= 99) || formDetails.disbursed_roi === null))) {
                errorMsg = 'Please enter valid Disbursal ROI for marking status to disbursal'
                setErrorMessageList({ disbursed_roi: errorMsg })
            }
            if (formDetails?.disbursed_amount && formDetails?.disbursed_amount < 20000) {
                errorMsg = 'Please enter valid Disbursal Amount for marking status to disbursal'
                setErrorMessageList({ disbursed_amount: errorMsg })
            }

            if (+lead.leadDetail?.lead_details?.approved_amount < +formDetails?.disbursed_amount) {
                errorMsg = 'Disburse an amount greater than the sanctioned amount, please correct the amount before proceeding.'
                setErrorMessageList({ disbursed_amount: errorMsg })
            }
        }
        if (modalSavequalify) {
            if ([LeadStatusService.LEAD_STATUS_MASTER.INTERESTED_CONTACTED, LeadStatusService.LEAD_STATUS_MASTER.NOT_INTERESTED_CONTACTED].includes(formDetails.status_id) && (!formDetails?.loan_amount || parseInt(formDetails.loan_amount) < 20000)) {
                errorMsg = 'Minimum Loan Amount is ₹ 20,000/-'
                setErrorMessageList({ loan_amount: errorMsg })
            }
        }
        if (formDetails?.follow_up_datetime) {
            const dateObj = new Date(formDetails.follow_up_datetime);
            if (dateObj.getHours() === 0 && dateObj.getMinutes() === 0 && dateObj.getSeconds() === 0) {
                errorMsg = "Please select followup time";
                setErrorMessageList({ follow_up_datetime: errorMsg });
            }
        }

        if (errorMsg) {
            validate.status = false
            validate.error = errorMsg
        }
        setFormError(errorMsg || null);
        return validate;
    }

    const saveStatusSubstatus = async (button = "save") => {
        const variables = {
            api_called_by: "web",
            UpdateLeadInput: {
                lead_id: lead.leadDetail.id,
                status_id: formDetails.status_id,
                sub_status_id: formDetails.sub_status_id,
                is_qualified: formDetails?.save_button === "qualify" ? 1 : 0,
                follow_up_date: formDetails?.follow_up_datetime || null,
                remarks: followupStatus?.remarks ? followupStatus?.remarks?.replace(/['"]/g, '') : '',
            },
        };

        if (
            ([LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_CONTACTED.VISIT_REQUESTED, LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_QUALIFIED.VISIT_REQUESTED].includes(formDetails.sub_status_id)) &&
            (formDetails?.visit_datetime && formDetails?.cra_address1 && formDetails?.cra_pincode && formDetails?.cra_state && formDetails?.cra_city)
        ) {
            variables.UpdateLeadInput.visit_date = formDetails?.visit_datetime || null
            variables.UpdateLeadInput.cra_address1 = formDetails?.cra_address1 || null
            variables.UpdateLeadInput.cra_address2 = formDetails?.cra_address2 || null
            variables.UpdateLeadInput.cra_state = formDetails?.cra_state || null
            variables.UpdateLeadInput.cra_city = formDetails?.cra_city || null
            variables.UpdateLeadInput.cra_pincode = formDetails?.cra_pincode || null
        }

        // return true;

        return await executeGraphQLMutation(UPDATE_LEAD_STATUS_UPDATE_FORM(variables.UpdateLeadInput), {}, client)
            .then(async (resp) => {
                let response = resp?.data?.UpdateLeadStatus || null;
                if (response && !response.success) {
                    throw new Error(response.error || "Something went wrong");
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
    };

    const saveExpectedDate = async () => {
        const variables = {
            lead_id: lead.leadDetail.id,
            status_id: formDetails.status_id,
            is_allow_history: 1
        };
        let existExpecteddate = null;
        if ([INTERESTED_CONTACTED, INTERESTED_QUALIFIED].includes(formDetails?.status_id)) {
            if (formDetails?.expected_login_date) {
                variables.expected_date = dateFormat(formDetails.expected_login_date, "yyyy-mm-dd")
                if (lead?.leadDetail?.lead_details?.expected_login_date) {
                    existExpecteddate = dateFormat(lead.leadDetail.lead_details.expected_login_date, "yyyy-mm-dd")
                }
            }
        } else if ([LOGIN].includes(formDetails?.status_id)) {
            if (formDetails?.expected_approved_date) {
                variables.expected_date = dateFormat(formDetails.expected_approved_date, "yyyy-mm-dd")
                if (lead.leadDetail?.lead_details?.expected_approved_date) {
                    existExpecteddate = dateFormat(lead.leadDetail.lead_details.expected_approved_date, "yyyy-mm-dd")
                }
            }
        } else if ([SANCTIONED].includes(formDetails?.status_id)) {
            if (formDetails?.expected_disbursed_date) {
                variables.expected_date = dateFormat(formDetails.expected_disbursed_date, "yyyy-mm-dd")
                if (lead.leadDetail?.lead_details?.expected_disbursed_date) {
                    existExpecteddate = dateFormat(lead.leadDetail.lead_details.expected_disbursed_date, "yyyy-mm-dd")
                }
            }
        }
        if (variables.expected_date != existExpecteddate) {
            await executeGraphQLMutation(SAVE_EXPECTED_DATES__LEAD_DETAILS, variables, client)
                .catch(error => {
                    toast.error(error.message);
                });
        }
    };

    const saveFollowUpDetails = async () => {

        if (followupStatus?.follow_up_datetime && formDetails?.follow_up_datetime) {
            const is_allow_history = 1;
            const reqBody = {
                lead_id: lead.leadDetail.id,
                followup_with: followupStatus?.pending_with || "",
                followup_type: followupStatus?.followup_type || "call",
                followup_date: followupStatus.follow_up_datetime || "",
                comment: followupStatus?.remarks || "",
            };
            const mutation = SAVE_FOLLOWUP(authUser?.id,is_allow_history)
            const variables = { CreateFollowupInput: reqBody };
            await executeGraphQLMutation(mutation, variables, client)
                .then((resp) => {
                    const response = resp?.data?.SAVE_FOLLOWUP || null;
                    if (response && !response.success) {
                        throw new Error(response.error || "Something went wrong");
                    } else {
                        // const folloupdata = GET_FOLLOWUP(lead.leadDetail.id, client);
                        // setFolloupHisory(folloupdata);
                        // dispatch(getLoanDetail(lead.leadDetail.id, client));
                    }
                })
                .catch((err) => {
                    toast.error(err.message);
                })
                .finally(() => {
                    setFollowupStatus({ comment: "", followup_date: null });
                    CloseCreateFollowupNew();
                });
        }
    };

    
    const handleFileChange = async (event) => {
        let selectedImage = event && event.target && event.target.files && event.target.files.length && event.target.files[0].name || "";
        let publicUrl = '', docPathArray = [];
        if (!selectedImage) {
          toast.error("Please upload an image.");
          return;
        }
        if (selectedImage) {
          let lead_id = lead?.leadDetail?.id || "";
          // if ((max_upload - leadDocCount) > 0) {
          const formData = new FormData();
          formData.append("dealer_id", lead?.leadDetail?.id);
          formData.append("upload_type", "customer_doc");
          for (let imgFile of event.target.files) {
            formData.append("images", imgFile);
          }
    
          try {
            publicUrl = await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
            if (publicUrl && publicUrl.data && publicUrl.data.data && publicUrl.data.data.length) {
              docPathArray = publicUrl.data.data;
              docPathArray = docPathArray.map(ob => ob.file_url);
              saveDocument(lead_id, docPathArray);
            }
          } catch (error) {
            console.error('Error uploading file:', error);
          }
        }
    }

    const saveDocument = (lead_id, docPathArray) => {
        let leadDocArr = [];
        for (let imgPath of docPathArray) {
          leadDocArr.push({
            lead_id: Number(lead_id),
            doc_id: 43,
            parent_doc_id: 114,
            doc_path: imgPath,
            doc_source: "web",
            customer_type: "customer",
            customer_id: lead?.leadDetail?.customer?.customer_id || 0,
            user_id: authUser && authUser.id || 1,
          })
        }
        let variables = {
          saveDocInput: {
            lead_id: lead_id,
            lead_docs: leadDocArr
          },
          api_called_by: 'web'
        };
    
        let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
              save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
                  lead_id
                  doc_id
                  doc_path
              }
          }`;
        executeGraphQLMutation(mutation, variables, client).then(result => {
          if (result && result.data) {
            setSannctionUploaded(true)
            toast.success('Document uploaded successfully');
            // dispatch(getLoanDetail(lead_id, client));
          }
        })
    }


    const saveQualifyStatusDetails = async () => {
        if (modalSavequalify) {
            const variables = {
                api_called_by: 'web',
                LeadAdditionalInput: {
                    lead_id: lead.leadDetail.id,
                    lead_details: {
                        is_property_identified: `${formDetails.is_property_identified === 'yes' ? 1 : 0}`,
                        property_value: formDetails?.property_value || null,
                        property_type: +formDetails?.property_type || null,
                        usage_type: +formDetails?.usage_type || null,
                        user_paid_token_amount: +formDetails?.user_paid_token_amount,
                        agreement_type: +formDetails?.agreement_type || null,
                        property_address1: formDetails?.property_address1 || null,
                        property_address2: formDetails?.property_address2 || null,
                        property_pincode: formDetails?.property_pincode || null,
                        property_city: formDetails?.property_city || null,
                        property_state: formDetails?.property_state || null,
                        expected_property_value: formDetails?.expected_property_value || null,
                        property_agreement_value: formDetails?.property_agreement_value || null,
                        property_sub_type: +formDetails?.property_sub_type || null,
                        builder_name_id: formDetails?.builder_name_id || null,
                        project_name: formDetails?.project_name || null,
                        loan_amount: formDetails?.loan_amount,
                    }
                }
            };

            await executeGraphQLMutation(SAVE_CUST_DETAILS(userId), variables, client)
                .then(resp => {
                    let response = resp?.data?.LeadAdditionalInput || null;
                    if (response && !response.success) {
                        throw new Error(response.error || "Something went wrong while saving qualify details");
                    } else {
                        handleCloseSaveQualifyModal();
                    }
                })
                .catch(err => {
                    toast.error(err.message);
                })
        }
    }

    const saveLoginStatusDetails = async () => {
        if (formDetails?.bank_id && formDetails?.login_date && formDetails?.login_amount && formDetails?.login_id) {
            const reqBody = {
                bank_id: formDetails.bank_id || null,
                login_number: formDetails.login_id || null,
                login_amount: formDetails.login_amount || null,
                login_remark: formDetails.approval_remark || "",
                login_date: formDetails.login_date ? dateFormat(formDetails.login_date, "yyyy-mm-dd") : null
            }

            let mutation = UPDATE_LEAD_STATUS,
                variables = {
                    api_called_by: 'web',
                    UpdateLeadStatus: {
                        lead_id: lead.leadDetail.id,
                        user_id: authUser && authUser.id || 1,
                        update_type: "status",
                        save_type: "update",
                        status_id: [LeadStatusService.LEAD_STATUS_MASTER.LOGIN],
                        login_form: reqBody,
                        callFrom: "updateStatusForm",
                    },
                };

            await executeGraphQLMutation(mutation, variables, client)
                .then(resp => {
                    let response = resp?.data?.UpdateLeadStatus || null;
                    if (response && !response.success) {
                        throw new Error(response.error || "Something went wrong");
                    }
                })
                .catch(err => {
                    throw new Error(err.message);
                })
        }
    }

    const saveSanctionedStatusDetails = async () => {
        if (formDetails?.approved_amount && formDetails?.approval_number && formDetails?.approved_emi && formDetails?.approval_date && formDetails?.approved_roi) {
            const reqBody = {
                approved_amount: formDetails.approved_amount || null,
                approval_number: formDetails.approval_number || null,
                approval_remark: formDetails.approval_remark || "",
                approved_emi: formDetails.approved_emi || null,
                approval_date: formDetails.approval_date ? dateFormat(formDetails.approval_date, "yyyy-mm-dd") : null,
                approved_roi: formDetails.approved_roi || null
            }

            let mutation = UPDATE_LEAD_STATUS,
                variables = {
                    api_called_by: 'web',
                    UpdateLeadStatus: {
                        update_type: "status",
                        save_type: "update",
                        status_id: [LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED],
                        // sub_status_id: (checked) ? 3 : 4,
                        lead_id: lead.leadDetail.id,
                        user_id: authUser && authUser.id || 1,
                        approval_form: reqBody,
                        callFrom: "updateStatusForm",
                    }
                };

            await executeGraphQLMutation(mutation, variables, client)
                .then(resp => {
                    let response = resp?.data?.UpdateLeadStatus || null;
                    if (response && !response.success) {
                        throw new Error(response.error || "Something went wrong");
                    }
                    // if (formDetails.approval_remark) dispatch((getLeadRemarks({ lead_id: lead.leadDetail.id, source: 'WEB' }, client)))
                })
                .catch(err => {
                    throw new Error(err.message);
                })
        }
    }

    const saveDisbursalStatusDetails = async () => {
        if (formDetails?.loan_type && formDetails?.tranche_type && formDetails?.disbursed_amount && formDetails?.disbursed_date && formDetails?.disbursed_tenure && formDetails?.disbursed_id && formDetails?.disbursed_roi) {
            const reqBody = {
                lead_id: lead.leadDetail.id,
                product_sub_type: +formDetails?.product_sub_type || 0,
                disbursed_amount: formDetails?.disbursed_amount,
                disbursed_tenure: formDetails?.disbursed_tenure,
                disbursal_remark: formDetails?.disbursal_remark,
                disbursed_id: formDetails?.disbursed_id,
                disbursed_date: formDetails?.disbursed_date ? dateFormat(formDetails.disbursed_date, "yyyy-mm-dd") : null,
                disbursed_roi: formDetails?.disbursed_roi || "0"
            }

            let mutation = UPDATE_LEAD_STATUS,
                variables = {
                    api_called_by: 'web',
                    UpdateLeadStatus: {
                        update_type: "status",
                        save_type: "update",
                        tranche_type: formDetails?.tranche_type || null,
                        loan_type: formDetails?.loan_type || null,
                        loan_sub_type: formDetails?.loan_sub_type ? formDetails?.loan_sub_type?.join(',') : null,
                        // disbursement_done: disbursement_done,
                        status_id: [LeadStatusService.LEAD_STATUS_MASTER.DISBURSED],
                        lead_id: lead.leadDetail.id,
                        user_id: authUser && authUser.id || 1,
                        disbursal_form: reqBody,
                        callFrom: "updateStatusForm",
                    }
                };

            await executeGraphQLMutation(mutation, variables, client)
                .then(resp => {
                    let response = resp?.data?.UpdateLeadStatus || null;
                    if (response && !response.success) {
                        throw new Error(response.error || "Something went wrong on saving Disbursal Details");
                    }
                    // if (formDetails.approval_remark) dispatch((getLeadRemarks({ lead_id: lead.leadDetail.id, source: 'WEB' }, client)))
                })
                .catch(err => {
                    throw new Error(err.message);
                })
        }
    }

    const handleMultiSelectChange = async (n, value) => {
        setFormDetails({
            ...formDetails,
            loan_sub_type: value.map(ob => ob.value)
        })
    }


    return (
        <>
            <div
                className="image-form-outer"
                key={lead.leadDetail && lead.leadDetail?.status_id}
            >
                <div className="image-tab-panel">
                    <DocumentGallery
                        documentCategoryList={documentCategoryList}
                        doc_id="1"
                    />
                </div>
                <div className="form-tab-right-panel">
                    {showFormError ? (
                        <div>
                            <h3
                                className="emailheadingticket"
                                style={{ textAlign: "center" }}
                            >
                                Status Machine not configured as lead sub-status{" "}
                                <u style={{ color: "red" }}>not available</u> OR{" "}
                                <u style={{ color: "red" }}>INVALID</u> for the lead
                            </h3>
                        </div>
                    ) : (
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h2>Update Status</h2>
                                <button
                                    type="button"
                                    className={style.remarkButton}
                                    onClick={() => setRemarkModal(true)}
                                >
                                    + Remarks
                                </button>
                            </div>
                            {/* {formError && (<div className="error-msg">{formError}</div>)} */}
                            {/* <p>{JSON.stringify(formDetails, null, 2)}</p> */}

                            <div className="">
                                <div className="flex justify-content-between">
                                    <Formsy className="lead-form-filed" autoComplete="off">
                                        {/* <div className="row">
                                            <fieldset className="col-md-6">
                                                <div className={style.followupContainer}>
                                                <div className={style.followup}>
                                                    <div className={style.followupSection}>
                                                    <p>
                                                        <strong>Follow-ups</strong>
                                                    </p>
                                                    <ul>
                                                        <li>
                                                        <strong>Overdue :</strong> 20 Sep, 2024 11:00
                                                        <div className={style.action}>
                                                            <a href="#">Mark as Done</a>
                                                            <a href="#">Reschedule</a>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                    <div className={style.divider}></div>
                                                    <div className={style.followupSection}>
                                                    <ul>
                                                        <li>
                                                        <strong>Upcoming :</strong> 20 Sep, 2024 11:00
                                                        <div className={style.action}>
                                                            <a href="#">Mark as Done</a>
                                                            <a href="#">Reschedule</a>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                                </div>
                                            </fieldset>
                                        </div> */}

                                        <div className="row">
                                            <fieldset className="single-select col-md-6">
                                                <FormsySelect
                                                    name="status_id"
                                                    id="status_id"
                                                    inputProps={{
                                                        options: statusList,
                                                        placeholder: "Status",
                                                        className: "react-select",
                                                        //required: "required",
                                                        classNamePrefix: "react-select",
                                                        value: statusList && statusList.filter(({ value }) => value == formDetails?.status_id),
                                                    }}
                                                    isFieldRequired={true}
                                                    value={formDetails?.status_id || 0}
                                                    onChange={handleStatusChange}
                                                />
                                                <div className="error-msg">{errorMessageList?.status_id}</div>
                                            </fieldset>
                                            <fieldset className="single-select col-md-6">
                                                <FormsySelect
                                                    placeholder="Select"
                                                    name="sub_status_id"
                                                    id="sub_status_id"
                                                    inputProps={{
                                                        options: subStatusList,
                                                        placeholder: "Sub-Status",
                                                        className: "react-select",
                                                        //required: "required",
                                                        classNamePrefix: "react-select",
                                                        value: subStatusList && subStatusList.filter(({ value }) => value == formDetails?.sub_status_id),
                                                    }}
                                                    isFieldRequired={true}
                                                    value={formDetails?.sub_status_id || 0}
                                                    onChange={(data) => { handleSelectChange(data, "sub_status_id"); }}
                                                />
                                                <div className="error-msg">{errorMessageList?.sub_status_id}</div>
                                            </fieldset>
                                        </div>

                                        <div className="row">
                                            {!![INTERESTED_CONTACTED, INTERESTED_QUALIFIED].includes(formDetails?.status_id) && (
                                                <fieldset className="single-select col-md-6">
                                                    <FormsyDatePicker
                                                        name={"expected_login_date"}
                                                        id={"expected_login_date"}
                                                        selectedDate={formDetails?.expected_login_date}
                                                        placeholder={"Expected Login Date"}
                                                        fieldTitle={formDetails?.expected_login_date ? "Expected Login Date" : "NA"}
                                                        minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                                        onDateSelect={(date) => handleDateChange(date, "expected_login_date")}
                                                        DateFormate
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        required={false}
                                                        className={style.textarea}
                                                        style={{ border: "1px solid #cccccc" }}
                                                    />
                                                    <div className="error-msg">{errorMessageList?.expected_login_date}</div>
                                                </fieldset>
                                            )}
                                            {!![LOGIN].includes(formDetails?.status_id) && (
                                                <fieldset className="single-select col-md-6">
                                                    <FormsyDatePicker
                                                        name={"expected_approved_date"}
                                                        id={"expected_approved_date"}
                                                        selectedDate={formDetails?.expected_approved_date}
                                                        placeholder={"Expected Sanction Date"}
                                                        fieldTitle={formDetails?.expected_approved_date ? "Expected Sanction Date" : "NA"}
                                                        minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                                        onDateSelect={(date) => handleDateChange(date, "expected_approved_date")}
                                                        DateFormate
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        required={false}
                                                        className={style.textarea}
                                                        style={{ border: "1px solid #cccccc" }}
                                                    />
                                                    <div className="error-msg">{errorMessageList?.expected_approved_date}</div>
                                                </fieldset>
                                            )}
                                            {!![SANCTIONED].includes(formDetails?.status_id) && (
                                                <fieldset className="single-select col-md-6">
                                                    <FormsyDatePicker
                                                        name={"expected_disbursed_date"}
                                                        id={"expected_disbursed_date"}
                                                        selectedDate={formDetails?.expected_disbursed_date}
                                                        placeholder={"Expected Disbursed Date"}
                                                        fieldTitle={formDetails?.expected_disbursed_date ? "Expected Disbursed Date" : "NA"}
                                                        minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                                        onDateSelect={(date) => handleDateChange(date, "expected_disbursed_date")}
                                                        DateFormate
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        required={false}
                                                        className={style.textarea}
                                                        style={{ border: "1px solid #cccccc" }}
                                                    />
                                                    <div className="error-msg">{errorMessageList?.expected_disbursed_date}</div>
                                                </fieldset>
                                            )}
                                        </div>

                                        {!![LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_CONTACTED.VISIT_REQUESTED, LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_QUALIFIED.VISIT_REQUESTED].includes(formDetails?.sub_status_id) && (
                                            <>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6 ">
                                                        <FormsyDatePicker
                                                            name={"visit_datetime"}
                                                            id={"visit_datetime"}
                                                            selectedDate={formDetails?.visit_datetime}
                                                            placeholder={"Visit Date"}
                                                            fieldTitle={formDetails?.visit_datetime ? "Visit Date" : "NA"}
                                                            // value={formDetails?.visit_datetime}
                                                            onDateSelect={(date) => handleDateChange(date, "visit_datetime")}
                                                            DateFormate
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            showTimeSelect={true}
                                                            timeIntervals={15}
                                                            minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                                            minTime={minTime}
                                                            disabled={false}
                                                            required={false}
                                                            hasError={false}
                                                            className={style.textarea}
                                                            style={{ border: "1px solid #cccccc" }}
                                                            isFieldRequired={true}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.visit_datetime}</div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"cra_address1"}
                                                            name={"cra_address1"}
                                                            type={"text"}
                                                            value={""}
                                                            placeholder=" "
                                                            label={"Address 1"}
                                                            onChange={handleInputChange}
                                                            isFieldRequired={true}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.cra_address1}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"cra_address2"}
                                                            name={"cra_address2"}
                                                            type={"text"}
                                                            value={""}
                                                            placeholder=" "
                                                            label={"Address 2"}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.cra_address2}</div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"cra_pincode"}
                                                            name={"cra_pincode"}
                                                            type={"number"}
                                                            value={""}
                                                            placeholder=" "
                                                            label={"Pincode"}
                                                            onInput={(e) => {
                                                                if (e.target.value.length > 6) {
                                                                    e.target.value = e.target.value.slice(0, 6);
                                                                }
                                                            }}
                                                            onChange={handleInputChange}
                                                            isFieldRequired={true}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.cra_pincode}</div>
                                                    </fieldset>
                                                </div>

                                                <div className="row">
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsySelect
                                                            name="cra_state"
                                                            id="cra_state"
                                                            inputProps={{
                                                                options: stateList,
                                                                placeholder: "Select State",
                                                                className: "react-select",
                                                                // required: "required",
                                                                classNamePrefix: "react-select",
                                                                value:
                                                                    ALL_OPTIONS &&
                                                                    ALL_OPTIONS["cra_state"] &&
                                                                    ALL_OPTIONS["cra_state"].filter(
                                                                        ({ value }) => value == formDetails["cra_state"]
                                                                    ),
                                                                // isDisabled: true,
                                                            }}
                                                            value={formDetails && formDetails["cra_state"]}
                                                            onChange={(data) => handleSelectChange(data, "cra_state")}
                                                            isFieldRequired={true}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.cra_state}</div>
                                                    </fieldset>
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsySelect
                                                            name="cra_city"
                                                            id="cra_city"
                                                            inputProps={{
                                                                options: cityList,
                                                                placeholder: "Select City",
                                                                className: "react-select",
                                                                // required: "required",
                                                                classNamePrefix: "react-select",
                                                                value: cityList && cityList.filter(({ value }) => value == formDetails?.cra_city),
                                                                // isDisabled: true,
                                                            }}
                                                            value={formDetails?.cra_city || 0}
                                                            onChange={(data) => { handleSelectChange(data, "cra_city"); }}
                                                            isFieldRequired={true}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.cra_city}</div>
                                                    </fieldset>
                                                </div>
                                            </>
                                        )}

                                        {!!([LeadStatusService.LEAD_STATUS_MASTER.LOGIN].includes(formDetails?.status_id) && lead.leadDetail.status_id != LeadStatusService.LEAD_STATUS_MASTER.LOGIN) && (
                                            <>
                                                <div className="row">
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsySelect
                                                            name="bank_id"
                                                            id="bank_id"
                                                            inputProps={{
                                                                options: ALL_OPTIONS && ALL_OPTIONS.bank_list,
                                                                placeholder: "Bank Name",
                                                                className: "react-select",
                                                                // required: "required",
                                                                classNamePrefix: "react-select",
                                                                value: ALL_OPTIONS && ALL_OPTIONS.bank_list && ALL_OPTIONS.bank_list.filter(({ value }) => value == formDetails?.bank_id),
                                                            }}
                                                            isFieldRequired={true}
                                                            value={formDetails?.bank_id || 0}
                                                            onChange={(data) => { handleSelectChange(data, "bank_id"); }}
                                                            errorMessage={errorMessageList?.bank_id || null}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.bank_id}</div>
                                                    </fieldset>
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsyDatePicker
                                                            name={"login_date"}
                                                            id={"login_date"}
                                                            selectedDate={formDetails?.login_date}
                                                            placeholder={"Login Date"}
                                                            fieldTitle={formDetails?.login_date ? "Login Date" : "NA"}
                                                            maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                                                            onDateSelect={(date) => handleDateChange(date, "login_date")}
                                                            DateFormate
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            isFieldRequired={true}
                                                            className={style.textarea}
                                                            style={{ border: "1px solid #cccccc" }}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.login_date}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"login_amount"}
                                                            name={"login_amount"}
                                                            type={"number-format"}
                                                            value={formDetails?.login_amount || ''}
                                                            placeholder=" "
                                                            label={"Login Amount"}
                                                            isFieldRequired={true}
                                                            thousandsGroupStyle="lakh"
                                                            thousandSeparator={','}
                                                            decimalSeparator={'.'}
                                                            onChange={(data) => handlePatternFormatChange(data, 'login_amount')}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.login_amount}</div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"login_id"}
                                                            name={"login_id"}
                                                            type={"text"}
                                                            value={formDetails?.login_id || ''}
                                                            placeholder=" "
                                                            label={"Login ID"}
                                                            isFieldRequired={true}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.login_id}</div>
                                                    </fieldset>
                                                </div>
                                            </>
                                        )}

                                        {!!([LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED].includes(formDetails?.status_id) && lead.leadDetail.status_id != LeadStatusService.LEAD_STATUS_MASTER.SANCTIONED) && (
                                            <>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"approved_amount"}
                                                            name={"approved_amount"}
                                                            type={"number-format"}
                                                            value={formDetails?.approved_amount || ''}
                                                            placeholder=" "
                                                            label={"Sanctioned Amount"}
                                                            isFieldRequired={true}
                                                            thousandsGroupStyle="lakh"
                                                            thousandSeparator={','}
                                                            decimalSeparator={'.'}
                                                            onChange={(data) => handlePatternFormatChange(data, 'approved_amount')}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.approved_amount}</div>
                                                    </fieldset>
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsyDatePicker
                                                            name={"approval_date"}
                                                            id={"approval_date"}
                                                            selectedDate={formDetails?.approval_date}
                                                            placeholder={"Sanctioned Date"}
                                                            fieldTitle={formDetails?.approval_date ? "Sanctioned Date" : "NA"}
                                                            maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                                                            minDate={lead?.leadDetail?.lead_details?.login_date ? new Date(lead.leadDetail.lead_details.login_date) : null}
                                                            onDateSelect={(date) => handleDateChange(date, "approval_date")}
                                                            DateFormate
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            isFieldRequired={true}
                                                            className={style.textarea}
                                                            style={{ border: "1px solid #cccccc" }}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.approval_date}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"approved_emi"}
                                                            name={"approved_emi"}
                                                            type={"number-format"}
                                                            value={formDetails?.approved_emi || ''}
                                                            placeholder=" "
                                                            label={"Sanctioned EMI"}
                                                            isFieldRequired={true}
                                                            thousandsGroupStyle="lakh"
                                                            thousandSeparator={','}
                                                            decimalSeparator={'.'}
                                                            onChange={(data) => handlePatternFormatChange(data, 'approved_emi')}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.approved_emi}</div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"approval_number"}
                                                            name={"approval_number"}
                                                            type={"text"}
                                                            value={formDetails?.approval_number || ''}
                                                            placeholder=" "
                                                            label={"Sanctioned ID"}
                                                            isFieldRequired={true}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.approval_number}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"approved_roi"}
                                                            name={"approved_roi"}
                                                            type={"number"}
                                                            value={formDetails?.approved_roi || ''}
                                                            placeholder=" "
                                                            label={"Sanctioned ROI"}
                                                            isFieldRequired={true}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.approved_roi}</div>
                                                    </fieldset>
                                                </div>
                                            </>
                                        )}

                                        {!!([LeadStatusService.LEAD_STATUS_MASTER.DISBURSED].includes(formDetails?.status_id) && lead.leadDetail.status_id != LeadStatusService.LEAD_STATUS_MASTER.DISBURSED) && (
                                            <>
                                                <div className="row">
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsySelect
                                                            name="loan_type"
                                                            id="loan_type"
                                                            inputProps={{
                                                                options: ALL_OPTIONS && ALL_OPTIONS?.loan_type,
                                                                placeholder: "Loan Type",
                                                                className: "react-select",
                                                                // required: "required",
                                                                classNamePrefix: "react-select",
                                                                value: ALL_OPTIONS && ALL_OPTIONS?.loan_type && ALL_OPTIONS?.loan_type.filter(({ id }) => id == formDetails?.loan_type),
                                                            }}
                                                            required={true}
                                                            value={formDetails?.loan_type || 0}
                                                            onChange={(data) => handleSelectChange(data, "loan_type")}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.loan_type}</div>
                                                    </fieldset>
                                                    <fieldset className="single-select col-md-6">
                                                        <MultiSelect
                                                            options={ALL_OPTIONS && ALL_OPTIONS?.loan_sub_type}
                                                            placeholder={"Loan Sub Type"}
                                                            name={"loan_sub_type"}
                                                            id={"loan_sub_type"}
                                                            optionLabel="label"
                                                            optionValue="value"
                                                            values={formDetails?.loan_sub_type}
                                                            onChange={handleMultiSelectChange}
                                                            required={true}
                                                        />
                                                        {/* <FormsySelect
                                                            name="loan_sub_type"
                                                            id="loan_sub_type"
                                                            inputProps={{
                                                                options: ALL_OPTIONS && ALL_OPTIONS?.loan_sub_type,
                                                                placeholder: "Loan Sub Type",
                                                                className: "react-select",
                                                                // required: "required",
                                                                classNamePrefix: "react-select",
                                                                value: ALL_OPTIONS && ALL_OPTIONS?.loan_sub_type && ALL_OPTIONS?.loan_sub_type.filter(({ id }) => id == formDetails?.loan_sub_type),
                                                            }}
                                                            value={formDetails?.loan_sub_type || 0}
                                                            onChange={(data) => handleSelectChange(data, "loan_sub_type")}
                                                        /> */}
                                                        <div className="error-msg">{errorMessageList?.loan_sub_type}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset class="form-filed col-md-12 ">
                                                        <div style={{ marginBottom: 10 }}>Disbursal Type*</div>
                                                        <div className='followupradio' style={{ marginBottom: 10 }}>
                                                            <div className='followupcall'>
                                                                <input type="radio" id="tranche_type" name="tranche_type" value="1" onChange={handleInputChange} />
                                                                <label htmlFor="single">Single disbursal</label>
                                                            </div>
                                                            <div className='followupcall'>
                                                                <input type="radio" id="tranche_type" name="tranche_type" value="2" onChange={handleInputChange} />
                                                                <label htmlFor="Tranche">Tranche disbursal</label>
                                                            </div>
                                                        </div>
                                                        <div className="error-msg">{errorMessageList?.tranche_type}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"disbursed_amount"}
                                                            name={"disbursed_amount"}
                                                            type={"number-format"}
                                                            value={formDetails?.disbursed_amount || ''}
                                                            placeholder=" "
                                                            label={"Disbursal Amount"}
                                                            isFieldRequired={true}
                                                            thousandsGroupStyle="lakh"
                                                            thousandSeparator={','}
                                                            decimalSeparator={'.'}
                                                            onChange={(data) => handlePatternFormatChange(data, 'disbursed_amount')}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.disbursed_amount}</div>
                                                    </fieldset>
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsyDatePicker
                                                            name={"disbursed_date"}
                                                            id={"disbursed_date"}
                                                            selectedDate={formDetails?.disbursed_date}
                                                            placeholder={"Disbursal Date"}
                                                            fieldTitle={formDetails?.disbursed_date ? "Disbursal Date" : "NA"}
                                                            minDate={lead?.leadDetail?.lead_details?.approval_date ? new Date(lead.leadDetail.lead_details.approval_date) : null}
                                                            maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                                                            onDateSelect={(date) => handleDateChange(date, "disbursed_date")}
                                                            DateFormate
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            isFieldRequired={true}
                                                            className={style.textarea}
                                                            style={{ border: "1px solid #cccccc" }}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.disbursed_date}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsySelect
                                                            name="disbursed_tenure"
                                                            id="disbursed_tenure"
                                                            inputProps={{
                                                                options: ALL_OPTIONS && ALL_OPTIONS?.disbursed_tenure,
                                                                placeholder: "Disbursal Tenure",
                                                                className: "react-select",
                                                                // required: "required",
                                                                classNamePrefix: "react-select",
                                                                value: ALL_OPTIONS && ALL_OPTIONS?.disbursed_tenure && ALL_OPTIONS?.disbursed_tenure.filter(({ id }) => id == formDetails?.disbursed_tenure),
                                                            }}
                                                            isFieldRequired={true}
                                                            value={formDetails?.disbursed_tenure || 0}
                                                            onChange={(data) => handleSelectChange(data, "disbursed_tenure")}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.disbursed_tenure}</div>
                                                    </fieldset>
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"disbursed_id"}
                                                            name={"disbursed_id"}
                                                            type={"text"}
                                                            value={formDetails?.disbursed_id || ''}
                                                            placeholder=" "
                                                            label={"Disbursal ID"}
                                                            isFieldRequired={true}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.disbursed_id}</div>
                                                    </fieldset>
                                                </div>
                                                <div className="row">
                                                    <fieldset className="form-filed col-md-6">
                                                        <FormsyInputField
                                                            id={"disbursed_roi"}
                                                            name={"disbursed_roi"}
                                                            type={"number"}
                                                            value={formDetails?.disbursed_roi || ''}
                                                            placeholder=" "
                                                            label={"Disbursal ROI"}
                                                            isFieldRequired={true}
                                                            onChange={handleInputChange}
                                                        />
                                                        <div className="error-msg">{errorMessageList?.disbursed_roi}</div>
                                                    </fieldset>
                                                </div>
                                            </>
                                        )}

                                        <div className="row">
                                            <fieldset className="form-filed col-md-6 ">
                                                <FormsyDatePicker
                                                    name={"follow_up_datetime"}
                                                    id={"follow_up_datetime"}
                                                    selectedDate={followupStatus?.follow_up_datetime}
                                                    placeholder={"Follow-up"}
                                                    fieldTitle={followupStatus?.follow_up_datetime ? "Follow-up" : "NA"}
                                                    value={followupStatus?.follow_up_datetime}
                                                    onDateSelect={(date) => handleDateChange(date, "follow_up_datetime")}
                                                    DateFormate
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    showTimeSelect={true}
                                                    timeIntervals={15}
                                                    minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                                    minTime={minTime}
                                                    disabled={false}
                                                    hasError={false}
                                                    className={style.textarea}
                                                    isFieldRequired={
                                                        !(
                                                          validStatusIds.includes(formDetails?.status_id) &&
                                                          validSubStatusIds.includes(formDetails?.sub_status_id)
                                                        )
                                                      }
                                                     style={{ border: "1px solid #cccccc" }}
                                                />
                                                <div className="error-msg">{errorMessageList?.follow_up_datetime}</div>
                                            </fieldset>
                                            <fieldset className="single-select col-md-6">
                                                <FormsySelect
                                                    placeholder="Select"
                                                    name="pending_with"
                                                    id="pending_with"
                                                    isFieldRequired={
                                                        !(
                                                          validStatusIds.includes(formDetails?.status_id) &&
                                                          validSubStatusIds.includes(formDetails?.sub_status_id)
                                                        )
                                                      }
                                                    inputProps={{
                                                        options: FollowUpService.FOLLOW_UPS_WITH_UPDATE_FORM(),
                                                        placeholder: "Pending With",
                                                        className: "react-select",
                                                        // required: "required",
                                                        classNamePrefix: "react-select",
                                                        value: FollowUpService.FOLLOW_UPS_WITH_UPDATE_FORM().filter(
                                                            ({ value }) =>
                                                                value === formDetails?.pending_with
                                                        ),
                                                    }}
                                                    onChange={(data) => { handleSelectChange(data, "pending_with"); }}
                                                />
                                                <div className="error-msg">{errorMessageList?.pending_with}</div>
                                            </fieldset>
                                        </div>

                                        <div className="row">
                                            <fieldset class="col-md-12">
                                                <div class="material">
                                                    <textarea
                                                        rows={3}
                                                        id={"remark"}
                                                        name={"remark"}
                                                        type={"text"}
                                                        value={followupStatus?.remarks || ""}
                                                        placeholder=""
                                                        onChange={(e) => setFollowupStatus(prev => ({ ...prev, remarks: e.target.value }))}
                                                        className={`form-input`}
                                                        style={{ border: "1px solid #cccccc" }}
                                                    ></textarea>
                                                    <label
                                                        data-label={"Remark"}
                                                        class="form-label"
                                                    ></label>
                                                </div>
                                            </fieldset>
                                        </div>
                                        {!![SANCTIONED].includes(formDetails?.status_id) && (
                                             <>
                                                        <label  className="file-upload-sanction">
                                                             Upload Sanction Letter
                                                        <div>
                                                            { sanctionUploaded ? 
                                                                <>
                                                               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 48 48">
                                                                <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                                                                </svg>
                                                                </>
                                                            : 
                                                            <>
                                                                <i className="ic-upload" style={{ fontSize: 24 }}> </i>
                                                                <input multiple={true} type="file" name="file" id="upload" class="" onChange={(e)=>{handleFileChange(e)}} />
                                                            </>
                                                            }
                                                      
                                                    </div>
                                                    </label>
                                              </>
                                            )}
                                        <div className="row">
                                            <fieldset className="react-select col-mb-6">
                                                <button
                                                    type="submit"
                                                    onClick={handleSaveButton}
                                                    className={`${style.saveButton} ${isLoading ? style.disabledButton : ""}`}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? "Saving..." : "Save"}
                                                </button>
                                            </fieldset>

                                            {!!([INTERESTED_CONTACTED].includes(formDetails?.status_id) && ![LeadStatusService.LEAD_SUB_STATUS_MASTER.NOT_INTERESTED_CONTACTED.NOT_APPLIED_FOR_LOAN, LeadStatusService.LEAD_SUB_STATUS_MASTER.INTERESTED_CONTACTED.DEAL_CANCELLED_POSTPONED__CONTACTED].includes(formDetails?.sub_status_id)) && (
                                                <fieldset className="react-select col-mb-6">
                                                    <button
                                                        type="button"
                                                        onClick={() => { handleSaveButton('qualify-modal'); }}
                                                        className={`${style.saveButton} ${isLoading ? style.disabledButton : ""}`}
                                                        disabled={isLoading}
                                                    >
                                                        Save and qualify
                                                    </button>
                                                </fieldset>
                                            )}
                                        </div>
                                    </Formsy>
                                </div>
                              
                                {/* <p>{JSON.stringify(lead, null, 2)}</p> */}
                                {/* <p>
                                    {Object.entries(lead).map(([key, value]) => (
                                        <div key={key}>
                                            <strong>{key}:</strong> {JSON.stringify(value, null, 2)}
                                        </div>
                                    ))}
                                </p> */}
                            </div>
                        </>
                    )}
                </div>
                {/* <div className="mark-lost-popup">
                    <PreferedBankModel BankModelShow={PreffredBank} hideModalPreffredBank={hideModalPreffredBank} />
                    </div> 
                */}
            </div>

            <div className="mark-lost-popup">
                <OpenModalForm show={modalSavequalify}
                    handleClose={handleCloseSaveQualifyModal}
                    removeHeight={true}
                    width={800}
                >
                    {modalSavequalify && (
                        <SaveAndQualify
                            PropertyIdentifiedForm={PropertyIdentifiedForm}
                            PropertyIdentifiedFormFirstPart={PropertyIdentifiedFormFirstPart}
                            PropertyIdentifiedFormSecondPart={PropertyIdentifiedFormSecondPart}
                            updateSetFormDetails={updateSetFormDetails}
                            formDetails={formDetails}
                            handleSaveButton={handleSaveButton}
                            errorMessageList={errorMessageList}
                            isLoading={isLoading}
                        />
                    )}
                </OpenModalForm>
            </div>

            {remarkModal && (
                <OpenModalForm
                    show={remarkModal}
                    handleClose={handleCloseModal}
                    removeHeight={true}
                >
                    <LeadRemarkModal handleClose={handleCloseModal} />
                </OpenModalForm>
            )}
        </>
    );
};

export default LeadUpdateStatusForm;
