import Q from "q";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import ModalPopup from "../../elements/Popup";
import {
  BANK_ACCOUNT_NUMBER,
  BENIFICIERY_NAME,
} from "../../../config/partnerConstant";
import secureStorage from "../../../config/partnerEncrypt";
import { DealerService, InventoryService } from "../../../services/index";
import MasterService from "../../../services/MasterService";
import KycAllComment from "./KycAllComment";
import KycComment from "./KycComment";
import KycDocument from "./KycDocument";
// import ViewHistory from './ViewHistory';
import { executeGraphQLQueryFinex } from "../../../common/executeGraphQLQueryFinex";
import { FINEX_MASTER } from "../../../services/scheme.gql";
import greenTickSvg from "../../../webroot/images/greentick.svg";
import redTickSvg from "../../../webroot/images/redtick.svg";

import nonVeryfiedSvg from "../../../webroot/images/norverified.svg";
import saveSvg from "../../../webroot/images/saveicon.svg";



class KycDetails extends Component {
  constructor(props) {
      super(props);
      this.handleSelect = this.handleSelect.bind(this);
      this.handleChangetext = this.handleChangetext.bind(this);
      this.addNewKycDetail = this.addNewKycDetail.bind(this);
      this.deleteNewKycDetail = this.deleteNewKycDetail.bind(this);
      this.state = {
          key: 1,
          city_id: "",
          loading: true,
          bank_state_id: "",
          location: "",
          dealership: "",
          business: "",
          filteredcity: props.cityAllList || [],
          kyccategory: [],
          kycdoc: [],
          kycMasterlist: props.kycMasterlist || [],
          dealer_id_hash: "",
          startDate: new Date(),
          kycUploadFile: [],
          kycDealerData: {},
          kycDealerDetail: {},
          kycDealerDocData: [],
          kycDealerAddDoc: [{ file_url: "", kyc_category_id: 1 }],
          kycDealerCallVerificationData: [],
          basic_details: props.basic_details || {},
          kyc_category_id: '',
          errors: {},
          kyc_errors: [],
          kyc_doc_errors: [],
          kyc_upload_doc_errors: [],
          cv_link_errors: {},
          addNewBankDetail: false,
          bankList: [],
          basic_details: props.basic_details || {},
          activeTab: 'tab1',
          showCommentMsg: false,
          showAllCommentMsg: false,
          kycMasterlistAddKyc: [{
              id: 1,
              name: "Bank Statement",
              status: 1
          }, {
              id: 2,
              name: "Bank Cover",
              status: 1
          }],
          account_length: 0,
          historyPopup: false,
          can_verify: false,
          loginUserId:0,
      }
  }
  handleTabClick = (tabId) => {
      this.setState({ activeTab: tabId });
  };

  handleKycIcon = () => {
      setTimeout( async ()  => {
      await this.getDealerDocKycDetail();
      }, 1000);
  }

  componentDidMount = async () => {
      await this.getDealerKycDetail();
      await this.getDealerDocKycDetail();
      await this.getDealerCallVerificationKycDetail();
      await this.getBankList();
      let token = JSON.parse(secureStorage.storage.user_information);
      this.setState({ loginUserId: token?.user_data?.user_id || 0 });
  }

  closeHistoryModel = () => {
      this.setState({ historyPopup: false });
  }

  handleHistory = () => {
      this.setState({ historyPopup: true });
  }

  // getBankList = async()=>{
  //     DealerService.getMasters(["bank_list"]).then((response) => {
  //        // console.log("response.data.data.bank_list",response.data.data.bank_list);
  //         if (response && response.status == 200 && response.data.status == 200) {
  //             this.setState({ bankList: response.data.data.bank_list});
  //         }
  //     }).catch((error) => {
  //         this.setState({loading:false})
  //     });
  // }
  getBankList = async () => {
      const masterData = await executeGraphQLQueryFinex(FINEX_MASTER()).catch(err => console.log(err));
      if (masterData && masterData.data && masterData.data.masterdata) {
          const bankData = [];
          const bank_list = masterData.data.masterdata.bank_list || [];
          bank_list.forEach(bank => {
              bankData.push({ bank_id: bank.id, bankname: bank.banklang.bank_name });
          });
          this.setState({ bankList: bankData });
      } else {
          this.setState({ bankList: [] });
          return [];
      }
  }
  get get_dealer_id_hash() {
      return this.props.dealer_id_hash
  }
  handlebankListOptionChange = (ovalue) => {
      let errors = this.state.errors
      let kycMasterlist_new = { ...this.state.kycDealerData }
      kycMasterlist_new.bank_id = ovalue.bank_id;
      kycMasterlist_new.bank_account_no = ""
      kycMasterlist_new.bank_account_type = ""
      kycMasterlist_new.beneficiary_name = ""
      kycMasterlist_new.bank_ifsc_code = ""
      kycMasterlist_new.confirm_bank_account_no = ""
      errors.bankname = ""
      errors.acno = ""
      errors.account_type = ""
      errors.beneficiary_name = ""
      errors.coacno = ""

      this.setState({ kycDealerData: kycMasterlist_new, account_length: ovalue.valid_length, errors: errors }, () => {
      })
  }
  handleStateOptionChange = () => (ovalue) => {
      this.state.kycDealerData.bank_state_id = ovalue.id;
      let citylist = [...this.state.cityAllList];
      const result = citylist && citylist.filter(cityinfo => cityinfo.state_id == ovalue.id) || [];
      this.setState({ filteredcity: result })
  }
  handleDocCategoryOptionChange = (idx) => (ovalue) => {
      let kycDealerDoc = [...this.state.kycDealerDocData];
      kycDealerDoc[idx].kyc_master_id = ovalue.id;
      this.setState({ kycDealerDocData: kycDealerDoc })
  }
  handleChange = date => {
      this.setState({
          startDate: date
      });
  };
  handleSelect(key) {
      this.setState({ key });
  }
  handledealertype = (sname, ovalue) => {
      let chkval = ovalue.target.value;
      let kycMasterlist_new = { ...this.state.kycDealerData }
      kycMasterlist_new.bank_account_type = chkval;
      this.setState({ kycDealerData: kycMasterlist_new }, () => {
      })
  }

  handleChange = (sname, ovalue) => {
      this.setState({
          [sname]: ovalue.value
      });

  }
  handletKycDealerDataTextChange = (sname, ovalue) => {
      let kycMasterlist_new = { ...this.state.kycDealerData }
      kycMasterlist_new[sname] = ovalue.target.value;
      this.setState({ kycDealerData: kycMasterlist_new }, () => {
      })
  }
  handletkycDealerDocTextChange = (sname, index, ovalue) => {
      let kycMasterlist_new = [...this.state.kycDealerDocData];
      kycMasterlist_new[index][sname] = ovalue.target.value;
      this.setState({ kycDealerDocData: kycMasterlist_new }, () => {

      })
  }

  handleChangeVerifiedDocs = (sname, index, e) => {
      let is_active = e.target.checked ? '1' : '0';
      let kycMasterlist_new = [...this.state.kycDealerDocData];
      kycMasterlist_new[index][sname] = is_active;
      this.setState({ kycDealerDocData: kycMasterlist_new }, () => {
      })
  }
  handletkycDealerCallVerificationTextChange = (sname, index, ovalue) => {
      let kycMasterlist_new = [...this.state.kycDealerCallVerificationData];
      kycMasterlist_new[index][sname] = ovalue.target.value;
      this.setState({ kycDealerCallVerificationData: kycMasterlist_new }, () => {
      })
  }

  handleCityOptionChange = (sname, ovalue) => {
      let kycMasterlist_new = { ...this.state.kycDealerData }
      kycMasterlist_new.bank_city_id = ovalue.id;
      this.setState({ kycDealerData: kycMasterlist_new }, () => {
      })

  }

  addNewKycDetail() {
      this.setState({ addNewBankDetail: true });
  }

  deleteNewKycDetail() {
      this.setState({ addNewBankDetail: false });
  }
  handleChangetext = (event) => {
      this.setState({
          [event.target.name]: event.target.value
      });
  }
  componentWillReceiveProps(nextProps) {
      this.setState({
          basic_details: nextProps.basic_details,
          cityAllList: nextProps.cityAllList,
          filteredcity: nextProps.cityAllList,
          kycMasterlist: nextProps.kycMasterlist,
      });
  }
  stringifyFormData(fd) {
      var object = {};
      fd.forEach((value, key) => {
          if (!object.hasOwnProperty(key)) {
              object[key] = value;
              return;
          }
          if (!Array.isArray(object[key])) {
              object[key] = [object[key]];
          }
          object[key].push(value);
      });
      return JSON.parse(JSON.stringify(object));
  }
  handleKycDocChange = (idx) => (ovalue) => {
      let kycDealerDocData_new = [...this.state.kycDealerDocData]
      kycDealerDocData_new[idx].kyc_child_id = ovalue.kyc_child_id;
      kycDealerDocData_new[idx].doc_number = '';
      this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
      })
  }

  getDealerKycDetail = async () => {
      DealerService.getKycDetails(this.get_dealer_id_hash).then(result => {
          if (result && result.data && result.data.status == 200) {
              let tempkycDealerData = [];
              let address1 = '';
              let address2 = '';
              if (result.data && result.data.data && result.data.data.length > 0) {
                  tempkycDealerData = result.data.data
              }
              this.setState({ kycDealerData: tempkycDealerData, kycDealerDetail: tempkycDealerData, loading: false })
          }
          else {
              this.setState({ loading: false })
              toast.error(result.data.message || "Some Error Occurred");
          }
      }).catch(error => {
          this.setState({ loading: false })
          toast.error(error.message || "Some Error Occurred");
      });
  }
  getDealerDocKycDetail = async () => {
      const verifiedDocs = []
      DealerService.getDocKycDetails(this.get_dealer_id_hash).then(result => {
          if (result && result.data && result.data.status == 200) {
              this.setState({ kycDealerDocData: result.data.data }, () => { });
              result.data.data && result.data.data.length > 0 && result.data.data.map(docsData => {
                  if (docsData.verified_status == '1') {
                      verifiedDocs.push(docsData.kyc_master_id);
                  }
              })
              if (verifiedDocs.length >= 3) {
                  this.setState({ can_verify: true });
              }
          }
          else {
              toast.error(result.data.message || "Some Error Occurred");
          }
      }).catch(error => {
          toast.error(error.message || "Some Error Occurred");
      });
  }

  getDealerCallVerificationKycDetail = async () => {
      DealerService.kycDealerCallVerificationData(this.get_dealer_id_hash).then(result => {
          if (result && result.data && result.data.status == 200) {
              this.setState({ kycDealerCallVerificationData: result.data.data }, () => { });
          }
          else {
              toast.error(result.data.message || "Some Error Occurred");
          }
      }).catch(error => {
          toast.error(error.message || "Some Error Occurred");
      });
  }


  s3UploadFiles = (allfiles, formPostdata) => {

      let dealerKycDocSaveArr = [];
      if (allfiles.length) {
          this.setState({ loading: true })
          let prom = [];
          allfiles.forEach((fileskyc, index) => {
              let filesData = fileskyc.files;
              var formData = new FormData();

              formData.append("upload_type", 'customer_doc');
              formData.append("dealer_id", this.state.basic_details.id);
              if (filesData.length) {
                  formData.append('images', filesData[0]);
                  prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
              }
              else {
                  prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
              }
          });

          Q.allSettled(prom)
              .then((op) => {
                  op.forEach((res, index) => {
                      let response = res.value || [];
                      if (res.state == 'fulfilled') {
                          //console.log(formPostdata);
                          // return true;
                          if (response.data.data.length) {
                              let tempFileName = '';
                              let tempFileUrl = '';
                              let temp_kyc_master_id = 0;
                              let dealerKycDocSaveJson = {};

                              if (formPostdata.kyccategory.length > 1) {
                                  dealerKycDocSaveJson = {
                                      "kyc_master_id": formPostdata.kyccategory[index],
                                      "kyc_child_id": formPostdata.kycdoc[index],
                                      "doc_number": (formPostdata.docnumber[index]) ? (formPostdata.docnumber[index]) : '',
                                      "comment": formPostdata.Comment[index] ? formPostdata.Comment[index] : '',
                                      "verified_status": '1'
                                      //"verified_status": formPostdata.verified_status_new && (formPostdata.verified_status_new[index] =='on' || formPostdata.verified_status_new[index] =='1') ? '1' : '0'
                                  }
                                  if (formPostdata.dc_dealer_kyc_doc[index] != null && formPostdata.dc_dealer_kyc_doc[index] > 0) {
                                      let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc[index];
                                      dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                  }
                                  if (formPostdata.saved_file_name[index] != null && formPostdata.saved_file_url[index]) {
                                      dealerKycDocSaveJson.file_name = formPostdata.saved_file_name[index];
                                      dealerKycDocSaveJson.file_url = formPostdata.saved_file_url[index];

                                  }
                              } else {

                                  dealerKycDocSaveJson = {
                                      "kyc_master_id": formPostdata.kyccategory,
                                      "kyc_child_id": formPostdata.kycdoc,
                                      "doc_number": (formPostdata.docnumber) ? formPostdata.docnumber : '',
                                      "comment": (formPostdata.Comment) ? formPostdata.Comment : '',
                                      "verified_status": '1'
                                      //"verified_status": formPostdata.verified_status_new  && (formPostdata.verified_status_new[index] =='on' || formPostdata.verified_status_new[index]) =='1' ? '1' : '0'
                                  }
                                  if (formPostdata.dc_dealer_kyc_doc != null && formPostdata.dc_dealer_kyc_doc > 0) {
                                      let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                                      dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                  }
                                  if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                      dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                      dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                  }
                              }
                              if (response.data.data[0] != 'not_updated') {
                                  tempFileName = response.data.data[0].file_name;
                                  tempFileUrl = response.data.data[0].file_url;
                                  dealerKycDocSaveJson["file_name"] = tempFileName;
                                  dealerKycDocSaveJson["file_url"] = tempFileUrl;
                              }

                              dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                          }
                      }
                  });

                  let postData = {
                      dealer_id_hash: this.get_dealer_id_hash,
                      docs: dealerKycDocSaveArr
                  };
                  DealerService.saveKycDocDetails(postData).then(result => {
                      if (result && result.data && result.data.status == 200) {
                          this.setState({ loading: false })
                          toast.success(result.data.message, {
                              // onClose: () => window.location.reload(true)
                          });

                      }
                      else {
                          this.setState({ loading: false })
                          toast.error(result.data.message || "Some Error Occurred");
                      }
                  }).catch(error => {
                      this.setState({ loading: false })
                      toast.error(error.message || "Some Error Occurred");
                  });
              })
              .catch((error) => {
                  this.setState({ loading: false })
                  toast.error(error.message || "Some Error Occurred");
              });
      }
  }
  s3UploadBankDetailFiles = (allfiles, formPostdata, kyc_id) => {
      let dealerKycDocSaveArr = [];
      if (allfiles.length) {
          this.setState({ loading: true })
          let prom = [];
          allfiles.forEach((fileskyc, index) => {
              let filesData = fileskyc.files;
              var formData = new FormData();
              formData.append("upload_type", 'customer_doc');
              formData.append("dealer_id", this.state.basic_details.id);
              if (filesData.length) {
                  formData.append('images', filesData[0]);
                  prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
              }
              else {
                  prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
              }
          });
          Q.allSettled(prom)
              .then((op) => {
                  op.forEach((res, index) => {
                      let response = res.value || [];
                      if (res.state == 'fulfilled') {
                          if (response.data.data.length) {
                              let tempFileName = '';
                              let tempFileUrl = '';
                              let temp_kyc_master_id = 0;
                              let dealerKycDocSaveJson = {};
                              if (formPostdata.add_kyc_category.length > 1) {
                                  if (formPostdata.saved_file_name[index] != null && formPostdata.saved_file_url[index]) {
                                      dealerKycDocSaveJson.file_name = formPostdata.saved_file_name[index];
                                      dealerKycDocSaveJson.file_url = formPostdata.saved_file_url[index];
                                      dealerKycDocSaveJson.kyc_id = kyc_id;
                                      dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];
                                  }
                              } else {
                                  if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                      dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                      dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                      dealerKycDocSaveJson.kyc_id = kyc_id;
                                      dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];
                                  }
                              }
                              if (response.data.data[0] != 'not_updated') {
                                  tempFileName = response.data.data[0].file_name;
                                  tempFileUrl = response.data.data[0].file_url;
                                  dealerKycDocSaveJson["file_name"] = tempFileName;
                                  dealerKycDocSaveJson["file_url"] = tempFileUrl;
                                  dealerKycDocSaveJson.kyc_id = kyc_id;
                                  dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];

                              }
                              dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                          }
                      }
                  });
                  let postData = {
                      dealer_id_hash: this.get_dealer_id_hash,
                      docs: dealerKycDocSaveArr
                  };
                  DealerService.saveKycBankDocDetails(postData).then(result => {
                      if (result && result.data && result.data.status == 200) {
                          this.setState({ addNewBankDetail: false, loading: false });
                          this.getDealerKycDetail();
                          toast.success(result.data.message);
                          toast.success(result.data.message, {
                              onClose: () => window.location.reload(true)
                          });

                      }
                      else {
                          this.setState({ loading: false })
                          toast.error(result.data.message || "Some Error Occurred");
                      }
                  }).catch(error => {
                      this.setState({ loading: false })
                      toast.error(error.message || "Some Error Occurred");
                  });
              })
              .catch((error) => {
                  this.setState({ loading: false })
                  toast.error(error.message || "Some Error Occurred");
              });
      }
  }
  saveBankDetail = async (event) => {
      event.preventDefault();
      var parentThis = this;
      // alert("Hello");
      const form = event.target;
      const data = new FormData(form);
      const formdata = this.stringifyFormData(data);
      if (this.validFormData(formdata)) {
          this.setState({ loading: true });
          var r = window.confirm(this.props.t('dealerDetails.kyc_details.warning_alert_msg'));
          if (r == true) {
              let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
              let dealerKycSaveJson = {
                  "dealer_id_hash": this.get_dealer_id_hash,
                  "bank_account_type": 'saving',
                  "bank_account_no": formdata.acno,
                  "confirm_bank_account_no": formdata.acno,
                  "beneficiary_name": formdata.beneficiary_name,
                  "bank_ifsc_code": formdata.bank_ifsc_code
                  // "bank_ifsc_code": formdata.ifsccode
              };
              DealerService.saveKycDetails(dealerKycSaveJson).then(result => {
                  if (result && result.data && result.data.status == 200) {
                      this.s3UploadBankDetailFiles(allfiles, formdata, result.data.data.id);
                      this.setState({ kycDealerAddDoc: [{ file_url: "", kyc_category_id: 1 }] })
                      this.getDealerKycDetail();
                      toast.success(result.data.message);
                  }
                  else {
                      this.setState({ loading: false })
                      toast.error(result.data.message || "Some Error Occurred");
                  }
              }).catch(error => {
                  this.setState({ loading: false })
                  toast.error(error.message || "Some Error Occurred");
              });
          } else {
              this.setState({ loading: false })
          }
      }
  }
  saveKycDetail = (event) => {
      event.preventDefault();
      var parentThis = this;
      const form = event.target;
      const data = new FormData(form);
      const formdata = this.stringifyFormData(data);
      var formIsValid = false;
      var kyc_errors = [...this.state.kyc_errors];
      var error = {};
      formdata && formdata.length > 0 && Object.keys(formdata).forEach((key) => {
          if (key == 'kyccategory' || key == 'kycdoc' || key == 'docnumber' || key == "verified_status_new") {
              let key_error_msg = '';
              if (key == 'kyccategory') {
                  key_error_msg = 'key category';
              } else if (key == 'kycdoc') {
                  key_error_msg = 'key doc';
              } else if (key == 'docnumber') {
                  key_error_msg = 'key doc number';
              }
              if (Array.isArray(formdata[key]) && formdata[key].length > 0) {
                  formdata[key].forEach((fileskyc, index) => {
                      let value = formdata[key][index];
                      let key_doc_value = formdata['kycdoc'][index];
                      if (key == 'docnumber') {
                          let tempError = {};
                          kyc_errors[index] = tempError;
                          if (value != '' && key_doc_value == 2) {
                              if (!value.match(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\.[0-9]\-[0-9]{3}\.[0-9]{3}$/)) {
                                  error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
                                  tempError['docnumber'] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
                                  kyc_errors[index] = tempError;
                              }
                          }
                      } else if (!value) {
                          error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                          kyc_errors[index] = error;
                      }
                  })
              } else {
                  let value = formdata[key];
                  if (!value) {
                      error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                      kyc_errors[0] = error;
                  }
              }

          }

      })
      this.setState({ kyc_errors: kyc_errors }, () => { })
      if ((Object.keys(error).length) == 0) {
          formIsValid = true;
      }
      if (formIsValid) {
          let allfiles = document.querySelectorAll('input[type="file"]');
          this.s3UploadFiles(allfiles, formdata);
      } else {
          toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
      }

  }
  saveKycCallVerificationDetail = (event) => {
      event.preventDefault();
      var parentThis = this;
      let dealerKycCallVerificationSaveArr = [];
      const form = event.target;
      const data = new FormData(form);
      const formdata = this.stringifyFormData(data);
      var formIsValid = false;
      var error = {};
      if ((Object.keys(error).length) == 0) {
          formIsValid = true;
      }
      let dealerKycDocSaveJson = {};
      if (formIsValid) {
          if (Array.isArray(formdata.comment)) {
              for (let index = 0; index < formdata.comment.length; index++) {
                  dealerKycDocSaveJson = {
                      "comment": formdata.comment[index],
                      "link": formdata.link[index]
                  }
                  if (formdata.dc_dealer_kyc_call_verification_id[index] != null && formdata.dc_dealer_kyc_call_verification_id[index] > 0) {
                      let id = formdata.dc_dealer_kyc_call_verification_id[index];
                      dealerKycDocSaveJson.id = id;
                  }
                  // if (!dealerKycDocSaveJson.link.trim()) error[index + "_link"] = "required";
                  dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
              }
          } else {
              dealerKycDocSaveJson = {
                  "comment": formdata.comment,
                  "link": formdata.link
              }
              if (formdata.dc_dealer_kyc_call_verification_id != '') {
                  let id = formdata.dc_dealer_kyc_call_verification_id;
                  dealerKycDocSaveJson.id = id;
              }
              // if (!dealerKycDocSaveJson.link.trim()) error["0_link"] = "required";
              dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
          }
          let postData = {
              dealer_id_hash: this.get_dealer_id_hash,
              docs: dealerKycCallVerificationSaveArr
          };
          // if(Object.keys(error).length > 0) {
          //     this.setState({ cv_link_errors: error }, () => { });
          //     toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
          //     return;
          // }
          // this.setState({ cv_link_errors: error }, () => { });

          DealerService.saveKycCallVerificationDetails(postData).then(result => {
              if (result && result.data && result.data.status == 200) {
                  this.setState({ loading: false })
                  toast.success(result.data.message, {
                      onClose: () => window.location.reload(true)
                  });
              }
              else {
                  this.setState({ loading: false })
                  toast.error(result.data.message || "Some Error Occurred");
              }
          }).catch(error => {
              this.setState({ loading: false })
              toast.error(error.message || "Some Error Occurred");
          });
      } else {
          toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
      }
  }

  deleteKycDocRow = (idx) => {
      let dealerKycDocRow = [...this.state.kycDealerDocData];
      dealerKycDocRow.splice(idx, 1);
      this.setState({ kycDealerDocData: dealerKycDocRow });
  }
  deleteKycDocRowCategory = (idx) => {
      let dealerKycDocRowData = [...this.state.kycDealerAddDoc];
      let kyc_doc_errors_msg = [...this.state.kyc_doc_errors];
      let kyc_upload_doc_errors_msg = [...this.state.kyc_upload_doc_errors];
      dealerKycDocRowData.splice(idx, 1);
      kyc_doc_errors_msg.splice(idx, 1);
      kyc_upload_doc_errors_msg.splice(idx, 1);
      this.setState({ kycDealerAddDoc: dealerKycDocRowData, kyc_doc_errors: kyc_doc_errors_msg, kyc_upload_doc_errors: kyc_upload_doc_errors_msg });
  }
  deleteKycCallVerificationRow = (idx) => {
      let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
      dealerKycDocRow.splice(idx, 1);
      this.setState({ kycDealerCallVerificationData: dealerKycDocRow });
  }
  removecurrentdoc = (idx) => {
      let kycDealerDocData_new = [...this.state.kycDealerDocData]
      kycDealerDocData_new[idx].file_url = null;
      this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
      })
  }
  addKycRow = () => {
      let dealerKycDocRow = [...this.state.kycDealerDocData];
      let newDealerKycPush = { 'file_url': '' };
      dealerKycDocRow.push(newDealerKycPush);
      this.setState({ kycDealerDocData: dealerKycDocRow })
  }
  addKycRowCategory = () => {
      let dealerKycDocRow = [...this.state.kycDealerAddDoc];
      let newDealerKycPush = { 'file_url': '' };
      dealerKycDocRow.push(newDealerKycPush);
      this.setState({ kycDealerAddDoc: dealerKycDocRow })
  }

  handleKycDocCategoryOptionChange = (idx) => (ovalue) => {
      let kycDealerDoc = [...this.state.kycDealerAddDoc];
      kycDealerDoc[idx].kyc_category_id = ovalue.id;
      this.setState({ kycDealerAddDoc: kycDealerDoc })
  }
  addKycCallVerificationRow = () => {
      let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
      let newDealerKycPush = {};
      dealerKycDocRow.push(newDealerKycPush);
      this.setState({ kycDealerCallVerificationData: dealerKycDocRow })
  }
  filterbankDoc = (masterId, docData,basidcInfo) => {
     let filteredData = docData.filter(item => {
      return (basidcInfo.is_name_matched === 1 && item.kyc_master_id === masterId);
     });   
      return  filteredData  && filteredData[0]?.file_url;
  }

  findIsValidatedKyc = (masterId, docData,basicinfo={}) => {
      let result;
      if (!docData || !Array.isArray(docData)) {
          result = { status: "0", rejectReason: null };
      }
      
     let filteredData = docData.filter(item => {
      return (item.verified_status === "1" && item.kyc_master_id === masterId && item.file_url);
     });
      if (filteredData.length > 0) {
          result = { status: filteredData[0].verified_status || "0", rejectReason: null };
      } else {
          let rejectedData = docData.find(item => item.reject_reason != null &&  item.kyc_master_id === masterId);
          if (rejectedData) {
              result = { status: "0", rejectReason: rejectedData.reject_reason };
          } else {
              result = { status: "0", rejectReason: null };
          }
      }
      return result;
  }
  validFormData = (formData) => {
      let errors = {};
      let formIsValid = true;
      var kyc_doc_errors = [...this.state.kyc_doc_errors];
      var kyc_upload_doc_errors = this.state.kyc_upload_doc_errors;

      // if (!formData['bankname']) {
      //     formIsValid = false;
      //     errors["bankname"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_name_required');
      // }

      // if (!formData['branch_name']) {
      //     formIsValid = false;
      //     errors["branch_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.branch_name_required');
      // }else if (!BRANCH_NAME.test(formData['branch_name'])) {
      //     formIsValid = false;
      //     errors["branch_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.branch_name_not_valid');
      // }

      if (!formData['beneficiary_name']) {
          formIsValid = false;
          errors["beneficiary_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.beneficiary_name_required');
      }
      if (!formData['bank_ifsc_code']) {
          formIsValid = false;
          errors["bank_ifsc_code_required"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_ifsc_code_required');
      }
      else if (!BENIFICIERY_NAME.test(formData['beneficiary_name'])) {
          formIsValid = false;
          errors["beneficiary_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.beneficiary_name_not_valid');
      }
      let bankListTemp = this.state.bankList
      var bank_list_selected = bankListTemp.filter(elm => parseInt(elm.bank_id) === parseInt(formData.bankname));
      let account_length = bank_list_selected && bank_list_selected.length ? bank_list_selected[0].valid_length : 0
      if (!formData['acno']) {
          formIsValid = false;
          errors["acno"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_required');
      }
      else if (!BANK_ACCOUNT_NUMBER.test(formData['acno'])) {
          formIsValid = false;
          errors["acno"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_not_valid');
      } else if (account_length > 0 && formData['acno'].length !== account_length) {
          formIsValid = false;
          errors["acno"] = 'Format of Account Number must ' + account_length + ' digits';
      }

      if (!formData['coacno']) {
          formIsValid = false;
          errors["coacno"] = this.props.t('dealerDetails.kyc_details.validation_texts.confirm_account_number_required');
      } else if (!BANK_ACCOUNT_NUMBER.test(formData['coacno'])) {
          formIsValid = false;
          errors["coacno"] = this.props.t('dealerDetails.kyc_details.validation_texts.confirm_account_number_not_valid');
      } else if (formData['coacno'].length !== account_length && account_length > 0) {
          formIsValid = false;
          errors["coacno"] = 'Format of Account Number must ' + account_length + ' digits';
      } else if (!(formData['acno'].trim() === "" && formData['coacno'].trim() === "") && formData['coacno'] !== formData['acno']) {
          formIsValid = false;
          errors["coacno"] = this.props.t('dealerDetails.kyc_details.validation_texts.number_not_matching');
      }

      // let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
      // Object.keys(formData).forEach((key) => {
      //     if (key == 'add_kyc_category') {
      //         let key_error_msg = '';
      //         let key_error_msg_new = 'Please upload DocFile';
      //         if (key == 'add_kyc_category') {
      //             key_error_msg = 'kyc category';
      //         }
      //         if (Array.isArray(formData[key]) && formData[key].length > 0) {
      //             formData[key].forEach((fileskyc, index) => {
      //                 let value = formData[key][index];
      //                 if (!value) {
      //                     errors[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
      //                     kyc_doc_errors[index] = errors;
      //                 }else{
      //                     delete kyc_doc_errors[index];
      //                 }
      //                 if (allfiles[index].files.length == 0) {
      //                     kyc_upload_doc_errors[index] = { 'doc_upload': this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg_new };
      //                 } else {
      //                     delete kyc_upload_doc_errors[index];
      //                 }
      //             })
      //         }
      //         else {
      //             kyc_upload_doc_errors[0] = { 'doc_upload': this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg_new };;
      //         }
      //     }

      // })
      // if(allfiles[0].files.length>0){
      //     delete kyc_upload_doc_errors[0];
      // }
      // this.setState({ kyc_doc_errors: kyc_doc_errors }, () => { })
      // if ((Object.keys(errors).length) == 0 && (Object.keys(kyc_upload_doc_errors).length) == 0) {
      //     formIsValid = true;
      //     this.setState({ kyc_doc_errors: [], kyc_upload_doc_errors: [] })
      // } else {
      //     formIsValid = false;
      // }
      this.setState({ errors: errors })
      return formIsValid;
  }

  handleChangeForFlip = (type, index, kycId) => async ({ target }) => {
      let kycDealerDetail = [...this.state.kycDealerDetail];
      let status = ''
      if (target.checked) {
          kycDealerDetail[index]['active_status'] = '1';
          kycDealerDetail[index]['id'] = kycId;
          status = '1';
      } else {
          kycDealerDetail[index]['active_status'] = '0';
          kycDealerDetail[index]['id'] = kycId;
          status = '0';
      }
      this.setState({ kycDealerDetail });
      this.setState({ [type]: true, kycId: kycId, currentIndex: index, active_status: status });
  }



  handleChangeForViewComment = (type, index, kyc_comment_data) => {
      let kycDealerDetail = [...this.state.kycDealerDetail];
      this.setState({ kycDealerDetail });
      this.setState({ [type]: true, currentIndex: index, comment: kyc_comment_data });
  }

  getObjectKeyValue = (kycMasterlist, value) => {
      const data = kycMasterlist.filter(a => a.id == value);
      const parseData = data[0];
      return parseData && parseData.name ? parseData.name : "";
  }

  closeModal = (flag = true) => {
      if (flag !== false) {
          let index = this.state.currentIndex;
          let kycDealerDetail = [...this.state.kycDealerDetail];
          if (kycDealerDetail[index]['active_status'] == '1') {
              kycDealerDetail[index]['active_status'] = '0';
          } else {
              kycDealerDetail[index]['active_status'] = '1';
          }
          this.setState({ kycDealerDetail });
      }
      this.setState({ showCommentMsg: false, showAllCommentMsg: false });
  }

  closeModalViewAllComment = () => {
      this.setState({ showCommentMsg: false, showAllCommentMsg: false });
  }


  onChangeHandler = event => {
      let files = event.target.files
      let err = [] // create empty array
      const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf', 'application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      for (var x = 0; x < files.length; x++) {
          if (types.every(type => files[x].type !== type)) {
              err[x] = files[x].type + ' ' + this.props.t('dealerDetails.kyc_details.kyc_file_support_msg') + ' \n';
              // assign message to array
          }
          if (files[x].size && files[x].size > 8000000) {
              err[x] = this.props.t('dealerDetails.kyc_details.kyc_file_size_error') + ' \n';
          }
      };
      for (var z = 0; z < err.length; z++) { // loop create toast massage
          event.target.value = null
          toast.error(err[z])
      }
      return true;
  }
  handleChangeAccountStatus = (dealer_id_hash, current_status) => async ({ target }) => {
      let isChecked = target.checked;
      //  alert(isChecked)
      await this.changeDealerStatus(target, isChecked, current_status, dealer_id_hash);
  }

  async changeDealerStatus(target, isChecked, current_status, dealer_id_hash) {
      // alert(isChecked+'-'+current_status+'-'+dealer_id_hash)
      let org = { ...this.state.basic_details };
      let active = isChecked ? "Active" : "Inactive";
      var r = window.confirm(this.props.t('Listing.Search_Result.alert_active_inactive', { status: active, dealer_name: org.organization }));
      let dealerUpdateData = { ...this.state.basic_details };

      if (r == true) {
          if (isChecked) {
              dealerUpdateData['status'] = '1';
              dealerUpdateData['current_status'] = current_status;
              dealerUpdateData['dealer_id_hash'] = dealer_id_hash;

          } else {
              dealerUpdateData['status'] = '2';
              dealerUpdateData['current_status'] = current_status;
              dealerUpdateData['dealer_id_hash'] = dealer_id_hash;
          }
          this.setState({ dealerUpdateData: dealerUpdateData });
          var thisObj = this;
          let dealerListTempData = { ...this.state.basic_details };
          thisObj.setState({ loading: true })
          let checkVal = isChecked;
          const response = await MasterService.post('dealer/dealer/status_change', { dealer_id_hash: dealer_id_hash, status: dealerUpdateData.status, current_status: dealerUpdateData.current_status });
          thisObj.flag = false;
          if (response.data.status == 200) {
              thisObj.setState({ basic_details: dealerUpdateData, loading: false });
          } else {
              thisObj.setState({ loading: false })
              toast.error(response.data.message)
          }
      }
  }


  render() {
      var { activeTab } = this.state;
      const bankList = this.state.bankList;
      const { StateList } = this.props;
      const { kycMasterlistAddKyc, kycDealerAddDoc, addNewBankDetail, filteredcity, kycMasterlist, kycDealerData, kycDealerDetail, kycDealerDocData, kycDealerCallVerificationData, errors, kyc_errors, kyc_upload_doc_errors, kyc_doc_errors } = this.state;
      const { cv_link_errors } = this.state;
      let basicinfo = this.state.basic_details;
      activeTab = activeTab === "tab1" && basicinfo.applicant_type === "2" ? "tab4" : activeTab;

      let masterlist = {};
      if (kycMasterlist) {
          kycMasterlist.forEach((val) => {
              masterlist['cat_' + val.id] = val.child;
          })
      }
      let role;

      if (secureStorage.getItem('loginUserInfo')) {
          let loginResponse = secureStorage.getItem('loginUserInfo');
          if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
              role = loginResponse.data.user_data.role;
          }
      }
      return (
          <div className={(this.state.loading ? "card" : "card")}>
              {/* {JSON.stringify(kycDealerDocData)} */}
              <div className="card-heading">
                  <div>
                      <h1 className="card-title">
                          KYC Details <span className="f12">({basicinfo.organization}  {basicinfo.gcd_code})</span>
                      </h1>

                  </div>
                  {/* <div className="pull-right">
                      <span className="btn">verify Status:</span>
                                     {  ((basicinfo.status == '0' || basicinfo.status == '2' || basicinfo.status == '4') && this.state.can_verify && role=='admin') ? <>
                                                        
                                                         <label className="switch-btn btn btn-link" htmlFor={"active" + basicinfo.dealer_id_hash} >
                                                              <input className="switch-btn" id={"active" + basicinfo.dealer_id_hash} value="open" name={"active" + basicinfo.dealer_id_hash} type="checkbox" onClick={this.handleChangeAccountStatus(basicinfo.dealer_id_hash,basicinfo.status)} checked={(basicinfo.status == '1') ? true : false} />
                                                              <div className="slider round"></div>
                                                              <span className="switch-label"></span>
                                                              {this.props.t('Listing.Search_Result.Active')}
                                                          </label></> 
                                                          : basicinfo.status == '5' ? "Blacklisted" : basicinfo.status=='1' ?  <img src={require('./../../../webroot/images/tick.png')} alt="verified"width={30} height={30} /> :<span className='doc-approval'>Docs approval Pending</span>
                                                  }
                     </div>
                  <div className="pull-right">
                      <button className="btn" onClick={() => {this.handleHistory()}}>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</button>
                      <button className="btn" onClick={this.addNewKycDetail}>{this.props.t('dealerDetails.kyc_details.add_more_bank_detail')}</button>
                  </div>
                  <div className="clearfix"></div> */}
              </div>
              {/* <div className="card-body">    
             
                  <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                      <div className="accrdian-bt0">
                          {addNewBankDetail ?
                              <Accordion title={this.props.t('dealerDetails.kyc_details.add_more_bank_detail')} active="true" delete="true">
                                  <button className="btn btn-link delete-user" onClick={() => { if (window.confirm(this.props.t('dealerDetails.Basic_Employee_Details.Confirm_Delete'))) this.deleteNewKycDetail() }} ><i className="ic-delete"></i>{this.props.t('dealerDetails.Basic_Employee_Details.Delete')}</button>

                                  <div className="card-heading">
                                      <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.bank_detail')}</h2>
                                  </div>
                                  <div className="card-body">
                                      <form onSubmit={this.saveBankDetail} method="post">

                                          <div className="row">
                                              <div className="col-sm-4 form-group">
                                                  <label>{this.props.t('dealerDetails.kyc_details.bank_name')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                  <Select
                                                      id="bankname"
                                                      onChange={this.handlebankListOptionChange}
                                                      options={bankList}
                                                      name="bankname"
                                                      placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                                      value={bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)}
                                                      getOptionLabel={({ bankname }) => bankname}
                                                      getOptionValue={({ bank_id }) => bank_id}
                                                  />
                                                  {errors.bankname &&
                                                      <div className="error show" >{errors.bankname}</div>
                                                  }
                                              </div>
                                              <div className="col-sm-3">
                                                  <InputField type="text" error={(errors.beneficiary_name) ? errors.beneficiary_name : ''} name="beneficiary_name" id="beneficiary_name" placeholder={this.props.t('dealerDetails.kyc_details.beneficiary_name')} value={kycDealerData.beneficiary_name} label={this.props.t('dealerDetails.kyc_details.beneficiary_name')} onChange={this.handletKycDealerDataTextChange.bind(this, 'beneficiary_name')} required={true} />
                                              </div>                                           
                                              <div className="col-sm-3">
                                                  <InputField  type="password" error={(errors.acno) ? errors.acno : ''} name="acno" id="acno" placeholder={this.props.t('dealerDetails.kyc_details.bank_account_number')} value={kycDealerData.bank_account_no} label={this.props.t('dealerDetails.kyc_details.bank_account_number')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_account_no')} required={true} account_length={this.state.account_length}  RestrictOnPaste={1} RestrictOnCopy={1}/>
                                              </div>
                                              <div className="col-sm-3">
                                                  <InputField  type="tel" error={(errors.coacno) ? errors.coacno : ''} name="coacno" id="coacno" placeholder={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} value={kycDealerData.confirm_bank_account_no} label={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} onChange={this.handletKycDealerDataTextChange.bind(this, 'confirm_bank_account_no')} required={true} account_length={this.state.account_length} RestrictOnPaste={1} RestrictOnCopy={1} autocomplete="new-password"/>
                                              </div>

                                              <div className="col-sm-3">
                                                  <InputField type="text" error={(errors.bank_ifsc_code_required) ? errors.bank_ifsc_code_required : ''} name="bank_ifsc_code" id="bank_ifsc_code" placeholder={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')} value={kycDealerData.bank_ifsc_code} label={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_ifsc_code')} required={true} />
                                              </div>    
                                              <div className="col-sm-4 form-group rc-inline">
                                                  <div>{this.props.t('dealerDetails.kyc_details.type_of_account')}</div>
                                                  <RadioBox type="radio" name="account_type" id="saving" value="saving" label={this.props.t('dealerDetails.kyc_details.saving')} checked={(kycDealerData.bank_account_type == 'saving') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                                  <RadioBox type="radio" name="account_type" id="current" value="current" label={this.props.t('dealerDetails.kyc_details.current')} checked={(kycDealerData.bank_account_type == 'current') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                                  {errors.account_type &&
                                                      <div className="error show" >{errors.account_type}</div>
                                                  }
                                              </div>                                            
                                              </div>
       
                                          <Button type="button" btnClass="btn btn-link" onClick={this.addKycRowCategory}> {this.props.t('dealerDetails.kyc_details.add_more_kyc')}</Button>
                                          <div className="card-footer text-center">
                                              <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                                          </div>
                                      </form>
                                  </div>
                              </Accordion>
                              : ''}

                          {
                              kycDealerDetail.length > 0 ?
                                  kycDealerDetail.map((kyc_dealer_data, index) =>
                                      <Accordion key={kyc_dealer_data.id} title={kyc_dealer_data.beneficiary_name+ " - " +kyc_dealer_data.bank_account_no}>
                                          {
                                              (kyc_dealer_data.active_status == '1' || kyc_dealer_data.active_status == '0') ? <>
                                              
                                                  <label className="delete-user switch-btn btn btn-link" htmlFor={"active" + kyc_dealer_data.id} >                                                    
                                                      <input className="switch-btn" id={"active" + kyc_dealer_data.id} value="open" name={"active" + kyc_dealer_data.id} type="checkbox" onChange={this.handleChangeForFlip('showCommentMsg', index, kyc_dealer_data.id)} checked={(kyc_dealer_data.active_status == '1') ? true : false} />
                                                      <input className="switch-btn" id={"active" + kyc_dealer_data.id} value="open" name={"active" + kyc_dealer_data.id} type="checkbox" onClick={() => this.handleChangeForFlip('showCommentMsg',kyc_dealer_data.id, index)} checked={(kyc_dealer_data.active_status == '1') ? true : false} />
                                                      <div className="slider round"></div>
                                                      <span className="switch-label"></span>
                                                      {this.props.t('Listing.Search_Result.Active')}
                                                  </label></>
                                                  : ""
                                          }
                                          
                                          <div className="card-heading">
                                              <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.bank_detail')}</h2>
                                          </div>
                                          <div className="card-body">
                                             <div className="row">
                                                   <div className="col-sm-4 form-group">
                                                      <label>{this.props.t('dealerDetails.kyc_details.bank_name')}</label>
                                                      <Select
                                                          id={"bank_id" + index}
                                                          options={bankList}
                                                          isDisabled={true}
                                                          name={"bank_id" + index}
                                                          placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                                          value={bankList.filter(({ bank_id }) => bank_id == kyc_dealer_data.bank_id)}
                                                          getOptionLabel={({ bankname }) => bankname}
                                                          getOptionValue={({ bank_id }) => bank_id}
                                                      />
                                                  </div> 
                                               

                                                  <div className="col-sm-4 form-group rc-inline">
                                                      <div>{this.props.t('dealerDetails.kyc_details.type_of_account')}</div>
                                                      <RadioBox type="radio" disabled={true} name={"account_type_" + index} id={"saving_"+index} value="saving" label={this.props.t('dealerDetails.kyc_details.saving')} checked={(kyc_dealer_data.bank_account_type == 'saving') ? true : false} readOnly={true} />
                                                      <RadioBox type="radio" disabled={true} name={"account_type_" + index} id={"current"+index} value="current" label={this.props.t('dealerDetails.kyc_details.current')} checked={(kyc_dealer_data.bank_account_type == 'current') ? true : false} readOnly={true} />
                                                  </div>

                                              </div>
                                              <div className="row">
                                              <div className="col-sm-4">
                                                      <InputField type="text" disabled={true} name={"beneficiary_name" + index} id={"beneficiary_name" + index} placeholder={this.props.t('dealerDetails.kyc_details.beneficiary_name')} value={kyc_dealer_data.beneficiary_name} label={this.props.t('dealerDetails.kyc_details.beneficiary_name')} />
                                                  </div>
                                                  <div className="col-sm-4">
                                                      <InputField type="password" disabled={true} name={"acno" + index} id={"acno" + index} placeholder={this.props.t('dealerDetails.kyc_details.bank_account_number')} value={kyc_dealer_data.bank_account_no} label={this.props.t('dealerDetails.kyc_details.bank_account_number')} />
                                                  </div>
                                                  <div className="col-sm-4">
                                                      <InputField type="tel" disabled={true} name={"coacno" + index} id={"coacno" + index} placeholder={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} value={kyc_dealer_data.bank_account_no} label={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} />
                                                  </div>
                                                  <div className="col-sm-4">
                                                      <InputField type="text" disabled={true} name={"bank_ifsc_code" + index} id={"bank_ifsc_code" + index} placeholder={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')} value={kyc_dealer_data.bank_ifsc_code} label={this.props.t('dealerDetails.kyc_details.bank_ifsc_code')} />
                                                  </div>

                                                  <div className="col-sm-4">
                                                      <label>{this.props.t('dealerDetails.kyc_details.added_account_date')}:</label>
                                                      <p>{moment(kyc_dealer_data.created_date).format('YYYY-MM-DD')}</p>
                                                  </div>
                                              </div>
                                              {kyc_dealer_data.kyc_comment.length > 0 ?
                                                  <div className="row">
                                                      <div className="col-sm-6 form-field">
                                                          <label>{this.props.t('dealerDetails.kyc_details.comment')}:</label>                                                            
                                                          <table className="table table-bordered">
                                                              <thead>
                                                                  <tr>
                                                                      <td colSpan="3">
                                                                          <a className="pull-right" href="#"
                                                                              title={this.props.t('dealerDetails.kyc_details.view_all_comment')}
                                                                              onClick={(e) => {
                                                                                          e.preventDefault();
                                                                                          this.handleChangeForViewComment('showAllCommentMsg',index ,kyc_dealer_data.kyc_comment)
                                                                                      }}
                                                                          > {this.props.t('dealerDetails.kyc_details.view_all_comment')} </a>
                                                                      </td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.action')}</th>
                                                                      <th style={{width: "60%"}}>{this.props.t('dealerDetails.kyc_details.comment')}</th>
                                                                      <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.comment_date')}</th>
                                                                  </tr>
                                                              </thead>
                                                              <tbody>
                                                                  <tr>
                                                                      <td style={{width: "20%"}}>{kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].comment_flag == "1"? this.props.t('dealerDetails.kyc_details.active') : this.props.t('dealerDetails.kyc_details.inactive')} </td>
                                                                      <td style={{width: "60%"}}>{kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].comment}</td>
                                                                      <td style={{width: "20%"}}>{moment(kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].created_date).format('YYYY-MM-DD')}</td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </div>
                                                  : ''}
                                              {
                                                  (kyc_dealer_data.kyc_doc.length) ?
                                                  kyc_dealer_data.kyc_doc.map((doc, idx) =>
                                                  <div key={'kyc-dealer-data-'+idx}>
                                                          <div className="row" disabled="true">
                                                              <div className="col-sm-4">
                                                                  <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}</label>
                                                                  <Select
                                                                      id="add_kyc_category"
                                                                      onChange={this.handleKycDocCategoryOptionChange(idx)}
                                                                      options={kycMasterlistAddKyc}
                                                                      isDisabled={true}
                                                                      name="add_kyc_category"
                                                                      placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                                      value={kycMasterlistAddKyc.filter(({ id }) => id === doc.kyc_category_id)}
                                                                      getOptionLabel={({ name }) => name}
                                                                      getOptionValue={({ id }) => id}
                                                                  />
                                                        
                                                              </div>
                                                              <div className="col-sm-4" >
                                                                  <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                                  {
                                                                      (doc.file_url != '' && doc.file_url != null) ?
                                                                          <div className="document-link">
                                                                              {
                                                                                  (doc.file_url != '' && doc.file_url != null) ?
                                                                                      <a href={doc.file_url} target="_blank" download>
                                                                                          {
                                                                                              (doc.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (doc.kyc_doc_type == 'image') ? <i className="ic-image-file icons-normal"></i> : (doc.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                                          }
                                                                                          View File
                                                                                      </a>
                                                                                      : ''
                                                                              }
                                                                          </div>
                                                                      : ''
                                                                  }
                                                              </div>
                                                      </div>
                                                  </div>
                                                      ) : ''
                                              }

                                          </div>
                                      </Accordion>
                                  ) : ''}
                      </div>
                  </div>
              </div> */}

              <div className='card'>
                  <div className='card-body'>

                      <div className='sidebar-box'>
                          <div className="sidebar">

                              {(basicinfo.applicant_type === "1") ? ( //|| basicinfo.applicant_type === "2"
                                  <>
                                      <div
                                          className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
                                          onClick={() => this.handleTabClick('tab1')}
                                      >
                                          Aadhaar Front {this.findIsValidatedKyc(1, kycDealerDocData, basicinfo).status === "1" ? (
                                              <img src={greenTickSvg} alt="" />
                                          ) : null}
                                          {this.findIsValidatedKyc(1, kycDealerDocData).rejectReason > 0 ? (
                                              <img style={{ marginLeft: 45, height: 23 }} src={redTickSvg} alt="" />
                                          ) : null}
                                      </div>
                                      <div
                                          className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
                                          onClick={() => this.handleTabClick('tab2')}
                                      >
                                          Aadhaar Back {this.findIsValidatedKyc(2, kycDealerDocData).status === "1" ? (
                                              <img src={greenTickSvg} alt="" />
                                          ) : null}
                                          {this.findIsValidatedKyc(2, kycDealerDocData).rejectReason > 0 ? (
                                              <img style={{ marginLeft: 45, height: 23 }} src={redTickSvg} alt="" />
                                          ) : null}
                                      </div>
                                      {basicinfo.applicant_type === "1" ? (
                                          <div
                                              className={`tab ${activeTab === 'tab3' ? 'active' : ''}`}
                                              onClick={() => this.handleTabClick('tab3')}
                                          >
                                              Pan Card {this.findIsValidatedKyc(3, kycDealerDocData).status === "1" ? (
                                                  <img src={greenTickSvg} alt="" />
                                              ) : null}
                                              {this.findIsValidatedKyc(3, kycDealerDocData).rejectReason > 0 ? (
                                                  <img style={{ marginLeft: 45, height: 23 }} src={redTickSvg} alt="" />
                                              ) : null}
                                          </div>
                                      ) : null}
                                      <div
                                          className={`tab ${activeTab === 'tab4' ? 'active' : ''}`}
                                          onClick={() => this.handleTabClick('tab4')}
                                      >
                                          Account Details {((this.findIsValidatedKyc(4, kycDealerDocData).status === "1") || (!this.findIsValidatedKyc(4, kycDealerDocData).rejectReason && !this.filterbankDoc(4, kycDealerDocData,basicinfo) && basicinfo.is_name_matched ===1)) ? (
                                              <img src={greenTickSvg} alt="" />
                                          ) : null}
                                          {this.findIsValidatedKyc(4, kycDealerDocData,basicinfo).rejectReason > 0 ? (
                                              <img style={{ marginLeft: 13, height: 23 }} src={redTickSvg} alt="" />
                                          ) : null}
                                      </div>
                                  </>
                              ) : null}
                              {(basicinfo.applicant_type === "2") ? (
                                  <>
                                      <div
                                          className={`tab ${activeTab === 'tab4' ? 'active' : ''}`}
                                          onClick={() => this.handleTabClick('tab4')}
                                      >
                                          Account Details {((this.findIsValidatedKyc(4, kycDealerDocData).status === "1") || (!this.findIsValidatedKyc(4, kycDealerDocData).rejectReason && !this.filterbankDoc(4, kycDealerDocData,basicinfo) && basicinfo.is_name_matched ===1)) ? (
                                              <img src={greenTickSvg} alt="" />
                                          ) : null}
                                          {this.findIsValidatedKyc(4, kycDealerDocData).rejectReason > 0 ? (
                                              <img style={{ marginLeft: 13, height: 23 }} src={redTickSvg} alt="" />
                                          ) : null}
                                      </div>
                                      {(basicinfo.is_gst === "1") ? (
                                          <div
                                              className={`tab ${activeTab === 'tab5' ? 'active' : ''}`}
                                              onClick={() => this.handleTabClick('tab5')}
                                          >
                                              GST Certificate {this.findIsValidatedKyc(5, kycDealerDocData).status === "1" ? (
                                                  <img src={greenTickSvg} alt="" />
                                              ) : null}
                                              {this.findIsValidatedKyc(5, kycDealerDocData).rejectReason > 0 ? (
                                                  <img style={{ marginLeft: 38, height: 23 }} src={redTickSvg} alt="" />
                                              ): null}
                                          </div>
                                      ) : null}
                                      <div
                                          className={`tab ${activeTab === 'tab6' ? 'active' : ''}`}
                                          onClick={() => this.handleTabClick('tab6')}
                                      >
                                          Company PAN {this.findIsValidatedKyc(6, kycDealerDocData).status === "1"  ? (
                                              <img src={greenTickSvg} alt="" />
                                          ) : null}
                                          {this.findIsValidatedKyc(6, kycDealerDocData).rejectReason > 0 ? (
                                                  <img style={{ marginLeft: 41, height: 23 }} src={redTickSvg} alt="" />
                                              ) : null}
                                      </div>
                                      {(basicinfo.is_msme === "1") ? (
                                          <div
                                              className={`tab ${activeTab === 'tab7' ? 'active' : ''}`}
                                              onClick={() => this.handleTabClick('tab7')}
                                          >
                                              MSME Certificate {this.findIsValidatedKyc(7, kycDealerDocData).status === "1" ? (
                                                  <img src={greenTickSvg} alt="" />
                                              ) : null}
                                              {this.findIsValidatedKyc(7, kycDealerDocData).rejectReason > 0 ? (
                                                  <img style={{ marginLeft: 25, height: 23 }} src={redTickSvg} alt="" />
                                              ) : null}
                                          </div>
                                      ) : null}
                                  </>
                              ) : null}
                          </div>


                          <div className="content">
                              {activeTab === 'tab1' && <KycDocument handleKycIcon={this.handleKycIcon} dealer_id_hash={this.get_dealer_id_hash} dealer_id={this.state.basic_details.id} basic_details={this.state.basic_details} kycDealerData={this.state.kycDealerData[0]} bankList={bankList} kyc_master_id={1} loginUserId={this.state.loginUserId}/>}
                              {activeTab === 'tab2' && <KycDocument handleKycIcon={this.handleKycIcon} dealer_id_hash={this.get_dealer_id_hash} dealer_id={this.state.basic_details.id} basic_details={this.state.basic_details} kycDealerData={this.state.kycDealerData[0]} bankList={bankList} kyc_master_id={2} loginUserId={this.state.loginUserId}/>}
                              {activeTab === 'tab3' && <KycDocument handleKycIcon={this.handleKycIcon} dealer_id_hash={this.get_dealer_id_hash} dealer_id={this.state.basic_details.id} basic_details={this.state.basic_details} kycDealerData={this.state.kycDealerData[0]} bankList={bankList} kyc_master_id={3} loginUserId={this.state.loginUserId}/>}
                              {activeTab === 'tab4' && <KycDocument handleKycIcon={this.handleKycIcon} dealer_id_hash={this.get_dealer_id_hash} dealer_id={this.state.basic_details.id} basic_details={this.state.basic_details} kycDealerData={this.state.kycDealerData[0]} bankList={bankList} kyc_master_id={4} loginUserId={this.state.loginUserId}/>}
                              {activeTab === 'tab5' && <KycDocument handleKycIcon={this.handleKycIcon} refreshData={this.props.refreshData} dealer_id_hash={this.get_dealer_id_hash} dealer_id={this.state.basic_details.id} basic_details={this.state.basic_details} kycDealerData={this.state.kycDealerData[0]} bankList={bankList} kyc_master_id={5} loginUserId={this.state.loginUserId}/>}
                              {activeTab === 'tab6' && <KycDocument handleKycIcon={this.handleKycIcon} dealer_id_hash={this.get_dealer_id_hash} dealer_id={this.state.basic_details.id} basic_details={this.state.basic_details} kycDealerData={this.state.kycDealerData[0]} bankList={bankList} kyc_master_id={6} loginUserId={this.state.loginUserId}/>}
                              {activeTab === 'tab7' && <KycDocument handleKycIcon={this.handleKycIcon} refreshData={this.props.refreshData} dealer_id_hash={this.get_dealer_id_hash} dealer_id={this.state.basic_details.id} basic_details={this.state.basic_details} kycDealerData={this.state.kycDealerData[0]} bankList={bankList} kyc_master_id={7} loginUserId={this.state.loginUserId}/>}
                          </div>
                      </div>


                      {/* <div className="row"style={{marginTop:30}}>
                  <div className="col-sm-10">
                      <Button btnClass="btn btn-default mrg-r30" ><i className="ic-arrow_back"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')}</Button>
                     <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">{this.props.t('dealerDetails.Outlet_Details_master.Previous')}</NavLink>
                     </div>
                    
              </div> */}

                  </div>



              </div>



              {/* <div className={(this.state.loading ? "card loading" : "card")}>
                  <form onSubmit={this.saveKycDetail} method="post" encType="multipart/form-data">
                      <div className="card-heading">
                          <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.upload_kyc_document')}</h2>
                      </div>
                      <div className="card-body">
                      
                          <p className="text-danger form-group f12">*{this.props.t('dealerDetails.kyc_details.kyc_doc_validation')}</p>
                     <table className='table table-bordered'>
                      <tr>
                          <th className="col-sm-2">{this.props.t('dealerDetails.kyc_details.kyc_category')}</th>
                          <th className="col-sm-2">{this.props.t('dealerDetails.kyc_details.kyc_document')}</th>
                          <th className="col-sm-2">{this.props.t('dealerDetails.kyc_details.document_number')}</th>
                          <th className="col-sm-2">{this.props.t('dealerDetails.kyc_details.comment')}</th>
                          <th className="col-sm-2">{this.props.t('dealerDetails.kyc_details.kyc_document')}</th>
                          <th className="col-sm-2">{this.props.t('dealerDetails.kyc_details.verify')}</th>
                      </tr>
                      {JSON.stringify(kycDealerDocData)}
                          
                          { 
                              (kycDealerDocData.length) ?
                                  kycDealerDocData.map((kycDealerDoc, idx) =>
                                      (kycDealerDoc.verified_status =='1') ?
                                      <tr className="row">
                                      <td className="col-sm-2">{this.getObjectKeyValue(kycMasterlist,kycDealerDoc.kyc_master_id)}</td>
                                      <td className="col-sm-2">Other</td>
                                      <td className="col-sm-2"> {kycDealerDoc.doc_number}</td>
                                      <td className="col-sm-2"> {kycDealerDoc.comment}</td>
                                      <td className="col-sm-2 ">{
                                                  (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                      <div className="ion-document">

                                                          {
                                                              (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                                  <a href={kycDealerDoc.file_url} target="_blank" download>
                                                                      {
                                                                          (kycDealerDoc.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.kyc_doc_type == 'image') ? <img src={kycDealerDoc.file_url} height={20}></img> : (kycDealerDoc.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                      }
                                                                    
                                                      </a>
                                                                  : ''
                                                          }
                                                         {kycDealerDoc.verified_status!='1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this, idx)}><i className="ic-clearclose"></i></span> : ""}
                                                      </div>
                                                      : ''
                                              }</td>
                                      <td className="col-sm-2"> {kycDealerDoc.verified_status == '1' ? "Verified" : "Pending" } { kycDealerDoc.verified_date ? DateFormate(kycDealerDoc.verified_date, 'dS mmm yyyy h:MM TT') : ""} </td>
                                  </tr> :
                                      <tr className="row" key={idx}>
                                          
                                          <input type="hidden" name="dc_dealer_kyc_doc" id="dc_dealer_kyc_doc" value={(kycDealerDoc.id)?kycDealerDoc.id:''} ></input>
                                          <input type="hidden" name="saved_file_name" id="saved_file_name" value={(kycDealerDoc.file_name)?kycDealerDoc.file_name:''}></input>
                                          <input type="hidden" name="saved_file_url" id="saved_file_url" value={(kycDealerDoc.file_url)?kycDealerDoc.file_url:''}></input>
                                          <td className="col-sm-2" style={{height:60}}>
                                              <Select
                                                  id="kyccategory"                                                    
                                                  onChange={this.handleDocCategoryOptionChange(idx)}
                                                  options={kycMasterlist}
                                                  name="kyccategory"
                                                  placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                  value={kycMasterlist.filter(({ id }) => id === kycDealerDoc.kyc_master_id)}
                                                  getOptionLabel={({ name }) => name}
                                                  getOptionValue={({ id }) => id}
                                                  //isDisabled={(kycDealerDoc.disable_status)?kycDealerDoc.disable_status:''}
                                                 // isDisabled={kycDealerDoc.verified_status=='1' ? true:false}
                                              />
                                            
                                              {
                                                  (kyc_errors && kyc_errors[idx] && kyc_errors[idx].kyccategory) ? <span className="error show">{kyc_errors[idx].kyccategory}</span> : ''
                                              }
                                          </td>
                                          

                                          <td className="col-sm-2" style={{height:60}}>
                                             
                                              <Select
                                                  id="kycdoc"
                                                  onChange={this.handleKycDocChange(idx)}
                                                  options={masterlist['cat_' + kycDealerDoc.kyc_master_id]}
                                                  name="kycdoc"
                                                  placeholder={this.props.t('dealerDetails.kyc_details.kyc_document')}
                                                  value={(masterlist['cat_' + kycDealerDoc.kyc_master_id]) ? masterlist['cat_' + kycDealerDoc.kyc_master_id].filter(({ kyc_child_id }) => kyc_child_id === kycDealerDoc.kyc_child_id) : ''}
                                                  getOptionLabel={({ kyc_child_name }) => kyc_child_name}
                                                  getOptionValue={({ kyc_child_id }) => kyc_child_id}
                                               
                                              />
                                              {
                                                  (kyc_errors && kyc_errors[idx] && kyc_errors[idx].kycdoc) ? <span className="error show">{kyc_errors[idx].kycdoc}</span> : ''
                                              }
                                          </td>
                                          <td className="col-sm-2" style={{height:60}}>
                                          {
                                          (kycDealerDoc && kycDealerDoc.kyc_child_id && kycDealerDoc.kyc_child_id==2)?                                            
                                          <>
                                          
                                          <NumberFormat 
                                              className="form-control"
                                              format="##.###.###.#-###.###"
                                              mask="_"
                                              error={(kyc_errors && kyc_errors[idx] && kyc_errors[idx].docnumber)  ? kyc_errors[idx].docnumber : ''} 
                                              type="text" 
                                              name="docnumber" 
                                              is_label_required = {false}
                                              id="docnumber" 
                                              placeholder={this.props.t('dealerDetails.kyc_details.document_number')} 
                                              value={kycDealerDoc.doc_number} 
                                              onChange={this.handletkycDealerDocTextChange.bind(this, 'doc_number', idx)} 
                                             // disabled={kycDealerDoc.verified_status=='1' ? true:false}
                                              />   
                                              {
                                                  (kyc_errors && kyc_errors[idx] && kyc_errors[idx].docnumber) ? <span className="error show">{kyc_errors[idx].docnumber}</span> : ''
                                              }                                             
                                              
                                              </>
                                              
                                              :
                                          <InputField 
                                              error={(kyc_errors && kyc_errors[idx] && kyc_errors[idx].docnumber)  ? kyc_errors[idx].docnumber : ''} 
                                              type="text" 
                                              name="docnumber" 
                                              id="docnumber" 
                                              placeholder={this.props.t('dealerDetails.kyc_details.document_number')} 
                                              value={kycDealerDoc.doc_number} 
                                              is_label_required = {true}
                                              onChange={this.handletkycDealerDocTextChange.bind(this, 'doc_number', idx)} 
                                             // disabled={kycDealerDoc.verified_status=='1' ? true:false}
                                              />                                            
                                          }
                                          </td>
                                          <td className="col-sm-2" style={{height:60}}>
                                              <InputField type="text" name="Comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerDoc.comment}  onChange={this.handletkycDealerDocTextChange.bind(this, 'comment', idx)}    is_label_required = {true}  />
                                          </td>
                                          <td className="col-sm-2" style={{height:60}}>
                                            
                                              <input type="file" name="fileName" multiple className={
                                                  (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                      'form-control hide' : 'form-control'
                                              } onChange={this.onChangeHandler} />
                                              {
                                                  (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                      <div className="ion-document">

                                                          {
                                                              (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                                  <a href={kycDealerDoc.file_url} target="_blank" download>
                                                                      {
                                                                          (kycDealerDoc.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.kyc_doc_type == 'image') ? <img src={kycDealerDoc.file_url} height={30}></img> : (kycDealerDoc.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                      }
                                                                    
                                                      </a>
                                                                  : ''
                                                          }
                                                         {kycDealerDoc.verified_status!='1' ? <span className="delete-docs" onClick={this.removecurrentdoc.bind(this, idx)}><i className="ic-clearclose"></i></span> : ""}
                                                      </div>
                                                      : ''
                                              }

                                          </td>
                                          <td className="col-sm-2" style={{height:60}}>
                                          {role=='dealercentral' && kycDealerDoc.verified_status != '1' ?  "Pending" : 
                                              <div style={{margin:8.5}}>
                                          <input type="checkbox"  name="verified_status_new" value={kycDealerDoc.verified_status_new}
                                          id={'verified_status_new'} key={'verified_status_new'}
                                        onClick={(e)=>this.handleChangeVerifiedDocs("verified_status_new",idx,e)}
                                        checked={kycDealerDoc.verified_status_new=='1' ? true:false}
                                       // disabled={kycDealerDoc.verified_status=='1' ? true:false}
                                      />
                                             
                                                   </div> 
                                              }
                                          </td>
                                          
                                          {
                                              (idx > 10) ?
                                              <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycDocRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button>:''
                                          }
                                          
                                      </tr>
                                  ) : ''
                          }
                           </table>
                         <div>  <Button type="button" btnClass="btn" onClick={this.addKycRow}> {this.props.t('dealerDetails.kyc_details.add_more_kyc')}</Button></div>
                      </div>
                     
                      {
                          (kycDealerDocData.length) ?
                              <div className="card-footer text-center">
                                  <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                              </div> : ''
                      }
                  </form>
          </div> */}
              {
                  // (role == 'admin') ?
                  //     <div className={(this.state.loading ? "card loading" : "card")}>
                  //         <form onSubmit={this.saveKycCallVerificationDetail} method="post" encType="multipart/form-data">
                  //             <div className="card-heading">
                  //                 <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.call_verification')}</h2>
                  //             </div>
                  //             <div className="card-body">
                  //                 {
                  //                     (kycDealerCallVerificationData.length) ?
                  //                         kycDealerCallVerificationData.map((kycDealerCallVerification, idx) =>
                  //                             <div className="row mrg-b15" key={idx} style={{ borderBottom: kycDealerCallVerificationData.length > idx + 1 ? "1px dashed #ccc" : "" }}>
                  //                                 <input type="hidden" name="dc_dealer_kyc_call_verification_id" id="dc_dealer_kyc_call_verification_id" value={(kycDealerCallVerification.id) ? kycDealerCallVerification.id : ''} ></input>
                  //                                 <div className="col-sm-4">
                  //                                     <InputField error={(cv_link_errors[idx + "_link"]) ? cv_link_errors[idx + "_link"] : ''} type="text" name="link" id="link" placeholder={this.props.t('dealerDetails.kyc_details.link')} value={kycDealerCallVerification.link} label={this.props.t('dealerDetails.kyc_details.link')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'link', idx)} />
                  //                                 </div>
                  //                                 <div className="col-sm-6 form-group">
                  //                                     <label>{this.props.t('dealerDetails.kyc_details.comment')}</label>
                  //                                     <textarea onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} rows='2' className="form-control" name="comment" value={kycDealerCallVerification.comment}></textarea>
                  //                                     {/* <InputField type="text" name="comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerCallVerification.comment} label={this.props.t('dealerDetails.kyc_details.comment')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} /> */}
                  //                                 </div>
                  //                                 <div className="col-sm-1 pad-t25">
                  //                                     <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycCallVerificationRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button>
                  //                                 </div>
                  //                             </div>
                  //                         ) : ''
                  //                 }
                  //                 <Button type="button" btnClass="btn btn-link" onClick={this.addKycCallVerificationRow}> {this.props.t('dealerDetails.kyc_details.add_more_call_verification')}</Button>
                  //             </div>
                  //             {
                  //                 (kycDealerCallVerificationData.length > 0) ?
                  //                     <div className="card-footer text-center">
                  //                         <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                  //                     </div> : ''
                  //             }
                  //         </form>
                  //     </div>
                  //     : ''
              }

              {this.state.showCommentMsg == false ? '' :
                  <ModalPopup id="add_lead" className="modal" title={this.props.t('dealerDetails.kyc_details.add_comment')} modalClose={this.closeModal}>
                      <KycComment getDealerKycDetail={this.getDealerKycDetail} modalClose={this.closeModal} kycId={this.state.kycId} currentIndex={this.state.currentIndex} active_status={this.state.active_status} basic_details={this.state.basic_details} />
                  </ModalPopup>
              }
              {this.state.showAllCommentMsg == false ? '' :
                  <ModalPopup id="add_lead" className="modal" title={this.props.t('dealerDetails.kyc_details.all_comment')} modalClose={this.closeModalViewAllComment}>
                      <KycAllComment modalClose={this.closeModalViewAllComment} currentIndex={this.state.currentIndex} comment={[...this.state.comment]} />
                  </ModalPopup>
              }

              {/* {this.state.historyPopup === false ? null : <ModalPopup id="view_history" className="modal ViewHistory data-table" title={'View History'} modalClose={this.closeHistoryModel}>
                 {basicinfo && basicinfo.id && <ViewHistory  histoy_dealer_id={basicinfo.id}/>}
             </ModalPopup>} */}

          </div>
      )
  }
}
const mapStateToProps = state => {
  return {
      auth_token: state.authToken,
      user_loggedin: state.userLoggedIn,
      dealer_id: state.dealer_id
  };
}

const mapDispatchToProps = dispatch => {
  return {
      updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(KycDetails));
//export default withTranslation('dealers')(BasicDetails)