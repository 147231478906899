import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import { useDispatch, useSelector } from "react-redux";
import DocumentGallery from "../DocumentGallery";
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import FormsySelect from "../../elements/FormsySelect";
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import LeadRemarkHistory from "../../lead-list/LeadRemarkHistory";
import Modal from "../../elements/Modal";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { GET_DETAILS_BY_PANCARD, SAVE_CUST_DETAILS } from "../../../services/customer.gql";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import {
  getLoanDetail,
  getMasterCityState,
  setCityList,
} from "../../../store/action/allAction";
import GeneralService from "../../../services/generalService";
import {
  CHECK_ASSIGN_USER,
  IS_USER_ACCESS_PAGE,
  DISBURSE_REJECTION_ID,
} from "../../../config/constants";
import crypto from "../../../config/crypto";
import { DELETE_COAPPLICANT } from "../../../services/customer.gql";

const MaskData = require("maskdata");
const maskOptions = {
  maskWith: "*",
  unmaskedStartDigits: 0,
  unmaskedEndDigits: 4,
  maskSpace: false,
};

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 76);

const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" },
];

const prioritizedStates = ["Haryana", "Delhi", "Karnataka","Maharashtra","Telangana","Uttar Pradesh"];
const prioritizedCities = ["New Delhi", "Gurgaon", "Fridabad","Manesar","Sonipat"];


const FORM_FIELDS = FormFields["BASIC_DETAILS"];

const fieldsRequiredForOffers = ["dob","cra_city","cra_state"]

const requiredForOffers = <img
src="https://static.ambak.com/saathi/1734675288455.png"
alt="img"
height={10}
width={10}
style={{marginRight:"10px"}}
/>

const starSymbol = "\u2726";


const BasicDetailsForm = (props) => {
  let show_customer_number = IS_USER_ACCESS_PAGE("show_customer_number","show_customer_number").is_edit_access;

  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isFetchAadharVisible, setIsFetchAadharVisible] = useState(false);
  const [isValueChange,setIsValueChange]=useState(false)
  const { masterdata, lead, remarksHistory } = useSelector(
    ({ masterdata, lead }) => ({
      masterdata,
      lead,
      remarksHistory: lead.remarksHistory || [],
    })
  );

  const [deleteCoApplicant,setDeleteCoApplicant] = useState(false) 
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const [isFormTouch, setTouch] = useState({});
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let accessCondition = !IS_USER_ACCESS_PAGE(
    "loanDetail",
    "customer-basic-details"
  )?.is_edit_access;

  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || [];
  useEffect(() => {
    if (!masterdata.data || !masterdata.data.state_list) {
      dispatch(getMasterCityState());
    }
  }, []);
  useEffect(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    if (options?.city && options?.city?.length) {
      let record = options.city
        .filter((ob) => ob.state_id == basicDetails.cra_state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });
      dispatch(setCityList(record));
    }
  }, [basicDetails?.cra_state]);

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.customer) {
      let customerData = { ...lead.leadDetail.customer };
      customerData["dob"] = customerData["dob"] ? dateFormat(customerData["dob"], "dd mmm, yyyy") : null;
      let aadhar_no =
        customerData && customerData.aadhar_no
          ? customerData?.aadhar_no?.match(/.{1,4}/g).join(" ")
          : "";
      customerData.masked_aadhar_no = MaskData.maskCard(aadhar_no, maskOptions);
      customerData.masked_mobile = show_customer_number != "1" ? MaskData.maskCard(
        customerData.mobile,
        maskOptions
      ): customerData.mobile;
      if(customerData["dob"]){
        customerData.age = calculateAge(customerData["dob"])
      }
      customerData.aadhar_touch = 1;
      if ((customerData.pancard_no !== null && customerData.pancard_no !== "") && (customerData.aadhar_no === null || customerData.aadhar_no === "")) {
        setIsFetchAadharVisible(true);
      }
      setBasicDetails(customerData);
    }
  }, [lead]);
  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options["qualification"] = options["qualification"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["marital_status"] = options["marital_status"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["gender"] = GENDER_OPTIONS;
    options["marital_status"] = options?.marital_status?.map((ob) => {
      return { ...ob, value: ob.id };
    });
    if(options["state_list"]){
      options["state_list"] = [
        ...options["state_list"].filter((option) =>
          prioritizedStates.includes(option.label)
        ),
        ...options["state_list"]
          .filter((option) => !prioritizedStates.includes(option.label))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ];
    }

    if (options["city_list"]) {
      options["city_list"] = [
        ...options["city_list"]?.filter((option) =>
          prioritizedCities.includes(option.label)
        ),
        ...options["city_list"]
          .filter((option) => !prioritizedStates.includes(option.label))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ].filter((city) => city.label !== "dddsdxelhi");
    }
    
    return options;
  }, [masterdata]);


  const handleInputChange = (event) => {
    const { id, value } = event.target;
    let ob = {};
    setTouch({
      ...isFormTouch,
      [id]: true,
    });
    let inputValue = value;
    if (id === "pancard_no") {
      inputValue = value.toUpperCase();
      const isValidPan = /^[A-Z]{3}[CHFATBLJGP][A-Z]\d{4}[A-Z]$/.test(
        value.toUpperCase()
      );
      if (
        isValidPan &&
        (basicDetails["aadhar_no"] === "" ||
          basicDetails["aadhar_no"] === null ||
          basicDetails["aadhar_no"] === undefined)
      ) {
        setIsFetchAadharVisible(true);
      } else {
        setIsFetchAadharVisible(false);
      }
    } else if (id == "cra_pincode" && value.length == 6) {
      setCityStateByPin(value);
    } else if (id == "aadhar_no") {
      if (basicDetails?.aadhar_no?.length == 12 && value?.length == 14) {
        inputValue = basicDetails.aadhar_no;
      } else {
        inputValue = value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
        inputValue = /^[0-9\sX]+$/.test(inputValue) ? inputValue : "XXXX XXXX";
      }
      ob.aadhar_touch = 0;
    }
    ob[id] = inputValue.trim();
    setBasicDetails((currentValue) => ({
      ...currentValue,
      ...ob,
    }));
    setIsValueChange(true);
  };

  function calculateAge(dateString) {
    // Parse the input string into a JavaScript Date object
    const [day, month, year] = dateString.split(/[\s,]+/); // Split by space and comma
    const monthIndex = new Date(`${month} 1, 2000`).getMonth(); // Convert month name to index (0-11)
    const birthDate = new Date(year, monthIndex, day);
  
    const today = new Date();
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
  
    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    return age;
  }

  const setCityStateByPin = async (pincode) => {
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0];
        setBasicDetails({
          ...basicDetails,
          cra_pincode: pincode,
          cra_state: item?.state_id,
          cra_city: item?.city_id,
        });
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: date,
        age:calculateAge(dateFormat(date,"dd mmm, yyyy"))
      }));
      setIsValueChange(true);
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name.includes("state")) {
        let options = masterdata ? { ...masterdata.data } : {};
        if (options?.city && options?.city?.length) {
          let record = options.city
            .filter((ob) => ob.state_id == data.value)
            .map((data) => {
              data.value = data.id;
              data.label = data.label;
              return data;
            });
          dispatch(setCityList(record));
        }
      }
      setBasicDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      setIsValueChange(true);
    }
  };
  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let is_value_change = isValueChange;
    let reqBody = {
      lead_id: lead_id,
      first_name: basicDetails.first_name || "",
      last_name: basicDetails.last_name || "",
      mobile: basicDetails.mobile,
      email: basicDetails.email ? basicDetails.email : null,
      ...(basicDetails.marital_status && {
        marital_status: basicDetails.marital_status,
      }),
      ...(basicDetails.gender && { gender: basicDetails.gender }),
      dob:(basicDetails.dob) ? dateFormat(basicDetails.dob, "yyyy-mm-dd"): "",
      aadhar_no: basicDetails.aadhar_no?.trim().replace(/\s/g, "") || "",
      ...(basicDetails.cra_pincode && {
        cra_pincode: basicDetails.cra_pincode?.toString(),
      }),
      pancard_no: basicDetails.pancard_no,
      cra_address1: basicDetails.cra_address1 || "",
      cra_address2: basicDetails.cra_address2 || "",
      ...(basicDetails.cra_city && { cra_city: basicDetails.cra_city }),
      ...(basicDetails.cra_state && { cra_state: basicDetails.cra_state }),
      mother_name: basicDetails.mother_name || "",
      qualification: basicDetails.qualification || "",
    };
    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          is_value_change,
          customer: reqBody,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        setTouch({});
        toast.success("Success");
        dispatch(getLoanDetail(lead_id, client));
        // navigateToNext();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  // const navigateToNext = () => {
  //   navigate(`/lead-detail/customer-details/additional-details/${lead_id}`);
  // }

  let BasicDetailsForm = useMemo(() => {
    return FORM_FIELDS.map((field) => {
      if (field.name === "dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    });
  }, []);

  BasicDetailsForm = useMemo(() => {
    if (basicDetails) {
      // To Show nationality input text box in case of non phillipines
      if (basicDetails.nationality !== "other") {
        return FORM_FIELDS.filter((v) => v.id !== "other_nationality");
      }
    }
    return FORM_FIELDS;
  }, [basicDetails]);

  // All the conditions can be combined here for button enable/disable
  // const isSubmitDisabled = useMemo(() => {
  //   return lead.leadDetail.sub_status_id == 2 ? false : true;
  // }, [allowSubmit, getContext]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  function formatDate(dateStr) {
    // Parse the input date string (assumed format is DD/MM/YYYY)
    const [day, month, year] = dateStr.split('/');
  
    // Create a Date object from the parsed values (months in Date are 0-based)
    const date = new Date(year, month - 1, day);
  
    // Format the date to "18 Dec, 2024"
    const formattedDate = `${day} ${date.toLocaleString('default', { month: 'short' })}, ${year}`;
  
    return formattedDate;
  }

  const handleFetchAadhar = async () => {
    try {
      const aadharField = document.getElementById("aadhar_no");
      setIsFetchAadharVisible(false);
      const pan_card = basicDetails["pancard_no"];
      
      executeGraphQLQuery(GET_DETAILS_BY_PANCARD(pan_card), client)
      .then((result) => {
        if (result.data) {
          const jsonResp = JSON.parse(result.data?.get_details_by_pancard);
          if (jsonResp.status === 200) {
            if (jsonResp.data?.response?.maskedAadhaar) {
              setBasicDetails((current) => ({
                ...current,
                aadhar_no: jsonResp.data?.response?.maskedAadhaar,
                masked_aadhar_no: jsonResp.data?.response?.maskedAadhaar,
                dob:formatDate(jsonResp.data.response.dob)
              }));
              aadharField.readOnly = true;
            } else {
              setBasicDetails((current) => ({
                ...current,
                aadhar_no: `XXXXXXXX`,
                masked_aadhar_no: `XXXXXXXX`,
              }));
              toast.error(jsonResp.data?.response?.message);
              aadharField.readOnly = false;
            };
          } else {
            aadharField.readOnly = false;
            setBasicDetails((current) => ({
              ...current,
              aadhar_no: `XXXXXXXX`,
              masked_aadhar_no: `XXXXXXXX`,
            }));
            toast.error("Internal server error");
          }
        } else {
          aadharField.readOnly = false;
          setBasicDetails((current) => ({
            ...current,
            aadhar_no: "",
            masked_aadhar_no: "",
          }));
          toast.error("Internal server error");
        } 
      })
      .catch((error) => {
        console.error('ERROR : ', error.message);
      });
    } catch (error) {
      console.log(`ERROR : ${error.message}`);
      toast.error(error.message);
    }
  }

  

    const addCoApplicant = () => {
      navigate(
        `/lead-detail/co-applicant-details/basic-details/${crypto.encode(
          lead?.leadDetail.id
        )}`
      );
    };
    let lead_id = lead?.leadDetail?.id || ""; 

    const movetoNext = ()=>{  
      handleSubmit(); 
      if(allowSubmit){
        navigate(`/lead-detail/customer-details/income-details/${crypto.encode(lead_id)}`); 
      }  
    }

    const moveToCoapplicant = (tabId)=>{ 
      navigate(`/lead-detail/co-applicant-details/basic-details/${crypto.encode(lead_id)}?tabId=${tabId}`);  
    }
    const OpenCoApplicant = (e,id) => {
      setDeleteCoApplicant(id)
      e.stopPropagation()
      document.body.classList.add("overflow-hidden");
    };
  
    const closeModal  = () => {
      setDeleteCoApplicant(false)
      document.body.classList.remove("overflow-hidden");
    };

    const removeCoapplicant = async ()=>{
      const response = await executeGraphQLMutation(
        DELETE_COAPPLICANT(deleteCoApplicant),
        {},
        client
      );
      if (response.data?.delete_coapplicant) {
        dispatch(getLoanDetail(lead_id, client)) 
        toast.success("Success"); 
      } else {
        toast.error(response.data?.update_partner_id);
      }
      closeModal();  
    }

    const sectionInfo = {
      section: "Customer details",
      sub_section: "Basic details",
    };
    const rejectDisable =
      lead.leadDetail.reject_reason_id > 0 &&
      !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) &&
      [2, 3].includes(lead.leadDetail.status_id)
        ? true
        : false;

    const IS_LOST = lead?.leadDetail?.status_id == 5 ? true : false;


    return (
      <div
        className="image-form-outer"
        key={lead.leadDetail && lead.leadDetail?.status_id}
      >
        <div className="image-tab-panel">
          <DocumentGallery
            documentCategoryList={documentCategoryList}
            doc_id="1"
          />
        </div>

        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              {/* <span className="main-heading">Customer Details</span> */}
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <h2>Customer Details</h2>
                <span>
                  {requiredForOffers}
                    Required for Offer Check
                </span>
              </div>
            </div>
            <div className="coapptabsflex">
              <div className={`newcotabs active`}>
                <div className="primarycoapp active">
                  <div className={`primaryfont`}>Primary</div>
                </div>
                <div className="primarymain">
                  {basicDetails?.first_name ||
                    "" + " " + basicDetails?.last_name ||
                    ""}
                </div>
              </div>

              {lead?.leadDetail?.co_applicant.length > 0 &&
                lead?.leadDetail?.co_applicant.map((coApplicant, index) => {
                  const tabId = `coApplicant_${index}`;
                  const label = `Co-Applicant ${index + 1}`;
                  return (
                    <div
                      className={`newcotabs`}
                      key={index}
                      onClick={() => moveToCoapplicant(tabId)}
                    >
                      <div className="primarycoapp">
                        <div className={`primaryfont`}>{label}</div>
                        <i
                          className="ic-delete coappdelete"
                          onClick={(e) => OpenCoApplicant(e, coApplicant.id)}
                        ></i>
                      </div>
                      <div className="primarymain">
                        {coApplicant.ca_first_name || "N/A"}
                      </div>
                    </div>
                  );
                })}
            </div>

            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
            >
              <div className="row">
                {BasicDetailsForm.map((field, index) =>
                  ["text", "pattern-format", "number"].includes(field.type) ? (
                    <fieldset className="form-filed col-md-6 " key={index}>
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={
                          (lead?.leadDetail?.fulfillment_type === "self" || (lead?.leadDetail?.fulfillment_type === "ambak" && field.name==="mobile")) &&
                          (field.name === "mobile" ||
                            (field.name === "aadhar_no" &&
                              basicDetails.aadhar_touch))
                            ? basicDetails[`masked_${field.name}`]
                            : basicDetails && basicDetails[field.name]
                        }
                        placeholder=" "
                        maxLength={field.maxLength}
                        label={`${field.label} ${fieldsRequiredForOffers.includes(field.name) ? starSymbol :""}`}

                        onChange={
                          field.type === "pattern-format"
                            ? (data) =>
                                handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={
                          isFormTouch[field.name] ? field.validations : null
                        }
                        
                        validationError={
                          basicDetails[field.name] ||
                          basicDetails[field.name] == ""
                            ? isFormTouch[field.name]
                              ? field.validationError
                              : ""
                            : ""
                        }
                        readOnly={field.readOnly}
                        required={field.required}
                      />
                      {field.name === "pancard_no" && isFetchAadharVisible && (
                        <div
                          type="submit"
                          className="fetchAadharbtn"
                          onClick={handleFetchAadhar}
                        >
                          Fetch Aadhar
                        </div>
                      )}
                    </fieldset>
                  ) : field.type === "date-picker" ? (
                    <fieldset className="form-filed col-md-6 " key={index}>
                      <FormsyDatePicker
                        name="dob"
                        selectedDate={
                          basicDetails &&
                          basicDetails[field.name] &&
                          new Date(basicDetails[field.name])
                        }
                        placeholder={`${field.label} ${fieldsRequiredForOffers.includes(field.name) ? starSymbol :""}`}
                        maxDate={field.maxDate}
                        minDate={field.minDate}
                        value={basicDetails && basicDetails[field.name]}
                        onDateSelect={(date) =>
                          handleDateChange(date, field.name)
                        }
                        dateFormat="dd MMM, yyyy"
                        validations={field.validations}
                        validationError={
                          basicDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        hasError={
                          basicDetails.hasOwnProperty(field.name) &&
                          !basicDetails[field.name]
                            ? true
                            : false
                        }
                      />
                    </fieldset>
                  ) : field.type === "dropdown" ? (
                    <fieldset className="single-select col-md-6" key={index}>
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder: `${field.label} ${fieldsRequiredForOffers.includes(field.name) ? starSymbol :""}`,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value:
                            ALL_OPTIONS &&
                            ALL_OPTIONS[field.optionsKey] &&
                            ALL_OPTIONS[field.optionsKey].filter(
                              ({ value }) => value == basicDetails[field.name]
                            ),
                          isDisabled: field.readOnly,
                        }}
                        required={field.required}
                        value={basicDetails && basicDetails[field.name]}
                        onChange={(data) =>
                          handleSelectChange(data, field.name)
                        }
                      />
                    </fieldset>
                  ) : null
                )}
              </div>

              <div className="btn-save-remarks btn-save-next">
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={!allowSubmit || IS_LOST}
                >
                  Save
                </button>
                <button
                  className="btn-primary-outline"
                  type="button"
                  onClick={addCoApplicant}
                  disabled={IS_LOST}
                >
                  Add Co-Applicant
                </button>
                {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine} disabled={rejectDisable}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}

                <button
                  className="btn-primary-outline btn-next"
                  disabled={!allowSubmit || IS_LOST}
                  onClick={movetoNext}
                >
                  Next
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory
              showremarkform={showremarkform}
              markAsFreezed={getContext.markAsFreezed}
              accessCondition={accessCondition}
              sectionInfo={sectionInfo}
            />
          </Modal>

          <Modal
            show={deleteCoApplicant}
            handleClose={closeModal}
            removeHeight={true}
          >
            <h3>Co-Applicant Remove</h3>
            <div className="removecoapp">
              Are you sure want to remove Co-Applicant?
            </div>
            <div className="removebtn">
              <button className="coappnobtn" onClick={closeModal}>
                No
              </button>
              <button className="coappyesbtn" onClick={removeCoapplicant}>
                Yes!
              </button>
            </div>
          </Modal>
        </div>
      </div>
    ); 
};
export default BasicDetailsForm;
