import React, { useEffect, useState } from "react";
import sampleCsvPath from "../../../../webroot/excel-sample/pre_register_lead_sample.csv";
import { InventoryService, MasterService } from "../../../../services";
import { toast } from "react-toastify";
import "../pre-register-lead.css";
import Loader from "../../../elements/Loader";
import generalService from "../../../../services/generalService";
import Select from "react-select";

const LeadImportScreen = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [importResponse, setImportResponse] = useState();
  const [subSource, setSubSource] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [recentFileUrl,setRecentFileUrl]=useState('');

  useEffect(() => {
    getUserInfo();
    getSubSource();
    getCityList();
    getRecentFileUrl();
  }, []);

  const getCityList = async () => {
    let response = await generalService.stateCityList();
    const city = response.data.data.city;
    setCityList(city);
  };

  const getRecentFileUrl = async () => {
    try {
       const data= await MasterService.get(`/partner/partner/recent-uploaded-fileurl`)
      setRecentFileUrl(data.data?.data)
    } catch (error) {
      console.log(error);
    }
  }

  const getUserInfo = () => {
    const userInformation = localStorage.getItem("user_information");
    if (userInformation) {
      try {
        const parsedUserInformation = JSON.parse(userInformation);
        if (parsedUserInformation?.user_data) {
          setUserInfo((prevState) => ({
            ...prevState,
            yodha_code: parsedUserInformation?.yodha_code,
            user_id: parsedUserInformation?.user_data?.user_id,
          }));
        } else {
        }
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
      }
    }
  };

  const handleFileChange = (event) => {
    // setFile(event.target.files[0]);

    const selectedFile = event.target.files[0];

    if (
      selectedFile &&
      selectedFile.type !== "text/csv" &&
      !selectedFile.name.endsWith(".csv")
    ) {
      toast.error("Please upload a valid CSV file.");
      event.target.value = null; // Clear the selected file
      return;
    }

    setFile(selectedFile);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.error("Please choose a CSV file first!");
      return;
    }

    const formData = new FormData();
    formData.append("dealer_id", userInfo?.user_id);
    formData.append("upload_type", "customer_doc");
    formData.append("images", file);

    setUploading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await InventoryService.uploadFilesToS3(formData, {
        "Content-Type": "multipart/form-data",
      });

      if (response.data.status === 200) {
        const getUploadedFileInfo = response?.data?.data[0];
        await startImportingLeadData(getUploadedFileInfo);
      }
    } catch (error) {
      setUploading(false);
      setError(error.message || "Something went wrong");
      console.error("There was an error uploading the file!", error);
    }
  };

  const startImportingLeadData = async (fileInfo) => {
    const params = {
      file_url_info: fileInfo,
      user_info: userInfo,
    };

    try {
      const res = await MasterService.post(
        "/partner/partner/import-partner-lead",
        params
      );
      if (res.status === 200) {
        if (res.data.error) {
          setUploading(false);
          toast.error(res?.data?.error);
        } else {
          setImportResponse(res.data.result);
          setUploading(false);
          setSuccess(true);
          alert(
            `Total Created: ${res?.data?.result.created_leads_count}, Total Failed: ${res?.data?.result.already_exists_leads_count}, Total Invalid: ${res?.data?.result.invalid_leads_count}`
          );
        }
      }
    } catch (error) {
      console.error("Error in startImportingLeadData:", error); // Log any errors
    }
  };

  const getSubSource = async () => {
    try {
      const [{ data: subSourceData }] = await Promise.all(
        [
          MasterService.get("/partner/partner/get-sub-source"),
        ]
      );
      setSubSource(subSourceData.data)
    } catch (error) {
      console.log(error);
    }
  }

  const DropdownSubSource = ({ data }) => {
    return (
      <div style={styles.container}>
        <label style={styles.label} htmlFor="subsource-dropdown">
          Example List of sub_source_id with Subsource name
        </label>
        <Select
            id="city_id"
            options={data}
            name="city_id"
            placeholder={"subsource name : sub_source_id"}
            getOptionLabel={(option) => `${option.subsource} : ${option.id}`}
            getOptionValue={(option) => option.id}
          />
      </div>
    );
  };

  const DropdownCity = () => {
    return (
      <div style={styles.container}>
        <label style={styles.label} htmlFor="subsource-dropdown">
          Example List of city_id with city name
        </label>
        <Select
            id="city_id"
            options={cityList}
            name="city_id"
            placeholder={"city name : city_id"}
            getOptionLabel={(option) => `${option.name} : ${option.id}`}
            getOptionValue={(option) => option.id}
          />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <h3 className="top-heading">Import Leads</h3>
      <div className="card">
        <div className="card-body">
          <div className="leadcard">
            <div className="leadcardbody">
              <div className="leadfileinput">
                <div className="leadfileflex">
                  <div>
                    Select File to Upload<span>*</span>
                  </div>
                  <input
                    className="leadfileupload"
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv"
                  />
                  {/* <input type="file" className="leadfileupload" /> */}
                </div>
                <div className="leaddownloadfile">
                  <a
                    href={sampleCsvPath}
                    download="pre_register_lead_sample.csv"
                    className="leaddownloadfile"
                  >
                    Download Sample File
                  </a>
                </div>
              </div>

              <div className="leadrequired">
                Required CSV Format<span>*</span>
              </div>
              <div className="table-responsive ">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>name</th>
                      <th>mobile</th>
                      <th>email</th>
                      <th>sub_source_id</th>
                      <th>yodha_code</th>
                      <th>city_id</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <tr>
                        <td>Shashi</td>
                        <td>9876543210</td>
                        <td>shashi@gmail.com</td>
                        <td>2</td>
                        <td>68</td>
                        <td>1650</td>
                      </tr>
                      <tr>
                        <td>Dev</td>
                        <td>8400707072</td>
                        <td>dev@gmail.com</td>
                        <td>2</td>
                        <td>68</td>
                        <td>1651</td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>

              <div className="leadnote">
                <span>Note : </span>csv file should be separated by tab.comma
                and semicolon
              </div>
              <DropdownCity/>
              <DropdownSubSource data={subSource} />
              <div className="leadcard">
                <button
                  style={{marginLeft:"250px"}}
                  className="leaduploadbtn"
                  onClick={handleFileUpload}
                  disabled={uploading}
                >
                  {uploading ? "Uploading..." : "Start Upload"}
                </button>
                {/* <button className="leaduploadbtn">Upload</button> */}
                {recentFileUrl && (
                      <div className="recentfileurlfile import-responce-Container">
                        <a
                          href={recentFileUrl}
                          download="recent_file_url.csv"
                          className="recentfileurlfile"
                        >
                          Download Recent Uploaded File
                        </a>
                      </div>
                    )}
              </div>
              <div className="leaderror">
                {error && <div style={{ color: "red" }}>Error: {error}</div>}
                {uploading && <Loader />}
                {success && (
                  <>
                    <div style={{ color: "green" }}>
                      Lead import completed successfully ✅!
                    </div>
                    {importResponse?.failed_CSV_Url && (
                      <div className="leaddownloadfile import-responce-Container">
                        <a
                          href={importResponse?.failed_CSV_Url}
                          download="pre_register_lead_sample.csv"
                          className="leaddownloadfile"
                        >
                          Download Failed Record
                        </a>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadImportScreen;


const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "20px",
    fontFamily: "Arial, sans-serif",
  },
  label: {
    marginRight: "10px",
    fontSize: "16px",
    color: "#333",
    fontWeight: "bold",
  },
  select: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "14px",
    color: "#333",
    width: "250px",
    backgroundColor: "#f9f9f9",
  },
  option: {
    padding: "10px",
    fontSize: "14px",
    color: "#333",
  },
};
