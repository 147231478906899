import { gql } from "@apollo/client";

export const UPDATE_CALL_FEEDBACK = gql`
mutation
    update_call_feedback($updateCallFeedbackInput: UpdateCallFeedbackInput!,  $partner_id: Float,$user_id:Float, $api_called_by: API_CALL_BY!) {
        update_call_feedback(updateCallFeedbackInput: $updateCallFeedbackInput, partner_id: $partner_id,user_id:$user_id, api_called_by: $api_called_by)
    }`


export const GET_CALL_ATTEMPT_DATA =gql`
mutation
    get_call_attempt_data($partner_id: Float,$lead_id:Float) {
        get_call_attempt_data(partner_id: $partner_id,lead_id:$lead_id)
    }`

    export const START_CALLING_QUEUE = gql`
    mutation prioritize_lead($user_id: Float!, $lead_id: Float, $queue: Float, $lead_queue_end_time: String) {
      prioritize_lead(user_id: $user_id, lead_id: $lead_id, queue: $queue, lead_queue_end_time: $lead_queue_end_time) {
        id
        lead_id
        priority_id
        user_id
        customer_name
        overdue
        lead_url
        lead_queue_start_time
        lead_queue_end_time
        duration
        updated_date
        status
      }
    }
  `;

  export const PAUSE_PRIORITIZE_LEAD = gql`
  mutation pausePrioritizeLead($id: Float!, $isPlaying: Boolean!) {
    pause_prioritize_lead(id: $id, isPlaying: $isPlaying)
  }`;
  