import React, { useState, useEffect } from "react";
import { Table, Spinner, Alert, Button, Form } from "react-bootstrap";
import { MasterService } from "../../services";
import Pagination from "../elements/pagination";
import InputField from "../elements/Input";
import Select from "react-select";
import Formsy from "formsy-react";
import FormsySelect from "../elements/FormsySelect";
import "./HlRoiData.css";
import Modal from "../elements/Modal";

// Toastify
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PROFESSION_OPTIONS = [
  { value: "", label: "All" },
  { value: "1", label: "Salaried" },
  { value: "2", label: "Self Employed Professional" },
  { value: "3", label: "Self Employed Non Professional" },
];

const LapRoiData = () => {
  const [lapRoi, setLapRoi] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  // Modal and form states
  const [showModal, setShowModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [saving, setSaving] = useState(false);

  // Filter states
  const [searchParams, setSearchParams] = useState({
    bank_name: "",
    status: "",
    profession_id: "",
  });

  // API endpoints
  const API_URL = "/process-rule/crud/getBreLapData";
  const ADD_API_URL = "/process-rule/crud/addBreLapRoiData";
  const UPDATE_API_URL = "/process-rule/crud/updateBreLapDta/";
  const SOFT_DELETE_API_URL = "/process-rule/crud/delete-breLapData/";


  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null)
      const response = await MasterService.get(API_URL, {
        headers: { "Content-Type": "application/json" },
      });
      if (response?.data?.data) {
        setLapRoi(response.data.data);
      } else {
        setError("No data received from API");
      }
    } catch (err) {
      console.error(" API Error:", err);
      setError("Failed to fetch data. Check API.");
      toast.error("Error fetching data");

    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  
  const filteredData = lapRoi.filter((item) => {
    const bankMatch = searchParams.bank_name
      ? item.bank_name?.toLowerCase().includes(searchParams.bank_name.toLowerCase())
      : true;

    const statusMatch = searchParams.status
      ? item.lap_status.toString() === searchParams.status
      : true;

      const professionMatch = searchParams.profession_id
      ? item.profession_id?.toString() === searchParams.profession_id || 
        item.lap_profession_id?.toString() === searchParams.profession_id 
      : true;
  
    return bankMatch && statusMatch && professionMatch;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setPageCount(Math.ceil(filteredData.length / itemsPerPage));
  }, [filteredData, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  const handleAddClick = () => {
    setSelectedItem({
      lap_id: "",             
      bank_id: "",
      profession_id: "",
      min_cibil: "",
      max_cibil: "",
      property_sub_type: "",
      salary_mode: "",
      property_usage: "",
      roi: "",
      status: "", 
    });
    setIsUpdating(false);
    setShowModal(true);
  };

  const handleDeleteClick = async (item) => {
    const id = item.lap_id;
    try {
      await MasterService.put(`${SOFT_DELETE_API_URL}${id}`, { status: 0 }, {
        headers: { "Content-Type": "application/json" },
      });
      toast.success("Delete Successfully");


      setLapRoi((prevData) =>
        prevData.map((data) =>
          data.lap_id === id ? { ...data, lap_status: "0" } : data
        )
      );
      fetchData();
    } catch (error) {
      console.error("Failed to delete entry:", error);
      setError("Failed to delete entry.");
    }
  };

  const handleSaveChanges = async () => {
    if (!selectedItem || saving) return;
  
    let requestData = {};
  
    
    // 1) ADD MODE
    if (!isUpdating) {
      if (!selectedItem.bank_id || selectedItem.bank_id.trim() === "") {
        toast.error("Bank ID is required!");
        return;
      }
      if (!selectedItem.min_cibil || selectedItem.min_cibil.trim() === "") {
        toast.error("Min CIBIL is required!");
        return;
      }
      if (!selectedItem.roi || selectedItem.roi.trim() === "") {
        toast.error("ROI is required!");
        return;
      }
  
      requestData = {
        bank_id: Number(selectedItem.bank_id),
        profession_id: selectedItem.profession_id ? 
        Number(selectedItem.profession_id) : null,
        min_cibil:selectedItem.min_cibil !== "" && selectedItem.min_cibil != null
        ? Number(selectedItem.min_cibil)
        : null,
        max_cibil: selectedItem.max_cibil !== "" && selectedItem.max_cibil != null
        ? Number(selectedItem.max_cibil)
        : null,
        salary_mode:selectedItem.salary_mode ? selectedItem.salary_mode 
        : null,
        property_sub_type:selectedItem.property_sub_type ?
         selectedItem.property_sub_type : null,
        property_usage:selectedItem.property_usage ?
         selectedItem.property_usage : null,
        roi:   selectedItem.roi !== "" && selectedItem.roi != null
        ? Number(selectedItem.roi)
        : null,
        status:
        selectedItem.status && selectedItem.status.trim() !== ""
          ? Number(selectedItem.status)
          : 1,};
    }
    else {
      requestData = {
        profession_id: selectedItem.profession_id
          ? Number(selectedItem.profession_id)
          : null,
          min_cibil:
          selectedItem.min_cibil !== "" && selectedItem.min_cibil != null
            ? Number(selectedItem.min_cibil)
            : null,
        max_cibil:
          selectedItem.max_cibil !== "" && selectedItem.max_cibil != null
            ? Number(selectedItem.max_cibil)
            : null,
        property_sub_type: selectedItem.property_sub_type ?
        selectedItem.property_sub_type : null,
        property_usage: selectedItem.property_usage ?
        selectedItem.property_usage : null,
        roi:
          selectedItem.roi !== "" && selectedItem.roi != null
            ? Number(selectedItem.roi)
            : null,
        status:
          selectedItem.status && selectedItem.status.trim() !== ""
            ? Number(selectedItem.status)
            : 1,
      };
    }
  
    setSaving(true);
    try {
      if (isUpdating) {
        // Perform update
        await MasterService.put(
          `${UPDATE_API_URL}${selectedItem.lap_id}`,
          requestData,
          { headers: { "Content-Type": "application/json" } }
        );
        toast.success("Updated successfully!");
      } else {
        // Perform add
        const response = await MasterService.put(ADD_API_URL, requestData, {
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.success) {
          setLapRoi([...lapRoi, response.data.data]);
          toast.success("Added successfully!");
        }
      }
      setShowModal(false);
      fetchData(); // Refresh data after save
    } catch (error) {
      console.error("Save Error:", error.response?.data || error.message);
      setError("Failed to save data. Please try again.");
      toast.error("Invalid bank ID");
      await fetchData();
    } finally {
      setSaving(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const getProfessionLabel = (professionId) => {
    if (!professionId) return '';
    const profession = PROFESSION_OPTIONS.find(
      p => p.value === professionId?.toString()
    );
    return profession ? profession.label : professionId;
  };

  return (
    <>
      <div>
        {/* Filters */}
        <div className="search-wrap mb-3">
          <div className="filter-container">
            <div className="filter-body">
              <div className="row">
                {/* Bank Name Filter */}
                <div className="col-sm-3">
                  <fieldset className="single-select">
                    <div className="filter-label">Bank Name</div>
                    <div className="filter-input">
                      <InputField
                        name="bank_name"
                        placeholder="Search Bank Name"
                        value={searchParams.bank_name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </fieldset>
                </div>

                {/* Status Filter */}
                <div className="col-sm-3">
                  <fieldset className="single-select">
                    <div className="filter-label">Status</div>
                    <div className="material">
                      <Select
                        options={[
                          { value: "", label: "All" },
                          { value: "1", label: "Active" },
                          { value: "0", label: "Inactive" },
                        ]}
                        placeholder="Select Status"
                        value={{
                          value: searchParams.status,
                          label:
                            searchParams.status === "1"
                              ? "Active"
                              : searchParams.status === "0"
                              ? "Inactive"
                              : "All",
                        }}
                        onChange={(selected) => {
                          setSearchParams({
                            ...searchParams,
                            status: selected?.value || "",
                          });
                        }}
                      />
                    </div>
                  </fieldset>
                </div>

                {/* Profession Filter */}
                <div className="col-sm-3">
                  <fieldset className="single-select">
                    <div className="filter-label">Profession</div>
                    <div className="material">
                      <Select
                        options={PROFESSION_OPTIONS}
                        placeholder="Select Profession"
                        value={
                          PROFESSION_OPTIONS.find(
                            (opt) => opt.value === searchParams.profession_id
                          ) || PROFESSION_OPTIONS[0]
                        }
                        onChange={(selected) => {
                          setSearchParams({
                            ...searchParams,
                            profession_id: selected?.value || "",
                          });
                        }}
                      />
                    </div>
                  </fieldset>
                </div>

                <div className="text-btn d-flex">
                  <div className="btn-submit-reset top-btn-none pull-right">
                    <button
                      className="btn-primary"
                      onClick={() => setCurrentPage(1)}
                    >
                      Apply
                    </button>
                    <button
                      className="btn-reset"
                      onClick={() => {
                        setSearchParams({
                          bank_name: "",
                          status: "",
                          profession_id: "",
                        });
                        setCurrentPage(1);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn-reset"
                      onClick={handleAddClick}
                      style={{ marginLeft: "100px" }}
                    >
                      Add Entry
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Data Table */}
        {loading ? (
          <div className="text-center mt-8">
            <Spinner animation="border" variant="primary" />
            <p className="mt-2 text-gray-600">Loading data...</p>
          </div>
        ) : error ? (
          <Alert variant="danger" className="mb-8">
            {error}
          </Alert>
        ) : (
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <Table striped bordered hover responsive className="w-full">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th>ID</th>
                  <th>Bank </th>
                  <th>Profession </th>
                  <th>Min Cibil</th>
                  <th>Max Cibil</th>
                  <th>Salary Mode</th>
                  <th>Property Sub Type</th>
                  <th>Property Usage</th>
                  <th>ROI</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((item) => (
                    <tr key={item.lap_id} className="hover:bg-gray-50 transition-colors">
                      <td>{item.lap_id || item.id}</td>
                      <td>{item.bank_name}</td>
                      <td className="py-4 px-6">
      {getProfessionLabel(item.profession_id || item.lap_profession_id)}
    </td>                      <td>{item.lap_min_cibil}</td>
                      <td>{item.lap_max_cibil}</td>
                      <td>{item.lap_salary_mode}</td>
                      <td>{item.lap_property_sub_type}</td>
                      <td>{item.lap_property_usage}</td>
                      <td>{item.lap_roi}</td>
                      <td>
                        <span
                          className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${
                            item.lap_status === "1" || item.lap_status === 1
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800"
                          }`}
                        >
                          {item.lap_status === "1" || item.lap_status === 1
                            ? "Active"
                            : "Inactive"}
                        </span>
                      </td>
                      <td>
                        <div className="button-group" style={{ margin: 0 }}>
                          {/* Update Button */}
                          <Button
                            onClick={() => {
                              setSelectedItem({
                                lap_id: item.lap_id, 
                                profession_id: item.lap_profession_id?.toString(),
                                min_cibil: item.lap_min_cibil?.toString(),
                                max_cibil: item.lap_max_cibil?.toString(),
                                salary_mode: item.lap_salary_mode,
                                property_sub_type: item.lap_property_sub_type,
                                property_usage: item.lap_property_usage,
                                roi: item.lap_roi?.toString(),
                                status: item.lap_status?.toString() || "1",
                              });
                              setIsUpdating(true);
                              setShowModal(true);
                            }}
                            variant="warning"
                            size="sm"
                            style={{ marginRight: "6px" }}
                          >
                            ✏️ Edit
                          </Button>
                          {(item.lap_status === "1" || item.lap_status === 1) && (
                            <Button
                              onClick={() => handleDeleteClick(item)}
                              variant="danger"
                              size="sm"
                            >
                              🗑️ Delete
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="11" className="text-center py-6 text-gray-600">
                      No matching data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {/* Add Pagination Component */}
            {filteredData.length > 0 && (
              <div className="mt-4 mb-4">
                <Pagination
                  perPage={itemsPerPage}
                  pageCount={pageCount}
                  activePage={currentPage}
                  onPaginate={handlePageChange}
                />
              </div>
            )}
          </div>
        )}

<Modal show={showModal} handleClose={() => setShowModal(false)} centered>
        <div className="modern-modal">
          <h4 className="modal-title">
            {selectedItem?.lap_id ? "Edit ROI Data" : "Add ROI Data"}
          </h4>

          <Formsy onValidSubmit={handleSaveChanges}>
            {/* Bank ID - Required for add */}
            {!isUpdating && (
              <div className="input-group">
                <label>
                  Bank ID <span className="required-asterisk">*</span>
                </label>
                <InputField
                  name="bank_id"
                  value={selectedItem?.bank_id || ""}
                  required
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      bank_id: e.target.value,
                    })
                  }
                />
              </div>
            )}
{/* Profession Dropdown in Modal */}
<div className="input-group">
  <label>Profession</label>
  <FormsySelect
    name="profession_id"
    inputProps={{
      options: PROFESSION_OPTIONS.filter(option => option.value !== ""), // Remove "All"
      placeholder: selectedItem?.profession_id ? undefined : "Select Profession",
      className: "react-select",
      classNamePrefix: "react-select",
      value: PROFESSION_OPTIONS.filter(
        (opt) => opt.value === selectedItem?.profession_id?.toString()
      ),
    }}
    onChange={(selected) =>
      setSelectedItem({
        ...selectedItem,
        profession_id: selected?.value || "",
      })
    }
  />
</div>

                          {/* Min CIBIL */}
<div className="input-group">
  <label>
    Min CIBIL {!selectedItem?.lap_id && <span style={{ color: "red" }}>*</span>}
  </label>
  <InputField
    name="min_cibil"
    type="text"
    value={selectedItem?.min_cibil || ""}
    className="modern-input"
    onChange={(e) =>
      setSelectedItem({
        ...selectedItem,
        min_cibil: e.target.value,
      })
    }
  />
</div>

              {/* Max CIBIL */}
              <div className="input-group">
                <label>Max CIBIL</label>
                <InputField
                  name="max_cibil"
                  type="text"
                  value={selectedItem?.max_cibil || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      max_cibil: e.target.value,
                    })
                  }
                />
              </div>

              {/* Salary Mode */}
              <div className="input-group">
                <label>Salary Mode</label>
                <InputField
                  name="salary_mode"
                  value={selectedItem?.salary_mode || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      salary_mode: e.target.value,
                    })
                  }
                />
              </div>

              {/* Property Sub Type */}
              <div className="input-group">
                <label>Property Sub Type</label>
                <InputField
                  name="property_sub_type"
                  value={selectedItem?.property_sub_type || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      property_sub_type: e.target.value,
                    })
                  }
                />
              </div>

              {/* Property Usage */}
              <div className="input-group">
                <label>Property Usage</label>
                <InputField
                  name="property_usage"
                  value={selectedItem?.property_usage || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      property_usage: e.target.value,
                    })
                  }
                />
              </div>

           {/* ROI */}
<div className="input-group">
  <label>
    ROI {!selectedItem?.lap_id && <span style={{ color: "red" }}>*</span>}
  </label>
  <InputField
    name="roi"
    type="text"
    value={selectedItem?.roi || ""}
    className="modern-input"
    onChange={(e) =>
      setSelectedItem({
        ...selectedItem,
        roi: e.target.value,
      })
    }
  />
</div>

              {/* Status */}
              <div className="input-group">
  <label>Status</label>
  <FormsySelect
    name="status"
    inputProps={{
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ],
      placeholder: selectedItem?.status ? undefined : "Select Status",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ].filter((option) => option.value === selectedItem?.status),
    }}
    onChange={(selected) =>
      setSelectedItem({
        ...selectedItem,
        status: selected?.value || "1",
      })
    }
  />
</div>

              {/* Modal Buttons */}
              <div className="button-group">
                <button
                  className="btn-cancel"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn-update"
                  type="submit"
                  disabled={saving}
                >
                  {saving
                    ? "Saving..."
                    : selectedItem?.lap_id
                    ? "Update"
                    : "Add"}
                </button>
              </div>
            </Formsy>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LapRoiData;
