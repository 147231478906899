import { useEffect, useState } from "react";
import Pagination from "../elements/pagination"
import crypto from "../../config/crypto";
import moment from "moment";
import ReactAudioPlayer from 'react-audio-player';
import { Modal } from "react-bootstrap";

export const CallListTable = ({callType,pageCount,callList,page ,setPage}) => {
    const limit = 10;

    const [dataIndex, setDataIndex] = useState(-1);
    const [audioUrl, setAudioUrl] = useState('');

    
    // useEffect(() => {
    //   searchCallDetail();
    // }, [page, limit, callType, leadType]);


    const navigateLeadDetail = (lead_id,status_id) => {
      let url =  `/lead-detail/update-status/${crypto.encode(lead_id)}`
        if(status_id == 1){
            url =  `/lead-detail/update-status/${crypto.encode(lead_id)}` 
        }else if(status_id == 2){
            url =  `/lead-detail/update-status/${crypto.encode(lead_id)}`
        }else if(status_id ==3){
            url =  `/lead-detail/update-status/${crypto.encode(lead_id)}` 
        }else if(status_id==4){
            url =  `/lead-detail/disbursed/${crypto.encode(lead_id)}`  
        }
        window.open(url, '_blank');
        // navigate(url)
    }

    function secondsToTime(seconds) {

      const hours = Math.floor(~~seconds / 3600);
      const minutes = Math.floor((~~seconds % 3600) / 60);
      const secs = ~~seconds % 60;
      
      return [hours, minutes, secs]
        .map(unit => String(unit).padStart(2, '0')) // Ensures two-digit format
        .join(':');
    }

    const onPaginate = (p) => {
      setPage(p);
    }


    useEffect(()=>{
      // Make the GET request using fetch
      setAudioUrl(callList?.[dataIndex]?.recording_url);
    },[dataIndex])

    return (
        <div className="lead-list-data-table">
          <Modal show={dataIndex !== -1} handleClose={()=>{setDataIndex(-1)}} >

            <div style={{position: "fixed",inset: "0px",width: "20rem",height: "5rem",maxWidth: "100vw",maxHeight: "100dvh",margin: "auto"}}>  
              <div onClick={()=>{setDataIndex(-1)}} style={{cursor:"pointer",fontSize:"20px", float:"right", marginBottom:"10px", color:"white"}}>X</div>
              <ReactAudioPlayer
                src={audioUrl}
                autoPlay={false}
                controls={true}
              />
            </div>
          </Modal>
             <table>
                <thead>
                    <tr style={{backgroundColor: '#fff1d6'}}>
                        <th>Lead ID</th>
                        <th>Mobile No</th>
                        <th>Duration</th>
                        <th>Time</th>
                        <th>Call Status</th>
                        <th>Assigned To</th>
                        {callType !="Missed" && <th>Recording Url</th>}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                  {callList?.map((data, index) => (
                    <tr key={index}>
                      <td>{data?.lead_id}</td>
                      <td>{"XXXXXX" + data?.mobile_no?.slice(-4)}</td>
                      <td>{secondsToTime(data?.duration/1000)}</td>
                      <td>{moment(data?.time).format("DD-MM-YYYY") }</td>
                      <td><div className="badge" data-value={data?.call_status}>{data?.call_status}</div></td>
                      <td>{data?.lead?.assign_user?.name} - {data?.lead?.assign_user?.id}</td>
                      {callType != "Missed" && 
                      <td>
                        {data?.recording_url &&
                        <div className="tooltip">
                          <span className="tooltiptext">Play Recording</span>
                          <img  onClick={()=>{setDataIndex(index)}} src="https://static.ambak.com/images/multimedia-audio-player.svg" alt="play" style={{cursor:"pointer"}}/>
                        </div>
                        }
                      </td>
                      }
                    <td style={{cursor: "pointer",fontSize:"20px"}} onClick={() => navigateLeadDetail(data?.lead_id,data?.lead?.status_id)}><i className="ic-edit commoniconbtn mrg-r10" style={{width:"30px"}}></i></td>
                    </tr>
                  ))}
                    
                </tbody>
            </table>
            <Pagination pageCount={Math.ceil(pageCount/limit)} activePage={page} onPaginate={onPaginate} />
            <div className="mark-lost-popup">
            </div>
        </div>
    )
}   