import React, { useState } from "react";
import { Tab, Nav, Container } from "react-bootstrap";
import BreBankUSP from "./bre_bank_usp"; 
import BreBankProcessingCharges from "./bre_bank_processing_charges";
import BreLapProcessing from "./bre_bank_lap_processing_charges";
import './BREDashboard.css';
import { useSelector } from "react-redux";
import HlRoiDataTable from "../bre-bank-operation/HlRoiDataTable";
import LapCashRoiDataTable from "../bre-bank-operation/LapCashRoiDataTable";
import LapRoiData from "../bre-bank-operation/LapRoiDataTable";
import BreFoirDataTable from "./BreFoirDataTable";
import BreCashRoiDataTable from "./BreCashRoiDataTable";
import BreBankTenureCal from "./BreBankTenureCal";


const BREDashboard = () => {
  const user_information=useSelector((state)=>state.user.user_information)
  const userInfo=JSON.parse(user_information);
  const name = userInfo?.name||""  ; 
  const [activeTab, setActiveTab] = useState("processingCharges");
  // Change dynamically if needed

  return (
    <div className="container-fluid" >
    
    <div style={{ textAlign: "center", marginTop: "3px" }}>
  <div style={{ fontSize: "18px", fontWeight: "400", color: "#000", marginBottom: "2px" }}>
    Hello <span>{name}</span>,
  </div>
  <div style={{ fontSize: "32px", fontWeight: "700", color: "#000" }}>
    Welcome to BRE Dashboard
  </div>
</div>




        {/* Tabs Section */}
        <div style={{marginTop:"50px"}}>
        <Tab.Container  defaultActiveKey={activeTab}>
          <Nav variant="flex-column nav nav-newpills">
            <Nav.Item>
              <Nav.Link eventKey="processingCharges" onClick={() => setActiveTab("processingCharges")}>
                Processing Charges
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="lapProcessingCharges" onClick={() => setActiveTab("lapProcessingCharges")}>
                LAP Processing Charges
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bankUSP" onClick={() => setActiveTab("bankUSP")}>
                Bank USP
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="HlRoiDataTable" onClick={() => setActiveTab("HlRoiDataTable")}>
              HL ROI
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="LapCashRoiDataTable" onClick={() => setActiveTab("LapCashRoiDataTable")}>
              LAP Cash ROI 
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="LapRoiData" onClick={() => setActiveTab("LapRoiData")}>
              LAP ROI 
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="BreFoirDataTable" onClick={() => setActiveTab("BreFoirDataTable")}>
              BreFoirDataTable
              </Nav.Link>
            </Nav.Item>
            
            <Nav.Item>
              <Nav.Link eventKey="BreCashRoiDataTable" onClick={() => setActiveTab("BreCashRoiDataTable")}>
              Bre Cash ROI
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="BreBankTenureCal" onClick={() => setActiveTab("BreBankTenureCal")}>
              Bank Tenure Cal
              </Nav.Link>
            </Nav.Item>
            
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="processingCharges">
              <BreBankProcessingCharges />
            </Tab.Pane>
            <Tab.Pane eventKey="lapProcessingCharges">
              <BreLapProcessing />
            </Tab.Pane>
            <Tab.Pane eventKey="bankUSP">
              <BreBankUSP />
            </Tab.Pane>

            <Tab.Pane eventKey="HlRoiDataTable">
              <HlRoiDataTable />
            </Tab.Pane>

            <Tab.Pane eventKey="LapCashRoiDataTable">
              <LapCashRoiDataTable />
            </Tab.Pane>

            <Tab.Pane eventKey="LapRoiData">
              <LapRoiData />
            </Tab.Pane>

            <Tab.Pane eventKey="BreFoirDataTable">
              <BreFoirDataTable />
            </Tab.Pane>
            
            <Tab.Pane eventKey="BreCashRoiDataTable">
              <BreCashRoiDataTable />
            </Tab.Pane>

            <Tab.Pane eventKey="BreBankTenureCal">
              <BreBankTenureCal />
            </Tab.Pane>
            
          </Tab.Content>
        </Tab.Container>
      </div>
      </div>
  );
};

export default BREDashboard;
