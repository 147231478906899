import axios from "axios";
import { API_URL } from "../config/constants";
import { getCurrentUserDetails } from '../view/partner/pre-register-lead-manager/utility/getUserInfo';



const userInfo = getCurrentUserDetails();
const { access_token } = userInfo || {};
if(!access_token) {
    localStorage.setItem('isToken', false);
}

export const gatewayApiInstance = axios.create({
    baseURL: `${API_URL}finex`, // Set your base URL
    timeout: 5000, // Optional: set a timeout for requests (in ms)
    headers: {
        "api_source":"finex",
        "authorization":`Bearer ${access_token}`,
    }
  });