import csvToJsonLib from "csvtojson";

export const convertCsvToJson = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const csvData = event.target.result;
      const jsonArray = await csvToJsonLib().fromString(csvData);
      resolve(jsonArray);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsText(file);
  });
};

export const convertJsonToCsv = (json) => {
  const items = json.map((item) => Object.values(item));
  const header = Object.keys(json[0]);
  const csvData = [header.join(","), ...items.map((row) => row.join(","))].join(
    "\n"
  );
  return csvData;
};

export const downloadFile = (fileData, fileType, fileName) => {
  const blob = new Blob([fileData], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getNumberFormat_IN = (value) => {
  const formattedNumber = value.toLocaleString("en-IN");
  return formattedNumber;
};

export const sortStateListByTopStates = (state_list = []) => {
  const topStates = [
    "Delhi",
    "Haryana",
    "Karnataka",
    "Maharashtra",
    "Telangana",
    "Uttar Pradesh",
  ];

  const sortedStates = state_list.sort((a, b) => {
    const aTopIndex = topStates.indexOf(a.name);
    const bTopIndex = topStates.indexOf(b.name);

    if (aTopIndex !== -1 && bTopIndex !== -1) {
      return a.name.localeCompare(b.name);
    } else if (aTopIndex !== -1) {
      return -1;
    } else if (bTopIndex !== -1) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  return sortedStates;
};

export const numberWithCommas = (value)=> {
  return (value==0)?'--': '₹ '+value.toLocaleString('en-IN')
}

export const toCamelCase = (str)=> {
  if(str){
      return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } 
}


export const maskString=(str, options = {}) =>{
  const {
    maskChar = '*', // Character to use for masking
    unmaskedStart = 0, // Number of characters to leave unmasked at the start
    unmaskedEnd = 0, // Number of characters to leave unmasked at the end
    maskAll = false, // Whether to mask the entire string
  } = options;

  if (typeof str !== 'string') {
    return ""
  }

  const strLength = str.length;

  // If the entire string needs to be masked
  if (maskAll) {
    return maskChar.repeat(strLength);
  }

  // Ensure unmaskedStart + unmaskedEnd doesn't exceed string length
  const safeUnmaskedStart = Math.min(unmaskedStart, strLength);
  const safeUnmaskedEnd = Math.min(unmaskedEnd, strLength - safeUnmaskedStart);

  const start = str.slice(0, safeUnmaskedStart);
  const end = str.slice(-safeUnmaskedEnd);
  const masked = maskChar.repeat(Math.max(0, strLength - safeUnmaskedStart - safeUnmaskedEnd));

  return start + masked + end;
}
export const debounce=(func, delay)=> {
  let timer; // Holds the timer ID

  return function (...args) {
    const context = this; // Preserve the `this` context

    // Clear the timer if the function is called again before the delay
    clearTimeout(timer);

    // Set a new timer
    timer = setTimeout(() => {
      func.apply(context, args); // Call the original function
    }, delay);
  };
}