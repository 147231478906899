import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import Pagination from "../elements/pagination";
import { MasterService } from "../../services";
import InputField from "../elements/Input";
import Select from "react-select";
import Modal from "../elements/Modal";
import Formsy from "formsy-react";
import FormsySelect from "../elements/FormsySelect";

// Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Endpoints
const FOIR_API_URL    = "/process-rule/crud/getAllFoir"; // GET all
const ADD_FOIR_URL    = "/process-rule/crud/addFoir";    // POST add
const UPDATE_FOIR_URL = "/process-rule/crud/updateFoir"; // PUT updateFoir/:id
const DELETE_FOIR_URL = "/process-rule/crud/deleteFoir"; // PUT deleteFoir/:id

// Status filter options
const statusOptions = [
  { value: "",  label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

// Profession filter + modal options
const professionOptions = [
  { value: "",  label: "All" },
  { value: "1", label: "Salaried" },
  { value: "2", label: "Self Employed Professional" },
  { value: "3", label: "Self Employed Non-Professional" },
];

const BreFoirDataTable = () => {
  // FOIR data from API
  const [foirData, setFoirData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Modal & form
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Filter states – now using bank_name instead of bank_id
  const [searchParams, setSearchParams] = useState({
    bank_name: "",
    profession_id: "",
    status: "",
  });

  // Loading indicator
  const [loading, setLoading] = useState(true);

  // ********** 1) Fetch on mount **********
  useEffect(() => {
    fetchFoirData();
  }, []);

  // ********** 2) Apply filters **********
  useEffect(() => {
    applyFilters();
  }, [searchParams, foirData]);

  // **************************************
  //           FETCH FOIR DATA
  // **************************************
  const fetchFoirData = async () => {
    setLoading(true);
    try {
      const response = await MasterService.get(FOIR_API_URL);
      // Some backends send { data: [...] }, others send [...]
      const dataList = response.data?.data || response.data || [];
      setFoirData(dataList);
    } catch (error) {
      console.error("Error fetching FOIR data:", error);
      toast.error("Failed to fetch FOIR data!");
    } finally {
      setLoading(false);
    }
  };

  // **************************************
  //             APPLY FILTERS
  // **************************************
  const applyFilters = () => {
    const { bank_name, profession_id, status } = searchParams;
    const result = foirData.filter((item) => {
      // Filter by bank name using case-insensitive match
      const bankMatch = bank_name
        ? item.bank_name.toLowerCase().includes(bank_name.toLowerCase())
        : true;

      // status filter
      const statusMatch = status
        ? String(item.status) === status
        : true;

      // profession filter
      const professionMatch = profession_id
        ? String(item.profession_id) === profession_id
        : true;

      return bankMatch && statusMatch && professionMatch;
    });
    setFilteredData(result);
  };

  // **************************************
  //           FILTER HANDLERS
  // **************************************
  const handleFilterChange = (field, value) => {
    setCurrentPage(1);
    setSearchParams((prev) => ({ ...prev, [field]: value }));
  };

  // **************************************
  //        DELETE => status=0
  // **************************************
  const handleDelete = async (id) => {
    if (!id) return;
    try {
      await MasterService.put(`${DELETE_FOIR_URL}/${id}`);
      toast.success("FOIR entry set to Inactive!");
      fetchFoirData();
    } catch (err) {
      console.error("Delete FOIR error:", err);
      toast.error("Error deleting FOIR entry!");
    }
  };


  console.log("Selected Itemsssssssssss ",selectedItem)

  // **************************************
  //            EDIT FOIR
  // **************************************
  const handleEdit = (item) => {
    setSelectedItem({
      id:           item.id,
      bank_id:      String(item.bank_id || ""),
      profession_id:String(item.profession_id || ""),
      min_amount:   String(item.min_amount || ""),
      max_amount:   String(item.max_amount || ""),
      foir:         String(item.foir || ""),
      status:       String(item.status ),
    });
    setShowModal(true);
  };

  // **************************************
  //            ADD FOIR
  // **************************************
  const handleAdd = () => {
    setSelectedItem({
      id:          null,
      bank_id:     "",
      profession_id: "",
      min_amount:  "",
      max_amount:  "",
      foir:        "",
      status:      "1",
    });
    setShowModal(true);
  };

  // **************************************
  //        SUBMIT (Add / Update)
  // **************************************
  const handleSaveChanges = async () => {
    if (!selectedItem) return;
  
    // Convert form data
    const idVal = selectedItem.id;
    const bank_id = Number(selectedItem.bank_id);
    const prof_id = Number(selectedItem.profession_id);
    const minVal = Number(selectedItem.min_amount);
    const maxVal = Number(selectedItem.max_amount);
    const foirVal = Number(selectedItem.foir);
    const status = selectedItem.status?.toString() || "1";
  
    // Validate required fields in ADD mode
    if (!idVal) {
      if (!bank_id || bank_id <= 0) {
        toast.error("Bank ID is required and must be > 0");
        return;
      }
      if (!minVal || minVal <= 0) {
        toast.error("Min Amount is required and must be > 0");
        return;
      }
      if (!foirVal || foirVal <= 0) {
        toast.error("FOIR is required and must be > 0");
        return;
      }
    }
  
    // Build payload
    const bodyData = {
      bank_id,
      profession_id: prof_id || 0,
      min_amount: minVal || 0,
      max_amount: maxVal || 0,
      foir: foirVal || 0,
      status,
    };
  
    try {
      if (!idVal) {
        // ------- ADD MODE -------
        const resp = await MasterService.post(ADD_FOIR_URL, bodyData, {
          headers: { "Content-Type": "application/json" },
        });
        if (resp.status > 399) {
          toast.error("Bank ID is not valid");
          return;
        }
        toast.success("FOIR added successfully!");
      } else {
        // ------- UPDATE MODE -------
        await MasterService.put(`${UPDATE_FOIR_URL}/${idVal}`, bodyData, {
          headers: { "Content-Type": "application/json" },
        });
        toast.success("FOIR updated successfully!");
      }
  
      setShowModal(false);
      await fetchFoirData(); // Now fetch the latest data
    } catch (error) {
      console.error("Save FOIR error:", error.response?.data || error.message);
      const errMsg =
        error?.response?.data?.message || "Error saving FOIR data!";
      toast.error(errMsg);
    }
  };
  
  // **************************************
  //           PAGINATION
  // **************************************
  const indexOfLastItem  = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems     = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
  // **************************************
  //              RENDER
  // **************************************
  return (
    <div>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
          <p>Loading FOIR data...</p>
        </div>
      ) : (
        <>
          {/* Filters */}
          <div className="search-wrap mb-3">
            <div className="filter-container">
              <div className="filter-body">
                <div className="row">
                  {/* Bank Name Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Bank Name</div>
                      <div className="filter-input">
                        <InputField
                          name="bank_name"
                          placeholder="Filter by Bank Name"
                          value={searchParams.bank_name}
                          onChange={(e) =>
                            handleFilterChange("bank_name", e.target.value)
                          }
                        />
                      </div>
                    </fieldset>
                  </div>
  
                  {/* Status Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Status</div>
                      <div className="material">
                        <Select
                          options={statusOptions}
                          placeholder="Select Status"
                          value={statusOptions.find(
                            (opt) => opt.value === searchParams.status
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            handleFilterChange("status", newVal);
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
  
                  {/* Profession Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Profession</div>
                      <div className="material">
                        <Select
                          options={professionOptions}
                          placeholder="Select Profession"
                          value={professionOptions.find(
                            (opt) => opt.value === searchParams.profession_id
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            handleFilterChange("profession_id", newVal);
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>
  
                  {/* Buttons */}
                  <div className="text-btn d-flex">
                    <div className="btn-submit-reset top-btn-none pull-right">
                      <button
                        className="btn-primary"
                        onClick={() => setCurrentPage(1)}
                      >
                        Apply
                      </button>
                      <button
                        className="btn-reset"
                        onClick={() => {
                          setSearchParams({
                            bank_name: "",
                            profession_id: "",
                            status: "",
                          });
                          setCurrentPage(1);
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn-reset"
                        style={{ marginLeft: "150px" }}
                        onClick={handleAdd}
                      >
                        Add Entry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          {/* Table */}
          <Table striped bordered hover responsive>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Bank</th>
                <th>Profession</th>
                <th>Min Amount</th>
                <th>Max Amount</th>
                <th>FOIR</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.bank_name}</td>
                    <td>{item.profession_label || "-"}</td>
                    <td>{item.min_amount}</td>
                    <td>{item.max_amount}</td>
                    <td>{item.foir}</td>
                    <td>
                      {String(item.status) === "1" ? "Active" : "Inactive"}
                    </td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-2"
                        onClick={() => handleEdit(item)}
                      >
                        ✏️ Edit
                      </Button>
                      {String(item.status) === "1" && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDelete(item.id)}
                        >
                          🗑️ Delete
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No FOIR data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
  
          {/* Pagination */}
          {filteredData.length > 0 && (
            <Pagination
              perPage={itemsPerPage}
              pageCount={Math.ceil(filteredData.length / itemsPerPage)}
              activePage={currentPage}
              onPaginate={(page) => setCurrentPage(page)}
            />
          )}
        </>
      )}
  
      {/* MODAL for Add/Edit */}
      <Modal show={showModal} handleClose={() => setShowModal(false)} centered>
        <div className="modern-modal">
          <h4 className="modal-title">
            {selectedItem?.id ? "Edit FOIR" : "Add FOIR"}
          </h4>
  
          <Formsy onValidSubmit={handleSaveChanges}>
            {/* Bank ID (only if adding) */}
            {!selectedItem?.id && (
              <div className="input-group">
                <label>
                  Bank ID <span style={{ color: "red" }}>*</span>
                </label>
                <InputField
                  name="bank_id"
                  value={selectedItem?.bank_id || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      bank_id: e.target.value,
                    })
                  }
                />
              </div>
            )}
  
            {/* Profession */}
            <div className="input-group">
  <label>Profession</label>
  <FormsySelect
    name="profession_id"
    inputProps={{
      options: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ],
      placeholder: selectedItem?.profession_id ? undefined : "Select Profession",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ].filter((opt) => opt.value === selectedItem?.profession_id),
    }}
    onChange={(selected) =>
      setSelectedItem({
        ...selectedItem,
        profession_id: selected?.value || "",
      })
    }
  />
</div>

  
            {/* Min Amount */}
            <div className="input-group">
              <label>
                Min Amount{!selectedItem?.id && <span style={{ color: "red" }}>*</span>}
              </label>
              <InputField
                name="min_amount"
                value={selectedItem?.min_amount || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    min_amount: e.target.value,
                  })
                }
              />
            </div>
  
            {/* Max Amount */}
            <div className="input-group">
              <label>Max Amount</label>
              <InputField
                name="max_amount"
                value={selectedItem?.max_amount || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    max_amount: e.target.value,
                  })
                }
              />
            </div>
  
            {/* FOIR */}
            <div className="input-group">
              <label>FOIR{!selectedItem?.id && <span style={{ color: "red" }}>*</span>}</label>
              <InputField
                name="foir"
                value={selectedItem?.foir || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    foir: e.target.value,
                  })
                }
              />
            </div>
  
            {/* Status */}
            
  
            <div className="input-group">
  <label>Status</label>
  <FormsySelect
    name="status"
    inputProps={{
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ],
      placeholder: selectedItem?.status ? undefined : "Select Status",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ].filter((option) => option.value === selectedItem?.status),
    }}
    onChange={(selected) =>
{
      console.log("HHHHHHHHH",selected)
      setSelectedItem({
        ...selectedItem,
        status: selected?.value,
      })
    }
  }
  />
</div>



            {/* Modal Buttons */}
            <div className="button-group">
              <button
                className="btn-cancel"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="btn-update" type="submit">
                {selectedItem?.id ? "Update" : "Add"}
              </button>
            </div>
          </Formsy>
        </div>
      </Modal>
    </div>
  );
};

export default BreFoirDataTable;
