import React, { useEffect, useState } from "react";
import { MasterService } from "../../../../services";

const FosHistoryPopup = ({ id }) => {
  const [taskHistory, setTaskHistory] = useState([]);

  useEffect(() => {
    const fetchTaskHistory = async () => {
      try {
        const response = await MasterService.post(
          "/sfa/task_management/taskHistory",
          { task_id: id }
        );
        setTaskHistory(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching task history:", error);
      }
    };
    fetchTaskHistory();
  }, [id]);

  const ActivityCreatedBy = (item) => {
    const userMapping = {
      1: { name: item?.created_by_name, id: item?.created_by },
      2: { name: item?.transferred_by_name, id: item?.transferred_by },
      3: { name: item?.updated_by_name, id: item?.updated_by },
      4: { name: item?.name, id: item?.user_id },
    };
    return userMapping[item?.history_type] ? (
      <>
        <div>Name: {userMapping[item.history_type].name}</div>
        <div>Id: {userMapping[item.history_type].id}</div>
      </>
    ) : null;
  };

  const ActivityDetails = (item) => (
    <>
      {item?.history_type == 2 && (
        <>
          <div>Task Reassigned to: {item?.name}</div>
          <div>Task Reassigned to ID: {item?.user_id}</div>
        </>
      )}
      <div>Task Start Time: {new Date(item?.task_start_time).toLocaleString()}</div>
      <div>Task End Time: {new Date(item?.task_end_time).toLocaleString()}</div>
    </>
  );

  return (
    <div className="table-responsive">
      <table className="table table-bordered tablefontsize">
        <thead>
          <tr>
            <th>Activity Type</th>
            <th>Activity Date</th>
            <th>Activity Done By</th>
            <th>Activity Details</th>
            <th>Activity Remarks</th>
          </tr>
        </thead>
        <tbody>
          {taskHistory.length > 0 ? (
            taskHistory.map((item, index) => (
              <tr key={index}>
                <td>
                  {[
                    "Task Created",
                    "Task Reassigned",
                    "Task Rescheduled",
                    "Task Completed",
                  ][item?.history_type - 1] || "N/A"}
                </td>
                <td>{new Date(item?.created_date).toLocaleString() || "N/A"}</td>
                <td>{ActivityCreatedBy(item) || "N/A"}</td>
                <td>{ActivityDetails(item) || "N/A"}</td>
                <td>{
                  <>
                <div>{item?.comment || "N/A"}</div>
                <div>{item?.history_type == 4 && item?.meeting_reason}</div>
                </>
                }</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No history available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FosHistoryPopup;