import { gql } from "@apollo/client";

export const SEARCH_CALLBACK_DETAILS =(variables) => gql`
  mutation{
    get_call_back_details(filters:{
      lead_id:${variables.lead_id},
      page:${variables.page},
      limit:${variables.limit},
      call_status: "${variables.call_status}",
      lead_type: "${variables.lead_type}",
      mobile_no: "${variables.mobile_no}",
      assign_to: ${parseInt(variables.assigne_to)},
      start_time: "${variables.start_date}",
      end_time: "${variables.end_date}"
    }){
    data {
      id
      lead_id
      duration
      recording_url
      mobile_no
      call_status
      agent_mobile_no
      lead_type
      time
      call_bound
      lead {
        status_id
        assign_user {
            name
            id
        }
      }
    }
    total
    page
    limit
    }
  }
`;
