import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import Pagination from "../elements/pagination";
import { MasterService } from "../../services";
import InputField from "../elements/Input";
import Select from "react-select";
import Modal from "../elements/Modal";
import Formsy from "formsy-react";
import FormsySelect from "../elements/FormsySelect";

// Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Endpoints for Cash ROI (adjust as needed)
const CASH_ROI_API_URL    = "/process-rule/crud/getAllCashRoi";  // GET all
const ADD_CASH_ROI_URL    = "/process-rule/crud/addCashRoi";     // POST add
const UPDATE_CASH_ROI_URL = "/process-rule/crud/updateCashRoi";  // PUT updateCashRoi/:id
const DELETE_CASH_ROI_URL = "/process-rule/crud/deleteCashRoi";  // PUT deleteCashRoi/:id

// Status filter options
const statusOptions = [
  { value: "",  label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

// Profession filter + modal options
const professionOptions = [
  { value: "",  label: "All" },
  { value: "1", label: "Salaried" },
  { value: "2", label: "Self Employed Professional" },
  { value: "3", label: "Self Employed Non-Professional" },
];

const BreCashRoiDataTable = () => {
  // Cash ROI data from API
  const [cashRoiData, setCashRoiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Modal & form
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Filter states – note the change: we now use bank_name instead of bank_id!
  const [searchParams, setSearchParams] = useState({
    bank_name: "",
    profession_id: "",
    status: "",
  });

  // Loading indicator
  const [loading, setLoading] = useState(true);

  // ********** 1) Fetch on mount **********
  useEffect(() => {
    fetchCashRoiData();
  }, []);

  // ********** 2) Apply filters **********
  useEffect(() => {
    applyFilters();
  }, [searchParams, cashRoiData]);

  // **************************************
  //           FETCH CASH ROI DATA
  // **************************************
  const fetchCashRoiData = async () => {
    setLoading(true);
    try {
      const response = await MasterService.get(CASH_ROI_API_URL);
      // Some backends send { data: [...] }, others send [...]
      const dataList = response.data?.data || response.data || [];
      setCashRoiData(dataList);
    } catch (error) {
      console.error("Error fetching Cash ROI data:", error);
      toast.error("Failed to fetch Cash ROI data!");
    } finally {
      setLoading(false);
    }
  };

  // **************************************
  //             APPLY FILTERS
  // **************************************
  const applyFilters = () => {
    const { bank_name, profession_id, status } = searchParams;
    const result = cashRoiData.filter((item) => {
      // Filter by bank name using case-insensitive matching
      const bankMatch = bank_name
        ? item.bank_name.toLowerCase().includes(bank_name.toLowerCase())
        : true;

      // status filter
      const statusMatch = status
        ? String(item.status) === status
        : true;

      // profession filter
      const professionMatch = profession_id
        ? String(item.profession_id) === profession_id
        : true;

      return bankMatch && statusMatch && professionMatch;
    });
    setFilteredData(result);
    setCurrentPage(1);
  };

  // **************************************
  //           FILTER HANDLERS
  // **************************************
  const handleFilterChange = (field, value) => {
    setSearchParams((prev) => ({ ...prev, [field]: value }));
  };

  // **************************************
  //        DELETE => status=0
  // **************************************
  const handleDelete = async (id) => {
    if (!id) return;
    try {
      await MasterService.put(`${DELETE_CASH_ROI_URL}/${id}`);
      toast.success("Cash ROI entry set to Inactive!");
      fetchCashRoiData();
    } catch (err) {
      console.error("Delete Cash ROI error:", err);
      toast.error("Error deleting Cash ROI entry!");
    }
  };

  // **************************************
  //            EDIT CASH ROI
  // **************************************
  const handleEdit = (item) => {
    setSelectedItem({
      id:             item.id,
      bank_id:        String(item.bank_id || ""),
      profession_id:  String(item.profession_id || ""),
      min_amount:     String(item.min_amount || ""),
      max_amount:     String(item.max_amount || ""),
      roi:            String(item.roi || ""),
      status:         String(item.status ),
    });
    setShowModal(true);
  };

  // **************************************
  //            ADD CASH ROI
  // **************************************
  const handleAdd = () => {
    setSelectedItem({
      id:           null,
      bank_id:      "",
      profession_id:"",
      min_amount:   "",
      max_amount:   "",
      roi:          "",
      status:       "1",
    });
    setShowModal(true);
  };

  // **************************************
  //        SUBMIT (Add / Update)
  // **************************************
  const handleSaveChanges = async () => {
    if (!selectedItem) return;

    // Convert form data
    const idVal       = selectedItem.id;
    const bank_id     = Number(selectedItem.bank_id);
    const prof_id     = Number(selectedItem.profession_id);
    const minVal      = Number(selectedItem.min_amount);
    const maxVal      = Number(selectedItem.max_amount);
    const roiVal      = Number(selectedItem.roi);
    const status      = selectedItem.status?.toString() || "1";

    // Simple validations for ADD mode
    if (!idVal) {
      if (!bank_id || bank_id <= 0) {
        toast.error("Bank ID is required and must be > 0");
        return;
      }
      if (!minVal || minVal <= 0) {
        toast.error("Min Amount is required and must be > 0");
        return;
      }
      if (!roiVal || roiVal <= 0) {
        toast.error("ROI is required and must be > 0");
        return;
      }
    }

    // Build request payload
    const bodyData = {
      bank_id,
      profession_id: prof_id || 0,
      min_amount:    minVal || 0,
      max_amount:    maxVal || 0,
      roi:           roiVal || 0,
      status,
    };

    try {
      if (!idVal) {
        // ADD Mode => POST
        const resp = await MasterService.post(ADD_CASH_ROI_URL, bodyData, {
          headers: { "Content-Type": "application/json" },
        });
        if (resp.status > 399) {
          toast.error("Bank ID is not valid");
          return;
        }
        toast.success("Cash ROI added successfully!");
      } else {
        // UPDATE Mode => PUT
        await MasterService.put(`${UPDATE_CASH_ROI_URL}/${idVal}`, bodyData, {
          headers: { "Content-Type": "application/json" },
        });
        toast.success("Cash ROI updated successfully!");
      }

      setShowModal(false);
      await fetchCashRoiData(); // Refresh data
    } catch (error) {
      console.error("Save Cash ROI error:", error.response?.data || error.message);
      const errMsg = error?.response?.data?.message || "Error saving Cash ROI data!";
      toast.error(errMsg);
    }
  };

  // **************************************
  //       PAGINATION + Current Items
  // **************************************
  const indexOfLastItem  = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems     = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // **************************************
  //              RENDER
  // **************************************
  return (
    <div>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
          <p>Loading Cash ROI data...</p>
        </div>
      ) : (
        <>
          {/* Filters */}
          <div className="search-wrap mb-3">
            <div className="filter-container">
              <div className="filter-body">
                <div className="row">
                  {/* Bank Name Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Bank Name</div>
                      <div className="filter-input">
                        <InputField
                          name="bank_name"
                          placeholder="Filter by Bank Name"
                          value={searchParams.bank_name}
                          onChange={(e) => handleFilterChange("bank_name", e.target.value)}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Status Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Status</div>
                      <div className="material">
                        <Select
                          options={statusOptions}
                          placeholder="Select Status"
                          value={statusOptions.find(
                            (opt) => opt.value === searchParams.status
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            handleFilterChange("status", newVal);
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Profession Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Profession</div>
                      <div className="material">
                        <Select
                          options={professionOptions}
                          placeholder="Select Profession"
                          value={professionOptions.find(
                            (opt) => opt.value === searchParams.profession_id
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            handleFilterChange("profession_id", newVal);
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Filter Buttons */}
                  <div className="text-btn d-flex">
                    <div className="btn-submit-reset top-btn-none pull-right">
                      <button
                        className="btn-primary"
                        onClick={() => setCurrentPage(1)}
                      >
                        Apply
                      </button>
                      <button
                        className="btn-reset"
                        onClick={() => {
                          setSearchParams({
                            bank_name: "",
                            profession_id: "",
                            status: "",
                          });
                          setCurrentPage(1);
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn-reset"
                        style={{ marginLeft: "150px" }}
                        onClick={handleAdd}
                      >
                        Add Entry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table */}
          <Table striped bordered hover responsive>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Bank</th>
                <th>Profession</th>
                <th>Min Amount</th>
                <th>Max Amount</th>
                <th>ROI</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.bank_name}</td>
                    <td>{item.profession_label || "-"}</td>
                    <td>{item.min_amount}</td>
                    <td>{item.max_amount}</td>
                    <td>{item.roi}</td>
                    <td>
                      {String(item.status) === "1" ? "Active" : "Inactive"}
                    </td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-2"
                        onClick={() => handleEdit(item)}
                      >
                        ✏️ Edit
                      </Button>
                      {String(item.status) === "1" && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDelete(item.id)}
                        >
                          🗑️ Delete
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No Cash ROI data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination */}
          {filteredData.length > 0 && (
            <Pagination
              perPage={itemsPerPage}
              pageCount={Math.ceil(filteredData.length / itemsPerPage)}
              activePage={currentPage}
              onPaginate={(page) => setCurrentPage(page)}
            />
          )}
        </>
      )}

      {/* MODAL for Add/Edit */}
      <Modal show={showModal} handleClose={() => setShowModal(false)} centered>
        <div className="modern-modal">
          <h4 className="modal-title">
            {selectedItem?.id ? "Edit Cash ROI" : "Add Cash ROI"}
          </h4>

          <Formsy onValidSubmit={handleSaveChanges}>
            {/* Bank ID (only if adding) */}
            {!selectedItem?.id && (
              <div className="input-group">
                <label>
                  Bank ID <span style={{ color: "red" }}>*</span>
                </label>
                <InputField
                  name="bank_id"
                  value={selectedItem?.bank_id || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedItem({
                      ...selectedItem,
                      bank_id: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {/* Profession */}
            <div className="input-group">
  <label>Profession</label>
  <FormsySelect
    name="profession_id"
    inputProps={{
      options: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ],
      placeholder: selectedItem?.profession_id ? undefined : "Select Profession",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Salaried" },
        { value: "2", label: "Self Employed Professional" },
        { value: "3", label: "Self Employed Non-Professional" },
      ].filter((opt) => opt.value === selectedItem?.profession_id),
    }}
    onChange={(selected) =>
      setSelectedItem({
        ...selectedItem,
        profession_id: selected?.value || "",
      })
    }
  />
</div>


            {/* Min Amount */}
            <div className="input-group">
              <label>Min Amount {!selectedItem?.id && <span style={{ color: "red" }}>*</span>}</label>
              <InputField
                name="min_amount"
                value={selectedItem?.min_amount || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    min_amount: e.target.value,
                  })
                }
              />
            </div>

            {/* Max Amount */}
            <div className="input-group">
              <label>Max Amount</label>
              <InputField
                name="max_amount"
                value={selectedItem?.max_amount || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    max_amount: e.target.value,
                  })
                }
              />
            </div>

            {/* ROI */}
            <div className="input-group">
              <label>ROI {!selectedItem?.id && <span style={{ color: "red" }}>*</span>}</label>
              <InputField
                name="roi"
                value={selectedItem?.roi || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedItem({
                    ...selectedItem,
                    roi: e.target.value,
                  })
                }
              />
            </div>

            {/* Status */}
            <div className="input-group">
  <label>Status</label>
  <FormsySelect
    name="status"
    inputProps={{
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ],
      placeholder: selectedItem?.status ? undefined : "Select Status",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ].filter((opt) => opt.value === selectedItem?.status),
    }}
    onChange={(selected) =>
      setSelectedItem({
        ...selectedItem,
        status: selected?.value || "1",
      })
    }
  />
</div>


            

            {/* Modal Buttons */}
            <div className="button-group">
              <button
                className="btn-cancel"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="btn-update" type="submit">
                {selectedItem?.id ? "Update" : "Add"}
              </button>
            </div>
          </Formsy>
        </div>
      </Modal>
    </div>
  );
};

export default BreCashRoiDataTable;
