import { DOC_STATUS, IS_USER_ACCESS_PAGE, USERS_FOR_VIEW_AMBAK_LEADS, MAKER_ROLE_ID } from '../../config/constants';
import { PARTNER_DROPDOWN_LIST_FILTER } from "../../services/leadCreate.gql";
import { GET_SFA_USER_LIST_ROLE_ID_WISE } from '../../services/leads.gql';
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import morefilters from "../../webroot/images/morefiltersicon.png";
import { GET_USER_LIST } from "../../services/role.gql";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from 'react';
import MultiSelect from '../elements/MultiSelect';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import crypto from "../../config/crypto";
import { toast } from 'react-toastify';
import dateFormat from "dateformat";
import Select from 'react-select';

let Financier = [], Status = [], SubStatus = [], BankList = [], DateType = [];
let FollowupWith = [
  { value: "customer", label: "Customer" },
  { value: "financir", label: "Financir" },
  { value: "saathi",   label: "Saathi"   },
  { value: "ambak",    label: "Ambak"    },
  { value: "yoddha",   label: "Yoddha"   }
];

let CallingReason = [
  { value: 6,  label: "Interested"     },
  { value: 25, label: "Not Interested" },
  // { value: 7, label: "Call Back requested" },
  // { value: 8, label: "Visit requested" },
  // { value: 9, label: "Customer Disconnected" },
  // { value: 10, label: "Customer Busy" }
];

const PreLogin = [
  { value: 'kyc_doc',       label: 'KYC docs'       },
  { value: 'financial_doc', label: 'Financial docs' },
  { value: 'login_doc',     label: 'Login Docs'     }
];
//---------------------------------------------------------------------------
const accessCondition = IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_edit_access
  || IS_USER_ACCESS_PAGE("assign_user", "assign_user")?.is_access_page;
//---------------------------------------------------------------------------

const SEARCH_TYPE = [
  // { value: 'first_name', label: 'Customer Name' },
  { value: 'lead_id',          label: 'Lead ID'          },
  { value: 'full_name',        label: 'Full Name'        },
  { value: 'mobile',           label: 'Customer Phone'   }, 
  { value: 'parent_partner_id',label: 'Parent Partner ID'},
  { value: 'partner_id',       label: 'Partner ID'       },
  { value: 'rm_id',            label: 'BM ID'            },
  { value: 'login_number',     label: 'Login ID'         },
  { value: 'approval_number',  label: 'Sanctioned ID'    },
  { value: 'disbursed_id',     label: 'Disbursed ID'     }
]

const VERIFICATION_STATUS = [
  { id: 2, value: 'login_verified',              label: 'Login verified'                },
  { id: 2, value: 'login_not_verified',          label: 'Login verification pending'    },
  { id: 2, value: 'login_verification_failed',   label: 'Login verification failed'     },
  { id: 3, value: 'sanction_verified',           label: 'Sanction verified'             },
  { id: 3, value: 'sanction_not_verified',       label: 'Sanction verification pending' },
  { id: 3, value: 'sanction_verification_failed',label: 'Sanction verification failed'  },
  { id: 4, value: 'disbursal_verified',          label: 'Disbursal verified'            },
  { id: 4, value: 'disbursal_not_verified',      label: 'Disbursal verification pending'},
  { id: 4, value: 'disburse_verification_failed',label: 'Disbursal verification failed' }
];

const DISBURSAL_STATUS = [
  { value: "1", label: "Single Disbursed" },
  { value: "2", label: "Tranch Disbursed" }
]

const IS_DEMO_FILTER = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No"  }
]

const FOLLOWUP_MODE = [
  { value: 1, label: "manual" },
  { value: 2, label: "system" }
]

const IS_QUALIFIED = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No"  }
]

const DATE_TYPE_LIST = [
  { label: "New Lead",   value: "created_date"   },
  { label: "LogIn",      value: "login_date"     },
  { label: "Sanctioned", value: "approval_date"  },
  { label: "Disbursed",  value: "disbursed_date" },
]

const YODDHA_HIERARCHY = [
  { label: "Business Manager",            value: "rm_id" , role_id:3 },
  { label: "Consulting Business Manager", value: "cbm_id", role_id:6 },
  { label: "Area Business Manager",       value: "abm_id", role_id:7 },
  { label: "City Business Manager",       value: "sbm_id", role_id:4 },
  { label: "Regional Head",               value: "rh_id" , role_id:2 },
  { label: "Zonal Head",                  value: "zh_id" , role_id:8 },
  { label: "National Head",               value: "nh_id" , role_id:1 },
  { label: "Sales Head",                  value: "sh_id" , role_id:5 }
]

const PARTNER_HIERARCHY = [
  { label: "Parent Partner",value: "partner_id" },
  { label: "Sub Partner",   value: "parent_partner_id"        }
]

const ORDER_BY_LIST = [
  { id: "followup_date",    value: "followup_date_asc",     label: "Followup date ASC",     sort: "ASC"  },
  { id: "followup_date",    value: "followup_date_desc",    label: "Followup date DESC",    sort: "DESC" },
  { id: "id",               value: "id_asc",                label: "Lead id ASC",           sort: "ASC"  },
  { id: "id",               value: "id_desc",               label: "Lead id DESC",          sort: "DESC" },
  { id: "last_updated_date",value: "last_updated_date_asc", label: "Last updated date ASC", sort: "ASC"  },
  { id: "last_updated_date",value: "last_updated_date_desc",label: "Last updated date DESC",sort: "DESC" },
  // { id: "customer.first_name", value: "first_name_asc", label: "First name (a-z)", sort: "ASC" },
  // { id: "last_updated_date", value: "status_updated_date_asc", label: "Status update Date ASC", sort: "ASC" },
  // { id: "last_updated_date", value: "status_updated_date_desc", label: "Status update Date DESC", sort: "DESC" },
  // { id: "lead_details.loan_amount", value: "loan_amount_asc", label: "Loan amount ASC", sort: "ASC" },
  // { id: "lead_details.loan_amount", value: "loan_amount_desc", label: "Loan amount DESC", sort: "DESC" },
]

const SOURCE_TYPE = [
  { label: "Referrals",value: 8  },
  { label: "Online",   value: 9  },
  { label: "Saathi",   value: 10 },
  { label: "Yoddha",   value: 11 },
  { label: "Affiliate",value: 12 },
  { label: "Inbound",  value: 13 },
]

const LeadListFilter = ({ paramArray,financers, statuses, subStatusList, handleSearch, setFilterParam, fulfillments, productType, productSubType, propertyType, rmlist, leadPagination }) => {
  let { status: queryStatus, searchtext: search_text, searchtype: searchtype, fulfillment_type, loan_type, loan_sub_type, property_type,assign_to,followup_mode,followup_status,is_qualified } = useParams();
  const [verification_status, setVerificationStatus] = useState(VERIFICATION_STATUS);
  const [assignTooo, setAssignTooo] = useState({assign_to:0});
  const [subSourceList, setSubSourceList] = useState();
  const [partnerData, setPartnerData] = useState([]);
  followup_mode=parseInt(crypto.decode(followup_mode)?.toLowerCase());
  const [filterData, setFilterData] = useState({});
  const [filtersArr, setFiltersArr] = useState([]);
  const [userList, setUserList] = useState([]);
  const [sfaList, setSfaList] = useState([]);
  const client = useApolloClient();

  const { masterdata,lead } = useSelector(({ masterdata,lead }) => ({masterdata,lead}));
  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  
  fulfillments = masterdata?.data?.fulfillment_type || [];
  productType = masterdata?.data?.loan_type || [];
  productSubType = masterdata?.data?.loan_sub_type?.map(ob=>{
    return {...ob,value:ob.id}
  }) || [];
  propertyType = masterdata?.data?.property_type || [];
  DateType = masterdata?.data?.sub_status_dates;


  if (Financier.length == 0) {
    financers && financers.map((financer) => {
      Financier.push({ financier_id: financer?.financierlang?.financier_id, financier_name: financer?.financierlang?.financier_name })
    })
  }
  if (BankList.length == 0) {
    let banklistAray = masterdata?.data?.bank_list;
    
    banklistAray && banklistAray.map((ob) => {
      BankList.push({ bank_id: ob?.id, bank_name: ob?.banklang?.bank_name })
    })
  }
  if (Status.length == 0) {
    statuses && statuses.map((status) => {
      if(status?.statuslang) {
        Status.push({ "status_id": status?.statuslang?.status_id, "status_name": status?.statuslang?.status_name })
      } else if(status?.status_id) {
        Status.push({ "status_id": status?.status_id, "status_name": status?.status_name })
      }
    })
  }

  const handleOutsideClick = (event) => {
    const toggleCheckbox = document.getElementById('toggleCheckbox');
    if (toggleCheckbox.checked) {
      toggleCheckbox.checked = false;
    }
  };

  const removeFilter = (key, value, type) => {
    let filterDataObj = filterData;
    let filtersArrObj = filtersArr;
    if (['financier_id', 'status_id', 'sub_status_id'].includes(type)) {
      filterData[type] = filterData[type].filter(item => item != key);
      if (type == 'status_id') {
        let status_ids = [];
        let arr1 = [];
        SubStatus = [];
        status_ids = filterData[type].map(obj => {
          let arr2 = subStatusList ? subStatusList?.filter(obj2 => obj2.status_id == obj) : []; 
          arr1 = arr1.concat(arr2);
          return obj;
        });
        filterVerificationStatus(status_ids);
        let subst = [];
        arr1.map((substatus) => {
          let sub_still_filtered = filterData['sub_status_id'].filter(item => item == substatus.substatuslang.sub_status_id);
          if (sub_still_filtered.length > 0)
            subst.push(sub_still_filtered?.[0]);
          SubStatus.push({ "sub_status_id": substatus.substatuslang.sub_status_id, "sub_status_name": substatus.substatuslang.sub_status_name })
        })
        filterData['sub_status_id'] = subst;
      }

    }
    else if(["rm_id", "cbm_id", "abm_id", "sbm_id", "rh_id", "zh_id", "nh_id", "sh_id"].includes(type)){
      delete filterDataObj[type];
      delete filterDataObj["yoddha_hierarchy"]
      delete filterDataObj["yoddha_name"]
    }
    else {
      if (type === 'date_type') {
        delete filterDataObj["from_date"];
        delete filterDataObj["to_date"];
        setValues([
          "",
          ""
        ])
      }
      delete filterDataObj[type];
    }
    let indx = filtersArr.findIndex(itm => itm.key == key && itm.value == value);
    filtersArr.splice(indx, 1);
    setFilterData({ ...filterDataObj });
    setFiltersArr([...filtersArrObj]);
    searchLead()
  }


  const base64DecodeId = (encodedId) => {
    return JSON.parse(atob(encodedId));
  };

  const handleYoddhaHierarchyChange = async (role_id) =>{
    const response = await executeGraphQLQuery(GET_SFA_USER_LIST_ROLE_ID_WISE(role_id), client);
    if (response.data) {
      const listOfSfa = response.data?.sfa_list?.data?.user_list || [];
      const mappedList = listOfSfa.map((obj) => ({ ...obj, value: +obj.id, label: obj.name }));
      setSfaList(mappedList);
    }
  }

  const handleSubSourceList = (source_id) => {
    const sub_source = masterdata.data?.subsource_type?.filter(obj => obj.source_id === source_id).map(obj => ({ value: obj.id, label: obj.sub_source_name }));
    setSubSourceList(sub_source);
  }
  

  const handleChange = async (n, v, isMulti, opt, optl) => {
    setAssignTooo((prev)=>({...prev,[n]:v.value}));
    switch (n) {
      case 'followup_with':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'calling_reason_id':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'pre_login':
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'bank_id':
        let bank_ids = [];
        bank_ids = v.map(obj => { return obj.bank_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'financier_id':
        let financier_ids = [];
        financier_ids = v.map(obj => { return obj.financier_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'rm_id':
        let rm_ids = [];
        rm_ids = v.map(obj => { return obj.rm_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'status_id':
        // let status_ids = [];
        // let arr1 = [];
        // SubStatus = [];
        // filterVerificationStatus(v);
        // status_ids = v.map(obj => {
        //   let arr2 = (subStatusList) ? subStatusList.filter(obj2 => obj2.status_id == obj.status_id) : [];
        //   arr1 = arr1.concat(arr2);
        //   return obj.status_id;
        // });

        // arr1.map((substatus) => {
        //   SubStatus.push({ "sub_status_id": substatus.substatuslang.sub_status_id, "sub_status_name": substatus.substatuslang.sub_status_name })
        // })
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'sub_status_id':
        let sub_status_ids = [];
        sub_status_ids = v.map(obj => { return obj.sub_status_id });
        setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
        break;
      case 'date_type':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'partner_id':
        setFilterData((prev) => ({ ...prev, [n]: v.value, search_text: v.value }));
        break;
      case 'parent_partner_id':
        setFilterData((prev) => ({ ...prev, [n]: v.value, search_text: v.value }));
        break;
      case 'verification_status':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'doc_status':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'from_date':
        setFilterData((prev) => ({ ...prev, [n]: v, 'to_date': '' }));
        break;
      case 'to_date':
        setFilterData((prev) => ({ ...prev, [n]: v }));
        break;
      case 'fulfillment_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'loan_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'tranche_type':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'is_demo':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'property_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'search_text':
        setFilterData((prev) => ({ ...prev, [n]: v.target.value }));
        break;
      case 'search_type':
        if(v.value === "parent_partner_id"){
          setFilterData((prev) => ({ ...prev, [n]:v.value,  partner_hierarchy: v.value }));
          getPartnerData(v)
        }else if(v.value === "partner_id"){
          setFilterData((prev) => ({ ...prev, [n]:v.value,  partner_hierarchy: v.value }));
          getPartnerData(v)
        }else {
          setFilterData((prev) => ({ ...prev, [n]:v.value }));
        }
        break;
      case 'loan_sub_type':
        setFilterData((prev) => ({ ...prev, [n]: v.id }));
        break;
      case 'column':
        setFilterData((prev) => ({ ...prev, [n]: v.id, sort: v.sort }));
        break;
      case 'assign_to':
        setFilterData((prev) => ({ ...prev, [n]: [v.value] }));
        break;
      case 'followup_mode':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case 'is_qualified':
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case "yoddha_hierarchy":
        handleYoddhaHierarchyChange(v.role_id);
        setFilterData((prev) => ({ ...prev, [n]: v.value, yoddha_name: null }));
        break;
      case "yoddha_name":
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case "source":
        handleSubSourceList(v.value);
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case "sub_source":
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
        break;
      case "checklist_item":
        setFilterData((prev) => ({ ...prev, checklist_id: v.value }));
        break;
      case "status":
        setFilterData((prev) => ({ ...prev, checklist_sub_status_id: v.value }));
        break;
      case "date":
        setFilterData((prev) => ({...prev,checklist_complete_date: v.value, checklist_complete_date:null }));  
      default:
        setFilterData((prev) => ({ ...prev, [n]: v.value }));
    }
  }

  const getPartnerData = (data) => {
    const { value } = data;
    let partnerType = (value=="partner_id") ? "parent_partner" : (value=="parent_partner_id") ? "sub_partner" : "";
    setFilterData((prev) => ({ ...prev, partner_hierarchy: value, search_type: value }));
    executeGraphQLQuery(PARTNER_DROPDOWN_LIST_FILTER(partnerType), client)
      .then((result) => {
        if (result?.data) {
          const jsonData = JSON.parse(result?.data?.partner_list_dropdown);
          if (jsonData?.status === 200) {
            const partnerList = jsonData?.data?.map((obj) => {
              return {
                id: obj.partnerId,
                label: `${obj.PartnerName || "N/A"} - ${obj.partnerId}`,
                value: obj.partnerId,
              };
            });

            setPartnerData(partnerList);
          }
        }
      })
      .catch((errors) => {
        console.log(errors.message);
        toast.error("Something went wrong");
      });
  };

  // useEffect(() => {
  //   getPartnerData();
  // }, []);

  useEffect(() => {
    SubStatus = [];
    if (queryStatus == 'search_text' && search_text) {
      setFilterData({ search_text: search_text });
      setFilterData((prev) => ({ ...prev, ['search_type']: searchtype }));
    } else if (queryStatus) {
      let defulatStatus = crypto.decode(queryStatus);
      let urlparams = defulatStatus?.split('-') || [];
      let loan_status = (defulatStatus != 'followup' && urlparams.length === 1 )? defulatStatus : 0;
      const queryFilterStatusIds = typeof loan_status === 'string' ? loan_status.split(",") : loan_status && Array.isArray(loan_status) && loan_status.length ? loan_status : [];
      if (queryFilterStatusIds.length) { 
        setFilterData((prev) => ({ ...prev, status_id: queryFilterStatusIds.map(Number) }));
        filterVerificationStatus([loan_status].map(Number), true);
      } else if (defulatStatus == 'followup') setFilterData((prev) => ({ ...prev, followup_type: 'all' }));
      else if (urlparams.length === 2) setFilterData((prev) => ({ ...prev, [urlparams[0]]: urlparams[1] }));
      if (fulfillment_type) {
        setFilterData((prev) => ({ ...prev, fulfillment_type: fulfillment_type }));
      }
      if (assign_to) {
        setFilterData((prev) => ({ ...prev, assign_to: base64DecodeId(assign_to) }));
      }
      if (loan_type) {
        setFilterData((prev) => ({ ...prev, loan_type: loan_type }))
      }
      if (loan_sub_type) {
        setFilterData((prev) => ({ ...prev, loan_sub_type: loan_sub_type }))
      }
    }
    if (queryStatus == undefined) {
      setFilterData((prev) => ({ ...prev, is_demo: "0" }));
      searchLead({});
    }
     else if (statuses?.length && queryStatus != undefined) {
      setFilterData((prev) => ({ ...prev, is_demo: "0" }));
      searchLead({});
    }
    if (followup_mode) {
      setFilterData((prev) => ({ ...prev, followup_mode: followup_mode }));
    } 
    if (is_qualified) {
      setFilterData((prev) => ({ ...prev, is_qualified: is_qualified }));
    } 
    if (paramArray.includes("FOLLOWUP")) {
      setFilterData((prev) => ({ ...prev, followup_type: "all" }));
    }
  }, [queryStatus, statuses]);

  const filterVerificationStatus = (selectedStatus, calltype = false) => {
    let filterVerificationStatus = VERIFICATION_STATUS;
    if (selectedStatus.length) {
      selectedStatus = !calltype ? selectedStatus.map(data => data.status_id) : selectedStatus;
      filterVerificationStatus = VERIFICATION_STATUS.filter(data => selectedStatus.includes(data.id));
    }
    setVerificationStatus(filterVerificationStatus);
  }

  const searchLead = (e) => {
    if (e === "top filter") {
      filterData.filterTopChange = "yes";
    }
    let filterDetails = [];
    
    if (filterData && filterData.search_text) {
      filterDetails.push({ type: 'search_text', value: 'Search-' + filterData.search_text, key: 0 })
    }

    if (filterData && filterData.financier_id && filterData.financier_id.length) {
      filterData.financier_id.map((element) => {
        let finance = financers && financers.filter((obj) => obj.financierlang.financier_id == element);
        if (finance && finance[0] && finance[0].financierlang && finance[0].financierlang && finance[0].financierlang.financier_name) {
          filterDetails.push({ type: 'financier_id', value: 'Financier-' + finance[0].financierlang.financier_name, key: finance[0].financierlang.financier_id })
        }
      })
    }

    if (filterData && filterData.rm_id && filterData.rm_id.length) {
      filterData.rm_id.map((element) => {
        let rmdetail = rmlist && rmlist.filter((obj) => obj.id == element);
        if (rmdetail && rmdetail[0] && rmdetail[0].id && rmdetail[0].label) { 
          filterDetails.push({ type: 'rm_id', value: 'rmlist-' + rmdetail[0].label, key: rmdetail[0].id })
        }
      })           
    }

    if (filterData && filterData.status_id && filterData.status_id.length) {
      filterData?.status_id.map((element) => {
        let status = statuses.filter((obj) => { return obj.statuslang ? obj.statuslang?.status_id == element : obj?.status_id == element});
        if (status && Array.isArray(status) && status.length && status[0].status_id) {
          filterDetails.push({ type: 'status_id', value: 'Status-' + status[0].status_name, key: status[0].status_id })
        }
      })
    }

    if (filterData && filterData.sub_status_id && filterData.sub_status_id.length) {
      filterData.sub_status_id.map((element) => {
        let sub_status = subStatusList && subStatusList.filter((obj) => obj.substatuslang.sub_status_id == element);
        if (sub_status && sub_status[0] && sub_status[0].substatuslang && sub_status[0].substatuslang && sub_status[0].substatuslang.sub_status_name) {
          filterDetails.push({ type: 'sub_status_id', value: 'Sub Status-' + sub_status[0].substatuslang.sub_status_name, key: sub_status[0].substatuslang.sub_status_id })
        }
      })
    }

    if (filterData && filterData.date_type) { 
      if (!filterData.from_date) {
        toast.error("Please enter valid start date");
        return;
      }
      if (!filterData.to_date) {
        toast.error("Please enter valid to date");
        return;
      }
      filterDetails.push({ type: 'date_type', value: 'DateType-' + filterData.date_type, key: 0 })
    }
    if (filterData && filterData.to_date) {
      filterDetails.push({ type: 'date_type', value: 'To Date-' + dateFormat(filterData.to_date, 'dd mmm yyyy'), key: 0 })
    }

    if (filterData && filterData.fulfillment_type) {
      filterDetails.push({ type: 'fulfillment_type', value: 'Fulfillment Type-' + filterData.fulfillment_type, key: 0 })
    }

    if (filterData && filterData?.loan_type) {
      filterDetails.push({ type: 'loan_type', value: 'Product Type-' + filterData.loan_type, key: filterData.loan_type })
    }

    if (filterData && filterData?.loan_sub_type) {
      filterDetails.push({ type: 'loan_sub_type', value: 'Product Sub Type-' + filterData?.loan_sub_type, key: filterData?.loan_sub_type })
    }

    if (filterData && filterData.search_type) {
      filterDetails.push({ type: 'search_type', value: 'Search Type-' + filterData.search_type, key: 0 })
    }

    if (filterData && filterData.verification_status) {                                                                                   
      let statusLabel = VERIFICATION_STATUS.filter(ob=>ob.value ==filterData.verification_status)?.[0]?.label || ''; 
      filterDetails.push({ type: 'verification_status', value: 'Verificattion Status-' + statusLabel, key: filterData.verification_status })
    }

    if (filterData && filterData.tranche_type) {
      filterDetails.push({ type: 'tranche_type', value: 'Disbursal Type-' + filterData.tranche_type, key: filterData.tranche_type })
    } 
    if (filterData && filterData.hasOwnProperty('is_demo')) {
      filterDetails.push({ type: 'is_demo', value: 'Is Demo-' + IS_DEMO_FILTER?.filter(data=> data?.value === filterData.is_demo)?.map(v=>v?.label), key: filterData.is_demo })
    }
    else{
      filterDetails.push({ type: 'is_demo', value: 'Is Demo-No' , key: '0' })
    }

    if (filterData && filterData?.property_type) {
      filterDetails.push({ type: 'property_type', value: 'Property Type-' + filterData.property_type, key: filterData.property_type })
    }

    if (filterData && filterData.doc_status) {
      filterDetails.push({ type: 'doc_status', value: 'Doc Status-' + filterData.doc_status, key: 0 })
    }
    if (filterData && filterData.partner_id) {
      filterDetails.push({ type: 'partner_id', value: 'Partner id-' + filterData.partner_id, key: 0 })
    }
    if (filterData && filterData.parent_partner_id) {
      filterDetails.push({ type: 'parent_partner_id', value: 'Parent Partner id-' + filterData.parent_partner_id, key: 0 })
    }
    if (filterData && filterData.assign_to) {
      const matchedUsers = userList.filter((user) =>
        filterData.assign_to.includes(user.value)
      );
      const labels = matchedUsers.map((user) => user.label);
      filterDetails.push({ type: 'assign_to', value: 'Assign E2E Sales-' + labels, key: 0 })
    }
    if (filterData && filterData.followup_mode) {
      filterDetails.push({ type: 'followup_mode', value: 'Followup mode-' + FOLLOWUP_MODE?.filter(data=> data?.value === filterData?.followup_mode)?.map(v=>v?.label), key: filterData?.followup_mode })
    }
    
    if (filterData && filterData.is_qualified) {
      filterDetails.push({ type: 'is_qualified', value: 'Qualified-' + IS_QUALIFIED?.filter(data=> data?.value === filterData?.is_qualified)?.map(v=>v?.label), key: filterData?.is_qualified })
    }

    if (filterData["yoddha_hierarchy"] && filterData["yoddha_name"]) {
      filterDetails.push({ type: filterData.yoddha_hierarchy, value: `${filterData.yoddha_hierarchy}-` + filterData.yoddha_name, key: 0 });
    }

    if (filterData.followup_type) {
      filterDetails.push({ type: 'followup_type', value: 'Followup Type-' + filterData.followup_type, key: 0 })
    }

    setFiltersArr(filterDetails)
    handleSearch(filterData)
    // const toggleCheckbox = document.getElementById('toggleCheckbox');
    // toggleCheckbox.checked = false;
  }

  document.addEventListener("click", function(event) {
    const isTargetSelectOption = event.target.role == "option"
    const moreFilterDiv = document.querySelector(".more-filter");
    if (!moreFilterDiv?.contains(event.target) && !isTargetSelectOption) {
      const toggleCheckbox = document.getElementById("toggleCheckbox");
      if(toggleCheckbox) toggleCheckbox.checked = false;
    }
  });
  
  const resetSearchLead = (e) => {
    document.getElementById("more-filter-dropdown").classList.remove('show');
    setFilterData({});
    setFiltersArr([]);
    setFilterParam();
    setValues(["", ""]);
    handleSearch({});
    filterVerificationStatus([])
    setSubSourceList([])
    setPartnerData([])
    setSfaList([])
    setAssignTooo({assign_to: 0})
  }

  const [values, setValues] = useState(["", ""]);

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setFilterData((prev) => ({
      ...prev,
      from_date: start,
      to_date: end
    }));
  }

  const handleKeyDown = (e)=>{ 
    if (e.key === 'Enter') {
      searchLead();
    }
  }
  let selfAccess = IS_USER_ACCESS_PAGE("self_fullfill_bucket", "self_fullfill_bucket")?.is_edit_access;
  let ambakAccess = IS_USER_ACCESS_PAGE("ambak_fullfill_backet", "ambak_fullfill_backet")?.is_edit_access; 

  let fulfillments_option =  [] 
  for(let item of fulfillments){
    if((item.id =='self' && selfAccess === 1) || (item.id =='ambak' && ambakAccess === 1)){
      fulfillments_option.push({
        ...item,
        value: item.id
      })
    }
  } 
  let newFiltersArr = (selfAccess === 1)? filtersArr: filtersArr.filter(ob=>ob.type!="fulfillment_type"); 

  // -----------------------------------------------------------------

  const getUserList = async () => {
    let user_list = [],userlist=[];
    await executeGraphQLQuery(GET_USER_LIST(5,1),client).then((response) => {
      if (response && response.data && response.data.get_user_list) {
        user_list = response.data.get_user_list?.filter(obj => obj.status === '1');
        user_list && user_list?.map((v)=>{
          userlist.push({value:v.user_id,label:v.name,role_id:v.role_assigned_ids})
          return v;
        })
        userlist.push({value:-1,label:"Unassigned Leads",role_id:"5"})
        setUserList(userlist);
      }
     }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserList();
    };
    fetchData();
  }, []);

  let maker_user_list = userList.filter(data => data.role_id.includes(MAKER_ROLE_ID) && data.value != UserInfo?.id); 


  return (
    <React.Fragment>
      <div className="top-heading" >
        <div className="filterflex">
          <h1 style={{ marginLeft: "7px" }}>Finance Cases</h1>
          <div className="filterinput" 
            // onClick={(e)=>handleOutsideClick()}
            >
            <div>
              <fieldset className="single-select">
                <div className="material">
                  <Select
                    options={SEARCH_TYPE}
                    placeholder={"Search Type"}
                    name={"search_type"}
                    optionLabel="label"
                    optionValue="value"
                    value={
                      filterData && filterData["search_type"]
                        ? SEARCH_TYPE.filter(
                            ({ value }) => value === filterData["search_type"]
                          )
                        : { value: "lead_id", label: "Lead Id" }
                    }
                    onChange={(e) => handleChange("search_type", e)}
                  />
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset className="search-bx">
                <div className="material">
                  <input
                    id=""
                    type="text"
                    name="search_text"
                    value={filterData.search_text || ""}
                    onChange={(e) => handleChange("search_text", e)}
                    className="form-input-new"
                    title={"SEARCH_TEXT"}
                    placeholder="Search" 
                    onKeyDown={handleKeyDown} 
                  />
                  <i className="ic-search"></i>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div className="loan-filter-bg">
        <div className="top-filter-bg">
          <div className="form-tab-right-panel">
            <div className="row">
              <div className="col-sm-2">
                <fieldset className="multiselect-dropDown">
                  <div className="filter-title">Status</div>
                  <div className="material">
                    <MultiSelect
                      options={Status}
                      placeholder={"Status"}
                      name={"status_id"}
                      optionLabel="status_name"
                      optionValue="status_id"
                      values={filterData.status_id}
                      onChange={handleChange}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
                <fieldset className="single-select">
                <div className="filter-title">Verification Status</div>
                  <div className="material">
                    <Select
                      options={verification_status}
                      placeholder={"Verification Status"}
                      name={"verification_status"}
                      optionLabel="label"
                      optionValue="value"
                      value={
                        filterData && filterData["verification_status"]
                          ? VERIFICATION_STATUS.filter(
                              ({ value }) =>
                                value === filterData["verification_status"]
                            )
                          : ""
                      }
                      onChange={(e) => handleChange("verification_status", e)}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
                <fieldset className="multiselect-dropDown">
                <div className="filter-title">Bank</div>
                  <div className="material">
                    <MultiSelect
                      options={BankList}
                      placeholder={"Bank"}
                      name={"bank_id"}
                      optionLabel="bank_name"
                      optionValue="bank_id"
                      values={filterData.bank_id}
                      onChange={handleChange}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
                <fieldset className="single-select">
                <div className="filter-title">Disbursed Type</div>
                  <div className="material">
                    <Select
                      options={DISBURSAL_STATUS}
                      placeholder={"Disbursed Type"}
                      name={"tranche_type"}
                      optionLabel="label"
                      optionValue="value"
                      value={
                        filterData && filterData["tranche_type"]
                          ? DISBURSAL_STATUS.filter(
                              ({ value }) =>
                                value === filterData["tranche_type"]
                            )
                          : ""
                      }
                      onChange={(e) => handleChange("tranche_type", e)}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
                <div className="more-filter">
                  <label className="morefilterbtn" htmlFor="toggleCheckbox">
                    More filter
                    <img src={morefilters} style={{marginLeft:5}} alt='icon' width={20} height={20} />
                  </label>
                  <input type="checkbox" id="toggleCheckbox" />
                  <div id="filterCard">
                    <div className="morefilterheading">More Filter</div>
                    <div className="middlecontent">
                      <div className='row'>
                        <div className="col-sm-12">
                          <div className="morefilterbg">
                            <div className="moresubheading">Partner Filter</div>
                            <div className="morefilterflex">
                              <fieldset className="single-select">
                                <div className="filter-title">Partner Hierarchy</div>
                                <div className="material">
                                  <Select
                                    options={PARTNER_HIERARCHY}
                                    placeholder={"Partner Hierarchy"}
                                    name={"partner_hierarchy"}
                                    optionLabel="label"
                                    optionValue="value"
                                    menuPlacement="auto"
                                    value={filterData.partner_hierarchy ? PARTNER_HIERARCHY.filter(({ value }) => value === filterData.partner_hierarchy) : ""}
                                    onChange={(data) => getPartnerData(data)}
                                  />
                                </div>
                              </fieldset>
                              {filterData?.["search_type"] ==="parent_partner_id" ?
                              <fieldset className="single-select">
                                <div className="filter-title">Partner Name</div>
                                <div className="material">
                                  <Select
                                    options={partnerData}
                                    placeholder={"Partner Name"}
                                    name={"parent_partner_id"}
                                    optionLabel="label"
                                    menuPlacement="auto"
                                    optionValue="value"
                                    value={
                                      filterData && filterData["parent_partner_id"]
                                        ? partnerData.filter(
                                            ({ value }) =>
                                              value === filterData["parent_partner_id"]
                                          )
                                        : ""
                                    }
                                    onChange={(e) => handleChange("parent_partner_id", e)}
                                  />
                                </div>
                              </fieldset> : 
                               <fieldset className="single-select">
                               <div className="filter-title">Partner Name</div>
                               <div className="material">
                                 <Select
                                   options={partnerData}
                                   placeholder={"Partner Name"}
                                   name={"partner_id"}
                                   optionLabel="label"
                                   menuPlacement="auto"
                                   optionValue="value"
                                   value={
                                     filterData && filterData["partner_id"]
                                       ? partnerData.filter(
                                           ({ value }) =>
                                             value === filterData["partner_id"]
                                         )
                                       : ""
                                   }
                                   onChange={(e) => handleChange("partner_id", e)}
                                 />
                               </div>
                             </fieldset> 
                              }
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-sm-12">
                          <div className="morefilterbg">
                            <div className="moresubheading">Yoddha Filter</div>
                            <div className="morefilterflex">
                              <fieldset className="single-select">
                                <div className="filter-title">Yoddha Hierarchy</div>
                                <div className="material">
                                  <Select
                                    options={YODDHA_HIERARCHY}
                                    placeholder={"Yoddha Hierarchy"}
                                    name={"yoddha_hierarchy"}
                                    optionLabel="label"
                                    optionValue="value"
                                    menuPlacement="auto"
                                    value={filterData.yoddha_hierarchy ? YODDHA_HIERARCHY.filter(({ value }) => value === filterData.yoddha_hierarchy) : ""}
                                    onChange={(e) => handleChange("yoddha_hierarchy", e)}
                                  />
                                </div>
                              </fieldset>

                              <fieldset className="single-select">
                                <div className="filter-title">Yoddha Name</div>
                                <div className="material">
                                  <Select
                                    options={sfaList}
                                    placeholder={"Yoddha Name"}
                                    name={"yoddha_name"}
                                    optionLabel="label"
                                    optionValue="value"
                                    menuPlacement="auto"
                                    value={filterData["yoddha_name"] ? sfaList.filter(({ value }) => value === filterData["yoddha_name"]) : ""}
                                    onChange={(e) => handleChange("yoddha_name", e)}
                                  />
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <fieldset className="single-select">
                            <div className="filter-title">Is Demo</div>
                            <div className="material">
                              <Select
                                options={IS_DEMO_FILTER}
                                placeholder={"IS Demo"}
                                name={"is_demo"}
                                optionLabel="label"
                                optionValue="value"
                                menuPlacement="auto"
                                value={
                                  filterData && filterData["is_demo"]
                                    ? IS_DEMO_FILTER.filter(
                                        ({ value }) =>
                                          value === filterData["is_demo"]
                                      )
                                    : ""
                                }
                                onChange={(e) => handleChange("is_demo", e)}
                              />
                            </div>
                          </fieldset>
                        </div>
                        
                        {
                          selfAccess === 1 ?
                          <div className="col-sm-6">
                            <fieldset className="single-select">
                              <div className="filter-title">Product Type</div>
                              <div className="material">
                                <Select
                                  options={productType}
                                  placeholder={"Product Type"}
                                  name={"loan_type"}
                                  optionLabel="label"
                                  optionValue="id"
                                  menuPlacement="auto"
                                  value={
                                    filterData && filterData["loan_type"]
                                      ? productType.find(({ id }) => id === filterData["loan_type"])
                                      : null
                                  }
                                  onChange={(e) => handleChange("loan_type", e)}
                                  styles={{
                                    option: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                                      color: 'black',
                                    }),
                                  }}
                                />
                              </div>
                            </fieldset>
                          </div> : null
                        }
                        
                        <div className="col-sm-6">
                          <fieldset className="single-select">
                            <div className="filter-title">Product Sub Type</div>
                            <div className="material">
                              <Select
                                options={productSubType}
                                placeholder={"Product Sub Type"}
                                name={"loan_sub_type"}
                                optionLabel="label"
                                optionValue="id"
                                menuPlacement="auto"
                                value={
                                  filterData && filterData["loan_sub_type"]
                                    ? productSubType.filter(
                                        ({ id }) =>
                                          id === filterData["loan_sub_type"]
                                      )
                                    : ""
                                }
                                onChange={(e) => handleChange("loan_sub_type", e)}
                                styles={{
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
                                    color: 'black',
                                  }),
                                }}
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div className="col-sm-12">
                          <div className="morefilterbg">
                            <div className="moresubheading">Date Filter</div>
                            <div className="morefilterflex">
                              <fieldset className="single-select">
                                <div className="filter-title">Date Type</div>
                                <div className="material">
                                  <Select
                                    options={DATE_TYPE_LIST}
                                    placeholder={"Select Date Type"}
                                    name={"date_type"}
                                    menuPlacement="auto"
                                    value={
                                      filterData && filterData["date_type"]
                                        ? DATE_TYPE_LIST.filter(
                                            ({ value }) =>
                                              value === filterData["date_type"]
                                          )
                                        : ""
                                    }
                                    onChange={(e) => handleChange("date_type", e)}
                                  />
                                </div>
                              </fieldset>
                              <fieldset className="single-select">
                                <div className="filter-title">From Date</div>
                                <div className="datepicker" style={{background:"#fff"}}>
                                  <DatePicker
                                    selected={filterData?.from_date}
                                    onChange={(date) => onChangeDate(date)}
                                    startDate={filterData?.from_date}
                                    endDate={filterData?.to_date}
                                    selectsRange
                                    placeholderText={"Select Date"}
                                    dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                    menuPlacement="auto"
                                    peekNextMonth
                                    minDate={null}
                                    maxDate={new Date()}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    isClearable={true}
                                  />
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <fieldset className="single-select">
                            <div className="filter-title">Property Type</div>
                            <div className="material">
                              <Select
                                options={propertyType}
                                placeholder={"Property Type"}
                                name={"property_type"}
                                optionLabel="label"
                                optionValue="id"
                                menuPlacement="auto"
                                value={
                                  filterData && filterData["property_type"]
                                    ? propertyType.filter(
                                        ({ id }) =>
                                          id === filterData["property_type"]
                                      )
                                    : ""
                                }
                                onChange={(e) => handleChange("property_type", e)}
                              />
                            </div>
                          </fieldset>
                        </div>
                      {/* <div className="col-sm-6">
                      <div className="filter-title">Select Date Type</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={DATE_TYPE_LIST}
                              placeholder={"Select Date Type"}
                              name={"date_type"}
                              value={
                                filterData && filterData["date_type"]
                                  ? DATE_TYPE_LIST.filter(
                                      ({ value }) =>
                                        value === filterData["date_type"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("date_type", e)}
                              menuShouldScrollIntoView={false}
                              menuPlacement="auto"
                            />
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-sm-6">
                      <div className="filter-title">Select Date Range</div>
                        <fieldset className="single-select">
                          <div className="datepicker">
                            <DatePicker
                              selected={filterData?.from_date}
                              onChange={(date) => onChangeDate(date)}
                              startDate={filterData?.from_date}
                              endDate={filterData?.to_date}
                              selectsRange
                              placeholderText={"Select Date Range"}
                              dropdownMode="select"
                              showMonthDropdown
                              showYearDropdown
                              peekNextMonth
                              minDate={null}
                              maxDate={new Date()}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              isClearable={true}
                            />
                          </div>
                        </fieldset>
                      </div> */}
                      
                      <div className="col-sm-6">
                      
                      <div className="filter-title">Select E2E Sales</div>
                        <fieldset className="single-select">
                          <div className="material" title={userList.filter((v) => (v.value === lead.assign_to))?.[0]?.label}>            
                            <Select
                              options={accessCondition ===1 ? maker_user_list : []}
                              placeholder={"Select E2E Sales"}
                              name={'assign_to'}
                              optionLabel="label"
                              optionValue="value"
                              menuPlacement="auto"
                              className='react-select-table'
                              classNamePrefix='react-select-table'
                              value={userList.filter((v) => (v.value === assignTooo.assign_to)) }
                              onChange={(e) => handleChange("assign_to", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>
                       
                      <div className="col-sm-6">
                      <div className="filter-title">Followup Mode</div>
                        <fieldset className="single-select">
                          <div className="material">
                            <Select
                              options={FOLLOWUP_MODE}
                              placeholder={"Followup Mode"}
                              name={"followup_mode"}
                              optionLabel="label"
                              menuPlacement="auto"
                              optionValue="value"
                              value={
                                filterData && filterData["followup_mode"]
                                  ? FOLLOWUP_MODE.filter(
                                      ({ value }) =>
                                        value === filterData["followup_mode"]
                                    )
                                  : ""
                              }
                              onChange={(e) => handleChange("followup_mode", e)}
                              menuShouldScrollIntoView={false}
                            />
                          </div>
                        </fieldset>
                      </div>

                        <div className="col-sm-12">
                          <fieldset className="multiselect-dropDown">
                            <div className="filter-title">Pendancy On</div>
                            <div className="material">
                              <MultiSelect
                                options={FollowupWith}
                                placeholder={"Pendancy On"}
                                name="followup_with"
                                optionLabel="label"
                                menuPlacement="auto"
                                optionValue="value"
                                values={filterData?.followup_with}
                                onChange={handleChange}
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div className="col-sm-12">
                          {!USERS_FOR_VIEW_AMBAK_LEADS.includes(UserInfo?.email) ? (
                            <fieldset className="single-select">
                              <div className="filter-title">Fulfillment Type</div>
                              <div className="material">
                                <Select
                                  options={fulfillments_option}
                                  placeholder={"Fulfillment"}
                                  name={"fulfillment_type"}
                                  optionLabel="label"
                                  menuPlacement="auto"
                                  optionValue="id"
                                  value={
                                    filterData && filterData["fulfillment_type"]
                                      ? fulfillments_option.filter(
                                          ({ id }) =>
                                            id === filterData["fulfillment_type"]
                                        )
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleChange("fulfillment_type", e)
                                  }
                                />
                              </div>
                            </fieldset>
                          ) : ("")}
                        </div>

                        <div className="col-sm-12">
                          <fieldset className="single-select">
                            <div className="filter-title">Doc Status</div>
                            <div className="material">
                              <Select
                                options={DOC_STATUS}
                                placeholder={"Doc Status"}
                                name={"doc_status"}
                                optionLabel="label"
                                menuPlacement="auto"
                                optionValue="value"
                                value={
                                  filterData && filterData["doc_status"]
                                    ? DOC_STATUS.filter(
                                        ({ value }) =>
                                          value === filterData["doc_status"]
                                      )
                                    : ""
                                }
                                onChange={(e) => handleChange("doc_status", e)}
                              />
                            </div>
                          </fieldset>
                        </div>
                        
                        {/* <div className="col-sm-12">
                          <fieldset className="single-select">
                            <div className="filter-title">E2E Sales</div>
                            <div className="material" title={userList.filter((v) => (v.value === lead.assign_to))?.[0]?.label}>            
                              <Select
                                options={accessCondition ===1 ? maker_user_list : []}
                                placeholder={"Select E2E Sales"}
                                name={'assign_to'}
                                optionLabel="label"
                                optionValue="value"
                                menuPlacement="auto"
                                className='react-select-table'
                                classNamePrefix='react-select-table'
                                value={userList.filter((v) => (v.value === assignTooo.assign_to)) }
                                onChange={(e) => handleChange("assign_to", e)}
                              />
                            </div>
                          </fieldset>
                        </div> */}

                        <div className="col-sm-6">
                          <fieldset className="single-select">
                            <div className="filter-title">Qualified</div>
                            <div className="material">
                              <Select
                                options={IS_QUALIFIED}
                                placeholder={"Qualified"}
                                name={"is_qualified"}
                                optionLabel="label"
                                optionValue="value"
                                menuPlacement="auto"
                                value={
                                  filterData && filterData["is_qualified"]
                                    ? IS_QUALIFIED.filter(
                                        ({ value }) =>
                                          value === filterData["is_qualified"]
                                      )
                                    : ""
                                }
                                onChange={(e) => handleChange("is_qualified", e)}
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div className="col-sm-6">
                          <fieldset className="multiselect-dropDown">
                            <div className="filter-title">Interested</div>
                            <div className="material">
                              <MultiSelect
                                options={CallingReason}
                                placeholder={"Is Interested"}
                                name="calling_reason_id"
                                optionLabel="label"
                                optionValue="value"
                                menuPlacement="auto"
                                values={filterData?.calling_reason_id}
                                onChange={handleChange}
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div className="col-sm-6">
                          <fieldset className="single-select">
                            <div className="filter-title">Source</div>
                            <div className="material">
                              <Select
                                options={SOURCE_TYPE}
                                placeholder={"Source"}
                                name={"source"}
                                optionLabel="label"
                                optionValue="value"
                                menuPlacement="top"
                                value={
                                  filterData && filterData["source"]
                                    ? SOURCE_TYPE.filter(
                                        ({ value }) =>
                                          value === filterData["source"]
                                      )
                                    : ""
                                }
                                onChange={(e) => handleChange("source", e)}
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div className="col-sm-6" >
                          <fieldset className="single-select">
                            <div className="filter-title">Sub Source</div>
                            <div className="material">
                              <Select
                                options={subSourceList}
                                placeholder={"Sub Source"}
                                name={"Sub Source"}
                                optionLabel="label"
                                optionValue="value"
                                menuPlacement="top"
                                value={
                                  filterData && filterData["sub_source"]
                                    ? subSourceList.filter(
                                        ({ value }) =>
                                          value === filterData["sub_source"]
                                      )
                                    : ""
                                }
                                onChange={(e) => handleChange("sub_source", e)}
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div className="applybtnfixed">
                          <div className="col-sm-2">
                            <div className="more-filter" onClick={(e)=>handleOutsideClick()}>
                              <div className="filter-option">
                                <Dropdown id="more-filter-dropdown">
                                  <div className="text-btn d-flex">
                                    <div className="btn-submit-reset top-btn-none pull-right">
                                      <button onClick={searchLead} className="btn-primary">Apply</button>
                                      <button onClick={resetSearchLead} className="btn-reset">Reset</button>
                                    </div>
                                  </div>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="more-filter">
                  <div className="filter-option">
                    <Dropdown id="more-filter-dropdown">
                      <div className="text-btn d-flex">
                        <div className="btn-submit-reset top-btn-none pull-right">
                          <button onClick={()=>searchLead("top filter")} className="btn-primary">
                            Apply
                          </button>
                          <button
                            onClick={resetSearchLead}
                            className="btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
                <fieldset className="single-select">
                <div className="filter-title">Sort by</div>
                  <div className="material">
                    <Select
                      options={ORDER_BY_LIST}
                      placeholder={"Sort by"}
                      name={"column"}
                      optionLabel="label"
                      optionValue="value"
                      value={
                        filterData && filterData["column"]
                          ? ORDER_BY_LIST.filter(
                              ({ id, sort }) =>
                                id === filterData["column"] && sort === filterData["sort"]
                            )
                          : ""
                      }
                      onChange={(e) => handleChange("column", e)}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-sm-2">
                <fieldset className="multiselect-dropDown">
                  <div className="filter-title">Docs Status</div>
                  <div className="material">
                    <MultiSelect
                      options={PreLogin}
                      placeholder={"Docs Status"}
                      name="pre_login"
                      optionLabel="label"
                      optionValue="value"
                      values={filterData?.pre_login}
                      onChange={handleChange}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="selected-chips">
            <div className="chipsflex">
              <ul>
                {newFiltersArr &&
                  newFiltersArr.length > 0 &&
                  newFiltersArr.map((element, key) => (
                    <li key={key}>
                      {element.value}
                      <span>
                        <i
                          className="ic-clearclose"
                          style={{cursor:"pointer"}}
                          onClick={() =>
                            removeFilter(element.key, element.value, element.type)
                          }
                        ></i>
                      </span>
                    </li>
                  ))}
              </ul>
              {leadPagination ? (
                <p className="showingpage">
                  Showing {leadPagination.currentPageCount || 0}/{leadPagination.totalrecords || 0} entries on Page {leadPagination.page_no || 0}/{leadPagination.totalpage || 0}
                </p>
              ) : (<></>)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeadListFilter;
