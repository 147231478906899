import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Modal from "../../elements/partnerModal";
import "./payout.css";
import InputField from "../../elements/Input";
import RadioBox from "../../elements/Radiobox";
// import { MasterService } from "../../../services";
// import { getCurrentUserDetails } from "../../partner/pre-register-lead-manager/utility/getUserInfo";
// import { APFContext } from "../context/apfContextApi";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Table } from "react-bootstrap";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { PARTNER_DROPDOWN_LIST } from "../../../services/leadCreate.gql";
import { useApolloClient } from "@apollo/client";
import { CREATE_DEBIT_CREDIT_NOTE, INVOICE_LISTING, INVOICE_LISTING_DATA } from "../../../services/payout.gql";
import { executeGraphQLMutation } from "../../../common/partnerExecuteGraphQLQuery";
import ConfirmPopUp from "../../elements/ConfirmPopUp";
import Loader from "../../elements/Loader";

const AddNotesPopup = (props) => {
  //{openPopup, setPopup}
  //   const { cityList, resultList, isEdit, setIsEdit, allBankList, bankList, isNew, setIsNew, error, setError } = useContext(APFContext);
  const [formData, setFormData] = useState({account_type:"CREDIT"});
  const [leadData, setLeadData] = useState([])
  const [error, setError] = useState({});
  const [validationError,setValidationError] = useState(false)
  const [partnerList, setPartnerList] = useState([])
  const [invoiceList, setInvoiceList] = useState([])
  const [noteRadio,setNoteRadio] = useState(false)
  const [loading,setLoading] = useState(false)
  const [searchLoading,setSearchLoading] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false);
  const client = useApolloClient();


  const getPartnerData = async () => {
    try {
      const result = await executeGraphQLQuery(PARTNER_DROPDOWN_LIST, client)
      if (result?.data) {
        const jsonData = JSON.parse(result?.data?.partner_list_dropdown);
        if (jsonData?.status === 200) {
          const partnerList = jsonData?.data?.map((obj) => {
            return {
              id: obj.partnerId,
              label: `${obj.PartnerName || "N/A"} - AMK${obj.partnerId}`,
              value: obj.partnerId,
            };
          });
          setPartnerList(partnerList);
        }
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong");
    }
  };

  const getInvoiceDataByPartner = async (partner_id) => {
    try {
      const variables = {
        filter_params: {
          "status": "paid",
          "source": "web",
          "partner_id": partner_id,
          "tab_type":"STD",
        },
      };
      const result = await executeGraphQLMutation(INVOICE_LISTING(1), variables)
      if (result?.data?.invoice_list?.length) {
        const invoices = result?.data?.invoice_list[0].invoices
        if (invoices.length) {
          const invoiceData = invoices.map((obj) => {
            return {
              id: obj.id,
              label: obj.invoice_no,
              value: obj.invoice_no,
            };
          });
          setInvoiceList(invoiceData);
        }
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong");
    }
  };

  const getInvoiceLeadDataByInvoice = async (invoice_id) => {
    try {
      setSearchLoading(true)
      const result = await executeGraphQLMutation(
        INVOICE_LISTING_DATA(invoice_id)
      );
      if (result.data.invoice_payouts.length) {
        const invoices = result.data.invoice_payouts;
        setLeadData(invoices);
      }
      setSearchLoading(false)
    } catch (error) {
      setSearchLoading(false)
      toast.error("Failed to fetch data");
      console.error("error", error);
    }
  };
  const parseLeadsNotes = ()=>{
    const data = []
    leadData.map(item=>{
      const obj={
        lead_id:item.payouts.partnerleads.lead_id,
        payout_id:item.payouts.id,
        note_amount:+item.total_amount,
        reason:item.reason
      }
      if(obj.note_amount || obj.reason){
        data.push(obj)
      }
    })
    return data
  }
  const handleCreateNote = async () => {
    try {
      setLoading(true)
      const variables ={
          CreditDebitNoteInputArgs: {
              note_type: formData.account_type,
              partner_id: formData.partner_id,
              invoice_id: formData.invoice_id,
              total_note_amount: sumTotalAmount(),
              reason: formData.reason,
              leads_notes: parseLeadsNotes()
          }
      }
      const result = await executeGraphQLMutation(
        CREATE_DEBIT_CREDIT_NOTE(),variables
      );
      if (result.data.create_credit_debit_notes.status) {
        handleCloseModal()
        toast.success(result.data.create_credit_debit_notes.msg)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setShowConfirmation(false)
      toast.error("Failed to create note");
      console.error("error", error);
    }
  };
  //   const [isPopUpOpen, setPopUpOpen] = useState(openPopup);

  const errorHandler = () => {
    const { partner_id,invoice_id,total_amount,reason } = formData;

    if(validationError){
      return true
    }
    let hasError = false;
    let newErrorState = {
      partner_id:false,
      invoice_id:false,
      total_amount:false,
      reason:false,
    };

    if (!partner_id) {
      newErrorState.partner_id = true;
      hasError = true;
    }
    if (!invoice_id) {
      newErrorState.invoice_id = true;
      hasError = true;
    }
    if (!reason) {
      newErrorState.reason = true;
      hasError = true;
    }

    if (hasError) {
      setError((prevError) => ({
        ...prevError,
        ...newErrorState,
      }));
      return hasError;
    }

    return false;
  };

  const handleCloseModal = () => {
    setFormData({account_type:"CREDIT"})
    setLeadData([])
    setShowConfirmation(false)
    setError({})
    props.setPopup(false);
    // setTimeout(() => {
    //   setSelectedLenders([]);
    // }, 1000);
  };
  const handleOnChange = (key, value) => {
    setError((prevError) => ({
      ...prevError,
      [key]:false,
    }));
    if(key=="total_amount"){
      value = formData?.total_amount? +formData.total_amount+Number(value):+value
    }
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleLeadDataChange = (key,value,index)=>{
    setLeadData(prev=>
      prev.map((item,i) => 
        i === index ? { ...item, [key]: value } : item
      )
    )
  }
  useEffect(() => {
    getPartnerData()
  }, [])

  const handlePartnerChange = async (partner) => {
    setInvoiceList([])
    setLeadData([])
    handleOnChange("invoice_id", null)
    handleOnChange("partner_id", partner.id)
    await getInvoiceDataByPartner(partner.id)
  }
  const handleInvoiceChange = async (invoice) => {
    setLeadData([])
    handleOnChange("invoice_id", invoice.id)
    await getInvoiceLeadDataByInvoice(invoice.id)
  }

  const sumTotalAmount = () => {
    return leadData.reduce((sum, item) => {
      // Convert total_amount to a number, handle cases where total_amount is null or not a number
      const amount = parseFloat(item.total_amount) || 0;
      return sum + amount;
    }, 0);
  };
  const confirmNo = ()=>{
    setShowConfirmation(false)
  }
  const handleSaveClick = () =>{
    const total_note_amount=sumTotalAmount()
      setError((prevError) => ({
        ...prevError,
        "total_amount":total_note_amount?false:true,
      }));
      if(errorHandler()) return 
      if(!total_note_amount){
        return
      }
      setShowConfirmation(true)
  }
  console.log("error ===>> ", error)
  return (
    <>
    {searchLoading ? <Loader/> : null}
    <div>
      <Modal
        show={props.openPopup}
        handleClose={handleCloseModal}
        width={1200}
        removeHeight
      >
        <div className="payout-container" style={{ width: "auto !important" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Add New Credit/Debit Note</h2>
          </div>
          <form>
            <div className="">
              <div className="radio-btn">
                <RadioBox
                  type="radio"
                  name="account_type"
                  id="credit"
                  value="credit"
                  label="Credit Note"
                  checked={formData.account_type=="CREDIT"}
                  onChange={(e)=>handleOnChange("account_type",e.target.checked?"CREDIT":null)}
                />
                <RadioBox
                  type="radio"
                  name="account_type"
                  id="debit"
                  value="debit"
                  label="Debit Note"
                  checked={formData.account_type=="DEBIT"}
                  onChange={(e)=>handleOnChange("account_type",e.target.checked?"DEBIT":null)}
                />
              </div>
            </div>


            <div className="form-row" >
              <div style={{ width: "40%", height: "300px" }}>
              <Card >
                <CardBody>
                  {/* <div className="form-row"> */}
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="partner">Select Partner: *</label>
                      <Select
                        id="partner_id"
                        name="partner"
                        placeholder={"Select partner.."}
                        options={partnerList}
                        value={formData.partner_id?partnerList.find(item=>item.id==formData.partner_id):null}
                        onChange={(partner) => handlePartnerChange(partner)}
                      />
                      {error.partner_id && (
                        <span className="error show">{"Please select partner"}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="partner">Select Invoice: *</label>
                      <Select
                        id="invoice_id"
                        name="invoice"
                        placeholder={"Select invoice.."}
                        options={invoiceList}
                        value={formData.invoice_id?invoiceList.find(item=>item.id==formData.invoice_id):null}
                        onChange={(invoice) => handleInvoiceChange(invoice)}
                      />
                      {error.invoice_id && (
                        <span className="error show">{"Please select invoice"}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="total_amount">Total Amount: *</label>
                    <InputField
                      type="text"
                      id="total_amount"
                      name="total_amount"
                      placeholder="Total Amount"
                      value={sumTotalAmount()}
                      disabled
                    />
                    {error.total_amount && (
                        <span className="error show">{"Please enter total amount"}</span>
                      )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="reason">Enter Reason: *</label>
                    <InputField
                      type="text"
                      id="reason"
                      name="reason"
                      placeholder="Enter Reason"
                      value={formData?.reason}
                      error={error?.reason ? "Please Enter Reason" : ""}
                      onChange={(e)=>handleOnChange("reason",e.target.value)}
                    />
                  </div>
                  {/* </div> */}
                </CardBody>
              </Card>
              </div>
              <div style={{width:"60%"}}>
              <Card >
                <CardHeader>
                  <h3 className="popupheader">Leads</h3>
                </CardHeader>
                <CardBody style={{ height: "500px", overflow: "scroll" }}>
                    {leadData.length ?<div className="form-row" >
                      <Table className="tablepopup">
                        <thead>
                          <th>ID</th>
                          <th>Customer</th>
                          <th>Payout</th>
                          <th>TDS</th>
                          <th>GST</th>
                          <th>PAT</th>
                          <th>Action</th>
                        </thead>
                        <tbody>
                        {leadData.length ? leadData.map((item,index) => {
                          return <Leads key={index} setValidationError={setValidationError} formData={{...item,index,total_amount:null,reason:null}} handleOnChange={handleLeadDataChange} />
                        }):<span className="notfoundlead">{searchLoading?"":"Data not found"}</span>}
                        </tbody>
                      </Table>
                    </div>:<span className="notfoundlead">{searchLoading?"":"Data not found"}</span>}
                </CardBody>
              </Card>
              </div>
            </div>
            <div className="btn-submit-reset pull-right" style={{marginBottom:"20px",gap:"10px"}}>
                <button
                type="button"
                  className="btn-reset"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn-primary"
                  onClick={()=>handleSaveClick()}
                >
                  Create
                </button>
              </div>
          </form>
        </div>
      </Modal>
              {showConfirmation && <ConfirmPopUp
                  confirmNo={confirmNo}
                  confirmYes={handleCreateNote}
                  message="Are you sure you want to create Debit/Credit Note ?"
              />}
    </div>
  </>
  );
};

const Leads = ({ formData,handleOnChange,setValidationError }) => {
  const [selected,setSelected] = useState(false)
  const [error,setError] = useState({})
  const [values,setValues] = useState({
    total_amount:null,
    reason:null
  })

  const handleChange=(key,value)=>{
    setError((prevError) => ({
      ...prevError,
      [key]:false,
    }));
    if(key=="total_amount"){
      if(+formData.payout_amount<+value){
        setError(prev=>({...prev,total_amount:"Amount must be less than payout"}))
        setValidationError(true)
      }else{
        setValidationError(false)
      }
    }
    setValues(prev=>({
        ...prev,
        [key]:!selected?null:value
      })
    )
  }
  useEffect(()=>{
    if(selected){
      handleOnChange("total_amount",values.total_amount,formData.index)
      handleOnChange("reason",values.reason,formData.index)
    }
  },[values.total_amount,values.reason])

  const handleSelect=()=>{
    if(selected){
      handleChange("total_amount",null)
      handleChange("reason",null)
      handleOnChange("total_amount",null,formData.index)
      handleOnChange("reason",null,formData.index)
    }
    setSelected(!selected)
  }
  return (
    <>
      <tr>
        <td>{formData.payouts.partnerleads.lead_id}</td>
        <td>{formData.payouts.partnerleads.customer_name}</td>
        <td>{formData.payout_amount}</td>
        <td>{formData.tds}</td>
        <td>{formData.payouts.gst_amount}</td>
        <td>{formData.pat}</td>
        <td>
          <button type="button" className="btn-new" onClick={handleSelect}>+</button>
        </td>
      </tr>
      {selected && <tr>
        <td style={{ borderBottom: "1px solid #ddd" }} colSpan={7}>
          <div className="form-row">
            <div className="form-group" style={{ marginBottom: "0px" }}>
              <InputField
                type="number"
                id="total_amount"
                name="total_amount"
                value={values.total_amount}
                placeholder="Total Amount"
                error={error.total_amount??""}
                onChange={(e)=>handleChange("total_amount",e.target.value)}
              />
            </div>
            <div className="form-group" style={{ marginBottom: "0px" }}>
              <InputField
                type="text"
                id="reason"
                name="reason"
                placeholder="Reason"
                value={values.reason}
                onChange={(e)=>handleChange("reason",e.target.value)}
              />
            </div>
          </div>
        </td>
      </tr>}
    </>
  );
};

export default AddNotesPopup;
