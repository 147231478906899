import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import Pagination from "../elements/pagination";
import { MasterService } from "../../services";
import InputField from "../elements/Input";
import Select from "react-select";
import Formsy from "formsy-react";
import FormsySelect from "../elements/FormsySelect";
import Modal from "../elements/Modal";
import "./BreBankUSP.css";

// Toastify
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Filter options
const statusOptions = [
  { value: "", label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

// Endpoints
const GET_ALL_USP_URL = "/process-rule/crud/getallusps";
const DELETE_USP_URL = "/process-rule/crud/deleteusp";
const UPDATE_USP_URL = "/process-rule/crud/updateusp";
const ADD_USP_URL    = "/process-rule/crud/addusp";

const BreBankUSP = () => {
  const [uspData, setUspData] = useState([]);
  const [loading, setLoading]  = useState(true);
  const [error, setError]      = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Single modal for Add or Edit
  const [showModal, setShowModal]     = useState(false);
  const [selectedUSP, setSelectedUSP] = useState(null); // Null => Add mode

  // Filters
  const [searchParams, setSearchParams] = useState({
    bank_name: "",
    usp_text: "",
    status: "",
  });

  // Fetch on mount
  useEffect(() => {
    fetchData();
  }, []);

  // Re-filter or re-paginate only when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams]);

  // 1) Fetch all USPs
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await MasterService.get(GET_ALL_USP_URL, {
        headers: { "Content-Type": "application/json" },
      });
      setUspData(response.data?.data || []);
    } catch (err) {
      setError("Failed to fetch USP data.");
      toast.error("Error fetching USP data.");
    } finally {
      setLoading(false);
    }
  };

  // 2) Filter logic
  const filteredData = uspData.filter((usp) => {
    const bankMatch = searchParams.bank_name
      ? usp.bank_name.toLowerCase().includes(searchParams.bank_name.toLowerCase())
      : true;

    const textMatch = searchParams.usp_text
      ? usp.usp_usp_text.toLowerCase().includes(searchParams.usp_text.toLowerCase())
      : true;

    const statusMatch =
      searchParams.status !== ""
        ? String(usp.usp_status) === searchParams.status
        : true;

    return bankMatch && textMatch && statusMatch;
  });

  // 3) Paginate
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex   = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  // 4) Handle filter input
  const handleInputChange = (e) => {
    setCurrentPage(1);
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value,
    });
  };

  // 5) Delete => set status=0, refresh
  const handleDelete = async (uspId) => {
  {
      try {
        await MasterService.put(`${DELETE_USP_URL}/${uspId}`, {}, {
          headers: { "Content-Type": "application/json" },
        });
        toast.success("USP deleted successfully!");
        fetchData(); // refresh
      } catch (err) {
        console.error("Error deleting USP:", err.response?.data || err.message);
        toast.error("Error deleting USP");
      }
    }
  };

  // 6) Edit => set up selectedUSP, open modal
  const handleEdit = (usp) => {
    setSelectedUSP({
      usp_id: usp.usp_id,
      usp_bank_id: usp.usp_bank_id?.toString() || "",
      usp_usp_text: usp.usp_usp_text || "",
      usp_logo: usp.usp_logo || "https://static.ambak.com/bre-bank-logo/thumbsup.png",
      usp_status: String(usp.usp_status),
    });
    setShowModal(true);
  };

  // 6a) Add => open modal in "add" mode
  const handleAdd = () => {
    setSelectedUSP({
      usp_id: null,
      usp_bank_id: "",
      usp_usp_text: "",
      usp_logo: "https://static.ambak.com/bre-bank-logo/thumbsup.png",
      usp_status: "1", // default active
    });
    setShowModal(true);
  };

  // 7) Decide "Add" or "Update"
  const handleUpdate = async () => {
    if (!selectedUSP) return;

    try {
      // If there's no `usp_id` => Add mode
      if (!selectedUSP.usp_id) {
        // Validate bank_id
        if (!selectedUSP.usp_bank_id?.trim()) {
          toast.error("Bank ID is required!");
          return;
        }
        const bankId = Number(selectedUSP.usp_bank_id);
        if (isNaN(bankId) || bankId <= 0) {
          toast.error("Bank ID must be a valid positive number!");
          return;
        }

        // Build the add payload
        const payload = {
          bank_id: bankId,
          usp_text: selectedUSP.usp_usp_text,
          logo: selectedUSP.usp_logo,
          status: Number(selectedUSP.usp_status),
        };

      const resp=  await MasterService.put(ADD_USP_URL, payload, {
          headers: { "Content-Type": "application/json" },
        });

       


        toast.success("USP added successfully!");
        setShowModal(false);
        await fetchData();
        // Optionally reset filters
        setSearchParams({ bank_name: "", usp_text: "", status: "" });

      } else {
        // existing USP => Update
        const payload = {
          usp_id: selectedUSP.usp_id,
          usp_text: selectedUSP.usp_usp_text,
          logo: selectedUSP.usp_logo || "",
          status: Number(selectedUSP.usp_status),
        };

        await MasterService.put(`${UPDATE_USP_URL}/${selectedUSP.usp_id}`, payload, {
          headers: { "Content-Type": "application/json" },
        });

        toast.success("USP updated successfully!");
        setShowModal(false);
        await fetchData();
      }
    } catch (err) {
      console.error("Error saving USP:", err.response?.data || err.message);
      toast.error("InValid Bank Id");
    }
  };

  return (
    <div>

      {/* Loading / error states */}
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
          <p>Loading data...</p>
        </div>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <>
          {/* Search Filters */}
          <div className="search-wrap mb-3">
            <div className="filter-container">
              <div className="filter-body">
                <div className="row">
                  {/* Bank Name Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Bank Name</div>
                      <div className="filter-input">
                        <InputField
                          type="text"
                          name="bank_name"
                          placeholder="Search Bank Name"
                          value={searchParams.bank_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* USP Text Filter */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">USP Text</div>
                      <div className="filter-input">
                        <InputField
                          type="text"
                          name="usp_text"
                          placeholder="Search USP Text"
                          value={searchParams.usp_text}
                          onChange={handleInputChange}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Status Filter */}
                  <div className="col-sm-2">
                    <fieldset className="single-select">
                      <div className="filter-label">Status</div>
                      <div className="filter-input">
                        <Select
                          options={statusOptions}
                          placeholder="Select Status"
                          name="status"
                          value={statusOptions.find(
                            (o) => o.value === searchParams.status
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            setSearchParams({ ...searchParams, status: newVal });
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Buttons: Apply, Reset, Add */}
                  <div className="text-btn d-flex">
                    <div className="btn-submit-reset top-btn-none pull-right">
                      <button
                        className="btn-primary"
                        onClick={() => setCurrentPage(1)}
                      >
                        Apply
                      </button>
                      <button
                        className="btn-reset"
                        onClick={() =>
                          setSearchParams({
                            bank_name: "",
                            usp_text: "",
                            status: "",
                          })
                        }
                      >
                        Reset
                      </button>

                      {/* Add USP */}
                      <button
                        className="btn-reset"
                        style={{ marginLeft: "150px" }}
                        onClick={handleAdd}
                      >
                        Add Entry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* USP Table */}
          <Table striped bordered hover responsive>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Bank </th>
                <th>USP Text</th>
                <th>Logo</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((usp) => (
                  <tr key={usp.usp_id}>
                    <td>{usp.usp_id}</td>
                    <td>{usp.bank_name || "N/A"}</td>
                    <td>{usp.usp_usp_text || "No USP Available"}</td>
                    <td>
                      <img
                        src={usp.usp_logo}
                        alt="Bank Logo"
                        width="50"
                      />
                    </td>
                    <td>{usp.usp_status === 1 ? "Active" : "Inactive"}</td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleEdit(usp)}
                        style={{ marginRight: "6px" }}
                      >
                        ✏️ Edit
                      </Button>
                      {String(usp.usp_status)==='1' &&(
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(usp.usp_id)}
                      >
                        🗑️ Delete
                      </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination
            perPage={itemsPerPage}
            onPaginate={(page) => setCurrentPage(page)}
            pageCount={totalPages}
            activePage={currentPage}
          />
        </>
      )}

      {/* Single Modal for Add or Edit */}
      <Modal show={showModal} handleClose={()=>setShowModal(false)} centered>
        <div className="modern-modal">
          <h4 className="modal-title">
            {selectedUSP?.usp_id ? "Edit USP" : "Add USP"}
          </h4>

          <Formsy onValidSubmit={handleUpdate}>
            {/* If no usp_id => Add mode => show Bank ID field */}
            {!selectedUSP?.usp_id && (
              <div className="input-group">
                <label>
                  Bank ID <span style={{ color: "red" }}>*</span>
                </label>
                <InputField
                  name="usp_bank_id"
                  type="number"
                  value={selectedUSP?.usp_bank_id || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedUSP({
                      ...selectedUSP,
                      usp_bank_id: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {/* USP Text */}
            <div className="input-group">
              <label>USP Text</label>
              <InputField
                name="usp_usp_text"
                value={selectedUSP?.usp_usp_text || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedUSP({
                    ...selectedUSP,
                    usp_usp_text: e.target.value,
                  })
                }
              />
            </div>

            {/* Logo URL */}
            <div className="input-group">
              <label>Logo URL</label>
              <InputField
                name="usp_logo"
                value={selectedUSP?.usp_logo || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedUSP({
                    ...selectedUSP,
                    usp_logo: e.target.value,
                  })
                }
              />
            </div>

            {/* Status */}
            <div className="input-group">
  <label>Status</label>
  <FormsySelect
  name="usp_status"
  inputProps={{
    options: [
      { value: "1", label: "Active" },
      { value: "0", label: "Inactive" },
    ],
    // If usp_status exists, don't set a placeholder.
    placeholder: selectedUSP?.usp_status ? undefined : "Select Status",
    className: "react-select",
    classNamePrefix: "react-select",
    value:[
      { value: "1", label: "Active" },
      { value: "0", label: "Inactive" },
    ].filter((option) => option.value === (selectedUSP?.usp_status))
  }}
  onChange={(selected) =>
    setSelectedUSP({
      ...selectedUSP,
      usp_status: selected?.value || "1",
    })
  }
/>

</div>


            {/* Modal Buttons */}
            <div className="button-group">
              <button
                className="btn-cancel"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="btn-update" type="submit">
                {selectedUSP?.usp_id ? "Update" : "Add"}
              </button>
            </div>
          </Formsy>
        </div>
      </Modal>
    </div>
  );
};

export default BreBankUSP;
