import React, { useEffect, useMemo, useState } from "react";
import { FINEX_MASTER_LIST } from "../../services/scheme.gql";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import InputField from "../elements/InputField";
import Select from "react-select";
import { CTP_LIST, PTP_LIST, HARD_MONTH_LIST } from "../../config/constants";
import { toast } from "react-toastify";
import { ADD_RULE_CONFIG } from "../../services/etaRule.gql";
import { useNavigate } from "react-router-dom";

const formDateMap = {
  ctp_start_day: -1,
  ctp_start_month: -1,
  ctp_end_day: -1,
  ctp_end_month: -1,
  expected_mis_day: -1,
  expected_mis_month: -1,
  expected_collection_day: -1,
  expected_collection_month: -1,
};

const AddRule = () => {
  const navigate = useNavigate();
  const [ruleConfigData, setRuleConfigData] = useState({});
  const [financers, setFinancers] = useState([]);
  const [lastChosenDay, setLastChosenDay] = useState(-1);
  const [currentId, setCurrentId] = useState("");

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];

      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            value: banks.banklang.bank_id,
            label: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
    }
  };

  useEffect(() => {
    getFinexMasterData();
  }, []);

  const MONTH_LIST = useMemo(() => {
    switch (currentId) {
      case "ctp_start_month":
        if (lastChosenDay < formDateMap["ctp_start_day"]) {
          return HARD_MONTH_LIST.filter(
            (month) => Number(month.value) > formDateMap["ctp_start_month"]
          );
        }
        return HARD_MONTH_LIST.filter(
          (month) => Number(month.value) >= formDateMap["ctp_start_month"]
        );

      case "ctp_end_month":
        if (lastChosenDay < formDateMap["ctp_end_day"]) {
          return HARD_MONTH_LIST.filter(
            (month) => Number(month.value) > formDateMap["ctp_end_month"]
          );
        }
        return HARD_MONTH_LIST.filter(
          (month) => Number(month.value) >= formDateMap["ctp_end_month"]
        );

      case "expected_mis_month":
        if (lastChosenDay < formDateMap["expected_mis_day"]) {
          return HARD_MONTH_LIST.filter(
            (month) => Number(month.value) > formDateMap["expected_mis_month"]
          );
        }
        return HARD_MONTH_LIST.filter(
          (month) => Number(month.value) >= formDateMap["expected_mis_month"]
        );
      default:
        return HARD_MONTH_LIST;
    }
  }, [lastChosenDay]);

  const CALENDAR_DAYS = useMemo(
    () =>
      Array.from({ length: 31 }, (_, index) => {
        const day = index + 1;
        return { id: day, label: `${day}`, value: day };
      }),
    []
  );

  const handleSelectChange = (id, data) => {
    if (id.includes("day")) {
      formDateMap[id] = Number(data.value);
      setLastChosenDay(data.value);
    } else if (id.includes("month")) {
      formDateMap[id] = Number(data.value);
      setCurrentId(id);
    }

    setRuleConfigData((current) => ({
      ...current,
      [id]: data.id,
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setRuleConfigData((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handleSaveRule = async () => {
    try {
      const variables = {
        addRuleInput: ruleConfigData,
      };
      const response = await executeGraphQLMutation(ADD_RULE_CONFIG, variables);
      if (response.data) {
        if (response.data?.add_rule_config === "Rule added successfully") {
          toast.success(response.data?.add_rule_config);
        } else {
          toast.error(response.data?.add_rule_config);
        }
        setTimeout(() => navigate("/payout/rule-list"), 2000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="top-heading">Bank config for ETA calculation</h2>
        <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2">
              <label>Banks</label>
              <Select
                id="financer_id"
                name="financer_id"
                options={financers}
                value={
                  ruleConfigData.financer_id &&
                  financers.find(({ id }) => id === ruleConfigData.financer_id)
                }
                onChange={(data) => handleSelectChange("financer_id", data)}
              ></Select>
            </div>

            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "rule_start_date",
                  type: "date",
                  name: "rule_start_date",
                  autocompleate: "off",
                  label: "Rule start",
                  value: ruleConfigData.rule_start_date,
                }}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-sm-2">
              <InputField
                inputProps={{
                  id: "rule_end_date",
                  type: "date",
                  name: "rule_end_date",
                  autocompleate: "off",
                  label: "Rule end",
                  value: ruleConfigData.rule_end_date,
                  min: new Date().toISOString().split("T")[0],
                }}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <h2 className="top-heading">Calculation Trigger Point</h2>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <p>If the</p>
              <div className="col-sm-2">
                <Select
                  id="ctp"
                  name="ctp"
                  placeholder="CTP"
                  options={CTP_LIST}
                  value={
                    ruleConfigData.ctp &&
                    CTP_LIST.find(({ id }) => id === ruleConfigData.ctp)
                  }
                  onChange={(data) => handleSelectChange("ctp", data)}
                ></Select>
              </div>
              <p>date falls between</p>
              <div className="col-sm-2">
                <Select
                  id="ctp_start_day"
                  name="ctp_start_day"
                  placeholder="Start day ( 1 - 31 )"
                  options={CALENDAR_DAYS}
                  value={
                    ruleConfigData.ctp_start_day &&
                    CALENDAR_DAYS.find(
                      ({ id }) => id === ruleConfigData.ctp_start_day
                    )
                  }
                  onChange={(data) => handleSelectChange("ctp_start_day", data)}
                ></Select>
              </div>
              <p>of</p>
              <div className="col-sm-2">
                <Select
                  id="ctp_start_month"
                  name="ctp_start_month"
                  placeholder="Month"
                  options={MONTH_LIST}
                  values={
                    ruleConfigData.ctp_start_month &&
                    MONTH_LIST.find(
                      ({ id }) => id === ruleConfigData.ctp_start_month
                    )
                  }
                  onChange={(data) =>
                    handleSelectChange("ctp_start_month", data)
                  }
                ></Select>
              </div>
              <p>and</p>
              <div className="col-sm-2">
                <Select
                  id="ctp_end_day"
                  name="ctp_end_day"
                  placeholder="End day ( 1 - 31 )"
                  options={CALENDAR_DAYS}
                  value={
                    ruleConfigData.ctp_end_day &&
                    CALENDAR_DAYS.find(
                      ({ id }) => id === ruleConfigData.ctp_end_day
                    )
                  }
                  onChange={(data) => handleSelectChange("ctp_end_day", data)}
                ></Select>
              </div>
              <p>of</p>
              <div className="col-sm-2">
                <Select
                  id="ctp_end_month"
                  name="ctp_end_month"
                  placeholder="Month"
                  options={MONTH_LIST}
                  values={
                    ruleConfigData.ctp_end_month &&
                    MONTH_LIST.find(
                      ({ id }) =>
                        id === ruleConfigData.ctp_end_month &&
                        id > ruleConfigData.ctp_start_month
                    )
                  }
                  onChange={(data) => handleSelectChange("ctp_end_month", data)}
                ></Select>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <h2 className="top-heading">Expected MIS Date</h2>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <p>Then the MIS will be recieved on</p>
              <div className="col-sm-2">
                <Select
                  id="expected_mis_day"
                  name="expected_mis_day"
                  placeholder="End day ( 1 - 31 )"
                  options={CALENDAR_DAYS}
                  value={
                    ruleConfigData.expected_mis_day &&
                    CALENDAR_DAYS.find(
                      ({ id }) => id === ruleConfigData.expected_mis_day
                    )
                  }
                  onChange={(data) =>
                    handleSelectChange("expected_mis_day", data)
                  }
                ></Select>
              </div>
              <p>of</p>
              <div className="col-sm-2">
                <Select
                  id="expected_mis_month"
                  name="expected_mis_month"
                  placeholder="Month"
                  options={MONTH_LIST}
                  values={
                    ruleConfigData.expected_mis_month &&
                    MONTH_LIST.find(
                      ({ id }) => id === ruleConfigData.expected_mis_month
                    )
                  }
                  onChange={(data) =>
                    handleSelectChange("expected_mis_month", data)
                  }
                ></Select>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <h2 className="top-heading">Expected Collection Date</h2>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <p>And the collection from bank will come by</p>
              <div className="col-sm-2">
                <Select
                  id="expected_collection_day"
                  name="expected_collection_day"
                  placeholder="End day ( 1 - 31 )"
                  options={CALENDAR_DAYS}
                  value={
                    ruleConfigData.expected_collection_day &&
                    CALENDAR_DAYS.find(
                      ({ id }) => id === ruleConfigData.expected_collection_day
                    )
                  }
                  onChange={(data) =>
                    handleSelectChange("expected_collection_day", data)
                  }
                ></Select>
              </div>
              <p>of</p>
              <div className="col-sm-2">
                <Select
                  id="expected_collection_month"
                  name="expected_collection_month"
                  placeholder="Month"
                  options={MONTH_LIST}
                  values={
                    ruleConfigData.expected_collection_month &&
                    MONTH_LIST.find(
                      ({ id }) =>
                        id === ruleConfigData.expected_collection_month
                    )
                  }
                  onChange={(data) =>
                    handleSelectChange("expected_collection_month", data)
                  }
                ></Select>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <h2 className="top-heading">Expected Payout Date</h2>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <p>But the payout will be processed to partner 3 days after</p>
              <div className="col-sm-2">
                <Select
                  id="ptp"
                  name="ptp"
                  placeholder="Payout Trigger Point"
                  options={PTP_LIST}
                  value={
                    ruleConfigData.ptp &&
                    PTP_LIST.find(({ id }) => id === ruleConfigData.ptp)
                  }
                  onChange={(data) => handleSelectChange("ptp", data)}
                ></Select>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div className="btn-submit-reset top-btn-none">
              <button
                type="submit"
                className="btn btn-primary mrg-r10"
                disabled={Object.keys(ruleConfigData).length < 13}
                onClick={handleSaveRule}
              >
                Save
              </button>
              <button
                type="reset"
                className="btn-reset"
                onClick={() => window.location.reload()}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("ac_manager")(
  connect(mapStateToProps, mapDispatchToProps)(AddRule)
);
