import React, { useState, useEffect } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import Pagination from "../elements/pagination";
import { MasterService } from "../../services";
import InputField from "../elements/Input";
import Select from "react-select";
import Formsy from "formsy-react";
import FormsySelect from "../elements/FormsySelect";
import Modal from "../elements/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { valueFromAST } from "graphql";

// -------------------------------------
//     Endpoint URLs
// -------------------------------------
const GET_ALL_TENURE_URL = "/process-rule/crud/getallbanktenure";
const DELETE_TENURE_URL = "/process-rule/crud/deletebanktenure";
const UPDATE_TENURE_URL = "/process-rule/crud/updatebanktenure";
const ADD_TENURE_URL = "/process-rule/crud/addbanktenure";

// -------------------------------------
//     Options for filtering
// -------------------------------------
const statusOptions = [
  { value: "", label: "All" },
  { value: "1", label: "Active" },
  { value: "0", label: "Inactive" },
];

const incomeTypeOptions = [
  { value: "", label: "All" },
  { value: "salaried", label: "salaried" },
  { value: "Self Employed Professional", label:"Self Employed Professional" },
  {value:"self_empoyee_non_professional", label:"self_empoyee_non_professional"},
  
];

// For the salary_mode field in the form and table
const salaryModeMap = {
  "1": "Account",
  "2": "Cash",
};


  


const BreBankTenureCal = () => {
  // -------------------------
  //   States
  // -------------------------
  const [tenureData, setTenureData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Modal for Add/Edit
  const [showModal, setShowModal] = useState(false);
  const [selectedTenure, setSelectedTenure] = useState(null);

  // For text expansion in table rows (if needed)
  const [expandedRows, setExpandedRows] = useState({});

  // Filtering
  const [searchParams, setSearchParams] = useState({
    bank_name: "",
    status: "",
    income_type: "",
  });

  // -------------------------
  //   useEffect
  // -------------------------
  useEffect(() => {
    fetchData();
  }, []);

  // Reset page whenever filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams]);

  // -------------------------
  //   Fetch Data
  // -------------------------
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await MasterService.get(GET_ALL_TENURE_URL);
      setTenureData(response.data?.data || []);
    } catch (err) {
      setError("Failed to fetch Tenure data.");
      toast.error("Error fetching Tenure data.");
    } finally {
      setLoading(false);
    }
  };

  // -------------------------
  //   Filtering
  // -------------------------
  const filteredData = tenureData.filter((item) => {
    const bankMatch = searchParams.bank_name
      ? item.bank_name?.toLowerCase().includes(searchParams.bank_name.toLowerCase())
      : true;

    const statusMatch =
      searchParams.status !== ""
        ? String(item.tenure_status) === searchParams.status
        : true;

    const incomeTypeMatch =
      searchParams.income_type !== ""
        ? item.tenure_income_type === searchParams.income_type
        : true;

    return bankMatch && statusMatch && incomeTypeMatch;
  });

  // -------------------------
  //   Pagination
  // -------------------------
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  // -------------------------
  //   Handle Filter Inputs
  // -------------------------
  const handleInputChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  // -------------------------
  //   Delete => Status=0
  // -------------------------
  const handleDelete = async (tenureId) => {
    try {
      await MasterService.put(`${DELETE_TENURE_URL}/${tenureId}`);
      toast.success("Status changed to Inactive!");
      await fetchData();
    } catch (err) {
      toast.error("Error updating status.");
    }
  };

  // -------------------------
  //   Toggle text expansion
  // -------------------------
  const toggleTextExpansion = (rowKey) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowKey]: !prev[rowKey],
    }));
  };

  // -------------------------
  //   Edit => open modal
  // -------------------------
  const handleEdit = (tenure) => {
    setSelectedTenure({
      tenure_id: tenure.tenure_id,
      tenure_income_type: tenure.tenure_income_type || "",
      tenure_salary_mode: tenure.tenure_salary_mode || "",
      tenure_age: tenure.tenure_age?.toString() || "",
      tenure_retirement_age: tenure.tenure_retirement_age?.toString() || "",
      tenure_status: tenure.tenure_status?.toString() || "1",
    });
    setShowModal(true);
  };

  // -------------------------
  //   Add => open modal
  // -------------------------
  const handleAdd = () => {
    setSelectedTenure({
      tenure_id: null,
      tenure_income_type: "",
      tenure_salary_mode: "",
      tenure_age: "",
      tenure_retirement_age: "",
      tenure_status: "1",
    });
    setShowModal(true);
  };

  // -------------------------
  //   Save => Add or Update
  // -------------------------
  const handleUpdate = async () => {
    try {
      // =============== CREATE ===============
      if (!selectedTenure?.tenure_id) {
        // Validate Bank ID is provided and valid
        if (!selectedTenure.bank_id?.trim()) {
          toast.error("Bank ID is required!");
          return;
        }
        const bankId = Number(selectedTenure.bank_id);
        if (isNaN(bankId) || bankId <= 0) {
          toast.error("Bank ID must be a valid positive number!");
          return;
        }
      
        // Validate Age is provided and valid
        if (!selectedTenure.tenure_age?.trim()) {
          toast.error("Age is required for new entries!");
          return;
        }
        const ageNum = Number(selectedTenure.tenure_age);
        if (isNaN(ageNum) || ageNum <= 0) {
          toast.error("Age must be a valid positive number!");
          return;
        }
      
        // Build create payload including bank_id and age
        const addPayload = {
          bank_id: bankId, // Now required on Add
          age: ageNum,      // Now required on Add
          income_type: selectedTenure.tenure_income_type || "",
          salary_mode: selectedTenure.tenure_salary_mode || "",
          retirement_age: selectedTenure.tenure_retirement_age
            ? Number(selectedTenure.tenure_retirement_age)
            : 0,
          status: selectedTenure.tenure_status,
        };
      
        const data = { data: JSON.stringify(addPayload) };
        const resp = await MasterService.post(ADD_TENURE_URL, data, {
          headers: { "Content-Type": "application/json" },
        });
      
        if (resp.status > 399) {
          toast.error("Bank Id invalid or Invalid Entry");
          return;
        }
      
        toast.success("Tenure added successfully!");
        setShowModal(false);
        await fetchData();
      }
      

      // =============== UPDATE ===============
       else {
        // Build update payload
        // (All fields optional except we do not allow changing 'bank_id' in your scenario)
        const payload = {};

        if (selectedTenure.tenure_income_type) {
          payload["income_type"] = selectedTenure.tenure_income_type;
        }
        if (selectedTenure.tenure_salary_mode) {
          payload["salary_mode"] = selectedTenure.tenure_salary_mode;
        }
        if (selectedTenure.tenure_age) {
          const ageNum = Number(selectedTenure.tenure_age);
          if (isNaN(ageNum) || ageNum < 0) {
            toast.error("Age must be a valid non-negative number!");
            return;
          }
          payload["age"] = ageNum;
        }
        if (selectedTenure.tenure_retirement_age) {
          payload["retirement_age"] = Number(selectedTenure.tenure_retirement_age);
        }
        if (selectedTenure.tenure_status) {
          payload["status"] = selectedTenure.tenure_status;
        }

        const reqData = { data: JSON.stringify(payload) };
        await MasterService.put(`${UPDATE_TENURE_URL}/${selectedTenure.tenure_id}`, reqData, {
          headers: { "Content-Type": "application/json" },
        });

        toast.success("Tenure updated successfully!");
        setShowModal(false);
        await fetchData();
      }
    } catch (err) {
     
      toast.error("Error saving Tenure record");
    }
  };

  // -------------------------
  //   JSX Return
  // -------------------------
  return (
    <>
    

      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" variant="primary" />
          <p>Loading data...</p>
        </div>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <>
          {/* Filters */}
          <div className="search-wrap mb-3">
            <div className="filter-container">
              <div className="filter-body">
                <div className="row">
                  {/* Bank Name */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Bank Name</div>
                      <div className="filter-input">
                        <InputField
                          name="bank_name"
                          placeholder="Search Bank Name"
                          value={searchParams.bank_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Status */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Status</div>
                      <div className="material">
                        <Select
                          options={statusOptions}
                          placeholder="Select Status"
                          name="status"
                          value={statusOptions.find(
                            (o) => o.value === searchParams.status
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            setSearchParams({ ...searchParams, status: newVal });
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Income Type */}
                  <div className="col-sm-3">
                    <fieldset className="single-select">
                      <div className="filter-label">Income Type</div>
                      <div className="material">
                        <Select
                          options={incomeTypeOptions}
                          placeholder="Select Income Type"
                          name="income_type"
                          value={incomeTypeOptions.find(
                            (opt) => opt.value === searchParams.income_type
                          )}
                          onChange={(selected) => {
                            const newVal = selected?.value || "";
                            setSearchParams({
                              ...searchParams,
                              income_type: newVal,
                            });
                          }}
                        />
                      </div>
                    </fieldset>
                  </div>

                  {/* Filter Buttons */}
                  <div className="text-btn d-flex">
                    <div className="btn-submit-reset top-btn-none pull-right">
                      <button className="btn-primary" onClick={() => setCurrentPage(1)}>
                        Apply
                      </button>
                      <button
                        className="btn-reset"
                        onClick={() =>
                          setSearchParams({
                            bank_name: "",
                            status: "",
                            income_type: "",
                          })
                        }
                      >
                        Reset
                      </button>
                      <button
                        className="btn-reset"
                        style={{ marginLeft: "150px" }}
                        onClick={handleAdd}
                      >
                        Add Entry
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Data Table */}
          <Table striped bordered hover responsive>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Bank </th>
                <th>Income Type</th>
                <th>Salary Mode</th>
                <th>Age</th>
                <th>Retirement Age</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((tenure) => (
                  <tr key={tenure.tenure_id}>
                    <td>{tenure.tenure_id}</td>
                    <td>{tenure.bank_name || "-"}</td>
                    <td>{tenure.tenure_income_type || "-"}</td>
                    {/* Display "Account" if "1", "Cash" if "2" */}
                    <td>
                      {tenure.tenure_salary_mode
                        ? salaryModeMap[tenure.tenure_salary_mode] || "-"
                        : "-"}
                    </td>
                    <td>{tenure.tenure_age || "-"}</td>
                    <td>{tenure.tenure_retirement_age || "-"}</td>
                    <td>
                      {String(tenure.tenure_status) === "1" ? "Active" : "Inactive"}
                    </td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        style={{ marginRight: "0px" }}
                        onClick={() => handleEdit(tenure)}
                      >
                        ✏️ Edit
                      </Button>
                      {String(tenure.tenure_status) === "1" && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDelete(tenure.tenure_id)}
                        >
                          🗑️ Delete
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination
            perPage={itemsPerPage}
            onPaginate={(page) => setCurrentPage(page)}
            pageCount={totalPages}
            activePage={currentPage}
          />
        </>
      )}

      {/* Add/Edit Modal */}
      <Modal show={showModal} handleClose={() => setShowModal(false)} centered>
        <div className="modern-modal">
          <h4 className="modal-title">
            {selectedTenure?.tenure_id ? "Edit Tenure" : "Add Tenure"}
          </h4>
          <Formsy onValidSubmit={handleUpdate}>
            {/* Age (required only in Add mode) */}
{!selectedTenure?.tenure_id && (
  <div className="input-group">
    <label>
      Bank ID <span style={{ color: "red" }}>*</span>
    </label>
    <InputField
      name="bank_id"
      value={selectedTenure?.bank_id || ""}
      className="modern-input"
      onChange={(e) =>
        setSelectedTenure({ ...selectedTenure, bank_id: e.target.value })
      }
    />
  </div>
)}

            {!selectedTenure?.tenure_id && (
              <div className="input-group">
                <label>
                  Age <span style={{ color: "red" }}>*</span>
                </label>
                <InputField
                  name="tenure_age"
                  value={selectedTenure?.tenure_age || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedTenure({
                      ...selectedTenure,
                      tenure_age: e.target.value,
                    })
                  }
                />
              </div>
            )}



            {/* If editing, user can still update age if they want */}
            {selectedTenure?.tenure_id && (
              <div className="input-group">
                <label>Age</label>
                <InputField
                  name="tenure_age"
                  value={selectedTenure?.tenure_age || ""}
                  className="modern-input"
                  onChange={(e) =>
                    setSelectedTenure({
                      ...selectedTenure,
                      tenure_age: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {/* Income Type */}
            <div className="input-group">
              <label>Income Type</label>
              <FormsySelect
                name="tenure_income_type"
                inputProps={{
                  options: [
                    { value: "salaried", label: "salaried" },
                    { value: "Self Employed Professional", label:"Self Employed Professional" },
                    {value:"self_empoyee_non_professional", label:"self_empoyee_non_professional"},
                  ],
                  placeholder: selectedTenure?.tenure_income_type
                    ? undefined
                    : "Select Income Type",
                  className: "react-select",
                  classNamePrefix: "react-select",
                  value: [
                    { value: "salaried", label: "salaried" },
                    { value: "Self Employed Professional", label:"Self Employed Professional" },
                    {value:"self_empoyee_non_professional", label:"self_empoyee_non_professional"},
                  ].filter(
                    (option) => option.value === selectedTenure?.tenure_income_type
                  ),
                }}
                onChange={(selected) =>
                  setSelectedTenure({
                    ...selectedTenure,
                    tenure_income_type: selected?.value || "",
                  })
                }
              />
            </div>

            {/* Salary Mode => 1 => Account, 2 => Cash */}
            <div className="input-group">
  <label>
    Salary Mode {!selectedTenure?.tenure_id && <span style={{ color: "red" }}>*</span>}
  </label>
  <FormsySelect
    name="tenure_salary_mode"
    inputProps={{
      options: [
        { value: 1, label: "Account" },
        { value: 2, label: "Cash" },
      ],
      placeholder: selectedTenure?.tenure_salary_mode
        ? undefined
        : "Select Salary Mode",
      className: "react-select",
      classNamePrefix: "react-select",
      value: [
        { value: 1, label: "Account" },
        { value: 2, label: "Cash" },
      ].filter(
        (option) => option.value == selectedTenure?.tenure_salary_mode
      ),
    }}
    onChange={(selected) =>
      setSelectedTenure({
        ...selectedTenure,
        tenure_salary_mode: selected?.value || "",
      })
    }
  />
</div>










            {/* Retirement Age */}
            <div className="input-group">
              <label>Retirement Age</label>
              <InputField
                name="tenure_retirement_age"
                type="number"
                value={selectedTenure?.tenure_retirement_age || ""}
                className="modern-input"
                onChange={(e) =>
                  setSelectedTenure({
                    ...selectedTenure,
                    tenure_retirement_age: e.target.value,
                  })
                }
              />
            </div>

            {/* Status => 1 => Active, 0 => Inactive */}
            <div className="input-group">
              <label>Status</label>
              <FormsySelect
                name="tenure_status"
                inputProps={{
                  options: [
                    { value: "1", label: "Active" },
                    { value: "0", label: "Inactive" },
                  ],
                  placeholder: selectedTenure?.tenure_status
                    ? undefined
                    : "Select Status",
                  className: "react-select",
                  classNamePrefix: "react-select",
                  value: [
                    { value: "1", label: "Active" },
                    { value: "0", label: "Inactive" },
                  ].filter(
                    (option) => option.value === selectedTenure?.tenure_status
                  ),
                }}
                onChange={(selected) =>
                  setSelectedTenure({
                    ...selectedTenure,
                    tenure_status: selected?.value || "1",
                  })
                }
              />
            </div>

            {/* Modal Buttons */}
            <div className="button-group">
              <button
                className="btn-cancel"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="btn-update" type="submit">
                {selectedTenure?.tenure_id ? "Update" : "Add"}
              </button>
            </div>
          </Formsy>
        </div>
      </Modal>
    </>
  );
};

export default BreBankTenureCal;
